import { Box, Typography } from '@mui/material';
import { CSSProperties } from 'react';

export const HelpPopoverText: React.FC<React.PropsWithChildren & { style?: CSSProperties }> = ({
  children,
  style,
}) => {
  return (
    <Typography color='secondary' style={style}>
      {children}
    </Typography>
  );
};
interface HelpPopoverProps extends React.PropsWithChildren {
  title: string;
}

const HelpPopover: React.FC<HelpPopoverProps> = ({ children, title }) => {
  return (
    <Box paddingY={2} paddingX={1}>
      <Typography color='secondary' variant='p18Bold' mb={1}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default HelpPopover;
