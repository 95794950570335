import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

interface AddAuthMethodRowProps {
  onAdd: () => void;
}

const AddAuthMethodRow: React.FC<AddAuthMethodRowProps> = ({ onAdd }) => {
  const { t } = useTranslation();
  return (
    <Grid
      mt={2}
      pl={4}
      width='100%'
      display='inline-flex'
      justifyContent='space-between'
      flexDirection='row'
      className='add-investment'
      style={{ cursor: 'pointer' }}
      sx={{ borderRadius: '10px' }}
      onClick={() => {
        onAdd();
      }}>
      <Typography variant='p16Bold' color='primary'>
        {t('AddAnAuthenticationMethod')}
      </Typography>
      <IconButton sx={{ padding: '0' }}>
        <Add color='primary' fontSize='large' />
      </IconButton>
    </Grid>
  );
};

export default AddAuthMethodRow;
