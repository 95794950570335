import { Authentication, Data, injectCommandExecutor } from '@3nickels/data-modules';
import { createServiceModule, inject, Scopes } from '@aesop-fables/containr';
import { ICommandExecutor, IListener } from '@aesop-fables/scrinium';
import { interval, NEVER, Subscription, switchMap } from 'rxjs';
import { useService } from '@aesop-fables/containr-react';

export const userEventServiceKey = 'services/userEventService';

export class UserEventListener implements IListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    private context: Authentication.IAuthenticationContext,
    @inject(userEventServiceKey) private userEventService: IUserEventService
  ) {}

  start(): Subscription {
    return this.context.isAuthenticated$
      .pipe(switchMap((x) => (x ? interval(1000 * 5) : NEVER)))
      .subscribe(() => {
        this.userEventService.publish();
      });
  }

  stop?: (() => void) | undefined;
}

export interface IUserEventService {
  publish: () => Promise<void>;
  logUserEvent: (data: Data.Logging.UserEventsData) => void;
}

export class UserEventService implements IUserEventService {
  private userEvents: Data.Logging.UserEventsData[] = [];
  constructor(@injectCommandExecutor() private commands: ICommandExecutor) {}

  async publish(): Promise<void> {
    if (this.userEvents.length > 0) {
      await this.commands.execute(Data.Logging.Commands.SaveUserEvents, this.userEvents);
      this.userEvents = [];
    }
  }

  logUserEvent(data: Data.Logging.UserEventsData) {
    this.userEvents.push(data);
  }
}

export const useUserEventServiceModule = createServiceModule('userEvents', (services) =>
  services.autoResolve<IUserEventService>(userEventServiceKey, UserEventService, Scopes.Singleton)
);

export function useUserEventService() {
  const service = useService<IUserEventService>(userEventServiceKey);
  return service;
}
