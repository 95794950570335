import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import { createServiceModule, inject } from '@aesop-fables/containr';
import { Api, Commands, Data, injectCommandExecutor } from '@3nickels/data-modules';

export interface ChangePasswordData {
  password?: string;
}

export interface ForgotPasswordData {
  birthDate?: Date;
  username?: string;
  password?: string;
  rePassword?: string;
  twoFactorType?: string;
}

export const accountSettingsWizardKey = 'services/accountSettingsWizard';

class AccountSettingsWizard implements Disposable {
  private forgotPasswordData: BehaviorSubject<ForgotPasswordData | undefined> = new BehaviorSubject<
    ForgotPasswordData | undefined
  >(undefined);
  private addTwoFactorMethodData: BehaviorSubject<
    Data.TwoFactor.AuthTwoFactorFormData | undefined
  > = new BehaviorSubject<Data.TwoFactor.AuthTwoFactorFormData | undefined>(undefined);

  constructor(
    @inject(Api.ApiKeys.AuthApi)
    private readonly authApi: Api.AuthApi,
    @injectCommandExecutor() private readonly command: Commands.CommandExecutor
  ) {
    // init
  }

  get forgotPasswordData$(): Observable<ForgotPasswordData | undefined> {
    return this.forgotPasswordData;
  }

  get addTwoFactorMethodData$(): Observable<Data.TwoFactor.AuthTwoFactorFormData | undefined> {
    return this.addTwoFactorMethodData;
  }

  setForgotPasswordData(data: ForgotPasswordData) {
    this.forgotPasswordData.next(data);
  }

  resetForgotPasswordData() {
    this.forgotPasswordData.next(undefined);
  }

  setAddTwoFactorMethodData(data: Data.TwoFactor.AuthTwoFactorFormData) {
    this.addTwoFactorMethodData.next(data);
  }

  resetAddTwoFactorMethodData() {
    this.addTwoFactorMethodData.next(undefined);
  }

  async initiateResetPassword(data: Api.ForgotPasswordRest): Promise<void> {
    await this.authApi.initiateResetPassword({
      username: data.username,
      birthDate: data.birthDate,
    });
  }

  async verifyResetPassword(code: string): Promise<void> {
    await this.authApi.verifyResetPassword(code);
  }

  async resetPassword(data: ForgotPasswordData): Promise<void> {
    await this.authApi.resetPassword({ password: data.password });
  }

  async saveTwoFactorMethod(method: Data.TwoFactor.AuthTwoFactorFormData): Promise<void> {
    const authTwoFactorData = Data.TwoFactor.mapAuthTwoFactorFormDataToAuthTwoFactorData(method);
    await this.authApi.saveTwoFactorMethod(authTwoFactorData);
  }

  async initiateChangePassword(data: Data.ChangePassword.ChangePasswordData): Promise<void> {
    const changePasswordData = Data.ChangePassword.mapChangePasswordDataToChangePasswordRest(data);
    await this.authApi.initiateChangePassword(changePasswordData);
  }

  dispose(): void {
    console.log('disposing AccountSettingsWizard');
  }
}

export const withAccountSettingsWizard = createServiceModule(
  accountSettingsWizardKey,
  (services) => {
    services.use<AccountSettingsWizard>(accountSettingsWizardKey, AccountSettingsWizard);
  }
);

export { AccountSettingsWizard };
