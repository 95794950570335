import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const SearchSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <g fillRule='nonzero'>
            <path
              d='m26.822 22.958-4.609-4.606s-.048-.048-.096-.048a13.032 13.032 0 0 1-1.488 1.823 10.755 10.755 0 0 1-1.825 1.488s.048.048.048.096l4.61 4.605c.911.912 2.4.912 3.312 0 .96-.96.96-2.446.048-3.358z'
              fill='#1E2969'
            />
            <path
              d='M20.123 5.029a10.304 10.304 0 0 0-14.594 0 10.304 10.304 0 0 0 0 14.594 10.304 10.304 0 0 0 14.594 0c4.039-4.038 4.039-10.601 0-14.594zM7.411 17.696a7.617 7.617 0 0 1 0-10.785 7.617 7.617 0 0 1 10.785 0 7.617 7.617 0 0 1 0 10.785c-2.938 2.983-7.802 2.983-10.785 0z'
              fill='#1B7690'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
