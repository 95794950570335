import { SvgIcon, SvgIconProps } from '@mui/material';

export function NetWorthSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 35 21' {...props}>
      <g fill='none' fill-rule='evenodd'>
        <path d='M0 0h35v21H0z' />
        <g fill={props.fill} fill-rule='nonzero'>
          <path d='M27.03 19.086H7.957a.962.962 0 0 0-.957.957c0 .524.433.957.957.957H27.03a.962.962 0 0 0 .957-.957.962.962 0 0 0-.957-.957zM9.15 16.608a.962.962 0 0 0-.957.957c0 .524.432.957.957.957h16.687a.962.962 0 0 0 .957-.957.962.962 0 0 0-.957-.957h-.236V8.02h.236a.47.47 0 0 0 .472-.472.47.47 0 0 0-.472-.472H9.15a.47.47 0 0 0-.472.472c0 .263.21.472.472.472h.236v8.588H9.15zM23.687 8.02v8.588H20.83V8.02h2.857zm-4.771 0v8.588h-2.858V8.02h2.858zm-7.63 0h2.858v8.588h-2.857V8.02zM7.957 6.211H27.043A.962.962 0 0 0 28 5.254a.967.967 0 0 0-.642-.905L17.893.09a.918.918 0 0 0-.786 0L7.564 4.375a.946.946 0 0 0-.538 1.075c.092.446.472.76.93.76z' />
        </g>
      </g>
    </SvgIcon>
  );
}
