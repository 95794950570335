import { Domain } from '@3nickels/data-modules';
import { Card } from '@mui/material';
import { Colors } from '../../../themes';

type QuizAnswerCardProps = {
  index: number;
  answer: Domain.QuizAnswerDto;
  selectedIds: number[];
  answerSubmitted: boolean;
  onAnswerPress: (id: number) => void;
};

const QuizAnswerCard = ({
  answer,
  selectedIds,
  answerSubmitted: readOnly,
  onAnswerPress,
}: QuizAnswerCardProps) => {
  const isSelected = selectedIds.includes(answer.answerId ?? 0);

  return (
    <Card
      className='hoverable-info-card'
      onClick={() => !readOnly && onAnswerPress(answer.answerId ?? 0)}
      sx={{
        marginBottom: '8px',
        ...(isSelected &&
          !readOnly && {
            'backgroundColor': Colors.primaryLightest,
            'borderColor': Colors.primaryLight,
            '&:hover': {
              backgroundColor: Colors.primaryLightest,
              borderColor: Colors.primaryLight,
            },
          }),
        ...(readOnly &&
          isSelected && {
            'backgroundColor': Colors.disabledLight,
            'borderColor': Colors.disabledBase,
            '&:hover': { backgroundColor: Colors.disabledLight, borderColor: Colors.disabledBase },
          }),
        ...(readOnly &&
          !isSelected && {
            '&:hover': { backgroundColor: Colors.tertiaryBase },
          }),
      }}>
      <div dangerouslySetInnerHTML={{ __html: answer.text ?? '' }} />
    </Card>
  );
};

export default QuizAnswerCard;
