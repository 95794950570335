import { Box } from '@mui/material';
import React from 'react';
import { nature } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import InflationCalculatorCard from './cards/InflationCalculatorCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const GoalsToolStartView: React.FC = () => {
  const explore = useExploreGroups();

  const calculators: Group[] = [
    {
      textKey: 'inflation-calculator',
      url: 'inflation-calculator',
      content: <InflationCalculatorCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplaySmallCustomGoals2 />}
        label='Goals'
        calculators={calculators}
        resources={[]}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={nature} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(GoalsToolStartView, meta);
