import { useEffect } from 'react';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { useLoading } from '../useLoading';
import { useMessage } from '../useMessage';
import { DeletePaymentMethod, SetDefaultPaymentMethod } from '../../data/payment/mutations';
import { PaymentMethodsData } from '../../api/apis/PaymentApi';
import { convertToTitleCase } from '../../helpers/utilityFunctions';

export function usePaymentMutations(paymentMethod: PaymentMethodsData | undefined) {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const setDefaultPaymentMethod = useMutation(new SetDefaultPaymentMethod());
  const removePaymentMethod = useMutation(new DeletePaymentMethod());

  useEffect(() => {
    if (setDefaultPaymentMethod.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(
        `${convertToTitleCase(paymentMethod?.type ?? '')} ending in ${
          paymentMethod?.lastDigits
        } set as default!`
      );
    }
  }, [setDefaultPaymentMethod.status]);

  useEffect(() => {
    if (removePaymentMethod.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage('Payment method removed!');
    }
  }, [removePaymentMethod.status]);

  return {
    setDefaultPaymentMethod,
    removePaymentMethod,
  };
}
