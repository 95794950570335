import { Api, Data } from '@3nickels/data-modules';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';

// gets legal docs in unauthenticated state
export function useTermsDocs() {
  const appStorage = useAppStorage();
  const termsDataCache =
    appStorage.retrieve<Data.TermsAndConditions.TermsAndConditionsCompartments>(
      Data.TermsAndConditions.termsAndConditionsStorageKey
    );
  return useObservable(termsDataCache.observe$<Api.TermsDoc[]>('docs'));
}
