/* tslint:disable */
import { Congrats, Review, CancellationPolicy } from '../sign-up';
import TermsAndConditions from '../sign-up/TermsAndConditions';
import PersonalInfo from '../sign-up/PersonalInfo';
import { RouteObject } from 'react-router';
import ProfileView from '../pages/profile/ProfileView';
import AccountSettingsLayout from '../pages/settings/AccountSettingsLayout';
import AccountSettingsView from '../pages/settings/AccountSettingsView';
import { ChangePlanWrapper } from '../pages/settings/manage-subscription/ChangePlanView';
import ManagePaymentsView from '../pages/settings/manage-payments/ManagePaymentsView';
import AlertsView from '../pages/settings/alerts/AlertsView';
import UpdatePasswordView from '../pages/settings/update-password/UpdatePasswordView';
import TwoFactorAuthView from '../pages/settings/two-factor-auth/TwoFactorAuthView';
import AddAuthMethodView from '../pages/settings/two-factor-auth/AddAuthMethodView';
import VerifyAuthMethodView from '../pages/settings/two-factor-auth/VerifyAuthMethodView';
import LegalView from '../pages/settings/legal/LegalView';
import FairUseNoticeView from '../pages/settings/legal/FairUseNoticeView';
import IconAuthorsView from '../pages/settings/icon-authors/IconAuthorsView';
import ContactUsView from '../pages/contact-us/ContactUsView';
import { AboutUsWrapper as AboutUsView } from '../pages/about-us/AboutUsView';
import { Navigate } from 'react-router-dom';
import { MobilePaymentsHistoryDetailsView } from '../pages/mobile-redirect/MobilePaymentsHistoryDetailsView';
import { ManageSubscriptionWrapper } from '../pages/settings/manage-subscription/ManageSubscriptionView';
import LmsLayout from '../pages/learning-management-system/LmsLayout';
import LmsCoursesView from '../pages/learning-management-system/LmsCoursesView';
import LmsCourseOverview from '../pages/learning-management-system/LmsCourseOverview';
import { LmsContentViewWrapper } from '../pages/learning-management-system/lessons/contents/LmsContentView';
import LmsQuizResultsView from '../pages/learning-management-system/quiz-results/LmsQuizResultsView';
import LmsQuizViewWrapper from '../pages/learning-management-system/quiz/LmsQuizViewWrapper';

export const commonRoutes: RouteObject[] = [
  {
    path: 'review',
    element: <Review />,
  },
  {
    path: 'personal-info',
    element: <PersonalInfo />,
  },
  {
    path: 'congrats',
    element: <Congrats />,
  },
  {
    path: 'accept-terms',
    element: <TermsAndConditions />,
  },
  {
    path: 'cancellation-policy',
    element: <CancellationPolicy />,
  },
  {
    path: 'profile',
    element: <ProfileView />,
  },
  {
    path: 'contact',
    element: <ContactUsView />,
  },
  {
    path: 'about',
    element: <AboutUsView />,
  },
  {
    path: 'settings',
    element: <AccountSettingsLayout />,
    children: [
      { index: true, element: <AccountSettingsView /> },
      {
        path: 'manage-subscription',
        children: [
          { index: true, element: <ManageSubscriptionWrapper /> },
          { path: 'change-plan', element: <ChangePlanWrapper /> },
        ],
      },
      {
        path: 'manage-payments',
        element: <ManagePaymentsView />,
      },
      {
        path: 'manage-payments/details',
        element: <MobilePaymentsHistoryDetailsView />,
      },
      {
        path: 'alerts',
        element: <AlertsView />,
      },
      {
        path: 'update-password',
        element: <UpdatePasswordView />,
      },
      {
        path: 'two-factor-authentication',
        children: [
          { index: true, element: <TwoFactorAuthView /> },
          { path: 'add', element: <AddAuthMethodView /> },
          { path: 'verify', element: <VerifyAuthMethodView /> },
        ],
      },
      {
        path: 'legal',
        children: [
          { index: true, element: <LegalView /> },
          { path: 'fair-use-notice', element: <FairUseNoticeView /> },
        ],
      },
      {
        path: 'icon-authors',
        element: <IconAuthorsView />,
      },
    ],
  },
  {
    path: 'learning',
    element: <LmsLayout />,
    children: [
      { index: true, element: <LmsCoursesView /> },
      {
        path: ':courseId',
        children: [
          { index: true, element: <LmsCourseOverview /> },
          {
            path: ':unitId',
            children: [
              { index: true, element: <Navigate to='..' replace /> },
              {
                path: ':lessonId',
                children: [
                  { path: 'content', element: <LmsContentViewWrapper /> },
                  {
                    path: ':quizId',
                    children: [
                      { path: ':questionId', element: <LmsQuizViewWrapper /> },
                      { path: 'results', element: <LmsQuizResultsView /> },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: <Navigate to='/' replace />,
  },
];
