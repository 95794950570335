import { theme } from '../../themes/ThemeWrapper';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function HexagonSvg(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns='http://www.w3.org/2000/svg'>
      <g fill='#fff'>
        <polygon
          style={{ stroke: theme.palette.primary.dark, strokeWidth: '5' }}
          points='300,150 225,280 75,280 0,150 75,20 225,20'
        />
      </g>
    </SvgIcon>
  );
}
