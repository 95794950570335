import { TableContainer, Table, TableBody, Grid, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonChurchResults: React.FC = () => {
  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody className='search-results-table-body'>
          <SkeletonChurch />
          <SkeletonChurch />
          <SkeletonChurch />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SkeletonChurch: React.FC = () => {
  return (
    <Grid className='search-results-row'>
      <Grid className='description-section'>
        <Skeleton className='church-name' variant='rectangular' height='30px' />
      </Grid>
    </Grid>
  );
};

export default SkeletonChurchResults;
