import { Grid, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { Colors } from '../../../../../../themes';
import React from 'react';

interface GoalCardSummaryProps {
  mainContent: React.ReactNode;
  subContent?: React.ReactNode;
  helpContent?: React.ReactNode;
}

const GoalCardSummary: React.FC<GoalCardSummaryProps> = ({
  mainContent,
  subContent,
  helpContent,
}) => {
  return (
    <Grid container className='goal-card-details-summary'>
      <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
        {mainContent}
      </Typography>
      <Grid display='flex' gap='5px'>
        <Typography variant='p16' color='primary'>
          {subContent}
        </Typography>
        {typeof helpContent !== 'undefined' && (
          <Tippy placement='right' content={helpContent} theme='light-nickels-theme'>
            <HelpOutline style={{ color: Colors.primaryBase }} />
          </Tippy>
        )}
      </Grid>
    </Grid>
  );
};

export default GoalCardSummary;
