import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InvestmentSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <g fillRule='nonzero'>
            <path
              d='M4.82 13.786h3.663c.725 0 1.313.587 1.313 1.313v6.03h-6.29V15.1c0-.726.587-1.313 1.313-1.313z'
              fill='#9A5238'
            />
            <path
              d='M13.217 9.587h3.664c.725 0 1.313.587 1.313 1.313v10.23h-6.29V10.9c0-.726.588-1.313 1.313-1.313z'
              fill='#1E2969'
            />
            <path
              d='M21.598 5.388h3.663c.726 0 1.314.587 1.314 1.313V21.13h-6.29V6.7c0-.725.587-1.312 1.313-1.312z'
              fill='#1B7690'
            />
            <path
              d='M2.452 23.48c0-.432.345-.795.795-.795H26.85c.432 0 .795.345.795.795a.793.793 0 0 1-.795.795H3.247a.804.804 0 0 1-.795-.795z'
              fill='#1E2969'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
