import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { campingBanner } from '../../assets/png';

const LifestyleBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container className='lifestyle-banner'>
      <Grid container flexDirection='column' className='left-grid' paddingLeft='80px'>
        <Typography variant='p30Bold' color='secondary'>
          {t('Lifestyle')}
        </Typography>
        <Typography mt={1} variant='p18' color='#ffffff'>
          {t('TailoredToYourNeeds')}
        </Typography>
      </Grid>
      <img src={campingBanner} alt='Lifestyle Banner' />
      <div className='gap' />
      <div className='gradient' />
    </Grid>
  );
};

export default LifestyleBanner;
