import { SvgIcon, SvgIconProps } from '@mui/material';

export function SettingsSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fill-rule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <g fill={props.fill} fill-rule='nonzero'>
          <path d='m22.824 10.033-1.666-.362a9.063 9.063 0 0 0-.536-1.292l.922-1.434a.648.648 0 0 0-.086-.808l-2.595-2.594a.648.648 0 0 0-.808-.087l-1.434.922a9.063 9.063 0 0 0-1.292-.536l-.362-1.666a.647.647 0 0 0-.633-.51h-3.668a.647.647 0 0 0-.633.51l-.362 1.666a9.063 9.063 0 0 0-1.292.536l-1.434-.922a.648.648 0 0 0-.808.087L3.543 6.137a.648.648 0 0 0-.087.808l.922 1.434a9.063 9.063 0 0 0-.536 1.292l-1.666.362a.648.648 0 0 0-.51.633v3.668c0 .305.213.568.51.633l1.666.362c.145.443.324.876.536 1.292l-.922 1.434a.648.648 0 0 0 .087.808l2.594 2.595a.648.648 0 0 0 .808.086l1.434-.922c.416.212.849.391 1.292.536l.362 1.666c.065.297.328.51.633.51h3.668a.647.647 0 0 0 .633-.51l.362-1.666a9.063 9.063 0 0 0 1.292-.536l1.434.922a.647.647 0 0 0 .808-.086l2.595-2.595a.648.648 0 0 0 .086-.808l-.922-1.434c.212-.416.391-.849.536-1.292l1.666-.362a.648.648 0 0 0 .51-.633v-3.668a.648.648 0 0 0-.51-.633zm-6.44 2.467a3.889 3.889 0 0 1-3.884 3.885A3.889 3.889 0 0 1 8.616 12.5 3.889 3.889 0 0 1 12.5 8.616a3.889 3.889 0 0 1 3.885 3.884z' />
        </g>
      </g>
    </SvgIcon>
  );
}
