import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogActions, Grid } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { Button } from '../../../components/buttons/Button';
import { resolveShowManagePayments } from '../AccountSettingsView';
import UnsubscribeModal from './UnsubscribeModal';
import { useDeviceType } from '../../../hooks/useDeviceType';

const getPrimaryButtonDetails = (
  plan: Domain.PricebookBundle,
  subscription: Domain.SubscriptionInfo | undefined,
  orgPaysPricebook: Domain.PricebookBundle | undefined,
  inAdviceFreeTrial: boolean,
  paymentApi: PaymentApi,
  multipleProducts: boolean,
  orgOnlyOffersFreePricebook: boolean,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const navigate = useNavigate();
  const resubscribe =
    subscription?.status !== 'active' && !multipleProducts && !orgOnlyOffersFreePricebook;

  if (multipleProducts) {
    return {
      text: 'Change Plan',
      onClick: () => navigate('/settings/manage-subscription/change-plan'),
    };
  }

  if (subscription?.status === 'active' && !orgPaysPricebook) {
    return { text: 'Unsubscribe', onClick: () => setModalOpen(true) };
  }

  if ((resubscribe || inAdviceFreeTrial) && !orgPaysPricebook) {
    return {
      text: 'Subscribe',
      onClick: async () => {
        const stripeUrl = await paymentApi.createSession(plan.pricebook.id);
        window.location.replace(stripeUrl.data);
      },
    };
  }
};

interface PlanActionGroupButtonsProps {
  plan: Domain.PricebookBundle;
  subscriptions: Domain.SubscriptionInfo[] | undefined;
  threeNickelsProducts: Domain.PricebookBundle[] | undefined;
  orgPaysPricebook: Domain.PricebookBundle | undefined;
  inAdviceFreeTrial: boolean;
  paymentApi: PaymentApi;
  multipleProducts: boolean;
  orgOnlyOffersFreePricebook: boolean;
}

const PlanActionGroupButtons: React.FC<PlanActionGroupButtonsProps> = ({
  plan,
  subscriptions,
  threeNickelsProducts,
  orgPaysPricebook,
  inAdviceFreeTrial,
  paymentApi,
  multipleProducts,
  orgOnlyOffersFreePricebook,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptions) return [];
    return subscriptions.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptions]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const addOns = false;
  const primaryButton = getPrimaryButtonDetails(
    plan,
    subscription,
    orgPaysPricebook,
    inAdviceFreeTrial,
    paymentApi,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    setModalOpen
  );
  const showManagePayments = resolveShowManagePayments(
    orgPaysPricebook,
    addOns,
    orgOnlyOffersFreePricebook,
    subscriptions
  );

  const onManagePayments = () => navigate('/settings/manage-payments');

  return (
    <>
      {subscription && (
        <UnsubscribeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          subscription={subscription}
          threeNickelsProducts={threeNickelsProducts}
        />
      )}
      <Grid container mt={mobile ? 1.5 : primaryButton || showManagePayments ? 3 : -1}>
        <DialogActions
          sx={{
            display: mobile ? 'flex' : undefined,
            flexDirection: mobile ? 'column' : undefined,
            width: mobile ? '100%' : undefined,
          }}
          className='browser-dialog'>
          {primaryButton && (
            <Button
              color='primary'
              fullWidth={mobile}
              variant={primaryButton?.text === 'Subscribe' ? undefined : 'outlined'}
              label={primaryButton.text}
              onClick={primaryButton.onClick}
            />
          )}
          {showManagePayments && (
            <Button
              color='primary'
              fullWidth={mobile}
              variant={primaryButton ? undefined : 'outlined'}
              className={primaryButton ? 'ghostAlt' : undefined}
              label='ManagePayments'
              onClick={onManagePayments}
            />
          )}
        </DialogActions>
      </Grid>
    </>
  );
};

export default PlanActionGroupButtons;
