import { FC, ReactNode, useEffect } from 'react';

type Props = {
  children?: ReactNode;
};

// Checks if the submit button is within a Form, if so we can ignore that submit
// This code was meant for those outside of forms preventing submit from working on enter
const SubmitInsideForm = (button: HTMLButtonElement): boolean => {
  let element: HTMLElement | null = button;
  while (element) {
    element = element.parentElement;
    if (element?.tagName?.toLocaleLowerCase() === 'form') return true;
  }

  return false;
};

export const SubmitOnEnter: FC<Props> = ({ children }) => {
  const handleUserKeyPress = (event: KeyboardEvent) => {
    const { key } = event;
    if (key === 'Enter') {
      const trigger = document.querySelector('button[type=submit]') as HTMLButtonElement;
      if (trigger && !trigger.hasAttribute('data-ignoreEnter') && !SubmitInsideForm(trigger)) {
        console.dir(trigger.parentElement);
        trigger.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  return <>{children}</>;
};
