import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const MortgageRatesCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomPercent />}
      label={t('MortgageRates')}
      content={t('CompareTheRatesOfLenders')}
    />
  );
};

export default MortgageRatesCard;
