import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export type NotificationMessage = {
  id: string;
  message: string;
};

export type AlertMenuProps = {
  notifications: NotificationMessage[];
  anchorEl?: HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
};

export const AlertMenu: React.FC<AlertMenuProps> = ({ notifications, anchorEl, setAnchorEl }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {notifications.map((n: NotificationMessage) => (
        <MenuItem key={n.id} onClick={handleClose}>
          {n.message}
        </MenuItem>
      ))}
      {notifications.length === 0 && <MenuItem disabled>{t('NoAlerts')}</MenuItem>}
    </Menu>
  );
};

AlertMenu.defaultProps = {
  notifications: [],
};
