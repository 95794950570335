import { Grid, Typography } from '@mui/material';
import { HexGridContainer, HexGridItemContainer } from '../themes/mui';
import React, { useMemo } from 'react';

export interface HexGridItem<T> {
  icon: React.ReactNode;
  label: string;
  item: T;
}

interface GridRowProps<T> {
  items: Array<HexGridItem<T>>;
  onClick?: (entity: T) => void;
  keyFn: (entity: T) => string;
}

export declare type HexGridRowProps<T> = GridRowProps<T> & {
  key: string;
  nested: boolean;
};

function HexGridRow<T>(props: HexGridRowProps<T>) {
  return (
    <Grid container>
      <Typography component='ul' sx={HexGridContainer} className={props.nested ? 'nested' : ''}>
        {props.items.map((x) => (
          <span
            className='content-wrapper'
            key={props.keyFn(x.item)}
            onClick={() => (props.onClick ? props.onClick(x.item) : undefined)}>
            <Typography component='li' sx={HexGridItemContainer}>
              <div className='content'>
                <Typography className='icon' justifyContent='center'>
                  {x.icon}
                </Typography>
                <Typography className='text' variant='p16' justifyContent='center' color='primary'>
                  {x.label}
                </Typography>
              </div>
            </Typography>
          </span>
        ))}
      </Typography>
    </Grid>
  );
}

export declare type HexGridProps<T> = GridRowProps<T> & {
  itemsPerRow: number;
};

function HexGrid<T>(props: HexGridProps<T>) {
  // We need options to control:
  //  a) The number of items per row
  //  b) Something to calculate the top margin?
  const { itemsPerRow } = props;
  const rowProps: HexGridRowProps<T>[] = useMemo(() => {
    const rows: HexGridRowProps<T>[] = [];
    const { items, ...itemProps } = props;
    const nrRows = Math.round(props.items.length / itemsPerRow);
    for (let i = 0; i < nrRows; i++) {
      const start = i * itemsPerRow;
      const end = start + itemsPerRow;
      rows.push({
        ...itemProps,
        items: items.slice(start, end),
        key: `chunk-${i}`,
        nested: i !== 0,
      });
    }
    return rows;
  }, [props.items, itemsPerRow]);

  return (
    <>
      {rowProps.map(({ key, ...hexProps }) => (
        <HexGridRow key={key} {...hexProps} />
      ))}
    </>
  );
}

export default HexGrid;
