import { Api } from '@3nickels/data-modules';
import { useListener } from '@aesop-fables/scrinium';
import { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { UserEventListener, useUserEventService } from '../services/userEvents';

export const UserEventLogger = () => {
  const location = useLocation();
  useListener(UserEventListener);
  const userEventService = useUserEventService();

  useEffect(() => {
    userEventService.logUserEvent({
      event: Api.UserEventsEnum.NAVIGATION,
      route: location.pathname,
      timestamp: new Date(),
    });
  }, [location]);

  return <Outlet />;
};
