import React from 'react';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { formatWholeDollars } from '@3nickels/data-modules';
import { KeyValuePair } from '../../../../components';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import { Colors } from '../../../../themes';

interface OrgIncomeStatementProps {
  totalRevenue: number;
  totalExpenses: number;
  fundraisingExpenses: number;
}
const OrgIncomeStatement: React.FC<OrgIncomeStatementProps> = ({
  totalRevenue,
  totalExpenses,
  fundraisingExpenses,
}) => {
  const fundraisingExpensesPercent =
    totalExpenses !== 0 ? (fundraisingExpenses / totalExpenses) * 100 : 0;

  return (
    <Grid container direction='column'>
      <Typography mb={1} variant='p22Bold' color='primary'>
        {t('IncomeStatement')}
      </Typography>
      <Grid container style={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item sm={5.75} style={{ display: 'flex', flexDirection: 'column' }}>
          <Card className='income-card' style={{ marginBottom: '8px' }}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <Typography variant='p16' color='secondary'>
                {t('TotalRevenue')}
              </Typography>
              <Typography variant='p30Bold' color='secondary'>
                {formatWholeDollars(totalRevenue)}
              </Typography>
            </Grid>
          </Card>
          <Card className='income-card'>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <Typography variant='p16' color='secondary'>
                {t('TotalExpenses')}
              </Typography>
              <Typography variant='p30Bold' color='error'>
                {formatWholeDollars(totalExpenses * -1)}
              </Typography>
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={0.5} />
        <Grid item sm={5.75} style={{ display: 'flex' }}>
          <Card className='income-card' style={{ width: '100%' }}>
            <Grid container direction='column' rowGap='10px'>
              <Typography variant='p16Bold' color='secondary'>
                {t('ExpensesBreakdown')}
              </Typography>
              <KeyValuePair
                label='FundraisingExpenses'
                value={`(${formatPercent(fundraisingExpensesPercent)}) ${formatWholeDollars(
                  fundraisingExpenses
                )}`}
              />
              <KeyValuePair
                label='OtherExpenses'
                value={`(${formatPercent(100 - fundraisingExpensesPercent)}) ${formatWholeDollars(
                  totalExpenses - fundraisingExpenses
                )}`}
              />
              <Divider sx={{ borderColor: Colors.primaryLight }} />
              <KeyValuePair label='TotalExpenses' value={formatWholeDollars(totalExpenses)} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrgIncomeStatement;
