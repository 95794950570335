import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface Props extends React.PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  sortDirection?: boolean;
}

const Caret: React.FC<Props> = (props) => {
  return (
    <ArrowDropUpIcon
      style={{
        transform: props.sortDirection ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: '0.2s',
      }}
    />
  );
};

const CaretPlaceholder: React.FC<Props> = () => {
  return (
    <div
      style={{
        margin: '12px',
      }}></div>
  );
};

export { Caret, CaretPlaceholder };
