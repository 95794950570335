import { Box, Grid, Stack } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { GivingProbablyMore } from './GivingProbablyMore';
import { GivingIsMoreThanMoney } from './GivingIsMoreThanMoney';
import { ThingsToConsider } from './ThingsToConsider';

const IntroToGivingView: React.FC = () => {
  return (
    <Box>
      <Grid container flexDirection='column'>
        <Stack spacing={6}>
          <GivingProbablyMore />
          <GivingIsMoreThanMoney />
          <ThingsToConsider />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(IntroToGivingView, meta);
