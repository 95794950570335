import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const StepFive: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={5}
      headerIcon={<Svgs.ArtApproved />}
      headerText='FinalDetails'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('BeforeMakingYourFinalDecision')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('PaymentDetailsAreTherePenalties'),
              t('AprIsTheTotalCost'),
              t('CreditorsIfYoureUsingALoan'),
            ]}
          />
          <Typography color='secondary' variant='p16'>
            {t('UponChoosingALender')}
          </Typography>
        </Stack>
      }
    />
  );
};

export default StepFive;
