/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryVoronoiContainer } from 'victory';
import { Chart, Spacing } from '../themes';
interface ChartLineProps {
  height: number;
  width?: number;
  formatY: (y: number) => string;
  data: ChartLineSeries[];
  yAxisLabel?: string;
  onClick?: (pt: { x: number; y: number }) => void;
  yLines?: ChartYLinesData[];
  mouseLeftChart?: boolean;
  setMouseLeftChart?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ChartLineSeries {
  color: string;
  data: ChartLineData[];
}

export interface ChartLineData {
  x: number;
  y: number;
}

type YLineType = 'solid' | 'dashed';

export interface ChartYLinesData {
  x: number;
  color: string;
  type?: YLineType;
}

export const ChartLine: React.FunctionComponent<ChartLineProps> = (props) => {
  const { height, width, formatY, data, yAxisLabel, yLines, mouseLeftChart, setMouseLeftChart } =
    props;
  const chartHeight = height + Spacing.md;
  const yVerticalLine = useMemo(() => getMaxYValue(data), [data]);
  const tickValues = useMemo(() => getTickValues(data), [data]);

  const maxYValue = getMaxYValue(data);
  const formatMaxvalue = formatY(maxYValue);
  const yLabelsPadding = (Math.ceil(formatMaxvalue.length / 3) + 3) * 12;

  const chartPadding = yAxisLabel ? yLabelsPadding + 10 : yLabelsPadding;

  const onActivated = (pts: any[]) => {
    if (pts.length > 0 && props.onClick) {
      props.onClick({ x: pts[0].x, y: pts[0].y });
    }
  };

  return (
    <VictoryChart
      width={Math.max(100, (width ?? 300) + Spacing.sm)}
      height={chartHeight}
      theme={Chart.theme}
      padding={{ top: Spacing.xl, bottom: Spacing.xl, left: chartPadding, right: chartPadding / 2 }}
      containerComponent={
        <VictoryVoronoiContainer
          labels={({ datum }) => (mouseLeftChart ? '' : `${formatY(datum.y)}`)}
          voronoiBlacklist={['line-y']}
          onActivated={onActivated}
        />
      }
      events={
        typeof setMouseLeftChart !== 'undefined'
          ? [
              {
                target: 'parent',
                eventHandlers: {
                  onMouseEnter: () => {
                    if (setMouseLeftChart) {
                      setMouseLeftChart(false);
                      return [];
                    }
                  },
                  onMouseLeave: () => {
                    if (setMouseLeftChart) {
                      setMouseLeftChart(true);
                      return [];
                    }
                  },
                },
              },
            ]
          : []
      }>
      <VictoryAxis dependentAxis tickFormat={(y) => formatY(y)} />
      {yAxisLabel && (
        <VictoryAxis
          label={yAxisLabel}
          dependentAxis
          tickFormat={() => ``}
          offsetX={20}
          axisComponent={<></>}
          tickComponent={<></>}
          style={{ grid: { stroke: 0 } }}
        />
      )}
      <VictoryAxis
        animate={false}
        tickValues={tickValues}
        tickFormat={(datum: any) => `${datum}`}
        style={{ grid: { stroke: 0 } }}
      />
      {data.map((s, i) => (
        <VictoryLine key={i.toString()} data={s.data} style={{ data: { stroke: s.color } }} />
      ))}
      {typeof yVerticalLine !== 'undefined' &&
        yLines?.map((s, i) => (
          <VictoryLine
            key={i.toString()}
            name='line-y'
            animate={false}
            data={[
              { x: s.x, y: 0 },
              { x: s.x, y: yVerticalLine },
            ]}
            style={{
              data: { stroke: s.color, strokeDasharray: getYLinesType(s.type) },
            }}
          />
        ))}
    </VictoryChart>
  );
};

export function getYLinesType(type?: YLineType): string | number {
  if (type === 'solid') return 0;
  if (type === 'dashed') return '5 5';

  return 0;
}

export function getMaxYValue(arr: ChartLineSeries[]): number {
  return Math.max(...arr.reduce((acc: number[], x) => acc.concat(x.data.map((s) => s.y)), []));
}

export function getTickValues(arr: ChartLineSeries[]): number[] | undefined {
  if (arr.length > 0 && arr[0].data.length > 4) return undefined;

  return arr[0].data.map((s) => s.x);
}
