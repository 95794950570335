/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import VolunteeringGoalForm from './VolunteeringGoalForm';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { GoalFormTitle } from '../../../../components/FormTitle';
import { AxiosError } from 'axios';

const formSchema = Yup.object({
  nameOfPlace: Yup.string().required('Name of Place is required'),
  typeOfPlace: Yup.string().required('Type of Place is required'),
  volunteeringFrequency: Yup.string().required('Required'),
  numberOfHours: Yup.string()
    .required('Number of Hours is required')
    .test({
      name: 'numberOfHours',
      // arrow function cannot access parent
      test: function (numberOfHours, { createError, path, parent }) {
        const { volunteeringFrequency } = parent;
        const hours = cleanWholeNumberStr(numberOfHours);
        if (hours === 0) {
          return createError({
            message: 'Number of Hours is required',
            path,
          });
        } else if (
          volunteeringFrequency === Domain.VolunteeringFrequencyEnum.Weekly &&
          hours > 80
        ) {
          return createError({
            message: 'Maximum: 80 hours',
            path,
          });
        } else if (
          volunteeringFrequency === Domain.VolunteeringFrequencyEnum.Monthly &&
          hours > 300
        ) {
          return createError({
            message: 'Maximum: 300 hours',
            path,
          });
        }
        return true;
      },
    }),
});

export const VolunteeringGoalEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useVolunteeringGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <VolunteeringGoalEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface VolunteeringGoalEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.VolunteeringGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.VolunteeringGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const VolunteeringGoalEditView: React.FC<VolunteeringGoalEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Goals.VolunteeringGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setError } = methods;
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }
    } catch (err) {
      const error = err as AxiosError;
      const message = (error.response?.data as AxiosError).message;
      if (error?.response?.status === 500 && message.includes('UNIQUE KEY')) {
        setError('nameOfPlace', {
          type: 'server',
          message: 'Please enter a unique name.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle type={Domain.GoalTypeEnum.Volunteer} />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={10}>
                  <VolunteeringGoalForm model={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color='primary'
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Save', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

export default VolunteeringGoalEditViewWrapper;
