import { PropsWithChildren, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useControlledAnimation } from '../hooks/useControlledAnimation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AnimatedTransitionProps extends PropsWithChildren {}

// very quick animation so user doesn't even notice
const notAnimated = { opacity: 1, y: 0, transition: { duration: 0.001 } };
const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const AnimatedTransition: React.FC<AnimatedTransitionProps> = ({ children }) => {
  const location = useLocation();
  const { controls } = useControlledAnimation();

  useEffect(() => {
    // use notAnimated by default
    controls.start('notAnimated');
  }, [location.pathname]);

  return (
    <motion.div
      key={location.pathname}
      animate={controls}
      initial={{ opacity: 0 }}
      variants={{
        notAnimated,
        visible,
      }}>
      {children}
    </motion.div>
  );
};

export default AnimatedTransition;
