import { Grid, Typography } from '@mui/material';
import { books } from '../../assets/png';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { useNextLessonByCourseId, useNextUnitByCourseId } from './LmsHooks';
import { LmsMedia } from './LmsMedia';
import { t } from 'i18next';
import GlowButton from '../../components/GlowButton';

export interface LmsNextLessonCardProps {
  course?: Domain.CourseDto;
}

const LmsNextLessonCard: React.FC<LmsNextLessonCardProps> = ({ course }) => {
  const navigate = useNavigate();
  const nextUnit = useNextUnitByCourseId(course?.courseId ?? 0);
  const nextLesson = useNextLessonByCourseId(course?.courseId ?? 0);
  const unitNumber = useMemo(
    () => (course?.units?.findIndex((x) => x.unitId === nextUnit?.unitId) ?? 0) + 1,
    [course, nextUnit]
  );

  const startLesson = () => {
    if (nextUnit && nextLesson) {
      navigate(`${nextUnit.unitId}/${nextLesson.lessonId}/content`);
    }
  };

  return (
    <Grid container className='lms-next-lesson-card'>
      <LmsNextLessonContent unitNumber={unitNumber} nextUnit={nextUnit} nextLesson={nextLesson} />
      <Grid className='end-adornment-wrapper'>
        <Grid className='gradient' />
        <Grid className='start-lesson-banner'>
          <GlowButton className='start-lesson-button' onClick={startLesson}>
            {t('Start')}
            <Svgs.IconArrowRightWhite />
          </GlowButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface LmsNextLessonContentProps {
  unitNumber?: number;
  nextUnit?: Domain.UnitDto;
  nextLesson?: Domain.LessonDto;
}

export const LmsNextLessonContent: React.FC<LmsNextLessonContentProps> = ({
  unitNumber,
  nextUnit,
  nextLesson,
}) => {
  return (
    <Grid minWidth='300px'>
      <Grid className='left-grid' paddingLeft='30px'>
        {typeof nextUnit?.icon?.id !== 'undefined' ? (
          <LmsMedia title={nextUnit.name ?? ''} {...nextUnit.icon} style={{ width: '100px' }} />
        ) : (
          <img src={nextUnit && nextLesson ? books : undefined} width='100px' />
        )}
      </Grid>
      <Grid
        className='course-details'
        paddingX='30px'
        sx={{
          minWidth: 0,
          overflow: 'hidden',
        }}>
        <Typography variant='p12' color='secondary' fontWeight='bold'>
          {t('NextUp')}
        </Typography>
        <Typography
          variant='p30Bold'
          color='primary'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
          }}>
          {nextLesson?.name}
        </Typography>
        <Typography
          variant='p16'
          color='secondary'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
          }}>
          Unit {unitNumber}: {nextUnit?.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LmsNextLessonCard;
