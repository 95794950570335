/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../hooks/useAutoSaveHandler';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { BudgetSectionProps, IncomeFormData, mapIncomeFormToIncomeRest } from '../Types';
import { useIncomeSchema } from '../BudgetHooks';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import EmbeddedFormInput from '../../../components/EmbeddedFormInput';
import { Grid } from '@mui/material';
import _ from 'lodash';

export declare type IncomeFormProps = BudgetSectionProps & {
  defaultValues: IncomeFormData;
  incomeType: 'earned' | 'unearned';
};

export const IncomeForm: React.FC<IncomeFormProps> = ({ defaultValues, mode, incomeType }) => {
  const { formState } = useFormContext<IncomeFormData>();
  const schema = useIncomeSchema();
  const { person } = Hooks.useCombinedSelfData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): IncomeFormData => {
    const annualIncome = cleanWholeNumberStr(data.annualIncome ?? '', { max });
    const otherAnnualIncome = cleanWholeNumberStr(data.otherAnnualIncome ?? '', { max });
    const spouseAnnualIncome = cleanWholeNumberStr(data.spouseAnnualIncome ?? '', { max });
    const spouseAnnualOtherIncome = cleanWholeNumberStr(data.spouseAnnualOtherIncome ?? '', {
      max,
    });
    const unearnedIncome = cleanWholeNumberStr(data.unearnedIncome ?? '', { max });

    return {
      annualIncome,
      otherAnnualIncome,
      spouseAnnualIncome,
      spouseAnnualOtherIncome,
      unearnedIncome,
    };
  };

  const onSave = useCallback(
    async (data: any) => {
      const params = mapIncomeFormToIncomeRest(mode, person as Domain.SelfData, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Income.Commands.UpdateIncome, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial', // PATCH
    onSave,
    schema,
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        {incomeType === 'earned' && (
          <>
            <EmbeddedFormInput<IncomeFormData>
              label='MyIncome'
              placeholder='$0'
              autoComplete='off'
              name='annualIncome'
              type='dollar'
              max={max}
              defaultValue={defaultValues.annualIncome}
              error={formState.errors.annualIncome !== undefined}
              helperText={formState.errors.annualIncome?.message?.toString()}
            />
            <EmbeddedFormInput<IncomeFormData>
              label='MyOtherEarnedIncome'
              placeholder='$0'
              autoComplete='off'
              name='otherAnnualIncome'
              type='dollar'
              max={max}
              defaultValue={defaultValues.otherAnnualIncome}
              error={formState.errors.otherAnnualIncome !== undefined}
              helperText={formState.errors.otherAnnualIncome?.message?.toString()}
            />

            {includeSpouse && (
              <>
                <EmbeddedFormInput<IncomeFormData>
                  label="Spouse'sIncome"
                  placeholder='$0'
                  autoComplete='off'
                  name='spouseAnnualIncome'
                  type='dollar'
                  max={max}
                  defaultValue={defaultValues.spouseAnnualIncome}
                  error={formState.errors.spouseAnnualIncome !== undefined}
                  helperText={formState.errors.spouseAnnualIncome?.message?.toString()}
                />
                <EmbeddedFormInput<IncomeFormData>
                  label="Spouse'sOtherEarnedIncome"
                  placeholder='$0'
                  autoComplete='off'
                  name='spouseAnnualOtherIncome'
                  type='dollar'
                  max={max}
                  defaultValue={defaultValues.spouseAnnualOtherIncome}
                  error={formState.errors.spouseAnnualOtherIncome !== undefined}
                  helperText={formState.errors.spouseAnnualOtherIncome?.message?.toString()}
                />
              </>
            )}
          </>
        )}
        {incomeType === 'unearned' && (
          <EmbeddedFormInput<IncomeFormData>
            label='OtherUnearnedIncome'
            placeholder='$0'
            autoComplete='off'
            name='unearnedIncome'
            type='dollar'
            max={max}
            defaultValue={defaultValues.unearnedIncome}
            error={formState.errors.unearnedIncome !== undefined}
            helperText={formState.errors.unearnedIncome?.message?.toString()}
          />
        )}
      </Grid>
    </form>
  );
};

export const UnearnedIncomeForm: React.FC<IncomeFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<IncomeFormData>();
  const schema = useIncomeSchema();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }
  }, [mode]);

  const formatInputs = (data: any): IncomeFormData => {
    const unearnedIncome = cleanWholeNumberStr(data.unearnedIncome ?? '');
    return { unearnedIncome };
  };

  const saveHandler = useCallback(
    async (data: any) => {
      const formData = formatInputs(data);
      const params = mapIncomeFormToIncomeRest(mode, person as Domain.SelfData, formData);
      await commands.execute(Data.Income.Commands.UpdateIncome, params);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    mode: 'partial',
    schema: schema,
    onSave: saveHandler,
  });

  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <EmbeddedFormInput<IncomeFormData>
          label='OtherUnearnedIncome'
          placeholder='$0'
          autoComplete='off'
          name='unearnedIncome'
          type='dollar'
          max={max}
          defaultValue={defaultValues.unearnedIncome}
          error={formState.errors.unearnedIncome !== undefined}
          helperText={formState.errors.unearnedIncome?.message?.toString()}
        />
      </Grid>
    </form>
  );
};
