import { Card } from '@mui/material';

interface ContentCopyProps {
  copy: string | undefined;
}

const ContentCopy: React.FC<ContentCopyProps> = ({ copy }) => {
  return (
    <Card className='lms-content-card'>
      <div dangerouslySetInnerHTML={{ __html: copy ?? '' }} />
    </Card>
  );
};

export default ContentCopy;
