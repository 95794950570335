import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../../../themes';
import { KeyValuePair, Spacer } from '../../../../components';
import { Api, Domain } from '@3nickels/data-modules';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import InfoCard from '../../../../components/InfoCard';
import { useGoalEditor } from '../../../goals/useGoalEditor';

type CostOfCollegeResultsProps = {
  results: Api.CollegeCostRest | undefined;
  noTuition: boolean;
};

const CostOfCollegeResults: React.FC<CostOfCollegeResultsProps> = ({ results, noTuition }) => {
  const { t } = useTranslation();
  const editGoal = useGoalEditor();

  const formatCostValue = (
    value: number | undefined,
    noTuition: boolean,
    results: Api.CollegeCostRest | undefined
  ) => {
    if (!results || noTuition) return '– – –';
    return formatWholeDollars(value ?? 0);
  };

  const onInfoCardClick = () => {
    editGoal(Domain.GoalTypeEnum.College);
  };

  return (
    <Grid container mt={2} direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography variant='p16Bold' color='secondary'>
            {t(`CostBreakdown`)}
          </Typography>
          <KeyValuePair
            label='TuitionAndFees'
            value={formatCostValue(results?.tuition, noTuition, results)}
          />
          <KeyValuePair
            label='LivingCost'
            value={formatCostValue(results?.livingCost, noTuition, results)}
          />
          <Divider sx={{ borderColor: Colors.primaryLight }} />
          <KeyValuePair
            label='TotalAnnualCost'
            value={formatCostValue(results?.totalAnnualCost, noTuition, results)}
          />
        </Stack>
        <Spacer height='sm' />
        {typeof results !== 'undefined' && (
          <InfoCard onClick={onInfoCardClick}>
            <Typography variant='p16' color='secondary'>
              {t('ReadyToStartSavingForCollege')}
            </Typography>
          </InfoCard>
        )}
      </Grid>
    </Grid>
  );
};

export default CostOfCollegeResults;
