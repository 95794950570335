import React from 'react';
import { Grid } from '@mui/material';
import { universitySchool } from '../../assets/png';

const UniversitySchoolPng: React.FC = () => {
  return (
    <Grid item container alignItems='flex-end' justifyContent='center' flex={1}>
      <img
        src={universitySchool}
        alt='University School'
        style={{ maxHeight: '130px', maxWidth: '230px' }}
      />
    </Grid>
  );
};

export default UniversitySchoolPng;
