import { Domain } from '@3nickels/data-modules';
import { mapDebtEnumToTypePath } from './pages/account-details/debt/DebtUtils';

export function cashAccountSummary(id: number) {
  return `/account-details/cash-account/summary/${id}`;
}

export function debtSummary(id: number, type: Domain.DebtTypeEnum) {
  return `/account-details/debt/summary/${mapDebtEnumToTypePath(type)}/${id}`;
}

export function homeSummary(id: number) {
  return `/account-details/home/summary/${id}`;
}

export function hsaSummary(id: number) {
  return `/account-details/health-savings-account/summary/${id}`;
}

export function brokerageSummary(id: number) {
  return `/account-details/brokerage-account/summary/${id}`;
}

export function retirementSummary(id: number, type: Domain.PlanTypeEnum) {
  const wizardPlanType =
    Domain.planTypeIdToAccountKey[type as keyof typeof Domain.planTypeIdToAccountKey];
  return `/account-details/retirement-savings-plan/summary/${wizardPlanType}/${id}`;
}
