import { MenuItem, Grid, Card, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Data, Domain, formatDateForApi, Hooks } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import FormContent from '../../../../components/form/FormContent';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Button } from '../../../../components/buttons/Button';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { Spacer } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import SliderInputTypeToggle from '../../../../components/SliderInputTypeToggle';
import TextInput from '../../../../components/form/TextInput';
import { formatForDisplay } from '../../../../components/form/DateInput';

export type MortgageRefinanceCalculatorFormProps = {
  results: Domain.MortgageRefinanceResultData | undefined;
  mortgages: Domain.IDebt[];
  formMethods: UseFormReturn;
  mortgageRefinanceFormData?: Domain.MortgageRefinanceFormData;
  selectedMortgage?: Domain.IDebt;
  setSelectedMortgage: Dispatch<SetStateAction<Domain.IDebt | undefined>>;
  resetResults: () => void;
  onSubmit: (values: Domain.MortgageRefinanceFormData) => void;
};

const MortgageRefinanceCalculatorForm: React.FC<MortgageRefinanceCalculatorFormProps> = ({
  results,
  mortgages,
  formMethods,
  mortgageRefinanceFormData,
  selectedMortgage,
  setSelectedMortgage,
  resetResults,
  onSubmit,
}) => {
  const { setLoading } = useLoading();
  const { formState, watch, handleSubmit, setValue, getValues } = formMethods;
  const [closingCostPercent, setClosingCostPercent] = useState(true);
  const commands = Hooks.useCommands();

  useEffect(() => {
    const index = mortgages.findIndex(
      (mortgage) => mortgage.id === mortgageRefinanceFormData?.debtId
    );
    if (index !== -1) {
      setValue('debtId', mortgages[index].id);
    } else if (mortgages.length === 1) {
      setValue('debtId', mortgages[0].id);
    }
  }, [mortgages, mortgageRefinanceFormData?.debtId]);

  useEffect(() => {
    if (typeof mortgageRefinanceFormData?.newLoanTermYears === 'undefined') {
      setValue('newLoanTermYears', Domain.MortgageTermTypeEnum['30-Year Fixed']);
    } else {
      setValue('newLoanTermYears', mortgageRefinanceFormData.newLoanTermYears);
    }
  }, [mortgageRefinanceFormData?.newLoanTermYears]);

  // update closingCostDlr
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (
        typeof selectedMortgage?.amountOwed !== 'undefined' &&
        closingCostPercent &&
        name === 'closingCostPct'
      ) {
        const loanBalance = selectedMortgage?.amountOwed;
        const closingCostPercent = cleanWholeNumberStr(values.closingCostPct);
        const closingCostDollar = (loanBalance * closingCostPercent) / 100;
        setValue('closingCostDlr', closingCostDollar);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, selectedMortgage, closingCostPercent]);

  // update closingCostPct
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (
        typeof selectedMortgage?.amountOwed !== 'undefined' &&
        !closingCostPercent &&
        name === 'closingCostDlr'
      ) {
        const loanBalance = selectedMortgage.amountOwed;
        if (loanBalance > 0) {
          const closingCostDollar = cleanWholeNumberStr(values.closingCostDlr);
          const closingCostPercent = (closingCostDollar / loanBalance) * 100;
          if (closingCostDollar > loanBalance) {
            setValue('closingCostDlr', loanBalance);
          }
          setValue('closingCostPct', closingCostPercent);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, selectedMortgage, closingCostPercent]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'debtId') {
        const id = values.debtId;
        const mortgage = mortgages.find((x) => x.id === id);
        if (typeof mortgage !== 'undefined' && mortgage.id !== selectedMortgage?.id) {
          if (typeof mortgage.originationDate !== 'undefined') {
            setValue(
              'originationDate',
              formatForDisplay((mortgage?.originationDate as unknown as string) ?? '')
            );
          }
          setSelectedMortgage(mortgage);
          resetResults();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, mortgages, selectedMortgage]);

  const saveOriginationDateOnSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) => {
      try {
        setLoading(true);
        const originationDate = formatDateForApi(values.originationDate);
        if (originationDate !== (selectedMortgage?.originationDate as unknown as string)) {
          const updatedMortgage = await commands.execute(Data.Debt.Commands.SaveDebtCommand, {
            id: selectedMortgage?.id ?? 0,
            originationDate: originationDate as unknown as Date,
          });
          setSelectedMortgage(updatedMortgage);
        }
      } finally {
        onSubmit(values);
        setLoading(false);
      }
    },
    [selectedMortgage]
  );

  const toggleClosingCosts = () => {
    setClosingCostPercent((prev) => {
      if (typeof selectedMortgage?.amountOwed !== 'undefined') {
        const values = getValues();
        if (prev) {
          const loanBalance = selectedMortgage?.amountOwed;
          const closingCostPercent = cleanWholeNumberStr(values.closingCostPct);
          const closingCostDollar = (loanBalance * closingCostPercent) / 100;
          const current = cleanWholeNumberStr(values.closingCostDlr);
          if (current !== closingCostDollar) {
            setValue('closingCostDlr', closingCostDollar);
          }
        } else {
          const loanBalance = selectedMortgage.amountOwed;
          if (loanBalance > 0) {
            const closingCostDollar = cleanWholeNumberStr(values.closingCostDlr);
            const closingCostPercent = (closingCostDollar / loanBalance) * 100;
            const current = cleanWholeNumberStr(values.closingCostpct);
            if (current !== closingCostPercent) {
              setValue('closingCostPct', closingCostPercent);
            }
          }
        }
      }
      return !prev;
    });
  };

  if (mortgages.length === 0) return <NoMortgagesAdded />;

  return (
    <FormContent formProviderProps={formMethods}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(saveOriginationDateOnSubmit)}>
        <Grid className='tool-form-container' rowGap='10px'>
          <Typography variant='p18Bold' color='secondary'>
            {t('YourCurrentMortgage')}
          </Typography>
          <Grid container flexDirection='column' rowGap='30px'>
            <SelectInput<Domain.MortgageRefinanceFormData>
              error={formState.errors.debtId !== undefined}
              helperText={formState.errors.debtId?.message?.toString()}
              defaultValue={mortgageRefinanceFormData?.debtId}
              name='debtId'
              label='MortgageName'
              placeholder='ChooseMortgage'>
              {mortgages.map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </SelectInput>
            <TextInput<Domain.MortgageRefinanceFormData>
              error={formState.errors.originationDate !== undefined}
              helperText={formState.errors.originationDate?.message?.toString()}
              defaultValue={mortgageRefinanceFormData?.originationDate as unknown as string}
              name='originationDate'
              label='OriginationDate'
              type='dateInput'
            />
          </Grid>
          <Typography variant='p18Bold' color='secondary' mt='30px'>
            {t('YourNewMortgage')}
          </Typography>
          <Grid container flexDirection='column' rowGap='30px'>
            <SliderPercent<Domain.MortgageRefinanceFormData>
              error={formState.errors.newInterestRate !== undefined}
              helperText={formState.errors.newInterestRate?.message?.toString()}
              defaultValue={mortgageRefinanceFormData?.newInterestRate ?? 3}
              name='newInterestRate'
              label='InterestRate'
            />
            <SelectInput<Domain.MortgageRefinanceFormData>
              error={formState.errors.newLoanTermYears !== undefined}
              helperText={formState.errors.newLoanTermYears?.message?.toString()}
              defaultValue={mortgageRefinanceFormData?.newLoanTermYears}
              name='newLoanTermYears'
              placeholder='ChooseMortgageTerm'
              label='MortgageTerm'>
              {Domain.mortgageTermTypes.map((x) => (
                <MenuItem value={x.key} key={x.key}>
                  {x.value}
                </MenuItem>
              ))}
            </SelectInput>
            <SliderInputTypeToggle
              onToggle={toggleClosingCosts}
              PercentInput={
                <SliderPercent<Domain.MortgageRefinanceFormData>
                  error={formState.errors.closingCostPct !== undefined}
                  helperText={formState.errors.closingCostPct?.message?.toString()}
                  defaultValue={mortgageRefinanceFormData?.closingCostPct ?? 3}
                  name='closingCostPct'
                  label='ClosingCosts'
                />
              }
              DollarInput={
                <SliderPercent<Domain.MortgageRefinanceFormData>
                  error={formState.errors.closingCostDlr !== undefined}
                  helperText={formState.errors.closingCostDlr?.message?.toString()}
                  defaultValue={mortgageRefinanceFormData?.closingCostDlr}
                  name='closingCostDlr'
                  label='ClosingCosts'
                  type='dollar'
                  max={selectedMortgage?.amountOwed}
                  step={1000}
                />
              }
            />
          </Grid>
        </Grid>
        <Button
          type='submit'
          style={{ width: '45%', alignSelf: 'end', marginTop: '30px' }}
          label={results ? 'Recalculate' : 'Calculate'}
        />
      </form>
    </FormContent>
  );
};

const NoMortgagesAdded: React.FC = () => {
  const navigate = useNavigate();

  const navToAccounts = () => navigate('/account-details');

  return (
    <Card className='tools-no-items-added-box'>
      <Spacer height='xxs' />
      <Svgs.ArtHouse />
      <Typography variant='p16' color='error'>
        {t('PleaseAddHomeBeforeUsing')}
      </Typography>
      <Button label='GoToAccounts' onClick={navToAccounts} />
    </Card>
  );
};

export default MortgageRefinanceCalculatorForm;
