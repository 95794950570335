/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayout } from '../HolisticFinancialAdviceLayout';
import { Spacing } from '../../../themes';
import { Button } from '../../../components/buttons/Button';
import BulletedList from '../../../components/BulletedList';
import { acorns, etrade, fidelity, vanguard } from '../../../assets/png';

const ShopAccountOptionsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathnameSegments = location.pathname.split('/');
  const toolsView = pathnameSegments.includes('tools');

  return (
    <Box>
      <Typography
        className='title'
        color='primary'
        component='h1'
        variant='h1'
        style={{ textTransform: 'capitalize' }}>
        {t('WhatAreMyOptions?')}
      </Typography>
      <Typography color='secondary' variant='p18Bold' mt='40px'>
        {t('OpeningABrokerageOrIra')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('WevePickedSeveralOptions')}
      </Typography>
      <Grid container className='content-card-container' flexDirection='column'>
        <Card
          className='content-title-card'
          variant='ghost'
          style={{ paddingTop: '0', paddingBottom: '0' }}>
          <Grid sm={2} />
          <Grid sm={1.5}>
            <Typography variant='p16Bold' color='primary'>
              {t('InstitutionName')}
            </Typography>
          </Grid>
          <Grid sm={2}>
            <Typography variant='p16Bold' color='primary'>
              {t('AnnualFees')}
            </Typography>
          </Grid>
          <Grid sm={2}>
            <Typography variant='p16Bold' color='primary'>
              {t('AccountMinimum')}
            </Typography>
          </Grid>
          <Grid sm={3}>
            <Typography variant='p16Bold' color='primary'>
              {t('Pros')}
            </Typography>
          </Grid>
          <Grid sm={3}>
            <Typography variant='p16Bold' color='primary'>
              {t('Cons')}
            </Typography>
          </Grid>
        </Card>
        <AccountOptionCard
          img={fidelity}
          institutionName='Fidelity'
          annualFees='$0'
          accountMinimum='$0'
          pros={[t('EasyToOpen'), t('LowFees')]}
          cons={[t('PhoneAssistedTradeFeesAreMoreExpensive')]}
        />
        <AccountOptionCard
          img={vanguard}
          institutionName='Vanguard'
          annualFees='$20*'
          accountMinimum='$0'
          pros={[t('DoesntTryHardToSell'), t('DoesntMakeItEasyToTradeUnecessarily')]}
          cons={[t('OpeningAnAccountIsntEasy')]}
          footer='*Based on a balance less than $10,000.'
        />
        <AccountOptionCard
          img={etrade}
          institutionName='E*TRADE'
          annualFees='$0'
          accountMinimum='$0'
          pros={[t('EasyToOpen'), t('LowFees')]}
          cons={[t('ETradeChargesExitFees')]}
        />
        <AccountOptionCard
          img={acorns}
          institutionName='Acorns'
          annualFees='$36-60**'
          accountMinimum='$0'
          pros={[t('MakesItEasyToGetStartedSavingSmallAmounts')]}
          cons={[t('AtVerySmallBalancesCanBeExpensive')]}
          footer='**Based on a monthly fee of $3-$5 per month.'
        />
      </Grid>
      <Typography variant='p12' color='primary' mt='20px'>
        {t('NoteTheCompaniesMentioned')}
      </Typography>
      {!toolsView && (
        <Grid item sm={2} mt={Spacing.xxs}>
          <Button
            fullWidth
            label='Back'
            color='secondary'
            variant='outlined'
            onClick={() => navigate(-1)}
          />
        </Grid>
      )}
    </Box>
  );
};

interface AccountOptionCardProps {
  img: string;
  institutionName: string;
  annualFees: string;
  accountMinimum: string;
  pros: string[];
  cons: string[];
  footer?: string;
}

const AccountOptionCard: React.FC<AccountOptionCardProps> = ({
  img,
  institutionName,
  annualFees,
  accountMinimum,
  pros,
  cons,
  footer,
}) => {
  return (
    <Card className='content-card' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <Grid sm={2}>
        <img src={img} alt='fidelity' width='140px' />
      </Grid>
      <Grid sm={1.5}>
        <Typography variant='p16Bold' color='secondary'>
          {institutionName}
        </Typography>
      </Grid>
      <Grid sm={2}>
        <Typography variant='p16' color='secondary'>
          {annualFees}
        </Typography>
      </Grid>
      <Grid sm={2}>
        <Typography variant='p16' color='secondary'>
          {accountMinimum}
        </Typography>
      </Grid>
      <Grid sm={3}>
        <BulletedList color='secondary' children={pros} />
      </Grid>
      <Grid sm={3}>
        <BulletedList color='secondary' children={cons} />
      </Grid>
      {footer && (
        <Typography variant='p12' color='primary' position='absolute' bottom='5px'>
          {footer}
        </Typography>
      )}
    </Card>
  );
};

const meta = {
  nextLocaleKey: 'NextAccount',
} satisfies LayoutMeta<HolisticFinancialAdviceLayout>;

export default withLayoutMeta(ShopAccountOptionsView, meta);
