import { Domain, Hooks } from '@3nickels/data-modules';
import { useEffect, useMemo } from 'react';

export function convertPathnameToCategoryEnum(pathname: string): Domain.CategoryEnum {
  switch (pathname) {
    case 'credit-card':
      return 'CREDIT_CARD' as Domain.CategoryEnum;
    default:
      return pathname.toUpperCase() as Domain.CategoryEnum;
  }
}

export function useFilteredLessonsByCategories() {
  const { lessons, setCategories } = Hooks.useFilteredLessonsContext();
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const targetPathname = pathnameSegments[toolsIndex + 1];

  useEffect(() => {
    setCategories([convertPathnameToCategoryEnum(targetPathname)]);
  }, [targetPathname]);

  const filteredLessons = useMemo(() => {
    return lessons;
  }, [lessons]);

  return filteredLessons;
}
