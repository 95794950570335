import { Card, Grid, Typography } from '@mui/material';
import { Colors } from '../../../themes';
import { Svgs } from '../../../assets/svg';
import { Domain } from '@3nickels/data-modules';
interface AnswersDisplayProps {
  answers?: Domain.QuizAnswerDto[];
  reviewQuestion: Domain.ReviewQuestionDto;
}
const AnswersDisplay: React.FC<AnswersDisplayProps> = ({ answers, reviewQuestion }) => {
  return (
    <Grid className='lms-answers' ml={2} mr={2}>
      {answers?.map((answer, i) => (
        <Answer key={i} answer={answer} reviewQuestion={reviewQuestion} />
      ))}
    </Grid>
  );
};
interface AnswerProps {
  answer: Domain.QuizAnswerDto;
  reviewQuestion: Domain.ReviewQuestionDto;
}
const Answer: React.FC<AnswerProps> = ({ answer, reviewQuestion }) => {
  const userSelectedAnswer = reviewQuestion.userAnswerSelectionIds.some(
    (answerId) => answerId === answer.answerId
  );
  const answerIsCorrect = reviewQuestion.correctAnswerIds.some(
    (answerId) => answerId === answer.answerId
  );
  const answerWrong = userSelectedAnswer && !answerIsCorrect;

  return (
    <Card
      style={{
        padding: '10px',
        boxShadow: 'none',
        borderRadius: '10px',
        border:
          answerIsCorrect && userSelectedAnswer
            ? `1px solid ${Colors.successBase}`
            : answerWrong
            ? `1px solid ${Colors.accentWarning}`
            : `none`,
        backgroundColor:
          answerWrong || (answerIsCorrect && userSelectedAnswer)
            ? Colors.tertiaryBase
            : 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Typography
        className='lms-quiz-text'
        variant='p14'
        mr={2}
        color={
          answerIsCorrect
            ? Colors.successBase
            : answerWrong
            ? Colors.accentWarning
            : Colors.disabledBase
        }>
        <div dangerouslySetInnerHTML={{ __html: answer.text ?? '' }} />
      </Typography>
      <Grid width='20px' height='20px' display='block'>
        {answerIsCorrect ? (
          <Svgs.IconCheckmarkGreen />
        ) : answerWrong ? (
          <Svgs.DisplayExtraSmallCustomX />
        ) : null}
      </Grid>
    </Card>
  );
};

export default AnswersDisplay;
