import { useMemo } from 'react';
import { useLoading } from '../../../../hooks/useLoading';
import { usePlaidCredentialRefresh } from '../../../../hooks/usePlaidCredentialRefresh';
import InstitutionalAccountCard from './InstitutionalAccountCard';
import { Data, Domain, Hooks } from '@3nickels/data-modules';

declare type PlaidInstitutionCardProps = {
  institution: Domain.PlaidInstitution;
  onClick: (institution: Domain.PlaidInstitution) => void;
};

export const PlaidInstitutionCard: React.FC<PlaidInstitutionCardProps> = ({
  institution,
  onClick,
}) => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const unlinkHandler = async (institution: Domain.PlaidInstitution) => {
    try {
      setLoading(true);
      await commands.execute(Data.Plaid.Commands.UnlinkPlaidInstitution, {
        accessId: institution.accessId,
      });
    } finally {
      setLoading(false);
    }
  };

  const { updateLoginHandler } = usePlaidCredentialRefresh(institution.accessId);

  const actions = useMemo(() => {
    const list = [];
    if (institution.requiresLogin === true) {
      list.push({
        textKey: 'UpdateLogin',
        onClick: () => updateLoginHandler(),
      });
    }

    list.push({
      textKey: 'UnlinkInstitution',
      onClick: () => {
        unlinkHandler(institution);
      },
    });

    return list;
  }, [institution]);

  return (
    <InstitutionalAccountCard
      key={`${institution.accessId}:${institution.institutionId}`}
      onClick={onClick}
      institution={institution}
      actions={actions}
    />
  );
};
