import { Grid } from '@mui/material';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import { Svgs } from '../../../../assets/svg';
import ToolHeader from '../../../../components/ToolHeader';
import { useLoading } from '../../../../hooks/useLoading';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../../components/form/FormContent';
import { useState } from 'react';
import {
  formatWholePositiveDollars,
  formatWholeDollars,
} from '../../../../helpers/utilityFunctions';
import HowMuchCarCalculatorResults from './HowMuchCarCalculatorResults';
import HowMuchCarCalculatorForm from './HowMuchCarCalculatorForm';
import { useMessage } from '../../../../hooks/useMessage';
import { CarPurchaseMethod } from './Types';
import { useSubject } from '@aesop-fables/scrinium';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  cashAvailable: Yup.string().required('Required'),
  salesTaxRate: Yup.string().required('Required'),
  tradeInAllowance: Yup.string().required('Required'),
  owedOnTradeIn: Yup.string().required('Required'),
});

const cleanValues = (values: Api.AdviceCarAffordabilityRest) => {
  return {
    cashAvailable: Number((values.cashAvailable?.toString() ?? '').replace(/[$,]/g, '')),
    salesTaxRate: Number(values.salesTaxRate),
    tradeInAllowance: Number((values.tradeInAllowance?.toString() ?? '').replace(/[$,]/g, '')),
    owedOnTradeIn: Number((values.owedOnTradeIn?.toString() ?? '').replace(/[$,]/g, '')),
  };
};

const HowMuchCarCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Api.AdviceCarAffordabilityResultRest | undefined>(
    undefined
  );
  const [pdfParams, setPdfParams] = useState<Api.AdviceCarAffordabilityRest | undefined>(undefined);
  const [purchaseMethod, setPurchaseMethod] = useState<CarPurchaseMethod>('Cash');
  const salesTaxRate = useSubject(Data.Savings.DefaultSalesTaxSubject);
  const savings = useSubject(Data.Savings.TotalSavingsSubject);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Api.AdviceCarAffordabilityRest>(
    SavedFormsKeyEnum.HowMuchCar
  );
  const defaultValues = {
    salesTaxRate: salesTaxRate ?? 0,
    ...formData,
    cashAvailable: savings?.totalSavings,
  };

  const methods = useForm<Api.AdviceCarAffordabilityRest>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: Api.AdviceCarAffordabilityRest) => {
    setLoading(true);

    try {
      const cleanedValues = cleanValues(values);
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.HowMuchCar,
        formData: cleanedValues,
      });
      const results = await commands.execute(
        Data.Cars.Commands.CalculateAdviceAffordabilityOutput,
        cleanedValues
      );
      setResults(results);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!pdfParams) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Cars.Commands.CalculateAdviceAffordabilityOutputPdf,
        pdfParams
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HowMuchCarCalculatorResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const header = results ? (
    <ToolHeader icon={<Svgs.ArtCar1 />} title='HeresHowMuchCarAfford' onDownload={onDownload} />
  ) : undefined;

  const boxItems =
    purchaseMethod === 'Cash'
      ? [
          {
            boxTitle: 'MaxCarPrice',
            boxResult: results
              ? formatWholePositiveDollars(results?.cashResult?.affordablePrice)
              : undefined,
          },
        ]
      : purchaseMethod === 'Loan'
      ? [
          {
            boxTitle: 'IdealCarPrice',
            boxResult: results
              ? formatWholeDollars(results?.loanResult?.idealAffordablePrice ?? 0)
              : undefined,
          },
          {
            boxTitle: 'MaxCarPrice',
            boxResult: results
              ? formatWholeDollars(results?.loanResult?.totalAffordablePrice ?? 0)
              : undefined,
          },
        ]
      : [
          {
            boxTitle: 'IdealCarPrice',
            boxResult: results
              ? formatWholeDollars(results?.leaseResult?.idealAffordablePrice ?? 0)
              : undefined,
          },
          {
            boxTitle: 'MaxCarPrice',
            boxResult: results
              ? formatWholeDollars(results?.leaseResult?.totalAffordablePrice ?? 0)
              : undefined,
          },
        ];

  if (typeof formData === 'undefined') return null;

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={header}
            inputColumn={
              <HowMuchCarCalculatorForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                defaultValues={defaultValues}
              />
            }
            outputTabs={['Cash', 'Loan', 'Lease']}
            onOutputTabSelected={setPurchaseMethod}
            selectedOutputTab={purchaseMethod}
            outputColumn={
              <HowMuchCarCalculatorResults results={results} purchaseMethod={purchaseMethod} />
            }
            boxItems={boxItems}></ToolTemplate>
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HowMuchCarCalculatorView, meta);
