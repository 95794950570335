import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import DownloadAppButton from './DownloadAppButton';
import CampingBannerPng from '../../components/images/CampingBannerPng';
import { useTranslation } from 'react-i18next';
import { useMobileRedirect } from '../../hooks/useMobileRedirect';
import { MobileTopNavBar } from './MobileTopNavBar';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface MobileHomeViewProps {
  stripeRedirect?: boolean;
}

const MobileHomeView: React.FC<MobileHomeViewProps> = ({ stripeRedirect }) => {
  const { t } = useTranslation();
  const mobileRedirect = useMobileRedirect();
  const { height } = useWindowDimensions();

  return (
    <Grid
      container
      flexDirection='column'
      display='flex'
      height={height}
      sx={{
        background: 'linear-gradient(to bottom, #DEF3F8, #B9E6EE, #90D5E4)',
      }}>
      <Grid container flexDirection='column' justifyContent='space-between' flex={2}>
        <MobileTopNavBar />
        <Grid item container flexDirection='column' alignItems='center' spacing={2} p={2}>
          <Grid item>
            <Typography variant='h1' className='title' color='primary' align='center'>
              {stripeRedirect ? t('YoureAllSet') : t('YoureLoggedIn')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='secondary' align='center'>
              {t('ContinueToMobileApp')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          spacing={3}
          p={2}>
          <Grid item container alignItems='center' justifyContent='center' maxWidth='228px'>
            <Button variant='outlined' color='secondary' fullWidth onClick={mobileRedirect}>
              {t('OpenTheApp')}
            </Button>
          </Grid>
          <Grid item>
            <DownloadAppButton />
          </Grid>
        </Grid>
      </Grid>

      <CampingBannerPng />
    </Grid>
  );
};

export default MobileHomeView;
