import { Typography, Card, Grid } from '@mui/material';
import { ChartLine, ChartLineSeries } from '../../../../components/ChartLine';
import ToolHeader from '../../../../components/ToolHeader';
import { formatYAxis } from '../../../../helpers/utilityFunctions';
import { Colors } from '../../../../themes';
import { CscChartLegend } from './CscChartLegend';
import { Row } from '../../../../components';

export const FundGrowthView: React.FC<{ chartData: ChartLineSeries[] }> = ({ chartData }) => {
  return (
    <>
      <ToolHeader title='College Fund Growth' />
      <Typography color='secondary' variant='p16'>
        Estimate the growth of a college fund based on different monthly savings amounts, assuming
        an annual return of 5%.
      </Typography>

      <Card className='chart-card'>
        <Grid direction='column'>
          <CscChartLegend
            labels={['$50/mo', '$100/mo', '$250/mo', '$500/mo']}
            colors={[
              Colors.alternateBase,
              Colors.accentBase,
              Colors.primaryBase,
              Colors.secondaryBase,
            ]}
          />

          <ChartLine height={250} width={600} formatY={(y) => formatYAxis(y)} data={chartData} />

          <Row style={{ justifyContent: 'center' }}>
            <Typography mt={1} mb={2} color='secondary' variant='p16Bold'>
              Years Invested
            </Typography>
          </Row>
        </Grid>
      </Card>
    </>
  );
};
