import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { houseLandscape } from '../../../../assets/png';
import { useTranslation } from 'react-i18next';
import InformationTable, { InformationTableData } from '../../../../components/InformationTable';
import { Grid } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';

const HealthInsuranceTypesView: React.FC = () => {
  const { t } = useTranslation();

  const tableData: InformationTableData = {
    headers: {
      column: [t('HMOColon'), t('PPOColon'), t('EPOColon'), t('POSColon')],
      row: [
        t('DoIHaveToStayInNetwork'),
        t('DoINeedAReferralToSeeASpecialist'),
        t('DoIHaveChoicesInMyProviders'),
        t('WhatAreTheOutOfPocketCosts'),
      ],
    },
    content: [
      [
        t('YesExceptForEmergencies'),
        t('NoButYouWillPayMore'),
        t('YesExceptForEmergencies'),
        t('NotUsuallyButTheCost'),
      ],
      [t('YesPeriod'), t('NotUsually'), t('NotUsually'), t('YesPeriod')],
      [
        t('ThereIsNotMuchFlexibility'),
        t('ThereIsMoreFlexibility'),
        t('ThereIsMoreFlexibility'),
        t('ThereIsMoreFlexibility'),
      ],
      [
        t('UsuallyLowExceptForHSA'),
        t('ItDependsOnThePlan'),
        t('UsuallyLow'),
        t('UsuallyLowButItDepends'),
      ],
    ],
  };

  const hdhpTableData: InformationTableData = {
    headers: {
      column: [t('WhatIsAnHSAEligiblePlan'), t('WhatIsAnHSA'), t('KeyBenefits')],
      row: [t('HDHPColon')],
    },
    content: [
      [
        t('AnHSAEligiblePlan'),
        t('AnHSAIsAHealthSavings'),
        <BulletedList
          children={[t('HSAContributionsAreNotSubject'), t('HSASavingsCanBeUsedInRetirement')]}
        />,
      ],
    ],
  };

  return (
    <Grid container spacing={3}>
      <Grid item>
        <InformationTable data={tableData} />
      </Grid>
      <Grid item>
        <InformationTable data={hdhpTableData} headerEmphasis='row' />
      </Grid>
    </Grid>
  );
};

const meta = {
  background: (
    <img src={houseLandscape} className='tools-start-view-background' alt='House landscape' />
  ),
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HealthInsuranceTypesView, meta);
