import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Spacing } from '../../../../themes';

const StepFive: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={5}
      headerText='PaperworkAndDelivery'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('CongratulationsYoureAlmostThere')}
          </Typography>
          <Typography color='secondary' variant='p16Bold'>
            {t('ForNewCars')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('MakeSureTheNumbersOnYourPaperworkAreCorrect'),
              t('IfYoureTakingOutALoan'),
              t('UponDeliveryOfTheCar'),
            ]}
          />
          <Typography color='secondary' variant='p16Bold'>
            {t('ForUsedCars')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[t('MakeSureToAskAboutTheCarsHistory'), t('YouShouldLeave')]}
          />
        </Stack>
      }
    />
  );
};

export default StepFive;
