/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Data, Domain, enumMapToListItems, ListItem } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import { Button } from '../../../../components/buttons/Button';

type PersonalLoanCalculatorFormProps = {
  methods: UseFormReturn<Data.Loans.AdviceLoansPersonalData, any, undefined>;
  onSubmit: (values: Data.Loans.AdviceLoansPersonalData) => Promise<void>;
  results: Data.Loans.AdviceLoansPersonalResultData | undefined;
  defaultValues: Data.Loans.AdviceLoansPersonalData;
};

const PersonalLoanCalculatorForm: React.FC<PersonalLoanCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState, setValue } = useFormContext();

  const loanTermMonthsTypes: ListItem[] = enumMapToListItems(Domain.LoanTermMonthsEnumMap);

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit(data);
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('loanTermMonths', defaultValues.loanTermMonths);
    }
  }, [defaultValues]);

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Data.Loans.AdviceLoansPersonalData>
        error={formState.errors.loanAmount !== undefined}
        helperText={formState.errors.loanAmount?.message?.toString()}
        name='loanAmount'
        label='DesiredLoanAmount'
        type='dollar'
        min={0}
        max={1000000}
        initialMax={100000}
        step={1000}
        defaultValue={defaultValues.loanAmount}
      />
      <SelectInput<Data.Loans.AdviceLoansPersonalData>
        error={formState.errors.loanTermMonths !== undefined}
        helperText={formState.errors.loanTermMonths?.message?.toString()}
        name='loanTermMonths'
        placeholder='ChooseLoanTerm'
        label='DesiredLoanTerm'>
        {loanTermMonthsTypes.map((x) => (
          <MenuItem value={x.key}>{x.value}</MenuItem>
        ))}
      </SelectInput>
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalLoanCalculatorForm;
