import { Grid, TypographyProps } from '@mui/material';
import BulletedText from './BulletedText';
import { Children } from 'react';
import { useTranslation } from 'react-i18next';

const BulletedList: React.FC<TypographyProps> = ({ children, style, color }) => {
  const { t } = useTranslation();
  return (
    <Grid>
      {Children.map(children, (child, index) => (
        <BulletedText key={index} color={color} style={{ ...style }}>
          {typeof child === 'string' ? t(child) : child}
        </BulletedText>
      ))}
    </Grid>
  );
};

export default BulletedList;
