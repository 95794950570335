/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ListItem,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { firstValueFrom, map } from 'rxjs';
import RetirementPlanBasicsForm from './RetirementPlanBasicsForm';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { useRetirementPlanMeta } from '../../../../hooks/useRetirementPlanMeta';
import { useRetirementPlanPath } from '../../../../hooks/useRetirementPlanPath';
import HelpTitle from '../../../../components/HelpTitle';
import { Spacer } from '../../../../components';
import BulletedList from '../../../../components/BulletedList';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { Margins } from '../../../../themes';

const accountBasicsSchema = (includeSpouse: boolean, planTypes: ListItem[]) =>
  Yup.object({
    type: Yup.number()
      .transform((transformed, original) => (String(original).trim() === '' ? null : transformed))
      .required('Plan Type is required')
      .test('type', 'Plan Type is required', (type) => {
        const res = planTypes.some((planType) => planType.key === type);
        return res;
      }),
    name: Yup.string()
      .required('Name is required')
      .test('name', 'Plan name must not contain special characters', (name) =>
        /^["^[0-9a-zA-Z\s\-.(),']*$/.test(name)
      ),
    owner: includeSpouse ? Yup.string().required('Owner is required') : Yup.string().notRequired(),
  });

export const RetirementPlanBasicsEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard, params } =
    Hooks.useRetirementWizard<Data.InvestmentAccountBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id, type: planType } = useParams();
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : params?.type ?? Domain.PlanTypeEnum['#457'];
  useRetirementPlanMeta(editing);

  useEffect(() => {
    // ensure type is initialized
    if (
      typeof isStarted !== 'undefined' &&
      !isStarted &&
      type &&
      Domain.PlanTypeEnumMap.get(type)
    ) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted, type]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <RetirementPlanBasicsEditView
              currentStep={currentStep}
              wizard={wizard}
              editing={editing}
              onBack={onBack}
              params={params}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface RetirementPlanBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccountBasicFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const RetirementPlanBasicsEditView: React.FC<RetirementPlanBasicsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const path = useRetirementPlanPath();
  const includeSpouse = Hooks.useIncludeSpouse();
  const planTypes = Hooks.useAvailablePlanTypes(params.isSpouse ? 'spouse' : 'me');

  const methods = useForm<
    Data.InvestmentAccountBasicFormData & Data.InvestmentAccounts.InvestmentAccountWizardParams
  >({
    defaultValues: {
      ...currentStep.model,
      type: currentStep.model?.name ? params.type : undefined,
    },
    resolver: yupResolver(accountBasicsSchema(includeSpouse, planTypes)),
    mode: 'onChange',
  });
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      const wizardPlanType =
        Domain.planTypeIdToAccountKey[
          wizardParams?.type as keyof typeof Domain.planTypeIdToAccountKey
        ];
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(
        `/account-details/retirement-savings-plan/basics/${wizardPlanType}/${wizardParams?.id}`,
        {
          replace: true,
        }
      );

      navigate(
        `/account-details/retirement-savings-plan/${path}/${wizardPlanType}/${wizardParams?.id}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <HelpTitle
                text={editing ? 'AccountBasics' : 'AddRetirementPlan'}
                helpContext={
                  <HelpPopover title={t(editing ? 'AccountBasics' : 'AddRetirementPlan')}>
                    <HelpPopoverText>
                      We need to collect some details about your account:
                    </HelpPopoverText>
                    <BulletedList
                      style={Margins.mt_xxs}
                      children={[
                        'Account basics– what to call it, who owns it, etc.',
                        'Contributions– whether or not permitted, by whom (account owner and/or employer), and how much',
                        'Investments– specific mutual funds/ETF’s individual stocks, and bonds, their balances, etc.',
                      ]}
                    />
                    <Spacer height='xxs' />
                    <HelpPopoverText children={'We’ll walk you through it, step by step.'} />
                  </HelpPopover>
                }
              />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <RetirementPlanBasicsForm
                    planBasics={currentStep.model}
                    onSubmit={onSubmit}
                    wizard={wizard}
                    params={params}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(RetirementPlanBasicsEditViewWrapper, meta);
