import { Domain } from '@3nickels/data-modules';
import { Card, Grid, Typography } from '@mui/material';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import { Colors } from '../../../../themes';
import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../../../themes/ThemeWrapper';
import { AdviceDebtIncomePercentageTypeEnum, getIsAboveRecommended } from './Types';

interface RatioSectionProps {
  results: Domain.DebtIncomePercentageData | undefined;
  noDebts: boolean;
}

export const RatioSection: React.FC<RatioSectionProps> = ({ results, noDebts }) => {
  return (
    <Grid container item justifyContent='space-between' alignItems='center' flexDirection='row'>
      <RatioRow
        icon={<Svgs.ArtDebt />}
        title='DebtToIncomeRatio'
        ratioValue={results?.debtToIncomeRatio ?? 0}
        recommendedValue={AdviceDebtIncomePercentageTypeEnum.DebtToIncome}
        helpBody='DebtToIncomeRatioHelpText'
        noDebts={noDebts}
        debtSupported={getIsAboveRecommended(
          AdviceDebtIncomePercentageTypeEnum.DebtToIncome,
          results?.debtToIncomeRatio ?? 0
        )}
      />
      <RatioRow
        icon={<Svgs.ArtHouse height='74px' />}
        title='HousingCostRatio'
        ratioValue={results?.housingCostRatio ?? 0}
        recommendedValue={AdviceDebtIncomePercentageTypeEnum.HousingCost}
        helpBody='HousingCostRatioHelpText'
        noDebts={noDebts}
        debtSupported={getIsAboveRecommended(
          AdviceDebtIncomePercentageTypeEnum.HousingCost,
          results?.housingCostRatio ?? 0
        )}
      />
      <RatioRow
        icon={<Svgs.ArtCreditCard2 height='74px' />}
        title='ConsumerDebtRatio'
        ratioValue={results?.consumerDebtRatio ?? 0}
        recommendedValue={AdviceDebtIncomePercentageTypeEnum.ConsumerDebt}
        helpBody='ConsumerDebtRatioHelpText'
        noDebts={noDebts}
        debtSupported={getIsAboveRecommended(
          AdviceDebtIncomePercentageTypeEnum.ConsumerDebt,
          results?.consumerDebtRatio ?? 0
        )}
      />
    </Grid>
  );
};

interface RatioRowProps {
  icon: JSX.Element;
  title: string;
  ratioValue: number;
  recommendedValue: number;
  helpBody: string;
  noDebts: boolean;
  debtSupported: boolean;
}
const RatioRow: React.FC<RatioRowProps> = ({
  icon,
  title,
  ratioValue,
  recommendedValue,
  helpBody,
  noDebts,
  debtSupported,
}) => {
  const { t } = useTranslation();

  return (
    <Card className='debt-ratio'>
      <Grid container direction='column' display='flex' justifyContent='center' alignItems='center'>
        {icon}
        <Typography
          variant='p30Bold'
          color={debtSupported ? 'secondary' : Colors.accentBase}
          fontFamily='Montserrat'
          marginY={1.5}>
          {noDebts ? '– – –' : formatPercent(ratioValue)}
        </Typography>
        <Grid container justifyContent='center' alignItems='center' flexDirection='row'>
          <Typography mr='5px' color='primary' variant='p18Bold'>
            {t(title)}
          </Typography>
          <Tippy
            placement='right'
            content={<HelpContent title={title} helpBody={helpBody} />}
            theme='light-nickels-theme'>
            <HelpOutline style={{ color: theme.palette.primary.main }} />
          </Tippy>
        </Grid>
        <Typography mt='3px' variant='p14' color='primary'>
          Recommended max is {formatPercent(recommendedValue)}
        </Typography>
      </Grid>
    </Card>
  );
};

interface HelpContentProps {
  title: string;
  helpBody: string;
}
const HelpContent: React.FC<HelpContentProps> = ({ title, helpBody }) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Typography variant='p16Bold' color='secondary'>
        {t(title)}
      </Typography>
      <Typography variant='p16' color='secondary' mt={1}>
        {t(helpBody)}
      </Typography>
    </Grid>
  );
};
