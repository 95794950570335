import { Data, Domain } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { capitalizeFirstLetter } from '../../../../helpers/utilityFunctions';
import { SummaryCardProps } from '../../Types';
import { mapDebtTypeToLabelNickname } from '../DebtUtils';

export interface DebtBasicsCardProps extends SummaryCardProps {
  debtBasics: Data.Debt.DebtBasicFormData;
  params: Data.Debt.DebtWizardParams;
  includeSpouse?: boolean;
}

const DebtBasicsCard: React.FC<DebtBasicsCardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='DebtBasics' actionContent='Edit' actionClick={props.onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t(mapDebtTypeToLabelNickname(props.params.type ?? Domain.DebtTypeEnum.OTHER))}
          </Typography>
          <Typography color='secondary'>{props.debtBasics?.name}</Typography>
        </Grid>
        {props.includeSpouse && (
          <Grid item xs={9.6}>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {capitalizeFirstLetter(props.debtBasics?.owner ?? '')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardDisplay>
  );
};

export default DebtBasicsCard;
