import { Data, Hooks } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';
import { useLoading } from './useLoading';
import { usePlaidLink } from 'react-plaid-link';

export function usePlaidCredentialRefresh(id?: number) {
  const [token, setToken] = useState<string | null>(null);
  const [accessId, setAccessId] = useState<number | undefined>(id);
  const commands = Hooks.useCommands();
  const wizard = Hooks.usePlaidWizard();
  const { setLoading } = useLoading();

  const { open } = usePlaidLink({
    token,
    async onSuccess() {
      setLoading(true);
      try {
        await commands.execute(Data.Plaid.Commands.RefreshPlaidInstitution, {
          accessId,
        });
      } finally {
        setLoading(false);
      }

      setToken(null);
    },
    onExit() {
      setToken(null);
    },
  });

  const updateLoginHandler = async (value?: number) => {
    if (id && !accessId) setAccessId(id);
    const newToken = await wizard.getExpiredCredentialsToken(value ?? id ?? 0);
    setToken(newToken);
  };

  const setIdAndUpdateLogin = (value: number) => {
    setAccessId(value);
    updateLoginHandler(value);
  };

  useEffect(() => {
    if (token && accessId) {
      open();
    }
  }, [token, accessId, open]);

  return { updateLoginHandler, setIdAndUpdateLogin };
}
