import { Grid } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Typewriter, { TypewriterProps } from '../../components/Typewriter';

export interface StartViewProps extends PropsWithChildren, TypewriterProps {
  imageSrc: string;
}

const StartView: React.FC<StartViewProps> = ({ imageSrc, children, ...typewriterProps }) => {
  return (
    <Grid display='flex' flexDirection='column' alignItems='center'>
      <Grid display='flex' justifyContent='center'>
        <Grid item sm={10} md={8} lg={6} xl={5} component='img' src={imageSrc} />
      </Grid>
      <Typewriter
        className='start-screen-text'
        color='primary'
        variant='p30'
        display='inline'
        {...typewriterProps}>
        {children}
      </Typewriter>
    </Grid>
  );
};

export default StartView;
