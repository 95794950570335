/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import CarBasicsForm from './CarBasicsForm';
import { firstValueFrom, map } from 'rxjs';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { Modal } from '../../../../components';

const carBasicsSchema = (includeSpouse: boolean) =>
  Yup.object({
    name: Yup.string().required('Required'),
    owner: includeSpouse ? Yup.string().required('Required') : Yup.string().notRequired(),
    value: Yup.string().required('Required'),
  });

export const CarBasicsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useCarWizard<Data.Cars.CarBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && ( // don't even mount until correct step is set
        <FormLoader loading={loading}>
          <CarBasicsEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface CarBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Cars.CarBasicFormData, Data.Cars.CarWizardParams>;
  wizard: Data.Cars.CarWizard;
}

const CarBasicsEditView: React.FC<CarBasicsEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const includeSpouse = Hooks.useIncludeSpouse();
  const methods = useForm<Data.Cars.CarBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(carBasicsSchema(includeSpouse)),
  });
  const { setLoading } = useLoading();
  const [modalOpen, setModalOpen] = useState(false);

  const formatInputs = (values: any) => {
    const value = cleanWholeNumberStr(values.value);

    return { value };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/car/basics/${wizardParams?.id}`, { replace: true });
      navigate(`/account-details/car/details/${wizardParams?.id}`);
    } finally {
      setLoading(false);
    }
  };

  const preSubmit = async (values: any) => {
    const { value } = formatInputs(values);
    if (value === 0) {
      setModalOpen(true);
      return;
    }

    onSubmit(values);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <Modal
          title='Continue Without a Current Value?'
          primaryButtonText='No, Enter a Current Value'
          secondaryButtonText='Yes, Continue Anyway'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            const values = methods.getValues();
            onSubmit(values);
          }}>
          <Typography variant='p16' color='secondary'>
            You haven't entered a current value for this car. Would you still like to continue?
          </Typography>
        </Modal>
      </div>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(preSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t(editing ? 'CarBasics' : 'AddCar')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CarBasicsForm carBasics={currentStep.model} onSubmit={preSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 2,
    currentStep: 1,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(CarBasicsEditViewWrapper, meta);
