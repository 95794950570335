import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import { Spacing } from '../../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type DebtDetailsFormProps = {
  debtDetails?: Data.Debt.DebtDetailsFormData;
  onSubmit: (values: Data.Debt.DebtDetailsFormData) => Promise<void>;
};

const DebtDetailsForm: React.FC<DebtDetailsFormProps> = (props) => {
  const { formState } = useFormContext();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Debt.DebtDetailsFormData>
          error={formState.errors.amountOwed !== undefined}
          helperText={formState.errors.amountOwed?.message?.toString()}
          defaultValue={props.debtDetails?.amountOwed}
          label='AmountIOwe'
          name='amountOwed'
          type='dollar'
          placeholder='$0'
          autoFocus
        />
        <TextInput<Data.Debt.DebtDetailsFormData>
          error={formState.errors.interestRate !== undefined}
          helperText={formState.errors.interestRate?.message?.toString()}
          defaultValue={props.debtDetails?.interestRate}
          label='InterestRate'
          name='interestRate'
          type='percent'
          placeholder='0%'
        />
        <TextInput<Data.Debt.DebtDetailsFormData>
          error={formState.errors.monthlyPayment !== undefined}
          helperText={formState.errors.monthlyPayment?.message?.toString()}
          defaultValue={props.debtDetails?.monthlyPayment}
          label='AmountIPayMonthly'
          name='monthlyPayment'
          type='dollar'
          placeholder='$0'
        />
        <TextInput<Data.Debt.DebtDetailsFormData>
          error={formState.errors.minimumPayment !== undefined}
          helperText={formState.errors.minimumPayment?.message?.toString()}
          defaultValue={props.debtDetails?.minimumPayment}
          label='MinimumPayment'
          name='minimumPayment'
          type='dollar'
          placeholder='$0'
        />
      </Stack>
    </Box>
  );
};

export default DebtDetailsForm;
