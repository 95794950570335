import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { formatPhoneNumber } from '../../../../helpers/utilityFunctions';
import { Svgs } from '../../../../assets/svg';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';

interface OrgContactInfoProps {
  telephone: string | undefined;
  fax: string | undefined;
  email: string | undefined;
  primaryAddress: string | undefined;
  secondaryAddress: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
}

const OrgContactInfo: React.FC<OrgContactInfoProps> = ({
  telephone,
  fax,
  email,
  primaryAddress,
  secondaryAddress,
  city,
  state,
  zip,
}) => {
  const navigate = useNavigate();

  return (
    <Grid>
      <Typography mb={1} variant='p22Bold' color='primary'>
        {t('ContactInfo')}
      </Typography>
      <Card className='org-contact-info-card'>
        {telephone && (
          <ContactMethod
            icon={<Svgs.DisplaySmallCustomPhone2 />}
            method='Telephone'
            details={formatPhoneNumber(telephone)}
          />
        )}
        {fax && (
          <ContactMethod
            icon={<Svgs.DisplaySmallCustomFax />}
            method='Fax'
            details={formatPhoneNumber(fax)}
          />
        )}
        {email && (
          <ContactMethod
            icon={<Svgs.DisplaySmallCustomEnvelope />}
            method='Email'
            details={email}
            onClick={() => (window.location.href = `mailto:${email}`)}
          />
        )}
        <ContactMethod
          icon={<Svgs.DisplayMediumCustomLocation />}
          method='Address'
          details={
            <Grid flexDirection='column'>
              <Typography variant='p16' color='secondary'>
                {primaryAddress ?? ''}
              </Typography>
              {secondaryAddress && (
                <Typography variant='p16' color='secondary'>
                  {secondaryAddress}
                </Typography>
              )}
              <Typography variant='p16' color='secondary'>
                {city ?? ''}, {state ?? ''}, {zip ?? ''}
              </Typography>
            </Grid>
          }
        />
      </Card>
      <Grid item mt={2} xs={2}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Grid>
  );
};

export default OrgContactInfo;

interface ContactMethodProps {
  icon: JSX.Element;
  method: string;
  details: string | JSX.Element;
  onClick?: () => void;
}

const ContactMethod: React.FC<ContactMethodProps> = ({ icon, method, details, onClick }) => {
  const formatEmailWithBreak = (email: string) => {
    return email.replace('@', '\u200B@');
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems='flex-start'
      className={typeof onClick !== 'undefined' ? 'clickable-contact-method' : ''}
      onClick={onClick}>
      <Grid item>{icon}</Grid>
      <Grid item xs>
        <Typography variant='p18Bold' color='secondary'>
          {t(`${method}`)}
        </Typography>
        {typeof details === 'string' ? (
          <Typography variant='p16' color='secondary' className='break-word'>
            {method === 'Email' ? formatEmailWithBreak(details) : details}
          </Typography>
        ) : (
          details
        )}
      </Grid>
    </Grid>
  );
};
