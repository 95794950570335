import { Data, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../../../Types';
import { useEffect, useState } from 'react';

export interface IraContributionsCardProps extends SummaryCardProps {
  accountContributions: Data.InvestmentAccounts.AccountContributionsFormData;
  planType: Domain.IraPlanTypeEnum;
}

const IraContributionsCard: React.FC<IraContributionsCardProps> = ({
  accountContributions,
  planType,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [contributionType, setContributionType] = useState<
    keyof Data.InvestmentAccounts.AccountContributionsFormData | undefined
  >(undefined);

  useEffect(() => {
    const type = Domain.getIraContributionType(planType);
    setContributionType(type);
  }, [planType]);

  return (
    <CardDisplay headerKey='AnnualContributions' actionContent='Edit' actionClick={onEdit}>
      {contributionType && (
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AnnualSavings')}
            </Typography>
            <Typography color='secondary'>
              {formatWholeDollars(accountContributions[contributionType] as unknown as number)}
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item xs />
          <Grid item xs />
          <Grid item xs />
        </Grid>
      )}
    </CardDisplay>
  );
};

export default IraContributionsCard;
