import { Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Card, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Colors } from '../../../../themes';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light-border.css'; // must import any theme you wish to use with Tippy
import 'tippy.js/dist/tippy.css'; // optional
import '../../../../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import CoveringRefinanceCostsHelp from './help/CoveringRefinanceCostsHelp.';
import { KeyValuePair, PopupInfoCard } from '../../../../components';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import dayjs from 'dayjs';
import PMIHelp from './help/PMIHelp';

export interface MortgageRefinanceCalculatorResultsProps {
  results?: Domain.MortgageRefinanceResultData;
  mortgage?: Domain.IDebt;
}

const MortgageRefinanceCalculatorResults: React.FC<MortgageRefinanceCalculatorResultsProps> = ({
  results,
  mortgage,
}) => {
  const { years, months } = useMemo(() => {
    const monthCovered = results?.numMonthsCovered ?? 0;
    const years = Math.floor(monthCovered / 12);
    const months = monthCovered - years * 12;
    return { years, months };
  }, [results?.numMonthsCovered]);
  const savingsExpected = useMemo(() => {
    return (results?.totalSavings ?? 0) > 0;
  }, [results]);

  if (typeof results === 'undefined') return null;

  return savingsExpected ? (
    <Grid container direction='column' rowGap='40px' mt='10px'>
      <Card className='tools-output-box'>
        <Grid
          container
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          paddingX='20px'>
          <Grid display='flex' columnGap='5px'>
            <Typography variant='p16' color='secondary'>
              {t('CostsCoveredIn')}
            </Typography>
            <Tippy
              placement='right'
              content={
                <CoveringRefinanceCostsHelp
                  time={
                    years > 0
                      ? `${years} year${years > 1 ? 's' : ''} ${months} month${
                          months > 1 ? 's' : ''
                        }`
                      : `${months} month${months > 1 ? 's' : ''}`
                  }
                />
              }
              theme='light-nickels-theme'>
              <HelpOutline style={{ color: Colors.primaryBase, alignSelf: 'center' }} />
            </Tippy>
          </Grid>
          {years > 0 ? (
            <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
              <strong>{years}</strong>&nbsp;{`year${years > 1 ? 's' : ''}`}
              &nbsp;
              <strong>{months}</strong>&nbsp;{`month${months > 1 ? 's' : ''}`}
            </Typography>
          ) : (
            <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
              <strong>{months}</strong>&nbsp;{`month${months > 1 ? 's' : ''}`}
            </Typography>
          )}
        </Grid>
      </Card>
      <Grid container direction='column' rowGap='10px'>
        <Typography variant='p16Bold' color='secondary'>
          {mortgage?.name}
        </Typography>
        <KeyValuePair
          label='AmountIOwe'
          value={
            typeof mortgage?.amountOwed !== 'undefined'
              ? formatWholeDollars(mortgage.amountOwed)
              : '– – –'
          }
        />
        <KeyValuePair
          label='InterestRate'
          value={
            typeof mortgage?.interestRate !== 'undefined'
              ? formatPercent(mortgage.interestRate)
              : '– – –'
          }
        />
        <KeyValuePair
          label='MortgageTerm'
          value={
            Domain.MortgageTermTypeEnumMap.get(
              Domain.getMortgageTermTypeEnumOfYears(mortgage?.loanTermYears ?? 0)
            )?.toString() ?? '– – –'
          }
        />
        <KeyValuePair
          label='OriginationDate'
          value={
            typeof mortgage?.originationDate !== 'undefined'
              ? dayjs(mortgage.originationDate).format('MM/DD/YYYY')
              : '– – –'
          }
        />
      </Grid>
      <PopupInfoCard popupContext={<PMIHelp />}>
        <Typography variant='p16' color='secondary'>
          {t('DontForgetPMI')}
        </Typography>
      </PopupInfoCard>
    </Grid>
  ) : (
    <Grid container direction='column' rowGap='10px' mt='20px'>
      <Typography variant='p16Bold' color='secondary'>
        {t('RefinanceSummary')}
      </Typography>
      <KeyValuePair
        label='OldMonthlyPayment'
        value={
          typeof results.oldMonthlyPayments !== 'undefined'
            ? formatWholeDollars(results.oldMonthlyPayments)
            : '– – –'
        }
      />
      <KeyValuePair
        label='NewMonthlyPayment'
        value={
          typeof results.newMonthlyPayments !== 'undefined'
            ? formatWholeDollars(results.newMonthlyPayments)
            : '– – –'
        }
      />
    </Grid>
  );
};

export default MortgageRefinanceCalculatorResults;
