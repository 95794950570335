import React from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { fence } from '../../../../assets/png';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { t } from 'i18next';

const BasicInvestingTermsView: React.FC = () => {
  return (
    <Box>
      <Grid container justifyContent='center' alignItems='center'>
        <Stack spacing={Spacing.xxxs + 1}>
          <TermCard term='AssetClass' definition='ACategoryOfInvestments' />
          <TermCard term='Bond' definition='ALoanToACompany' />
          <TermCard term='Broker' definition='YouMustHaveABroker' />
          <TermCard term='CD' definition='ACertificateOfDeposit' />
          <TermCard term='CompanySize' definition='CompanySizeIsDetermined' />
          <TermCard term='Diversification' definition='DontPutAllYourEggs' />
          <TermCard term='Dividend' definition='TheseAreRegularPayments' />
          <TermCard term='EfficientFrontier' definition='InModernPortfolioTheory' />
          <TermCard term='ETF' definition='AnExchangeTradedFund' />
          <TermCard term='ExtendedHours' definition='SomeBrokersAllowTrading' />
          <TermCard term='IdiosyncraticRisk' definition='TheRiskThatIsUnique' />
          <TermCard term='IndexEgSP' definition='AnIndexIsAGroup' />
          <TermCard term='Margins' definition='BrokersCanAllowYou' />
          <TermCard term='ModernPortfolioTheory' definition='TheInvestingMethod' />
          <TermCard term='MutualFund' definition='OveseenByMoneyManagers' />
          <TermCard term='OrderTypes' definition='WhenYouBuyAndSell' />
          <TermCard term='PennyStock' definition='TheseAreVeryRiskyStocks' />
          <TermCard term='Portfolio' definition='APortfolioIsACollection' />
          <TermCard term='PublicOfferings' definition='WithAnInitialPublicOffering' />
          <TermCard term='RiskAndReward' definition='TheRiskierTheInvestment' />
          <TermCard term='ShortSelling' definition='ThisIsAWayToBet' />
          <TermCard term='Stock' definition='AStockIsAPiece' />
          <TermCard term='StockExchange' definition='StockExchangesWork' />
          <TermCard term='StockSplits' definition='WhenACompanyPerformsAStockSplit' />
          <TermCard term='TickerSymbol' definition='The3To5' />
        </Stack>
      </Grid>
    </Box>
  );
};

interface TermCardProps {
  term: string;
  definition: string;
}

const TermCard: React.FC<TermCardProps> = ({ term, definition }) => {
  return (
    <Card className='basic-investing-terms'>
      <Typography mb={0.5} variant='p16Bold' color='primary'>
        {t(`${term}`)}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t(`${definition}`)}
      </Typography>
    </Card>
  );
};

const meta = {
  background: <img src={fence} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(BasicInvestingTermsView, meta);
