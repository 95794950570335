import { useCallback } from 'react';
import { DevicePlatform, useDevicePlatform } from './useDevicePlatform';

const APP_STORE_URLS: Readonly<Record<Exclude<DevicePlatform, 'unknown'>, string>> = {
  'iOS': 'https://apps.apple.com/us/app/3nickels-invest-budget-save/id1544362343',
  'Android': 'https://play.google.com/store/apps/details?id=com.guidedchoice.iiinickels',
  'Windows Phone': '',
} as const;

export const useAppStoreNavigation = () => {
  const platform = useDevicePlatform();

  return useCallback(() => {
    if (platform === 'iOS' || platform === 'Android') {
      window.location.href = APP_STORE_URLS[platform];
    }
  }, [platform]);
};
