import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import PlanCard from './PlanCard';
import PlanActionGroupButtons from './PlanActionGroupButtons';
import PlanFeaturesTable from './PlanFeaturesTable';
import Footnote from './Footnote';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { Loading } from '../../../hooks/useLoading';
import { getFootnoteElement, getRecentPlan } from './functions';
import { ChangePlanWrapper } from './ChangePlanView';
import { MobileScreenWrapper } from '../../mobile-redirect/MobileScreenWrapper';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';

export const ManageSubscriptionWrapper: React.FC = () => {
  const { multipleProducts, orgDoesNotOfferFreePricebook } = Hooks.useAvailableProducts();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const subscriptionInit = Hooks.useSubscriptionInfoInitialized();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];

  const showSelectPlan = orgDoesNotOfferFreePricebook && multipleProducts && !subscription;

  if (!subscriptionInit) {
    return <Loading />;
  }
  if (showSelectPlan) {
    return <ChangePlanWrapper />;
  }
  if (mobile) {
    return (
      <MobileScreenWrapper>
        <ManageSubscriptionView />
      </MobileScreenWrapper>
    );
  }

  const ManageSubscription = withLayoutMeta(ManageSubscriptionView, meta);
  return <ManageSubscription />;
};

const ManageSubscriptionView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const subscriptionInit = Hooks.useSubscriptionInfoInitialized();
  const freeTrialStatus = Hooks.useFreeTrialStatus();
  const legalDocs = Hooks.useTermsAndConditions();
  const {
    threeNickelsProducts,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    orgPaysPricebook,
    orgDoesNotOfferFreePricebook,
  } = Hooks.useAvailableProducts();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const inAdviceFreeTrial = freeTrialStatus ? freeTrialStatus.active : false;
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];

  const recentPlan = getRecentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  const userNeverSubscribedToExclusivePricebook =
    (!subscriptionInfoData || subscriptionInfoData.length === 0) &&
    orgDoesNotOfferFreePricebook &&
    !multipleProducts;

  const planForTable = threeNickelsProducts
    ? threeNickelsProducts.filter(
        (p) => p.pricebook.pricebookName === recentPlan.pricebook.pricebookName
      )
    : [];

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  if (!subscriptionInit) {
    return <Loading />;
  }

  let footnoteCounter = 0;
  const footnoteTexts: string[] = [];

  const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
    footnoteCounter,
    recentPlan,
    inAdviceFreeTrial,
    subscription,
    freeTrialStatus,
    userNeverSubscribedToExclusivePricebook,
    true
  );
  if (footnoteText) {
    footnoteCounter = counter;
    footnoteTexts.push(footnoteText);
  }

  return (
    <Box>
      <Grid container flexDirection='column'>
        {mobile ? (
          <Grid mb={2} display='flex' direction='row' sx={{ alignItems: 'center' }}>
            <Svgs.IconArrowLeft onClick={() => navigate('/')} />
            <Typography ml={1} color='primary' variant='p22Bold'>
              {t('ManageSubscription')}
            </Typography>
          </Grid>
        ) : (
          <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
            {t('ManageSubscription')}
          </Typography>
        )}
      </Grid>
      <PlanCard
        plan={recentPlan}
        pricebookMetadata={pricebookMetadata}
        subscriptionInfo={subscription}
        threeNickelsProducts={threeNickelsProducts}
        freeTrialStatus={freeTrialStatus}
        inAdviceFreeTrial={inAdviceFreeTrial}
        userNeverSubscribedToExclusivePricebook={userNeverSubscribedToExclusivePricebook}
        footnoteIndex={footnoteText ? footnoteCounter : undefined}
        manageSubscription
      />
      {footnoteTexts.map((text, index) => (
        <Grid item mt={3} xs={mobile ? 12 : 5} key={index}>
          <Footnote index={index} text={text} termsApi={termsApi} legalDocs={legalDocs} />
        </Grid>
      ))}
      <PlanActionGroupButtons
        plan={recentPlan}
        subscriptions={subscriptionInfoData}
        threeNickelsProducts={threeNickelsProducts}
        inAdviceFreeTrial={inAdviceFreeTrial}
        orgPaysPricebook={orgPaysPricebook}
        paymentApi={paymentApi}
        multipleProducts={multipleProducts}
        orgOnlyOffersFreePricebook={orgOnlyOffersFreePricebook}
      />
      {planForTable.length > 0 && <PlanFeaturesTable products={planForTable} />}
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;
