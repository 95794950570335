import { Domain } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import { LmsMedia } from '../../LmsMedia';

interface ContentHeaderProps {
  icon: Domain.MediaMetadata | undefined;
  title: string | undefined;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ icon, title }) => {
  return (
    <Grid container className='lms-content-header'>
      {icon && (
        <Grid item className='lms-content-icon'>
          <LmsMedia id={icon?.id} title={icon?.mediaName ?? ''} icon />
        </Grid>
      )}
      <Grid item style={{ flexGrow: 1 }}>
        <div dangerouslySetInnerHTML={{ __html: title ?? '' }} />
      </Grid>
    </Grid>
  );
};

export default ContentHeader;
