import { SvgIcon, SvgIconProps } from '@mui/material';

export function ToolsSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g id='3Nickels-Web' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='action/small/help/default-copy-2'>
          <g id='Group'>
            <rect id='Rectangle' x='0' y='0' width='25' height='25'></rect>
          </g>
          <g id='Group-6-Copy-2' transform='translate(2, 2)' fill={props.fill}>
            <path
              d='M12.761,10.4800841 L18.5872365,15.7275379 C19.556278,16.600246 19.6343717,18.0932791 18.7616635,19.0623206 C18.7341365,19.0928862 18.7058154,19.1227273 18.6767293,19.1518134 C17.7545957,20.0739471 16.2595217,20.0739471 15.337388,19.1518134 C15.308302,19.1227273 15.2799809,19.0928862 15.2524538,19.0623206 L9.98,13.2080841 L12.761,10.4800841 Z M8.56864079,2.00658156 C10.1259098,3.56385057 10.4992473,5.84604057 9.67182835,7.69755626 L10.873,8.77908409 L8.281,11.3210841 L7.22247217,10.1469124 C5.37095648,10.9743314 3.08876649,10.6009939 1.53149747,9.04372488 C-0.04578927,7.46643814 -0.408511461,5.14547382 0.460813029,3.28156588 L3.30811186,6.12891038 C3.95586301,6.77666154 5.00607514,6.77666154 5.6538263,6.12891038 C6.30157745,5.48115923 6.30157745,4.4309471 5.6538263,3.78319594 L2.8064818,0.935897115 C4.67038974,0.0665726252 6.99135405,0.429294816 8.56864079,2.00658156 Z'
              id='Combined-Shape'></path>
            <path
              d='M12.2560581,-2.29136277 L12.8964393,0.977846481 L12.2560581,2.37893616 L11.9353428,2.37863723 L11.9363529,13.7406308 C12.6822666,14.0045001 13.2166298,14.7159895 13.2166298,15.552286 L13.2166298,22.0641728 C13.2166298,22.3773015 12.9627889,22.6311425 12.6496601,22.6311425 L9.94131246,22.6311425 C9.62818374,22.6311425 9.37434276,22.3773015 9.37434276,22.0641728 L9.37434276,15.552286 C9.37434276,14.7161961 9.90844195,14.0048518 10.6540668,13.7408266 L10.6543428,2.37863723 L10.3349145,2.37893616 L9.69453335,0.977846481 L10.3349145,-2.29136277 L12.2560581,-2.29136277 Z'
              id='Combined-Shape'
              transform='translate(11.2955, 10.1699) rotate(-315) translate(-11.2955, -10.1699)'></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
