import { type CSSProperties } from '@mui/material/styles/createMixins';
// eslint-disable-next-line no-restricted-imports
import { Spacing } from '.';

export const textCenter: CSSProperties = { textAlign: 'center' };
export const verticalAlignImgPrxxs: CSSProperties = {
  '& > img': { verticalAlign: 'middle', paddingRight: Spacing.xxs },
};
export const alignCenter: CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
};
export const verticalAlignBottom: CSSProperties = {
  display: 'flex',
  verticalAlign: 'bottom',
  alignItems: 'self-end',
};
export const verticalAlignCenter: CSSProperties = {
  display: 'flex',
  verticalAlign: 'center',
  alignItems: 'center',
};
export const alignSelfCenter: CSSProperties = { alignSelf: 'center' };
