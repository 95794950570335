import { Box, Stack } from '@mui/material';
import React from 'react';
import { Spacing } from '../../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { t } from 'i18next';
import { Spacer } from '../../../../components';

export type RetirementPlanEmployerContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.EmployerContributionsFormData;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const RetirementPlanEmployerContributionsForm: React.FC<
  RetirementPlanEmployerContributionsFormProps
> = ({ contributions, params }) => {
  const { formState } = useFormContext();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.perDollarEmployerMatchRate !== undefined}
          helperText={formState.errors.perDollarEmployerMatchRate?.message?.toString()}
          defaultValue={contributions?.perDollarEmployerMatchRate}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='PerDollarEmployerMatchRate'
          name='perDollarEmployerMatchRate'
          type='percent'
          precision={0}
          max={1000}
          helpContext={
            <HelpPopover title={t('PerDollarEmployerMatchRate')}>
              <HelpPopoverText>
                Your employer will either partially or fully match the amount you put into your
                retirement plan.
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <i>
                  For example, an employer might match 50 cents for every dollar you contribute.
                  This would be considered a “per dollar employer match rate” of 50%.
                </i>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.maxPercentEmployerMatch !== undefined}
          helperText={formState.errors.maxPercentEmployerMatch?.message?.toString()}
          defaultValue={contributions?.maxPercentEmployerMatch}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='MaxPercentForMatch'
          name='maxPercentEmployerMatch'
          type='percent'
          precision={0}
          helpContext={
            <HelpPopover title={t('MaxPercentForMatch')}>
              <HelpPopoverText>
                Your employer’s match may be capped at a certain percentage, such as 5% of your
                salary.
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <i>
                  For example, an employer might match 50% of your contributions up to 5% of your
                  salary. If you save 6% of your salary into your account, only the first 5% will be
                  matched.
                </i>
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <div>
                  <strong>Note:</strong> if your employer doesn’t set a maximum in this way, just
                  leave this field blank.
                </div>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
        {!params.institutionalAccount && (
          <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
            error={formState.errors.maxDollarEmployerMatch !== undefined}
            helperText={formState.errors.maxDollarEmployerMatch?.message?.toString()}
            defaultValue={contributions?.maxDollarEmployerMatch}
            InputProps={{ readOnly: params.institutionalAccount }}
            label='MaxDollarAmountForMatch'
            name='maxDollarEmployerMatch'
            type='dollar'
            helpContext={
              <HelpPopover title={t('MaxDollarAmountForMatch')}>
                <HelpPopoverText>
                  Your employer’s match may be capped at a certain dollar amount, such as $2,000 per
                  year.
                </HelpPopoverText>
                <Spacer height='xxs' />
                <HelpPopoverText>
                  <i>
                    For example, an employer might match 50% of your contributions up to $2,000 per
                    year. If you save $3,000 into your account, only the first $2,000 will be
                    matched.
                  </i>
                </HelpPopoverText>
                <Spacer height='xxs' />
                <HelpPopoverText>
                  <div>
                    <strong>Note:</strong> if your employer doesn’t set a maximum in this way, just
                    leave this field blank.
                  </div>
                </HelpPopoverText>
              </HelpPopover>
            }
          />
        )}
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.otherEmployerContrib !== undefined}
          helperText={formState.errors.otherEmployerContrib?.message?.toString()}
          defaultValue={contributions?.otherEmployerContrib}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='FixedEmployerContibutionsOrProfitSharing'
          name='otherEmployerContrib'
          type='percent'
          precision={0}
          helpContext={
            <HelpPopover title={t('FixedEmployerContibutionsOrProfitSharing')}>
              <Spacer height='xxxs' />
              <HelpPopoverText>
                <strong>Fixed employer contribution</strong>
              </HelpPopoverText>
              <Spacer height='xxxs' />
              <HelpPopoverText>
                Your employer may contribute a fixed amount. It differs from a match because the
                amount contributed by them is not affected by how much you put into your account.
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <strong>Profit sharing</strong>
              </HelpPopoverText>
              <Spacer height='xxxs' />
              <HelpPopoverText>
                Some employers share a portion of profits with eligible employees. It’s a percentage
                of pay and can be given in the form of stocks, bonds, or cash.
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <div>
                  <strong>Note:</strong> if your employer doesn’t offer either of these, just leave
                  this field blank.
                </div>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
      </Stack>
    </Box>
  );
};

export default RetirementPlanEmployerContributionsForm;
