import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Row, Spacer } from '../../../components';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import {
  formatDateMMDDYYYY,
  formatWholeDollars,
  convertMonthsToYearMonth,
} from '../../../helpers/utilityFunctions';
import { useTranslation } from 'react-i18next';
import { RetirementAgeGoalCard } from './RetirementAgeGoalCard';
import { theme } from '../../../themes/ThemeWrapper';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';

const MortgageMonthlyPaymentHelp = (extraPrincipal: number) => {
  return (
    <HelpPopover title='Extra Paid Toward Principal'>
      <HelpPopoverText>
        This monthly payment includes an extra {formatWholeDollars(extraPrincipal)} paid toward
        principal each month.
      </HelpPopoverText>
    </HelpPopover>
  );
};

const CollegeGoalAmountHelp = () => {
  return (
    <HelpPopover title='College Goal Amount'>
      <HelpPopoverText>Why is this amount more than the cost you entered?</HelpPopoverText>
      <Spacer height='xxs' />
      <HelpPopoverText>
        We account for the time value of money, which includes looking at college cost inflation and
        other factors.
      </HelpPopoverText>
    </HelpPopover>
  );
};

interface DetailItem {
  displayText: string;
  value: string | JSX.Element;
  subText?: string | JSX.Element;
  helpContext?: React.ReactNode;
}

const resolveDetails = (
  advisedGoalPlan: Api.GoalRest,
  originalGoalPlan: Api.GoalRest,
  goalStatus: Domain.GoalStatusEnum,
  ssi: number
): DetailItem[] => {
  const details: DetailItem[] = [];

  switch (advisedGoalPlan?.goalType) {
    case Domain.GoalTypeEnum.Non_Mortgage_Debt: {
      const debtGoal = advisedGoalPlan as Api.PayOffDebtGoalRest;
      const debtPayOff = convertMonthsToYearsAndMonths(debtGoal.numberOfMonthsToPayoff ?? 0);
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisDebt',
          value: <strong>today!</strong>,
        });
      } else {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisDebt',
          value: debtPayOff,
        });
        details.push({
          displayText: 'YouNeedToPayOffThisAmountEachMonth',
          value: (
            <Grid>
              <strong>{formatWholeDollars(debtGoal.monthlyPaymentTarget ?? 0)}</strong>&nbsp;per
              month
            </Grid>
          ),
        });
        details.push({
          displayText: 'HeresHowMuchInterestYoullPayInTotal',
          value: <strong>{formatWholeDollars(debtGoal.totalInterestPaid ?? 0)}</strong>,
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Mortgage: {
      const mortgageGoal = advisedGoalPlan as Api.PayOffMortgageGoalRest;
      const mortgagePayOff = convertMonthsToYearsAndMonths(
        mortgageGoal.numberOfMonthsToPayoff ?? 0
      );
      const monthlyPayment =
        (mortgageGoal.monthlySavings ?? 0) + (mortgageGoal.extraMonthlyPrinciplePayment ?? 0);
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisMortgage',
          value: <strong>today!</strong>,
        });
      } else {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisMortgage',
          value: mortgagePayOff,
        });
        details.push({
          displayText: 'YouNeedToPayThisAmountEachMonth',
          value: (
            <Grid>
              <strong>{formatWholeDollars(monthlyPayment)}</strong>
              &nbsp;per month
            </Grid>
          ),
          helpContext:
            (mortgageGoal.extraMonthlyPrinciplePayment ?? 0) === 0
              ? undefined
              : MortgageMonthlyPaymentHelp(mortgageGoal.extraMonthlyPrinciplePayment ?? 0),
        });
        details.push({
          displayText: 'HeresHowMuchInterestYoullPayInTotal',
          value: <strong>{formatWholeDollars(mortgageGoal.totalInterestPaid ?? 0)}</strong>,
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Retirement: {
      const retirementGoal = advisedGoalPlan as Api.RetirementGoalRest;
      details.push({
        displayText: 'YourMonthlyIncomeDuringRetirement',
        value: (
          <Grid>
            <strong>{formatWholeDollars(retirementGoal.projectedMonthlyIncome ?? 0)}</strong>
            &nbsp;per month
          </Grid>
        ),
        subText: (
          <Grid>
            This number includes an estimated&nbsp;
            <strong>{formatWholeDollars(ssi)}</strong>
            /month of Social Security income.
          </Grid>
        ),
      });
      details.push({
        displayText: 'YouNeedToSaveThisAmountEachMonth',
        value: (
          <Grid>
            <strong>
              {formatWholeDollars(
                (retirementGoal.monthlySavings ?? 0) + (retirementGoal.outOfPlanSavingsAmount ?? 0)
              )}
            </strong>
            &nbsp;per month
          </Grid>
        ),
      });
      break;
    }
    case Domain.GoalTypeEnum.Cash_Giving: {
      const cashGoal = advisedGoalPlan as Api.CashGoalRest;
      details.push({
        displayText: 'YouDreamOfGivingThisAmountEachYear',
        value: (
          <Grid>
            <strong>{formatWholeDollars(cashGoal.annualGivingAmountTarget ?? 0)}</strong>&nbsp;per
            year
          </Grid>
        ),
      });
      if ((cashGoal.monthlySavings ?? 0) > 0) {
        details.push({
          displayText: 'YouNeedToGiveThisAmountEachMonth',
          value: (
            <Grid>
              <strong>{formatWholeDollars(cashGoal.monthlySavings ?? 0)}</strong>&nbsp;per month
            </Grid>
          ),
        });
        details.push({
          displayText: 'YourGivingNeedsToGoUpThisAmountEachYear',
          value: (
            <Grid>
              <strong>{formatWholeDollars(cashGoal.annualIncreaseAmountTarget ?? 0)}</strong>
              &nbsp;per year
            </Grid>
          ),
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Volunteer: {
      const volunteeringGoal = advisedGoalPlan as Domain.VolunteerGoalHolder;
      for (let i = 0; i < volunteeringGoal.items.length; i++) {
        const item = volunteeringGoal.items[i];
        const { numberOfHours, frequency } = item;
        details.push({
          displayText: item.nameOfPlace ?? '',
          value: (
            <Grid>
              <strong>{numberOfHours}</strong>&nbsp;hour{(numberOfHours ?? 0) > 1 && 's'} per&nbsp;
              {frequency === Domain.VolunteeringFrequencyEnum.Weekly ? 'week' : 'month'}
            </Grid>
          ),
        });
      }
      break;
    }
    default: {
      const target = advisedGoalPlan ?? originalGoalPlan;
      details.push({
        displayText: 'YourSavingsGoalAmount',
        value: <strong>{formatWholeDollars(originalGoalPlan.amountNeeded ?? 0).toString()}</strong>,
        helpContext:
          target.goalType === Domain.GoalTypeEnum.College ? CollegeGoalAmountHelp() : undefined,
      });
      if (
        goalStatus === Domain.GoalStatusEnum.OnTarget ||
        (goalStatus === Domain.GoalStatusEnum.PartiallyFunded && target.monthlySavings !== 0)
      ) {
        details.push({
          displayText: 'YouNeedToSaveThisAmountEachMonth',
          value: (
            <Grid>
              <strong>{formatWholeDollars(target.monthlySavings ?? 0)}</strong>&nbsp;per month
            </Grid>
          ),
        });
        details.push({
          displayText: 'YoullGetThereByThisDate',
          value: <strong>{formatDateItem(target?.goalDate)}</strong>,
        });
      }
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'YoullGetThereByThisDate',
          value: <strong>today!</strong>,
        });
      }
    }
  }

  return details;
};

const formatDateItem = (value?: Date) => {
  return value ? formatDateMMDDYYYY(value.toString()) : 'ASAP';
};

function convertMonthsToYearsAndMonths(numMonths: number) {
  const { years, months } = convertMonthsToYearMonth(numMonths);

  if (numMonths < 12) {
    const monthText = numMonths > 1 ? 'months' : 'month';
    return (
      <Typography fontFamily='Montserrat' variant='p30' color='secondary'>
        <strong>{numMonths}</strong>&nbsp;{monthText}
      </Typography>
    );
  } else {
    const yearText = years > 1 ? 'years' : 'year';
    const monthText = months > 1 ? 'months' : months === 1 ? 'month' : '';

    return (
      <Typography fontFamily='Montserrat' variant='p30' color='secondary'>
        <strong>{years}</strong>&nbsp;{yearText}
        <strong>&nbsp;{months > 0 && months}</strong>&nbsp;{monthText}
      </Typography>
    );
  }
}

export interface GoalDetailsCardProps {
  goal: Domain.GoalSummary;
  ssi: number;
}

const GoalDetailsCard: React.FC<GoalDetailsCardProps> = ({ goal, ssi }) => {
  const { t } = useTranslation();
  const includeSpouse = Hooks.useIncludeSpouse();
  const { advisedGoalPlan, goalPlan, goalStatus } = goal;
  const retirementGoal = advisedGoalPlan as Api.RetirementGoalRest;
  const originalRetirementGoal = goalPlan as Api.RetirementGoalRest;

  const details = resolveDetails(advisedGoalPlan, goalPlan, goalStatus, ssi);

  return (
    <Grid container justifyContent='center' mt={4}>
      <Grid container className='goal-summary' maxWidth='550px'>
        {details.length !== 0 && (
          <>
            {details.map((detail) => {
              return (
                <Card style={{ padding: '15px', textAlign: 'center' }}>
                  <Row>
                    <Typography variant='p16' color='secondary'>
                      {t(detail.displayText)}
                    </Typography>
                    {detail.helpContext && (
                      <Grid item ml='5px' display='inline-flex' flexDirection='row'>
                        <Tippy
                          placement='right'
                          content={detail.helpContext}
                          theme='light-nickels-theme'
                          className='help'>
                          <HelpOutline style={{ color: theme.palette.primary.main }} />
                        </Tippy>
                      </Grid>
                    )}
                  </Row>
                  <Typography mt='10px' fontFamily='Montserrat' variant='p30' color='secondary'>
                    {detail.value}
                  </Typography>
                  {detail.subText && (
                    <Typography mt='10px' variant='p12' color='secondary'>
                      {detail.subText}
                    </Typography>
                  )}
                </Card>
              );
            })}
          </>
        )}
        {advisedGoalPlan?.goalType === Domain.GoalTypeEnum.Retirement && (
          <RetirementAgeGoalCard
            includeSpouse={includeSpouse}
            retirementGoal={retirementGoal}
            originalRetirementGoal={originalRetirementGoal}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GoalDetailsCard;
