import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const ShopInvestmentAccountsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomShoppingCart />}
      label={t('ShopInvestmentAccounts')}
      content={t('LearnAboutTheDifferencesBetweenFirms')}
    />
  );
};

export default ShopInvestmentAccountsCard;
