import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../themes/ThemeWrapper';

interface HelpTitleProps {
  text: string;
  helpContext: React.ReactNode;
}

const HelpTitle: React.FC<HelpTitleProps> = ({ text, helpContext: content }) => {
  const { t } = useTranslation();

  return (
    <Grid item display='flex'>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t(text)}
      </Typography>
      <Grid item display='flex' justifyContent='flex-start' alignSelf='center' ml='5px' mt={1}>
        <Tippy placement='right' content={content} theme='light-nickels-theme'>
          <HelpOutline style={{ color: theme.palette.primary.main }} />
        </Tippy>
      </Grid>
    </Grid>
  );
};

export default HelpTitle;
