import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={2}
      headerIcon={
        <Svgs.ArtLaptop
          height='70px'
          width='auto'
          style={{ marginBottom: '7px', marginTop: '7px' }}
        />
      }
      headerText='DoYourResearch'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('BeforeStartingYourHomeSearch')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('WhatDoesTheHousingMarket'),
              t('WhatTypeOfHouse'),
              t('WhatLocationWillBestFitMyLifestyle'),
              t('FindOutIfYourStateCountyOrCity'),
            ]}
          />
        </>
      }
    />
  );
};

export default StepTwo;
