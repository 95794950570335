import { Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { firstValueFrom } from 'rxjs';

export async function iraFeeCheckerLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const iras$ = resolver.resolveSubject(Data.InvestmentAccounts.InvestmentAccountDataSubject);
  const iras = await firstValueFrom(iras$);

  return json(iras ?? null);
}
