import { Authentication, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { useObservable } from '@aesop-fables/scrinium';
import { useEffect, useState } from 'react';
import { firstValueFrom } from 'rxjs';
import { AuthLocalStorage } from '../authentication';

export * from './interimHooks';
export * from './useSubmitReady';
export * from './useLocalStorage';

export function useAuthenticationContext(): Authentication.IAuthenticationContext {
  return useService<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
}

export function useAuthenticatedFlag(): boolean {
  const context = useAuthenticationContext();
  return useObservable(context.isAuthenticated$) ?? false;
}

export function useAuthenticationReady(): boolean | undefined {
  const context = useAuthenticationContext();
  return useObservable(context.authReady$);
}

interface AuthenticationStatus {
  isSessionExpired: boolean;
  isReady: boolean;
}

export function useAuthenticationStatus(): AuthenticationStatus {
  const context = useAuthenticationContext();
  const authReady = useAuthenticationReady();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  useEffect(() => {
    async function checkAuth() {
      const current = await firstValueFrom(context.isAuthenticated$);
      const cache = AuthLocalStorage.isAuthenticated();

      setIsSessionExpired(!current && cache);
    }

    if (authReady === true) {
      checkAuth();
    }
  }, [authReady]);

  return {
    isSessionExpired,
    isReady: authReady === true,
  };
}

export function useNeedsOnboarding() {
  const userConfig = Hooks.useUserConfig();
  const productType = Hooks.useProductType();

  if (typeof userConfig === 'undefined' || typeof productType === 'undefined') return undefined;

  return (
    productType === Domain.ProductTypeEnum.advice &&
    (!userConfig?.adviceOnboarded ||
      !userConfig?.accountDetailsComplete ||
      !userConfig?.budgetDetailsReviewed)
  );
}

export function useIsFreeUser() {
  const productType = Hooks.useProductType();

  return productType === Domain.ProductTypeEnum.free;
}
