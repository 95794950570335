/* eslint-disable */
// import { St } from 'react';
import { type CSSProperties } from 'react';
import * as Spacing from './spacing';

function marginHorizontal(value: number): CSSProperties {
  return { marginLeft: value, marginRight: value };
}

function marginVertical(value: number): CSSProperties {
  return { marginTop: value, marginBottom: value };
}

function margin(value: number): string {
  return `${value}px`;
}

export const m_0: CSSProperties = {
  ...marginHorizontal(0),
  ...marginVertical(0),
};

export const mt_0: CSSProperties = { marginTop: 0 };
export const mb_0: CSSProperties = { marginBottom: 0 };
export const ml_0: CSSProperties = { marginLeft: 0 };
export const mr_0: CSSProperties = { marginRight: 0 };
export const mh_0: CSSProperties = { ...marginHorizontal(0) };
export const mv_0: CSSProperties = { ...marginVertical(0) };

export const m_xxxs: CSSProperties = {
  ...marginHorizontal(Spacing.xxxs),
  ...marginVertical(Spacing.xxxs),
};
export const mt_xxxs: CSSProperties = { marginTop: margin(Spacing.xxxs) };
export const mb_xxxs: CSSProperties = { marginBottom: margin(Spacing.xxxs) };
export const ml_xxxs: CSSProperties = { marginLeft: margin(Spacing.xxxs) };
export const mr_xxxs: CSSProperties = { marginRight: margin(Spacing.xxxs) };
export const mh_xxxs: CSSProperties = { ...marginHorizontal(Spacing.xxxs) };
export const mv_xxxs: CSSProperties = { ...marginVertical(Spacing.xxxs) };

export const m_xxs: CSSProperties = {
  ...marginHorizontal(Spacing.xxs),
  ...marginVertical(Spacing.xxs),
};

export const mt_xxs: CSSProperties = { marginTop: margin(Spacing.xxs) };
export const mb_xxs: CSSProperties = { marginBottom: margin(Spacing.xxs) };
export const ml_xxs: CSSProperties = { marginLeft: margin(Spacing.xxs) };
export const mr_xxs: CSSProperties = { marginRight: margin(Spacing.xxs) };
export const mh_xxs: CSSProperties = { ...marginHorizontal(Spacing.xxs) };
export const mv_xxs: CSSProperties = { ...marginVertical(Spacing.xxs) };

export const m_xs: CSSProperties = {
  ...marginHorizontal(Spacing.xs),
  ...marginVertical(Spacing.xs),
};
export const mt_xs: CSSProperties = { marginTop: margin(Spacing.xs) };
export const mb_xs: CSSProperties = { marginBottom: margin(Spacing.xs) };
export const ml_xs: CSSProperties = { marginLeft: margin(Spacing.xs) };
export const mr_xs: CSSProperties = { marginRight: margin(Spacing.xs) };
export const mh_xs: CSSProperties = { ...marginHorizontal(Spacing.xs) };
export const mv_xs: CSSProperties = { ...marginVertical(Spacing.xs) };

export const m_sm: CSSProperties = {
  ...marginHorizontal(Spacing.sm),
  ...marginVertical(Spacing.sm),
};
export const mt_sm: CSSProperties = { marginTop: margin(Spacing.sm) };
export const mb_sm: CSSProperties = { marginBottom: margin(Spacing.sm) };
export const ml_sm: CSSProperties = { marginLeft: margin(Spacing.sm) };
export const mr_sm: CSSProperties = { marginRight: margin(Spacing.sm) };
export const mh_sm: CSSProperties = { ...marginHorizontal(Spacing.sm) };
export const mv_sm: CSSProperties = { ...marginVertical(Spacing.sm) };

export const m_md: CSSProperties = {
  ...marginHorizontal(Spacing.md),
  ...marginVertical(Spacing.md),
};
export const mt_md: CSSProperties = { marginTop: margin(Spacing.md) };
export const mb_md: CSSProperties = { marginBottom: margin(Spacing.md) };
export const ml_md: CSSProperties = { marginLeft: margin(Spacing.md) };
export const mr_md: CSSProperties = { marginRight: margin(Spacing.md) };
export const mh_md: CSSProperties = { ...marginHorizontal(Spacing.md) };
export const mv_md: CSSProperties = { ...marginVertical(Spacing.md) };

export const m_lg: CSSProperties = {
  ...marginHorizontal(Spacing.lg),
  ...marginVertical(Spacing.lg),
};
export const mt_lg: CSSProperties = { marginTop: margin(Spacing.lg) };
export const mb_lg: CSSProperties = { marginBottom: margin(Spacing.lg) };
export const ml_lg: CSSProperties = { marginLeft: margin(Spacing.lg) };
export const mr_lg: CSSProperties = { marginRight: margin(Spacing.lg) };
export const mh_lg: CSSProperties = { ...marginHorizontal(Spacing.lg) };
export const mv_lg: CSSProperties = { ...marginVertical(Spacing.lg) };

export const m_xl: CSSProperties = {
  ...marginHorizontal(Spacing.xl),
  ...marginVertical(Spacing.xl),
};
export const mt_xl: CSSProperties = { marginTop: margin(Spacing.xl) };
export const mb_xl: CSSProperties = { marginBottom: margin(Spacing.xl) };
export const ml_xl: CSSProperties = { marginLeft: margin(Spacing.xl) };
export const mr_xl: CSSProperties = { marginRight: margin(Spacing.xl) };
export const mh_xl: CSSProperties = { ...marginHorizontal(Spacing.xl) };
export const mv_xl: CSSProperties = { ...marginVertical(Spacing.xl) };

export const m_xxl: CSSProperties = {
  ...marginHorizontal(Spacing.xxl),
  ...marginVertical(Spacing.xxl),
};
export const mt_xxl: CSSProperties = { marginTop: margin(Spacing.xxl) };
export const mb_xxl: CSSProperties = { marginBottom: margin(Spacing.xxl) };
export const ml_xxl: CSSProperties = { marginLeft: margin(Spacing.xxl) };
export const mr_xxl: CSSProperties = { marginRight: margin(Spacing.xxl) };
export const mh_xxl: CSSProperties = { ...marginHorizontal(Spacing.xxl) };
export const mv_xxl: CSSProperties = { ...marginVertical(Spacing.xxl) };
