import React from 'react';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../components/panels/CardDisplay';
import { Api } from '@3nickels/data-modules';
import { SummaryCardProps } from '../../account-details/Types';

export interface MailingInfoCardProps extends SummaryCardProps {
  mailingAddress?: Api.ContactInfoRest;
}

const MailingInfoCard: React.FC<MailingInfoCardProps> = (props) => {
  return (
    <CardDisplay headerKey='MailingAddress' actionContent='Edit' actionClick={props.onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography color='secondary' display='inline'>
            {props.mailingAddress?.mailingAddress1}
            {props.mailingAddress?.mailingAddress2 && (
              <Typography color='secondary' display='inline'>
                &nbsp;#{props.mailingAddress.mailingAddress2}
              </Typography>
            )}
          </Typography>

          <Typography color='secondary'>{`${props.mailingAddress?.mailingCity ?? ''}, ${
            props.mailingAddress?.mailingState ?? ''
          } ${props.mailingAddress?.mailingZip ?? ''}`}</Typography>
        </Grid>
      </Grid>
    </CardDisplay>
  );
};

export default MailingInfoCard;
