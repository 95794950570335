import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Data, Hooks } from '@3nickels/data-modules';
import { Box, Stack, Typography } from '@mui/material';
import { PasswordRequirements } from '../../../components';
import TextInput from '../../../components/form/TextInput';
import FormContent from '../../../components/form/FormContent';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useMessage } from '../../../hooks/useMessage';
import { passwordRegex } from '../../../sign-up/CreateAccount';

const newPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Required')
    .min(8, 'Please follow the password requirements below')
    .max(32, 'Please follow the password requirements below')
    .matches(/[0-9]/, 'Please follow the password requirements below')
    .matches(/[A-Z]/, 'Please follow the password requirements below')
    .matches(/[a-z]/, 'Please follow the password requirements below')
    .matches(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/, 'Please follow the password requirements below')
    .test('password-requirements', 'Please follow the password requirements below', (value) => {
      return passwordRegex.test(value);
    }),
  rePassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password does not match'),
});

const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const methods = useForm<Data.ChangePassword.PasswordConfirmData>({
    resolver: yupResolver(newPasswordSchema),
  });
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasANumber, setHasANumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const updatePasswordRequirements = (passwordInput: string) => {
    setHasMinChar(passwordInput.length >= 8 && passwordInput.length <= 32);
    setHasANumber(/[0-9]/.test(passwordInput));
    setHasUpperCase(/[A-Z]/.test(passwordInput));
    setHasLowerCase(/[a-z]/.test(passwordInput));
    setHasSpecialChar(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/.test(passwordInput));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    updatePasswordRequirements(newPassword);
  };

  const changePassword = async (values: Data.ChangePassword.PasswordConfirmData) => {
    try {
      const data: Data.ChangePassword.ChangePasswordData = { password: values.password };
      await commands.execute(Data.ChangePassword.Commands.ChangePassword, data);
      showMessage('Password updated!');
      navigate('/settings');
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    }
  };

  const onSubmit = methods.handleSubmit((data) => {
    changePassword(data);
  });

  return (
    <FormContent formProviderProps={methods}>
      <Box component='form' onSubmit={onSubmit}>
        <Typography mt={5} variant='p18Bold' color='secondary'>
          {t('CreateNewPassword')}
        </Typography>
        <Typography mt={1} mb={3} variant='p16' color='secondary'>
          {t('FollowPasswordLength')}
        </Typography>
        <Stack spacing={3}>
          <TextInput<Data.ChangePassword.PasswordConfirmData>
            error={methods.formState.errors.password !== undefined}
            helperText={methods.formState.errors.password?.message?.toString()}
            onChange={handlePasswordChange}
            label='EnterNewPassword'
            placeholder={t('EnterNewPassword') as string}
            name='password'
            type='password'
          />
          <PasswordRequirements
            hasMinChar={hasMinChar}
            hasSpecialChar={hasSpecialChar}
            hasANumber={hasANumber}
            hasUpperCase={hasUpperCase}
            hasLowerCase={hasLowerCase}
          />
          <TextInput<Data.ChangePassword.PasswordConfirmData>
            error={methods.formState.errors.rePassword !== undefined}
            helperText={methods.formState.errors.rePassword?.message?.toString()}
            label='ReEnterNewPassword'
            placeholder={t('ReEnterNewPassword') as string}
            name='rePassword'
            type='password'
          />
        </Stack>
        <WizardFooter onDone={onSubmit} nextLabel='Update Password' backLabel='Cancel' />
      </Box>
    </FormContent>
  );
};

export default NewPasswordForm;
