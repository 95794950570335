import { Grid, TypographyProps } from '@mui/material';
import { Children } from 'react';
import NumberedListItem from './NumberedListItem';

const NumberedList: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <Grid sx={{ listStyle: 'decimal', pl: 4 }}>
      {Children.map(children, (child, index) => (
        <NumberedListItem key={index} color={color} style={{ ...style }}>
          {child}
        </NumberedListItem>
      ))}
    </Grid>
  );
};

export default NumberedList;
