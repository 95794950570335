import { AppBar, Button, Toolbar } from '@mui/material';
import { Svgs } from '../assets/svg';
import { useLocation, useNavigate } from 'react-router-dom';
import logo3NSmall from '../assets/png/logo3NSmall.png';
import React from 'react';
import { useDeviceType } from '../hooks/useDeviceType';
import DropdownMenu from './DropdownMenu';
import Row from './Row';
import Spacer from './Spacer';

const TopNavBar: React.FC = () => {
  const { isMobile } = useDeviceType();

  // TODO active tab bottom bar
  return (
    <>
      <AppBar className='top-nav'>
        <Toolbar>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', width: '98%' }}>
            <div style={{ flexGrow: 1, display: 'block' }}>
              <img
                style={{ width: '250px', marginTop: '15px' }}
                src={logo3NSmall}
                alt='3Nickels Logo'
              />
            </div>
            {isMobile ? (
              <DropdownMenu />
            ) : (
              <Row>
                <HomeButton />
                <DropdownMenu />
              </Row>
            )}
          </Row>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

const HomeButton: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navToLandingPage = () => {
    navigate('/');
  };

  return (
    <Button
      onClick={navToLandingPage}
      variant={`${location.pathname === '/' ? 'activeGhost' : 'ghost'}`}>
      Home
      <Spacer width='xxs' />
      {location.pathname === '/' ? <Svgs.IconHouseActive /> : <Svgs.IconHouseDefault />}
    </Button>
  );
};

export default TopNavBar;
