import React, { useMemo } from 'react';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../../../themes';
import { Spacer } from '../../../../components';
import { Api } from '@3nickels/data-modules';
import { formatWholeDollars, formatYAxis } from '../../../../helpers/utilityFunctions';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ChartBar, ChartBarData } from '../../../../components/ChartBar';

type FinanceOrPayCashCalculatorResultsProps = {
  results: Api.AdviceCarFinanceOrCashResultRest | undefined;
  advancedData?: { yearsToOwn: number; loanTerm: number };
};

const FinanceOrPayCashCalculatorResults: React.FC<FinanceOrPayCashCalculatorResultsProps> = ({
  results,
  advancedData,
}) => {
  const comparisonResults = {
    costs: formatWholeDollars(
      Math.abs((results?.loanTotalCost ?? 0) - (results?.cashTotalCost ?? 0))
    ),
    cashIsLessFinancing:
      (results?.cashTotalCost ?? 0) <= (results?.loanTotalCost ?? 0) ? 'less' : 'more',
  };

  const largerChartValue =
    ((results?.cashTotalCost ?? 0) > (results?.loanTotalCost ?? 0)
      ? results?.cashTotalCost
      : results?.loanTotalCost) ?? 0;
  const yTicksStep =
    largerChartValue > 1000000
      ? 150000
      : largerChartValue > 850000
      ? 100000
      : largerChartValue > 450000
      ? 75000
      : largerChartValue > 350000
      ? 50000
      : largerChartValue > 275000
      ? 40000
      : largerChartValue > 200000
      ? 30000
      : largerChartValue > 140000
      ? 20000
      : largerChartValue > 80000
      ? 15000
      : largerChartValue > 45000
      ? 10000
      : 5000;

  const financingPeriod = useMemo(() => {
    if (!advancedData?.yearsToOwn) return `${advancedData?.loanTerm} months`;
    const loanTermInYears = (advancedData.loanTerm ?? 0) / 12;
    return loanTermInYears > advancedData.yearsToOwn
      ? `${advancedData.yearsToOwn} year${advancedData.yearsToOwn > 1 ? 's' : ''}`
      : `${advancedData.loanTerm} months`;
  }, [advancedData]);

  const chartData: ChartBarData[] = results
    ? [
        {
          x: 'f',
          y: results?.loanTotalCost ?? 0,
          color: Colors.secondaryBase,
        },
        {
          x: 'c',
          y: results?.cashTotalCost ?? 0,
          color: Colors.alternateBase,
        },
      ]
    : [];

  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Spacer height='xxs' />

          <Typography color='secondary' variant='p18Bold'>
            Total Cost
          </Typography>

          <Typography variant='p16' color='secondary'>
            {!results
              ? `Fill out your car purchase details to see the cost of financing versus paying cash.`
              : comparisonResults.costs === '$0'
              ? `Paying cash will cost you the same as financing over ${financingPeriod}.`
              : `Paying cash will cost you ${comparisonResults.costs} ${comparisonResults.cashIsLessFinancing} than financing over ${financingPeriod}.`}
          </Typography>
          <Spacer height='xxxs' />
          <Card className='tools-chart-output-box'>
            <Grid mt={2} container direction='column'>
              {!results && (
                <ChartLegend
                  items={[
                    { text: 'Financing', color: Colors.secondaryBase, bubbleLegend: true },
                    { text: 'Cash', color: Colors.alternateBase, bubbleLegend: true },
                  ]}
                  orientation='horizontal'
                />
              )}
              <ChartBar
                height={200}
                width={300}
                formatY={formatYAxis}
                formatLabel={formatWholeDollars}
                formatX={(x) => {
                  if (x === 'f') {
                    return 'Financing';
                  } else if (x === 'c') {
                    return 'Cash';
                  }
                  return '';
                }}
                yTicksStep={yTicksStep}
                data={chartData}
              />
            </Grid>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FinanceOrPayCashCalculatorResults;
