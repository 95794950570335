import { HomeSvg, Home2Svg } from './HomeSvg';
import { iPhoneSvg } from './iPhoneSvg';
import { EarPhoneSvg } from './EarPhoneSvg';
import { MenuHelpSvg } from './MenuHelpSvg';
import { SignOutSvg } from './SignOutSvg';
import { NickelsLogoSvg } from './3NickelsLogoSvg';
import { AccountSvgNav } from './AccountSvg';
import { PiggyBankSvg } from './PiggyBankSvg';
import { PalmTreeSvg } from './PalmTreeSvg';
import { InvestmentSvg } from './InvestmentSvg';
import { DebtSvg } from './DebtSvg';
import { CarSvg } from './CarSvg';
import { MenuExclamationSvg } from './MenuExclamationSvg';
import { AccountSvg } from './AccountSvg';
import { AdviceSvg } from './AdviceSvg';
import { NetWorthSvg } from './NetWorthSvg';
import { BudgetSvg } from './BudgetSvg';
import { GoalsSvg } from './GoalsSvg';
import { ToolsSvg } from './ToolsSvg';
import { SettingsSvg } from './SettingsSvg';
import { UniversitySvg } from './UniversitySvg';
import { AccountsSvg } from './AccountsSvg';
import { LearnSvg } from './LearnSvg';

// This file is used for the side nav
export default {
  HomeSvg,
  Home2Svg,
  iPhoneSvg,
  EarPhoneSvg,
  MenuHelpSvg,
  InvestmentSvg,
  MenuExclamationSvg,
  SignOutSvg,
  NickelsLogoSvg,
  AccountSvgNav,
  PiggyBankSvg,
  PalmTreeSvg,
  CarSvg,
  DebtSvg,
  AccountSvg,
  AdviceSvg,
  NetWorthSvg,
  BudgetSvg,
  GoalsSvg,
  ToolsSvg,
  SettingsSvg,
  UniversitySvg,
  AccountsSvg,
  LearnSvg,
};
