import React from 'react';
import { useTranslation } from 'react-i18next';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepOne: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={1}
      headerIcon={<Svgs.ArtUmbrella2 />}
      headerText='DecideWhereYoullGetYourInsurance'
      body={
        <BulletedList
          color='secondary'
          style={{ marginTop: '6px' }}
          children={[
            t('YourEmployerMayOfferInsurance'),
            t('IfYouDontHaveInsurance'),
            t('AFinalOption'),
          ]}
        />
      }
    />
  );
};

export default StepOne;
