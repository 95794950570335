import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const TaxBenefitsDonationsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomTax />}
      label={t('TaxBenefitsOfDonations')}
      content={t('UsethisQuickToolGiving')}
    />
  );
};

export default TaxBenefitsDonationsCard;
