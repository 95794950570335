import { Grid } from '@mui/material';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import { useLoading } from '../../../../hooks/useLoading';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../../components/form/FormContent';
import { useMemo, useState } from 'react';
import { useMessage } from '../../../../hooks/useMessage';
import FinanceOrPayCashCalculatorResults from './FinanceOrPayCashCalculatorResults';
import FinanceOrPayCashCalculatorForm from './FinanceOrPayCashCalculatorForm';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useSubject } from '@aesop-fables/scrinium';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  carPrice: Yup.string()
    .required('Required')
    .test('carPrice', 'Required', (carPrice) => {
      const cleanNumber = cleanWholeNumberStr(carPrice);
      return cleanNumber > 0;
    }),
  salesTaxRate: Yup.string().required('Required'),
  downPayment: Yup.string().required('Required'),
  interestRate: Yup.string().required('Required'),
  loanTerm: Yup.string().required('Required'),
  yearsToOwn: Yup.string().notRequired(),
  savingsRate: Yup.string().notRequired(),
});

const cleanValues = (values: Api.AdviceCarFinanceOrCashRest) => {
  return {
    carPrice: Number((values.carPrice?.toString() ?? '').replace(/[$,]/g, '')),
    salesTaxRate: Number(values.salesTaxRate),
    downPayment: Number((values.downPayment?.toString() ?? '').replace(/[$,]/g, '')),
    interestRate: Number(values.interestRate),
    loanTerm: Number(values.loanTerm),
    yearsToOwn: Number(values.yearsToOwn),
    savingsRate: Number(values.savingsRate),
  };
};

const FinanceOrPayCashCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Api.AdviceCarFinanceOrCashResultRest | undefined>(
    undefined
  );
  const [pdfParams, setPdfParams] = useState<Api.AdviceCarFinanceOrCashRest | undefined>(undefined);
  const [advancedData, setAdvancedData] = useState<
    { yearsToOwn: number; loanTerm: number } | undefined
  >(undefined);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Api.AdviceCarFinanceOrCashRest>(
    SavedFormsKeyEnum.FinanceOrPayCash
  );
  const salesTaxRate = useSubject(Data.Savings.DefaultSalesTaxSubject);
  const defaultValues: Api.AdviceCarFinanceOrCashRest = useMemo(
    () =>
      formData && formData.carPrice
        ? formData
        : {
            carPrice: 0,
            salesTaxRate: salesTaxRate ?? 0,
            downPayment: 0,
            interestRate: 0,
            loanTerm: undefined,
            yearsToOwn: 0,
            savingsRate: 0,
          },
    [salesTaxRate, formData]
  );

  const methods = useForm<Api.AdviceCarFinanceOrCashRest>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: Api.AdviceCarFinanceOrCashRest) => {
    setLoading(true);

    try {
      const cleanedValues = cleanValues(values);
      setAdvancedData({
        yearsToOwn: cleanedValues.yearsToOwn ?? 0,
        loanTerm: cleanedValues.loanTerm ?? 0,
      });
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.FinanceOrPayCash,
        formData: cleanedValues,
      });
      const results = await commands.execute(
        Data.Cars.Commands.CalculateAdviceCarFinanceOrCashOutput,
        cleanedValues
      );
      setResults(results);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!pdfParams) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Cars.Commands.CalculateAdviceCarFinanceOrCashOutputPdf,
        pdfParams
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'FinanceVsCashCarCalculatorResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const header = results ? <ToolHeader title='FinanceVsCash' onDownload={onDownload} /> : undefined;

  if (typeof formData === 'undefined') return null;
  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={header}
            inputColumn={
              <FinanceOrPayCashCalculatorForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                defaultValues={defaultValues}
              />
            }
            outputColumn={
              <FinanceOrPayCashCalculatorResults results={results} advancedData={advancedData} />
            }></ToolTemplate>
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(FinanceOrPayCashCalculatorView, meta);
