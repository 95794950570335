import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Svgs } from '../assets/svg';
import { Backdrop, Box, IconButton, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { Observable } from 'rxjs';
import { ProfileData } from '../models/ProfileData';
import { useDeviceType } from '../hooks/useDeviceType';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Data } from '@3nickels/data-modules';
import { API_BASE_URL } from '../configuration/Environment';
import Column from './Column';
import Row from './Row';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 8,
    'marginTop': theme.spacing(3),
    'minWidth': 300,
    'color': theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '15px 15px',
    },
  },
}));

export default function DropdownMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const formRef = React.useRef<HTMLFormElement>(null);

  const { isMobile } = useDeviceType();

  const appStorage = useAppStorage();
  const personCache = appStorage.retrieve<Data.People.PersonCompartments>(
    Data.People.personStorageKey
  );
  const personData = useObservable(personCache.observe$('person') as Observable<ProfileData>);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navToLandingPage = () => {
    navigate('/');
  };

  return (
    <div>
      {isMobile ? (
        <IconButton
          id='demo-customized-button'
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <Svgs.ActionSmallGrip />
        </IconButton>
      ) : (
        <Button
          id='demo-customized-button'
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          variant={`${location.pathname !== '/' ? 'activeGhost' : 'ghost'}`}
          disableElevation
          onClick={handleClick}
          endIcon={
            location.pathname !== '/' ? (
              <Svgs.DisplaySmallCustomProfileActive />
            ) : (
              <Svgs.DisplaySmallCustomProfileDefault />
            )
          }>
          {personData ? personData.firstName : `FirstName`}
        </Button>
      )}
      {/* TODO ensure backdrop does not overlap with TopNavBar, not sure if having TopNavBar in route file will fix */}
      <Backdrop open={open} onClick={handleClose}>
        <StyledMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <Column>
            <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end' }}>
              <Svgs.IconNavAlertDefault />
            </IconButton>
          </Column>
          {isMobile && (
            <Box>
              <MenuItem onClick={navToLandingPage} disableRipple>
                <Row>
                  <Svgs.IconHouseActive
                    style={{ alignSelf: 'center', marginLeft: '-2px', marginRight: '20px' }}
                  />
                  <Column>
                    <Typography variant='p18Bold'>Home</Typography>
                  </Column>
                </Row>
              </MenuItem>
              <Divider color='secondary' />
            </Box>
          )}
          <MenuItem onClick={() => navigate('/profile')} disableRipple>
            <Row>
              <Svgs.DisplaySmallCustomProfileActive
                style={{ alignSelf: 'center', marginLeft: '-2px', marginRight: '20px' }}
              />
              <Column>
                <Typography variant='p18Bold'>
                  {personData ? personData.firstName : `FirstName`}
                </Typography>
                <Typography>View Profile</Typography>
              </Column>
            </Row>
          </MenuItem>
          <Divider color='secondary' />
          <MenuItem onClick={() => navigate('/payment')} disableRipple>
            <Svgs.DisplaySmallCustomCreditCard
              style={{ alignSelf: 'center', marginRight: '25px' }}
            />
            Payment
          </MenuItem>
          <MenuItem onClick={() => navigate('/contact-us')} disableRipple>
            <Svgs.DisplayHamburgerMenuHeadset
              style={{ alignSelf: 'center', marginRight: '25px' }}
            />
            Contact Us
          </MenuItem>
          <Divider color='secondary' />
          <form ref={formRef} method='post' action={`${API_BASE_URL}/logout`}>
            <MenuItem onClick={() => formRef.current?.submit()} disableRipple>
              <Svgs.DisplayHamburgerMenuSignOut
                style={{ alignSelf: 'center', marginRight: '25px' }}
              />
              Sign Out
            </MenuItem>
            <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
          </form>
        </StyledMenu>
      </Backdrop>
    </div>
  );
}
