import { Hooks } from '@3nickels/data-modules';
import { useEffect } from 'react';
import { AccountDetailsLayoutMeta } from '../pages/account-details/AccountDetailsLayout';
import { useOutletContext } from 'react-router-dom';
import { LayoutContext, LayoutMeta } from '../types/LayoutMeta';

export function useRetirementPlanMeta(editing?: boolean) {
  const { setMeta } = useOutletContext<LayoutContext>();
  const navigationState = Hooks.useRetirementWizardNavigation();

  useEffect(() => {
    if (!editing && navigationState) {
      const currentStep = navigationState.steps.indexOf(navigationState.currentStep) + 1;
      const steps = navigationState.steps.length;
      setMeta((previous) => {
        const meta: LayoutMeta<AccountDetailsLayoutMeta> = {
          ...previous,
          headerVariant: 'hex',
          hexSteps: { currentStep, steps },
        };
        return meta;
      });
    }
  }, [editing, navigationState]);
}
