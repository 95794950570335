import React, { useState } from 'react';
import { API_URL } from '../../configuration/Environment';
import { universitySchoolSquare } from '../../assets/png';

interface LmsMediaProps {
  title: string;
  id?: number;
  icon?: boolean;
  style?: React.CSSProperties;
}
export const LmsMedia: React.FC<LmsMediaProps> = ({ title, id, icon, style }) => {
  const [hasError, setHasError] = useState<boolean>(false);

  if (!id || hasError) {
    return (
      <img
        src={universitySchoolSquare}
        alt={`${title} ${icon ? 'Icon' : 'Background'}`}
        style={{ ...style, maxHeight: 140 }}
      />
    );
  }

  return (
    <img
      className='tools-start-view-background'
      src={`${API_URL}lms/media/${id}`}
      onError={() => setHasError(true)}
      crossOrigin='use-credentials'
      alt={`${title} ${icon ? 'Icon' : 'Background'}`}
      style={{ ...style }}
    />
  );
};
