import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { carCircle } from '../../../../assets/png';
import { Svgs } from '../../../../assets/svg';
import ToolHeader from '../../../../components/ToolHeader';
import { IconContentRow } from '../../../../components/IconContentRow';
import { Spacer } from '../../../../components';

export const TopSection: React.FC = () => {
  return (
    <Grid mb={2} container flexDirection='row'>
      <Grid sx={{ width: '65%' }} container flexDirection='column'>
        <Stack spacing={3}>
          <ToolHeader title='WhatYouNeedToKnowAutoInsurance' />
          <Typography variant='p18Bold' color='secondary'>
            {t('PricingInsurance')}
          </Typography>
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomBirthdayCake />}
            title='Age'
            text='OlderIsBetter'
          />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomGender />}
            title='Gender'
            text='FemalesSafer'
          />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomHeart />}
            title='MaritalStatus'
            text='MarriedSafer'
          />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomBriefcase />}
            title='Occupation'
            text='CertainOccupations'
          />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomLocation />}
            title='ZipCode'
            text='WhereMostAccidentsOccur'
          />
          <Spacer height='xxxs' />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomSpeedometer />}
            title='AnnualMileage'
            text='MoreDrivingHigherRisk'
          />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomCarSecondary />}
            title='VehicleInfo'
            text='VehicleRisk'
          />
          <Spacer height='xxxs' />
          <IconContentRow
            icon={<Svgs.DisplayMediumCustomCreditCard />}
            title='CreditHistory'
            text='FiscalResponsibility'
          />
          <IconContentRow
            icon={<Svgs.DisplaySmallCustomCollision />}
            title='DrivingHistory'
            text='SignificantImpact'
            bulletItems={[
              'DUI',
              'Speeding ticket in the last 5 years',
              'At-fault accident',
              'Driving schools',
            ]}
          />
        </Stack>
      </Grid>

      <Grid mt={15} sx={{ width: '30%' }} container flexDirection='column'>
        <img src={carCircle} />
      </Grid>
    </Grid>
  );
};
