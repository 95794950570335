/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AutoSaveStatus } from './useAutoSaveHandler';

export interface IAutoSaveContext {
  getStatus: (key: string) => AutoSaveStatus | undefined;
  setSaving: (keys: string[]) => void;
  setComplete: (keys: string[]) => void;
  clearStatus: (keys: string[]) => void;
}

const AutoSaveContext = createContext<IAutoSaveContext>({
  getStatus: () => undefined,
  setSaving: () => {},
  setComplete: () => {},
  clearStatus: () => {},
});

export const AutoSaveProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [status, setStatus] = useState<Map<string, AutoSaveStatus | undefined>>(new Map());

  const getStatus = useCallback(
    (key: string) => {
      return status.get(key);
    },
    [status]
  );

  const setSaving = useCallback(
    (keys: string[]) => {
      const savingMap = new Map();
      keys.forEach((key) => savingMap.set(key, 'saving'));
      setStatus(savingMap);
    },
    [status, setStatus]
  );

  const setComplete = useCallback(
    (keys: string[]) => {
      const completeMap = new Map();
      keys.forEach((key) => completeMap.set(key, 'completed'));
      setStatus(completeMap);
    },
    [status, setStatus]
  );

  const clearStatus = useCallback(
    (keys: string[]) => {
      keys.forEach((key) => {
        setStatus((previous) => {
          const clearedMap = new Map(previous);
          clearedMap.delete(key);
          return clearedMap;
        });
      });
    },
    [status, setStatus]
  );

  const value = useMemo(
    () => ({ getStatus, setSaving, setComplete, clearStatus }),
    [getStatus, setSaving, setComplete, clearStatus]
  );

  return <AutoSaveContext.Provider value={value}>{children}</AutoSaveContext.Provider>;
};

export function useAutoSaveStatus() {
  const context = useContext(AutoSaveContext);
  if (!context) {
    throw new Error('useAutoSaveContext must be used within AutoSaveProvider');
  }

  return context;
}
