import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const MortgageRefinanceCalculatorCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomCalculator />}
      label={t('MortgageRefinanceCalculator')}
      content={t('SeeIfRefinancing')}
    />
  );
};

export default MortgageRefinanceCalculatorCard;
