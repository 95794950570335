import { Grid, Button } from '@mui/material';
import { t } from 'i18next';
import { Colors } from '../../themes';
import { AllLessonsButton, AllLessonsButtonProps, LmsLayoutMeta } from './LmsLayout';
import { useNavigate } from 'react-router-dom';
import { LayoutMeta } from '../../types/LayoutMeta';
import { useEffect } from 'react';
import { useContentView } from '../layout/ContentViewContext';

interface LmsFooterProps extends AllLessonsButtonProps {
  meta: LayoutMeta<LmsLayoutMeta>;
}

const LmsFooter: React.FC<LmsFooterProps> = ({
  course,
  yourLessonsView,
  expanded,
  setExpanded,
  meta,
}) => {
  const navigate = useNavigate();
  const { setId } = useContentView();

  useEffect(() => {
    setId('min');

    return () => {
      setId(undefined);
      setExpanded(false);
    };
  }, []);

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Grid gridArea='sidebar' display='flex' flexDirection='column' justifyContent='flex-end'>
        <Grid sx={{ backgroundColor: Colors.tertiaryBase, paddingY: '25px', paddingX: '20px' }}>
          <AllLessonsButton
            course={course}
            yourLessonsView={yourLessonsView}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </Grid>
      </Grid>
      <Grid
        className='footer-content'
        gridArea='footer'
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'>
        <Grid
          className='content'
          sx={{
            backgroundColor: Colors.tertiaryBase,
            paddingY: '25px',
          }}>
          <Grid className='children' width='100%' display='flex' justifyContent='space-between'>
            <Button
              color='secondary'
              variant='outlined'
              onClick={onBack}
              sx={{ minWidth: '200px' }}>
              {t(meta.backLocaleKey ?? 'Back')}
            </Button>
            {typeof meta.centerComponent !== 'undefined' && meta.centerComponent}
            <Button
              color='secondary'
              onClick={meta.onNext}
              sx={{ minWidth: '200px' }}
              disabled={meta.disableNextButton}>
              {t(meta.nextLocaleKey ?? 'Next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LmsFooter;
