import { Domain } from '@3nickels/data-modules';
export interface LinkAccountFormData {
  id?: number;
  name?: string;
  owner?: Domain.Owner;
  maritalStatus?: boolean;
}

export declare type LinkAccountWizardParams = {
  id?: number;
};

export enum PlaidAccountStatusEnum {
  Complete = 0,
  Incomplete = 1,
  NotImported = 2,
  Unlinked = 3,
}

export enum PlaidAccountType {
  CashAccount = 0,
  Investment = 1,
  Debt = 2,
}
