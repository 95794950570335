import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';

const PMIHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('PMI')}>
      <HelpPopoverText>{t('IfYouWerePayingPMI')}</HelpPopoverText>
    </HelpPopover>
  );
};

export default PMIHelp;
