import { Drawer } from '@mui/material';

interface ActionSheetProps extends React.PropsWithChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionSheet: React.FC<ActionSheetProps> = (props) => {
  const { open, setOpen } = props;

  return (
    <Drawer className='action-sheet' anchor='bottom' open={open} onClose={() => setOpen(false)}>
      {props.children}
    </Drawer>
  );
};
export default ActionSheet;
