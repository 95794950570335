import styled from '@emotion/styled';

export const Container = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 16px;
  position: relative;
  width: 100%;
  color: #4a606a;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const Header = styled.div`
  color: #4a606a;
  font-size: 30px;
  line-height: 38px;
`;

export const PolicyText = styled.div`
  font-size: 24px;
`;
