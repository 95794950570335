/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Typography } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import ContentView from '../layout/Content';
import HexHeader from '../layout/header-variants/HexHeader';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { Loading } from '../../hooks/useLoading';
import { useContentView } from '../layout/ContentViewContext';

export type PersonalDetailsLayout = Record<string, unknown>;

export declare type PersonalDetailsLayoutMeta = {
  step: keyof Data.People.PersonWizard | 'summary';
};

const PersonalDetailsWizardGuard: FC<PersonalDetailsLayout> = () => {
  const { wizard } = Hooks.usePersonalDetailsWizard();
  // const resolver = useService<ISubjectResolver>(ScriniumServices.SubjectResolver);
  // const isIncomeReady$ = resolver.resolveSubject(AppReadySubject);
  return (
    <ObservableGuard
      predicate$={wizard.isStarted$}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      <PersonalDetailsLayout />
    </ObservableGuard>
  );
};

// This layout is used exclusively for wrapping the Personal Details wizard screens
const PersonalDetailsLayout: FC<PersonalDetailsLayout> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { wizard } = Hooks.usePersonalDetailsWizard();
  const isStarted = useObservableGuardCondition();
  const { meta, setMeta } = useLayoutMeta<PersonalDetailsLayoutMeta>();
  const isSummary = location.pathname.toString().includes('summary') ? true : false;
  const { sideNav } = useContentView();

  useEffect(() => {
    if (typeof isStarted === 'undefined' || !isStarted) {
      wizard.start({});
    }
  }, [isStarted]);

  return (
    <>
      <Backdrop open={!isStarted}>
        <Loading />
      </Backdrop>
      {isStarted && (
        <ContentView
          sideNavMenuItems={sideNav()}
          headerChildren={
            meta.step === 'summary' ? (
              <Typography variant='p18Bold' color='primary' alignSelf='end'>
                {t('PersonalDetails')}
              </Typography>
            ) : (
              <HexHeader
                stepProps={isSummary ? undefined : meta.hexSteps}
                startAdornment={
                  <Typography className='hex-header' fontWeight='bold' color='primary'>
                    {t('PersonalDetails')}
                  </Typography>
                }
              />
            )
          }>
          <Outlet context={{ setMeta }} />
        </ContentView>
      )}
    </>
  );
};

export default PersonalDetailsWizardGuard;
