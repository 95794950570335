import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Typography } from '@mui/material';
import { Colors } from '../../../../themes';
import { Domain } from '@3nickels/data-modules';
import { formatWholeDollars, formatYAxis } from '../../../../helpers/utilityFunctions';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ChartLine, ChartLineSeries, ChartYLinesData } from '../../../../components/ChartLine';

type RentOrBuyResultsProps = {
  results: Domain.AdviceHrobResultData | undefined;
};

const RentOrBuyResults: React.FC<RentOrBuyResultsProps> = ({ results }) => {
  const { t } = useTranslation();
  const breakEvenPoint = useMemo(() => {
    return results?.breakEvenPoint ?? 0;
  }, [results]);
  const cumulativeBuyCostData = results?.cumulativeBuyCost ?? [];
  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(
    breakEvenPoint <= 0 ? cumulativeBuyCostData.length - 1 : breakEvenPoint
  );
  const cumulativeBuyCost = results?.cumulativeBuyCost
    ? results.cumulativeBuyCost[selectedYearIndex]
    : 0;
  const cumulativeRentCost = results?.cumulativeRentCost
    ? results.cumulativeRentCost[selectedYearIndex]
    : 0;

  const [mouseLeftChart, setMouseLeftChart] = useState<boolean>(false);
  const [chartYLinesData, setChartYLinesData] = useState<ChartYLinesData[]>(
    createChartYLinesData(results)
  );
  const chartData: ChartLineSeries[] = createChartData(results);

  const handleChartClicked = (pt: { x: number; y: number }) => {
    if (typeof results === 'undefined') return;
    setSelectedYearIndex(pt.x);
    setChartYLinesData(createChartYLinesData(results, pt.x));
  };

  useEffect(() => {
    if (typeof results === 'undefined') return;
    setSelectedYearIndex(breakEvenPoint <= 0 ? cumulativeBuyCostData.length - 1 : breakEvenPoint);
    setChartYLinesData(createChartYLinesData(results));
  }, [results, mouseLeftChart]);

  return (
    <Grid container mt={3} direction='column' rowGap='20px'>
      <Grid>
        {typeof results === 'undefined' ? (
          <Typography variant='p16' color='secondary' align='center'>
            {t('FillOutYourHousingDetails')}
          </Typography>
        ) : breakEvenPoint > 0 ? (
          <Typography variant='p16' color='secondary' align='center'>
            If you stay in your home for {results?.breakEvenPoint} year
            {(results?.breakEvenPoint ?? 0) > 1 && 's'} or more, buying is cheaper than renting.
          </Typography>
        ) : (
          <Typography variant='p16' color='accent' align='center'>
            {t('SinceTheBreakEvenPeriod')}
          </Typography>
        )}
      </Grid>
      <Grid>
        <Card className='tools-chart-output-box'>
          <Grid mt={1} container direction='column'>
            <ChartLegend
              orientation='horizontal'
              items={[
                { text: 'Buy', color: Colors.secondaryBase, bubbleLegend: true },
                { text: 'Rent', color: Colors.alternateBase, bubbleLegend: true },
                { text: 'Break-even', color: Colors.primaryBase, dashed: true },
                { text: 'Selected', color: Colors.accentBase, dashed: true },
              ]}
            />
            <ChartLine
              height={225}
              formatY={(y) => {
                if (typeof results === 'undefined') {
                  return '';
                }
                return formatYAxis(y);
              }}
              data={chartData}
              yLines={chartYLinesData}
              onClick={handleChartClicked}
              mouseLeftChart={mouseLeftChart}
              setMouseLeftChart={setMouseLeftChart}
            />
            <Typography
              mb={1}
              variant='p12'
              color='accent'
              display={typeof results !== 'undefined' ? 'flex' : 'none'}
              alignItems='center'
              justifyContent='center'>
              {t('ClickOnTheChart')}
            </Typography>
          </Grid>
        </Card>
      </Grid>

      <Grid
        container
        mt={15}
        className='year-output'
        display={typeof results !== 'undefined' ? 'flex' : 'none'}>
        <Grid container padding='2px 10px'>
          <Grid item xs={3}>
            <Typography variant='p18Bold' color='primary'>
              Year {selectedYearIndex}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='p16Bold' color='secondary' display='flex' justifyContent='right'>
              {t('Rent')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='p16Bold' color='secondary' display='flex' justifyContent='right'>
              {t('Buy')}
            </Typography>
          </Grid>
        </Grid>
        <Card sx={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='p16' color='secondary'>
                {t('MonthlyCost')}
              </Typography>
              <Typography mt={1} variant='p16' color='secondary'>
                {t('TotalCost')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className='right-align'>
                {formatWholeDollars(results?.rentMonthlyCost ?? 0)}
              </Typography>
              <Typography mt={1} className='right-align'>
                {formatWholeDollars(cumulativeRentCost)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className='right-align'>
                {formatWholeDollars(results?.buyMonthlyCost ?? 0)}
              </Typography>
              <Typography mt={1} className='right-align'>
                {formatWholeDollars(cumulativeBuyCost)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RentOrBuyResults;

function createChartData(resultData: Domain.AdviceHrobResultData | undefined): ChartLineSeries[] {
  return [
    {
      color: Colors.secondaryBase,
      data: resultData?.cumulativeBuyCost?.map((y, i) => {
        return {
          x: i,
          y: y,
        };
      }) ?? [
        {
          x: 0,
          y: 0,
        },
      ],
    },
    {
      color: Colors.alternateBase,
      data: resultData?.cumulativeRentCost?.map((y, i) => {
        return {
          x: i,
          y: y,
        };
      }) ?? [
        {
          x: 0,
          y: 0,
        },
      ],
    },
  ];
}

function createChartYLinesData(
  resultData: Domain.AdviceHrobResultData | undefined,
  ptxSelected?: number
): ChartYLinesData[] {
  const breakEvenPoint = resultData?.breakEvenPoint ?? 0;
  const cumulativeBuyCostData = resultData?.cumulativeBuyCost ?? [];
  if (typeof resultData === 'undefined')
    return [
      {
        x: 0,
        color: Colors.primaryBase,
        type: 'dashed',
      },
      {
        x: 0,
        color: Colors.accentBase,
        type: 'dashed',
      },
    ];
  return [
    {
      x: breakEvenPoint <= 0 ? cumulativeBuyCostData.length - 1 : breakEvenPoint,
      color: Colors.primaryBase,
      type: 'dashed',
    },
    {
      x: ptxSelected ? ptxSelected : 0,
      color: Colors.accentBase,
      type: 'dashed',
    },
  ];
}
