import { Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountSvg } from '../../../../components/images/AccountSvg';
import { Spacing } from '../../../../themes';

const NoAccountsFoundCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card className='empty-account-card' style={{ minHeight: '100px', overflow: 'hidden' }}>
      <Grid item sm={11} display='flex' flexDirection='column' alignItems='center'>
        <Grid container flexDirection='row' justifyContent='center'>
          <AccountSvg style={{ marginRight: Spacing.xs }} />
          <Typography color='secondary' fontWeight='bold'>
            {t('NoAccountsFound')}
          </Typography>
        </Grid>
        <Divider color='primary.light' style={{ width: '100%', margin: Spacing.xs }} />
        <Typography color='error'>{t('WeCouldntFind')}</Typography>
      </Grid>
    </Card>
  );
};

export default NoAccountsFoundCard;
