import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import AlertMenu, { Alert } from './AlertMenu';
import { Hooks } from '@3nickels/data-modules';

const AlertsView: React.FC = () => {
  const { t } = useTranslation();
  const communicationsSettings = Hooks.useCommunicationsSettings();
  const alertOptions: Alert[] = [
    {
      header: 'PersonalizedFinancialTips',
      subHeader: 'WellKeepYouPostedAboutNewFeatures',
      icon: <Svgs.DisplaySmallCustomLightBulb />,
      communicationsSettings: [
        {
          name: 'RECEIVE_FINANCIAL_TIPS_EMAIL',
          label: 'Emails',
          value: communicationsSettings?.RECEIVE_FINANCIAL_TIPS_EMAIL ?? false,
        },
      ],
    },
    {
      header: 'OffersAndPromotions',
      subHeader: 'WellKeepYouPostedAboutAllThings3Nickels',
      icon: <Svgs.DisplaySmallCustomPromotions />,
      communicationsSettings: [
        {
          name: 'RECEIVE_OFFERS_AND_PROMOTIONS_EMAIL',
          label: 'Emails',
          value: communicationsSettings?.RECEIVE_OFFERS_AND_PROMOTIONS_EMAIL ?? false,
        },
      ],
    },
  ];

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography variant='p30Bold' component='h1' color='primary'>
          {t('Alerts')}
        </Typography>
        {alertOptions.map((option, index) => {
          return <AlertMenu key={index} option={option} />;
        })}
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(AlertsView, meta);
