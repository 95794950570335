import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LinkAccountHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography className='header-text' color='primary' fontWeight='bold'>
        {t('LinkAnAccount')}
      </Typography>
    </>
  );
};

export default LinkAccountHeader;
