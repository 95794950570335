/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import {
  BudgetSectionProps,
  TransportFormData,
  calcHousingPayment,
  getAdjustedRealEstateDebtPayment,
} from '../../Types';
import { Divider, Grid, Typography } from '@mui/material';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import EmbeddedList from '../../../../components/EmbeddedList';
import { useNavigate } from 'react-router';
import { Colors } from '../../../../themes';
import { LeaveBudgetDetailsModal } from '../../BudgetDetailsLayout';
import { SpendingProps } from '../Types';
import { t } from 'i18next';
import { CoversExpensesModal } from './CoversExpensesModal';

export declare type DebtFormProps = BudgetSectionProps;

export const DebtForm: React.FC<DebtFormProps> = ({ mode }) => {
  const navigate = useNavigate();

  const debts = Hooks.useCombinedDebts();
  const realEstateDebts = Hooks.useCombinedRealEstateData().filter(
    (realEstate) => calcHousingPayment(realEstate) > 0
  );
  const creditCards = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.CREDIT_CARD),
    [debts]
  );
  const medicalDebts = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.MEDICAL_DEBT),
    [debts]
  );
  const studentLoans = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.STUDENT_LOAN),
    [debts]
  );
  const personalLoans = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.PERSONAL_LOAN),
    [debts]
  );
  const otherDebts = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.OTHER),
    [debts]
  );

  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Domain.CreditCardData | undefined>();

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <Grid className='embedded-form'>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <CoversExpensesModal
        open={typeof selectedCard !== 'undefined'}
        setOpen={() => setSelectedCard(undefined)}
        creditCard={selectedCard}
      />
      <EmbeddedList<Domain.CreditCardData>
        items={creditCards}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Credit Cards'
        emptyHeader='No Credit Cards Added'
        titleFn={(x) => (
          <Grid container flexDirection='column' rowGap='5px'>
            {x.name ?? ''}
            <Typography
              className='clickable'
              variant='p12'
              color='primary'
              fontWeight='normal'
              onClick={() => setSelectedCard(x)}>
              {x.coversExpenses ? t('CoversBudgetExpenses') : t('NotCoverBudgetExpenses')}
            </Typography>
          </Grid>
        )}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={medicalDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Medical Debts'
        emptyHeader='No Medical Debts Added'
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={studentLoans}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Student Loans'
        emptyHeader='No Student Loans Added'
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={personalLoans}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Personal Loans'
        emptyHeader='No Personal Loans Added'
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.RealEstateData>
        items={realEstateDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Real Estate Debts'
        emptyHeader='No Real Estate Debts Added'
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) => formatWholeDollars(getAdjustedRealEstateDebtPayment(x, mode))}
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={otherDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Other Debts'
        emptyHeader='No Other Debts Added'
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      />
    </Grid>
  );
};

export const DebtSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  spendingSummary,
}) => {
  const methods = useForm<TransportFormData>();

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.debts),
    [spendingSummary, mode]
  );

  return (
    <CollapsiblePanel
      variant='compressed'
      header='Debt'
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomDebt />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <DebtForm mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
