import * as Page from './style';
import { Spacer } from '../components';
import { logo3NSmall as logo } from '../assets/png';
import { Link } from 'react-router-dom';
import { PHONE_NUMBER } from '../pages/contact-us/ContactOptions';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';

const CancellationPolicy = () => {
  return (
    <PageContentWithBackground useContainer={false}>
      <Page.Container>
        <Page.LogoContainer>
          <Link to='/'>
            <img style={{ maxWidth: '220px' }} src={logo} alt='Logo' />
          </Link>
        </Page.LogoContainer>
        <Spacer height='s25' />
        <Page.Header>Cancellation and Refund Policy</Page.Header>
        <Spacer height='xs' />
        <Page.PolicyText>
          You may cancel your subscription at any time, though no refunds will be made for the time
          remaining on your current subscription period.
        </Page.PolicyText>
        <Spacer height='xs' />
        <Page.PolicyText>
          Refunds on new subscriptions are available only if you have not yet completed the setup of
          your account.
        </Page.PolicyText>
        <Spacer height='xs' />
        <Page.PolicyText>
          If you have questions or would like to request a refund, please contact
          GuidedChoice/3Nickels Customer Service at {PHONE_NUMBER}, Monday thru Friday, 8:00 a.m. to
          5:00 p.m. Pacific Time, help@guidedchoice.com, or help@3nickels.com.
        </Page.PolicyText>
      </Page.Container>
    </PageContentWithBackground>
  );
};

export default CancellationPolicy;
