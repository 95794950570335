import { Grid } from '@mui/material';
import { Spacing } from '../../themes';
import NextLessonCard from './NextLessonCard';
import { Hooks } from '@3nickels/data-modules';
import UnitProgressCard from './components/UnitProgressCard';
import CoachingSessionPanel from './components/CoachingSessionPanel';
import { useMemo } from 'react';
import LmsCourseCertificatePanel from '../learning-management-system/LmsCourseCertificatePanel';

const UniversityOverview: React.FC = () => {
  const units = Hooks.useUniversityUnits();
  const nextLesson = Hooks.useNextUniversityLesson();
  const coachingSessionUrl = Hooks.useCoachingSessionUrl();
  const course = Hooks.useUniversityCourse();
  const lessonStats = useMemo(() => {
    let totalCount = 0;
    let completeCount = 0;
    course?.units.map((unit) => {
      totalCount += unit.lessons.length;
      const filtered = unit.lessons.filter((lesson) => lesson.complete);
      completeCount += filtered.length;
    });
    return { totalLessons: totalCount, lessonsCompleted: completeCount };
  }, [course?.units]);

  return (
    <Grid display='flex' flexDirection='column' rowSpacing={Spacing.lg} rowGap='20px'>
      <NextLessonCard />
      <Grid className='3NU-overview-content' display='flex' columnGap='20px'>
        <Grid className='unit-section' maxWidth='67%'>
          {units.map((unit) => (
            <UnitProgressCard unit={unit} selectedLesson={nextLesson} />
          ))}
        </Grid>
        <Grid display='flex' flexDirection='column' justifyContent='start' rowGap='20px'>
          <CoachingSessionPanel coachingSessionUrl={coachingSessionUrl} />
          <LmsCourseCertificatePanel
            course={{
              completionPercentage: course?.progress,
              totalLessons: lessonStats.totalLessons,
              lessonsCompleted: lessonStats.lessonsCompleted,
              courseId: course?.id,
              name: course?.title,
            }}
            university
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UniversityOverview;
