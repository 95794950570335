import React from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';
import { TextInputProps } from './TextInput';

export default function PhoneNumberInput<T>(props: TextInputProps<T>) {
  const [formattedValue, setFormattedValue] = React.useState<string>('');
  const methods = useFormContext();

  const phoneNumberHandler = (value: string) => {
    let returnNumber = '';
    if (value.length === 0 || value.length === 2) {
      setFormattedValue('');
      return returnNumber;
    }

    const numbers = cleanWholeNumberStr(value, { excludeNegatives: true });
    const numberString = String(numbers === 0 ? '' : numbers);

    if (!value.includes(')') && numberString.length >= 2 && numberString.length <= 3) {
      const phoneNumber = numberString.slice(0, numberString.length - 1);
      returnNumber = `(${phoneNumber})`;
      setFormattedValue(returnNumber);
      return returnNumber;
    }

    if (numberString.length >= 1 && numberString.length <= 3) {
      returnNumber = `(${numberString})`;
      setFormattedValue(returnNumber);
      return returnNumber;
    }

    if (numberString.length >= 4 && numberString.length <= 6) {
      const group1 = numberString.slice(0, 3);
      const group2 = numberString.slice(3);
      returnNumber = `(${group1}) ${group2}`;
      setFormattedValue(returnNumber);
      return returnNumber;
    }

    if (numberString.length >= 7 && numberString.length <= 10) {
      const group1 = numberString.slice(0, 3);
      const group2 = numberString.slice(3, 6);
      const group3 = numberString.slice(6);
      returnNumber = `(${group1}) ${group2}-${group3}`;
      setFormattedValue(returnNumber);
      return returnNumber;
    }
  };

  React.useEffect(() => {
    const phoneNumber = phoneNumberHandler(props.defaultValue?.toString() ?? '');
    methods.setValue(props.name as string, phoneNumber);
  }, [props.defaultValue]);

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        // allow custom function to handle inputs with unconventional names
        // i.e. fieldArrays where convention is fieldArrayName.index.property
        if (props.resolver) {
          const newVal = props.resolver(values);
          setFormattedValue(newVal);
          return;
        }
        const newVal = values[name];
        phoneNumberHandler(newVal);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name, phoneNumberHandler]);

  return (
    <TextField
      {...(props as TextFieldProps)}
      fullWidth
      size='small'
      variant='outlined'
      inputProps={{
        ...props.inputProps,
        ...{ color: 'secondary' },
        ...(methods && methods.register ? { ...methods.register(props.name as string) } : {}),
      }}
      defaultValue={undefined}
      value={formattedValue}
    />
  );
}
