import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../../../themes';
import { Domain } from '@3nickels/data-modules';
import {
  formatPercent,
  formatWholeDollars,
  formatYAxis,
} from '../../../../helpers/utilityFunctions';
import { ChartLegend } from '../../../../components/ChartLegend';
import { ChartLine, ChartLineSeries, ChartYLinesData } from '../../../../components/ChartLine';
import { KeyValuePair } from '../../../../components';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';

type InflationCalculatorResultsProps = {
  results: Domain.GoalsInflationResultData | undefined;
};

const InflationCalculatorResults: React.FC<InflationCalculatorResultsProps> = ({ results }) => {
  const { t } = useTranslation();
  const initialYear = Domain.currentYear - 1 + (results?.assetRealValues?.length ?? 1);
  const [mouseLeftChart, setMouseLeftChart] = useState<boolean>(false);
  const [selectedYearIndex, setSelectedYearIndex] = useState(initialYear - Domain.currentYear);
  const [chartYLinesData, setChartYLinesData] = useState<ChartYLinesData[]>(
    createChartYLinesData(results, initialYear)
  );
  const chartData: ChartLineSeries[] = createChartData(results);

  const handleChartClicked = (pt: { x: number; y: number }) => {
    if (typeof results === 'undefined') return;
    setSelectedYearIndex(pt.x - Domain.currentYear);
    setChartYLinesData(createChartYLinesData(results, initialYear, pt.x));
  };

  useEffect(() => {
    if (typeof results === 'undefined') return;
    setSelectedYearIndex(initialYear - Domain.currentYear);
    setChartYLinesData(createChartYLinesData(results, initialYear));
  }, [results, mouseLeftChart]);

  return (
    <Grid container mt={3} direction='column' rowGap='20px'>
      <Grid>
        <Card className='tools-chart-output-box'>
          <Grid mt={1} container direction='column'>
            <ChartLegend
              orientation='horizontal'
              items={[
                { text: 'Future value', color: Colors.secondaryBase, bubbleLegend: true },
                { text: 'Feels like', color: Colors.alternateBase, bubbleLegend: true },
              ]}
            />
            <ChartLine
              height={225}
              formatY={(y) => {
                if (typeof results === 'undefined') {
                  return '';
                }
                return formatYAxis(y);
              }}
              data={chartData}
              yLines={chartYLinesData}
              onClick={handleChartClicked}
              mouseLeftChart={mouseLeftChart}
              setMouseLeftChart={setMouseLeftChart}
            />
            <Typography
              mb={1}
              variant='p12'
              color='accent'
              display={typeof results !== 'undefined' ? 'flex' : 'none'}
              alignItems='center'
              justifyContent='center'>
              {t('ClickOnTheChart')}
            </Typography>
          </Grid>
        </Card>
      </Grid>

      <Grid
        container
        mt={15}
        className='year-output'
        display={typeof results !== 'undefined' ? 'flex' : 'none'}>
        <Grid container padding='2px 10px'>
          <Grid item>
            <Typography variant='p18Bold' color='secondary'>
              Year {Domain.currentYear + selectedYearIndex}
            </Typography>
          </Grid>
        </Grid>
        <Card sx={{ width: '100%' }}>
          <Grid container direction='column'>
            <Grid item>
              <Stack spacing={Spacing.xxxs}>
                <KeyValuePair
                  label='FutureSavingsValueColon'
                  value={formatWholeDollars(results?.assetValues?.[selectedYearIndex] ?? 0)}
                />
                <KeyValuePair
                  label='SavingsWillFeelLikeColon'
                  value={formatWholeDollars(results?.assetRealValues?.[selectedYearIndex] ?? 0)}
                />
                <KeyValuePair
                  label='AnnualizedInflationRateColon'
                  value={formatPercent(results?.averageInflation?.[selectedYearIndex] ?? 0)}
                  helpContext={
                    <HelpPopover title={t('AnnualizedInflationRate')}>
                      <HelpPopoverText>{t('YearlyPercentageIncrease')}</HelpPopoverText>
                    </HelpPopover>
                  }
                />
                <KeyValuePair
                  label='CumulativeInflationColon'
                  value={formatPercent(results?.cumulativeInflation?.[selectedYearIndex] ?? 0)}
                  helpContext={
                    <HelpPopover title={t('CumulativeInflation')}>
                      <HelpPopoverText>{t('TotalPercentageIncrease')}</HelpPopoverText>
                    </HelpPopover>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InflationCalculatorResults;

function createChartData(
  resultData: Domain.GoalsInflationResultData | undefined
): ChartLineSeries[] {
  return [
    {
      color: Colors.secondaryBase,
      data: resultData?.assetValues?.map((y, i) => {
        return {
          x: Domain.currentYear + i,
          y: y,
        };
      }) ?? [
        {
          x: 0,
          y: 0,
        },
      ],
    },
    {
      color: Colors.alternateBase,
      data: resultData?.assetRealValues?.map((y, i) => {
        return {
          x: Domain.currentYear + i,
          y: y,
        };
      }) ?? [
        {
          x: 0,
          y: 0,
        },
      ],
    },
  ];
}

function createChartYLinesData(
  resultData: Domain.GoalsInflationResultData | undefined,
  initialYear: number,
  ptxSelected?: number
): ChartYLinesData[] {
  if (typeof resultData === 'undefined')
    return [
      {
        x: 0,
        color: Colors.accentBase,
        type: 'dashed',
      },
    ];
  return [
    {
      x: ptxSelected ? ptxSelected : initialYear ?? 0,
      color: Colors.accentBase,
      type: 'dashed',
    },
  ];
}
