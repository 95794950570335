import { Box, MenuItem, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';

export type CashAccountBasicsFormProps = {
  cashAccountBasics?: Data.CashAccounts.CashAccountBasicFormData;
  onSubmit: (values: Data.CashAccounts.CashAccountBasicFormData) => Promise<void>;
};

const CashAccountBasicsForm: React.FC<CashAccountBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <SelectInput<Data.CashAccounts.CashAccountBasicFormData>
          error={formState.errors.accountType !== undefined}
          helperText={formState.errors.accountType?.message?.toString()}
          defaultValue={props.cashAccountBasics?.accountType}
          name='accountType'
          autoFocus
          label='TypeOfCashAccount'>
          {Domain.CashAccountTypeOptions.map((x) => (
            <MenuItem value={x.key}>{x.value}</MenuItem>
          ))}
        </SelectInput>
        <TextInput<Data.CashAccounts.CashAccountBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.cashAccountBasics?.name}
          inputProps={{ maxLength: 64 }}
          label='CashAccountNickname'
          name='name'
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.CashAccounts.CashAccountBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            defaultValue={props.cashAccountBasics?.owner}
            items={[
              { label: 'Me', value: 'me' },
              { label: 'Spouse', value: 'spouse' },
            ]}
          />
        )}
        <TextInput<Data.CashAccounts.CashAccountBasicFormData>
          error={formState.errors.principle !== undefined}
          helperText={formState.errors.principle?.message?.toString()}
          defaultValue={props.cashAccountBasics?.principle}
          label='CurrentBalance'
          name='principle'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default CashAccountBasicsForm;
