import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import { createServiceModule } from '@aesop-fables/containr';

export const signUpProgressWizardKey = 'services/signUpProgressWizard';

class SignUpProgressWizard implements Disposable {
  private readonly state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.init();
  }

  async init() {
    try {
      const signUpProgressWizardStateJson = localStorage.getItem('sign-up-progress-wizard-state');
      if (signUpProgressWizardStateJson) {
        const signUpProgressWizardState = JSON.parse(signUpProgressWizardStateJson) as boolean;
        this.state.next(signUpProgressWizardState);
      }
    } catch (err) {
      console.error(err);
    }
  }

  get state$(): Observable<boolean> {
    return this.state;
  }

  setState(state: boolean) {
    localStorage.setItem('sign-up-progress-wizard-state', JSON.stringify(state));
    this.state.next(state);
  }

  clearCache() {
    localStorage.removeItem('sign-up-progress-wizard-state');
  }

  dispose(): void {
    console.log('disposing SignUpProgressWizard');
  }
}

export const withSignUpProgressWizard = createServiceModule(signUpProgressWizardKey, (services) => {
  services.use<SignUpProgressWizard>(signUpProgressWizardKey, SignUpProgressWizard);
});

export { SignUpProgressWizard };
