import { Data, Domain } from '@3nickels/data-modules';
import { useMutation, MutationStatus, useSubject } from '@aesop-fables/scrinium';
import { useEffect } from 'react';
import { useLoading } from '../useLoading';
import { useMessage } from '../useMessage';
import { useTranslation } from 'react-i18next';

export function useSaveDependent(
  editing?: boolean,
  cleanUp?: (dependents?: Domain.DependentData[]) => void
) {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const dependents =
    useSubject<Domain.DependentData[]>(Data.People.PersonServices.DependentData) ?? [];

  const saveDependent = useMutation(new Data.People.Mutations.SaveDependent());

  useEffect(() => {
    if (saveDependent.status === MutationStatus.Complete) {
      setLoading(false);
      const messageKey = editing ? 'DependentUpdated' : 'DependentAdded';
      showMessage(t(messageKey) as string, 'success');
      if (cleanUp) {
        cleanUp(dependents);
      }
    }
  }, [saveDependent.status]);

  return saveDependent;
}

export function useDeleteDependent(editing?: boolean, cleanUp?: () => void) {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const deleteDependent = useMutation(new Data.People.Mutations.DeleteDependent());

  useEffect(() => {
    if (deleteDependent.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('DependentRemoved') as string);
      if (cleanUp) {
        cleanUp();
      }
    }
  }, [deleteDependent.status]);

  return deleteDependent;
}
