import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, LinkProps } from '@mui/material';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Link {...props} component={RouterLink as any} />;
}

interface CustomizedBreadcrumbsProps {
  pathnameSegments: string[];
  breadcrumbNameMap: { [key: string]: string };
}

const CustomizedBreadcrumbs: React.FC<CustomizedBreadcrumbsProps> = ({
  pathnameSegments,
  breadcrumbNameMap,
}) => {
  const breadcrumbSegments = pathnameSegments.filter(Boolean);

  if (breadcrumbSegments.length <= 1) return <></>;

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {breadcrumbSegments.map((value, index) => {
        const last = index === breadcrumbSegments.length - 1;
        const to = `/${breadcrumbSegments.slice(0, index + 1).join('/')}`;

        return (
          <LinkRouter
            underline='hover'
            variant={last ? 'p16Bold' : 'p16'}
            color={last ? 'secondary' : 'primary'}
            to={to}
            key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomizedBreadcrumbs;
