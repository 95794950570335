import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@3nickels/data-modules';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Svgs } from '../../assets/svg';

export const PHONE_NUMBER = '1-800-774-7459';

interface ContactOptionsProps {
  featureMap: Domain.FeatureMap;
  coachingSessionUrl: string | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ContactOptions: React.FC<ContactOptionsProps> = ({
  featureMap,
  coachingSessionUrl,
  setOpen,
}) => {
  const { t } = useTranslation();
  const { coachingSession } = featureMap;

  const onBookASession = () => {
    window.open(`${coachingSessionUrl}`, '_blank');
  };

  return (
    <Grid container mt={2} rowGap={3} flexDirection='column'>
      {coachingSession && (
        <ContactOption
          title='BookACoachingSession'
          icon={<Svgs.DisplaySmallCustomCalendar />}
          description='BookA45MinuteTimeSlot'
          buttonText='Book a Session'
          onButtonClick={onBookASession}
        />
      )}
      <ContactOption
        title='SendUsAMessage'
        icon={<Svgs.DisplaySmallCustomEnvelope />}
        description='BrieflyDescribeWhatTheSituationIs'
        buttonText='Message Us'
        buttonVariant={coachingSession ? 'outlined' : undefined}
        onButtonClick={() => setOpen(true)}
      />
      <ContactOption title='CallUs' icon={<Svgs.DisplaySmallCustomPhone2 />}>
        <Card className='card-row' sx={{ padding: '20px 15px' }}>
          <Typography variant='p16' color='secondary'>
            {t('ToSpeakToARepresentative')}&nbsp;<strong>{PHONE_NUMBER}</strong>.
          </Typography>
          <Typography mt={1} variant='p16Italic' color='secondary'>
            {t('BusinessHours')}
          </Typography>
        </Card>
      </ContactOption>
    </Grid>
  );
};

interface ContactOptionProps extends React.PropsWithChildren {
  title: string;
  icon: React.ReactNode;
  description?: string;
  buttonText?: string;
  buttonVariant?:
    | 'link'
    | 'text'
    | 'tertiary'
    | 'outlined'
    | 'contained'
    | 'ghost'
    | 'activeGhost'
    | undefined;
  onButtonClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const ContactOption: React.FC<ContactOptionProps> = (props) => {
  const { t } = useTranslation();
  const { title, icon, description, buttonText, buttonVariant, onButtonClick } = props;

  return (
    <Stack spacing={1.5}>
      {icon}
      <Typography variant='p18Bold' color='secondary'>
        {t(`${title}`)}
      </Typography>
      {buttonText && (
        <Card
          className='card-row'
          sx={{ padding: '15px', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Grid
            columnGap='25px'
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='p16' color='secondary'>
              {t(`${description}`)}
            </Typography>
            <Button
              color='primary'
              variant={buttonVariant}
              style={{ width: '25%', minWidth: 'fit-content' }}
              onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Grid>
        </Card>
      )}
      {props.children}
    </Stack>
  );
};

export default ContactOptions;
