import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { KeyValuePair, Spacer } from '../../../../components';
import { Api } from '@3nickels/data-modules';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';

type HomeSaleProceedsCalculatorResultsProps = {
  results: Api.HouseSaleNetResultRest | undefined;
};

const HomeSaleProceedsCalculatorResults: React.FC<HomeSaleProceedsCalculatorResultsProps> = ({
  results,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Spacer height='xxs' />
          <Typography variant='p16Bold' color='secondary'>
            {t('HomeSaleDetails')}
          </Typography>
          <KeyValuePair
            label={t('HomeSalePrice')}
            value={results ? formatWholeDollars(results?.homeSalePrice ?? 0) : '– – –'}
          />
          <KeyValuePair
            label={t('TotalCostSell')}
            value={results ? formatWholeDollars(results?.totalCostToSell ?? 0) : '– – –'}
          />
          <KeyValuePair
            label={t('EstTaxImpact')}
            value={results ? formatWholeDollars(results?.taxImpact ?? 0) : '– – –'}
          />
          <Divider color='primary' />
          <KeyValuePair
            label={t('NetProceeds')}
            value={results ? formatWholeDollars(results?.netProceeds ?? 0) : '– – –'}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default HomeSaleProceedsCalculatorResults;
