import { useTranslation } from 'react-i18next';
import { useAppStoreNavigation } from '../../hooks/useAppStoreNavigation';
import { useDevicePlatform } from '../../hooks/useDevicePlatform';

const DownloadAppButton: React.FC = () => {
  const { t } = useTranslation();
  const platform = useDevicePlatform();
  const appStoreNav = useAppStoreNavigation();

  if (platform !== 'iOS' && platform !== 'Android') {
    return null;
  }

  const imageSrc =
    platform === 'iOS' ? './images/downloadOnTheAppStore.svg' : './images/google-play-badge.png';

  const altText = platform === 'iOS' ? t('DownloadOnAppStore') : t('GetItOnGooglePlay');

  return (
    <button
      type='button'
      onClick={appStoreNav}
      aria-label={altText}
      style={{
        border: 'none',
        background: 'none',
        padding: 0,
        cursor: 'pointer',
      }}>
      <img src={imageSrc} width={200} alt={altText} />
    </button>
  );
};

export default DownloadAppButton;
