import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Api } from '@3nickels/data-modules';
import CardDisplay from '../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../../account-details/Types';
import { formatPhoneNumber } from '../../../helpers/utilityFunctions';

export interface ContactInfoCardProps extends SummaryCardProps {
  contactInfo?: Api.ContactInfoRest;
}

const ContactInfoCard: React.FC<ContactInfoCardProps> = ({ contactInfo, onEdit }) => {
  const { t } = useTranslation();
  const mobile = (contactInfo?.mobilePhone ?? '').includes('(')
    ? contactInfo?.mobilePhone
    : formatPhoneNumber(contactInfo?.mobilePhone ?? '');

  return (
    <CardDisplay headerKey='ContactInfo' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Phone')}
          </Typography>
          <Typography color='secondary'>{mobile}</Typography>
        </Grid>
        <Grid item xs={9.6}>
          <Typography variant='h3' component='h3' color='primary'>
            {t('EmailAddress')}
          </Typography>
          <Typography color='secondary'>{contactInfo?.email}</Typography>
        </Grid>
      </Grid>
    </CardDisplay>
  );
};

export default ContactInfoCard;
