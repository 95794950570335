import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepOne: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={1}
      headerIcon={<Svgs.ArtCreditScore />}
      headerText='CheckYourCreditScore'
      body={
        <>
          <Typography color='secondary' variant='p16'>
            {t('YourCreditScoreWillBeCritical')}
          </Typography>
        </>
      }
    />
  );
};

export default StepOne;
