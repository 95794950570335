import HexagonStep, { HexagonStepProps } from '../../../components/HexagonStep';
import React from 'react';

export interface HexHeaderProps {
  stepProps?: HexagonStepProps;
  startAdornment?: React.ReactNode;
}

const HexHeader: React.FC<HexHeaderProps> = (props) => {
  return (
    <>
      {props.stepProps !== undefined && (
        <>
          {props.startAdornment}
          <HexagonStep steps={props.stepProps.steps} currentStep={props.stepProps.currentStep} />
        </>
      )}
    </>
  );
};

export default HexHeader;
