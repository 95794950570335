import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Location from '@mui/icons-material/LocationOn';
import { type Address } from '../../../types/PersonalDetails';
import AddressSearcher from '../../../components/AddressSearcher';
import { Spacing } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../components/form/FormContent';
import { Data, Hooks } from '@3nickels/data-modules';
import { WizardStep, useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { FormLoader } from '../../../components/FormLoader';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import TextInput from '../../../components/form/TextInput';

export const MailingAddressInfoEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.MailingAddressFormData>();
  useEffect(() => {
    wizard.selectStep('addressInfo');
  }, []);

  return (
    <FormLoader loading={loading}>
      <MailingAddressInfoEditView
        currentStep={currentStep}
        wizard={wizard}
        editing={editing}
        onBack={onBack}
      />
    </FormLoader>
  );
};

interface MailingAddressInfoEditProps extends EditViewProps {
  currentStep: WizardStep<Data.People.MailingAddressFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
}

const addressInfoSchema = Yup.object({
  addressLine1: Yup.string().required('Mailing Address is required'),
});

const MailingAddressInfoEditView: React.FC<MailingAddressInfoEditProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<Data.People.MailingAddressFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(addressInfoSchema),
  });
  const appStorage = useAppStorage();
  const keysDataCache = appStorage.retrieve<Data.Keys.KeysCompartments>(Data.Keys.keysStorageKey);
  const googleKey = useObservable(keysDataCache.observe$<string>('googleAddressKey'));

  const { setLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);
      await wizard.commitStep('addressInfo');
      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/personal-details/financial-info');
    } finally {
      setLoading(false);
    }
  };

  const [address, setAddress] = useState<Address | undefined>();
  const { setValue, formState, trigger } = methods;
  const selectAddress = (address: Address) => {
    Object.entries(address).forEach(([key, val]) => {
      setValue(key as keyof Address, val);
    });
    setAddress(address);
    setValue('addressLine2', undefined);
    trigger();
  };

  useEffect(() => {
    if (address || !currentStep?.model) {
      return;
    }

    if (!currentStep.model.addressLine1 || !currentStep.model.addressLine1.trim()) {
      return;
    }

    setAddress(currentStep.model);
  }, [currentStep]);

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography className='title' color='primary' component='h1' variant='h1'>
            {t('MailingAddress')}
          </Typography>

          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 7}>
              <Typography variant='p16Bold' color='primary'>
                {t('SearchForYourAddress')}
              </Typography>
              <Box component='form'>
                {googleKey && (
                  <AddressSearcher
                    error={formState.errors.addressLine1 !== undefined}
                    helperText={formState.errors.addressLine1?.message?.toString()}
                    name='mailingAddress'
                    googleAddressKey={googleKey}
                    autoFocus
                    onSelected={(address) => {
                      selectAddress(address);
                    }}
                  />
                )}

                {address && (
                  <>
                    <Grid
                      className='mailing-info'
                      display='inline-flex'
                      p={`${Spacing.md}px`}
                      mt={Spacing.xxs}
                      mb={Spacing.xxs}
                      container>
                      <Location />
                      <Box ml={2}>
                        <Typography color='secondary' fontWeight='bold'>
                          {address?.addressLine1}
                        </Typography>
                        <Typography
                          color='secondary'
                          fontWeight='bold'>{`${address?.city}, ${address?.state} ${address?.zipCode}`}</Typography>
                      </Box>
                    </Grid>
                    <TextInput<Address>
                      inputProps={{ maxLength: 30 }}
                      defaultValue={currentStep.model.addressLine2}
                      placeholder={t('AptNumber') as string}
                      label='ApartmentUnitEtc'
                      name='addressLine2'
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <WizardFooter
          color={editing ? 'primary' : undefined}
          onBack={onBack}
          {...getWizardFooterProps(meta.nextLocaleKey, editing)}
        />
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'NextBasicFinances',
  // nextUrl: 'financial-info',
  showBack: true,
  showNext: true,
  hexSteps: {
    steps: 5,
    currentStep: 3,
  },
} satisfies LayoutMeta;

export default withLayoutMeta(MailingAddressInfoEditViewWrapper, meta);
