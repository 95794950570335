const useDeviceType = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /mobile/i.test(userAgent);
  const isTablet = /iPad|Android|Touch/i.test(userAgent);
  const isDesktop = !isMobile && !isTablet;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export { useDeviceType };
