import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';

const StepOne: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={1}
      headerText='StartResearching'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('AsYouBeginYourResearch')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[t('Safety'), t('Reliability'), t('Size'), t('Price'), t('Image'), t('Etc')]}
          />
        </>
      }
      related={[{ textKey: 'MostPopularCars', url: '/tools/car/most-popular-cars' }]}
    />
  );
};

export default StepOne;
