import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../themes/ThemeWrapper';
import { CSSProperties } from 'react';

interface HelpTextProps {
  text: string;
  textStyle?: CSSProperties;
  content: React.ReactNode;
}

const HelpText: React.FC<HelpTextProps> = ({ text, textStyle, content }) => {
  const { t } = useTranslation();

  return (
    <Grid className='help-text' item mt='16px' display={'flex'}>
      <Typography variant='p16Bold' color='primary' style={textStyle}>
        {t(text)}
      </Typography>
      <Tippy placement='right' content={content} theme='light-nickels-theme'>
        <HelpOutline style={{ color: theme.palette.primary.main, alignSelf: 'center' }} />
      </Tippy>
    </Grid>
  );
};

export default HelpText;
