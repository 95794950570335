import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const FairUseNoticeView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography mb={2} variant='p30Bold' component='h1' color='primary'>
        {t('FairUseNotice')}
      </Typography>
      <Grid sm={9}>
        <Stack spacing={2}>
          <Typography variant='p16' color='secondary'>
            The 3Nickels app may contain copyrighted material owned by a third party, the use of
            which has not always been specifically authorized by the copyright owner.
            Notwithstanding a copyright owner’s rights under the Copyright Act, Section 107 allows
            limited use of copyrighted material without requiring permission from the rights
            holders, for purposes such as education, criticism, comment, news reporting, teaching,
            scholarship, and research. These so-called “fair uses” are permitted even if the use of
            the work would otherwise be infringing.
          </Typography>
          <Typography variant='p16' color='secondary'>
            If you wish to use copyrighted material within this app for your own purposes that go
            beyond fair use, you must obtain permission from the copyright owner. We recommend that
            you seek the advice of legal counsel if you have any questions on this point.
          </Typography>
          <Typography className='link-wrapper' variant='p16' color='secondary'>
            If you believe that any content in this app violates your intellectual property or other
            rights, please&nbsp;
            <Link color='secondary' href={'mailto:help@3nickels.com'}>
              Contact Us
            </Link>
            .
          </Typography>
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(FairUseNoticeView, meta);
