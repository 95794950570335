import { Hooks } from '@3nickels/data-modules';
import { Button } from '../../../components/buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const linkedInstitutions = Hooks.useLinkedInstitutions();
  // hide if no institutions
  if (typeof linkedInstitutions === 'undefined' || linkedInstitutions.length === 0) return null;

  return (
    <Button
      variant='outlined'
      label='ViewLinkedAccounts'
      onClick={() => navigate('link-account/my-institutions')}
    />
  );
};

export default HeaderContent;
