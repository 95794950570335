import { Svgs } from '../../../../assets/svg';

interface CategoryIconProps {
  category?: string;
  selected?: boolean;
}

export const CategoryIcon: React.FC<CategoryIconProps> = ({ category, selected }) => {
  switch (category) {
    case 'Animals':
      return selected ? <Svgs.DisplaySmallWhiteVacation /> : <Svgs.DisplaySmallCustomVacation />;
    case 'Arts, Culture, Humanities':
      return selected ? <Svgs.DisplaySmallWhitePlan /> : <Svgs.DisplaySmallCustomPlan />;
    case 'Community Development':
      return selected ? <Svgs.DisplaySmallWhiteHouse /> : <Svgs.DisplaySmallCustomHouse />;
    case 'Education':
      return selected ? <Svgs.DisplaySmallWhiteCollege /> : <Svgs.IconCollege />;
    case 'Environment':
      return selected ? (
        <Svgs.DisplaySmallWhiteRetirement />
      ) : (
        <Svgs.DisplaySmallCustomRetirement />
      );
    case 'Health':
      return selected ? (
        <Svgs.DisplaySmallWhiteHealthInsurance />
      ) : (
        <Svgs.DisplaySmallCustomHealthInsurance />
      );
    case 'Human and Civil Rights':
      return selected ? <Svgs.DisplaySmallWhiteHandshake /> : <Svgs.DisplaySmallCustomHandshake />;
    case 'Human Services':
      return selected ? <Svgs.DisplaySmallWhiteMembers /> : <Svgs.DisplaySmallCustomMembers />;
    case 'International':
      return selected ? <Svgs.DisplaySmallWhiteBank /> : <Svgs.DisplaySmallCustomBank />;
    case 'Religion':
      return selected ? <Svgs.DisplaySmallWhiteBook /> : <Svgs.DisplaySmallCustomBook />;
    case 'Research and Public Policy':
      return selected ? (
        <Svgs.DisplaySmallWhiteItemization />
      ) : (
        <Svgs.DisplaySmallCustomItemization />
      );
    default:
      return <Svgs.DisplaySmallCustomVacation />;
  }
};
