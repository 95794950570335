import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { campingBanner } from '../../../assets/png';
import { useNavigate } from 'react-router-dom';
import { useNeedsOnboarding } from '../../../hooks';
import { useMemo } from 'react';
import { Data, Hooks } from '@3nickels/data-modules';
import { useLoading } from '../../../hooks/useLoading';
import GlowButton from '../../../components/GlowButton';

const WalkThroughAdviceCard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onboarding = useNeedsOnboarding();
  const commands = Hooks.useCommands();
  const { setLoading } = useLoading();

  const viewAdvice = useMemo(() => {
    if (onboarding) {
      return async () => {
        setLoading(true);
        await commands.relay(Data.HolisticAdvice.CalculateHolisticAdvice);
        setLoading(false);
        navigate('/holistic-advice/budget');
      };
    }

    // skip start screen if not in onboarding
    return async () => {
      navigate('/holistic-advice/budget/monthly');
    };
  }, [onboarding]);

  return (
    <Grid container className='walk-through-advice-card'>
      <Grid item my='30px' ml='40px'>
        <Typography variant='p30Bold' color='secondary' mb='20px'>
          {t('LookedAtYourAdviceLately?')}
        </Typography>
        <GlowButton className='walk-through-button' onClick={viewAdvice}>
          {t('WalkMeThrough')}
        </GlowButton>
      </Grid>
      <img src={campingBanner} />
    </Grid>
  );
};

export default WalkThroughAdviceCard;
