import React from 'react';
import { Card, Grid, Typography } from '@mui/material';

export interface InformationTableData {
  headers: {
    column: (string | React.ReactNode)[];
    row: (string | React.ReactNode)[];
  };
  content: (string | React.ReactNode)[][];
}

interface InformationTableProps {
  data: InformationTableData;
  headerEmphasis?: 'column' | 'row';
}

const InformationTable: React.FC<InformationTableProps> = ({ data, headerEmphasis = 'column' }) => {
  const columnEmphasis = headerEmphasis === 'column';

  return (
    <Grid container className='content-card-container' flexDirection='column'>
      <Grid container>
        <Grid item sm={2} />
        <Grid item display='flex' flexDirection='row' sm={10} mb={2}>
          {data.headers.column.map((colHeader, index) => (
            <Grid sm mr={1}>
              <Typography
                key={index}
                variant={columnEmphasis ? 'p18Bold' : 'p16Bold'}
                color={columnEmphasis ? 'primary' : 'secondary'}>
                {colHeader}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {data.headers.row.map((rowHeader, rowIndex) => (
        <Grid key={rowIndex} display='flex' flexDirection='row'>
          <Grid sm={2} pr={3} mb={2} display='flex' alignItems='center'>
            <Typography
              variant={columnEmphasis ? 'p16Bold' : 'p18Bold'}
              color={columnEmphasis ? 'secondary' : 'primary'}>
              {rowHeader}
            </Typography>
          </Grid>
          <Grid item sm={11.5} mb={2} flex={1}>
            <Card className='content-card' style={{ padding: 0 }}>
              <Grid container py={3} pl={'20px'}>
                {data.content?.[rowIndex]?.map((cell, cellIndex) => (
                  <Grid item sm pr={4} key={cellIndex}>
                    <Typography variant='p16' color='secondary'>
                      {cell}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default InformationTable;
