import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { frontPageInvestmentBrandColors } from '../../../assets/png';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import StartView from '../StartView';
import { Hooks } from '@3nickels/data-modules';
import useAdviceResultsNextInvestmentAccount from '../../../hooks/useAdviceResultsNextInvestmentAccount';
import { useUniqueInvMixForNavigation } from '../progress-indicator/useUniqueInvMixForNavigation';

const InvestmentsStartView: React.FC = () => {
  const { t } = useTranslation();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();
  const navigateToNext = useAdviceResultsNextInvestmentAccount();

  const onComplete = useMemo(() => {
    if (holisticAdvice && holisticAdvice.investmentMixes.length > 0) {
      const navigationInvMix = useUniqueInvMixForNavigation(holisticAdvice.investmentMixes);
      const account = navigationInvMix[0];
      return () => {
        navigateToNext(account.id);
      };
    }

    return () => {
      navigateToNext();
    };
  }, [holisticAdvice]);

  if (typeof holisticAdvice === 'undefined') return null;

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <StartView
        imageSrc={frontPageInvestmentBrandColors}
        className='start-screen-text'
        color='primary'
        variant='p30'
        display='inline'
        onComplete={onComplete}>
        {t('NowWalkThrough') + ' '}
        <strong>investments</strong> one-by-one.
      </StartView>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(InvestmentsStartView, meta);
