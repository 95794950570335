import React from 'react';
import { Box, Grid } from '@mui/material';
import { useContentView } from '../layout/ContentViewContext';
import ContentView from '../layout/Content';
import LifestyleBanner from './LifestyleBanner';
import { LifestylePanelsWrapper as LifestylePanels } from './LifestylePanels';

const LifestyleView: React.FC = () => {
  const { sideNav } = useContentView();

  return (
    <ContentView sideNavMenuItems={sideNav()}>
      <Box>
        <Grid container alignItems='center' justifyContent='center' display='flex'>
          <LifestyleBanner />
          <LifestylePanels />
        </Grid>
      </Box>
    </ContentView>
  );
};

export default LifestyleView;
