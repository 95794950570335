import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function BudgetSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g id='3Nickels-Web' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Group-21'>
          <rect id='Rectangle' x='0' y='0' width='25' height='25'></rect>
          <g id='budget' transform='translate(3, 3)' fill={props.fill} fill-rule='nonzero'>
            <path
              d='M2.20610097,3.40511904 C-1.09575036,7.36588513 -0.640322593,13.2357975 3.23081345,16.6409165 L8.99482117,9.58847329 L2.20610097,3.40511904 Z'
              id='Path'></path>
            <path
              d='M9.54987377,0 C9.53564165,0 9.52140953,0 9.50717741,0 C6.97386044,0 4.54016829,1.01156256 2.74692145,2.82097728 L9.54987377,9.01857889 L9.54987377,0 Z'
              id='Path'></path>
            <path
              d='M10.06223,9.54573121 L3.85702664,17.1395741 C8.06973351,20.2597459 14.0187588,19.3621623 17.1355926,15.1449437 C18.3310905,13.5207447 18.9857679,11.554609 19,9.54573121 L10.06223,9.54573121 Z'
              id='Path'></path>
            <path
              d='M14.7303647,6.36856993 C14.7303647,6.14061217 14.6734362,5.96964385 14.5738114,5.84141761 C14.4599544,5.71319137 14.2749369,5.59921248 14.0045266,5.47098624 C13.3356171,5.2002864 12.8517251,4.92958656 12.5528506,4.65888672 C12.2539762,4.38818688 12.0974229,3.9892608 12.0974229,3.44786112 C12.0974229,2.96345088 12.2539762,2.57877216 12.5813149,2.26533024 C12.9086536,1.95188832 13.3356171,1.78092 13.8764376,1.72393056 L13.8764376,0.826346881 L14.5453471,0.826346881 L14.5453471,1.73817792 C15.0577034,1.80941472 15.4562027,2.02312512 15.7550771,2.36506176 C16.0539516,2.7069984 16.1962728,3.14866656 16.1820407,3.69006624 L16.1678086,3.7043136 L14.7588289,3.7043136 C14.7588289,3.39087168 14.7019004,3.16291392 14.5738114,3.02044032 C14.4599544,2.86371936 14.3034011,2.79248256 14.1041515,2.79248256 C13.9049018,2.79248256 13.7483485,2.849472 13.6629558,2.97769824 C13.563331,3.10592448 13.5206346,3.26264544 13.5206346,3.44786112 C13.5206346,3.64732416 13.5775631,3.80404512 13.6771879,3.918024 C13.7768128,4.04625024 13.9760624,4.16022912 14.2607048,4.28845536 C14.9153822,4.57340256 15.3992742,4.8441024 15.7123808,5.1290496 C16.0254874,5.4139968 16.1820407,5.81292289 16.1820407,6.32582785 C16.1820407,6.82448545 16.0254874,7.20916417 15.7123808,7.52260609 C15.3992742,7.82180065 14.9865428,8.00701633 14.4599544,8.04975841 L14.4599544,8.90460001 L13.7910449,8.90460001 L13.7910449,8.04975841 C13.2359923,7.99276897 12.7805645,7.80755329 12.4247616,7.47986401 C12.0689586,7.15217473 11.8839411,6.66776449 11.8981732,6.02663329 L11.9124053,5.99813857 L13.3071529,5.99813857 C13.3071529,6.35432257 13.3783135,6.59652769 13.5348668,6.75324865 C13.6914201,6.90996961 13.8906697,6.98120641 14.1326157,6.98120641 C14.3318654,6.98120641 14.4884187,6.92421697 14.6022756,6.79599073 C14.6734362,6.71050657 14.7303647,6.55378561 14.7303647,6.36856993 Z'
              id='Path'></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export function BudgetSvgDark(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h30v30H0z' />
        <g fill='#5F5F5F' fillRule='nonzero'>
          <path d='M6.107 7.73C2.132 12.612 2.68 19.847 7.34 24.044l6.939-8.693L6.107 7.73zM14.947 3.533h-.051c-3.05 0-5.98 1.247-8.138 3.477l8.19 7.639V3.533zM15.564 15.299l-7.47 9.36c5.071 3.845 12.233 2.739 15.985-2.459a11.993 11.993 0 0 0 2.244-6.901h-10.76zM21.183 11.382c0-.28-.068-.491-.188-.65-.137-.157-.36-.298-.685-.456-.806-.334-1.388-.667-1.748-1-.36-.335-.548-.826-.548-1.494 0-.597.188-1.07.582-1.457.394-.386.908-.597 1.56-.667V4.55h.805v1.124c.617.088 1.096.351 1.456.773.36.421.531.966.514 1.633l-.017.018h-1.696c0-.387-.069-.668-.223-.843a.648.648 0 0 0-.565-.281c-.24 0-.429.07-.531.228-.12.158-.172.351-.172.58 0 .245.069.438.189.579.12.158.36.298.702.457.788.35 1.37.684 1.748 1.036.377.35.565.843.565 1.475 0 .614-.188 1.089-.565 1.475-.377.369-.874.597-1.508.65v1.053h-.805v-1.053c-.668-.07-1.217-.299-1.645-.703-.428-.404-.651-1-.634-1.791l.017-.035h1.68c0 .439.085.737.273.93.189.194.429.282.72.282.24 0 .428-.07.565-.229.086-.105.154-.298.154-.527z' />
        </g>
      </g>
    </SvgIcon>
  );
}

BudgetSvg.defaultProps = {
  fill: 'none',
};
