import {
  Backdrop,
  Divider,
  Grid,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { logo3NSmall } from '../../assets/png';
import { Column, Row } from '../../components';
import { Colors } from '../../themes';
import { Svgs } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Cookies from 'js-cookie';
import { API_BASE_URL } from '../../configuration/Environment';
import { resolveShowManagePayments } from '../settings/AccountSettingsView';
import { Hooks } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': '10px',
    'marginTop': theme.spacing(4),
    'width': '100%',
    '& .MuiMenu-list': {
      padding: '20px',
    },
  },
}));

export const MobileTopNavBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const formRef = React.useRef<HTMLFormElement>(null);
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const { orgOnlyOffersFreePricebook, orgPaysPricebook } = Hooks.useAvailableProducts();
  const addOns = false;
  const showManagePayments = resolveShowManagePayments(
    orgPaysPricebook,
    addOns,
    orgOnlyOffersFreePricebook,
    subscriptionInfoData
  );

  const menuItems = [
    {
      icon: <Svgs.IconHouseDefault />,
      text: 'Home',
      href: '/',
    },
    { isDivider: true },
    {
      icon: <Svgs.DisplaySmallCustomCreditCardPrimary />,
      text: 'ManageSubscription',
      href: '/settings/manage-subscription',
    },
    { isDivider: true },
  ];

  if (showManagePayments)
    menuItems.splice(3, 0, {
      icon: <Svgs.DisplaySmallCustomWalletPrimary />,
      text: 'ManagePayments',
      href: '/settings/manage-payments',
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    if (open) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navToLandingPage = () => {
    navigate('/');
  };

  return (
    <>
      <TopBar navToLandingPage={navToLandingPage} handleClick={handleClick} />

      <Backdrop style={{ zIndex: 999 }} open={open} onClick={handleClose}>
        <StyledMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          sx={{ padding: 0 }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <Column>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Svgs.IconNavAlertDefault onClick={handleClose} />
            </Row>
            {menuItems.map((item) => {
              if (item.isDivider) return <Divider color={Colors.primaryLighter} />;
              return (
                <MenuListItem
                  onClick={() => navigate(item.href ?? '/')}
                  handleClose={handleClose}
                  title={item.text ?? ''}
                  icon={item.icon ?? <Svgs.IconHouseDefault />}
                />
              );
            })}
            <form ref={formRef} method='post' action={`${API_BASE_URL}/logout`}>
              <MenuListItem
                onClick={() => formRef.current?.submit()}
                handleClose={handleClose}
                title='Sign Out'
                icon={<Svgs.DisplayHamburgerMenuSignOutPrimary />}
              />
              <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
            </form>
          </Column>
        </StyledMenu>
      </Backdrop>
    </>
  );
};

interface TopBarProps {
  navToLandingPage: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: (event: any) => void;
}
const TopBar: React.FC<TopBarProps> = ({ navToLandingPage, handleClick }) => {
  return (
    <Grid
      className='mobile-top-bar'
      display='flex'
      direction='row'
      style={{
        width: '100%',
        backgroundColor: 'white',
        minHeight: 75,
        maxHeight: 105,
        justifyContent: 'space-between',
        padding: '17px 20px',
        zIndex: 1000,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
      }}>
      <Grid onClick={navToLandingPage}>
        <img src={logo3NSmall} alt='logo' style={{ maxWidth: '55%', minWidth: '200px' }} />
      </Grid>
      <Svgs.ActionSmallGrip
        onClick={handleClick}
        style={{ height: '100%', width: '10%', minWidth: '35px', maxWidth: '80px' }}
      />
    </Grid>
  );
};

interface MenuListItemProps {
  onClick: () => void;
  handleClose: () => void;
  title: string;
  icon: JSX.Element;
}
const MenuListItem: React.FC<MenuListItemProps> = ({ onClick, handleClose, title, icon }) => {
  const { t } = useTranslation();
  return (
    <MenuItem
      sx={{ padding: 0 }}
      onClick={() => {
        onClick();
        handleClose();
      }}>
      <Grid
        item
        sx={{
          display: 'flex',
          width: 24,
          height: 24,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {icon}
      </Grid>
      <Typography ml={'20px'} variant='p16' color='primary'>
        {t(title)}
      </Typography>
    </MenuItem>
  );
};
