import { Grid, Typography } from '@mui/material';
import GroupPanels from '../../../components/GroupPanels';
import { useManualAccountTypes } from './ManualAccountTypes';
import { useTranslation } from 'react-i18next';
import { Spacing } from '../../../themes';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';

const ManuallyAddAnAccount: React.FC = () => {
  const { t } = useTranslation();
  const manualAccountTypes = useManualAccountTypes();

  return (
    <Grid container flexDirection='column'>
      <Typography variant='p30Bold' component='h1' color='primary'>
        {t('ManuallyAddAccount')}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t('PickAccountType')}
      </Typography>
      <Grid container pt={Spacing.xxs}>
        <GroupPanels variant='Popover' groups={manualAccountTypes} />
      </Grid>
    </Grid>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  header: (
    <Typography className='header-text' variant='p18Bold' color='primary'>
      Account Details
    </Typography>
  ),
} satisfies LayoutMeta;

export default withLayoutMeta(ManuallyAddAnAccount, meta);
