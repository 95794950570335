import { SVGProps } from 'react';
import { Svgs } from '../../../assets/svg';
import {
  beach,
  camp,
  carLandscape,
  cityTall,
  desert,
  farm,
  fence,
  houseLandscape,
  lake,
  nature,
  park,
  snowscape,
} from '../../../assets/png';

type HeaderIconProps = Omit<SVGProps<SVGSVGElement>, 'type'> & {
  module: string;
};

export const HeaderIcon: React.FC<HeaderIconProps> = ({ module, ...rest }) => {
  switch (module) {
    case 'budget':
      return <Svgs.DisplaySmallCustomBudget2 {...rest} />;
    case 'car':
      return <Svgs.DisplayMediumCustomCar {...rest} />;
    case 'college':
      return <Svgs.IconCollege2 {...rest} />;
    case 'credit-card':
      return <Svgs.DisplaySmallCustomCreditCard3 {...rest} />;
    case 'debt':
      return <Svgs.DisplaySmallCustomDebt2 {...rest} />;
    case 'gifts':
      return <Svgs.DisplaySmallCustomGiftsPrimary {...rest} />;
    case 'goals':
      return <Svgs.DisplaySmallCustomGoals2 {...rest} />;
    case 'house':
      return <Svgs.DisplayLargeCustomHouse {...rest} />;
    case 'investment':
      return <Svgs.DisplaySmallCustomInvestment2 {...rest} />;
    case 'loans':
      return <Svgs.DisplaySmallCustomLoansSecondary {...rest} />;
    case 'medical':
      return <Svgs.DisplayLargeCustomMedical {...rest} />;
    case 'retirement':
      return <Svgs.DisplayLargeCustomRetirement {...rest} />;
    default:
      return <Svgs.DisplaySmallCustomBudget2 {...rest} />;
  }
};

export const getBackground = (module: string) => {
  switch (module) {
    case 'budget':
      return <img src={farm} className='tools-start-view-background' />;
    case 'car':
      return <img src={carLandscape} className='tools-start-view-background' />;
    case 'college':
      return <img src={park} className='tools-start-view-background' />;
    case 'credit-card':
      return <img src={camp} className='tools-start-view-background' />;
    case 'debt':
      return <img src={desert} className='tools-start-view-background' />;
    case 'gifts':
      return <img src={snowscape} className='tools-start-view-background' />;
    case 'goals':
      return <img src={nature} className='tools-start-view-background' />;
    case 'house':
      return <img src={houseLandscape} className='tools-start-view-background' />;
    case 'investment':
      return <img src={fence} className='tools-start-view-background' />;
    case 'loans':
      return <img src={cityTall} className='tools-start-view-background' />;
    case 'medical':
      return <img src={lake} className='tools-start-view-background' />;
    case 'retirement':
      return <img src={beach} className='tools-start-view-background' />;
    default:
      return <img src={farm} className='tools-start-view-background' />;
  }
};
