import { Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { useLifestyleProductsByCategory } from './useLifestyleProductsByCategory';
import { useFilteredLessonsByCategories } from './useFilteredLessonsByCategories';
import { Group } from '../components/GroupPanels';
import LessonToolsCard from '../pages/tools/lessons/LessonToolsCard';
import LifestyleToolsCard from '../pages/tools/LifestyleToolsCard';

export function useExploreGroups() {
  const lifestyleInitialized = Hooks.useLifestyleInitialized();
  const lifestyleProducts = useLifestyleProductsByCategory();
  const lessons = useFilteredLessonsByCategories();

  const explore: Group[] = useMemo(() => {
    const g: Group[] = [];
    if (!lifestyleInitialized) return g;
    if ((lessons?.length ?? 0) > 0) {
      g.push({
        textKey: 'your-lessons',
        url: 'your-lessons',
        content: <LessonToolsCard />,
      });
    }
    if ((lifestyleProducts?.length ?? 0) > 0) {
      g.push({
        textKey: 'lifestyle',
        url: 'lifestyle',
        content: <LifestyleToolsCard />,
      });
    }

    return g;
  }, [lessons, lifestyleProducts, lifestyleInitialized]);

  return explore;
}
