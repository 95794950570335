import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';

const StepFour: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={<Svgs.ArtCreditCard2 />}
      headerText='CompareOtherOptions'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('InAdditionToTakingOutAPersonalLoan')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('IfYouHaveGoodCredit'),
              t('ForACheaperRate'),
              t('IfYourCreditMeansYourUnableToQualify'),
            ]}
          />
        </>
      }
    />
  );
};

export default StepFour;
