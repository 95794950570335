import { Svgs } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from './ToolsCard';
import { Grid } from '@mui/material';
import { Colors } from '../../themes';

const LifestyleToolsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={
        <Grid
          style={{ backgroundColor: Colors.primaryLightest, padding: '15px' }}
          borderRadius='50%'>
          <Svgs.ArtLifestyle />
        </Grid>
      }
      label={t('Lifestyle')}
      content={t('IdeaForYourLifeNeeds')}
    />
  );
};

export default LifestyleToolsCard;
