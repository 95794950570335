import { Typography } from '@mui/material';
import React from 'react';

interface DuplicateAccountHeaderProps {
  content?: string;
}

export const DuplicateAccountHeader: React.FC<DuplicateAccountHeaderProps> = ({ content }) => {
  return (
    <Typography variant='p18Bold' color='primary' alignSelf='end'>
      {content}
    </Typography>
  );
};
