import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const WhySaveNowCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomVideoCamera />}
      label={t('WhySaveNow')}
      content={t('WatchTheSurprisingEffect')}
    />
  );
};

export default WhySaveNowCard;
