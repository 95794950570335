import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/buttons/Button';
import { universitySchool } from '../../assets/png';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';

const NextLessonCard: React.FC = () => {
  const course = Hooks.useUniversityCourse();
  return (
    <Grid container className='3nu-next-lesson-card'>
      <Grid className='left-grid' paddingLeft='30px'>
        <Svgs.NickelsULogoSecondary />
      </Grid>
      {course?.complete ? (
        <Grid paddingX='10px'>
          <Typography variant='p18Bold' color='secondary'>
            Congratulations on completing the course!
          </Typography>
        </Grid>
      ) : (
        <NextLessonContent />
      )}
      <Grid className='gradient' />
      <img src={universitySchool} />
    </Grid>
  );
};

export const NextLessonContent: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const nextLesson = Hooks.useNextUniversityLesson();

  const minRemaining = useMemo(() => {
    if (nextLesson?.progress && nextLesson.duration) {
      const secondsViewed = nextLesson.duration * (nextLesson.progress / 100);
      const remaining = nextLesson.duration - secondsViewed;
      return Math.round(remaining / 60);
    }

    return Math.round((nextLesson?.duration ?? 0) / 60);
  }, [nextLesson]);

  const startLesson = () => {
    if (nextLesson) {
      navigate(`/learning/university/${encodeURIComponent(nextLesson.title)}`);
    }
  };

  return (
    <>
      <Grid className='course-details' paddingX='10px'>
        <Typography variant='p16Bold' color='primary'>
          {t(nextLesson?.progress ? 'InProgress' : 'NextUp')}
        </Typography>
        <Typography variant='p18Bold' color='secondary' my='5px'>
          {nextLesson?.title}
        </Typography>
        <Typography variant='p16' color='secondary'>
          {minRemaining} min remaining
        </Typography>
      </Grid>
      <Grid justifyContent='end'>
        <Button color='primary' onClick={startLesson}>
          {nextLesson?.progress ? 'Continue' : 'Start'}
        </Button>
      </Grid>
    </>
  );
};

export default NextLessonCard;
