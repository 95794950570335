import { Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { json } from 'react-router-dom';
import { firstValueFrom } from 'rxjs';

export async function profileLoader(container: IServiceContainer) {
  const wizard = container.get<Data.People.IPersonWizard>(Data.People.PersonServices.PersonWizard);
  const isStarted = await firstValueFrom(wizard.initialized$);
  if (!isStarted) {
    wizard.start({});
  }
  return json('person wizard started');
}
