import { Column, Row, Spacer } from '../../../../components';
import { Button } from '../../../../components/buttons/Button';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Box, Card, Drawer, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../../../themes/ThemeWrapper';
import { useMessage } from '../../../../hooks/useMessage';
import TextInput from '../../../../components/form/TextInput';
import FormContent from '../../../../components/form/FormContent';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spacing } from '../../../../themes';

const formSchema = Yup.object({
  currentTotalValue: Yup.string().required('Required'),
  investmentName: Yup.string().required('Required'),
  originalTotalCost: Yup.string().required('Required'),
});

const cleanValues = (values: Domain.GtdbTaxDeductionData) => {
  return {
    currentTotalValue: Number((values.currentTotalValue?.toString() ?? '').replace(/[$,]/g, '')),
    originalTotalCost: Number((values.currentTotalValue?.toString() ?? '').replace(/[$,]/g, '')),
    investmentName: values.investmentName,
    id: values.id,
    taxDeductionId: values.taxDeductionId,
  };
};

interface TaxInvestmentDonationsDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  investment: Domain.GtdbTaxDeductionData | undefined;
  taxDeductionId: number;
  setInvestments: React.Dispatch<React.SetStateAction<Domain.GtdbTaxDeductionData[] | undefined>>;
}

export const TaxInvestmentDonationsDrawer: React.FC<TaxInvestmentDonationsDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  investment,
  taxDeductionId,
  setInvestments,
}) => {
  const { t } = useTranslation();
  const commands = Hooks.useCommands();
  const { showMessage } = useMessage();
  const methods = useForm<Domain.GtdbTaxDeductionData>({
    resolver: yupResolver(formSchema),
    defaultValues: investment,
  });

  const onSubmit = async (values: Domain.GtdbTaxDeductionData) => {
    try {
      const cleanedValues = cleanValues({
        ...values,
        taxDeductionId: taxDeductionId,
        id: investment?.id,
      });
      const savedInvestment = await commands.execute(
        Data.TaxDeduction.Commands.SaveInvestment,
        cleanedValues
      );
      setInvestments((prev) => {
        const isEditing = prev?.find((x) => x.id === savedInvestment.id);
        if (isEditing) {
          const oldArray = prev?.filter((x) => x.id !== savedInvestment?.id);
          return [savedInvestment, ...(oldArray ?? [])];
        }

        return [savedInvestment, ...(prev ?? [])];
      });
      showMessage(`Investment ${investment?.id ? 'saved!' : 'added!'}`, 'success');
      setDrawerOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onClick = methods.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <Drawer anchor='right' open={drawerOpen}>
      <Box p={Spacing.xxs}>
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography variant='p30Bold' color='primary'>
              {(investment?.id ? 'Edit ' : 'Add ') + 'Investment for Donation'}
            </Typography>
            <Typography mt={1} mb={3} variant='p16' color='secondary'>
              Add an investment you are donating this year, such as stocks, bonds, mutual funds, or
              real estate.
            </Typography>
            <Column style={{ margin: '15px 100px' }}>
              <TextInput<Domain.GtdbTaxDeductionData>
                name='investmentName'
                label='InvestmentName'
                inputProps={{ maxLength: 30 }}
                placeholder='My Investment'
                defaultValue={investment?.investmentName}
              />
              <Spacer height='sm' />
              <TextInput<Domain.GtdbTaxDeductionData>
                name='originalTotalCost'
                label='OriginalCost'
                inputProps={{ maxLength: 8 }}
                placeholder='$0'
                defaultValue={investment?.originalTotalCost}
                type='dollar'
              />
              <Spacer height='sm' />
              <TextInput<Domain.GtdbTaxDeductionData>
                name='currentTotalValue'
                label='CurrentValue'
                inputProps={{ maxLength: 8 }}
                placeholder='$0'
                defaultValue={investment?.currentTotalValue}
                type='dollar'
              />
              <Spacer height='sm' />
              <Tippy
                placement='left'
                content={
                  <Grid>
                    <Typography variant='p16Bold' color='secondary'>
                      {t('GivingLoss')}
                    </Typography>
                    <Typography variant='p16' color='secondary' mt={1}>
                      {t('LossInSelling')}
                    </Typography>
                    <Typography variant='p16Bold' color='secondary' mt={1}>
                      {t('BestBetToSell')}
                    </Typography>
                    <Typography variant='p16' color='secondary' mt={1}>
                      {t('AddProceedsToDonations')}
                    </Typography>
                  </Grid>
                }
                theme='light-nickels-theme'>
                <Card className='hoverable-info-card'>
                  <Row style={{ alignItems: 'center' }}>
                    <Svgs.ArtLightbulbSmall />
                    <Typography ml={2} mr={1} variant='p16' color='secondary'>
                      {t('CurrentLessThanOriginal')}
                    </Typography>
                    <HelpOutline style={{ color: theme.palette.primary.main }} />
                  </Row>
                </Card>
              </Tippy>
            </Column>
            <Spacer height='xxl' />
            <Row style={{ justifyContent: 'space-between', margin: '0px 7px' }}>
              <Button
                onClick={() => setDrawerOpen(false)}
                variant='outlined'
                color='primary'
                style={{ alignSelf: 'flex-start', padding: '0px 25px' }}>
                Cancel
              </Button>
              <Button
                onClick={onClick}
                color='primary'
                style={{ alignSelf: 'flex-end', padding: '0px 25px' }}>
                Save
              </Button>
            </Row>
            <Spacer height='md' />
          </form>
        </FormContent>
      </Box>
    </Drawer>
  );
};
