import React from 'react';
import { Box, Grid } from '@mui/material';
import LifestyleBanner from '../lifestyle/LifestyleBanner';
import { LifestylePanelsWrapper as LifestylePanels } from '../lifestyle/LifestylePanels';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { ToolsLayoutMeta } from './ToolsLayout';

const LifestyleToolsView: React.FC = () => {
  return (
    <Box>
      <Grid container alignItems='center' justifyContent='center' display='flex'>
        <LifestyleBanner />
        <LifestylePanels filterByCategory />
      </Grid>
    </Box>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(LifestyleToolsView, meta);
