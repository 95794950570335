import CollapseMessage from '../../components/CollapseMessage';
import { useMessage } from '../../hooks/useMessage';

export const ToastBar = () => {
  const { message, hideMessage } = useMessage();

  return (
    <CollapseMessage
      severity={message?.severity}
      message={message?.content}
      show={message.show}
      hide={hideMessage}
    />
  );
};
