import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type CarBasicsFormProps = {
  carBasics?: Data.Cars.CarBasicFormData;
  onSubmit: (values: Data.Cars.CarBasicFormData) => Promise<void>;
};

const CarBasicsForm: React.FC<CarBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Cars.CarBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.carBasics?.name}
          inputProps={{ maxLength: 64 }}
          label='CarNickname'
          autoFocus
          name='name'
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.Cars.CarBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisCar'
            name='owner'
            row
            defaultValue={props.carBasics?.owner}
            items={[
              { label: 'Me', value: 'me' },
              { label: 'Spouse', value: 'spouse' },
            ]}
          />
        )}
        <TextInput<Data.Cars.CarBasicFormData>
          error={formState.errors.value !== undefined}
          helperText={formState.errors.value?.message?.toString()}
          defaultValue={props.carBasics?.value}
          label='CurrentCarValue'
          name='value'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default CarBasicsForm;
