import { Domain, Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { CarGoalEditViewWrapper } from './car/CarGoalEditView';
import { DrawerRoute } from './GoalDrawerManager';
import { OtherGoalEditViewWrapper } from './other/OtherGoalEditView';
import { VacationGoalEditViewWrapper } from './vacation/VacationGoalEditView';
import { EmergencyGoalEditViewWrapper } from './emergency/EmergencyGoalEditView';
import { RetirementGoalEditViewWrapper } from './retirement/RetirementGoalEditView';
import { HouseGoalEditViewWrapper } from './house/buy/HouseGoalEditView';
import { useNavigate } from 'react-router';
import { CollegeGoalCombinedEditViewWrapper } from './college/CollegeGoalCombinedEditView';
import { CashGivingGoalCombinedEditViewWrapper } from './giving/cash/CashGivingGoalCombinedEditView';
import { PresentsGivingGoalEditViewWrapper } from './giving/presents/PresentsGivingGoalEditView';
import { DebtSelectionEditViewWrapper } from './debt/DebtSelectionEditView';

export function useGoalDrawerRoutes() {
  const navigate = useNavigate();
  const basicGoalWizard = Hooks.useBasicGoalWizard();
  const cashGivingWizard = Hooks.useCashGivingGoalWizard();
  const collegeGoalWizard = Hooks.useCollegeGoalWizard();
  const retirementGoalWizard = Hooks.useRetirementGoalWizard();
  const mortgageGoalWizard = Hooks.useMortgageGoalWizard();

  const goalRoutes: DrawerRoute[] = useMemo(
    () => [
      {
        type: Domain.GoalTypeEnum.College,
        async onSelect(goal) {
          await collegeGoalWizard.wizard.start({ id: goal.id });
          collegeGoalWizard.wizard.selectStep('basic');
        },
        renderComponent(props) {
          return <CollegeGoalCombinedEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Car,
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({ id: goal.id, type: Domain.GoalTypeEnum.Car });
        },
        renderComponent(props) {
          return <CarGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Other,
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({ id: goal.id, type: Domain.GoalTypeEnum.Other });
        },
        renderComponent(props) {
          return <OtherGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Gift,
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({ id: goal.id, type: Domain.GoalTypeEnum.Gift });
        },
        renderComponent(props) {
          return <PresentsGivingGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Vacation,
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({ id: goal.id, type: Domain.GoalTypeEnum.Vacation });
        },
        renderComponent(props) {
          return <VacationGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum['Emergency Fund'],
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({
            id: goal.id,
            type: Domain.GoalTypeEnum['Emergency Fund'],
          });
        },
        renderComponent(props) {
          return <EmergencyGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Cash_Giving,
        async onSelect(goal) {
          await cashGivingWizard.wizard.start({ id: goal.id });
        },
        renderComponent(props) {
          return <CashGivingGoalCombinedEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Retirement,
        async onSelect(goal) {
          await retirementGoalWizard.wizard.start({ id: goal.id });
        },
        renderComponent(props) {
          return <RetirementGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.House,
        async onSelect(goal) {
          await basicGoalWizard.wizard.start({ id: goal.id, type: Domain.GoalTypeEnum.House });
        },
        renderComponent(props) {
          return <HouseGoalEditViewWrapper editing={true} {...props} />;
        },
      },
      {
        type: Domain.GoalTypeEnum.Volunteer,
        async onSelect() {
          navigate(`/goals/giving/volunteering`);
        },
      },
      {
        type: Domain.GoalTypeEnum.Mortgage,
        async onSelect(goal) {
          await mortgageGoalWizard.wizard.start({ id: goal.id });
          navigate(`/goals/house/pay-off-mortgage/${goal?.id}`);
        },
      },
      {
        type: Domain.GoalTypeEnum.Non_Mortgage_Debt,
        async onSelect() {
          // no wizard for debt payoff goal
        },
        renderComponent(props) {
          return <DebtSelectionEditViewWrapper editing={true} {...props} />;
        },
      },
    ],
    [basicGoalWizard]
  );

  return goalRoutes;
}
