import { Card, CardHeader, Grid, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

declare type TargetDateFundCardProps = PropsWithChildren & {
  header: React.ReactNode | string;
  summary: React.ReactNode | string;
  icon: React.ReactNode;
};

const TargetDateFundCard: React.FC<TargetDateFundCardProps> = (props) => {
  return (
    <Card className='collapsible-panel'>
      <CardHeader
        className='collapsible-panel-header'
        sx={{ cursor: 'auto !important' }}
        color='secondary'
        action={
          <Grid sx={{ cursor: 'auto !important' }}>
            <Typography
              className='collapsible-panel-summary'
              component='h1'
              fontFamily='Montserrat'
              variant='p20Bold'
              color='primary'
              sx={{ display: 'block', marginRight: '5px' }}>
              {props.summary}
            </Typography>
          </Grid>
        }
        disableTypography={true}
        title={
          <Grid container className='collapsible-panel-title'>
            <Grid item justifySelf='center' alignSelf='center'>
              <Typography component='h2' variant='p18Bold' color='secondary'>
                {props.header}
              </Typography>
            </Grid>
          </Grid>
        }
        avatar={props.icon}
      />
    </Card>
  );
};

export default TargetDateFundCard;
