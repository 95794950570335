export enum SavedFormsKeyEnum {
  CollisionInsurance = 'collision-insurance',
  CostOfCollege = 'cost-of-college',
  FinanceOrPayCash = 'finance-or-pay-cash',
  HomeSaleNetProceeds = 'home-sale-net-proceeds',
  HowMuchCar = 'how-much-car',
  HowMuchHouse = 'how-much-house',
  InflationCalculator = 'inflation-calculator',
  IraFeeChecker = 'ira-fee-checker',
  MortgageRates = 'mortgage-rates',
  MortgageRefinance = 'mortgage-refinance',
  PersonalLoanCalculator = 'personal-loan-calculator',
  RealCostOfDebt = 'real-cost-of-debt',
  RentOrBuy = 'rent-or-buy',
  TaxBenefitsOfDonations = 'tax-benefits-of-donations',
}
