import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { useNavigate, useParams } from 'react-router-dom';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { Backdrop } from '@mui/material';
import { FormLoader } from '../../../../../components/FormLoader';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { useEffect } from 'react';
import { map } from 'rxjs';
import { Loading } from '../../../../../hooks/useLoading';
import InvestmentsView from '../../investments/InvestmentsView';

// change from basics to investments eventually
export const BrokerageAccountInvestmentsEditViewWrapper: React.FC<EditViewProps> = ({ onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.useInvestmentAccountWizard<Data.InvestmentAccountBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: Domain.PlanTypeEnum['Brokerage Account'] });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted, currentStep]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <BrokerageAccountInvestmentsEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface BrokerageAccountBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccountBasicFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.InvestmentAccountWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const BrokerageAccountInvestmentsEditView: React.FC<BrokerageAccountBasicsEditViewProps> = ({
  params,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const { wizard } = Hooks.useInvestmentAccountWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const { id } = useParams();
  const investmentAccountId = parseInt(id ?? '');
  const { investments } = Hooks.useInvestmentData(investmentAccountId);

  const onBack = () => navigate(-1);
  const onSkip = () => navigate(`/account-details/brokerage-account/summary/${params.id}`);

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: Domain.PlanTypeEnum['Brokerage Account'] });
    }
  }, [isStarted]);

  if (!ready) return null;

  return (
    <>
      <InvestmentsView investmentAccountId={investmentAccountId} />
      <WizardFooter
        disableBack={false}
        onBack={onBack}
        onDone={onSkip}
        nextVariant={investments.length === 0 ? 'link' : undefined}
        {...getWizardFooterProps(investments.length > 0 ? 'Finish' : 'SkipForNow')}
      />
    </>
  );
};

const meta = {
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(BrokerageAccountInvestmentsEditViewWrapper, meta);
