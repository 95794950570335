import React, { useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WarningDeleteAccountModal,
  ConfirmDeleteAccountModal,
} from '../settings/DeleteAccountModals';

const RemoveAccountAction: React.FC = () => {
  const { t } = useTranslation();
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  return (
    <>
      <WarningDeleteAccountModal
        modalOpen={warningModalOpen}
        setModalOpen={setWarningModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
      />
      <ConfirmDeleteAccountModal modalOpen={confirmModalOpen} setModalOpen={setConfirmModalOpen} />
      <Grid container mt='50px' justifyContent='center' alignItems='center' flexDirection='column'>
        <Typography variant='p16' color='secondary'>
          {t('LookingToCloseYourAccount')}
        </Typography>
        <Link sx={{ textDecoration: 'none' }}>
          <Typography
            mt='20px'
            fontFamily='Montserrat'
            fontWeight='600'
            color='error.main'
            variant='p14'
            onClick={() => setWarningModalOpen(true)}>
            {t('RemoveMyAccount')}
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export default RemoveAccountAction;
