import { Backdrop, Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContactInfoForm from './ContactInfoForm';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { PersonalDetailsLayoutMeta } from '../PersonalDetailsLayout';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../components/form/FormContent';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { emailRegx, phoneNumberRegex } from '../../../helpers/utilityFunctions';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { map } from 'rxjs';

export const ContactInfoEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.ContactInfoFormData>();
  const isStarted = useObservable(wizard.isStarted$);

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({});
    } else if (isStarted) {
      wizard.selectStep('contactInfo');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'contactInfo')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'contactInfo' && ( // load params before mounting to prevent flickering
        <FormLoader loading={loading}>
          <ContactInfoEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface ContactInfoEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.People.ContactInfoFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
}

const contactInfoSchema = Yup.object({
  email: Yup.string().matches(emailRegx, 'Invalid email address'),
  mobileNumber: Yup.string().required('Required').matches(phoneNumberRegex, 'Invalid phone number'),
});

const ContactInfoEditView: React.FC<ContactInfoEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.People.ContactInfoFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(contactInfoSchema),
  });
  const { setLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);
      await wizard.commitStep('contactInfo');
      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/personal-details/mailing-info');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('ContactInfo')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <ContactInfoForm contactInfo={currentStep?.model} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'NextAddress',
  // nextUrl: 'mailing-info',
  showBack: true,
  showNext: true,
  hexSteps: {
    steps: 5,
    currentStep: 2,
  },
  step: 'contactInfo',
} satisfies LayoutMeta<PersonalDetailsLayoutMeta>;

export default withLayoutMeta(ContactInfoEditViewWrapper, meta);
