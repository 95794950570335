/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../hooks/useAutoSaveHandler';
import { Data, Hooks } from '@3nickels/data-modules';
import { BudgetSectionProps, OtherSavingsFormData } from '../Types';
import * as Yup from 'yup';
import EmbeddedFormInput from '../../../components/EmbeddedFormInput';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';

const schema: Yup.Schema = Yup.object({
  otherSavings: Yup.string().notRequired(),
});

export declare type OtherSavingsFormProps = BudgetSectionProps & {
  defaultValues: OtherSavingsFormData;
};

export const OtherSavingsForm: React.FC<OtherSavingsFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<OtherSavingsFormData>();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): OtherSavingsFormData => {
    const otherSavings = cleanWholeNumberStr(data.otherSavings ?? '', { max });
    return { otherSavings };
  };

  const saveHandler = useCallback(
    async (data: OtherSavingsFormData) => {
      const formData = formatInputs(data);
      const otherSavings = Data.Budget.convertMonthlyOrAnnual(
        'annual',
        mode,
        formData.otherSavings ?? 0
      );
      await commands.execute(Data.Savings.Commands.UpdateSaving, {
        otherSavings,
      });
    },
    [mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    mode: 'full',
    schema,
    defaultValues,
    onSave: saveHandler,
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <EmbeddedFormInput<OtherSavingsFormData>
        label='OtherSaving'
        placeholder='Other saving'
        autoComplete='off'
        name='otherSavings'
        type='dollar'
        max={max}
        defaultValue={defaultValues.otherSavings}
        error={formState.errors.otherSavings !== undefined}
        helperText={formState.errors.otherSavings?.message?.toString()}
      />
    </form>
  );
};
