/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, MenuItem, Typography } from '@mui/material';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Domain } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import { Button } from '../../../../components/buttons/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';

type RentOrBuyFormProps = {
  methods: UseFormReturn;
  defaultValues: Domain.AdviceHrobFormData;
  results: Domain.AdviceHrobResultData | undefined;
  onSubmit: (values: Domain.AdviceHrobFormData) => Promise<void>;
};

const RentOrBuyForm: React.FC<RentOrBuyFormProps> = ({
  methods,
  defaultValues,
  onSubmit,
  results,
}) => {
  const { t } = useTranslation();
  const { formState, setValue } = methods;

  const showAdvancedDefault = useMemo(() => {
    if (Object.keys(defaultValues).length === 0 && defaultValues.constructor === Object) {
      return false;
    }
    return (
      defaultValues.annualHomeownersInsurance !== 0 ||
      defaultValues.monthlyCondoFees !== 0 ||
      defaultValues.annualMaintenanceCosts !== 0 ||
      defaultValues.annualPropertyTax !== 0 ||
      defaultValues.otherAnnualHousingCosts !== 0 ||
      defaultValues.annualHomeValueIncrease !== 0
    );
  }, [defaultValues]);

  const [showAdvanced, setShowAdvanced] = useState(showAdvancedDefault);

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit({
      ...data,
      annualHomeownersInsurance: !showAdvanced ? undefined : data.annualHomeownersInsurance,
      monthlyCondoFees: !showAdvanced ? undefined : data.monthlyCondoFees,
      annualMaintenanceCosts: !showAdvanced ? undefined : data.annualMaintenanceCosts,
      annualPropertyTax: !showAdvanced ? undefined : data.annualPropertyTax,
      otherAnnualHousingCosts: !showAdvanced ? undefined : data.otherAnnualHousingCosts,
      annualHomeValueIncrease: !showAdvanced ? undefined : data.annualHomeValueIncrease,
    });
  });

  useEffect(() => {
    if (defaultValues) {
      setValue(
        'mortgageTermType',
        defaultValues.mortgageTermType ?? Domain.MortgageTermTypeEnum['30-Year Fixed']
      );
    }
  }, [defaultValues]);

  return (
    <Grid className='tool-form-container'>
      <Typography variant='p18Bold' color='secondary'>
        {t('RentInfo')}
      </Typography>
      <SliderPercent<Domain.AdviceHrobFormData>
        error={formState.errors.monthlyRent !== undefined}
        helperText={formState.errors.monthlyRent?.message?.toString()}
        name='monthlyRent'
        label='MonthlyRent'
        type='dollar'
        min={0}
        max={5000}
        step={100}
        defaultValue={defaultValues.monthlyRent}
      />
      <SliderPercent<Domain.AdviceHrobFormData>
        error={formState.errors.monthlyRentersInsurance !== undefined}
        helperText={formState.errors.monthlyRentersInsurance?.message?.toString()}
        name='monthlyRentersInsurance'
        label='MonthlyRentersInsurance'
        type='dollar'
        min={0}
        max={100}
        defaultValue={defaultValues.monthlyRentersInsurance}
      />
      <Typography variant='p18Bold' color='secondary'>
        {t('MortgageInfo')}
      </Typography>
      <SliderPercent<Domain.AdviceHrobFormData>
        error={formState.errors.homePrice !== undefined}
        helperText={formState.errors.homePrice?.message?.toString()}
        name='homePrice'
        label='HomePrice'
        type='dollar'
        min={0}
        max={1000000}
        step={10000}
        defaultValue={defaultValues.homePrice}
      />
      <SliderPercent<Domain.AdviceHrobFormData>
        error={formState.errors.downPayment !== undefined}
        helperText={formState.errors.downPayment?.message?.toString()}
        name='downPayment'
        label='DownPayment'
        type='dollar'
        min={0}
        max={1000000}
        step={10000}
        defaultValue={defaultValues.downPayment}
      />
      <SliderPercent<Domain.AdviceHrobFormData>
        error={formState.errors.mortgageInterestRate !== undefined}
        helperText={formState.errors.mortgageInterestRate?.message?.toString()}
        name='mortgageInterestRate'
        label='InterestRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.mortgageInterestRate}
      />
      <SelectInput<Domain.AdviceHrobFormData>
        error={formState.errors.mortgageTermType !== undefined}
        helperText={formState.errors.mortgageTermType?.message?.toString()}
        defaultValue={Domain.MortgageTermTypeEnum['30-Year Fixed']}
        name='mortgageTermType'
        placeholder='ChooseMortgageTerm'
        label='MortgageTerm'>
        {Domain.mortgageTermTypes.map((x) => (
          <MenuItem value={x.key}>{x.value}</MenuItem>
        ))}
      </SelectInput>

      <Typography
        className='no-underline-clickable'
        variant='p18Bold'
        color='secondary'
        width='190px'
        display='flex'
        justifyContent='space-between'
        onClick={() => setShowAdvanced((prev) => !prev)}>
        {showAdvanced ? t('HideAdvanced') : t('ShowAdvanced')}
        <IconButton>
          {showAdvanced ? (
            <KeyboardArrowUpIcon fontSize='large' color='primary' />
          ) : (
            <KeyboardArrowDownIcon fontSize='large' color='primary' />
          )}
        </IconButton>
      </Typography>

      <Grid flexDirection='column' rowGap='30px' display={showAdvanced ? 'flex' : 'none'}>
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.annualHomeownersInsurance !== undefined}
          helperText={formState.errors.annualHomeownersInsurance?.message?.toString()}
          name='annualHomeownersInsurance'
          label='AnnualHomeownerInsurance'
          type='dollar'
          min={0}
          max={10000}
          step={100}
          defaultValue={defaultValues.annualHomeownersInsurance}
        />
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.monthlyCondoFees !== undefined}
          helperText={formState.errors.monthlyCondoFees?.message?.toString()}
          name='monthlyCondoFees'
          label='MonthlyHOAFees'
          type='dollar'
          min={0}
          max={1000}
          step={50}
          defaultValue={defaultValues.monthlyCondoFees}
        />
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.annualMaintenanceCosts !== undefined}
          helperText={formState.errors.annualMaintenanceCosts?.message?.toString()}
          name='annualMaintenanceCosts'
          label='AnnualMaintenanceAndRepairs'
          type='dollar'
          min={0}
          max={10000}
          step={100}
          defaultValue={defaultValues.annualMaintenanceCosts}
        />
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.annualPropertyTax !== undefined}
          helperText={formState.errors.annualPropertyTax?.message?.toString()}
          name='annualPropertyTax'
          label='AnnualPropertyTax'
          type='dollar'
          min={0}
          max={10000}
          step={100}
          defaultValue={defaultValues.annualPropertyTax}
        />
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.otherAnnualHousingCosts !== undefined}
          helperText={formState.errors.otherAnnualHousingCosts?.message?.toString()}
          name='otherAnnualHousingCosts'
          label='AnnualOtherHousingCosts'
          type='dollar'
          min={0}
          max={10000}
          step={100}
          defaultValue={defaultValues.otherAnnualHousingCosts}
        />
        <SliderPercent<Domain.AdviceHrobFormData>
          error={formState.errors.annualHomeValueIncrease !== undefined}
          helperText={formState.errors.annualHomeValueIncrease?.message?.toString()}
          name='annualHomeValueIncrease'
          label='AnnualHomeValueIncrease'
          type='percent'
          precision={3}
          defaultValue={defaultValues.annualHomeValueIncrease}
        />
      </Grid>

      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default RentOrBuyForm;
