/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { firstValueFrom, map } from 'rxjs';
import { InvestmentAccountBasicFormData } from './Types';
import InvestmentAccountBasicsForm from './InvestmentAccountBasicsForm';

const accountBasicsSchema = (includeSpouse: boolean) =>
  Yup.object({
    name: Yup.string()
      .required('Name is required')
      .test('name', 'Plan name must not contain special characters', (name) =>
        /^["^[0-9a-zA-Z\s\-.(),']*$/.test(name)
      ),
    owner: includeSpouse ? Yup.string().required('Owner is required') : Yup.string().notRequired(),
  });

function resolveHeaderKey(type: Domain.PlanTypeEnum) {
  switch (type) {
    case Domain.PlanTypeEnum['Brokerage Account']:
      return 'AddBrokerageAccount';
    case Domain.PlanTypeEnum.HSA:
      return 'AddHSA';
    default:
      return 'InvestmentAccount';
  }
}

function resolveUrlSlug(type: Domain.PlanTypeEnum) {
  switch (type) {
    case Domain.PlanTypeEnum['Brokerage Account']:
      return 'brokerage-account';
    case Domain.PlanTypeEnum.HSA:
      return 'health-savings-account';
    default:
      throw new Error(`resolveUrlSlug does not support plan type ${type}`);
  }
}

export declare type InvestmentAccountBasicsProps = EditViewProps & {
  type: Domain.PlanTypeEnum;
};

export const InvestmentAccountBasicsEditViewWrapper: React.FC<InvestmentAccountBasicsProps> = ({
  editing,
  onBack,
  type,
}) => {
  const { loading, currentStep, wizard } =
    Hooks.useInvestmentAccountWizard<Data.InvestmentAccountBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && ( // don't even mount until correct step is set
        <FormLoader loading={loading}>
          <InvestmentAccountBasicsEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
            type={type}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface InvestmentAccountBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccountBasicFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.InvestmentAccountWizard;
  type: Domain.PlanTypeEnum;
}

const InvestmentAccountBasicsEditView: React.FC<InvestmentAccountBasicsEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const includeSpouse = Hooks.useIncludeSpouse();
  const methods = useForm<InvestmentAccountBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(accountBasicsSchema(includeSpouse)),
    mode: 'onChange',
  });
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/${resolveUrlSlug(type)}/basics/${wizardParams?.id}`, {
        replace: true,
      });

      // TODO -- Come back and fix this immediately
      if (type === Domain.PlanTypeEnum['Brokerage Account']) {
        navigate(`/account-details/${resolveUrlSlug(type)}/investments/${wizardParams?.id}`);
      } else if (type === Domain.PlanTypeEnum.HSA) {
        // TODO -- Contributions are next
        navigate(`/account-details/${resolveUrlSlug(type)}/contributions/${wizardParams?.id}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t(editing ? 'AccountBasics' : resolveHeaderKey(type))}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <InvestmentAccountBasicsForm
                    accountBasics={currentStep.model}
                    onSubmit={onSubmit}
                    type={type}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};
