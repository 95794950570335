import { Api } from '@3nickels/data-modules';
import { NickelsEnvironment } from '@3nickels/data-modules/lib/apis';

export const domain = window.location.hostname.split('.').slice(-2).join('.');
export const AUTH_URL = `https://auth.${domain}/api/`;
export const API_BASE_URL = `https://api.${domain}`;
export const API_URL = `${API_BASE_URL}/api/`;
export const ADMIN_URL = `https://ipm-auth.${domain}`;

export function getApiEnvFromDomain(): Api.ApiEnvironment {
  return {
    authUrl: AUTH_URL,
    mobileUrl: API_URL,
    key: 'custom' as unknown as NickelsEnvironment,
  };
}
