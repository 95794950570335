import { Domain, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { LayoutContext } from '../../../types/LayoutMeta';
import { Button } from '../../../components/buttons/Button';
import { useNavigate, useOutletContext } from 'react-router-dom';
import LessonSearcher from './LessonSearcher';
import LessonResults from './LessonResults';
import { convertToTitleCase } from '../../../helpers/utilityFunctions';
import { getBackground, HeaderIcon } from './ModuleElements';
import { Loading } from '../../../hooks/useLoading';
import { convertPathnameToCategoryEnum } from '../../../hooks/useFilteredLessonsByCategories';
import { BackgroundImage } from '../../../themes/mui';

export const YourLessonsViewWrapper: React.FC = () => {
  const { lessons, displayedLessons, loadingLessons, searchString, setCategories } =
    Hooks.useFilteredLessonsContext();
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];

  useEffect(() => {
    // call filtered lessons endpoint if user lost lessons context by refreshing
    if (!loadingLessons && lessons.length === 0)
      setCategories([convertPathnameToCategoryEnum(module)]);
  }, [module]);

  if (!loadingLessons && lessons.length === 0) {
    return <Loading />;
  }

  return (
    <YourLessonsView
      module={module}
      displayedLessons={displayedLessons}
      searchString={searchString}
    />
  );
};

interface YourLessonsViewProps {
  module: string;
  searchString: string | undefined;
  displayedLessons: Domain.LessonDto[];
}

const YourLessonsView: React.FC<YourLessonsViewProps> = ({
  module,
  searchString,
  displayedLessons,
}) => {
  const navigate = useNavigate();
  const { setMeta } = useOutletContext<LayoutContext>();

  const background = useMemo(() => {
    return getBackground(module);
  }, [module]);

  const methods = useForm<{ searchString: string }>({
    defaultValues: { searchString: searchString ?? '' },
  });

  useEffect(() => {
    if (background) {
      setMeta(() => ({
        background: background,
      }));
    }
  }, [background]);

  return (
    <Box>
      <Grid display='flex' justifyContent='space-between' alignItems='center'>
        <Grid display='flex' columnGap='20px' justifyContent='flex-start'>
          <HeaderIcon module={module} style={{ height: '80px', width: '80px' }} />
          <Typography variant='p50Bold' color='secondary'>
            {t('YourLessons')}
          </Typography>
        </Grid>
        <Button
          style={{ height: 'fit-content' }}
          variant='ghost'
          color='secondary'
          onClick={() => navigate(`/tools/${module}`)}>
          {'<'} {t('BackToTools', { module: convertToTitleCase(module.replace('-', '_')) })}
        </Button>
      </Grid>

      <Grid container className='search-view'>
        <Grid item width='50%'>
          <Stack spacing={3}>
            <Grid
              style={{
                paddingTop: '3vh',
                position: 'sticky',
                top: '1px',
                width: '100%',
                height: 'auto',
                ...BackgroundImage,
              }}>
              <FormContent formProviderProps={methods}>
                <LessonSearcher />
              </FormContent>
            </Grid>
            <LessonResults displayedLessons={displayedLessons} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default YourLessonsView;
