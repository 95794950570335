import { Box, MenuItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../../../../components/form/TextInput';
import { Margins, Spacing } from '../../../../../themes';
import { useFormContext } from 'react-hook-form';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import SelectInput from '../../../../../components/form/SelectInput';
import BulletedList from '../../../../../components/BulletedList';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';
import { formatWholeDollars } from '../../../../../helpers/utilityFunctions';

export type HsaContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.HsaContributionsFormData;
  onSubmit: (values: Data.InvestmentAccounts.HsaContributionsFormData) => Promise<void>;
};

const HsaContributionsForm: React.FC<HsaContributionsFormProps> = (props) => {
  const { t } = useTranslation();
  const { formState, setValue, trigger } = useFormContext();
  const federalLimits = Hooks.useFederalLimits();
  const singleLimit = formatWholeDollars(federalLimits?.individualHsaContributions ?? 0);
  const familyLimit = formatWholeDollars(federalLimits?.familyHsaContributions ?? 0);

  const [hideContributions, setHideContributions] = useState(
    props.contributions?.coverageType === Domain.CoverageTypeEnum['No Longer Eligible']
  );

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <SelectInput<Data.InvestmentAccounts.HsaContributionsFormData>
          error={formState.errors.coverageType !== undefined}
          helperText={formState.errors.coverageType?.message?.toString()}
          defaultValue={props.contributions?.coverageType}
          label='MyHDHPCoverageType'
          placeholder='ChooseCoverageType'
          name='coverageType'>
          {Domain.CoverageTypeEnumList.map((x) => (
            <MenuItem
              value={x.key}
              onClick={() => {
                if (x.key === Domain.CoverageTypeEnum['No Longer Eligible']) {
                  setValue('annualContribPreTaxDollar', '', {
                    shouldValidate: true,
                  });
                  trigger('annualContribPreTaxDollar');
                  setHideContributions(true);
                } else {
                  setHideContributions(false);
                  if (formState.dirtyFields['annualContribPreTaxDollar']) {
                    trigger('annualContribPreTaxDollar');
                  }
                }
              }}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
        {!hideContributions && (
          <TextInput<Data.InvestmentAccounts.HsaContributionsFormData>
            error={formState.errors.annualContribPreTaxDollar !== undefined}
            helperText={formState.errors.annualContribPreTaxDollar?.message?.toString()}
            defaultValue={props.contributions?.annualContribPreTaxDollar}
            label='AnnualContributions'
            name='annualContribPreTaxDollar'
            type='dollar'
            helpContext={
              <HelpPopover title={t('AnnualContributions')}>
                <Stack spacing={Spacing.xxxs}>
                  <HelpPopoverText>{t('HsaMedicalExpense')}</HelpPopoverText>
                  <HelpPopoverText style={{ display: 'inline' }}>
                    <strong>{t('Note')}:</strong> {t('HsaContributionLimits')}
                  </HelpPopoverText>
                  <BulletedList
                    style={Margins.mt_xxs}
                    children={[
                      t('HsaSingleLimit', { limit: singleLimit }),
                      t('HsaFamilyLimit', { limit: familyLimit }),
                    ]}
                  />
                  <HelpPopoverText>{t('HsaAgeCatchup')}</HelpPopoverText>
                </Stack>
              </HelpPopover>
            }
          />
        )}
      </Stack>
    </Box>
  );
};

export default HsaContributionsForm;
