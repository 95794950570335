/* eslint-disable @typescript-eslint/no-explicit-any */
// We'll probably want to do more with this later
export interface ILogger {
  debug(message?: any, ...optionalParams: any[]): void;
}

class Logger implements ILogger {
  debug(message?: any, ...optionalParams: any[]): void {
    if (typeof process.env.DEBUG === 'undefined') {
      return;
    }

    console.log(message, optionalParams);
  }
}

export const logger = new Logger();
