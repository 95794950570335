import { Domain } from '@3nickels/data-modules';
import { EditViewProps } from '../EditViewUtils';

export interface SummaryCardProps {
  onEdit: () => void;
  needsAttention?: boolean;
  disableEdit?: boolean;
}

export interface AccountEditViewProps extends EditViewProps {
  includeSpouse: boolean;
}

export function mapRetirementStepToPath(stepKey?: Domain.RetirementWizardNavigationRegistryKey) {
  switch (stepKey) {
    case 'basic':
      return 'basics';
    case 'contributions':
      return 'contributions';
    case 'employerContributions':
      return 'employer-contributions';
    case 'investments':
      return 'investments';
    default:
      return 'summary';
  }
}
