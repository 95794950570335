import { Box, Grid, Stack } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { KnowTheNumbers } from './KnowTheNumbers';
import { WhenChoosingLiability } from './WhenChoosingLiability';
import { TopSection } from './TopSection';
import { UsefulTip } from './UsefulTip';
import { CollisionInsurance } from './CollisionInsurance';
import { ComprehensiveInsurance } from './ComprehensiveInsurance';

const AutoInsuranceNeedToKnowView: React.FC = () => {
  return (
    <Box>
      <Grid container flexDirection='column'>
        <TopSection />
        <Stack mt={5} spacing={6}>
          <KnowTheNumbers />
          <WhenChoosingLiability />
          <UsefulTip />
          <CollisionInsurance />
          <ComprehensiveInsurance />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(AutoInsuranceNeedToKnowView, meta);
