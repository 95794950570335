import _ from 'lodash';
/* eslint-disable @typescript-eslint/no-explicit-any */
const sortData = (data: any, by: any, sortDirection: any, setNewData: any, setSortBy: any) => {
  const sortedData = [...data];

  sortedData.sort((a: any, b: any) => {
    const itemA =
      typeof a[by] === 'number'
        ? a[by]
        : a[by].includes('$')
        ? cleanWholeNumberStr(a[by])
        : a[by].toUpperCase();
    const itemB =
      typeof b[by] === 'number'
        ? b[by]
        : b[by].includes('$')
        ? cleanWholeNumberStr(b[by])
        : b[by].toUpperCase();
    return sortDirection ? (itemA > itemB ? 1 : -1) : itemA > itemB ? -1 : 1;
  });

  setNewData(sortedData);
  setSortBy(by);
  return sortedData;
};

const formatDateMMDDYYYY = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getUTCMonth() + 1; // add 1 for 0 indexing
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return month + '/' + day + '/' + year;
};

const formatMalformedDate = (str: string) => {
  const cleanedDate = str.replace(/\D/g, '');
  const month = cleanedDate.substring(0, 2);
  const day = cleanedDate.substring(2, 4);
  const year = cleanedDate.substring(4, 8);
  return `${month}/${day}/${year}`;
};

const isValidDate = (dateStr: string) => {
  const cleanDateStr = dateStr.replace(/\D/g, '');

  if (cleanDateStr.length !== 8) {
    return false;
  }

  const month = parseInt(cleanDateStr.substring(0, 2), 10);
  const day = parseInt(cleanDateStr.substring(2, 4), 10);
  const year = parseInt(cleanDateStr.substring(4, 8), 10);

  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function convertToTitleCase(str: string) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .replace(/Us/g, 'US');
}

function formatPhoneNumber(phoneNumber: string): string {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  if (cleaned.length === 0) return '';
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
}

function formatWholeDollars(x: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(Math.round(x));
}

const formatWholePositiveDollars = (x: number | undefined) => {
  if (!x || x <= 0) return formatWholeDollars(0);
  return formatWholeDollars(x);
};

function formatDecimalDollars(x: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(x);
}

interface CleanNumberOptions {
  float?: boolean;
  excludeNegatives?: boolean;
  precision?: number;
  max?: number;
}

function cleanWholeNumberStr(input: string, options: CleanNumberOptions = { float: true }): number {
  let strVal = input;
  if (typeof strVal !== 'string' && typeof strVal !== 'undefined') {
    strVal = (strVal as any).toString();
  }

  const clean = () => {
    if (options?.excludeNegatives) {
      if (!strVal) return 0;
      if (options?.float) {
        if (options?.precision)
          return _.floor(parseFloat(strVal?.replace(/[^\d.]/g, '')) || 0, options.precision);
        return parseFloat(strVal?.replace(/[^\d.]/g, '')) || 0;
      }
      return Math.round(parseFloat(strVal?.replace(/[^\d.]/g, '')) || 0);
    } else {
      if (!strVal) return 0;
      if (options?.float) {
        if (options.precision)
          return _.floor(parseFloat(strVal?.replace(/[^-?\d.]/g, '')) || 0, options.precision);
        return parseFloat(strVal?.replace(/[^-?\d.]/g, '')) || 0;
      }
      return Math.round(parseFloat(strVal?.replace(/[^-?\d.]/g, '')) || 0);
    }
  };
  const cleanedNumber = clean();
  if (options?.max && cleanedNumber > options.max) return options.max;
  return cleanedNumber;
}

export function formatPercent(x: number, excludeNegatives = false): string {
  if (isNaN(x || 0)) return 'NaN';
  let n = Number(x.toFixed(2));
  if (excludeNegatives) {
    n = Math.abs(n);
  }
  return `${n}%`;
}

function getInterestPayment(interestRate: number, amountOwed: number): number {
  const rate = interestRate / 100;
  return Math.ceil((rate * amountOwed) / 12);
}

function convertMonthsToYearMonth(totalMonths: number): { years: number; months: number } {
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  return { years, months };
}

function formatYAxis(y: number): string {
  if (Math.abs(y) >= 1000000) {
    return `$${Math.round((y / 1000000) * 10) / 10}M`;
  } else if (Math.abs(y) >= 1000) {
    return `$${Math.round((y / 1000) * 10) / 10}K`;
  }
  return formatWholeDollars(y);
}

const emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phoneNumberRegex = /\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}/;

export {
  sortData,
  formatDateMMDDYYYY,
  formatMalformedDate,
  isValidDate,
  capitalizeFirstLetter,
  convertToTitleCase,
  formatPhoneNumber,
  formatWholeDollars,
  formatWholePositiveDollars,
  formatDecimalDollars,
  cleanWholeNumberStr,
  getInterestPayment,
  convertMonthsToYearMonth,
  formatYAxis,
  emailRegx,
  phoneNumberRegex,
};
