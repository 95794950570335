import { useTranslation } from 'react-i18next';
import { AddInvestmentsViewProps } from './AddInvestments';
import { useEffect, useState } from 'react';
import { InvestmentDrawer, InvestmentDrawerProps, InvestmentDrawerStatus } from './InvestmentsView';
import GroupPanels, { Group, GroupPanelVariant } from '../../../../components/GroupPanels';
import { Grid, Typography } from '@mui/material';
import { CoinsSvg } from '../../../../components/images/CoinsSvg';
import { LinkSvg } from '../../../../components/images/LinkSvg';
import { SearchSvg } from '../../../../components/images/SearchSvg';
import HelpTitle from '../../../../components/HelpTitle';
import { AddInvestmentHelpContent } from './investment-details/InvestmentDetailsEditView';
import { LinkAccountProps } from './InvestmentsCard';
import { Domain } from '@3nickels/data-modules';

interface AddInvestmentSelectionProps extends AddInvestmentsViewProps, LinkAccountProps {
  variant?: GroupPanelVariant;
}

const AddInvestmentSelection: React.FC<AddInvestmentSelectionProps> = ({
  account,
  variant,
  linkAccount,
}) => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<InvestmentDrawerStatus>({ open: false });
  const planData = account.account as Domain.PlanData;

  useEffect(() => {
    if (drawerStatus.usePlaid === true && linkAccount) {
      linkAccount();
      setDrawerStatus({
        ...drawerStatus,
        usePlaid: false,
      });
    }
  }, [drawerStatus.usePlaid, linkAccount]);

  return (
    <Grid container flexDirection='column'>
      <HelpTitle
        text={t('AddInvestments')}
        helpContext={AddInvestmentHelpContent(t('AddInvestments'), planData.planType)}
      />

      <Typography className='subtitle' variant='p18Bold' color='secondary'>
        {account.name}
      </Typography>
      <Typography className='subtitle' variant='p16' color='secondary' marginBottom={'20px'}>
        {t('AddInvestmentBalance?')}
      </Typography>
      <InvestmentsGroupPanels
        variant={variant}
        balanceType={account.accountType === Domain.InvestmentAccountTypeEnum.HSA ? 'Cash' : 'Plan'}
        drawerStatus={drawerStatus}
        setDrawerStatus={setDrawerStatus}
      />
    </Grid>
  );
};

interface InvestmentsGroupPanelsProps extends InvestmentDrawerProps {
  variant?: GroupPanelVariant;
  balanceType?: 'Plan' | 'Cash';
}

export const InvestmentsGroupPanels: React.FC<InvestmentsGroupPanelsProps> = ({
  drawerStatus,
  setDrawerStatus,
  variant,
  balanceType = 'Plan',
}) => {
  const addInvestmentOptions: Group[] = [
    {
      textKey: 'LinkMyInvestments',
      subTextKey: 'LinkYourAccount',
      icon: LinkSvg({}),
      onClick: () => setDrawerStatus({ open: false, drawer: 'investment', usePlaid: true }),
    },
    {
      textKey: 'AddInvestmentsManually',
      subTextKey: 'SearchNameOrTicker',
      icon: SearchSvg({}),
      onClick: () => setDrawerStatus({ open: true, drawer: 'investment' }),
    },
    {
      textKey: `RatherEnter${balanceType}Balance`,
      subTextKey: 'SimplyEnterBalance',
      icon: CoinsSvg({}),
      onClick: () => setDrawerStatus({ open: true, drawer: 'balance' }),
    },
  ];

  return (
    <>
      <Grid className='collapsible-investment-selection' container>
        <GroupPanels variant={variant} groups={addInvestmentOptions} />
      </Grid>
      <InvestmentDrawer drawerStatus={drawerStatus} setDrawerStatus={setDrawerStatus} />
    </>
  );
};

export default AddInvestmentSelection;
