import { Api, Domain, Data } from '@3nickels/data-modules';

export const showBalanceTypeToggle = (type: Domain.PlanTypeEnum): boolean => {
  return (
    type !== Domain.PlanTypeEnum['IRA | Deductible'] &&
    type !== Domain.PlanTypeEnum['IRA | Non-deductible'] &&
    type !== Domain.PlanTypeEnum['IRA | Rollover'] &&
    type !== Domain.PlanTypeEnum['IRA | Roth'] &&
    type !== Domain.PlanTypeEnum['Other Asset'] &&
    type !== Domain.PlanTypeEnum['Brokerage Account']
  );
};

export function determineAvailableTaxTypes(
  accountContributions?: Data.InvestmentAccounts.AccountContributionsFormData,
  planEligibility?: Api.AccountEligibilityRest
): Domain.TaxTypeEnum[] {
  const availableTypes: Domain.TaxTypeEnum[] = [];
  if (
    accountContributions?.paycheckContributions ||
    planEligibility?.eligiblePlanTaxTypes?.preTax
  ) {
    availableTypes.push(Domain.TaxTypeEnum['Pre-tax']);
  }
  if (accountContributions?.rothContributionsAllowed) {
    availableTypes.push(Domain.TaxTypeEnum.Roth);
  }
  if (accountContributions?.afterTaxContributionsAllowed) {
    availableTypes.push(Domain.TaxTypeEnum['After-tax']);
  }

  return availableTypes;
}
