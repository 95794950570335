import { Column, Row, Spacer } from '../components';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { Link as DomLink } from 'react-router-dom';
import {
  Typography,
  Card,
  Link,
  CardContent,
  FormControlLabel,
  Button,
  Checkbox,
} from '@mui/material';
import { logo3NSmall } from '../assets/png';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { AcceptTerms } from '../data/mutations';
import { useLoading } from '../hooks/useLoading';
import { useNavigate } from 'react-router-dom';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Api, Hooks } from '@3nickels/data-modules';
import BulletedList from '../components/BulletedList';
import { Margins } from '../themes';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { useTermsDocs } from '../hooks/useTermsDocs';
import useWindowDimensions from '../hooks/useWindowDimensions';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const organizationData = Hooks.useOrganizationData();
  const acceptTerms = useMutation(new AcceptTerms());
  const docs = useTermsDocs();
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);

  const [terms, setTerms] = useState<string>('');
  const [disclosure, setDisclosure] = useState<string>('');
  const [privacyPolicy, setPrivacyPolicy] = useState<string>('');
  const [checked, setChecked] = useState(false);

  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const { setLoading } = useLoading();
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (docs) {
      docs.forEach((doc) => {
        const url = termsApi.getDocString(doc.id);
        if (doc.docDescription === 'Terms of Service') {
          setTerms(url);
        } else if (doc.docDescription === 'Disclosure') {
          setDisclosure(url);
        } else if (doc.docDescription === 'Privacy Policy') {
          setPrivacyPolicy(url);
        }
      });
    }
  }, [docs, termsApi]);

  useEffect(() => {
    if (acceptTerms.status === MutationStatus.Complete) {
      navigate('/');
      setLoading(false);
    }
  }, [acceptTerms.status]);

  // TODO -- We probably need an error toast or something here

  const onSubmit = async () => {
    setLoading(true);
    acceptTerms.action();
  };

  return (
    <PageContentWithBackground sx={{ height: mobile ? height : undefined }} useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Column style={{ margin: mobile ? mobileMargin : '15px 100px' }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
              {organizationData ? (
                organizationData.logoUri ? (
                  <img
                    src={organizationApi.getLogo()}
                    alt={organizationData?.name}
                    style={{ height: '60%' }}
                  />
                ) : (
                  <Typography variant='p25Bold' color='secondary'>
                    {organizationData?.name}
                  </Typography>
                )
              ) : (
                <Card variant='ghost' />
              )}
              <DomLink style={{ height: '70%' }} to='/'>
                <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
              </DomLink>
            </Row>
            <Column style={{ alignItems: 'center' }}>
              <Typography variant='p30Bold' color='primary'>
                Terms of Service, Disclosure, and Privacy Policy
              </Typography>
              <Spacer height='xs' />
              <Typography variant='p20' color='secondary'>
                Please take a few minutes to review our terms of service, disclosure, and privacy
                policy.
              </Typography>
            </Column>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p20Bold' color='secondary'>
                Terms of Service and Disclosure
              </Typography>
              <Spacer height='xxs' />
              <Typography className='link-wrapper' variant='p16' color='secondary'>
                Before we can get started, please take a few minutes to review our standard{' '}
                <Link href={terms} target='_blank' variant='p16Bold'>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link href={disclosure} target='_blank' variant='p16Bold'>
                  Disclosure
                </Link>{' '}
                documents and confirm your acceptance of them.
              </Typography>
            </Column>
            <Spacer height='sm' />
            <Card variant='innerCard'>
              <CardContent className='pad20'>
                <FormControlLabel
                  style={{ margin: 0 }}
                  color='secondary'
                  control={
                    <Checkbox
                      style={{ marginRight: '20px' }}
                      checked={checked}
                      color='secondary'
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                  }
                  label='I have read the Terms of Service and Disclosure documents, and I agree to them.'
                />
                <Spacer height='xxs' />
                <Typography variant='p16' color='secondary'>
                  The Disclosure document applies to the 3Nickels by GuidedChoice program only. It
                  is not to be construed as an offer to buy or sell, or the solicitation of an offer
                  to buy or sell, any security or financial product or service to any person in any
                  jurisdiction in which such offer, solicitation, purchase, or sale would be
                  unlawful.
                </Typography>
              </CardContent>
            </Card>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p20Bold' color='secondary'>
                Terms of Service and Disclosure
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16Bold' color='secondary'>
                California Consumer Privacy Act (CCPA) Notice at Collection
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16' color='secondary'>
                Under the California Consumer Privacy Act (CCPA), California consumers may be
                entitled to certain notices and disclosures regarding the collection and use of
                their Personal Information.
              </Typography>
            </Column>
            <Spacer height='xs' />
            <Card variant='innerCard'>
              <CardContent className='pad20'>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Column style={{ maxWidth: '50%' }}>
                    <Typography variant='p16Bold' color='secondary'>
                      CATEGORIES OF PERSONAL INFORMATION COLLECTED
                    </Typography>

                    <BulletedList
                      style={{ ...Margins.mt_xs }}
                      children={[
                        'Identifiers including but not limited to name, date of birth, gender, address/phone, marital status and tax filing status',
                        'Financial information including salary, investments, and account balances',
                        ' Professional and employment-related information including company name',
                      ]}
                    />
                  </Column>
                  <Column style={{ maxWidth: '50%' }}>
                    <Typography variant='p16Bold' color='secondary'>
                      PURPOSES FOR WHICH IT WILL BE USED
                    </Typography>
                    <BulletedList
                      style={{ ...Margins.mt_xs }}
                      children={[
                        'Providing financial advice and guidance,"  Responding to your requests and communicating with you,',
                        'Tracking our visitors use of our websites,',
                        'Improving the content, appearance, and utility of our websites,',
                        'Compliance, audits, and providing assistance to law enforcement, and',
                        ' Complete a corporate transaction, such as a merger, sale, joint venture, or disposition of all or any portion of our business, assets or stock.',
                      ]}
                    />
                  </Column>
                </Row>
              </CardContent>
            </Card>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p16' color='secondary'>
                In cases where investment advice has been provided, by law we must keep record of
                that advice and who it was for, as well as the information used to determine it. Any
                other data collected will either be deleted or obfuscated so that it cannot be
                associated with a particular user.
              </Typography>
              <Spacer height='xs' />
              <Typography className='link-wrapper' variant='p16' color='secondary'>
                GuidedChoice is committed to protecting the personal information that our customers
                provide to us. We do not sell, reveal, or share any of your personal information
                with any third parties and do not monitor, edit, or disclose your data except
                primarily to provide financial advice and guidance to you.{' '}
                <Typography display='contents' variant='p16Bold'>
                  Please review our{' '}
                  <Link href={privacyPolicy} target='_blank' display='contents' variant='p16Bold'>
                    Privacy Policy
                  </Link>
                  , which includes our general and California-specific privacy information.
                </Typography>
              </Typography>
            </Column>
            <Spacer height='md' />
            <Button
              style={{ width: '250px', alignSelf: 'center' }}
              color='secondary'
              disabled={!checked}
              onClick={handleSubmit}>
              I Agree
            </Button>
          </Column>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default TermsAndConditions;
