/* eslint-disable @typescript-eslint/no-empty-function */
import { LayoutHeader } from '../Types';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIsFreeUser, useNeedsOnboarding } from '../../hooks';
import { NickelsMenuItem } from '../../components/toc/SideNavigation';
import { Hooks } from '@3nickels/data-modules';

export interface IContentViewContext {
  id?: string;
  setId: (id?: string) => void;
  sideNav: (titleSelected?: string) => NickelsMenuItem[];
  header: LayoutHeader;
  setSelectedTab: (selected?: string) => void;
  expanded: boolean;
  toggleExpanded: () => void;
}

const ContentViewContext = createContext<IContentViewContext>({
  setId: () => {},
  sideNav: () => [],
  header: {},
  setSelectedTab: () => {},
  expanded: false,
  toggleExpanded: () => {},
});

export const ContentViewProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const needsOnboarding = useNeedsOnboarding();
  const isFreeUser = useIsFreeUser();
  const universityEnabled = Hooks.useUniversityEnabled();
  const courses = Hooks.useAllCourses();
  const [id, setId] = useState<string | undefined>();

  const learningNav = useMemo(() => {
    if (courses?.length === 0 && universityEnabled) {
      return '/learning/university';
    } else if (!universityEnabled && courses?.length === 1) {
      return `/learning/${courses[0].courseId}`;
    } else {
      return '/learning';
    }
  }, [courses, universityEnabled]);

  const [header, setHeader] = useState<LayoutHeader>({});
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), [setExpanded]);

  const sideNav = useMemo(
    () => (titleSelected?: string) => {
      const data = [
        {
          title: 'Home',
          icon: 'HomeSvg',
          selected: false,
          href: '/',
        },
        {
          isDivider: true,
        },
        {
          title: 'Accounts',
          icon: 'AccountsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/account-details',
        },
        {
          title: 'Budget',
          icon: 'BudgetSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/budget-details',
        },
        {
          title: 'Goals',
          icon: 'GoalsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/goals',
        },
        {
          title: 'Tools',
          icon: 'ToolsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/tools',
        },
        {
          isDivider: true,
          exclude: isFreeUser || needsOnboarding,
        },
        {
          title: 'Learn',
          icon: 'LearnSvg',
          selected: false,
          exclude:
            (!universityEnabled && courses?.length === 0) ||
            (universityEnabled && courses?.length === 0 && isFreeUser),
          href: learningNav,
        },
        {
          title: 'Settings',
          icon: 'SettingsSvg',
          selected: false,
          href: '/settings',
        },
        {
          title: 'ContactUs',
          icon: 'EarPhoneSvg',
          selected: false,
          href: '/contact',
        },
        {
          title: 'AboutUs',
          icon: 'MenuExclamationSvg',
          selected: false,
          href: '/about',
        },
        {
          isDivider: true,
        },
        {
          title: 'SignOut',
          icon: 'SignOutSvg',
          selected: false,
          href: '/logout',
        },
      ];

      return data.map((p) =>
        Object.assign(p, {
          selected: p.title === titleSelected,
        })
      );
    },
    [needsOnboarding, isFreeUser, universityEnabled, courses, learningNav]
  );

  useEffect(() => {
    setHeader((previous) => ({ ...previous, hideProfile: needsOnboarding }));
  }, [needsOnboarding]);

  const setSelectedTab = (selected?: string) => {
    setHeader((previous) => ({ ...previous, selectedTab: selected }));
  };

  return (
    <ContentViewContext.Provider
      value={{ id, setId, sideNav: sideNav, header, setSelectedTab, expanded, toggleExpanded }}>
      {children}
    </ContentViewContext.Provider>
  );
};

export function useContentView() {
  const context = useContext(ContentViewContext);
  if (!context) {
    throw new Error('useContentView must be used within ContentViewProvider');
  }

  return context;
}
