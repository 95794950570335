import { Hooks } from '@3nickels/data-modules';
import { HelpOutline } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormContent from '../../../../components/form/FormContent';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { theme } from '../../../../themes/ThemeWrapper';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import CharityCategoriesSelector from './CharityCategoriesSelector';
import CharityResults from './CharityResults';
import CharitySearcher from './CharitySearcher';

const SearchForCharityView: React.FC = () => {
  const { searchTerm, charities, loadingCharities, loadingMoreCharities } =
    Hooks.useCharitySearcher();

  const methods = useForm<{ searchString: string }>({
    defaultValues: { searchString: searchTerm ?? '' },
  });

  const disableSelectors = useMemo(() => {
    return loadingCharities || loadingMoreCharities;
  }, [loadingCharities, loadingMoreCharities]);

  return (
    <Box>
      <Grid container className='search-view'>
        <Grid item width='100%'>
          <Stack spacing={1}>
            <Typography
              variant='p30Bold'
              color='primary'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {t('CharityNavigator')}
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '300',
                  verticalAlign: 'super',
                  marginLeft: '5px',
                  marginBottom: '15px',
                }}>
                &reg;
              </span>
            </Typography>
            <Grid display='flex' alignItems='center' justifyContent='center' columnGap='3px'>
              <Typography
                variant='p16'
                color='secondary'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {t('SearchCharityNavigatorsList')}
              </Typography>
              <Tippy placement='right' content={<HelpContent />} theme='light-nickels-theme'>
                <HelpOutline style={{ color: theme.palette.primary.main }} />
              </Tippy>
            </Grid>
            <FormContent formProviderProps={methods}>
              <Grid
                className='transactions-selectors'
                container
                display='flex'
                justifyContent='space-between'>
                <Grid item width='55%'>
                  <CharitySearcher />
                </Grid>
                <CharityCategoriesSelector disabled={disableSelectors} />
              </Grid>
            </FormContent>
            <CharityResults charities={charities} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const HelpContent: React.FC = () => {
  return (
    <HelpPopover title={t('CharityNavigator')}>
      <HelpPopoverText>{t('EachYearCharityNavigatorAnalyzes')}</HelpPopoverText>
    </HelpPopover>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(SearchForCharityView, meta);
