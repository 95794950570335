import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import { formatWholeDollars } from '../../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { Data, Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';

export interface HSAContributionsCardProps extends SummaryCardProps {
  accountContributions: Data.InvestmentAccounts.HsaContributionsFormData;
}

const HSAContributionsCard: React.FC<HSAContributionsCardProps> = ({
  onEdit,
  accountContributions,
}) => {
  const { t } = useTranslation();
  const hideContributions = useMemo(() => {
    return accountContributions?.coverageType === Domain.CoverageTypeEnum['No Longer Eligible'];
  }, [accountContributions?.coverageType]);

  return (
    <CardDisplay headerKey='AnnualContributions' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('HDHPCoverageType')}
          </Typography>
          <Typography color='secondary'>
            {Domain.CoverageTypeEnumMap.get(accountContributions.coverageType as string)}
          </Typography>
        </Grid>

        {!hideContributions && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AnnualContributions')}
            </Typography>
            <Typography color='secondary'>
              {formatWholeDollars(accountContributions.annualContribPreTaxDollar ?? 0)}
            </Typography>
          </Grid>
        )}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
      </Grid>
    </CardDisplay>
  );
};

export default HSAContributionsCard;
