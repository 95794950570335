import { Data, Hooks } from '@3nickels/data-modules';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { Spacing } from '../../../themes';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import { useFormContext } from 'react-hook-form';

export type SocialSecurityFormProps = {
  ssiStatus: Data.People.SocialSecurityStatus;
};

const SocialSecurityForm: React.FC<SocialSecurityFormProps> = ({ ssiStatus }) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<Data.People.SocialSecurityStatus>
          error={formState.errors.includeSsi !== undefined}
          helperText={formState.errors.includeSsi?.message?.toString()}
          name='includeSsi'
          label='Include your estimated SSI?'
          row
          defaultValue={ssiStatus.includeSsi?.toString()}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.People.SocialSecurityStatus>
            error={formState.errors.spouseIncludeSsi !== undefined}
            helperText={formState.errors.spouseIncludeSsi?.message?.toString()}
            name='spouseIncludeSsi'
            label="Include spouse's estimated SSI?"
            row
            defaultValue={ssiStatus.spouseIncludeSsi?.toString()}
            items={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default SocialSecurityForm;
