import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data } from '@3nickels/data-modules';
import { useMemo } from 'react';

export interface CarDetailsCardProps extends SummaryCardProps {
  carDetails: Data.Cars.CarDetailsFormData;
}

const CarDetailsCard: React.FC<CarDetailsCardProps> = ({ onEdit, carDetails }) => {
  const { t } = useTranslation();
  const missingLoanInfo = useMemo(
    () => typeof carDetails.hasLoan === 'undefined' || carDetails.hasLoan === null,
    [carDetails.hasLoan]
  );

  return (
    <CardDisplay headerKey='CarLoanDetails' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CarLoan?')}
          </Typography>
          <Typography color={missingLoanInfo ? 'error' : 'secondary'}>
            {missingLoanInfo ? t('AnswerNeeded') : t(carDetails.hasLoan ? 'Yes' : 'No')}
          </Typography>{' '}
        </Grid>
        {carDetails.hasLoan ? (
          <>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('CurrentBalance')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(carDetails.amountOwed ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('InterestRate')}
              </Typography>
              <Typography color='secondary'>{carDetails.interestRate ?? 0}%</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('AmountIPayMonthly')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(carDetails.monthlyPayment ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MinimumPayment')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(carDetails.minimumPayment ?? 0)}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
          </>
        )}
      </Grid>
    </CardDisplay>
  );
};

export default CarDetailsCard;
