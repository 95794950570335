import { Box, Card, CardContent, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../../components/form/TextInput';
import { Spacing } from '../../../../../themes';
import SecuritiesSearcher from './SecuritiesSearcher';
import { useEffect, useState } from 'react';
import { Api, Data, Domain, ListItem } from '@3nickels/data-modules';
import ToggleRadioButtonGroup from '../../../../../components/form/ToggleRadioButtonGroup';
import SelectInput from '../../../../../components/form/SelectInput';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { cleanWholeNumberStr, formatDecimalDollars } from '../../../../../helpers/utilityFunctions';

export declare type InvestmentDetailsFormData = Domain.InvestmentData;

export type InvestmentDetailsFormProps = {
  edit?: boolean;
  investment?: InvestmentDetailsFormData;
  account: Domain.IInvestmentAccount | undefined;
  institutionalAccount: boolean;
  contributions: Data.InvestmentAccounts.AccountContributionsFormData;
  planEligibility: Domain.AccountEligibility | undefined;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams | undefined;
  onSubmit: (values: InvestmentDetailsFormData) => void;
  selectSecurity?: (security?: Api.SecurityRest) => void;
};

const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  params,
  investment,
  contributions,
  planEligibility,
  institutionalAccount,
  selectSecurity,
  account,
}) => {
  const { formState, setValue, getValues, trigger } = useFormContext();
  const [selected, setSelected] = useState<Api.SecurityDetailsRest | undefined>(undefined);
  const [valueType, setValueType] = useState<Domain.InvestmentValueTypeEnum>(
    Domain.InvestmentValueTypeEnum['Add Balance']
  );
  const [balanceType, setBalanceType] = useState<Domain.InvestmentBalanceTypeEnum>(
    Domain.InvestmentBalanceTypeEnum['Total Balance']
  );
  const [filteredTypes, setFilteredTypes] = useState<ListItem[]>([]);
  const [isIra, setIsIra] = useState<boolean>();

  useEffect(() => {
    if (account?.accountType !== Domain.InvestmentAccountTypeEnum.Retirement) {
      setIsIra(false);
    } else {
      setIsIra(Domain.isIRA(params?.type));
    }
  }, [params?.type, account?.accountType]);

  useEffect(() => {
    if (account?.accountType !== Domain.InvestmentAccountTypeEnum.Retirement) {
      return;
    }

    const types: ListItem[] = [];
    if (planEligibility?.eligiblePlanTaxTypes?.preTax || contributions.preTaxContributionsAllowed) {
      types.push({
        key: Domain.TaxTypeEnum['Pre-tax'],
        value: 'Pre-tax',
      });
    }
    if (contributions.rothContributionsAllowed) {
      types.push({
        key: Domain.TaxTypeEnum.Roth,
        value: 'Roth',
      });
    }
    if (contributions.afterTaxContributionsAllowed) {
      types.push({
        key: Domain.TaxTypeEnum['After-tax'],
        value: 'After-tax',
      });
    }

    if (
      investment?.id &&
      !institutionalAccount &&
      typeof investment.taxType !== 'undefined' &&
      types.filter((x) => x.key === investment.taxType).length === 0
    ) {
      types.push({
        key: investment.taxType,
        value: Domain.TAX_TYPES_TEXT[investment.taxType],
      });
    }

    setFilteredTypes(types);
  }, [investment, institutionalAccount, planEligibility, account?.accountType]);

  useEffect(() => {
    if (investment?.security) {
      setSelected(investment.security);
      if (selectSecurity) {
        selectSecurity(investment.security);
      }
    }
    if (investment?.valueType) {
      setValueType(investment.valueType);
    }
    if (investment?.balanceType) {
      setBalanceType(investment.balanceType);
    }
  }, [investment]);

  useEffect(() => {
    if (selected && !getValues('valueType')) {
      setValue('valueType', Domain.InvestmentValueTypeEnum['Add Balance'].toString());
    }
    if (selected && !getValues('balanceType')) {
      setValue('balanceType', Domain.InvestmentBalanceTypeEnum['Total Balance'].toString());
    }
  }, [selected, formState]);

  useEffect(() => {
    if (filteredTypes.length === 0) return;

    let defaultTaxType = filteredTypes[0].key;
    const preTaxOption = filteredTypes.find((type) => type.key === Domain.TaxTypeEnum['Pre-tax']);
    if (preTaxOption) {
      defaultTaxType = preTaxOption.key;
    }
    const initialTaxType = investment?.taxType;
    const initialTaxTypeIsEligible =
      initialTaxType !== undefined && filteredTypes.some((type) => type.key === initialTaxType);

    setValue('taxType', initialTaxTypeIsEligible ? initialTaxType : defaultTaxType);

    if (
      initialTaxType === Domain.TaxTypeEnum['Not Invested'] &&
      valueType === Domain.InvestmentValueTypeEnum['Add Balance']
    ) {
      setValue('taxType', defaultTaxType);
    }
  }, [investment, filteredTypes, valueType]);

  const onSelectSecurity = (security?: Api.SecurityDetailsRest) => {
    if (security) {
      setValue('ticker', security.ticker, {
        shouldDirty: true,
      });
      setValue('securityId', security.id, {
        shouldDirty: true,
      });
      setValue('securityName', security.securityName, {
        shouldDirty: true,
      });
      trigger('selected');
      setSelected(security);
      if (selectSecurity) {
        selectSecurity(security);
      }
    }
  };

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <SecuritiesSearcher autoFocus onSelect={onSelectSecurity} />
        {selected && (
          <>
            <SelectedInvestmentDetailsCard selected={selected} />
            {account?.accountType !== Domain.InvestmentAccountTypeEnum.BrokerageAccount &&
              !isIra && (
                <ToggleRadioButtonGroup<InvestmentDetailsFormData>
                  error={formState.errors.valueType !== undefined}
                  helperText={formState.errors.valueType?.message?.toString()}
                  name='valueType'
                  label='Value'
                  row
                  onChange={(target) => {
                    setValueType(parseInt(target.value) as Domain.InvestmentValueTypeEnum);
                  }}
                  defaultValue={
                    investment?.valueType?.toString() ??
                    Domain.InvestmentValueTypeEnum['Add Balance'].toString()
                  }
                  items={[
                    {
                      label: 'AddBalance',
                      value: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
                    },
                    {
                      label: 'NotInvested',
                      value: Domain.InvestmentValueTypeEnum['Not Invested'].toString(),
                    },
                  ]}
                />
              )}

            {valueType !== Domain.InvestmentValueTypeEnum['Not Invested'] &&
              // !isIra &&
              filteredTypes.length > 1 && (
                <SelectInput<InvestmentDetailsFormData>
                  error={formState.errors.taxType !== undefined}
                  helperText={formState.errors.taxType?.message?.toString()}
                  defaultValue={
                    getValues('taxType')?.toString() ??
                    investment?.taxType?.toString() ??
                    Domain.TaxTypeEnum['Pre-tax'].toString()
                  }
                  label='TaxType'
                  placeholder='ChooseBalanceType'
                  name='taxType'>
                  {filteredTypes.map((x) => (
                    <MenuItem value={x.key}>{x.value}</MenuItem>
                  ))}
                </SelectInput>
              )}

            {valueType === Domain.InvestmentValueTypeEnum['Add Balance'] && (
              <>
                {typeof selected.price !== 'undefined' && selected.price > 0 && (
                  <ToggleRadioButtonGroup<InvestmentDetailsFormData>
                    error={formState.errors.balanceType !== undefined}
                    helperText={formState.errors.balanceType?.message?.toString()}
                    name='balanceType'
                    label='HowWouldYouLikeToAddYourBalance?'
                    row
                    onChange={(target) =>
                      setBalanceType(parseInt(target.value) as Domain.InvestmentBalanceTypeEnum)
                    }
                    defaultValue={
                      getValues('balanceType')?.toString() ??
                      investment?.balanceType?.toString() ??
                      Domain.InvestmentBalanceTypeEnum['Total Balance'].toString()
                    }
                    items={[
                      {
                        label: '#ofShares',
                        value: Domain.InvestmentBalanceTypeEnum['# of Shares'].toString(),
                      },
                      {
                        label: 'TotalBalance',
                        value: Domain.InvestmentBalanceTypeEnum['Total Balance'].toString(),
                      },
                    ]}
                  />
                )}

                {balanceType === Domain.InvestmentBalanceTypeEnum['Total Balance'] && (
                  <TextInput<InvestmentDetailsFormData>
                    error={formState.errors.balance !== undefined}
                    helperText={formState.errors.balance?.message?.toString()}
                    defaultValue={getValues('balance') ?? investment?.balance}
                    label='InvestmentBalance'
                    name='balance'
                    type='dollar'
                  />
                )}
                {balanceType === Domain.InvestmentBalanceTypeEnum['# of Shares'] && (
                  <TextInput<InvestmentDetailsFormData>
                    error={formState.errors.nrShares !== undefined}
                    helperText={formState.errors.nrShares?.message?.toString()}
                    defaultValue={
                      getValues('nrShares') ??
                      (investment?.balance === Domain.InvestmentBalanceTypeEnum['# of Shares']
                        ? (investment?.balance ?? 0) / (investment?.security?.price ?? 1)
                        : 0)
                    }
                    label='NumberOfShares'
                    name='nrShares'
                    type='integer'
                  />
                )}
                <SelectedInvestmentValueCard
                  investment={investment}
                  selected={selected}
                  balanceType={balanceType}
                />
              </>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

interface SelectedInvestmentDetailsCardProps {
  selected: Api.SecurityDetailsRest;
}

const SelectedInvestmentDetailsCard: React.FC<SelectedInvestmentDetailsCardProps> = ({
  selected,
}) => {
  const { t } = useTranslation();

  return (
    <Card className='security-details-card'>
      <CardContent>
        <Grid>
          <Typography variant='p14Bold' color='primary'>
            {t('InvestmentName')}
          </Typography>
          <Typography variant='p16' color='secondary' mt='5px'>
            {selected.securityName}
          </Typography>
        </Grid>
        <Grid mt='10px'>
          <Typography variant='p14Bold' color='primary'>
            {t('Ticker')}
          </Typography>
          <Typography variant='p16' color='secondary' mt='5px'>
            {selected.ticker}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

interface SelectedInvestmentValueCardProps extends SelectedInvestmentDetailsCardProps {
  balanceType: Domain.InvestmentBalanceTypeEnum;
  investment?: InvestmentDetailsFormData;
}

const SelectedInvestmentValueCard: React.FC<SelectedInvestmentValueCardProps> = ({
  investment,
  selected,
  balanceType,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const [balance, setBalance] = useState<number>(0);
  const [shares, setShares] = useState<number>(0);

  useEffect(() => {
    if (investment) {
      const b = investment.balance ?? 0;
      const s = selected.price ? b / selected.price : 0;
      setShares(parseFloat(s.toFixed(3)));
      if (balanceType === Domain.InvestmentBalanceTypeEnum['# of Shares']) {
        setBalance(b);
      }
    }
  }, [investment]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'nrShares') {
        const nrShares = cleanWholeNumberStr(value[name]);
        const newBalance = nrShares * (selected.price ?? 0);
        setBalance(newBalance);
      } else if (name === 'balance') {
        const balance = cleanWholeNumberStr(value[name], { float: true });
        const newShares = balance / (selected.price ?? 1);
        setShares(parseFloat(newShares.toFixed(3)));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, selected]);

  if (selected.price !== undefined && selected.price > 0)
    return (
      <Card className='security-details-card'>
        <CardContent>
          <Grid>
            <Typography variant='p14Bold' color='primary'>
              {t('SharePrice')}
            </Typography>
            <Typography variant='p16' color='secondary' mt='5px'>
              {formatDecimalDollars(selected.price ?? 0)}
            </Typography>
          </Grid>
          {balanceType === Domain.InvestmentBalanceTypeEnum['# of Shares'] && (
            <Grid mt='10px'>
              <Typography variant='p14Bold' color='primary'>
                {t('TotalBalance')}
              </Typography>
              <Typography variant='p16' color='secondary' mt='5px'>
                {formatDecimalDollars(balance)}
              </Typography>
            </Grid>
          )}
          {balanceType === Domain.InvestmentBalanceTypeEnum['Total Balance'] && (
            <Grid mt='10px'>
              <Typography variant='p14Bold' color='primary'>
                {t('NumberOfShares')}
              </Typography>
              <Typography variant='p16' color='secondary' mt='5px'>
                {shares}
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
};

export default InvestmentDetailsForm;
