import { Api, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import { GoalBannerIcon } from '../../../../goals';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { GoalCardProps } from './Types';
import GoalCardHeader from './components/GoalCardHeader';
import GoalCardSummary from './components/GoalCardSummary';
import GoalCardDetails from './components/GoalCardDetails';
import { convertMonthsToYearMonth } from '../../../../../helpers/utilityFunctions';

dayjs.extend(duration);

const PayOffDebtGoalCard: React.FC<GoalCardProps> = ({ goal }) => {
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;
  const mortgageGoal = advisedGoalPlan as Api.PayOffDebtGoalRest;
  const { years, months } = convertMonthsToYearMonth(mortgageGoal.numberOfMonthsToPayoff ?? 0);

  return (
    <CollapsiblePanel
      variant='shadow'
      header={<GoalCardHeader goal={goal} />}
      expandIconPosition='end'
      icon={<GoalBannerIcon type={goalPlan.goalType} />}>
      <Grid container gap='20px'>
        <GoalCardSummary
          mainContent={
            <>
              {years > 0 && (
                <>
                  <strong>{years}</strong>&nbsp;yr&nbsp;
                </>
              )}
              {(months > 0 || years === 0) && (
                <>
                  <strong>{months}</strong>&nbsp;mo
                </>
              )}
            </>
          }
          subContent='Time Until Pay Off'
        />
        {(goalStatus === Domain.GoalStatusEnum.OnTarget ||
          goalStatus === Domain.GoalStatusEnum.PartiallyFunded) && (
          <GoalCardDetails
            details={[
              {
                key: 'Monthly Payment',
                value: formatWholeDollars(advisedGoalPlan.monthlySavings ?? 0),
              },
              {
                key: 'Total Interest Will Pay',
                value: formatWholeDollars(mortgageGoal.totalInterestPaid ?? 0),
              },
            ]}
          />
        )}
      </Grid>
    </CollapsiblePanel>
  );
};

export default PayOffDebtGoalCard;
