import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { universitySchoolBrown } from '../../assets/png';
import { Colors } from '../../themes';
import { Domain } from '@3nickels/data-modules';
import LmsNextLesson from './LmsNextLesson';

interface LmsSectionProps {
  course?: Domain.CourseDto;
}

const LmsSection: React.FC<LmsSectionProps> = ({ course }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!course) return null;

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        Learn
      </Typography>
      <Grid
        className='3NU-section'
        style={{
          background: `linear-gradient(${Colors.primaryLight} 0 0) bottom left / ${
            course.completionPercentage ?? 0
          }% 8px no-repeat, white`,
        }}>
        <Typography
          className='clickable'
          variant='p12'
          color='primary'
          alignSelf='flex-end'
          onClick={() => navigate(`/learning/${course.courseId}`)}>
          {t('ViewCourse')}
        </Typography>
        <Grid className='content-section' columnGap='10px'>
          <Grid
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifySelf='flex-start'
            width='30%'
            pb={3}>
            <img src={universitySchoolBrown} width='80%' />
          </Grid>

          <LmsNextLesson course={course} />
        </Grid>
        <Typography variant='p12' color='primary' display='inline-block' mt='5px' mb='20px'>
          <span style={{ fontWeight: 'bold' }}>
            {Math.round(course.completionPercentage ?? 0)}% complete
          </span>{' '}
          | {course.lessonsCompleted}/{course.totalLessons} Lesson
          {(course.totalLessons ?? 0) > 1 && 's'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LmsSection;
