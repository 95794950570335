import { Grid, Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';
import { Row } from '../../../../components';

export const ThingsToConsider: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container className='content-card-container' flexDirection='column'>
      <Grid container className='content-card-header'>
        <Row style={{ justifyContent: 'center', width: '15%' }}>
          <Svgs.ArtDonation />
        </Row>
        <Typography variant='p22Bold' color='primary'>
          {t('DevelopGivingPlan')}
        </Typography>
      </Grid>

      <Card className='content-card'>
        <Grid flexDirection='column'>
          <BulletedList
            style={{ marginTop: '6px' }}
            color='secondary'
            children={[
              'WhatDoYouValue',
              'WhatIsYourPassion',
              'WhatAreYourGivingGoals',
              'WhoCanITrust',
            ]}
          />
        </Grid>
      </Card>
    </Grid>
  );
};
