import { Hooks } from '@3nickels/data-modules';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NetWorthSection: React.FC = () => {
  const { t } = useTranslation();
  const netWorth = Hooks.useNetWorth();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('NetWorth')}
      </Typography>
      {typeof netWorth === 'undefined' ? (
        <Skeleton variant='rounded' height='50px' width='160px' />
      ) : (
        <Typography
          fontFamily='Montserrat'
          variant='p40Bold'
          color={(netWorth?.totalNetWorth ?? 0) < 0 ? 'error' : 'secondary'}>
          {netWorth?.totalNetWorth?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }) ?? '$0'}
        </Typography>
      )}
    </Grid>
  );
};

export default NetWorthSection;
