/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import { Svgs } from '../../../../assets/svg';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Colors, Spacing } from '../../../../themes';
import InfoCard from '../../../../components/InfoCard';
import BulletedList from '../../../../components/BulletedList';
import { ChartBar, ChartBarData, ChartXLinesData } from '../../../../components/ChartBar';
import { Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { ChartLegend } from '../../../../components/ChartLegend';

function createChartXLinesData(standardDeduction: number): ChartXLinesData[] {
  return [
    {
      y: standardDeduction ?? 0,
      color: Colors.accentBase,
      label: `Standard Deduction: ${formatWholeDollars(standardDeduction)}/yr`,
      type: 'dashed',
    },
  ];
}

const GivingForTaxBenefitsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const standardDeduction = Hooks.useStandardDeduction();
  const chartData: ChartBarData[] = useMemo(() => {
    const total = (standardDeduction?.single ?? 0) + 1500;
    return [
      {
        x: 'c1',
        y: total,
        color: Colors.secondaryBase,
      },
      {
        x: 'Bunched',
        y: total / 5,
        color: Colors.alternateBase,
      },
      {
        x: 'c2',
        y: total / 5,
        color: Colors.alternateBase,
      },
      {
        x: 'c3',
        y: total / 5,
        color: Colors.alternateBase,
      },
      {
        x: 'Not Bunched',
        y: total / 5,
        color: Colors.alternateBase,
      },
      {
        x: 'c4',
        y: total / 5,
        color: Colors.alternateBase,
      },
    ];
  }, [standardDeduction?.single]);

  const chartXLinesData: ChartXLinesData[] = createChartXLinesData(standardDeduction?.single ?? 0);

  return (
    <Box>
      <Grid container flexWrap='nowrap' justifyContent='space-between' mt='10px'>
        <Grid item width='50%'>
          <Typography color='primary' component='h1' variant='h1'>
            {t('GivingForTaxBenefits')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('ScrollDownToDiscoverBenefits')}
          </Typography>
        </Grid>
        <InfoCard onClick={() => navigate('/tools/gifts/tax-benefits-of-donations')}>
          <Typography color='secondary' variant='p16'>
            {t('RelatedTaxBenefitsOfDonations')}
          </Typography>
        </InfoCard>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Card className='content-card'>
          <Grid display='flex'>
            <Box>
              <Typography color='secondary' variant='p18Bold'>
                {t('BunchingDonations')}
              </Typography>
              <br />
              <Typography color='secondary' variant='p16'>
                {t('WhenDonatingAnAmount', {
                  amount: formatWholeDollars(standardDeduction?.single ?? 0),
                })}
              </Typography>
              <br />
              <Typography color='secondary' variant='p16'>
                {t('LetsSayYouWantToDonate')}
              </Typography>
            </Box>
            {/* replace with graph */}
            <Grid width='500px' ml='50px'>
              <ChartLegend
                items={[
                  { text: 'Bunched', color: Colors.secondaryBase },
                  { text: 'Not Bunched', color: Colors.alternateBase },
                ]}
                orientation='horizontal'
              />
              <ChartBar
                height={200}
                width={200}
                formatY={formatWholeDollars}
                formatX={() => ''}
                data={chartData}
                suppressLabels={true}
                xLines={chartXLinesData}
                yTicksStep={4000}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplayLargeCustomMoneyBag />
          <Typography color='secondary' variant='p18Bold'>
            {t('DonorAdvisedFunds')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <BulletedList
            color='secondary'
            children={[
              t('DonateOnceAndClaim'),
              t('TheFundsAreDistributed'),
              t('YouDontControlFunds'),
            ]}
          />
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplayLargeCustomMoneyExchange />
          <Typography color='secondary' variant='p18Bold'>
            {t('DonorAppreciatedAssets')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <BulletedList
            color='secondary'
            children={[t('YouPayNoCapitalGains'), t('GetTheTaxBenefit')]}
          />
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplayLargeCustomScale />
          <Typography color='secondary' variant='p18Bold'>
            {t('DonateFromYourIra')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <BulletedList
            color='secondary'
            children={[
              t('ForThose701/2'),
              t('IfYouAreSubjectToRmdsTax'),
              t('ItMayReduceYourEstateTax'),
            ]}
          />
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplayLargeCustomTax />
          <Typography color='secondary' variant='p18Bold'>
            {t('InvestInACharitableGiftAnnuity')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <BulletedList
            color='secondary'
            children={[t('YouCanDonateAppreciatedAssets'), t('TheCharityWillPayYou')]}
          />
        </Card>
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(GivingForTaxBenefitsView, meta);
