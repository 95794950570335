import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { Loading } from '../../../../../hooks/useLoading';
import { Spacing } from '../../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import BrokerageAccountBasicsCard from '../basics/BrokerageAccountBasicsCard';
import { InvestmentAccountBasicsEditViewWrapper as BrokerageAccountBasicsEditView } from '../../InvestmentAccountBasicsEditView';
import InvestmentsCard from '../../investments/InvestmentsCard';
import { SummaryView } from '../../../../../components/SummaryHeader';
import { useMessage } from '../../../../../hooks/useMessage';
import { useSummaryOnDoneNavigation } from '../../../link-account/PlaidHooks';

declare type BrokerageAccountSummaryDrawerType =
  keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry;

const BrokerageAccountSummaryViewWrapper: React.FC = () => {
  const { wizard } = Hooks.useInvestmentAccountWizard();
  const { id } = useParams();
  const isStarted = useObservable(wizard.isStarted$) ?? false;

  const investmentAccountId = parseInt(id ?? '');
  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: investmentAccountId, type: Domain.PlanTypeEnum['Brokerage Account'] });
    }
  }, [isStarted]);

  if (!isStarted) {
    return <Loading />;
  }

  return <BrokerageAccountSummaryView wizard={wizard} investmentAccountId={investmentAccountId} />;
};

export interface InvestmentAccountSummaryViewProps {
  wizard: Data.InvestmentAccounts.InvestmentAccountWizard;
  investmentAccountId: number;
}

// We need guards because the hooks are getting tripped up
const BrokerageAccountSummaryView: React.FC<InvestmentAccountSummaryViewProps> = ({
  investmentAccountId,
  wizard,
}) => {
  const location = useLocation();
  const { showMessage } = useMessage();
  const data = Hooks.useInvestmentAccountWizardData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const [open, setOpen] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const navigate = useNavigate();
  const plaidWizard = Hooks.usePlaidWizard();
  const params = useObservable(wizard.params$);
  const accounts = Hooks.useCombinedInvestmentAccountData();
  const onDone = useSummaryOnDoneNavigation();
  const deleteMutation = useMutation(
    new Data.InvestmentAccounts.Mutations.DeleteBrokerageAccount()
  );
  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.CashAccountData,
    [accounts, params]
  );

  useEffect(() => {
    if (data.investments?.length > 0) {
      let balance = 0;
      data.investments.forEach((i) => (balance += i.balance ?? 0));
      setCurrentBalance(balance);
    }
  }, [data]);

  const openDrawer = (key?: BrokerageAccountSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.InvestmentAccount
    );
  }, [plaidWizard, account]);

  const linkInvestmentsHandler = () => {
    linkAccountHandler();
    navigate('/account-details/link-account/choose-institution', {
      state: { summaryUrl: location.pathname },
    });
  };

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('Investment Account Removed!');
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onLinkAccount={linkAccountHandler}
      linkableAccount={account}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      accountSubType={(params && Domain.planTypeToAccountLabel[params.type]) ?? ''}
      onRemoveAccount={removeAccountHandler}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <BrokerageAccountBasicsCard
            accountBasics={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <InvestmentsCard
            investmentAccountId={investmentAccountId}
            onEdit={() => openDrawer('basic')}
            linkAccount={linkInvestmentsHandler}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(
            undefined as unknown as keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry
          );
        }}
      />
      <WizardFooter nextLabel='Done' onDone={onDone} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useInvestmentAccountWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;

  if (key === 'basic') {
    return (
      <BrokerageAccountBasicsEditView
        type={Domain.PlanTypeEnum['Brokerage Account']}
        editing
        onBack={onBack}
      />
    );
  }

  return null;
};

export interface BrokerageAccountSummaryBrokerageAccountdProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(BrokerageAccountSummaryViewWrapper, meta);
