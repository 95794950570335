import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function MenuExclamationSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <g fill={props.fill} fillRule='nonzero'>
          <path d='M21.167 12.5a8.667 8.667 0 0 0-17.334 0m-2.166 0c0-5.983 4.85-10.833 10.833-10.833 5.983 0 10.833 4.85 10.833 10.833' />
          <path d='M3.833 12.5a8.667 8.667 0 0 0 17.334 0m2.166 0c0 5.983-4.85 10.833-10.833 10.833-5.983 0-10.833-4.85-10.833-10.833' />
          <path d='M13.024 18.456h-1.041a.833.833 0 0 1-.834-.833v-6.458c0-.46.373-.834.834-.834h1.041c.46 0 .834.373.834.834v6.458c0 .46-.373.833-.834.833zM11.15 8.085c0-.438.118-.775.354-1.01.236-.237.573-.355 1.01-.355.425 0 .755.118.99.354.237.236.355.573.355 1.01 0 .417-.118.746-.354.985-.236.24-.566.36-.99.36-.438 0-.775-.12-1.01-.36-.237-.24-.355-.568-.355-.984z' />
        </g>
      </g>
    </SvgIcon>
  );
}

MenuExclamationSvg.defaultProps = {
  fill: '#fff',
};
