import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup, {
  RadioInputProps,
} from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';

export type CashGivingGoalDetailsFormProps = {
  model?: Data.Goals.CashGivingGoalDetailsFormData;
  onSubmit: (values: Data.Goals.CashGivingGoalDetailsFormData) => Promise<void>;
};

const CashGivingGoalDetailsForm: React.FC<CashGivingGoalDetailsFormProps> = (props) => {
  const { formState, setValue, getValues } = useFormContext();
  const { model } = props;
  const [frequency, setFrequency] = useState<Domain.GivingPlanFrequencyGivingEnum>();
  const [showPreset, setShowPreset] = useState(false);
  const [dollarOrPercent, setDollarOrPercent] = useState<Domain.GivingPlanDollarPercentEnum>(
    Domain.GivingPlanDollarPercentEnum.Dollar
  );

  const givingLabel = useMemo(
    () =>
      frequency === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? 'Amount I Currently Give Each Month'
        : 'Amount I Currently Give Each Year',
    [frequency]
  );

  const increaseLabel = useMemo(
    () =>
      frequency === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? 'How much would you like your monthly giving to increase each year?'
        : 'How much would you like your giving to increase each year?',
    [frequency]
  );

  const presetOptions: RadioInputProps[] = useMemo(() => {
    const multiplier = frequency === Domain.GivingPlanFrequencyGivingEnum.Monthly ? 1 : 12;
    const numbers = [5, 10, 25, 50];
    return numbers.map((x) => {
      const value = x * multiplier;
      return { label: `$${value}`, value: value.toString() };
    });
  }, [frequency]);

  const setFrequencyHandler = (value: Domain.GivingPlanFrequencyGivingEnum) => {
    setFrequency(value);
    setValue('frequencyGivingSelf', value);
  };

  const setFrequencyGivingHandler = (value: Domain.GivingPlanFrequencyGivingEnum) => {
    const currentPersonalGiving = cleanWholeNumberStr(getValues('currentPersonalGiving') ?? '');
    const givingIncreaseGoal = cleanWholeNumberStr(getValues('givingIncreaseGoal') ?? '');
    const givingIncreaseGoalPercent = cleanWholeNumberStr(
      getValues('givingIncreaseGoalPercent') ?? ''
    );
    setValue(
      'currentPersonalGiving',
      value === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? currentPersonalGiving / 12
        : currentPersonalGiving * 12
    );
    setCurrentPersonalGivingHandler(
      value === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? currentPersonalGiving / 12
        : currentPersonalGiving * 12
    );
    setValue(
      'givingIncreaseGoal',
      value === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? givingIncreaseGoal / 12
        : givingIncreaseGoal * 12
    );
    setValue(
      'givingIncreaseGoalPercent',
      value === Domain.GivingPlanFrequencyGivingEnum.Monthly
        ? givingIncreaseGoalPercent / 12
        : givingIncreaseGoalPercent * 12
    );
  };

  const setCurrentPersonalGivingHandler = (value: number) => {
    setValue('currentPersonalGivingPreset', value.toString());
  };

  const handlePresetChange = (value: number) => {
    setValue('currentPersonalGiving', value);
  };

  const handleBlurCurrentGiving = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (name === 'currentPersonalGiving') {
      const preset = cleanWholeNumberStr(event.target.value).toString();
      setValue('currentPersonalGivingPreset', preset);

      if (presetOptions.find((x) => x.value === preset)) {
        setShowPreset(true);
      } else {
        setShowPreset(false);
      }
    }
  };

  const setDollarOrPercentHandler = (value: Domain.GivingPlanDollarPercentEnum) => {
    setDollarOrPercent(value);
  };

  useEffect(() => {
    setFrequencyHandler(model?.frequencyGivingSelf ?? Domain.GivingPlanFrequencyGivingEnum.Monthly);
  }, [model?.frequencyGivingSelf]);

  useEffect(() => {
    setFrequencyHandler(model?.frequencyGivingSelf ?? Domain.GivingPlanFrequencyGivingEnum.Monthly);
  }, [model?.frequencyGivingSelf]);

  useEffect(() => {
    if (!getValues('givingIncreaseGoal') || getValues('givingIncreaseGoal') === '$0') {
      setDollarOrPercent(Domain.GivingPlanDollarPercentEnum.Percent);
    }
  }, []);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<Data.Goals.CashGivingGoalDetailsFormData>
          error={formState.errors.frequencyGivingSelf !== undefined}
          helperText={formState.errors.frequencyGivingSelf?.message?.toString()}
          name='frequencyGivingSelf'
          row
          onChange={(target) => {
            setFrequencyHandler(target.value as Domain.GivingPlanFrequencyGivingEnum);
            setFrequencyGivingHandler(target.value as Domain.GivingPlanFrequencyGivingEnum);
          }}
          defaultValue={frequency}
          items={[
            { label: 'Monthly', value: Domain.GivingPlanFrequencyGivingEnum.Monthly },
            { label: 'Annual', value: Domain.GivingPlanFrequencyGivingEnum.Annual },
          ]}
        />

        <Grid display={showPreset ? undefined : 'none'}>
          <ToggleRadioButtonGroup
            error={formState.errors.frequencyGivingSelf !== undefined}
            helperText={formState.errors.frequencyGivingSelf?.message?.toString()}
            label={givingLabel}
            name='currentPersonalGivingPreset'
            row
            onChange={(target) => {
              handlePresetChange(parseInt(target.value));
            }}
            defaultValue={model?.currentPersonalGiving?.toString()}
            items={presetOptions}
          />
          <Typography
            className='clickable'
            variant='p12'
            color='primary'
            mt='10px'
            onClick={() => setShowPreset(false)}>
            Enter Custom Amount
          </Typography>
        </Grid>
        <Grid display={!showPreset ? undefined : 'none'}>
          <TextInput<Data.Goals.CashGivingGoalDetailsFormData>
            error={formState.errors.currentPersonalGiving !== undefined}
            helperText={formState.errors.currentPersonalGiving?.message?.toString()}
            defaultValue={props.model?.currentPersonalGiving ?? 0}
            label={givingLabel}
            name='currentPersonalGiving'
            type='dollar'
            autoFocus
            onBlur={handleBlurCurrentGiving}
          />
          <Typography
            className='clickable'
            variant='p12'
            color='primary'
            mt='10px'
            onClick={() => {
              setShowPreset(true);
            }}>
            Choose Preset Amount
          </Typography>
        </Grid>
        <Grid
          display={
            dollarOrPercent === Domain.GivingPlanDollarPercentEnum.Dollar ? undefined : 'none'
          }>
          <TextInput<Data.Goals.CashGivingGoalDetailsFormData>
            error={formState.errors.givingIncreaseGoal !== undefined}
            helperText={formState.errors.givingIncreaseGoal?.message?.toString()}
            defaultValue={props.model?.givingIncreaseGoal ?? 0}
            label={increaseLabel}
            name='givingIncreaseGoal'
            type='dollar'
            autoFocus
          />
          <Typography
            className='clickable'
            variant='p12'
            color='primary'
            mt='10px'
            onClick={() => setDollarOrPercentHandler(Domain.GivingPlanDollarPercentEnum.Percent)}>
            Switch to Percent Amount
          </Typography>
        </Grid>

        <Grid
          display={
            dollarOrPercent === Domain.GivingPlanDollarPercentEnum.Percent ? undefined : 'none'
          }>
          <TextInput<Data.Goals.CashGivingGoalDetailsFormData>
            error={formState.errors.givingIncreaseGoalPercent !== undefined}
            helperText={formState.errors.givingIncreaseGoalPercent?.message?.toString()}
            defaultValue={props.model?.givingIncreaseGoalPercent}
            label={increaseLabel}
            name='givingIncreaseGoalPercent'
            type='percent'
            precision={0}
            autoFocus
          />
          <Typography
            className='clickable'
            variant='p12'
            color='primary'
            mt='10px'
            onClick={() => setDollarOrPercentHandler(Domain.GivingPlanDollarPercentEnum.Dollar)}>
            Switch to Dollar Amount
          </Typography>
        </Grid>
      </Stack>
    </Box>
  );
};

export default CashGivingGoalDetailsForm;
