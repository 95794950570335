import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import CurrentPasswordForm from './CurrentPasswordForm';
import NewPasswordForm from './NewPasswordForm';

const UpdatePasswordView: React.FC = () => {
  const { t } = useTranslation();
  //   const ready = useObservableGuardCondition();
  const ready = true;
  const [statusComplete, setStatusComplete] = useState(false);

  return (
    <>
      {ready && (
        <Box>
          <Grid container flexDirection='column'>
            <Typography variant='p30Bold' component='h1' color='primary'>
              {t('UpdatePassword')}
            </Typography>
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={6}>
                <CurrentPasswordForm
                  statusComplete={statusComplete}
                  setStatusComplete={setStatusComplete}
                />
                {statusComplete && <NewPasswordForm />}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(UpdatePasswordView, meta);
