import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../../../themes';
import { KeyValuePair } from '../../../../components';
import { Data } from '@3nickels/data-modules';
import { formatPercent, formatWholeDollars } from '../../../../helpers/utilityFunctions';

type PersonalLoanCalculatorResultsProps = {
  creditScore: number;
  results: Data.Loans.AdviceLoansPersonalResultData | undefined;
};

const PersonalLoanCalculatorResults: React.FC<PersonalLoanCalculatorResultsProps> = ({
  creditScore,
  results,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction='column'>
      <Grid item mt={3} mb={3}>
        <KeyValuePair label='MyCreditScore' value={creditScore.toString()} />
      </Grid>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography variant='p16Bold' color='secondary'>
            {t(`SampleLoanDetails`)}
          </Typography>
          <KeyValuePair
            label='EstimatedAPR'
            value={results ? formatPercent(results?.estimatedAPR ?? 0) : '– – –'}
          />
          <KeyValuePair
            label='TotalPrincipal'
            value={results ? formatWholeDollars(results?.totalPrincipal ?? 0) : '– – –'}
          />
          <KeyValuePair
            label='TotalInterestPaid'
            value={results ? formatWholeDollars(results?.totalInterestPaid ?? 0) : '– – –'}
          />
          <Divider sx={{ borderColor: Colors.primaryLight }} />
          <KeyValuePair
            label='TotalCostOfLoan'
            value={
              results
                ? formatWholeDollars(
                    (results?.totalPrincipal ?? 0) + (results?.totalInterestPaid ?? 0)
                  )
                : '– – –'
            }
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PersonalLoanCalculatorResults;
