import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PersonSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <g fill={props.fill} fillRule='evenodd'>
        <path d='M0 0h30v30H0z' />
        <g transform='translate(5.333 3.429)' fill={props.htmlColor} fillRule='nonzero'>
          <circle cx='10.193' cy='5.493' r='5.493' />
          <path d='M14.099 12.559H6.303A6.308 6.308 0 0 0 0 18.862v3.906c0 .428.35.777.778.777h18.83a.78.78 0 0 0 .778-.777v-3.906a6.285 6.285 0 0 0-6.287-6.303z' />
        </g>
      </g>
    </SvgIcon>
  );
}

PersonSvg.defaultProps = {
  fill: 'none',
  htmlColor: '#1B7690',
};
