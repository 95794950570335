import { Grid, Typography, Card } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../../components';

export const GivingProbablyMore: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid container className='content-card-container' flexDirection='column'>
      <Grid container className='content-card-header'>
        <Row style={{ justifyContent: 'center', width: '15%' }}>
          <Svgs.ArtGivingHeart2 />
        </Row>
        <Typography variant='p22Bold' color='primary'>
          {t('ProbGivingMoreThanKnow')}
        </Typography>
      </Grid>

      <Card className='content-card'>
        <Grid flexDirection='column'>
          <Typography variant='p16' color='secondary'>
            {t('GivingWayMore')}
          </Typography>
          <Typography mt={3} variant='p16' color='secondary'>
            {t('EveryoneGiver')}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
};
