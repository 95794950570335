/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { map } from 'rxjs';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import HomeOtherCostsForm from './HomeOtherCostsForm';

const homeOtherCostsSchema = Yup.object({
  annualInsurance: Yup.string().notRequired(),
  monthlyHoaFees: Yup.string().notRequired(),
  annualHomeRepairCosts: Yup.string().notRequired(),
  annualPropertyTax: Yup.string().notRequired(),
  annualOtherHousingCosts: Yup.string().notRequired(),
});

export const HomeOtherCostsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.useHomeWizard<Data.RealEstate.HomeOtherCostsFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: Domain.RealEstateTypeEnum.PERSONAL });
    } else if (isStarted) {
      wizard.selectStep('otherCosts');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'otherCosts')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'otherCosts' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <HomeOtherCostsEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface HomeOtherCostsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.RealEstate.HomeOtherCostsFormData,
    Data.RealEstate.HousingEntityWizardParams
  >;
  wizard: Data.RealEstate.HomeWizard;
  params: Data.RealEstate.HousingEntityWizardParams;
}

const HomeOtherCostsEditView: React.FC<HomeOtherCostsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.RealEstate.HomeOtherCostsFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(homeOtherCostsSchema),
  });
  const { setLoading } = useLoading();
  const wizardParams = useObservable(wizard.params$);
  const { setMeta } = useOutletContext<LayoutContext>();

  useEffect(() => {
    if (wizardParams?.bindableAccount) {
      setMeta((previous) => ({ ...previous, hexSteps: { steps: 2, currentStep: 2 } }));
    }
  }, [wizardParams]);

  const formatInputs = (values: any) => {
    const annualInsurance = cleanWholeNumberStr(values.annualInsurance);
    const monthlyHoaFees = cleanWholeNumberStr(values.monthlyHoaFees);
    const annualHomeRepairCosts = cleanWholeNumberStr(values.annualHomeRepairCosts);
    const annualPropertyTax = cleanWholeNumberStr(values.annualPropertyTax);
    const annualOtherHousingCosts = cleanWholeNumberStr(values.annualOtherHousingCosts);

    return {
      annualInsurance,
      monthlyHoaFees,
      annualHomeRepairCosts,
      annualPropertyTax,
      annualOtherHousingCosts,
    };
  };

  const onSubmit = async (values: Data.RealEstate.HomeOtherCostsFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('otherCosts');

      if (editing && onBack) {
        onBack();
        return;
      }

      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/home/summary/${params?.id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('HomeOtherCosts')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <HomeOtherCostsForm homeOtherCosts={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 3,
    currentStep: 3,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(HomeOtherCostsEditViewWrapper, meta);
