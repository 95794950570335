import { Data, Domain, Queries } from '@3nickels/data-modules';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useRef } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';

const WhySaveNowView: React.FC = () => {
  const video = Queries.useObservableQuery(
    Data.Media.Queries.FindVideoByName,
    Domain.VideoTypeEnum.Why_Save_Now
  );
  const streamRef = useRef<StreamPlayerApi>();

  return (
    <Grid container flexDirection='column' rowGap='20px'>
      <Grid
        sx={{
          'width': '100%',
          'height': '500px',
          'maxHeight': '60vh',
          'backgroundColor': 'black',
          'backgroundSize': 'cover',
          'justifyContent': 'center',
          'display': 'flex',
          '& > div, & iframe': {
            width: '100%',
            height: '100%',
          },
        }}>
        {typeof video?.uid !== 'undefined' && (
          <Stream responsive={false} controls streamRef={streamRef} src={video.uid} />
        )}
      </Grid>
      <Grid container flexDirection='column' rowGap='10px'>
        <Typography variant='p30Bold' color='primary'>
          {t('ThePowerOfCompoundInterest')}
        </Typography>
        <Typography variant='p16' color='secondary'>
          {t('CompoundingInterestHasAnEffectThatSurprisesPeople')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(WhySaveNowView, meta);
