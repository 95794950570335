import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Spacing } from '../../../../themes';

const StepFour: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={4}
      headerText='ShoppingAndTestDrive'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('AndThisNextStepInvolvesMoreResearch')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('SearchDifferentToolsForTheBestPrice'),
              t('CheckToSeeWhatWarrantiesAreOffered'),
              t('AskSeveralDealershipsForPrices'),
              t('NegotiateIfAllowed'),
              t('DontLetYourselfBePressured'),
            ]}
          />
          <Typography color='secondary' variant='p16'>
            {t('WhenYouTakeTheCarForATestDrive')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[t('Comfort'), t('Acceleration'), t('Braking'), t('Visibility'), t('Etc')]}
          />
        </Stack>
      }
    />
  );
};

export default StepFour;
