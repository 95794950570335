import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { frontPageFinancialLiteracyLarge } from '../../../assets/png';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import StartView from '../StartView';
import { useAnimatedNavigation } from '../../../hooks/useControlledAnimation';

const BudgetStartView: React.FC = () => {
  const navigate = useAnimatedNavigation();
  const { t } = useTranslation();

  const onComplete = useMemo(() => {
    return () => {
      navigate('monthly');
    };
  }, []);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <StartView
        imageSrc={frontPageFinancialLiteracyLarge}
        className='start-screen-text'
        color='primary'
        variant='p30'
        display='inline'
        onComplete={onComplete}>
        {t('FirstLetsWalkThroughYourMonthly') + ' '}
        <strong>budget</strong>.
      </StartView>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(BudgetStartView, meta);
