import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { Breadcrumbs } from '../../components';
import { useContentView } from '../layout/ContentViewContext';

export type SpendingSummaryLayout = Record<string, unknown>;

export declare type SpendingSummaryLayoutMeta = object;

const breadcrumbNameMap: { [key: string]: string } = {
  '/spending-summary': 'Monthly Spending Overview',
  '/spending-summary/transactions': 'Transactions',
};

const SpendingSummaryLayout: React.FC<SpendingSummaryLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<SpendingSummaryLayoutMeta>();
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const { sideNav } = useContentView();

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('Home')}
      leftChildren={
        <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
      }>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default SpendingSummaryLayout;
