import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import { Svgs } from '../../../../assets/svg';
import ToolHeader from '../../../../components/ToolHeader';
import { useLoading } from '../../../../hooks/useLoading';
import FormContent from '../../../../components/form/FormContent';
import { cleanWholeNumberStr, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';
import HomeSaleProceedsCalculatorForm from './HomeSaleProceedsCalculatorForm';
import HomeSaleProceedsCalculatorResults from './HomeSaleProceedsCalculatorResults';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  homeSalePrice: Yup.string()
    .required('Required')
    .test('homeSalePrice', 'Required', (homeSalePrice) => {
      const cleanNumber = cleanWholeNumberStr(homeSalePrice);
      return cleanNumber > 0;
    }),
  homePurchasePrice: Yup.string()
    .required('Required')
    .test('homePurchasePrice', 'Required', (homePurchasePrice) => {
      const cleanNumber = cleanWholeNumberStr(homePurchasePrice);
      return cleanNumber > 0;
    }),
  eligibleHomeImprovement: Yup.string().required('Required'),
  agentFeesDollar: Yup.string().notRequired(),
  agentFeesPercentage: Yup.string().notRequired(),
  closingCostDollar: Yup.string().notRequired(),
  closingCostPercentage: Yup.string().notRequired(),
  takenHomeSalesTaxExclusion: Yup.boolean().required('Required'),
  livedAtLeastTwoYears: Yup.boolean().when('takenHomeSalesTaxExclusion', {
    is: (takenHomeSalesTaxExclusion: boolean) => !takenHomeSalesTaxExclusion,
    then: (schema) => schema.required('Required'),
  }),
});

const cleanValues = (values: Api.AdviceHouseSaleNetRest) => {
  return {
    homeSalePrice: Number((values?.homeSalePrice?.toString() ?? '').replace(/[$,]/g, '')),
    homePurchasePrice: Number((values?.homePurchasePrice?.toString() ?? '').replace(/[$,]/g, '')),
    eligibleHomeImprovement: Number(
      (values?.eligibleHomeImprovement?.toString() ?? '').replace(/[$,]/g, '')
    ),
    agentFeesDollar: Number((values?.agentFeesDollar?.toString() ?? '').replace(/[$,]/g, '')),
    agentFeesPercentage: Number(values?.agentFeesPercentage),
    closingCostDollar: Number((values?.closingCostDollar?.toString() ?? '').replace(/[$,]/g, '')),
    closingCostPercentage: Number(values?.closingCostPercentage),
    takenHomeSalesTaxExclusion: values?.takenHomeSalesTaxExclusion,
    livedAtLeastTwoYears: values?.livedAtLeastTwoYears,
    calculateEstimatedTaxImpact: true,
  };
};

const HomeSaleProceedsCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Api.HouseSaleNetResultRest | undefined>(undefined);
  const [pdfParams, setPdfParams] = useState<Api.AdviceHouseSaleNetRest | undefined>(undefined);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Api.AdviceHouseSaleNetRest>(
    SavedFormsKeyEnum.HomeSaleNetProceeds
  );

  const defaultValues: Api.AdviceHouseSaleNetRest = useMemo(() => {
    return (
      formData ?? {
        homeSalePrice: 0,
        homePurchasePrice: 0,
        eligibleHomeImprovement: 0,
        agentFeesPercentage: 6,
        agentFeesDollar: undefined,
        closingCostPercentage: 3,
        closingCostDollar: undefined,
        takenHomeSalesTaxExclusion: undefined,
        livedAtLeastTwoYears: undefined,
      }
    );
  }, [formData]);

  const methods = useForm<Api.AdviceHouseSaleNetRest>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: Api.AdviceHouseSaleNetRest) => {
    setLoading(true);
    try {
      const cleanedValues = cleanValues(values);
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.HomeSaleNetProceeds,
        formData: cleanedValues,
      });
      const results = await commands.execute(
        Data.Housing.Commands.CalculateAdviceSaleNet,
        cleanedValues
      );

      setResults(results);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!pdfParams) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Housing.Commands.CalculateAdviceSaleNetPdf,
        pdfParams
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HomeSaleNetProceedsCalculatorResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const header = (
    <ToolHeader
      icon={<Svgs.ArtHouseLarge />}
      title='HeresHomeSaleSummary'
      onDownload={onDownload}
    />
  );

  if (typeof formData === 'undefined') return null;
  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={results ? header : undefined}
            inputColumn={
              <HomeSaleProceedsCalculatorForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                defaultValues={defaultValues}
              />
            }
            outputColumn={<HomeSaleProceedsCalculatorResults results={results} />}
            boxItems={[
              {
                boxTitle: 'HomeSaleNetProceeds',
                boxResult: results ? formatWholeDollars(results?.netProceeds ?? 0) : undefined,
              },
            ]}
          />
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HomeSaleProceedsCalculatorView, meta);
