import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LinkSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <g fill='#8CBAC7' fillRule='nonzero'>
            <path d='m15.737 18.79-3.052 3.052a3.24 3.24 0 0 1-4.579 0 3.216 3.216 0 0 1-.948-2.29 3.217 3.217 0 0 1 .948-2.289l3.053-3.052a1.08 1.08 0 0 0-1.527-1.526L6.58 15.737A5.36 5.36 0 0 0 5 19.553a5.36 5.36 0 0 0 1.58 3.816 5.38 5.38 0 0 0 3.816 1.578 5.379 5.379 0 0 0 3.815-1.578l3.052-3.053a1.08 1.08 0 0 0-1.526-1.526zM24.948 10.396a5.36 5.36 0 0 0-1.58-3.816 5.402 5.402 0 0 0-7.63 0h-.001l-3.052 3.053a1.08 1.08 0 1 0 1.526 1.526l3.052-3.052a3.241 3.241 0 0 1 4.579 0c.611.611.948 1.424.948 2.289 0 .864-.337 1.677-.948 2.289l-3.053 3.052a1.08 1.08 0 0 0 1.527 1.526l3.052-3.052a5.36 5.36 0 0 0 1.58-3.815z' />
            <path d='M11.159 18.79a1.076 1.076 0 0 0 1.526 0l6.104-6.105a1.08 1.08 0 1 0-1.526-1.526l-6.104 6.104a1.08 1.08 0 0 0 0 1.526z' />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
