import React from 'react';
import { Card, Grid, IconButton, Link, Typography } from '@mui/material';
import { Api } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { convertToTitleCase, formatPhoneNumber } from '../../../helpers/utilityFunctions';

interface AuthMethodCardProps {
  variant: string;
  method: Api.AuthTwoFactorData;
  totalMethods?: number;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete?: (method: Api.AuthTwoFactorData) => void;
}

const AuthMethodCard: React.FC<AuthMethodCardProps> = ({
  variant,
  method,
  totalMethods,
  setModalOpen,
  onDelete,
}) => {
  const email = method.twoFactorType?.toLowerCase() === Api.TwoFactorTypeEnum.Email;

  const handleClose = (event: unknown) => {
    (event as React.MouseEvent<HTMLElement>).stopPropagation();
  };

  return (
    <Grid item mt={1}>
      <Card className='card-row' sx={{ padding: '10px' }}>
        <Grid container>
          <Grid item xs={1} alignSelf='center' paddingLeft={1}>
            {email ? <Svgs.DisplaySmallCustomEnvelope /> : <Svgs.DisplaySmallCustomPhone2 />}
          </Grid>
          <Grid item xs={6} alignSelf='center'>
            <Typography color='secondary' variant='p16Bold'>
              {email ? method.info : formatPhoneNumber(method.info ?? '')}
            </Typography>
          </Grid>
          <Grid item xs={3} alignSelf='center'>
            <Typography color='secondary' variant='p16'>
              {convertToTitleCase(method?.twoFactorType ?? '')}
            </Typography>
          </Grid>
          <Grid item xs={2} alignSelf='center' paddingRight={1} style={{ textAlign: 'end' }}>
            {variant === 'saved' && onDelete ? (
              <IconButton
                className='show-on-hover'
                sx={{ opacity: 0 }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  onDelete(method);
                  handleClose(event);
                }}
                disableRipple>
                <Svgs.IpmIconDelete />
              </IconButton>
            ) : (totalMethods ?? 0) > 1 && setModalOpen ? (
              <Link variant='p12' onClick={() => setModalOpen(true)}>
                Change default
              </Link>
            ) : undefined}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default AuthMethodCard;
