import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';
import { Spacing } from '../../../../themes';
import ChecklistTemplate from '../../ChecklistTemplate';

export const StepThree: React.FC = () => {
  return (
    <ChecklistTemplate
      stepNumber={3}
      headerIcon={<Svgs.DisplayLargeCustomClock />}
      headerText={'FigureOutTiming'}
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('ChooseBestTime')}
          </Typography>

          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[t('SpringBestSell'), t('SellBeforeBuying'), t('WholeProcess')]}
          />
        </Stack>
      }
    />
  );
};
