import { Box } from '@mui/material';
import React from 'react';
import { beach } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import IraFeeCheckerCard from './cards/IraFeeCheckerCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import WhySaveNowCard from './cards/WhySaveNowCard';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const RetirementToolStartView: React.FC = () => {
  const explore = useExploreGroups();

  const calculators: Group[] = [
    {
      textKey: 'ira-fee-checker',
      url: 'ira-fee-checker',
      content: <IraFeeCheckerCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'why-save-now',
      url: 'why-save-now',
      content: <WhySaveNowCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplayLargeCustomRetirement />}
        label='Retirement'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={beach} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(RetirementToolStartView, meta);
