/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Card, FormGroup, Button } from '@mui/material';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { mobileMargin } from '../App';
import { logo3NSmall } from '../assets/png';
import { Column, Row, Spacer } from '../components';
import { useDeviceType } from '../hooks/useDeviceType';
import { HelpText } from './CreateAccount';
import { useMutation } from '@aesop-fables/scrinium';
import { Data, Domain, formatDateForApi, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { API_URL, API_BASE_URL } from '../configuration/Environment';
import TextInput from '../components/form/TextInput';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormContent from '../components/form/FormContent';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { formatMalformedDate, isValidDate } from '../helpers/utilityFunctions';

const schema = (person: Domain.SelfData) =>
  Yup.object({
    firstName: !(person?.firstName && person.lastName)
      ? Yup.string().required('Required')
      : Yup.string().notRequired(),
    lastName: !(person?.firstName && person.lastName)
      ? Yup.string().required('Required')
      : Yup.string().notRequired(),
    birthDate: !person.birthDate
      ? Yup.string()
          .required('Required')
          .test('validDate', 'Must be a valid date', (birthdate) => {
            return isValidDate(birthdate);
          })
          .test('birthdate', 'Must be 12 years old or older', (birthdate) => {
            const date = formatMalformedDate(birthdate);
            return dayjs().subtract(12, 'y').isSameOrAfter(dayjs(date), 'day');
          })
          .test('birthdate', 'Must be 109 years old or younger', (birthdate) => {
            const date = formatMalformedDate(birthdate);
            return dayjs().subtract(109, 'y').isSameOrBefore(dayjs(date), 'day');
          })
      : Yup.string().notRequired(),
  });

const PersonalInfo = () => {
  const navigate = useNavigate();
  const organizationData = Hooks.useOrganizationData();
  const person = Hooks.usePersonData();
  const updatePersonData = useMutation(new Data.People.Mutations.UpdatePersonData());
  const { isMobile, isTablet } = useDeviceType();

  const methods = useForm<Domain.SelfData>({
    defaultValues: {
      firstName: person?.firstName,
      lastName: person?.lastName,
      birthDate: person?.birthDate,
    },
    resolver: yupResolver(schema(person ?? {})),
  });

  const formatInputs = (values: any) => {
    const birthDate =
      typeof values.birthDate !== 'undefined' && values.birthDate !== null
        ? formatDateForApi(values.birthDate)
        : values.birthDate;
    return { birthDate };
  };

  const onSubmit = async (values: Domain.SelfData) => {
    const cleanedValues = formatInputs(values);
    await updatePersonData.action({ ...values, ...cleanedValues });

    navigate('/');
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Column style={{ margin: isTablet || isMobile ? mobileMargin : '15px 100px' }}>
        <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
          {organizationData ? (
            organizationData.logoUri ? (
              <img
                src={`${API_URL}organization/logo`}
                alt={organizationData?.name}
                style={{ height: '60%' }}
              />
            ) : (
              <Typography variant='p25Bold' color='secondary'>
                {organizationData?.name}
              </Typography>
            )
          ) : (
            <Card variant='ghost' />
          )}
          <Link style={{ height: '70%' }} to='/'>
            <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
          </Link>
        </Row>
        <Typography variant='p30Bold' color='primary'>
          Personal Info
        </Typography>
        <Spacer height='xs' />
        <Typography variant='h2' color='secondary'>
          Just need a little more info to create your 3Nickels account!
        </Typography>
        <Spacer height='xs' />
        {typeof person !== 'undefined' ? (
          <FormContent formProviderProps={methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Column>
                <FormGroup
                  style={{
                    alignSelf: 'center',
                    width: isMobile || isTablet ? '90%' : '70%',
                    maxWidth: isMobile ? '' : '650px',
                  }}>
                  {!(person?.firstName && person.lastName) &&
                    (isMobile || isTablet ? (
                      <>
                        <TextInput<Domain.SelfData>
                          error={methods.formState.errors.firstName !== undefined}
                          helperText={methods.formState.errors.firstName?.message?.toString()}
                          inputProps={{ maxLength: 32, width: '100%' }}
                          defaultValue={person?.firstName}
                          placeholder='First Name'
                          label='FirstName'
                          name='firstName'
                          autoFocus
                        />
                        <Spacer height='sm' />
                        <TextInput<Domain.SelfData>
                          error={methods.formState.errors.lastName !== undefined}
                          helperText={methods.formState.errors.lastName?.message?.toString()}
                          inputProps={{ maxLength: 32, width: '100%' }}
                          defaultValue={person?.lastName}
                          placeholder='Last Name'
                          label='LastName'
                          name='lastName'
                        />
                        <Spacer height='sm' />
                      </>
                    ) : (
                      <>
                        <Row style={{ width: '100%' }}>
                          <Column style={{ width: '100%' }}>
                            <TextInput<Domain.SelfData>
                              error={methods.formState.errors.firstName !== undefined}
                              helperText={methods.formState.errors.firstName?.message?.toString()}
                              inputProps={{ maxLength: 32, width: '100%' }}
                              defaultValue={person?.firstName}
                              placeholder='First Name'
                              label='FirstName'
                              name='firstName'
                              autoFocus
                            />
                          </Column>
                          <Spacer height='sm' />
                          <Column style={{ marginLeft: '15px', width: '100%' }}>
                            <TextInput<Domain.SelfData>
                              error={methods.formState.errors.lastName !== undefined}
                              helperText={methods.formState.errors.lastName?.message?.toString()}
                              inputProps={{ maxLength: 32, width: '100%' }}
                              defaultValue={person?.lastName}
                              placeholder='Last Name'
                              label='LastName'
                              name='lastName'
                            />
                          </Column>
                        </Row>
                        <Spacer height='sm' />
                      </>
                    ))}
                  {!person?.birthDate && (
                    <TextInput<Domain.SelfData>
                      error={methods.formState.errors.birthDate !== undefined}
                      helperText={methods.formState.errors.birthDate?.message?.toString()}
                      helpContext={<HelpText />}
                      label='DateOfBirth'
                      name='birthDate'
                      type='dateInput'
                    />
                  )}
                </FormGroup>
              </Column>
              <Spacer height='sm' />
              {isMobile || isTablet ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  <Button type='submit' color='secondary'>
                    Save & Continue
                  </Button>
                  <Spacer height='xs' />
                  <form method='post' action={`${API_BASE_URL}/logout`}>
                    <Button
                      type='submit'
                      variant='outlined'
                      color='secondary'
                      style={{ width: '100%' }}>
                      Cancel &amp; Log Out
                    </Button>
                    <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                  </form>
                </Column>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <form method='post' action={`${API_BASE_URL}/logout`}>
                    <Button type='submit' variant='outlined' color='secondary'>
                      Cancel &amp; Log Out
                    </Button>
                    <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                  </form>
                  <Button type='submit' color='secondary'>
                    Save & Continue
                  </Button>
                </Row>
              )}
            </form>
          </FormContent>
        ) : (
          <form method='post' action={`${API_BASE_URL}/logout`}>
            <Button type='submit' variant='outlined' color='secondary'>
              Cancel &amp; Log Out
            </Button>
            <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
          </form>
        )}
      </Column>
    </PageContentWithBackground>
  );
};

export default PersonalInfo;
