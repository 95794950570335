import { Grid, Typography } from '@mui/material';
import { universitySchoolBrown } from '../../../../assets/png';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const UniversityCompleteCard: React.FC = () => {
  return (
    <Grid container p={Spacing.xxxs + 1} display='flex' justifyContent='space-between'>
      <Grid
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='40%'
        rowGap='10px'>
        <Svgs.NickelsULogoSecondary width='100%' />
        <img src={universitySchoolBrown} width='80%' />
      </Grid>
      <Grid
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        width='40%'>
        <Typography variant='p20Bold' color='secondary'>
          100% Complete
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UniversityCompleteCard;
