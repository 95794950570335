import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { SpendingSummaryLayoutMeta } from './SpendingSummaryLayout';
import SpendingSummaryTimeframeSelector from './SpendingSummaryTimeframeSelector';
import SpendingSummaryTransactionsCategoriesSelector from './SpendingSummaryTransactionsCategoriesSelector';
import SpendingSummaryTransactionsAccountsSelector from './SpendingSummaryTransactionsAccountsSelector';
import { Data, Hooks } from '@3nickels/data-modules';
import SpendingSummaryTransactionsTable from './SpendingSummaryTransactionsTable';
import FormContent from '../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import SpendingSummaryTransactionsSearch from './SpendingSummaryTransactionsSearch';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

const SpendingSummaryTransactionsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setMeta } = useOutletContext<LayoutContext>();
  const { pathname } = useLocation();

  const { transactions, loadingTransactions, loadingMoreTransactions, transactionsSearchString } =
    Hooks.useTransactions();
  const methods = useForm<{ searchString: string }>({
    defaultValues: { searchString: transactionsSearchString ?? '' },
  });
  const inBudgetDetails = useMemo(() => pathname.includes('/budget-details/'), [pathname]);

  const disableSelectors = useMemo(() => {
    return loadingTransactions || loadingMoreTransactions;
  }, [loadingTransactions, loadingMoreTransactions]);

  useEffect(() => {
    setMeta(() => ({
      onBack: (navigate) => (inBudgetDetails ? navigate(-1) : navigate('/spending-summary')),
    }));
  }, [navigate, pathname]);

  return (
    <Grid>
      <Typography variant='p30Bold' color='primary'>
        {t('Transactions')}
      </Typography>
      <FormContent formProviderProps={methods}>
        <Grid
          className='transactions-selectors'
          container
          display='flex'
          justifyContent={'space-between'}>
          <SpendingSummaryTransactionsSearch />
          <SpendingSummaryTransactionsCategoriesSelector disabled={disableSelectors} />
          <SpendingSummaryTimeframeSelector
            variant='panel'
            showStartAdornment
            hideArrow
            cutAverages
            disabled={disableSelectors}
          />
          <SpendingSummaryTransactionsAccountsSelector disabled={disableSelectors} />
        </Grid>
      </FormContent>
      <SpendingSummaryTransactionsTable transactions={transactions} />
    </Grid>
  );
};

const SpendingSummaryTransactionsViewWithTransactionsContext: React.FC = () => {
  return (
    <Data.Transactions.TransactionsProvider>
      <SpendingSummaryTransactionsView />
    </Data.Transactions.TransactionsProvider>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<SpendingSummaryLayoutMeta>;

export default withLayoutMeta(SpendingSummaryTransactionsViewWithTransactionsContext, meta);
