import { Data, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ChurchResults from './ChurchResults';
import ChurchSearcher from './ChurchSearcher';

const GiveToYourChurchView: React.FC = () => {
  const { searchString, churches, setIncrementSize } = Hooks.useTithely();

  useEffect(() => {
    setIncrementSize(20);
  }, []);

  const methods = useForm<{ searchString: string }>({
    defaultValues: { searchString: searchString ?? '' },
  });

  return (
    <Box>
      <Grid container className='search-view'>
        <Grid item width='50%'>
          <Stack spacing={2}>
            <Svgs.LogoTithely height='40px' width='auto' style={{ marginTop: '50px' }} />
            <Typography
              variant='p16'
              color='secondary'
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {t('LookingToGiveToASpecificChurch')}
            </Typography>
            <FormContent formProviderProps={methods}>
              <ChurchSearcher />
            </FormContent>
            <ChurchResults churches={churches} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const GiveToYourChurchViewWithTithelyContext: React.FC = () => {
  return (
    <Data.Tithely.TithelyProvider>
      <GiveToYourChurchView />
    </Data.Tithely.TithelyProvider>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(GiveToYourChurchViewWithTithelyContext, meta);
