import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Margins, Spacing } from '../../../../themes';
import { Row } from '../../../../components';

interface CscChartLegendProps {
  colors: string[];
  labels: string[];
}

export const CscChartLegend: React.FC<CscChartLegendProps> = ({ colors, labels }) => {
  return (
    <Grid style={Margins.mt_md}>
      <Row style={{ marginLeft: Spacing.xxs, marginRight: Spacing.xxs }}>
        {labels.map((label, i) => (
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid
              item
              sx={{
                height: 15,
                width: 15,
                backgroundColor: colors[i],
                borderRadius: '50%',
              }}
              key={i.toString()}
            />
            <Typography variant='p12' color='secondary' ml={1} style={{ textAlign: 'center' }}>
              {label}
            </Typography>
          </Grid>
        ))}
      </Row>
    </Grid>
  );
};
