import { Data } from '@3nickels/data-modules';
import { useObservableQuery } from '@aesop-fables/scrinium';

export function useNextUnitByCourseId(courseId: number) {
  // Uses next incomplete unit
  return useObservableQuery(Data.Lms.Queries.FindNextUnitByCourseId, courseId);
}

export function useNextLessonByCourseId(courseId: number) {
  // Uses next incomplete lesson
  return useObservableQuery(Data.Lms.Queries.FindNextLessonByCourseId, courseId);
}
