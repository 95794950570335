import { Domain } from '@3nickels/data-modules';
import { Card, CardHeader, Grid, Skeleton, Typography } from '@mui/material';
import { Colors } from '../../../../themes';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import SavingGoalCard from './cards/SavingGoalCard';
import VolunteeringGoalCard from './cards/VolunteeringGoalCard';
import CashGivingGoalCard from './cards/CashGivingGoalCard';
import MortgageGoalCard from './cards/MortgagePayOffCard';
import PayOffDebtGoalCard from './cards/DebtPayOffCard';
import RetirementGoalCard from './cards/RetirementGoalCard';

dayjs.extend(duration);

interface GoalsSectionProps {
  goals: Domain.GoalSummary[];
  ssi: number;
}

const GoalsSection: React.FC<GoalsSectionProps> = ({ goals, ssi }) => {
  const { t } = useTranslation();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Goals')}
      </Typography>
      {goals.length === 0 ? (
        <>
          <SkeletonGoal />
          <SkeletonGoal />
          <SkeletonGoal />
        </>
      ) : (
        goals.map((goal) => {
          switch (goal.advisedGoalPlan.goalType) {
            case Domain.GoalTypeEnum.Cash_Giving:
              return <CashGivingGoalCard key={goal.goalPlan.id} goal={goal} />;
            case Domain.GoalTypeEnum.Mortgage:
              return <MortgageGoalCard key={goal.goalPlan.id} goal={goal} />;
            case Domain.GoalTypeEnum.Non_Mortgage_Debt:
              return <PayOffDebtGoalCard key={goal.goalPlan.id} goal={goal} />;
            case Domain.GoalTypeEnum.Retirement:
              return <RetirementGoalCard key={goal.goalPlan.id} goal={goal} ssi={ssi} />;
            case Domain.GoalTypeEnum.Volunteer:
              return <VolunteeringGoalCard key={goal.goalPlan.id} goal={goal} />;
            default:
              return <SavingGoalCard key={goal.goalPlan.id} goal={goal} />;
          }
        })
      )}
    </Grid>
  );
};

const SkeletonGoal: React.FC = () => {
  return (
    <Card className='collapsible-panel' variant='shadow'>
      <CardHeader
        className='collapsible-panel-header'
        sx={{ cursor: 'auto !important' }}
        color='secondary'
        disableTypography={true}
        title={
          <Grid container className='collapsible-panel-title'>
            <Grid item justifySelf='center' alignSelf='center'>
              <Typography component='h2' variant='p18Bold' color='secondary'>
                <Grid item display='flex' flexDirection='column' gap='5px' width='100%'>
                  <Grid container>
                    <Skeleton variant='rounded' width='200px' height='19px' />
                  </Grid>
                  <Grid container gap='10px'>
                    <Skeleton variant='rounded' width='50px' height='14px' />
                    <Typography variant='p12' color={Colors.disabledBase} fontWeight='normal'>
                      |
                    </Typography>
                    <Skeleton variant='rounded' width='50px' height='14px' />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        }
        avatar={<Skeleton variant='circular' width='30px' height='30px' />}
      />
    </Card>
  );
};

export default GoalsSection;
