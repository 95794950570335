import React, { FC, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { type Address } from '../types/PersonalDetails';

type Props = Omit<
  TextFieldProps,
  'label' | 'inputProps' | 'name' | 'InputProps' | 'InputLabelProps'
>;

type SelectedFunction = (address: Address) => void;
export type AddressSearcherProps = {
  name: string;
  onSelected: SelectedFunction;
  googleAddressKey: string;
} & Props;
interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
const AddressSearcher: FC<AddressSearcherProps> = (props) => {
  const { googleAddressKey, onSelected, ...rest } = props;
  const [textValue, setTextValue] = useState('');
  const [cityFound, setCityFound] = useState<boolean>(false);
  const { t } = useTranslation();

  const resultsContainer = document.querySelectorAll<HTMLElement>('.pac-container');
  resultsContainer.forEach((result) => {
    result.style.zIndex = `${99999} !important`;
  });

  const parseAddress = (address: GeocoderAddressComponent[] | undefined) => {
    if (!address) return;

    let streetNumber;
    let streetName;
    let city;
    let state;
    let zip;

    address.forEach((element) => {
      switch (element.types[0]) {
        case 'street_number':
          streetNumber = element.short_name;
          break;
        case 'route':
          streetName = element.short_name;
          break;
        case 'locality':
          city = element.short_name;
          setCityFound(true);
          break;
        case 'neighborhood':
          if (!cityFound) {
            city = element.short_name;
            setCityFound(true);
          }
          break;
        case 'sublocality_level_1':
          if (!cityFound) {
            city = element.short_name;
            setCityFound(true);
          }
          break;
        case 'administrative_area_level_1':
          state = element.short_name;
          break;
        case 'postal_code':
          zip = element.short_name;
          break;
      }
    });
    if (!streetNumber) {
      return;
    }

    const response: Address = {
      addressLine1: `${streetNumber} ${streetName}`,
      city: city,
      state: state,
      zipCode: zip,
    };

    onSelected(response);
    setTextValue('');
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: googleAddressKey,
    onPlaceSelected: (place) => {
      if (!place) return;
      parseAddress(place.address_components);
    },
    options: {
      componentRestrictions: { country: 'us' },
      fields: ['name', 'address_component'],
      types: ['address'],
    },
  });

  return (
    <TextField
      {...(rest as TextFieldProps)}
      inputRef={materialRef}
      size='small'
      id='google-autocomplete-input'
      placeholder={t('EnterAddress') as string}
      color='secondary'
      fullWidth
      value={textValue}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTextValue(e.target.value);
      }}
      inputProps={{
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        },
      }}
      InputProps={{
        role: 'input',
        startAdornment: (
          <InputAdornment variant='standard' position='start'>
            <Search />
          </InputAdornment>
        ),
        endAdornment: textValue && (
          <InputAdornment variant='standard' position='end'>
            <IconButton
              size='small'
              disableRipple
              onClick={() => {
                setTextValue('');
              }}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AddressSearcher;
