import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Spacer } from '../../../../components';
import { Domain } from '@3nickels/data-modules';
import { Svgs } from '../../../../assets/svg';

type TaxBenefitsCalculatorResultsProps = {
  results: Domain.TaxBenefitsData | undefined;
  formData: Domain.GtbdFormData | undefined;
};

const TaxBenefitsCalculatorResults: React.FC<TaxBenefitsCalculatorResultsProps> = ({
  results,
  formData,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction='column'>
      <Grid item>
        {results && (
          <Grid mt={4} container direction='column'>
            <Spacer height='xxxs' />
            <Svgs.ArtPiggyBank2 style={{ alignSelf: 'center' }} />
            <Typography mt={4} variant='p16' color='secondary' sx={{ textAlign: 'center' }}>
              {formData?.itemizeDeductions ? t('ItemizeIsBest') : t('TakeStandardDeduction')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TaxBenefitsCalculatorResults;
