import { Domain } from '@3nickels/data-modules';
import { Svgs } from '../assets/svg';

interface BreakdownCategoryIconProps {
  type: string;
}

const BreakdownCategoryIcon: React.FC<BreakdownCategoryIconProps> = ({ type }) => {
  if (type === Domain.BreakdownCategoryEnum.Bonds.toString()) {
    return <Svgs.DisplaySmallCustomBank />;
  }

  if (type === Domain.BreakdownCategoryEnum.Cash.toString()) {
    return <Svgs.DisplaySmallCustomPiggyBank />;
  }

  if (type === Domain.BreakdownCategoryEnum.Stocks.toString()) {
    return <Svgs.DisplaySmallCustomInvestment />;
  }

  return <Svgs.DisplaySmallCustomBudget />;
};

export default BreakdownCategoryIcon;
