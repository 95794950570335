import { Grid, Typography } from '@mui/material';
import React from 'react';
import { TypewriterProps } from '../../components/Typewriter';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import GroupPanels, { Group } from '../../components/GroupPanels';

export interface ToolsStartViewProps extends TypewriterProps {
  imageSrc?: string;
  icon: React.ReactNode;
  label: string;
  calculators?: Group[];
  resources?: Group[];
  explore?: Group[];
}

const ToolsStartView: React.FC<ToolsStartViewProps> = ({
  label,
  icon,
  calculators = [],
  resources = [],
  explore = [],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container className='tool-start-view' display='flex' flexDirection='column' rowGap='30px'>
      <Grid display='flex' justifyContent='space-between' alignItems='center'>
        <Grid display='flex' columnGap='20px' justifyContent='flex-start'>
          {icon}
          <Typography variant='p50Bold' color='secondary'>
            {t(label)}
          </Typography>
        </Grid>
        <Button
          style={{ height: 'fit-content' }}
          variant='ghost'
          color='secondary'
          onClick={() => navigate('/tools')}>
          {'<'} {t('BackToAllTools')}
        </Button>
      </Grid>
      <ToolSection label='Calculators' tools={calculators} />
      <ToolSection label='Resources' tools={resources} />
      <ToolSection label='Explore' tools={explore} />
    </Grid>
  );
};

interface ToolSectionProps {
  label: string;
  tools: Group[];
}

const ToolSection: React.FC<ToolSectionProps> = ({ label, tools }) => {
  const { t } = useTranslation();

  if (tools.length === 0) return null;

  return (
    <Grid>
      <Typography variant='p18Bold' color='primary'>
        {t(label)}
      </Typography>
      <GroupPanels variant='Popover' groups={tools} groupsPerRow={3} />
    </Grid>
  );
};

export default ToolsStartView;
