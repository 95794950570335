import { RouteObject } from 'react-router';
import { YourLessonsViewWrapper } from '../pages/tools/lessons/YourLessonsView';
import { LmsContentViewWrapper } from '../pages/learning-management-system/lessons/contents/LmsContentView';
import LmsQuizViewWrapper from '../pages/learning-management-system/quiz/LmsQuizViewWrapper';
import LmsQuizResultsView from '../pages/learning-management-system/quiz-results/LmsQuizResultsView';

export const yourLessonsRoutes: RouteObject[] = [
  {
    path: 'your-lessons',
    children: [
      { index: true, element: <YourLessonsViewWrapper /> },
      {
        path: ':courseId/:unitId/:lessonId',
        children: [
          { path: 'content', element: <LmsContentViewWrapper /> },
          {
            path: ':quizId',
            children: [
              { path: ':questionId', element: <LmsQuizViewWrapper /> },
              { path: 'results', element: <LmsQuizResultsView /> },
            ],
          },
        ],
      },
    ],
  },
];
