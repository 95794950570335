import { Divider, Link, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type MenuLink = {
  key: string;
  isDivider?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export interface DropdownMenuProps {
  menuLinks: MenuLink[];
  element?: HTMLElement;
  onClose: (event?: unknown) => void;
}

export interface PopoverDropdownMenuProps extends DropdownMenuProps {
  horizontalPosition?: 'center' | 'left' | 'right' | number;
}

export const PopoverDropdown: React.FC<PopoverDropdownMenuProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Popover
      onClick={props.onClose}
      slotProps={{
        paper: {
          elevation: 4,
          sx: {
            paddingY: 1,
            borderRadius: '10px',
            minWidth: 250,
            ul: {
              listStyle: 'none',
              li: {
                'paddingY': '5px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  cursor: 'pointer',
                },
                'a': {
                  textDecoration: 'none',
                  display: 'block',
                },
              },
              a: { textDecoration: 'none' },
            },
          },
        },
      }}
      anchorEl={props.element}
      open={Boolean(props.element)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: props.horizontalPosition ?? 'left',
      }}>
      <ul>
        {props.menuLinks.map((m) => (
          <li key={m.key}>
            <Link>
              <Typography
                color='primary'
                sx={{
                  display: 'block',
                  paddingX: 2,
                }}
                onClick={m.onClick}>
                {t(m.key)}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </Popover>
  );
};

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  return (
    <Menu
      open={Boolean(props.element)}
      PaperProps={{
        elevation: 1,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
        },
      }}
      anchorEl={props.element}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {props.menuLinks.map((l, i) =>
        l.isDivider ? (
          <Divider key={i} />
        ) : (
          <MenuItem key={l.key} onClick={l.onClick}>
            {t(l.key)}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default DropdownMenu;
