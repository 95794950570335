import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { useSubject } from '@aesop-fables/scrinium';
import { Data, Domain } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';

export interface CollegeSavingsPlanDetailsCardProps extends SummaryCardProps {
  planDetails: Data.CollegeSavingsPlans.CollegeSavingsPlanDetailsFormData;
}

const CollegeSavingsPlanDetailsCard: React.FC<CollegeSavingsPlanDetailsCardProps> = ({
  onEdit,
  planDetails,
}) => {
  const { t } = useTranslation();
  const dependents =
    useSubject<Domain.DependentData[]>(Data.People.PersonServices.DependentData) ?? [];
  const [activeDependent, setActiveDependent] = useState<Domain.DependentData | undefined>(
    undefined
  );

  useEffect(() => {
    if (dependents?.length > 0) {
      const dependent = dependents.find((d) => d.id === planDetails.beneficiaryId) ?? dependents[0];
      setActiveDependent(dependent);
    }
  }, [planDetails.beneficiaryId, dependents, setActiveDependent]);

  return (
    <CardDisplay headerKey='WhoIsThisFor?' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('WhoIsThisCollegeSavingsPlanFor')}
          </Typography>
          <Typography color='secondary'>
            {planDetails.beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT
              ? activeDependent?.name
              : Domain.beneficiaryTypeLabels[
                  planDetails.beneficiaryType as Domain.BeneficiaryTypeEnum
                ]}
          </Typography>
        </Grid>
        {planDetails.beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('DependentYOB')}
              </Typography>
              <Typography color='secondary'>{activeDependent?.birthYear}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
        {planDetails.beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <>
            <Grid item xs />
            <Grid item xs />
          </>
        )}
      </Grid>
    </CardDisplay>
  );
};

export default CollegeSavingsPlanDetailsCard;
