/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import * as Yup from 'yup';
import { Hooks } from '@3nickels/data-modules';
import { IncomeFormData, incomeSchemaWithSpouse, incomeSchemaWithoutSpouse } from './Types';

export function useIncomeSchema() {
  const includeSpouse = Hooks.useIncludeSpouse();
  return useMemo(() => {
    const schema = includeSpouse ? incomeSchemaWithSpouse : incomeSchemaWithoutSpouse;
    return Yup.object(schema);
  }, [includeSpouse]) as unknown as Yup.Schema<IncomeFormData>;
}
