import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data, Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';

export interface HomeDetailsCardProps extends SummaryCardProps {
  homeDetails: Data.RealEstate.HousingEntityDetailsFormData;
}

const HomeDetailsCard: React.FC<HomeDetailsCardProps> = ({ onEdit, homeDetails }) => {
  const { t } = useTranslation();
  const missingLoanInfo = useMemo(
    () => typeof homeDetails.hasLoan === 'undefined' || homeDetails.hasLoan === null,
    [homeDetails.hasLoan]
  );

  return (
    <CardDisplay headerKey='MortgageDetails' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Mortgage?')}
          </Typography>
          <Typography color={missingLoanInfo ? 'error' : 'secondary'}>
            {missingLoanInfo ? t('AnswerNeeded') : t(homeDetails.hasLoan ? 'Yes' : 'No')}
          </Typography>
        </Grid>
        {homeDetails.hasLoan ? (
          <>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('AmountIOwe')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(homeDetails.amountOwed ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('InterestRate')}
              </Typography>
              <Typography color='secondary'>{homeDetails.interestRate}%</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MortgageTerm')}
              </Typography>
              <Typography color='secondary'>
                {Domain.MortgageTermTypeEnumMap.get(homeDetails.mortgageTerm as string)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MonthlyMortgagePayment')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(homeDetails.minimumPayment ?? 0)}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
          </>
        )}
      </Grid>
    </CardDisplay>
  );
};

export default HomeDetailsCard;
