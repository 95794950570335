/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HexGrid, { HexGridItem } from '../../../components/HexGrid';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { Button } from '../../../components/buttons/Button';
import { Spacing } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../GoalsLayout';
import { useMessage } from '../../../hooks/useMessage';

declare type GivingGoalSelectionItem = {
  type: Domain.GivingType;
  key: string;
};

const goalTypes: HexGridItem<GivingGoalSelectionItem>[] = [
  {
    icon: <Svgs.DisplayMediumPrimaryInvestment />,
    label: 'Assets',
    item: { key: 'assets', type: Domain.GivingType.Assets },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryAnotherBehalf />,
    label: "On Another's Behalf",
    item: { key: 'on-behalf', type: Domain.GivingType.AnotherBehalf },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCoins />,
    label: 'Cash',
    item: { key: 'cash', type: Domain.GivingType.Cash },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCollege />,
    label: 'College',
    item: { key: 'college', type: Domain.GivingType.College },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryGifts />,
    label: 'Presents',
    item: { key: 'presents', type: Domain.GivingType.Presents },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryTax />,
    label: 'For Tax Benefits',
    item: { key: 'tax-benefits', type: Domain.GivingType.TaxBenefits },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryTime />,
    label: 'Time',
    item: { key: 'time', type: Domain.GivingType.Time },
  },
];

const SelectGivingGoalTypeView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const basicGoalWizard = Hooks.useBasicGoalWizard();
  const cashGivingGoalWizard = Hooks.useCashGivingGoalWizard();

  const goalTypeSelected = async (type: Domain.GivingType) => {
    switch (type) {
      case Domain.GivingType.Assets:
        navigate('assets');
        break;
      case Domain.GivingType.AnotherBehalf:
        navigate('on-behalf');
        break;
      case Domain.GivingType.Cash:
        cashGivingGoalWizard.wizard.resetAll();
        await cashGivingGoalWizard.wizard.start({});
        navigate('cash/basics');
        break;
      case Domain.GivingType.College:
        navigate('college');
        break;
      case Domain.GivingType.Presents:
        basicGoalWizard.wizard.resetAll();
        await basicGoalWizard.wizard.start({ type: Domain.GoalTypeEnum.Gift });
        navigate('presents');
        break;
      case Domain.GivingType.TaxBenefits:
        navigate('tax-benefits');
        break;
      case Domain.GivingType.Time:
        navigate('volunteering');
        break;
      default:
        showMessage('Not implemented', 'error', 3000);
        break;
    }
  };

  return (
    <Box>
      <Typography className='title' color='primary' variant='p30'>
        {t('AddAGoal')}:<strong>&nbsp;Giving</strong>
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p18Bold'>
        {t('HowWouldYouLikeToGive?')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('LetUsKnowAboutYourGoals')}
      </Typography>
      <HexGrid
        items={goalTypes}
        keyFn={(i) => i.key}
        onClick={(x) => goalTypeSelected(x.type)}
        itemsPerRow={10}
      />
      <Grid item sm={1} mt={Spacing.sm}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(SelectGivingGoalTypeView, meta);
