import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type CarDetailsFormProps = {
  carDetails?: Data.Cars.CarDetailsFormData;
  onSubmit: (values: Data.Cars.CarDetailsFormData) => Promise<void>;
};

const CarDetailsForm: React.FC<CarDetailsFormProps> = (props) => {
  const { formState, setValue } = useFormContext();
  const [hasLoan, setHasLoan] = useState<boolean | undefined>(undefined);

  const setLoanHandler = (value: boolean) => {
    setHasLoan(value);
    if (value !== undefined && value !== null) {
      setValue('hasLoan', value ? 'true' : 'false');
    }
  };

  useEffect(() => {
    if (props.carDetails?.hasLoan !== undefined && props.carDetails?.hasLoan !== null) {
      setLoanHandler(props.carDetails?.hasLoan);
    }
  }, [props.carDetails?.hasLoan]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<Data.Cars.CarDetailsFormData>
          error={formState.errors.hasLoan !== undefined}
          helperText={formState.errors.hasLoan?.message?.toString()}
          label='DoYouHaveACarLoan'
          name='hasLoan'
          row
          onChange={(target) => {
            setLoanHandler(target.value === 'true');
          }}
          defaultValue={props.carDetails?.hasLoan?.toString()}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
        {hasLoan && (
          <>
            <TextInput<Data.Cars.CarDetailsFormData>
              error={formState.errors.amountOwed !== undefined}
              helperText={formState.errors.amountOwed?.message?.toString()}
              defaultValue={props.carDetails?.amountOwed}
              label='AmountIOwe'
              name='amountOwed'
              type='dollar'
              placeholder='$0'
              autoFocus
            />
            <TextInput<Data.Cars.CarDetailsFormData>
              error={formState.errors.interestRate !== undefined}
              helperText={formState.errors.interestRate?.message?.toString()}
              defaultValue={props.carDetails?.interestRate}
              label='InterestRate'
              name='interestRate'
              type='percent'
              placeholder='0%'
            />
            <TextInput<Data.Cars.CarDetailsFormData>
              error={formState.errors.monthlyPayment !== undefined}
              helperText={formState.errors.monthlyPayment?.message?.toString()}
              defaultValue={props.carDetails?.monthlyPayment}
              label='AmountIPayMonthly'
              name='monthlyPayment'
              type='dollar'
              placeholder='$0'
            />
            <TextInput<Data.Cars.CarDetailsFormData>
              error={formState.errors.minimumPayment !== undefined}
              helperText={formState.errors.minimumPayment?.message?.toString()}
              defaultValue={props.carDetails?.minimumPayment}
              label='MinimumPayment'
              name='minimumPayment'
              type='dollar'
              placeholder='$0'
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default CarDetailsForm;
