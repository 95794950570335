import { Domain } from '@3nickels/data-modules';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNextLessonByCourseId } from './LmsHooks';
import LmsNextLessonContent from './LmsNextLessonContent';

interface LmsNextLessonProps {
  course?: Domain.CourseDto;
}

const LmsNextLesson: React.FC<LmsNextLessonProps> = ({ course }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const nextLesson = useNextLessonByCourseId(course?.courseId ?? 0);

  const startLesson = () => {
    if (course?.courseId && nextLesson?.unitId && nextLesson?.lessonId) {
      navigate(`/learning/${course.courseId}/${nextLesson.unitId}/${nextLesson.lessonId}/content`);
    }
  };

  return (
    <Grid container xs alignItems='center' height='100%' lineHeight={2}>
      <Grid className='course-details wide' paddingX='15px' xs={8}>
        <Typography variant='p18Bold' color='primary' mb='5px'>
          {course?.name}
        </Typography>
        <LmsNextLessonContent nextLesson={nextLesson} variant='main' />
      </Grid>
      <Grid justifyContent='end'>
        <Button color='primary' onClick={startLesson}>
          {t('Start')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default LmsNextLesson;
