import { Navigate, Route } from 'react-router-dom';
import { CancellationPolicy, CreateAccount, SignUpTwoFactorAuth, Welcome } from '../sign-up/index';
import {
  ChangePassword,
  ForgotPasswordTwoFactorAuth,
  ForgotPassword,
  MemberLogin,
  LoginTwoFactorAuth,
} from '../login';

const SignUpRoutes = () => {
  return (
    <>
      <Route path='/' element={<MemberLogin />} />
      <Route path='/home' element={<MemberLogin />} />

      <Route path='/welcome' element={<Welcome />} />
      <Route path='/create-account' element={<CreateAccount />} />
      <Route path='/secure-account' element={<SignUpTwoFactorAuth />} />
      <Route path='/cancellation-policy' element={<CancellationPolicy />} />

      <Route path='/login' element={<MemberLogin />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/change-password' element={<ChangePassword />} />
      <Route path='/enter-code' element={<ForgotPasswordTwoFactorAuth />} />
      <Route path='/two-factor-auth' element={<LoginTwoFactorAuth />} />
      <Route path='/*' element={<Navigate to='/' replace />} />
    </>
  );
};

export default SignUpRoutes;
