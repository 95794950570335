import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';

interface CoveringRefinanceCostsHelpProps {
  time: string;
}

const CoveringRefinanceCostsHelp: React.FC<CoveringRefinanceCostsHelpProps> = ({ time }) => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('CoveringRefinanceCosts')}>
      <HelpPopoverText>{t('IfYouPlanOnOwningThisHome', { time })}</HelpPopoverText>
    </HelpPopover>
  );
};

export default CoveringRefinanceCostsHelp;
