import { Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Box, Grid, Typography, Divider } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading } from '../../../hooks/useLoading';
import { Colors } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import { useTranslation } from 'react-i18next';
import { Svgs } from '../../../assets/svg';
import BreakdownCategoryIcon from '../../../components/BreakdownCategoryIcon';
import { formatPercent } from '../../../helpers/utilityFunctions';
import { HelpOutline } from '@mui/icons-material';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import TargetDateFundDrawer from './TargetDateFundDrawer';
import useAdviceResultsNextInvestmentAccount from '../../../hooks/useAdviceResultsNextInvestmentAccount';
import { useBackHandler } from '../progress-indicator/useBackHandler';
import TargetDateFundCard from './TargetDateFundCard';
import { SplittingUpInvestmentsModal } from './SplittingUpInvestmentsModal';
import HelpTitle from '../../../components/HelpTitle';
import AssetBreakdownHeader from './AssetBreakdownHeader';
import { useUniqueInvMixForNavigation } from '../progress-indicator/useUniqueInvMixForNavigation';

const InvestmentAccountOverviewWrapper: React.FC = () => {
  const investments = Hooks.useCombinedInvestmentAccountData();
  const hsas = Hooks.useCombinedHsaData();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();
  const { id } = useParams();
  const investmentAccountId = parseInt(id ?? '');
  const accounts = [...investments, ...hsas];

  const backHandler = useBackHandler(holisticAdvice, 'investment', investmentAccountId);

  const [account, recommendations, tdf, nextId] = useMemo(() => {
    const account = accounts.find((x) => x.id === investmentAccountId);
    const navigationInvMix = useUniqueInvMixForNavigation(holisticAdvice?.investmentMixes ?? []);

    const index = navigationInvMix.findIndex((x) => x.id === investmentAccountId);

    if (typeof index === 'undefined' || index < 0) return [account];

    const recommendations = holisticAdvice?.investmentMixes[index];
    const tdf = holisticAdvice?.advisedInvestmentMixes.find((x) => x.id === investmentAccountId);
    const next = navigationInvMix[index + 1];

    return [account, recommendations, tdf, next?.id];
  }, [accounts, holisticAdvice, investmentAccountId]);

  if (!account || !recommendations) {
    return <Loading />;
  }

  return (
    <InvestmentAccountOverview
      account={account}
      recommendations={recommendations}
      tdf={tdf}
      nextId={nextId}
      onBack={backHandler}
    />
  );
};

export interface InvestmentAccountOverviewProps {
  account: Domain.PlanData;
  recommendations: Domain.BreakdownRecommendations;
  tdf?: Domain.BreakdownRecommendations;
  nextId?: number;
  onBack: (() => void) | undefined;
}

const InvestmentAccountOverview: React.FC<InvestmentAccountOverviewProps> = ({
  account,
  recommendations,
  tdf,
  nextId,
  onBack,
}) => {
  const { t } = useTranslation();
  const nextLabel = nextId ? 'Next Account' : 'Next: My Plan';

  const [open, setOpen] = useState(false);
  const [compare, setCompare] = useState(false);
  const navigateToNext = useAdviceResultsNextInvestmentAccount();

  const withBalance = (account.balance ?? 0) > 0;

  const onDone = () => {
    navigateToNext(nextId);
  };

  return (
    <Box width='100%'>
      <Grid
        container
        display='inline-flex'
        justifyContent='space-between'
        alignItems='start'
        flexWrap='nowrap'
        columnGap='20px'>
        <Grid display='inline-flex'>
          <Grid item>
            <HelpTitle
              text={account.nickname ?? ''}
              helpContext={<SplittingUpInvestmentsModal />}
            />
            {!withBalance && (
              <Typography className='subtitle' color='secondary' variant='p16'>
                {t('IfYoureGoingToAddMoney')}
              </Typography>
            )}
          </Grid>
        </Grid>
        {withBalance && (
          <Grid pt='10px'>
            <Typography fontFamily='Montserrat' variant='p40Bold' color='secondary'>
              {formatWholeDollars(account.balance ?? 0)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid className='investment-account-results'>
        <Grid container justifyContent='end'>
          <Grid className='investment-account-results-header' display='flex'>
            {withBalance && (
              <>
                {compare ? (
                  <Grid className='compare' display='flex' onClick={() => setCompare(false)}>
                    <Typography className='clickable' variant='p12' color='primary' mr='10px'>
                      Hide
                    </Typography>
                    <Svgs.IconMinimize />
                    <Typography variant='p16Bold' color={Colors.tertiaryDark} ml='20px'>
                      Current
                    </Typography>
                  </Grid>
                ) : (
                  <Grid className='compare' display='flex' onClick={() => setCompare(true)}>
                    <Typography className='clickable' variant='p12' color='primary' mr='10px'>
                      Compare to Current Portfolio
                    </Typography>
                    <Svgs.IconAdd />
                    <Typography variant='p16Bold' color={Colors.tertiaryDark} ml='20px'>
                      Current
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            <Typography variant='p16Bold' color='secondary'>
              Recommended
            </Typography>
          </Grid>
        </Grid>
        {recommendations?.invMix
          .filter((x) => x.newPercent > 0 || (x.oldPercent ?? 0) > 0)
          .map((x) => (
            <CollapsiblePanel
              header={
                <AssetBreakdownHeader
                  name={x.name}
                  totalAssets={
                    x.assets.filter(
                      (asset) =>
                        (asset.newValue?.dollar ?? 0) > 0 || (asset.newValue?.percent ?? 0) > 0
                    ).length
                  }
                />
              }
              headerXs={7}
              icon={<BreakdownCategoryIcon type={x.type.toString()} />}
              summary={
                <Grid width='100%' display='inline-flex' justifyContent='end'>
                  {compare && (
                    <>
                      <Typography
                        width='25%'
                        variant='inherit'
                        color={Colors.tertiaryDark}
                        justifyContent='end'>
                        {formatWholeDollars(x.oldDollars)}
                      </Typography>
                      <Typography
                        width='25%'
                        variant='inherit'
                        color={Colors.tertiaryDark}
                        fontWeight='bold'
                        justifyContent='end'>
                        {formatPercent(x.oldPercent)}
                      </Typography>
                    </>
                  )}
                  {withBalance && (
                    <Typography width='25%' variant='inherit' justifyContent='end'>
                      {formatWholeDollars(x.newDollars)}
                    </Typography>
                  )}
                  <Typography width='25%' variant='inherit' fontWeight='bold' justifyContent='end'>
                    {formatPercent(x.newPercent)}
                  </Typography>
                </Grid>
              }>
              {x.assets.map((asset) => (
                <Grid container className='investment-results-asset-breakdown'>
                  <Grid display='flex' pl='46px'>
                    <Typography width='15%' variant='p14' color='primary'>
                      {asset.ticker}
                    </Typography>
                    <Typography pl='10px' variant='p14Bold' color='primary'>
                      {asset.name}
                    </Typography>
                  </Grid>
                  <Grid className='amount-breakdown'>
                    {compare && (
                      <>
                        <Typography color={Colors.tertiaryDark}>
                          {formatWholeDollars(asset.oldValue?.dollar ?? 0)}
                        </Typography>
                        <Typography fontWeight='bold' color={Colors.tertiaryDark}>
                          {formatPercent(asset.oldValue?.percent ?? 0)}
                        </Typography>
                      </>
                    )}
                    {withBalance && (
                      <Typography color='secondary'>
                        {formatWholeDollars(asset.newValue?.dollar ?? 0)}
                      </Typography>
                    )}
                    <Typography fontWeight='bold' color='secondary'>
                      {formatPercent(asset.newValue?.percent ?? 0)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </CollapsiblePanel>
          ))}
        {tdf && (
          <>
            <Divider className='or-divider' color='secondary' flexItem>
              <Typography variant='p18Bold' color='secondary'>
                OR
              </Typography>
            </Divider>
            {tdf.invMix.map((x) => (
              <TargetDateFundCard
                header={x.assets[0]?.name ?? ''}
                icon={<Svgs.ArtTargetSmall />}
                summary={
                  <Grid width='100%' display='inline-flex' justifyContent='end'>
                    {withBalance && (
                      <Typography width='25%' variant='inherit' justifyContent='end'>
                        {formatWholeDollars(x.newDollars)}
                      </Typography>
                    )}
                    <Typography
                      width='25%'
                      variant='inherit'
                      fontWeight='bold'
                      justifyContent='end'>
                      {formatPercent(x.newPercent)}
                    </Typography>
                  </Grid>
                }
              />
            ))}
            <Grid className='help-text' display='flex'>
              <Typography
                className='clickable'
                variant='p16'
                color='primary'
                onClick={() => setOpen(true)}>
                {t('WhatAreTheDifferences')}
              </Typography>
              <HelpOutline style={{ color: Colors.primaryBase }} />
            </Grid>
          </>
        )}
      </Grid>
      <TargetDateFundDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <WizardFooter nextLabel={nextLabel} onDone={onDone} onBack={onBack} />
    </Box>
  );
};

export interface InvestmentAccountSummaryProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'NextAccount',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(InvestmentAccountOverviewWrapper, meta);
