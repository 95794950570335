import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function iPhoneSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <path
          d='M17.341 1h-9.67A1.67 1.67 0 0 0 6 2.675v19.65A1.67 1.67 0 0 0 7.672 24h9.656A1.67 1.67 0 0 0 19 22.325V2.675A1.66 1.66 0 0 0 17.34 1zm.412 18.632H7.26V3.822h10.493v15.81zM14.743 2.7H10.27v-.49h4.475v.49zm1.917-.22a.429.429 0 0 1-.425.426.429.429 0 0 1-.424-.425c0-.232.193-.425.424-.425.232-.013.425.18.425.425zm-2.11 19.74h-4.075V20.96h4.076v1.263z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </SvgIcon>
  );
}

iPhoneSvg.defaultProps = {
  fill: '#fff',
};
