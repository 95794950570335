import {
  ConfiguredDataSource,
  DataCache,
  DataCompartmentOptions,
  IAppStorage,
  ISubject,
  Predicate,
  ScriniumServices,
  createDataCache,
  fromAppStorage,
} from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import {
  ChargeData,
  PaymentApi,
  PaymentMethodsData,
  PaymentStatusData,
} from '../../api/apis/PaymentApi';
import { IServiceContainer, inject } from '@aesop-fables/containr';
import { Authentication, Data, PredicateListener } from '@3nickels/data-modules';
import { defaultIfEmpty, filter, map, Observable } from 'rxjs';
import { namespacedService } from '../../utils';

export const PaymentServices = {
  PaymentMethods: namespacedService('payment/subjects/PaymentMethods'),
  DefaultPaymentMethod: namespacedService('payment/subjects/DefaultPaymentMethod'),
  PaymentHistory: namespacedService('payment/subjects/PaymentHistory'),
};

export interface PaymentCompartments {
  paymentStatus: DataCompartmentOptions<PaymentStatusData | undefined>;
  paymentMethods: DataCompartmentOptions<PaymentMethodsData[] | undefined>;
  defaultPaymentMethod: DataCompartmentOptions<string | undefined>;
  paymentHistory: DataCompartmentOptions<ChargeData[] | undefined>;
}

export const paymentStorageKey = '@3nickels/web/payment';

export class PaymentDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @fromAppStorage(paymentStorageKey) private readonly cache: DataCache<PaymentCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  onNext(): void {
    const keys: (keyof PaymentCompartments)[] = [
      'paymentStatus',
      'paymentMethods',
      'defaultPaymentMethod',
      'paymentHistory',
    ];
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}

export const withPaymentData = Data.create3NickelsDataCacheModule<PaymentCompartments>(
  paymentStorageKey,
  (authContext: Authentication.IAuthenticationContext, container: IServiceContainer) => {
    const paymentApi = container.get<PaymentApi>(ApiKeys.Payment);
    return createDataCache<PaymentCompartments>({
      paymentStatus: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.get<Predicate>(
            Authentication.AuthenticationServices.IsAuthenticated
          ),
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await paymentApi.getPaymentStatus();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentMethods: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.get<Predicate>(
            Authentication.AuthenticationServices.IsAuthenticated
          ),
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await paymentApi.getAllPaymentMethods();
          return data;
        }),
        defaultValue: undefined,
      },
      defaultPaymentMethod: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.get<Predicate>(
            Authentication.AuthenticationServices.IsAuthenticated
          ),
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await paymentApi.getDefaultPaymentMethod();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentHistory: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: container.get<Predicate>(
            Authentication.AuthenticationServices.IsAuthenticated
          ),
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await paymentApi.getTransactionsHistory();
          return data;
        }),
        defaultValue: undefined,
      },
    });
  }
);

export class PaymentMethods implements ISubject<PaymentMethodsData[]> {
  private readonly cache: DataCache<PaymentCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly appStorage: IAppStorage) {
    this.cache = this.appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  }
  createObservable(): Observable<PaymentMethodsData[]> {
    return this.cache.observe$<PaymentMethodsData[]>('paymentMethods');
  }
}

export class PaymentHistory implements ISubject<ChargeData[]> {
  private readonly cache: DataCache<PaymentCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly appStorage: IAppStorage) {
    this.cache = this.appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  }
  createObservable(): Observable<ChargeData[]> {
    return this.cache.observe$<ChargeData[]>('paymentHistory').pipe(
      filter((x) => x !== undefined),
      defaultIfEmpty([]),
      map((x) => {
        return x.sort((a, b) => {
          const dateA = new Date(a.charge.datePaid);
          const dateB = new Date(b.charge.datePaid);
          return dateB.getTime() - dateA.getTime();
        });
      })
    );
  }
}

export class DefaultPaymentMethod implements ISubject<string> {
  private readonly cache: DataCache<PaymentCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly appStorage: IAppStorage) {
    this.cache = this.appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  }
  createObservable(): Observable<string> {
    return this.cache.observe$<string>('defaultPaymentMethod');
  }
}
