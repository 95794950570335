import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const CoinsSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <g fillRule='nonzero'>
            <path
              d='M15.103 19.06v-.086c-.475 0-.92-.029-1.352-.072a5.918 5.918 0 0 1-.979 1.67c.777.129 1.569.2 2.36.215l-.029-1.727z'
              fill='#F6C760'
            />
            <path
              d='M13.031 15.678a5.143 5.143 0 0 0-3.468-3.828 5.32 5.32 0 0 0-1.497-.23 5.04 5.04 0 0 0-3.411 1.324 5.074 5.074 0 0 0 6.836 7.498 4.984 4.984 0 0 0 1.655-3.756 6.361 6.361 0 0 0-.115-1.008z'
              fill='#1B7690'
            />
            <path
              d='m15.089 17.938-.029-1.8c-.316 0-.633-.014-.92-.028.014.201.028.403.028.604 0 .403-.043.806-.115 1.195.316.014.676.029 1.036.029zM20.659 9.504c-.346.187-.72.331-1.109.446-1.15.346-2.648.533-4.216.533-1.497 0-2.936-.173-4.044-.49a5.544 5.544 0 0 1-1.094-.417l.029 1.44a5.869 5.869 0 0 1 1.597.906c.014 0 .029 0 .043.014.979.245 2.217.389 3.469.389 1.453 0 2.892-.187 3.929-.49 1.108-.33 1.352-.69 1.352-.748 0-.072 0-.144.058-.216V9.504h-.014zM19.248 7.49C18.212 7.172 16.788 7 15.32 7c-1.468 0-2.85.173-3.871.49-1.08.33-1.31.647-1.31.748v.014c.029.115.331.46 1.425.777 1.007.302 2.346.46 3.756.46 1.454 0 2.879-.172 3.915-.489 1.108-.33 1.353-.676 1.353-.748.014-.086-.23-.432-1.339-.763z'
              fill='#F6C760'
            />
            <path
              d='M26.502 21.579v-1.526c-.346.173-.691.317-1.065.432-1.137.345-2.634.533-4.203.533-1.396 0-2.749-.159-3.828-.432a6.67 6.67 0 0 1-1.266-.46l.028 1.482v.014c0 .072.23.417 1.296.748 1.021.317 2.389.49 3.871.49 1.468 0 2.85-.173 3.871-.504 1.08-.345 1.31-.705 1.296-.777z'
              fill='#1B7690'
            />
            <path
              d='M20.644 12.354c-.345.172-.72.33-1.094.431-1.15.346-2.648.533-4.216.533-.777 0-1.54-.058-2.303-.144.417.576.72 1.209.907 1.9.431.029.906.057 1.396.057h.057c.835-.92 3.08-1.381 5.268-1.439v-1.338h-.015z'
              fill='#F6C760'
            />
            <path
              d='M25.408 17.664c-1.137.331-2.62.518-4.16.518-1.496 0-2.935-.172-4.043-.489a5.203 5.203 0 0 1-1.109-.432l.015 1.166v.432c.403.49 2.303 1.137 5.123 1.137 1.468 0 2.864-.173 3.9-.49 1.094-.33 1.339-.676 1.339-.748 0-.057.029-.115.029-.173v-1.352a4.81 4.81 0 0 1-1.094.431z'
              fill='#1E2969'
            />
            <path
              d='M25.163 15.203c-1.036-.316-2.46-.489-3.929-.489h-.086c-1.353 0-2.648.158-3.627.432-1.05.302-1.41.633-1.468.763v.014l.015.014c.028.101.33.46 1.424.777 1.008.288 2.346.461 3.757.461 1.453 0 2.82-.173 3.871-.475 1.137-.331 1.382-.69 1.396-.763-.014-.072-.273-.417-1.353-.734z'
              fill='#9A5238'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
