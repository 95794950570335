import { DataCache, IMutation, MutationContext } from '@aesop-fables/scrinium';
import { Api, Data } from '@3nickels/data-modules';

// move to dm
export class AcceptTerms implements IMutation<void> {
  async execute(context: MutationContext<void>): Promise<void> {
    const { storage, container } = context;
    const termsApi = container.get<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);

    await termsApi.acceptTerms();

    const cache = Data.Users.retrieveUserData(
      storage
    ) as unknown as DataCache<Data.Users.UserCompartments>;
    await cache.reload('userConfig');
  }
}
