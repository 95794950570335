import { Grid, Typography } from '@mui/material';
import { cityDark, qrCode } from '../../../../assets/png';
import { Colors } from '../../../../themes';
import { useTranslation } from 'react-i18next';

export const AdviceUpsellFooterCard = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      className='back-slash-split-card'
      height='22%'
      style={{ '--background': Colors.disabledLight } as React.CSSProperties}>
      <Grid
        item
        className='img-wrapper'
        sm={10}
        pt={3}
        style={{
          backgroundImage: 'linear-gradient(to bottom, #3c5167 -61%, #eae9c3',
        }}>
        <img src={cityDark} style={{ alignSelf: 'flex-end' }} />
      </Grid>
      <Grid
        container
        className='right-grid'
        pt={2}
        pb={2}
        alignItems='center'
        justifyContent='center'
        textAlign='center'>
        <Grid item sm={4} mr={'15%'}>
          <Typography variant='p20Bold' color='secondary'>
            {t('NoThanksWontUseAdvice')}
          </Typography>
        </Grid>
        <Grid item mr={2}>
          <img
            src={qrCode}
            alt='qr code'
            style={{
              maxWidth: '70px',
              marginBottom: '-16px',
            }}
          />
          <Typography justifyContent='center' variant='p12' color='secondary'>
            {t('DownloadMobileApp')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
