import { Box, MenuItem, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Margins, Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import HelpPopover from '../../../../components/HelpPopover';
import BulletedList from '../../../../components/BulletedList';
import { t } from 'i18next';

export type RetirementPlanBasicsFormProps = {
  planBasics?: Data.InvestmentAccountBasicFormData;
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const RetirementPlanBasicsForm: React.FC<RetirementPlanBasicsFormProps> = ({
  planBasics,
  wizard,
  params,
}) => {
  const { formState, watch } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();
  const planTypes = Hooks.useAvailablePlanTypes(params.isSpouse ? 'spouse' : 'me');

  const onOwnerChange = (owner: Domain.Owner) => {
    wizard.updateParams({ ...params, isSpouse: owner === 'spouse' });
  };

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'type' && params.type !== parseInt(values[name])) {
        // why does this run 4 times?
        const type = parseInt(values[name]);
        if (Domain.PlanTypeEnum[type]) {
          wizard.updateParams({ ...params, type });
        }
      } else if (name === 'owner') {
        onOwnerChange(values[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, params]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <SelectInput<Data.InvestmentAccounts.InvestmentAccountWizardParams>
          error={formState.errors.type !== undefined}
          helperText={formState.errors.type?.message?.toString()}
          defaultValue={params.id ? params.type : undefined}
          name='type'
          autoFocus
          label='TypeOfRetirementPlan'
          placeholder='ChoosePlanType'
          readOnly={
            params.institutionalAccount || (params.id !== undefined && !Number.isNaN(params.id))
          }
          helpContext={
            <HelpPopover title={t('TypeOfRetirementPlan')}>
              <BulletedList
                style={Margins.mt_xxs}
                children={[
                  'Different plan types have different rules.',
                  'Rules are used in projected simulations.',
                  <>
                    <strong>Recommendation:</strong> 401(k) is the most common plan; choose that if
                    you’re unsure about your plan type.
                  </>,
                ]}
              />
            </HelpPopover>
          }>
          {planTypes.map((x) => (
            <MenuItem key={x.key} value={x.key}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
        <TextInput<Data.InvestmentAccountBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={planBasics?.name}
          InputProps={{
            readOnly:
              params.institutionalAccount || (params.id !== undefined && !Number.isNaN(params.id)),
          }}
          inputProps={{ maxLength: 64 }}
          label='AccountNickname'
          name='name'
          helpContext={
            <HelpPopover title={t('AccountNickname')}>
              <BulletedList
                style={Margins.mt_xxs}
                children={[
                  'Used to identify your retirement plans in the event you have more than one',
                  <>
                    <strong>Recommendation:</strong> You can call it whatever you want, as long as
                    it’s something recognizable and easy to remember.
                  </>,
                ]}
              />
            </HelpPopover>
          }
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.InvestmentAccountBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            disabled={
              params.institutionalAccount || (params.id !== undefined && !Number.isNaN(params.id))
            }
            defaultValue={planBasics?.owner}
            items={[
              { label: 'Me', value: 'me' },
              { label: 'Spouse', value: 'spouse' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default RetirementPlanBasicsForm;
