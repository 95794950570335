import { Data, Domain, Hooks, ObservableGuard } from '@3nickels/data-modules';
import { MutationStatus, MutatorState, useObservable } from '@aesop-fables/scrinium';
import { Backdrop, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../AccountDetailsLayout';
import { Svgs } from '../../../assets/svg';
import { Button } from '../../../components/buttons/Button';
import { Spacing } from '../../../themes';
import HelpText from '../../../components/HelpText';
import { useDeleteAccountMutations } from '../../../hooks/useMutations/useAccountMutations';

const DuplicatAccountViewWrapper: React.FC = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { wizard, currentStep, params } =
    Hooks.useRetirementWizard<Data.InvestmentAccounts.AccountContributionsFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id, type: planType } = useParams();
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : undefined;
  const { account } = Hooks.useRetirementWizardData();
  const { institutionalAccount } = Hooks.useInstitutionalAccount();
  const { deleteRetirementPlan } = useDeleteAccountMutations();

  useEffect(() => {
    // ensure type is initialized
    if (
      typeof isStarted !== 'undefined' &&
      !isStarted &&
      type &&
      Domain.PlanTypeEnumMap.get(type)
    ) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('contributions');
    }
  }, [isStarted, type]);

  useEffect(() => {
    if (deleteRetirementPlan.status === MutationStatus.Complete) {
      if (institutionalAccount && institutionalAccount.planType) {
        wizard.start({
          id: institutionalAccount.id,
          type: institutionalAccount.planType,
          isSpouse: false,
        });
        navigate(
          `/account-details/retirement-savings-plan/basics/${
            Domain.planTypeIdToAccountKey[
              institutionalAccount.planType as keyof typeof Domain.planTypeIdToAccountKey
            ]
          }/${institutionalAccount.id}`
        );
      }
    }
  }, [deleteRetirementPlan.status, institutionalAccount]);

  const handleKeepBoth = async () => {
    setLoading(true);

    try {
      currentStep.save({ ...currentStep.model, eligibleForContributions: false });
      await wizard.commitStep('contributions');
      if (institutionalAccount && institutionalAccount.planType) {
        wizard.start({
          id: institutionalAccount.id,
          type: institutionalAccount.planType,
          isSpouse: false,
        });
        navigate(
          `/account-details/retirement-savings-plan/basics/${
            Domain.planTypeIdToAccountKey[
              institutionalAccount.planType as keyof typeof Domain.planTypeIdToAccountKey
            ]
          }/${institutionalAccount.id}`,
          { replace: true }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ObservableGuard
      predicate$={wizard.isStarted$}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {params && account && (
        <DuplicateAccountView
          params={params}
          duplicate={account}
          deleteRetirementPlan={deleteRetirementPlan}
          keepBoth={handleKeepBoth}
        />
      )}
    </ObservableGuard>
  );
};

interface DuplicateAccountViewProps {
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  duplicate: Domain.IInvestmentAccount;
  deleteRetirementPlan: MutatorState<Domain.IInvestmentAccount>;
  keepBoth: () => Promise<void>;
}

const DuplicateAccountView: React.FC<DuplicateAccountViewProps> = ({
  params,
  duplicate,
  deleteRetirementPlan,
  keepBoth,
}) => {
  const { setLoading } = useLoading();
  const { t } = useTranslation();

  const handleDeleteRetirementPlan = () => {
    if (duplicate.id) {
      setLoading(true);
      deleteRetirementPlan.action(duplicate);
    }
  };

  return (
    <Grid container flexDirection='column'>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('IsThisADuplicate?')}
      </Typography>
      <Typography className='subtitle' variant='p16' color='secondary'>
        {t('WeThinkWeMayHaveFoundADuplicate')}
      </Typography>
      <HelpText
        text='WhyDoesThisMatter?'
        content={
          <Grid container flexDirection='column' alignItems='center'>
            <Typography variant='p18Bold' color='secondary'>
              {t('WhyDoesThisMatter?')}
            </Typography>
            <Typography variant='p16' color='secondary' marginTop='16px'>
              {t('YourOrganizationAddedARetirementPlan')}
            </Typography>
            <Grid item marginTop='16px'>
              <Typography variant='p16Bold' color='secondary' display='inline'>
                {t('IfTheRetirementPlanYouAlreadyCreatedIsNowADuplicate')}{' '}
              </Typography>
              <Typography variant='p16' color='secondary' display='inline'>
                {t('weRecommendDeletingTheOldAccount')}
              </Typography>
            </Grid>
            <Grid item marginTop='16px'>
              <Typography variant='p16Bold' color='secondary' display='inline'>
                {t('IfItsNotADuplicate')}{' '}
              </Typography>
              <Typography variant='p16' color='secondary' display='inline'>
                {t('weWillTreatTheNewOneAsTheAccountYouCanContributeTo')}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <Grid item className='duplicate-account-content'>
        <Svgs.DisplayLargeCustomWarning />
        <Grid item className='duplicate-account-info'>
          <Typography variant='p18Bold' color='secondary'>
            {duplicate.name}
          </Typography>
          <Typography className='subtitle' variant='p16' color='secondary'>
            {Domain.planTypeToAccountLabel[params.type]}
          </Typography>
        </Grid>
        <Grid item display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item>
            <Button
              fullWidth
              label='DeleteItsADuplicate'
              variant='outlined'
              onClick={handleDeleteRetirementPlan}
            />
          </Grid>
          <Grid width='30px' />
          <Grid item>
            <Button
              fullWidth
              label='KeepBothNotADuplicate'
              variant='contained'
              onClick={keepBoth}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const meta = {
  headerVariant: 'duplicate',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(DuplicatAccountViewWrapper, meta);
