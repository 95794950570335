import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { PaymentCompartments, paymentStorageKey } from '..';

export class SetDefaultPaymentMethod implements IMutation<string> {
  async execute(context: MutationContext<string>): Promise<void> {
    const { data, storage, container } = context;
    const paymentApi = container.get<PaymentApi>(ApiKeys.Payment);

    // post new data to api
    await paymentApi.setDefaultPaymentMethod(data);

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);
    await cache.reloadAll();
  }
}

export class DeletePaymentMethod implements IMutation<string> {
  async execute(context: MutationContext<string>): Promise<void> {
    const { data, storage, container } = context;
    const paymentApi = container.get<PaymentApi>(ApiKeys.Payment);

    // post new data to api
    await paymentApi.removePaymentMethod(data);

    const cache = storage.retrieve<PaymentCompartments>(paymentStorageKey);
    await cache.reloadAll();
  }
}
