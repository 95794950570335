import { Data } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { SummaryCardProps } from '../../Types';

export interface DebtDetailsCardProps extends SummaryCardProps {
  debtDetails: Data.Debt.DebtDetailsFormData;
}

const DebtDetailsCard: React.FC<DebtDetailsCardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='DebtDetails' actionContent='Edit' actionClick={props.onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentBalance')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(props.debtDetails?.amountOwed ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('InterestRate')}
          </Typography>
          <Typography color='secondary'>{props.debtDetails?.interestRate}%</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AmountIPayMonthly')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(props.debtDetails?.monthlyPayment ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MinimumPayment')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(props.debtDetails.minimumPayment ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs />
      </Grid>
    </CardDisplay>
  );
};

export default DebtDetailsCard;
