/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Domain } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { EditViewProps } from '../../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import { InvestmentAccountBasicsEditViewWrapper } from '../../InvestmentAccountBasicsEditView';

export const HSABasicsEditViewWrapper: React.FC<EditViewProps> = (props) => {
  return <InvestmentAccountBasicsEditViewWrapper type={Domain.PlanTypeEnum.HSA} {...props} />;
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 3,
    currentStep: 1,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(HSABasicsEditViewWrapper, meta);
