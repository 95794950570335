/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { Spacing } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../GoalsLayout';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { getWizardFooterProps } from '../../EditViewUtils';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { Stack } from '@mui/system';

declare type SelectHouseGoalTypeFormData = { houseGoalType: Domain.HouseGoalTypeEnum };

const SelectHouseGoalTypeView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizard } = Hooks.useBasicGoalWizard();
  const { wizard: mortgageGoalWizard } = Hooks.useMortgageGoalWizard();
  const methods = useForm<SelectHouseGoalTypeFormData>();
  const [disableNext, setDisableNext] = useState(true);

  const onSubmit = (values: SelectHouseGoalTypeFormData) => {
    if (values.houseGoalType === Domain.HouseGoalTypeEnum.Buy) {
      wizard.start({ type: Domain.GoalTypeEnum.House });
      navigate('buy');
    } else {
      mortgageGoalWizard.start({});
      navigate('pay-off-mortgage');
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography className='title' color='primary' component='h1' variant='h1'>
            {t('GoalBasics')}
          </Typography>
          <Grid
            container
            display='flex'
            flexDirection='column'
            alignItems='center'
            mt={Spacing.xxs}>
            <Svgs.ArtHouseLarge />
            <Grid container justifyContent='center' mt={Spacing.xxs}>
              <Grid item sm={6}>
                <Box>
                  <Stack>
                    <ToggleRadioButtonGroup<SelectHouseGoalTypeFormData>
                      error={methods.formState.errors.houseGoalType !== undefined}
                      helperText={methods.formState.errors.houseGoalType?.message?.toString()}
                      name='houseGoalType'
                      label='Which option best describes your goal?'
                      row
                      onChange={disableNext ? () => setDisableNext(false) : undefined}
                      items={[
                        { label: 'Buy a House', value: Domain.HouseGoalTypeEnum.Buy },
                        { label: 'Pay Off Mortgage', value: Domain.HouseGoalTypeEnum.PayOff },
                      ]}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <WizardFooter
            disableBack={false}
            onBack={() => navigate(-1)}
            disableNext={disableNext}
            {...getWizardFooterProps(meta.nextLocaleKey, false)}
          />
        </Box>
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'Continue',
  title: 'House',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(SelectHouseGoalTypeView, meta);
