import * as React from 'react';
import { styled } from '@mui/material/styles';
import { HexagonSvg } from './images/HexagonSvg';
import { Colors } from '../themes';
import { Typography } from '@mui/material';

export type HexagonStepProps = {
  steps: number;
  currentStep: number;
};

const Hexagon = styled('div')({
  'height': '60px',
  'width': '70px',
  'position': 'relative',
  'display': 'flex',
  'marginTop': '10px',
  'marginBottom': '10px',
  '& .MuiSvgIcon-root': {
    width: '70px !important',
    height: 'inherit !important',
    filter: 'drop-shadow(0 0 4px rgba(30, 41, 105, 0.4))',
  },
  '& > .steps': {
    'width': '70px',
    'height': '60px',
    'position': 'absolute',
    'top': '0',
    'left': '0',
    'textAlign': 'center',
    'lineHeight': '60px',
    '& > .steps-text': {
      'display': 'inline-block',
      'verticalAlign': 'center',
      'lineHeight': 'normal',
      'fontSize': '11.5px',
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    '& > svg': {
      height: '60px',
      width: '60px',
      position: 'absolute',
      top: '0',
      left: '5px',
      circle: {
        'strokeWidth': '4px',
        'fill': 'transparent',
        'strokeDasharray': '10, 6',
        'stroke': Colors.primaryBase,
        '&.dim': {
          stroke: Colors.primaryLighter,
        },
        '&.main': {
          stroke: '#aaa',
        },
      },
    },
  },
});

const HexagonStep: React.FC<HexagonStepProps> = (props) => {
  const circles: React.ReactNode[] = new Array<React.ReactNode>();
  const radius: number = 55 / 2;
  const degRotation = 360 / props.steps;
  const dashArray = Math.ceil(2 * Math.PI * radius);
  const percentage = ((100 / props.steps) * dashArray) / 100;

  for (let i = 0; i < props.steps; i++) {
    circles.push(
      <circle
        key={i}
        className={i + 1 > props.currentStep ? 'dim' : ''}
        cx='50%'
        cy='50%'
        data-testid={`dash-${i + 1}${i + 1 > props.currentStep ? '-dim' : ''}`}
        r={`${radius}%`}
        style={{
          transformOrigin: 'center',
          transform: `rotate(${degRotation * (i + 1) + 200}deg)`,
          strokeDasharray: `${percentage - 15}, ${dashArray}`,
        }}
      />
    );
  }

  return (
    <Hexagon>
      <HexagonSvg viewBox='0 0 300 300' />
      <div className='steps'>
        <span className='steps-text' data-testid='steps-text'>
          <Typography color='secondary'>
            {props.currentStep}/{props.steps}
          </Typography>
        </span>
        <svg viewBox='0 0 70 70'>{circles}</svg>
      </div>
    </Hexagon>
  );
};

HexagonStep.defaultProps = {
  steps: 5,
  currentStep: 1,
};

export default HexagonStep;
