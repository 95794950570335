import { LoaderFunction, RouteObject } from 'react-router-dom';
import { Observable, filter } from 'rxjs';

export function wireUpLoaders(
  routes: RouteObject[],
  loaders: Map<string, LoaderFunction>,
  parentPath = ''
) {
  return routes.map((route) => {
    const path = parentPath.concat(route.path ?? '');
    if (route.path) {
      const loader = loaders.get(path);
      if (loader) {
        route.loader = loader;
      }
    }

    if (route.children) {
      route.children = wireUpLoaders(
        route.children,
        loaders,
        path.length > 0 ? path?.concat('/') : ''
      );
    }

    return route;
  });
}

export async function waitFor(predicate$: Observable<boolean>) {
  await new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
    let subscription: any;
    const unsubscribe = () => {
      if (subscription?.unsubscribe) {
        subscription.unsubscribe();
      }
    };

    subscription = predicate$.pipe(filter((val) => val === true)).subscribe({
      next() {
        try {
          unsubscribe();
        } catch (e) {
          reject(e);
          return;
        }

        resolve();
      },
    });
  });
}
