import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { Colors } from '../themes';
import { Check } from '@mui/icons-material';
import React, { useState } from 'react';

export interface FlatSelectorItem<T> {
  title: string;
  description?: string;
  value: T;
}

export interface FlatSelectorProps<T> {
  items: FlatSelectorItem<T>[];
  onSelectedChanged: (values: T[]) => void;
}

export function FlatSelector<T>(props: FlatSelectorProps<T>): React.ReactNode {
  const { items, onSelectedChanged } = props;
  const [selected, setSelected] = useState<T[]>([]);

  const updateSelectedItems = (values: T[]) => {
    setSelected(values);
    onSelectedChanged(values);
  };

  const itemClickHandler = (item: T) => {
    if (selected.indexOf(item) !== -1) {
      updateSelectedItems(selected.filter((x) => x !== item));
      return;
    }

    updateSelectedItems([...selected, item]);
  };

  function isCurrentItemSelected(item: T) {
    return selected.indexOf(item) !== -1;
  }

  return (
    <>
      {items.map((item) => {
        const checked = isCurrentItemSelected(item.value);
        return (
          <Card
            className={checked ? 'check-select-checked' : 'check-select'}
            onClick={() => {
              itemClickHandler(item.value);
            }}
            key={item.title}
            elevation={0}>
            <CardHeader
              disableTypography
              title={
                <Typography
                  color={checked ? Colors.tertiaryBase : Colors.secondaryBase}
                  variant='p18Bold'
                  marginTop={item.description?.length !== 0 || checked ? '' : '8px'}>
                  {item.title}
                </Typography>
              }
              subheader={
                item.description?.length !== 0 && (
                  <Typography
                    variant='p13'
                    color={checked ? Colors.tertiaryBase : Colors.secondaryBase}>
                    {item.description}
                  </Typography>
                )
              }
              action={
                checked && (
                  <Grid item mt='11px' mr={1}>
                    <Check htmlColor={Colors.tertiaryBase} />
                  </Grid>
                )
              }
            />
          </Card>
        );
      })}
    </>
  );
}
