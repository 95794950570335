import React from 'react';
import { useTranslation } from 'react-i18next';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepFour: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={<Svgs.ArtHealthInsurance2 />}
      headerText='ChooseAnInsurancePlan'
      body={
        <BulletedList
          color='secondary'
          style={{ marginTop: '6px' }}
          children={[
            t('MakeSureThatYourDoctor'),
            t('MakeSureThatYourMedications'),
            t('CallTheDifferentInsuranceCompanies'),
            t('ThoroughlyCheckToSee'),
          ]}
        />
      }
    />
  );
};

export default StepFour;
