import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function UniversitySvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fill-rule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <path
          d='M12.5 2C18.299 2 23 6.701 23 12.5S18.299 23 12.5 23 2 18.299 2 12.5 6.701 2 12.5 2zM9.642 5.741h-2.87v9.466c0 1.6.523 2.835 1.572 3.703 1.05.871 2.42 1.305 4.133 1.305 1.712 0 3.098-.437 4.156-1.305 1.057-.868 1.588-2.103 1.588-3.703V5.741h-2.874v9.462c0 .945-.252 1.65-.763 2.115-.512.461-1.224.693-2.107.693-.883 0-1.577-.232-2.08-.7-.504-.47-.755-1.17-.755-2.111V5.74z'
          fill={props.fill}
          fill-rule='nonzero'
        />
      </g>
    </SvgIcon>
  );
}

UniversitySvg.defaultProps = {
  fill: '#fff',
};
