import { Data, Domain } from '@3nickels/data-modules';
import { inject } from '@aesop-fables/containr';
import { DataCache, ISubject, fromAppStorage, injectSubject } from '@aesop-fables/scrinium';
import { Observable, combineLatest, delay, map } from 'rxjs';

export class AppReadySubject implements ISubject<boolean> {
  constructor(
    @injectSubject(Data.People.PersonServices.PersonPredicate)
    private readonly personInitialized$: Observable<boolean>,
    @injectSubject(Data.Users.UserServices.Predicates.Initialized)
    private readonly userInitialized$: Observable<boolean>
  ) {}

  createObservable(): Observable<boolean> {
    return combineLatest([this.personInitialized$, this.userInitialized$]).pipe(
      map((vals) => vals.every((x) => x === true)),
      delay(1)
    );
  }
}

export class IncomeReadySubject implements ISubject<boolean> {
  constructor(
    @fromAppStorage(Data.Income.incomeStorageKey)
    private readonly cache: DataCache<Data.Income.IncomeCompartments>
  ) {}

  createObservable(): Observable<boolean> {
    return combineLatest([this.cache.initialized$]).pipe(
      map((vals) => vals.every((x) => x === true)),
      delay(1)
    );
  }
}

export class AccountDetailsReadySubject implements ISubject<boolean> {
  constructor(
    @fromAppStorage(Data.InvestmentAccounts.investmentAccountStorageKey)
    private readonly investmentAccountCache: DataCache<Data.InvestmentAccounts.InvestmentAccountCompartments>
  ) {}

  createObservable(): Observable<boolean> {
    return combineLatest([this.investmentAccountCache.initialized$]).pipe(
      map((vals) =>
        vals.every((x) => {
          return x === true;
        })
      ),
      delay(1)
    );
  }
}

export class PlaidRedirectSubject implements ISubject<number | undefined> {
  constructor(
    @inject(Data.Plaid.PlaidServices.Wizard) private readonly wizard: Data.Plaid.PlaidWizard
  ) {}

  createObservable(): Observable<number | undefined> {
    return combineLatest([
      this.wizard.loading$,
      this.wizard.currentStatus$,
      this.wizard.targetInstitution$,
    ]).pipe(
      map(([, status, accessId]) => {
        return status === Domain.PlaidStatusEnum.RequiresAttention ? accessId : undefined;
      })
    );
  }
}
