import React, { useEffect } from 'react';
import { Backdrop } from '@mui/material';
import { Loading } from '../../hooks/useLoading';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { useSubject } from '@aesop-fables/scrinium';
import { BudgetDetailsManager } from './BudgetDetailsManager';
import { BudgetDetailsLayoutMeta } from './BudgetDetailsLayout';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { useNeedsOnboarding } from '../../hooks';
import { useOutletContext } from 'react-router-dom';

const BudgetDetailsHomeWrapper: React.FC = () => {
  const { setMeta } = useOutletContext<LayoutContext>();
  const incomeReady = useSubject<boolean>(IncomeReadySubject);
  const needsOnboarding = useNeedsOnboarding();

  useEffect(() => {
    setMeta((prev) => ({
      ...prev,
      showBack: needsOnboarding,
    }));
  }, [needsOnboarding]);

  if (typeof incomeReady === 'undefined' || incomeReady === false) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return <BudgetDetailsManager />;
};

const meta = {
  showNext: false,
  headerVariant: 'home',
} satisfies LayoutMeta<BudgetDetailsLayoutMeta>;

export default withLayoutMeta(BudgetDetailsHomeWrapper, meta);
