import { Collapse, Alert, IconButton, Typography, AlertColor } from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';

interface CollapseMessageProps {
  messageKey?: string;
  message?: string | JSX.Element;
  show: boolean;
  hide: () => void;
  severity?: AlertColor;
}

const CollapseMessage: React.FC<CollapseMessageProps> = ({
  message,
  messageKey,
  show,
  hide,
  severity,
}) => {
  return (
    <Collapse className='toast' role={severity} in={show}>
      <Alert
        severity={severity ?? 'success'}
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='large'
            onClick={() => {
              hide();
            }}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }>
        {typeof message === 'string' ? (
          <Typography fontSize={18}>{message ?? t(messageKey as string)}</Typography>
        ) : (
          message
        )}
      </Alert>
    </Collapse>
  );
};

export default CollapseMessage;
