import { Card, Typography } from '@mui/material';
import { zillow } from '../assets/png';
import { t } from 'i18next';

const ZillowCard: React.FC = () => {
  const openZillow = () => {
    window.open('http://www.zillow.com/mortgage-rates/', '_blank');
  };
  return (
    <Card className='zillow-card' onClick={openZillow}>
      <img src={zillow} width='120px' style={{ marginLeft: '20px', marginRight: '20px' }} />
      <Typography variant='p12' color='primary' display='block'>
        {t('ZillowIncUse1', { year: new Date().getFullYear() })}
        <u>{t('ZillowMortgageRates')}</u>
        {t('ZillowIncUse2', { year: new Date().getFullYear() })}
      </Typography>
    </Card>
  );
};

export default ZillowCard;
