const webSessionKey = `@3nickels/data-modules/authentication/session`;

class AuthLocalStorageManager {
  constructor(private readonly key: string) {}

  isAuthenticated(): boolean {
    const value = localStorage.getItem(this.key);
    return value === '1';
  }

  set(authenticated: boolean) {
    if (authenticated) {
      localStorage.setItem(this.key, '1');
      return;
    }

    this.clear();
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}

export const AuthLocalStorage = new AuthLocalStorageManager(webSessionKey);
