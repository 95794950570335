/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { firstValueFrom, map } from 'rxjs';
import PensionPlanBasicsForm from './PensionPlanBasicsForm';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';

const accountBasicsSchema = (includeSpouse: boolean) =>
  Yup.object({
    name: Yup.string()
      .required('Name is required')
      .test('name', 'Plan name must not contain special characters', (name) =>
        /^["^[0-9a-zA-Z\s\-.(),']*$/.test(name)
      ),
    owner: includeSpouse ? Yup.string().required('Owner is required') : Yup.string().notRequired(),
  });

export const PensionPlanBasicsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard, params } =
    Hooks.usePensionWizard<Data.Pensions.PensionWizardParams>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <PensionPlanBasicsEditView
              currentStep={currentStep}
              wizard={wizard}
              editing={editing}
              onBack={onBack}
              params={params}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface PensionPlanBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Pensions.PensionFormData, Data.Pensions.PensionWizardParams>;
  wizard: Data.Pensions.PensionWizard;
  params: Data.Pensions.PensionWizardParams;
}

const PensionPlanBasicsEditView: React.FC<PensionPlanBasicsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const includeSpouse = Hooks.useIncludeSpouse();
  const methods = useForm<Data.InvestmentAccountBasicFormData & Data.Pensions.PensionWizardParams>({
    defaultValues: { ...currentStep.model },
    resolver: yupResolver(accountBasicsSchema(includeSpouse)),
    mode: 'onChange',
  });
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save(values);

      if (editing && onBack) {
        await wizard.commitStep('basic');
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      const id = wizardParams?.id ? `/${wizardParams?.id.toString()}` : '';
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/pension-plan/basics${id}`, {
        replace: true,
      });

      navigate(`/account-details/pension-plan/details${id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t(editing ? 'AccountBasics' : 'AddPensionPlan')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <PensionPlanBasicsForm
                    planBasics={currentStep.model}
                    onSubmit={onSubmit}
                    wizard={wizard}
                    params={params}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 2,
    currentStep: 1,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(PensionPlanBasicsEditViewWrapper, meta);
