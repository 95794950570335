import { SvgIconProps, SvgIcon } from '@mui/material';

export function AccountsSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g id='3Nickels-Web' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Group-2'>
          <rect id='Rectangle' x='0' y='0' width='25' height='25'></rect>
          <g
            id='Layer_1_78_-Copy'
            transform='translate(3, 3)'
            fill={props.fill}
            fill-rule='nonzero'>
            <g id='Group'>
              <path
                d='M18.1223471,17.2681442 L0.865792759,17.2681442 C0.391385768,17.2681442 0,17.6595911 0,18.1340721 C0,18.6085531 0.391385768,19 0.865792759,19 L18.1223471,19 C18.5967541,19 18.9881398,18.6085531 18.9881398,18.1340721 C18.9881398,17.6595911 18.5967541,17.2681442 18.1223471,17.2681442 Z'
                id='Path'></path>
              <path
                d='M1.94506866,15.0262213 C1.47066167,15.0262213 1.07927591,15.4176682 1.07927591,15.8921492 C1.07927591,16.3666302 1.47066167,16.7580771 1.94506866,16.7580771 L17.0430712,16.7580771 C17.5174782,16.7580771 17.9088639,16.3666302 17.9088639,15.8921492 C17.9088639,15.4176682 17.5174782,15.0262213 17.0430712,15.0262213 L16.829588,15.0262213 L16.829588,7.25659435 L17.0430712,7.25659435 C17.2802747,7.25659435 17.4700375,7.06680194 17.4700375,6.82956142 C17.4700375,6.5923209 17.2802747,6.40252848 17.0430712,6.40252848 L1.94506866,6.40252848 C1.70786517,6.40252848 1.51810237,6.5923209 1.51810237,6.82956142 C1.51810237,7.06680194 1.70786517,7.25659435 1.94506866,7.25659435 L2.15855181,7.25659435 L2.15855181,15.0262213 L1.94506866,15.0262213 L1.94506866,15.0262213 Z M15.0980025,7.25659435 L15.0980025,15.0262213 L12.5124844,15.0262213 L12.5124844,7.25659435 L15.0980025,7.25659435 Z M10.7808989,7.25659435 L10.7808989,15.0262213 L8.19538077,15.0262213 L8.19538077,7.25659435 L10.7808989,7.25659435 Z M3.87827715,7.25659435 L6.46379526,7.25659435 L6.46379526,15.0262213 L3.87827715,15.0262213 L3.87827715,7.25659435 Z'
                id='Shape'></path>
              <path
                d='M0.865792759,5.61963477 L18.1223471,5.61963477 C18.1223471,5.61963477 18.1342072,5.61963477 18.1342072,5.61963477 C18.6086142,5.61963477 19,5.22818792 19,4.75370688 C19,4.37412205 18.7509363,4.05384735 18.4188514,3.9352271 L9.85580524,0.0800686749 C9.63046192,-0.0266895583 9.36953808,-0.0266895583 9.14419476,0.0800686749 L0.509987516,3.95895115 C0.142322097,4.12501951 -0.0711610487,4.52832839 0.0237203496,4.93163727 C0.106741573,5.33494615 0.450686642,5.61963477 0.865792759,5.61963477 Z'
                id='Path'></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
