import { Grid, GridProps } from '@mui/material';
import React, { useMemo } from 'react';
import SideNavigation, { type NickelsMenuItem } from '../../components/toc/SideNavigation';
import { Spacing } from '../../themes';
import { Header } from './Header';
import { AnimatePresence } from 'framer-motion';
import AnimatedTransition from '../../components/AnimatedTransiton';
import { useContentView } from './ContentViewContext';

export type ContentProps = {
  headerChildren?: React.ReactNode;
  leftChildren?: React.ReactNode;
  progressIndicator?: React.ReactNode;
  progressIndicatorPosition?: 'start' | 'end';
  sideNavMenuItems: NickelsMenuItem[];
  sideComponent?: React.ReactNode;
  footer?: React.ReactNode;
  background?: React.ReactNode;
  children: React.ReactNode;
  containerProps?: GridProps;
};

const ContentView: React.FC<ContentProps> = ({
  headerChildren,
  leftChildren,
  progressIndicator,
  progressIndicatorPosition = 'start',
  sideNavMenuItems,
  sideComponent,
  footer,
  background,
  children,
  containerProps,
}) => {
  const { expanded, id } = useContentView();
  const contentId = useMemo(() => {
    if (typeof id !== 'undefined') return id;
    if (expanded) return 'expanded';
    return '';
  }, [sideComponent, expanded, id]); // can prob remove expanded and just use id

  return (
    <Grid
      container
      flexDirection='row'
      className='content-wrapper'
      id={contentId}
      {...containerProps}>
      <Header leftChildren={leftChildren}>{headerChildren}</Header>
      <Grid gridArea='sidebar'>
        {sideComponent ?? <SideNavigation menuItems={sideNavMenuItems} />}
      </Grid>
      <Grid
        className='content-view'
        gridArea='content'
        sx={{ overflow: 'scroll', maxHeight: '90vh' }}>
        {background}
        <AnimatePresence mode='wait'>
          <AnimatedTransition>
            <Grid className='content' item flexDirection='column'>
              {progressIndicatorPosition === 'start' && (
                <Grid pt={Spacing.xxs} pl={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
              <Grid className='children' p={Spacing.xxs}>
                {children}
              </Grid>
              {progressIndicatorPosition === 'end' && (
                <Grid pt={Spacing.xxs} pr={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
            </Grid>
          </AnimatedTransition>
        </AnimatePresence>
      </Grid>
      {footer}
    </Grid>
  );
};

export default ContentView;
