import { Hooks } from '@3nickels/data-modules';
import { useEffect } from 'react';
import { useMessage } from './useMessage';

export function useInstitutionalAccountReviewStatus() {
  const { institutionalAccount, institutionalAccountRequiresReview } =
    Hooks.useInstitutionalAccount();
  const { showMessage } = useMessage();

  useEffect(() => {
    if (institutionalAccount?.active && institutionalAccountRequiresReview) {
      showMessage(`Your account, ${institutionalAccount.name} requires review`, 'warning');
    }
  }, [institutionalAccount, institutionalAccountRequiresReview]);
}
