export const xxxs = 1;
export const xxs = 5;
export const xs = 10;
export const sm = 15;
export const md = 20;
export const lg = 30;
export const xl = 40;
export const xxl = 50;

export const gutter = md;
