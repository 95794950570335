import { Domain } from '@3nickels/data-modules';

export const mapDebtTypePathToEnum = (type: string): Domain.DebtTypeEnum => {
  switch (type) {
    case 'credit-card':
      return Domain.DebtTypeEnum.CREDIT_CARD;
    case 'medical':
      return Domain.DebtTypeEnum.MEDICAL_DEBT;
    case 'student-loan':
      return Domain.DebtTypeEnum.STUDENT_LOAN;
    case 'personal-loan':
      return Domain.DebtTypeEnum.PERSONAL_LOAN;
    case 'other':
      return Domain.DebtTypeEnum.OTHER;
    default:
      return Domain.DebtTypeEnum.OTHER;
  }
};

export const mapDebtEnumToTypePath = (type: Domain.DebtTypeEnum | undefined): string => {
  switch (type) {
    case Domain.DebtTypeEnum.CREDIT_CARD:
      return 'credit-card';
    case Domain.DebtTypeEnum.MEDICAL_DEBT:
      return 'medical';
    case Domain.DebtTypeEnum.STUDENT_LOAN:
      return 'student-loan';
    case Domain.DebtTypeEnum.PERSONAL_LOAN:
      return 'personal-loan';
    case Domain.DebtTypeEnum.OTHER:
      return 'other';
    default:
      return Domain.DebtTypeEnum.OTHER;
  }
};

export const mapDebtTypePathToAccountType = (type: string): string => {
  switch (type) {
    case 'credit-card':
      return 'CreditCard';
    case 'medical':
      return 'MedicalDebt';
    case 'student-loan':
      return 'StudentLoan';
    case 'personal-loan':
      return 'PersonalLoan';
    case 'other':
      return 'OtherDebt';
    default:
      return 'OtherDebt';
  }
};

export const mapDebtTypeToAddTitle = (type: Domain.DebtTypeEnum | undefined): string => {
  switch (type) {
    case Domain.DebtTypeEnum.CREDIT_CARD:
      return 'AddCreditCard';
    case Domain.DebtTypeEnum.MEDICAL_DEBT:
      return 'AddMedicalDebt';
    case Domain.DebtTypeEnum.STUDENT_LOAN:
      return 'AddStudentLoan';
    case Domain.DebtTypeEnum.PERSONAL_LOAN:
      return 'AddPersonalLoan';
    case Domain.DebtTypeEnum.OTHER:
      return 'AddOtherDebt';
    default:
      return 'AddOtherDebt';
  }
};

export const mapDebtTypeToType = (type: Domain.DebtTypeEnum | undefined): string => {
  switch (type) {
    case Domain.DebtTypeEnum.CREDIT_CARD:
      return 'CreditCard';
    case Domain.DebtTypeEnum.MEDICAL_DEBT:
      return 'MedicalDebt';
    case Domain.DebtTypeEnum.STUDENT_LOAN:
      return 'StudentLoan';
    case Domain.DebtTypeEnum.PERSONAL_LOAN:
      return 'PersonalLoan';
    case Domain.DebtTypeEnum.OTHER:
      return 'OtherDebt';
    default:
      return 'OtherDebt';
  }
};

export const mapDebtTypeToLabelNickname = (type: Domain.DebtTypeEnum): string => {
  switch (type) {
    case Domain.DebtTypeEnum.CREDIT_CARD:
      return 'CreditCardNickname';
    case Domain.DebtTypeEnum.MEDICAL_DEBT:
      return 'MedicalDebtNickname';
    case Domain.DebtTypeEnum.STUDENT_LOAN:
      return 'StudentLoanNickname';
    case Domain.DebtTypeEnum.PERSONAL_LOAN:
      return 'PersonalLoanNickname';
    case Domain.DebtTypeEnum.OTHER:
      return 'OtherDebtNickname';
    default:
      return 'OtherDebtNickname';
  }
};
