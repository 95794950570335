import { Button } from '@mui/material';
import React from 'react';

export declare type ButtonHeaderProps = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

const ButtonHeader: React.FC<ButtonHeaderProps> = ({ disabled, onClick, text }) => {
  return (
    <Button
      variant='contained'
      onClick={onClick}
      disabled={disabled === true}
      sx={{
        width: '200px',
        height: '40px',
        fontSize: '14px',
        fontWeight: 'regular',
        whiteSpace: 'nowrap',
      }}>
      {text}
    </Button>
  );
};

export default ButtonHeader;
