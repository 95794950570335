import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { waitFor } from '../utils';

export async function incomeReadyLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const isIncomeReady$ = resolver.resolveSubject(IncomeReadySubject);
  await waitFor(isIncomeReady$);
  return json('income loaded');
}
