import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';

export const KnowTheNumbers: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.ArtLaptopLarge />
        <Grid ml={3}>
          <Typography color='secondary' variant='p18Bold'>
            {t('KnowNumbers')}
          </Typography>
          <Typography mt={1} color='primary' variant='p16Bold'>
            {t('Nutshell')}
          </Typography>
        </Grid>
      </Row>
      <Typography variant='p16Bold' color='secondary'>
        {t('LiabilityVsValue')}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t('UnprotectedAssets')}
      </Typography>
    </Stack>
  );
};
