import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import BulletedList from '../../../../components/BulletedList';
import { Colors, Spacing } from '../../../../themes';
import { useNavigate } from 'react-router-dom';

export const CollisionInsurance: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.DisplayLargeCustomCollision3 />
        <Grid ml={3}>
          <Typography color='secondary' variant='p18Bold'>
            {t('CollisionInsurance')}
          </Typography>
        </Grid>
      </Row>
      <Grid container direction='row'>
        <Grid item direction='column' sx={{ width: '65%' }}>
          <Stack spacing={2}>
            <Typography variant='p16' color='secondary'>
              {t('CollisionInsuranceExpsensive')}
            </Typography>
            <Typography variant='p16' color='secondary'>
              {t('CollisionCoversDamage')}
            </Typography>
            <BulletedList
              style={{ marginTop: 2 }}
              children={[
                'You cause the accident.',
                'You are hit by an object.',
                'You’re hit by an uninsured motorist.',
                'You roll your car.',
                'Just note– if you don’t have collision coverage, you will have to pay out of pocket to repair damage to your car.',
              ]}
            />
          </Stack>
        </Grid>
        <Divider
          orientation='vertical'
          color={Colors.primaryLight}
          sx={{ borderColor: 'none', margin: '0px 20px' }}
          flexItem
        />
        <Grid item direction='column'>
          <Grid display='flex' direction='column' item xs={3}>
            <Stack spacing={Spacing.xxxs + 0.5}>
              <Box display='flex' alignItems='center'>
                <Svgs.ArtLightbulbSmall />
                <Typography color='secondary' variant='p18Bold' sx={{ marginLeft: 1 }}>
                  {t('Related')}
                </Typography>
              </Box>

              <Typography
                key={'/tools/car/collision-insurance-breakeven'}
                color='primary'
                variant='p16Bold'
                className='hover-clickable'
                sx={{ marginLeft: 1 }}
                onClick={() => navigate('/tools/car/collision-insurance-breakeven')}>
                {t('Collision Insurance Breakeven Tool')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
