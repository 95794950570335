import { createServiceModule, Scopes } from '@aesop-fables/containr';
import { ApiKeys } from './apis/ApiKeys';
import { AuthTokenApi } from './apis/AuthTokenApi';
import { PaymentApi } from './apis/PaymentApi';
import { PricebookApi } from './apis/PricebookApi';
import axios, { AxiosInstance } from 'axios';
import { domain } from '../configuration/Environment';
import { PlatformSwitchApi } from './apis/PlatformSwitchApi';
import { onFulfilled } from '@aesop-fables/containr-axios';
import { Api } from '@3nickels/data-modules';

export const nickelsApisKey = 'api/nickels';

export const useNickelsApis = createServiceModule(nickelsApisKey, (services) => {
  services.autoResolve<PaymentApi>(ApiKeys.Payment, PaymentApi, Scopes.Transient);
  services.autoResolve<PricebookApi>(ApiKeys.Pricebook, PricebookApi, Scopes.Transient);
  services.autoResolve<AuthTokenApi>(ApiKeys.AuthToken, AuthTokenApi, Scopes.Transient);
  services.autoResolve<PlatformSwitchApi>(
    ApiKeys.PlatformSwitch,
    PlatformSwitchApi,
    Scopes.Transient
  );
  services.factory<AxiosInstance>(
    ApiKeys.MobileAuthAxios,
    () => {
      const instance = axios.create({
        baseURL: `https://auth.${domain}/api/`,
        withCredentials: true,
        withXSRFToken: true,
      });
      instance.interceptors.request.use(onFulfilled(new Api.RequestTimestampInterceptor()));
      return instance;
    },
    Scopes.Transient
  );
});
