/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Grid, Typography } from '@mui/material';
import ContentView from '../layout/Content';
import { PersonalDetailsSummaryContent } from '../personal-details/summary/PersonalDetailsSummaryView';
import { useTranslation } from 'react-i18next';
import { Hooks } from '@3nickels/data-modules';
import SavedGuidesCard from './SavedGuidesCard';
import { useContentView } from '../layout/ContentViewContext';
import { useEffect } from 'react';
import RemoveAccountAction from './RemoveAccountAction';

const ProfileView: React.FC = () => {
  const { t } = useTranslation();
  const { sideNav } = useContentView();
  const { person } = Hooks.useCombinedSelfData();
  const guides = Hooks.useSavedGuides() ?? [];
  const { setSelectedTab } = useContentView();

  useEffect(() => {
    setSelectedTab('profile');

    return () => setSelectedTab(undefined);
  }, []);

  return (
    <ContentView sideNavMenuItems={sideNav()}>
      <Box>
        <Typography variant='p18Bold' color='primary'>
          {t('MyProfile')}
        </Typography>
        <Typography variant='p30Bold' color='secondary'>
          {(person?.preferredName?.length ?? 0) > 0
            ? person?.preferredName ?? ''
            : person?.firstName ?? ''}
        </Typography>
        <PersonalDetailsSummaryContent showSsi />
        <Grid mt={2}>
          <SavedGuidesCard savedGuides={guides} onEdit={() => {}} />
        </Grid>
        <RemoveAccountAction />
      </Box>
    </ContentView>
  );
};

export default ProfileView;
