import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import BulletedList from './BulletedList';
import Row from './Row';

interface IconContentRowProps {
  icon: JSX.Element;
  title: string;
  text: string;
  bulletItems?: string[];
}

export const IconContentRow: React.FC<IconContentRowProps> = ({
  icon,
  title,
  text,
  bulletItems,
}) => {
  return (
    <>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box sx={{ width: '30px', height: '30px' }}>{icon}</Box>
        <Grid ml={3}>
          <Typography color='primary' variant='p16Bold'>
            {t(title)}
          </Typography>
          <Typography mt={1} mb={bulletItems ? 1 : undefined} color='secondary' variant='p16'>
            {t(text)}
          </Typography>
        </Grid>
      </Row>
      {bulletItems && (
        <Row style={{ marginTop: 0 }}>
          <Box sx={{ width: '30px' }} />
          <Grid ml={3}>
            <BulletedList style={{ marginTop: '4px' }} children={bulletItems} />
          </Grid>
        </Row>
      )}
    </>
  );
};
