import { Grid, Table, TableBody, TableContainer, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { AllLessonsButton, AllLessonsButtonProps } from './LmsLayout';
import { Colors } from '../../themes';
import { Svgs } from '../../assets/svg';
import { Domain } from '@3nickels/data-modules';
import LmsUnitProgressCard from './LmsUnitProgressCard';
import ProgressBar from '../../components/ProgressBar';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

interface LmsSideViewProps extends AllLessonsButtonProps {
  unit?: Domain.UnitDto;
  lesson?: Domain.LessonDto;
}

const LmsSideView: React.FC<LmsSideViewProps> = ({
  expanded,
  setExpanded,
  course,
  unit,
  lesson,
  yourLessonsView,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (expanded && unit) {
      const element = document.getElementById(unit.unitId?.toString() ?? '');
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [expanded, unit]);

  const progressColor = useMemo(
    () => (course?.completionPercentage === 100 ? Colors.secondaryLight : Colors.primaryLight),
    [course?.completionPercentage]
  );

  const handleCourseClick = () => {
    navigate(`/learning/${course?.courseId}`);
  };

  if (!expanded) return <Grid />;

  return (
    <Grid className='lms-side-nav'>
      <Grid className='lms-side-nav-content'>
        <Grid className='lms-side-nav-header' onClick={handleCourseClick}>
          <Svgs.Ribbon width='30px' height='30px' />
          <Grid display='flex' flexDirection='column' rowGap='10px' flexGrow={1}>
            <Typography variant='p16Bold' color='primary'>
              {course?.name}
            </Typography>
            <ProgressBar
              progress={course?.completionPercentage ?? 0}
              color={progressColor}
              backgroundColor={Colors.primaryLightest}
            />
            <Grid container gap='10px'>
              <Typography
                variant='p12'
                color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                fontWeight='bold'>
                {course?.completionPercentage ?? 0}% {t('Complete')}
              </Typography>
              <Typography
                variant='p12'
                color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                fontWeight='normal'>
                |
              </Typography>
              <Typography
                variant='p12'
                color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                fontWeight='normal'>
                {course?.lessonsCompleted ?? 0}/{course?.totalLessons ?? 0} {t('Lessons')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableBody>
              {course?.units?.map((unit) => (
                <LmsUnitProgressCard unit={unit} selectedLesson={lesson} variant='compressed' />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        px='20px'
        py='25px'
        boxShadow='-10px -2px 10px 0 rgba(142, 148, 180, 0.23);'
        sx={{
          '& :hover': {
            cursor: 'pointer',
          },
        }}>
        <AllLessonsButton
          course={course}
          yourLessonsView={yourLessonsView}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </Grid>
    </Grid>
  );
};

export default LmsSideView;
