import { Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { firstValueFrom } from 'rxjs';

export async function mortgageLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const mortgages$ = resolver.resolveSubject(Data.Debt.CombinedMortgageDataSubject);
  const mortgages = await firstValueFrom(mortgages$);

  return json(mortgages ?? null);
}
