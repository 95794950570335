import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import PlanCard from './PlanCard';
import PlanFeaturesTable from './PlanFeaturesTable';
import Footnote from './Footnote';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { MobileScreenWrapper } from '../../mobile-redirect/MobileScreenWrapper';
import { Loading } from '../../../hooks/useLoading';
import { getCurrentPlan, getFootnoteElement, getRecentPlan, resolveHeaders } from './functions';

export const ChangePlanWrapper: React.FC = () => {
  const { isMobile, isTablet } = useDeviceType();
  const { threeNickelsProducts } = Hooks.useAvailableProducts();
  const headers = resolveHeaders(threeNickelsProducts ?? []);
  const mobile = isMobile || isTablet;
  const ChangePlan = withLayoutMeta(ChangePlanView, meta);
  const removePadding = headers.length > 1 && threeNickelsProducts;

  if (mobile)
    return (
      <MobileScreenWrapper
        childrenWithoutPadding={
          removePadding && <PlanFeaturesTable products={threeNickelsProducts} />
        }>
        <ChangePlan removePadding={removePadding} />
      </MobileScreenWrapper>
    );
  return <ChangePlan />;
};

const ChangePlanView: React.FC<{ removePadding?: boolean }> = ({ removePadding }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const pathnameSegments = location.pathname.split('/');
  const changePlanView = pathnameSegments.includes('change-plan');
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const subscriptionInit = Hooks.useSubscriptionInfoInitialized();
  const legalDocs = Hooks.useTermsAndConditions();
  const freeTrialStatus = Hooks.useFreeTrialStatus();
  const inAdviceFreeTrial = freeTrialStatus ? freeTrialStatus.active : false;
  const { threeNickelsProducts, orgDoesNotOfferFreePricebook } = Hooks.useAvailableProducts();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const currentPlan = getCurrentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  const recentPlan = getRecentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  if (threeNickelsProducts) {
    threeNickelsProducts.sort((a, b) => {
      if (a === recentPlan) {
        return -1;
      }
      if (b === recentPlan) {
        return 1;
      }
      if (a.prices[0].price === 0) {
        return 1;
      }
      if (b.prices[0].price === 0) {
        return -1;
      }
      return a.pricebook.id - b.pricebook.id;
    });
  }

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  if (!subscriptionInit) {
    return <Loading />;
  }

  let footnoteCounter = 0;
  const footnoteTexts: string[] = [];

  return (
    <Box>
      <Grid container flexDirection='column'>
        {mobile ? (
          <Grid mb={2} display='flex' direction='row' sx={{ alignItems: 'center' }}>
            <Svgs.IconArrowLeft onClick={() => navigate('../')} />
            <Typography ml={1} color='primary' variant='p22Bold'>
              {changePlanView ? t('ChangePlan') : t('SelectPlan')}
            </Typography>
          </Grid>
        ) : (
          <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
            {changePlanView ? t('ChangePlan') : t('SelectPlan')}
          </Typography>
        )}
      </Grid>
      <Grid container={mobile ? undefined : true} direction='row' spacing={mobile ? undefined : 3}>
        {threeNickelsProducts &&
          threeNickelsProducts.map((plan, i) => {
            const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
              footnoteCounter,
              plan,
              inAdviceFreeTrial,
              subscription
            );
            if (footnoteText) {
              footnoteCounter = counter;
              footnoteTexts.push(footnoteText);
            }
            return (
              <Grid
                item
                mt={!mobile ? 0 : i === 0 ? 0 : 2}
                key={plan.pricebook.id}
                display='flex'
                direction={mobile ? 'column' : 'row'}
                sx={{ justifyContent: mobile ? 'center' : undefined }}>
                <PlanCard
                  plan={plan}
                  currentPlan={currentPlan}
                  pricebookMetadata={pricebookMetadata}
                  subscriptionInfo={subscription}
                  threeNickelsProducts={threeNickelsProducts}
                  orgDoesNotOfferFreePricebook={orgDoesNotOfferFreePricebook}
                  inAdviceFreeTrial={inAdviceFreeTrial}
                  footnoteIndex={footnoteText ? footnoteCounter : undefined}
                  mobileCardStyle={{
                    boxShadow: '0 4px 7px 1px rgba(30, 41, 105, 0.2)',
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
      <Stack spacing={1} mt={3}>
        {footnoteTexts.map((text, index) => (
          <Grid container direction='row' key={index}>
            <Footnote index={index} text={text} termsApi={termsApi} legalDocs={legalDocs} />
          </Grid>
        ))}
      </Stack>
      {!removePadding && threeNickelsProducts && (
        <PlanFeaturesTable products={threeNickelsProducts} />
      )}
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;
