import React from 'react';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../components/panels/CardDisplay';
import { Domain } from '@3nickels/data-modules';
import { SummaryCardProps } from '../../account-details/Types';
import { t } from 'i18next';

export interface SocialSecurityCardProps extends SummaryCardProps {
  person?: Domain.PersonData;
  spouse?: Domain.SpouseData;
}

const SocialSecurityCard: React.FC<SocialSecurityCardProps> = (props) => {
  return (
    <CardDisplay headerKey='SocialSecurityIncome' actionContent='Edit' actionClick={props.onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('IncludeYourEstimatedSSI')}
          </Typography>
          <Typography color='secondary'>{props.person?.includeSsi ? 'Yes' : 'No'}</Typography>
        </Grid>
        {props.person?.includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('IncludeSpousesEstimatedSSI')}
            </Typography>
            <Typography color='secondary'>{props.spouse?.includeSsi ? 'Yes' : 'No'}</Typography>
          </Grid>
        )}
        {!props.person?.includeSpouse && <Grid item xs />}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
      </Grid>
    </CardDisplay>
  );
};

export default SocialSecurityCard;
