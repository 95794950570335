import React from 'react';
import { Data } from '@3nickels/data-modules';
import BeneficiaryPicker from '../../../../../components/form/BeneficiaryPicker';

export type CollegeSavingsPlanDetailsFormProps = {
  planDetails?: Data.CollegeSavingsPlans.CollegeSavingsPlanDetailsFormData;
  onSubmit: (values: Data.CollegeSavingsPlans.CollegeSavingsPlanDetailsFormData) => Promise<void>;
  addDependentOpen: boolean;
  setAddDependentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editing?: boolean;
};

const CollegeSavingsPlanDetailsForm: React.FC<CollegeSavingsPlanDetailsFormProps> = ({
  planDetails,
  ...props
}) => {
  return (
    <BeneficiaryPicker
      label='WhoIsThisCollegeSavingsPlanFor'
      defaultValues={planDetails}
      {...props}
    />
  );
};

export default CollegeSavingsPlanDetailsForm;
