import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GroupPanels, { Group } from '../../../../components/GroupPanels';
import { Svgs } from '../../../../assets/svg';
import { Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { Colors } from '../../../../themes';
import UniversityCompleteCard from './UniversityCompleteCard';
import RepertoireCard from './RepertoireCard';

const ExploreSection: React.FC = () => {
  const { t } = useTranslation();

  const universityEnabled = Hooks.useUniversityEnabled();
  const universityCourse = Hooks.useUniversityCourse();
  const lmsCourses = Hooks.useAllCourses();

  const noLmsCourses = (lmsCourses?.length ?? 0) === 0;
  const totalCourseCount = (lmsCourses?.length ?? 0) + (universityEnabled ? 1 : 0);
  const universityComplete = universityCourse?.progress === 100;
  const allLmsCoursesComplete =
    lmsCourses?.every((course) => course.completionPercentage === 100) ?? true;
  const allCoursesComplete = universityEnabled
    ? universityComplete && allLmsCoursesComplete
    : allLmsCoursesComplete;

  const lifestyleProducts = Hooks.useLifestyleProducts();

  const groups: Group[] = useMemo(() => {
    const g: Group[] = [];
    if (universityEnabled && noLmsCourses && universityComplete) {
      g.push({
        textKey: '3NickelsU',
        url: '/learning/university',
        style: {
          background: `linear-gradient(${Colors.secondaryLight} 0 0) bottom left / 100% 8px no-repeat, white`,
        },
        content: <UniversityCompleteCard />,
      });
    }

    if (allCoursesComplete) {
      g.push({
        textKey: 'Learning',
        url:
          totalCourseCount === 1
            ? universityEnabled
              ? '/learning/university'
              : `/learning/courses/${lmsCourses?.[0].courseId}`
            : '/learning',
        content: <RepertoireCard />,
      });
    }
    g.push({
      textKey: 'ToolsAndChecklists',
      subTextKey: 'Explore12ModulesOfTools',
      icon: <Svgs.DisplaySmallCustomCalculator />,
      url: '/tools',
    });

    if ((lifestyleProducts?.length ?? 0) > 0) {
      g.push({
        textKey: 'Lifestyle',
        subTextKey: "Here'sAnIdeaForYourLifeNeeds",
        icon: <Svgs.DisplaySmallCustomLegacy />,
        url: '/lifestyle',
      });
    }

    return g;
  }, [universityEnabled, universityCourse, lifestyleProducts, lmsCourses]);

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Explore')}
      </Typography>
      <GroupPanels variant='Popover' groups={groups} groupsPerRow={2} />
    </Grid>
  );
};

export default ExploreSection;
