import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepThree: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={3}
      headerIcon={<Svgs.ArtPiggyBank height='90px' width='auto' />}
      headerText='StartSavingEarly'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('ThereAreSeveralThingsYouWantToStartSavingForEarly')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('DownPaymentThisAmountCouldBeDifferent'),
              t('Closingcosts'),
              t('TheTransitionPeriod'),
              t('MostImportantlyFindOutHowMuchHouse'),
            ]}
          />
        </>
      }
      related={[{ textKey: 'HowMuchHouseCanIAfford', url: '/tools/house/how-much-house' }]}
    />
  );
};

export default StepThree;
