import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';

const HomePurchasePriceHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('HomePurchasePrice')}>
      <HelpPopoverText>{t('LoanAmountCanAffectRate')}</HelpPopoverText>
    </HelpPopover>
  );
};

export default HomePurchasePriceHelp;
