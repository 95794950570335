import { Hooks } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';
import { mapRetirementStepToPath } from '../pages/account-details/Types';

export function useRetirementPlanPath() {
  const navigationState = Hooks.useRetirementWizardNavigation();
  const [path, setPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (navigationState) {
      const next = mapRetirementStepToPath(navigationState.nextStep);
      setPath(next);
    }
  }, [navigationState]);

  return path;
}
