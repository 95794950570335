import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { GoalBannerIcon } from '../../../../goals';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { GoalCardProps } from './Types';
import GoalCardHeader from './components/GoalCardHeader';

const VolunteeringGoalCard: React.FC<GoalCardProps> = ({ goal }) => {
  const { goalPlan, advisedGoalPlan } = goal;
  const target = (advisedGoalPlan ?? goalPlan) as Domain.VolunteerGoalHolder; // is this necessary?

  return (
    <CollapsiblePanel
      variant='shadow'
      header={<GoalCardHeader goal={goal} hideDate />}
      expandIconPosition='end'
      icon={<GoalBannerIcon type={goalPlan.goalType} />}>
      <Grid container gap='10px'>
        {target.items.map((item) => (
          <Grid key={item.id} container className='goal-card-details-summary' px='10px'>
            <Grid container display='flex' justifyContent='space-between'>
              <Typography variant='p16Bold' color='primary'>
                {item.nameOfPlace}
              </Typography>
              <Typography variant='p16' color='secondary'>
                {item.numberOfHours} hr{item.numberOfHours === 1 ? '' : 's'}/
                {item.frequency === Domain.VolunteeringFrequencyEnum.Weekly ? 'wk' : 'mo'}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </CollapsiblePanel>
  );
};

export default VolunteeringGoalCard;
