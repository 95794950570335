import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../components/form/TextInput';
import { Spacing } from '../../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type IraContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.AccountContributionsFormData;
  planType: Domain.IraPlanTypeEnum;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const IraContributionsForm: React.FC<IraContributionsFormProps> = ({ contributions, planType }) => {
  const { formState } = useFormContext();
  const [contributionType, setContributionType] = useState<
    keyof Data.InvestmentAccounts.AccountContributionsFormData | undefined
  >(undefined);

  useEffect(() => {
    const type = Domain.getIraContributionType(planType);
    setContributionType(type);
  }, [planType]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        {contributionType && (
          <TextInput<Data.InvestmentAccounts.AccountContributionsFormData>
            error={formState.errors[contributionType] !== undefined}
            helperText={formState.errors[contributionType]?.message?.toString()}
            defaultValue={contributions?.[contributionType]}
            label='AnnualSavings'
            name={contributionType}
            type='dollar'
          />
        )}
      </Stack>
    </Box>
  );
};

export default IraContributionsForm;
