import { Card, Divider, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Colors, Spacing } from '../../../../themes';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Row } from '../../../../components';

export const StepTwo: React.FC = () => {
  return (
    <ChecklistTemplate
      stepNumber={2}
      headerIcon={<Svgs.DisplayLargeCustomSaleProceeds />}
      headerText={'HomeSaleNetProceeds'}
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('UnderstandCurrentValue')}
          </Typography>
          <Typography mt={2} mb={2} color='secondary' variant='p16'>
            {t('SubtractSellingCost')}
          </Typography>
          <Card
            sx={{
              borderRadius: '4px',
              border: `solid 1px ${Colors.primaryLighter}`,
              backgroundColor: Colors.secondaryLighter,
              padding: '10px',
              width: '60%',
              boxShadow: 'none',
              alignSelf: 'center',
            }}>
            <Row style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography variant='p16' color='secondary'>
                {t('HomeSalePrice')}
              </Typography>
              <Typography variant='p16Bold' color='primary'>
                $300,000
              </Typography>
            </Row>
            <Row style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography variant='p16' color='secondary'>
                {t('SellingCost')}
              </Typography>
              <Typography variant='p16Bold' color='primary'>
                – $30,000
              </Typography>
            </Row>
            <Divider sx={{ width: '100%' }} color={Colors.primaryLight} />
            <Row style={{ justifyContent: 'space-between', marginTop: '10px' }}>
              <Typography variant='p16' color='secondary'>
                {t('NetProceeds')}
              </Typography>
              <Typography variant='p16Bold' color='primary'>
                $270,000
              </Typography>
            </Row>
          </Card>
        </Stack>
      }
      related={[{ textKey: 'HomeSaleNetProceeds', url: '/tools/house/home-sale-net-proceeds' }]}
    />
  );
};
