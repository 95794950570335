import { WizardFooterProps } from '../components/form/WizardFooter';

export interface EditViewProps {
  name?: string;
  isSpouse?: boolean;
  editing?: boolean;
  onBack?: () => void;
}

export const getWizardFooterProps = (nextLabel: string, editing?: boolean): WizardFooterProps => {
  if (editing) {
    return {
      nextLabel: 'Save',
      backLabel: 'Cancel',
    };
  }

  return {
    nextLabel,
  };
};
