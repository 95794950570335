import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolHeader from '../../../../components/ToolHeader';
import BulletedList from '../../../../components/BulletedList';
import GotchaItem from './GotchaItem';

const CreditCardGotchasView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container flexDirection='column'>
        <ToolHeader title='CreditCardGotchas' />
        <GotchaItem
          icon={<Svgs.ArtMoneyLarge />}
          title='AlwaysPayMoreThanTheMinimumPayment'
          body='TheMoreYouPay'
        />
        <GotchaItem
          icon={<Svgs.ArtCreditCard style={{ marginBottom: '10px' }} />}
          title='AlwaysPayOnTime'
          body={
            <>
              <Typography mb={2} variant='p16' color='secondary'>
                {t('LatePaymentCanResultIn')}
              </Typography>
              <BulletedList
                color='secondary'
                style={{ marginTop: '6px' }}
                children={[
                  t('ALateFeeOf25OrMore'),
                  t('AnIncreaseInInterestCharges'),
                  t('ABadCreditScore'),
                  t('ARaisedAPR'),
                ]}
              />
            </>
          }
        />
        <GotchaItem
          icon={<Svgs.ArtCreditScore style={{ margin: '10px 0px' }} />}
          title='KnowYourCreditLimit'
          body={
            <>
              <Typography mb={2} variant='p16' color='secondary'>
                {t('KeepYourCreditUsageUnder30')}
              </Typography>
              <Typography variant='p16' color='secondary'>
                {t('ForExampleIfYouHaveACombinedCreditLimit')}
              </Typography>
            </>
          }
        />
        <GotchaItem
          icon={<Svgs.ArtHandshake style={{ margin: '5px 0px' }} />}
          title='KeepUnusedAccountsOpen'
          body='SinceCreditScoresDependOnYourRatio'
        />
        <GotchaItem
          icon={
            <Svgs.ArtLightbulbLarge width='71px' height='auto' style={{ marginBottom: '12px' }} />
          }
          title='UnderstandLowOrZeroIntroductoryInterestRates'
          body='AnyBalanceOwedAfterTheInterestFeePeriod'
        />
        <GotchaItem
          icon={<Svgs.ArtTrophySmall2 style={{ marginBottom: '12px' }} />}
          title='RewardProgramsArentAlwaysTheBest'
          body='RewardProgramsAreOnlyGoodIf'
        />
        <GotchaItem
          icon={<Svgs.ArtCreditCard2 style={{ marginBottom: '13px' }} />}
          title='AvoidApplyingForTooManyCreditCardsAllAtOnce'
          body='EachApplicationTriggersAnInquiry'
        />
      </Grid>
    </Box>
  );
};

const meta = {} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CreditCardGotchasView, meta);
