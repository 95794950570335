import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function HomeSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <g fill={props.fill} fillRule='nonzero'>
          <path d='m22.11 11.416-8.943-8.15a1.033 1.033 0 0 0-1.384 0l-8.944 8.15c-.32.287-.422.726-.27 1.131a1 1 0 0 0 .945.658H4.95v8.168c0 .32.27.59.59.59h4.91c.322 0 .592-.27.592-.59V16.41h2.885v4.962c0 .32.27.59.59.59h4.911c.321 0 .591-.27.591-.59v-8.168h1.434a1 1 0 0 0 .945-.658 1.083 1.083 0 0 0-.286-1.13zM19.832 4.177h-3.931l4.522 4.118V4.768c0-.338-.27-.591-.59-.591z' />
        </g>
      </g>
    </SvgIcon>
  );
}

export function Home2Svg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <g fillRule='nonzero'>
            <path
              d='M26.533 13.7 15.8 3.919a1.24 1.24 0 0 0-1.66 0l-10.733 9.78a1.22 1.22 0 0 0-.324 1.357 1.2 1.2 0 0 0 1.134.79h1.721v9.801c0 .385.324.709.71.709h5.892a.717.717 0 0 0 .709-.709v-5.953h3.462v5.953c0 .385.324.709.71.709h5.892a.717.717 0 0 0 .709-.709v-9.8h1.72a1.2 1.2 0 0 0 1.135-.79 1.3 1.3 0 0 0-.344-1.357z'
              fill='#9A5238'
            />
            <path
              d='M23.799 5.012H19.08l5.427 4.941V5.721a.704.704 0 0 0-.71-.709z'
              fill='#1E2969'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

HomeSvg.defaultProps = {
  fill: '#fff',
};
