import { Typography, Button, Card, Grid, Stack, Drawer, Box } from '@mui/material';
import React from 'react';
import { ChargeData } from '../../../api/apis/PaymentApi';
import { Spacer, Row } from '../../../components';
import { formatDateMMDDYYYY, formatDecimalDollars } from '../../../helpers/utilityFunctions';
import { t } from 'i18next';
import { Spacing } from '../../../themes';

interface PastInvoiceDrawerProps {
  stripeTransaction: ChargeData;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PastInvoiceDrawer: React.FC<PastInvoiceDrawerProps> = ({
  stripeTransaction,
  drawerOpen,
  setDrawerOpen,
}) => {
  const receiptUrl = stripeTransaction?.charge.receiptUrl ?? '';

  const onViewReceipt = () => {
    window.open(receiptUrl);
  };

  return (
    <Drawer
      anchor='right'
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
      }}>
      <Box p={Spacing.xxs}>
        <Typography variant='p30Bold' color='primary'>
          {t('ViewPastInvoice')}
        </Typography>
        {receiptUrl ? (
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color='secondary' variant='p16' width='80%'>
              {t('YouCanEasilyViewAndDownloadYourReceipt')}
            </Typography>
            <Button onClick={onViewReceipt} color='primary' style={{ minWidth: 'fit-content' }}>
              {t('ViewReceipt')}
            </Button>
          </Row>
        ) : (
          <>
            <Spacer height='xxs' />
            <Typography color='error' variant='p16' width='90%'>
              {t('YourReceiptHasntBeenGenerated')}
            </Typography>
          </>
        )}
        <Spacer height='sm' />

        <Stack spacing='30px'>
          <Details
            header='InvoiceDetails'
            data={[
              { key: 'InvoiceNumber', value: stripeTransaction?.charge.invoiceId },
              {
                key: 'InvoiceDate',
                value: formatDateMMDDYYYY(stripeTransaction?.charge.datePaid ?? ''),
              },
            ]}
          />
          <Details
            header='ProductDetails'
            data={[
              { key: 'ProductName', value: stripeTransaction?.charge.description },
              {
                key: 'TotalPrice',
                value: formatDecimalDollars((stripeTransaction?.charge.amountPaid ?? 0) / 100),
                boldValue: true,
              },
            ]}
          />
          <Details
            header='BillingDetails'
            data={[
              { key: 'Name', value: stripeTransaction?.charge.name },
              { key: 'Email', value: stripeTransaction?.charge.email },
              {
                key: 'ZipCode',
                value: stripeTransaction?.paymentInfo.zipCode,
                hide: !stripeTransaction?.paymentInfo.zipCode,
              },
              {
                key: 'Country',
                value: stripeTransaction?.paymentInfo.country,
                hide: !stripeTransaction?.paymentInfo.country,
              },
            ]}
          />
        </Stack>

        <Spacer height='xxxl' />
        <Button color='primary' variant='outlined' onClick={() => setDrawerOpen(false)}>
          Close
        </Button>
      </Box>
    </Drawer>
  );
};

interface KeyValuePair {
  key: string;
  value: string;
  boldValue?: boolean;
  hide?: boolean;
}

interface DetailsProps {
  header: string;
  data: KeyValuePair[];
}

const Details: React.FC<DetailsProps> = ({ header, data }) => {
  return (
    <Grid className='past-invoice'>
      <Typography variant='p18Bold' color='secondary'>
        {t(`${header}`)}
      </Typography>
      <Card>
        {data.map((item, index) => (
          <Grid item key={index}>
            {!item.hide && (
              <Grid
                container
                flexDirection='row'
                alignItems='center'
                mt={index !== 0 ? '10px' : undefined}>
                <Grid item width='30%'>
                  <Typography variant='p16Bold' color='primary'>
                    {t(`${item.key}`)}
                  </Typography>
                </Grid>
                <Grid item width='70%'>
                  <Typography variant={item.boldValue ? 'p16Bold' : 'p16'} color='secondary'>
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Card>
    </Grid>
  );
};

export default PastInvoiceDrawer;
