import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useField } from 'react-final-form';

export interface FormInputProps<T> extends React.PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputStyle?: any;
  fieldName: keyof T;
  placeholder?: string;
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  name?: string;
  autoFocus?: boolean;
  id?: string;
  validate?: (x: string) => undefined | string;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const FormInput = <T extends unknown>(props: FormInputProps<T>) => {
  const {
    inputStyle,
    fieldName,
    required,
    name,
    validate,
    placeholder,
    initialValue,
    disabled,
    readonly,
    maxLength,
    autoFocus,
  } = props;
  const inputRef = useRef<typeof TextField>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { input, meta } = useField<string>(fieldName.toString(), {
    initialValue,
    validate: (value: string) =>
      !required || value ? (validate ? validate(value) : undefined) : 'Required',
    subscription: { value: true, touched: true, error: true },
  });

  const passwordInput = fieldName === 'password' || fieldName === 'rePassword';

  return (
    <TextField
      inputRef={inputRef}
      inputProps={{
        maxLength,
        type: `${(showPassword && passwordInput) || !passwordInput ? 'text' : 'password'}`,
      }}
      InputProps={
        passwordInput
          ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    color='primary'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : undefined
      }
      autoFocus={autoFocus}
      onFocus={(event) => {
        if (autoFocus && !event.target?.hasAttribute('data-autoFocused')) {
          event.target?.setSelectionRange(event.target.value.length, event.target.value.length);
          event.target?.setAttribute('data-autoFocused', 'true');
        }
      }}
      size='small'
      name={name}
      variant='outlined'
      style={inputStyle}
      placeholder={placeholder}
      value={!input.value ? '' : input.value.toString()}
      onChange={(v) => input.onChange(v)}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched ? meta.error : undefined}
      disabled={disabled || readonly}
    />
  );
};

export default FormInput;
