import { Typography } from '@mui/material';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { AdviceUpsellFooterCard } from './AdviceUpsellFooterCard';
import { AdviceUpsellHeaderCard } from './AdviceUpsellHeaderCard';
import { FreeCarousel } from '../FreeCarousel';

export const AdviceUpsellView: React.FC = () => {
  return (
    <>
      <AdviceUpsellHeaderCard />

      <Typography mt={5} mb={3} variant='p18Bold' color='primary'>
        Here’s What You Get!
      </Typography>

      <FreeCarousel variant='default' />

      <SubscriptionDetails sx={{ padding: '38px', paddingBottom: '45px', mx: '18%' }} />

      <AdviceUpsellFooterCard />
    </>
  );
};
