import AccountDetailsView from '../pages/account-details/AccountDetailView';
import ManuallyAddAnAccount from '../pages/account-details/accounts/ManuallyAddAccount';
import AccountTypeView from '../pages/account-details/link-account/account-type/AccountTypeView';
import DebtBasicsEditView from '../pages/account-details/debt/basics/DebtBasicsEditView';
import DebtDetailsEditView from '../pages/account-details/debt/details/DebtDetailsEditView';
import DebtSummaryView from '../pages/account-details/debt/summary/DebtSummaryView';
import CashAccountBasicsEditView from '../pages/account-details/cash-account/basics/CashAccountBasicsEditView';
import CashAccountSummaryView from '../pages/account-details/cash-account/summary/CashAccountSummaryView';
import CarBasicsEditView from '../pages/account-details/car/basics/CarBasicsEditView';
import CarDetailsEditView from '../pages/account-details/car/details/CarDetailsEditView';
import CarSummaryView from '../pages/account-details/car/summary/CarSummaryView';
import HomeBasicsEditView from '../pages/account-details/home/basics/HomeBasicsEditView';
import HomeDetailsEditView from '../pages/account-details/home/details/HomeDetailsEditView';
import HomeOtherCostsEditView from '../pages/account-details/home/other-costs/HomeOtherCostsEditView';
import HomeSummaryView from '../pages/account-details/home/summary/HomeSummaryView';
import BrokerageBasicsEditViewWrapper from '../pages/account-details/investment-account/brokerage-account/basics/BrokerageAccountBasicsEditView';
import BrokerageAccountInvestmentsEditView from '../pages/account-details/investment-account/brokerage-account/investments/BrokerageAccountInvestmentsEditView';
import BrokerageAccountSummaryView from '../pages/account-details/investment-account/brokerage-account/summary/BrokerageAccountSummaryView';
import CollegeSavingsPlanBasicsEditView from '../pages/account-details/investment-account/college-savings-plan/basics/CollegeSavingsPlanBasicsEditView';
import CollegeSavingsPlanDetailsEditView from '../pages/account-details/investment-account/college-savings-plan/details/CollegeSavingsPlanDetailsEditView';
import HSABasicsEditView from '../pages/account-details/investment-account/hsa/basics/HSABasicsEditView';
import HSAContributionsEditView from '../pages/account-details/investment-account/hsa/contributions/HSAContributionsEditView';
import BusinessEditView from '../pages/account-details/investment-account/business/BusinessEditView';
import OtherAssetEditView from '../pages/account-details/investment-account/other-asset/OtherAssetEditView';
import RealEstateBasicsEditView from '../pages/account-details/investment-account/real-estate/basics/RealEstateBasicsEditView';
import RealEstateDetailsEditView from '../pages/account-details/investment-account/real-estate/details/RealEstateDetailsEditView';
import CollegeSavingsPlanSummaryView from '../pages/account-details/investment-account/college-savings-plan/summary/CollegeSavingsPlanSummaryView';
import HSASummaryView from '../pages/account-details/investment-account/hsa/summary/HSASummaryView';
import BusinessSummaryView from '../pages/account-details/investment-account/business/BusinessSummaryView';
import RealEstateSummaryView from '../pages/account-details/investment-account/real-estate/summary/RealEstateSummaryView';
import OtherAssetSummaryView from '../pages/account-details/investment-account/other-asset/OtherAssetSummaryView';
import AccountOwnerEditView from '../pages/account-details/link-account/account-owner/AccountOwnerEditView';
import ManualImportAccountView from '../pages/account-details/link-account/import-account/ManualImportAccountView';
import ImportAccountView from '../pages/account-details/link-account/import-account/ImportAccountView';
import ChooseInstitutionView from '../pages/account-details/link-account/choose-institution/ChooseInstitutionView';
import HSAInvestmentsEditView from '../pages/account-details/investment-account/hsa/investments/HSAInvestmentsEditView';
import RetirementPlanBasicsEditView from '../pages/account-details/retirement-plan/basics/RetirementPlanBasicsEditView';
import RetirementPlanContributionsEditView from '../pages/account-details/retirement-plan/contributions/RetirementPlanContributionsEditView';
import RetirementPlanEmployerContributionsEditView from '../pages/account-details/retirement-plan/employer-contributions/RetirementPlanEmployerContributionsEditView';
import RetirementPlanInvestmentsEditView from '../pages/account-details/retirement-plan/investments/RetirementPlanInvestmentsEditView';
import RetirementPlanSummaryView from '../pages/account-details/retirement-plan/summary/RetirementPlanSummaryView';
import PensionPlanBasicsEditView from '../pages/account-details/pension/basics/PensionPlanBasicsEditView';
import PensionPlanDetailsEditView from '../pages/account-details/pension/details/PensionPlanDetailsEditView';
import PensionPlanSummaryView from '../pages/account-details/pension/summary/PensionPlanSummaryView';
import { Backdrop } from '@mui/material';
import { Loading } from '../hooks/useLoading';
import HousingTypeView from '../pages/account-details/link-account/housing-type/HousingTypeView';
import DuplicateAccountView from '../pages/account-details/retirement-plan/DuplicateAccountView';
import MyInstitutionsView from '../pages/account-details/link-account/choose-institution/MyInstitutionsView';

export const AccountDetailsRoutes = [
  {
    index: true,
    element: <AccountDetailsView />,
  },

  // Manual Account
  {
    path: 'manual-account',
    element: <ManuallyAddAnAccount />,
  },
  // Link Account
  {
    path: 'link-account',
    children: [
      {
        index: true,
        element: (
          <Backdrop open>
            <Loading />
          </Backdrop>
        ),
      },
      {
        path: 'choose-owner',
        element: <AccountOwnerEditView />,
      },
      {
        path: 'choose-institution',
        element: <ChooseInstitutionView />,
      },
      {
        path: 'my-institutions',
        element: <MyInstitutionsView />,
      },
      {
        path: 'account-type',
        element: <AccountTypeView />,
      },
      {
        path: 'mortgage-type',
        element: <HousingTypeView />,
      },
      {
        path: 'import-account',
        element: <ImportAccountView />,
      },
      {
        path: 'manual-import',
        element: <ManualImportAccountView />,
      },
    ],
  },
  // Retirement
  {
    path: 'retirement-savings-plan/duplicate/:type/:id',
    element: <DuplicateAccountView />,
  },
  {
    path: 'retirement-savings-plan/basics/:type?/:id?',
    element: <RetirementPlanBasicsEditView />,
  },
  {
    path: 'retirement-savings-plan/contributions/:type/:id',
    element: <RetirementPlanContributionsEditView />,
  },
  {
    path: 'retirement-savings-plan/employer-contributions/:type/:id',
    element: <RetirementPlanEmployerContributionsEditView />,
  },
  {
    path: 'retirement-savings-plan/investments/:type/:id',
    element: <RetirementPlanInvestmentsEditView />,
  },
  {
    path: 'retirement-savings-plan/summary/:type/:id',
    element: <RetirementPlanSummaryView />,
  },
  {
    path: 'cash-account/basics/:id?',
    element: <CashAccountBasicsEditView />,
  },
  { path: 'cash-account/summary/:id', element: <CashAccountSummaryView /> },
  {
    path: 'brokerage-account/basics/:id?',
    element: <BrokerageBasicsEditViewWrapper />,
  },
  {
    path: 'brokerage-account/investments/:id',
    element: <BrokerageAccountInvestmentsEditView />,
  },
  {
    path: 'brokerage-account/summary/:id',
    element: <BrokerageAccountSummaryView />,
  },
  {
    path: 'college-savings-plan/basics/:id?',
    element: <CollegeSavingsPlanBasicsEditView />,
  },
  {
    path: 'college-savings-plan/details/:id',
    element: <CollegeSavingsPlanDetailsEditView />,
  },
  {
    path: 'college-savings-plan/summary/:id',
    element: <CollegeSavingsPlanSummaryView />,
  },
  {
    path: 'health-savings-account/basics/:id?',
    element: <HSABasicsEditView />,
  },
  {
    path: 'health-savings-account/contributions/:id',
    element: <HSAContributionsEditView />,
  },
  {
    path: 'health-savings-account/investments/:id',
    element: <HSAInvestmentsEditView />,
  },
  {
    path: 'health-savings-account/summary/:id',
    element: <HSASummaryView />,
  },
  { path: 'business/basics/:id?', element: <BusinessEditView /> },
  { path: 'business/summary/:id', element: <BusinessSummaryView /> },
  {
    path: 'investment-real-estate/basics/:id?',
    element: <RealEstateBasicsEditView />,
  },
  {
    path: 'investment-real-estate/details/:id',
    element: <RealEstateDetailsEditView />,
  },
  {
    path: 'investment-real-estate/summary/:id',
    element: <RealEstateSummaryView />,
  },
  { path: 'other-asset/basics/:id?', element: <OtherAssetEditView /> },
  { path: 'other-asset/summary/:id', element: <OtherAssetSummaryView /> },
  { path: 'car/basics/:id?', element: <CarBasicsEditView /> },
  { path: 'car/details/:id', element: <CarDetailsEditView /> },
  { path: 'car/summary/:id', element: <CarSummaryView /> },
  { path: 'home/basics/:id?', element: <HomeBasicsEditView /> },
  { path: 'home/details/:id', element: <HomeDetailsEditView /> },
  { path: 'home/other-costs/:id', element: <HomeOtherCostsEditView /> },
  { path: 'home/summary/:id', element: <HomeSummaryView /> },
  { path: 'debt/basics/:type/:id?', element: <DebtBasicsEditView /> },
  { path: 'debt/details/:type/:id', element: <DebtDetailsEditView /> },
  { path: 'debt/summary/:type/:id', element: <DebtSummaryView /> },
  {
    path: 'pension-plan/basics/:id?',
    element: <PensionPlanBasicsEditView />,
  },
  { path: 'pension-plan/details/:id?', element: <PensionPlanDetailsEditView /> },
  { path: 'pension-plan/summary/:id', element: <PensionPlanSummaryView /> },
];
