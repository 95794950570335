import { Card, Grid } from '@mui/material';
import { Svgs } from '../assets/svg';
import { PropsWithChildren } from 'react';
import Tippy from '@tippyjs/react';

interface PopupInfoCardProps extends PropsWithChildren {
  renderIcon?: boolean;
  icon?: React.ReactNode;
  popupContext: React.ReactNode;
}

const PopupInfoCard: React.FC<PopupInfoCardProps> = ({ children, ...props }) => {
  const renderIcon = props.renderIcon ?? true;
  const icon = props.icon ?? <Svgs.ArtLightbulbSmall />;
  return (
    <Grid className='popup-info-card-border popup-info-card'>
      <Tippy placement='left' content={props.popupContext} theme='light'>
        <Card>
          {renderIcon && icon}
          {children}
        </Card>
      </Tippy>
    </Grid>
  );
};

export default PopupInfoCard;
