import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const SearchForCharityCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomSearch />}
      label={t('SearchForCharity')}
      content={t('WeveJoinedWithCharityNavigator')}
    />
  );
};

export default SearchForCharityCard;
