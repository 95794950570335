import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { LmsLayoutMeta } from '../../LmsLayout';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ContentCopy from './ContentCopy';
import ContentHeader from './ContentHeader';
import { useObservableQuery } from '@aesop-fables/scrinium';

export const LmsContentViewWrapper: React.FC = () => {
  const { courseId, unitId, lessonId } = useParams();
  const course = useObservableQuery(
    Data.Lms.Queries.FindCourseByCourseId,
    parseInt(courseId ?? '')
  );
  const unit = useObservableQuery(Data.Lms.Queries.FindUnitByUnitId, parseInt(unitId ?? ''));
  const lesson = useObservableQuery(
    Data.Lms.Queries.FindLessonByLessonId,
    parseInt(lessonId ?? '')
  );
  const contents = Hooks.useContentsByLessonId(parseInt(lessonId ?? ''));

  if (
    typeof course === 'undefined' ||
    typeof unit === 'undefined' ||
    typeof lesson === 'undefined' ||
    typeof contents === 'undefined'
  ) {
    return <Loading />;
  }
  return <LmsContentView course={course} unit={unit} lesson={lesson} contents={contents} />;
};

interface LmsContentViewProps {
  course: Domain.CourseDto;
  unit: Domain.UnitDto;
  lesson: Domain.LessonDto;
  contents: Domain.ContentDto[];
}

const LmsContentView: React.FC<LmsContentViewProps> = ({ course, unit, lesson, contents }) => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setMeta } = useOutletContext<LayoutContext>();
  const commands = Hooks.useCommands();
  const { resetQuiz } = Hooks.useQuizContext();
  const { nextUnitId, nextLesson } = Hooks.useNextLesson(
    course.courseId ?? 0,
    unit.unitId ?? 0,
    lesson?.lessonId ?? 0
  );
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];
  const yourLessonsView = pathnameSegments.includes('your-lessons');
  const path = yourLessonsView ? `/tools/${module}/your-lessons` : '/learning';

  useEffect(() => {
    if (contents) {
      setMeta((prev) => ({
        ...prev,
        disableNextButton: false,
        centerComponent: null,
        background: <img src={undefined} />,
        backLocaleKey: yourLessonsView ? 'CaretYourLessons' : 'CourseSummary',
        nextLocaleKey:
          lesson.quiz && !lesson.complete
            ? 'QuizMe!'
            : lesson.quiz
            ? 'QuizResults'
            : nextLesson && !yourLessonsView
            ? 'NextLesson'
            : course.completionPercentage === 100 && !yourLessonsView
            ? 'FinishCourse'
            : 'Done',
        onNext: navigateToNext,
        onBack: navigateToLessons,
      }));
    }
  }, [contents, nextLesson, nextUnitId, course.completionPercentage, yourLessonsView]);

  const navigateToNext = async () => {
    if (!lesson.contentComplete) {
      await commands.execute(Data.Lms.Commands.MarkContentComplete, lesson);
    }
    if (!lesson.quiz) {
      if (yourLessonsView) {
        return navigate(path);
      }
      if (nextLesson) {
        return navigate(
          `/learning/${course.courseId}/${nextUnitId}/${nextLesson?.lessonId}/content`
        );
      }
      return navigate(`/learning/${course.courseId}`);
    }
    if (lesson.quiz) {
      if (lesson.complete) {
        return navigate(
          `${path}/${course.courseId}/${unit?.unitId}/${lesson.lessonId}/${lesson.quiz?.quizId}/results`
        );
      }
      return navigateToQuiz();
    }
  };

  const navigateToQuiz = async () => {
    try {
      setLoading(true);
      resetQuiz(lesson.quiz?.quizId ?? 0);
      await commands.execute(Data.Lms.Commands.PostQuizAttempt, lesson.quiz);
      navigate(
        `${path}/${course.courseId}/${unit?.unitId}/${lesson.lessonId}/${lesson.quiz?.quizId}/${lesson.quiz?.questions?.[0].questionId}`
      );
    } finally {
      setLoading(false);
    }
  };

  const navigateToLessons = () => {
    if (yourLessonsView) {
      return navigate(path);
    }
    navigate(`/learning/${course.courseId}`);
  };

  return (
    <Stack spacing='10px'>
      {contents?.map((content, i) => (
        <Grid key={i}>
          <ContentHeader icon={content?.icon} title={content?.title} />
          <ContentCopy copy={content?.contentCopy} />
        </Grid>
      ))}
    </Stack>
  );
};

const meta = {
  background: <img src={undefined} />,
  backLocaleKey: 'CourseSummary',
} satisfies LayoutMeta<LmsLayoutMeta>;

export default withLayoutMeta(LmsContentViewWrapper, meta);
