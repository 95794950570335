import { Grid, Typography } from '@mui/material';
import { campingBanner } from '../../../../assets/png';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { Colors } from '../../../../themes';
import { theme } from '../../../../themes/ThemeWrapper';

export const AdviceUpsellHeaderCard: React.FC = () => {
  return (
    <Grid
      container
      className='forward-slash-split-card'
      style={{ '--background': theme.palette.primary.light } as React.CSSProperties}>
      <Grid
        item
        className='left-grid'
        pl={5}
        pt={3}
        pb={2}
        flexDirection='column'
        textAlign='center'>
        <Typography variant='p30Bold' color='secondary' display='inline'>
          You don’t&nbsp;
          <span style={{ color: Colors.tertiaryBase }}>travel</span>
          &nbsp;without a&nbsp;
          <span style={{ color: Colors.tertiaryBase }}>guide</span>
        </Typography>
        <Typography mt={1} variant='p18' color={Colors.tertiaryBase}>
          Let us be your financial map.
        </Typography>
        <SubscriptionDetails
          sx={{ paddingTop: '16px', paddingBottom: '10px' }}
          md={10}
          lg={7}
          buttonProps={{ color: 'secondary' }}
          linkColor={Colors.secondaryBase}
        />
      </Grid>

      <Grid
        container
        alignContent='flex-end'
        justifyContent='flex-end'
        className='img-wrapper'
        sm={6}
        style={{
          backgroundImage: 'linear-gradient(to bottom, #cfe1f2 -61%, #accae8)',
        }}>
        <Grid container width='135%'>
          <img src={campingBanner} />
        </Grid>
      </Grid>
    </Grid>
  );
};
