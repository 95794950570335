import { Data, formatWholeDollars } from '@3nickels/data-modules';
import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../../Types';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import React from 'react';

export interface RetirementPlanEmployerContributionsCardProps extends SummaryCardProps {
  employerContributions: Data.InvestmentAccounts.EmployerContributionsFormData;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const RetirementPlanEmployerContributionsCard: React.FC<
  RetirementPlanEmployerContributionsCardProps
> = (props) => {
  const { employerContributions, onEdit, disableEdit } = props;
  return (
    <CardDisplay
      headerKey='EmployerMatch&OtherContributions'
      actionContent='Edit'
      actionClick={onEdit}
      disableAction={disableEdit}>
      {(employerContributions?.contributionTiers?.length ?? 0) > 1 ? (
        <MultiTierEmployerContributionsContent {...props} />
      ) : (
        <SingleTierEmployerContributionsContent {...props} />
      )}
    </CardDisplay>
  );
};

const SingleTierEmployerContributionsContent: React.FC<
  RetirementPlanEmployerContributionsCardProps
> = ({ employerContributions, params }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography variant='h3' component='h3' color='primary'>
          {t('PerDollarEmployerMatchRate')}
        </Typography>
        <Typography color='secondary'>
          {formatPercent(employerContributions.perDollarEmployerMatchRate ?? 0)}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant='h3' component='h3' color='primary'>
          {t('MaximumPercentForMatch')}
        </Typography>
        <Typography color='secondary'>
          {formatPercent(employerContributions.maxPercentEmployerMatch ?? 0)}
        </Typography>
      </Grid>
      {!params.institutionalAccount && (
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MaximumDollarAmountForMatch')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(employerContributions.maxDollarEmployerMatch ?? 0)}
          </Typography>
        </Grid>
      )}
      <Grid item xs>
        <Typography variant='h3' component='h3' color='primary'>
          {t('FixedEmployerContributionsOrProfitSharing')}
        </Typography>
        <Typography color='secondary'>
          {formatPercent(employerContributions.otherEmployerContrib ?? 0)}
        </Typography>
      </Grid>
      <Grid item xs />
      {params.institutionalAccount && <Grid item xs />}
    </Grid>
  );
};

const MultiTierEmployerContributionsContent: React.FC<
  RetirementPlanEmployerContributionsCardProps
> = ({ employerContributions }) => {
  const { t } = useTranslation();

  return (
    <>
      {employerContributions.contributionTiers?.map((tier, index) => (
        <>
          {index > 0 && (
            <Grid container my={2}>
              <Grid item xs={7}>
                <Divider color='primary.light' />
              </Grid>
              {/* <Grid item xs />
              <Grid item xs /> */}
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs display='flex'>
              <Typography variant='p16Bold' color='secondary'>
                Tier {index + 1}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('PerDollarEmployerMatchRate')}
              </Typography>
              <Typography color='secondary'>
                {formatPercent(tier.percentContribution ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MaximumPercentForMatch')}
              </Typography>
              <Typography color='secondary'>{formatPercent(tier.to ?? 0)}</Typography>
            </Grid>
            {index === 0 && (
              <>
                <Grid item xs display='flex'>
                  <Typography variant='p16Bold' color='secondary'>
                    {t('OtherContributions')}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant='h3' component='h3' color='primary'>
                    {t('FixedEmployerContributionsOrProfitSharing')}
                  </Typography>
                  <Typography color='secondary'>
                    {formatPercent(employerContributions.otherEmployerContrib ?? 0)}
                  </Typography>
                </Grid>
              </>
            )}
            {index > 0 && (
              <>
                <Grid item xs />
                <Grid item xs />
              </>
            )}
          </Grid>
        </>
      ))}
    </>
  );
};

export default RetirementPlanEmployerContributionsCard;
