import React from 'react';
import { Button, Grid, SxProps, Typography } from '@mui/material';
import CampingBannerPng from '../../components/images/CampingBannerPng';
import { Colors } from '../../themes';
import { CityDarkPng } from '../../components/images/CityDarkPng';
import { FreeCarousel } from '../free/home/FreeCarousel';
import { SubscriptionDetails } from '../free/home/SubscriptionDetails';
import { useTranslation } from 'react-i18next';
import { useMobileRedirect } from '../../hooks/useMobileRedirect';
import { MobileTopNavBar } from './MobileTopNavBar';

const buttonStyles: SxProps = {
  marginTop: 1,
  minHeight: '40px',
  maxWidth: '500px',
};

const MobileAdviceUpsellView: React.FC = () => (
  <Grid container width='100%' flexDirection='column' justifyContent='center' alignItems='center'>
    <MobileTopNavBar />
    <HeroSection />
    <OfferingsSection />
  </Grid>
);

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ background: 'linear-gradient(to bottom, #cfe1f2, #accae8)' }}>
      <Grid
        item
        container
        flexDirection='column'
        alignItems='center'
        textAlign='center'
        xs
        py={4}
        flex={1}>
        <Grid item xs={9}>
          <Typography variant='h1' className='title' color='secondary' gutterBottom>
            {t('DontTravelWithoutGuide')}
          </Typography>
        </Grid>
        <Typography variant='p18Bold' color='secondary' gutterBottom mt={1}>
          {t('LetUsBeYourFinancialMap')}
        </Typography>
        <SubscriptionDetails
          xs={10}
          sm={8}
          mt={3}
          linkColor={Colors.secondaryBase}
          buttonProps={{ fullWidth: true, color: 'secondary', sx: buttonStyles }}
        />
      </Grid>
      <CampingBannerPng />
    </Grid>
  );
};

const OfferingsSection = () => {
  return (
    <Grid container justifyContent='center' sx={{ background: Colors.secondaryLighter }}>
      <FeaturesSection />
      <SubscriptionDetails
        mt={3}
        xs={10}
        sm={8}
        buttonProps={{ fullWidth: true, sx: buttonStyles }}
      />
      <OptOutSection />
      <CityDarkPng />
    </Grid>
  );
};

const FeaturesSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid item container flexDirection='column' alignItems='center' mt={1.5}>
      <Typography variant='p18Bold' color='primary' py={2.5}>
        {t('HereWhatYouGet')}
      </Typography>
      <FreeCarousel variant='default' swipeable />
    </Grid>
  );
};

const OptOutSection: React.FC = () => {
  const { t } = useTranslation();
  const mobileRedirect = useMobileRedirect();

  return (
    <Grid
      item
      container
      flexDirection='column'
      alignItems='center'
      alignSelf='center'
      xs={10}
      sm={8}
      py={3}
      mt={5}
      gap={1}>
      <Grid item xs={10}>
        <Typography variant='p18Bold' color='secondary' textAlign='center'>
          {t('NoThanksWontUseAdvice')}
        </Typography>
      </Grid>
      <Button
        variant='outlined'
        color='primary'
        fullWidth
        sx={buttonStyles}
        onClick={mobileRedirect}>
        {t('DownloadMobileApp')}
      </Button>
    </Grid>
  );
};

export default MobileAdviceUpsellView;
