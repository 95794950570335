import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../themes';
import { Svgs } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';

interface RelatedLinks {
  textKey: string;
  url: string;
}

interface ChecklistTemplateProps {
  stepNumber: number;
  headerIcon?: React.ReactNode;
  headerText: string;
  body: JSX.Element;
  related?: RelatedLinks[];
}

const ChecklistTemplate: React.FC<ChecklistTemplateProps> = ({
  stepNumber,
  headerIcon,
  headerText,
  body,
  related,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack spacing={Spacing.xxxs + 0.5}>
      <Grid container alignItems='center'>
        {headerIcon && (
          <Grid container item xs={1.75} alignItems='center' justifyContent='center'>
            {headerIcon}
          </Grid>
        )}
        <Grid item xs={headerIcon ? 10.25 : undefined}>
          <Grid container flexDirection='column'>
            <Typography mb={1} color='secondary' variant='p18Bold'>
              Step {stepNumber}
            </Typography>
            <Typography color='primary' variant='p22Bold'>
              {t(headerText)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Card className='content-card'>
        <Grid container>
          <Grid item xs={related ? 8 : undefined}>
            {body}
          </Grid>
          {related && (
            <>
              <Divider
                orientation='vertical'
                sx={{ borderColor: Colors.primaryLight, margin: '0px 30px' }}
                flexItem
              />
              <Grid item xs={3}>
                <Stack spacing={Spacing.xxxs + 0.5}>
                  <Box display='flex' alignItems='center'>
                    <Svgs.ArtLightbulbSmall />
                    <Typography color='secondary' variant='p18Bold' sx={{ marginLeft: 1 }}>
                      {t('Related')}
                    </Typography>
                  </Box>
                  {related.map((item) => (
                    <Typography
                      key={item.url}
                      color='primary'
                      variant='p16Bold'
                      className='hover-clickable'
                      sx={{ marginLeft: 1 }}
                      onClick={() => navigate(item.url)}>
                      {t(item.textKey)}
                    </Typography>
                  ))}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </Stack>
  );
};

export default ChecklistTemplate;
