import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { AxiosServices } from '@aesop-fables/containr-axios';
import { inject } from '@aesop-fables/containr';

export class PaymentApi {
  constructor(@inject(AxiosServices.AxiosInstance) private readonly axios: AxiosInstance) {}

  getPaymentStatus(): Promise<AxiosResponse<PaymentStatusData>> {
    return this.axios.get('license/active-status');
  }

  createSession(id: number): Promise<AxiosResponse<string>> {
    return this.axios.post(`session/create-session?pricebookId=${id}`);
  }

  addPaymentMethod(): Promise<AxiosResponse<string>> {
    return this.axios.post('payment/setup-intent-url');
  }

  setDefaultPaymentMethod(id: string): Promise<AxiosResponse<SetDefaultPaymentData>> {
    return this.axios.post(`payment/set-default-payment?paymentMethodId=${id}`);
  }

  removePaymentMethod(id: string): Promise<AxiosResponse<DetachPaymentData>> {
    return this.axios.post(`payment/detach-payment?paymentMethodId=${id}`);
  }

  // All payment methods
  getAllPaymentMethods(): Promise<AxiosResponse<PaymentMethodsData[]>> {
    return this.axios.get('payment/payment');
  }

  getDefaultPaymentMethod(): Promise<AxiosResponse<string>> {
    return this.axios.get('payment/default-payment');
  }

  getTransactionsHistory(): Promise<AxiosResponse<ChargeData[]>> {
    return this.axios.get('charge');
  }
}

export interface PaymentStatusData {
  hasActiveLicense: boolean;
}

export interface SetDefaultPaymentData {
  id: string;
  name: string;
  metadata: AdditionalProps;
  defaultPaymentMethod: string;
  email: string;
}

export interface AdditionalProps {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface DetachPaymentData {
  id: string;
  type: string;
  lastDigits: string;
}

export interface PaymentMethodsData {
  id: string;
  type: string;
  lastDigits: string;
}

export interface ChargeData {
  charge: ChargeDetails;
  paymentInfo: PaymentDetails;
}

export interface ChargeDetails {
  id: number;
  chargeId: string;
  stripeId: string;
  amountPaid: number;
  description: string;
  invoiceId: string;
  name: string;
  email: string;
  receiptUrl: string;
  datePaid: string;
  clearedAt: string;
  cleared: boolean;
  subscriptionId: number;
  checkoutId: number;
  quantity: number;
  origin: string;
}

export interface PaymentDetails {
  id: number;
  chargeId: number;
  paymentMethod: string;
  last4: string;
  zipCode: string;
  country: string;
}

export type ActivePaymentMethodData = { name: string };
