import { Typography, TypographyProps } from '@mui/material';
import { Margins } from '../themes';

const NumberedListItem: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <Typography
      style={{
        ...Margins.mt_xxxs,
        display: 'list-item',
        paddingLeft: '16px',
        ...style,
      }}
      color={color ?? 'secondary'}>
      {children}
    </Typography>
  );
};

export default NumberedListItem;
