import { Box } from '@mui/material';
import React from 'react';
import { cityTall } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import PersonalLoanCalculatorCard from './cards/PersonalLoanCalculatorCard';
import PersonalLoanChecklistCard from './cards/PersonalLoanChecklistCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const LoansToolsStartView: React.FC = () => {
  const explore = useExploreGroups();

  const calculators: Group[] = [
    {
      textKey: 'personal-loan-calculator',
      url: 'personal-loan-calculator',
      content: <PersonalLoanCalculatorCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'personal-loan-checklist',
      url: 'personal-loan-checklist',
      content: <PersonalLoanChecklistCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplaySmallCustomLoansSecondary />}
        label='Loans'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={cityTall} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(LoansToolsStartView, meta);
