import { Box } from '@mui/material';
import React from 'react';
import { houseLandscape } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import RentOrBuyCard from './cards/RentOrBuyCard';
import HowMuchHouseCard from './cards/HowMuchHouseCard';
import HomeSaleNetProceedsCard from './cards/HomeSaleNetProceedsCard';
import MortgageRefinanceCalculatorCard from './cards/MortgageRefinanceCalculatorCard';
import MortgageRatesCard from './cards/MortgageRatesCard';
import CostOfLivingCard from './cards/CostOfLivingCard';
import HouseBuyingChecklistCard from './cards/HouseBuyingChecklistCard';
import MortgageRefinanceChecklistCard from './cards/MortgageRefinanceChecklistCard';
import HouseSellingChecklistCard from './cards/HouseSellingChecklistCard';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const HouseToolsStartView: React.FC = () => {
  const explore = useExploreGroups();

  const calculators: Group[] = [
    {
      textKey: 'rent-or-buy',
      url: 'rent-or-buy',
      content: <RentOrBuyCard />,
    },
    {
      textKey: 'how-much-house',
      url: 'how-much-house',
      content: <HowMuchHouseCard />,
    },
    {
      textKey: 'home-sale-net-proceeds',
      url: 'home-sale-net-proceeds',
      content: <HomeSaleNetProceedsCard />,
    },
    {
      textKey: 'mortgage-refinance-calculator',
      url: 'mortgage-refinance-calculator',
      content: <MortgageRefinanceCalculatorCard />,
    },
    {
      textKey: 'mortgage-rates',
      url: 'mortgage-rates',
      content: <MortgageRatesCard />,
    },
  ];

  const resources: Group[] = [
    {
      textKey: 'cost-of-living-comparison',
      url: 'cost-of-living-comparison',
      content: <CostOfLivingCard />,
    },
    {
      textKey: 'house-buying-checklist',
      url: 'house-buying-checklist',
      content: <HouseBuyingChecklistCard />,
    },
    {
      textKey: 'house-selling-checklist',
      url: 'house-selling-checklist',
      content: <HouseSellingChecklistCard />,
    },
    {
      textKey: 'mortgage-refinance-checklist',
      url: 'mortgage-refinance-checklist',
      content: <MortgageRefinanceChecklistCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplayLargeCustomHouse />}
        label='House'
        calculators={calculators}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={houseLandscape} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HouseToolsStartView, meta);
