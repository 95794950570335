import VerificationInput from 'react-verification-input';
import './index.css';

export interface ConfirmationCodeFieldProps<T> {
  fieldName: keyof T;
  value: string;
  onChange: (x: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ConfirmationCodeField = <T extends unknown>(props: ConfirmationCodeFieldProps<T>) => {
  const { value, onChange } = props;

  return (
    <VerificationInput
      length={6}
      validChars='0-9'
      value={value}
      onChange={onChange}
      autoFocus={true}
      inputProps={{ inputMode: 'numeric' }}
      classNames={{
        character: 'characterStyles',
        characterInactive: 'inactiveCharacterStyles',
        characterSelected: 'selectedCharacterStyles',
      }}
    />
  );
};

export { ConfirmationCodeField };
