import { Grid, Typography } from '@mui/material';
import { Data, Domain, formatDateForApi, formatWholeDollars, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import { useForm } from 'react-hook-form';
import {
  cleanWholeNumberStr,
  formatMalformedDate,
  isValidDate,
} from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { useLoaderData } from 'react-router-dom';
import MortgageRefinanceCalculatorForm from './MortgageRefinanceCalculatorForm';
import MortgageRefinanceCalculatorResults from './MortgageRefinanceCalculatorResults';
import dayjs from 'dayjs';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const mortgageRefinanceSchema = Yup.object({
  debtId: Yup.string().required('Required'),
  originationDate: Yup.string()
    .required('Required')
    .test('validDate', 'Must be a valid date', (value) => {
      return isValidDate(value);
    })
    .test('Date not in the future', "Date can't be in the future.", (value) => {
      const date = formatMalformedDate(value);
      return dayjs(date).isSameOrBefore(dayjs(), 'day');
    })
    .test('Date less than 30 years ago', "Date can't be more than 30 years ago.", (value) => {
      const date = formatMalformedDate(value);
      return dayjs(date).isSameOrAfter(dayjs().subtract(30, 'years'), 'day');
    }),
  newInterestRate: Yup.string()
    .required('Required')
    .test('newInterestRate', 'Interest Rate must be greater than 0', (value) => {
      const cleanNumber = cleanWholeNumberStr(value);
      return cleanNumber > 0;
    }),
  newLoanTermYears: Yup.string().required('Required'),
  closingCostPct: Yup.string().notRequired(),
  closingCostDlr: Yup.string().notRequired(),
});

const MortgageRefinanceCalculatorView: React.FC = () => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Domain.MortgageRefinanceResultData | undefined>();
  const [selectedMortgage, setSelectedMortgage] = useState<Domain.IDebt | undefined>();
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.MortgageRefinanceFormData>(
    SavedFormsKeyEnum.MortgageRefinance
  );
  const mortgageRefinanceFormData: Domain.MortgageRefinanceFormData = useMemo(
    () => ({
      closingCostDlr: formData?.closingCostDlr,
      closingCostPct: formData?.closingCostPct,
      debtId: formData?.debtId,
      newInterestRate: formData?.newInterestRate,
      newLoanTermYears: formData?.newLoanTermYears,
      originationDate: formData?.originationDate,
    }),
    [formData]
  );
  const initialMortgages = useLoaderData() as Domain.IDebt[] | undefined;
  const combinedMortgages = Hooks.useCombinedMortgages();
  const mortgages = useMemo(
    // use preloaded initalMortgages value from loader until combinedMortgages resloves to prevent screen flickering
    // when combined Mortgages length is 0 initially
    () => (combinedMortgages.length === 0 ? initialMortgages : combinedMortgages),
    [initialMortgages, combinedMortgages]
  );
  const savingsExpected = useMemo(() => {
    return (results?.totalSavings ?? 0) > 0;
  }, [results]);
  const paidOff = useMemo(() => {
    return (results?.oldMonthlyPayments ?? 0) === 0;
  }, [results]);

  const formatInputs = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
  ): Domain.MortgageRefinanceFormData => {
    const formData: Domain.MortgageRefinanceFormData = {
      debtId: cleanWholeNumberStr(values.debtId ?? 0),
      originationDate: formatDateForApi(values.originationDate) as unknown as Date,
      newInterestRate: cleanWholeNumberStr(values.newInterestRate ?? 0),
      newLoanTermYears: values.newLoanTermYears,
      closingCostPct: cleanWholeNumberStr(values.closingCostPct ?? 0),
      closingCostDlr: cleanWholeNumberStr(values.closingCostDlr ?? 0),
    };

    return formData;
  };

  const onSubmit = async (values: Domain.MortgageRefinanceFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.MortgageRefinance,
        formData: cleanedValues,
      });
      const { data } = await commands.execute(
        Data.Housing.Commands.CalculateAdviceMortgageRefinance,
        Domain.mapMortgageRefinanceFormDataToCalculateAdviceMortgageRefinanceParams(cleanedValues)
      );
      setResults(Domain.mapMortgageRefinanceResultRestToMortgageRefinanceResultData(data));
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Housing.Commands.CalculateAdviceMortgageRefinancePdf,
        Domain.mapMortgageRefinanceFormDataToCalculateAdviceMortgageRefinanceParams(
          mortgageRefinanceFormData
        )
      );
      const url = window.URL.createObjectURL(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new Blob([pdfResults.data as any], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'MortgageRefinanceBreakevenResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm<Domain.MortgageRefinanceFormData>({
    resolver: yupResolver(mortgageRefinanceSchema),
    defaultValues: mortgageRefinanceFormData,
  });

  if (typeof mortgages === 'undefined' || typeof formData === 'undefined') return null;

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <ToolTemplate
        header={
          mortgages.length === 0 ? (
            <ToolHeader title={'NoMortgagesAddedYet'} />
          ) : results ? (
            savingsExpected ? (
              <ToolHeader
                icon={<Svgs.ArtHouse />}
                title={'RefinanceYouCouldExpectTheseSavings'}
                onDownload={onDownload}
              />
            ) : (
              <ToolHeader icon={<Svgs.ArtHouse />} title={'RefinancingMayNotBeTheBest'} />
            )
          ) : (
            <ToolHeader title={'AddMortgageDetails'} />
          )
        }
        inputColumn={
          <MortgageRefinanceCalculatorForm
            results={results}
            mortgages={mortgages}
            mortgageRefinanceFormData={mortgageRefinanceFormData}
            formMethods={methods}
            selectedMortgage={selectedMortgage}
            setSelectedMortgage={setSelectedMortgage}
            resetResults={() => setResults(undefined)}
            onSubmit={onSubmit}
          />
        }
        outputColumn={
          <MortgageRefinanceCalculatorResults results={results} mortgage={selectedMortgage} />
        }
        boxItems={[
          {
            boxTitle: 'SavingsOverLoanTerm',
            boxResult:
              typeof results !== 'undefined'
                ? formatWholeDollars(results.totalSavings ?? 0)
                : undefined,
            noResult: typeof results !== 'undefined' && savingsExpected === false,
            noResultContent: (
              <Grid paddingX='20px'>
                <Typography variant='p16' color='error'>
                  {paidOff ? t('ByOurCalculationsPaidOff') : t('RefinancingWillNotSaveMoney')}
                </Typography>
              </Grid>
            ),
          },
        ]}
      />
    </Grid>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(MortgageRefinanceCalculatorView, meta);
