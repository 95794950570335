import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepOne: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={1}
      headerIcon={<Svgs.ArtCreditScore />}
      headerText='EstimateTheCost'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('YourCreditScoreHasAHugeImpact')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('OnceYouveReviewedYourCreditScore')}
          </Typography>
        </>
      }
      related={[
        { textKey: 'PersonalLoanCalculator', url: '/tools/loans/personal-loan-calculator' },
      ]}
    />
  );
};

export default StepOne;
