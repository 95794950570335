import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import InvestmentsView from '../../investment-account/investments/InvestmentsView';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { useNavigate, useParams } from 'react-router-dom';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { Backdrop } from '@mui/material';
import { FormLoader } from '../../../../components/FormLoader';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { useEffect, useMemo } from 'react';
import { map } from 'rxjs';
import { Loading } from '../../../../hooks/useLoading';
import { useRetirementPlanMeta } from '../../../../hooks/useRetirementPlanMeta';
import { useRetirementPlanPath } from '../../../../hooks/useRetirementPlanPath';

export const RetirementPlanInvestmentsEditViewWrapper: React.FC<EditViewProps> = ({
  onBack,
  editing,
}) => {
  const { loading, currentStep, wizard, params } =
    Hooks.useRetirementWizard<Domain.InvestmentData[]>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id, type: planType } = useParams();
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : params?.type ?? Domain.PlanTypeEnum['#457'];
  useRetirementPlanMeta(editing);

  const commands = Hooks.useCommands();
  const { institutionalAccount, institutionalAccountRequiresReview } =
    Hooks.useInstitutionalAccount();
  const isInstitutional = useMemo(
    () => parseInt(id ?? '') === institutionalAccount?.id,
    [id, institutionalAccount]
  );
  useEffect(() => {
    if (institutionalAccountRequiresReview && isInstitutional) {
      commands.executeMutation(new Data.Users.Mutations.ReviewInstitutionalAccount(), null);
    }
  }, [institutionalAccountRequiresReview, isInstitutional, commands]);

  useEffect(() => {
    // ensure type is initialized
    if (
      typeof isStarted !== 'undefined' &&
      !isStarted &&
      type &&
      Domain.PlanTypeEnumMap.get(type)
    ) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('investments');
    }
  }, [isStarted, type]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'investments')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'investments' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <RetirementPlanInvestmentsEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface RetirementPlanBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Domain.InvestmentData[],
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.InvestmentAccountWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const RetirementPlanInvestmentsEditView: React.FC<RetirementPlanBasicsEditViewProps> = ({
  params,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const { id } = useParams();
  const investmentAccountId = parseInt(id ?? '');
  const wizardPlanType =
    Domain.planTypeIdToAccountKey[params.type as keyof typeof Domain.planTypeIdToAccountKey];
  const { investments } = Hooks.useInvestmentData(investmentAccountId);

  const path = useRetirementPlanPath();

  const onBack = () => navigate(-1);
  const onSkip = () =>
    navigate(`/account-details/retirement-savings-plan/${path}/${wizardPlanType}/${params.id}`);

  if (!ready) return null;

  return (
    <>
      <InvestmentsView investmentAccountId={investmentAccountId} />
      <WizardFooter
        disableBack={false}
        onBack={onBack}
        onDone={onSkip}
        nextVariant={investments.length === 0 ? 'link' : undefined}
        {...getWizardFooterProps(investments.length > 0 ? 'Finish' : 'SkipForNow')}
      />
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(RetirementPlanInvestmentsEditViewWrapper, meta);
