import React from 'react';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import { Data, Hooks } from '@3nickels/data-modules';
import { Colors } from '../../themes';
import { ArrowDropDown } from '@mui/icons-material';
import { Svgs } from '../../assets/svg';

interface SpendingSummaryTimeframeSelectorProps {
  variant?: 'ghost' | 'panel';
  showStartAdornment?: React.ReactNode;
  hideArrow?: boolean;
  cutAverages?: boolean;
  disabled?: boolean;
}

const ghostStyle = { color: Colors.primaryBase, fontWeight: 'bold', background: 'transparent' };
const panelStyle = {
  color: Colors.secondaryBase,
  fontSize: '12px',
  background: Colors.primaryLightest,
  borderRadius: '7px',
};

const SpendingSummaryTimeframeSelector: React.FC<SpendingSummaryTimeframeSelectorProps> = ({
  variant = 'ghost',
  showStartAdornment,
  hideArrow,
  cutAverages,
  disabled,
}) => {
  const { transactionsTimeframe, setTransactionsTimeframe } = Hooks.useTransactionsSummary();

  return (
    <Select
      size='small'
      id='timeframe'
      disableUnderline
      variant='standard'
      label='Timeframe'
      value={transactionsTimeframe}
      disabled={disabled}
      style={variant === 'panel' ? panelStyle : ghostStyle}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: variant === 'panel' ? `${Colors.primaryLighter} !important` : undefined,
        },
      }}
      SelectDisplayProps={{ style: { background: 'transparent' } }}
      IconComponent={(props) =>
        !hideArrow && <ArrowDropDown {...props} style={{ color: Colors.primaryBase }} />
      }
      startAdornment={
        showStartAdornment && (
          <InputAdornment position='start' style={{ paddingLeft: '5px' }}>
            <Svgs.ActionSmallCalendar />
          </InputAdornment>
        )
      }
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '33%',
            overflowY: 'scroll',
            borderRadius: '10px',
            boxShadow:
              '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2)',
          },
        },
      }}
      onChange={(e) =>
        setTransactionsTimeframe(e.target.value as Data.Transactions.TransactionTimeFrameEnum)
      }>
      {Data.Transactions.TransactionTimeFrameEnumList.map((x) => (
        <MenuItem
          style={{
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '14px',
            color: Colors.primaryBase,
          }}
          value={x.key}>
          {cutAverages ? x.value.replace('Average Over the ', '') : x.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SpendingSummaryTimeframeSelector;
