import { Data, Domain } from '@3nickels/data-modules';
import { DefaultPricebook } from '../../../models/PricebookData';
import { formatDecimalDollars } from '../../../helpers/utilityFunctions';

export const resolveHeaders = (products: Domain.PricebookBundle[]) => {
  const headers = [];
  if (
    products.some((p) => p.prices[0].price === 0 && p.pricebook.paidBy === Domain.PaidByEnum.USER)
  ) {
    headers.push('Basic Tools');
  }
  if (
    products.some(
      (p) =>
        p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    )
  ) {
    headers.push('Advice');
  }
  if (products.some((p) => p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELSU)) {
    headers.push('Advice + 3NickelsU');
  }
  return headers;
};

export function getCurrentPlan(
  subscription: Domain.SubscriptionInfo | undefined,
  inAdviceFreeTrial: boolean,
  threeNickelsProducts: Domain.PricebookBundle[] | undefined
): Domain.PricebookBundle | undefined {
  const advicePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    ) ?? DefaultPricebook;
  const freePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELS &&
        p.prices[0].price === 0 &&
        p.pricebook.paidBy === Domain.PaidByEnum.USER
    ) ?? DefaultPricebook;
  const status = subscription && getSubscriptionStatus(subscription);
  if (subscription && !status?.expired) {
    if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
      const pricebook = threeNickelsProducts?.find(
        (p) => p.pricebook.id === subscription.pricebookId
      );
      return pricebook ?? DefaultPricebook;
    } else {
      return advicePricebook;
    }
  } else if (inAdviceFreeTrial) {
    return undefined; // Return undefined so user can see action button to subscribe
  } else {
    return freePricebook;
  }
}

export const getSubscriptionStatus = (subscription: Domain.SubscriptionInfo) => {
  const expirationDate = new Date(subscription.expirationDate);
  const now = new Date();
  return {
    cancelled: subscription.status === 'cancelled',
    isActive: subscription.status === 'active' || subscription.status === 'trialing',
    expired: expirationDate < now,
  };
};

export const getName = (
  subscription: Domain.SubscriptionInfo,
  threeNickelsProducts: Domain.PricebookBundle[] | undefined
): string => {
  if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
    const pricebook = threeNickelsProducts?.find(
      (p) => p.pricebook.id === subscription.pricebookId
    );
    return pricebook?.pricebook.pricebookName ?? '';
  } else {
    return subscription.name;
  }
};

export const getInterval = (interval: Domain.PaymentIntervalEnum | undefined): string => {
  if (interval) {
    if (interval !== Domain.PaymentIntervalEnum.NONE) {
      return interval.toLocaleLowerCase();
    }
    return 'month';
  }
  return 'month';
};

export function getFootnoteElement(
  footnoteCounter: number,
  plan: Domain.PricebookBundle,
  inAdviceFreeTrial: boolean,
  subscription: Domain.SubscriptionInfo,
  freeTrialStatus?: Data.Users.FreeTrialInfoDto | undefined,
  userNeverSubscribedToExclusivePricebook?: boolean,
  manageSubscription?: boolean
) {
  let footnoteText: string | null = null;
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);
  const freeTrialDaysLeft = freeTrialStatus?.expirationDate
    ? Math.ceil(
        (new Date(freeTrialStatus.expirationDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
      )
    : 0;
  const subscriptionCancelled =
    plan.pricebook.id === subscription?.pricebookId &&
    (subscription?.status === 'cancelled' || subscription?.status === 'expired');

  if (!(free || orgPays)) {
    footnoteCounter++;
    if (promotion) {
      const priceAfterPromo = formatDecimalDollars(plan.prices[1].price / 100);
      const intervalAfterPromo = getInterval(plan.prices[1].intervalType);
      footnoteText = `Initially a one-time payment. After the ${introPeriodInMonths}-month promotional period,
          Advice subscription auto-renews at ${priceAfterPromo}/${intervalAfterPromo}.`;
    } else if (inAdviceFreeTrial && manageSubscription) {
      footnoteText = `${freeTrialDaysLeft} ${
        freeTrialDaysLeft === 1 ? 'day' : 'days'
      } left of free trial, subscription not required. Option to start an auto-renewing
          subscription of $14.99/month during or after 32-day free trial.`;
    } else if (userNeverSubscribedToExclusivePricebook) {
      const index = promotion ? 1 : 0; // use index of price that is not the promo price
      const price = formatDecimalDollars(plan.prices[index].price / 100);
      const interval = getInterval(plan.prices[index].intervalType);
      footnoteText = `You’re not currently subscribed. If started, this subscription automatically renews at ${price}/${interval} and will continue to auto-renew until you cancel.`;
    } else if (subscriptionCancelled) {
      footnoteText = `If restarted, this subscription automatically renews every ${getInterval(
        subscription?.interval
      )} and will continue to auto-renew until you cancel.`;
    } else {
      footnoteText = `This subscription automatically renews every ${getInterval(
        plan.prices[0].intervalType
      )} and will continue to auto-renew until
          you cancel.`;
    }
  }

  return { footnoteCounter, footnoteText };
}

export function getRecentPlan(
  subscription: Domain.SubscriptionInfo | undefined,
  inAdviceFreeTrial: boolean,
  threeNickelsProducts: Domain.PricebookBundle[] | undefined
): Domain.PricebookBundle {
  const advicePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELS && p.prices[0].price > 0
    ) ?? DefaultPricebook;
  const freePricebook =
    threeNickelsProducts?.find(
      (p) =>
        p.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELS &&
        p.prices[0].price === 0 &&
        p.pricebook.paidBy === Domain.PaidByEnum.USER
    ) ?? DefaultPricebook;
  if (subscription) {
    if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
      const pricebook = threeNickelsProducts?.find(
        (p) => p.pricebook.id === subscription.pricebookId
      );
      return pricebook ?? DefaultPricebook;
    } else {
      return advicePricebook;
    }
  } else if (inAdviceFreeTrial) {
    return advicePricebook;
  } else if (threeNickelsProducts && threeNickelsProducts.length === 1) {
    return threeNickelsProducts[0];
  } else {
    return freePricebook;
  }
}
