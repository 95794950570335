import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import {
  capitalizeFirstLetter,
  formatPercent,
  formatWholeDollars,
} from '../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { Data } from '@3nickels/data-modules';

export interface BusinessCardProps extends SummaryCardProps {
  business: Data.Business.BusinessFormData;
  includeSpouse?: boolean;
}

const BusinessCard: React.FC<BusinessCardProps> = ({ onEdit, business, includeSpouse }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('BusinessNickname')}
          </Typography>
          <Typography color='secondary'>{business.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {capitalizeFirstLetter(business?.owner ?? '')}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('OwnershipPercentage')}
          </Typography>
          <Typography color='secondary'>
            {formatPercent(business.ownershipPercentage ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentValueOfBusiness')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(business.valuation ?? 0)}</Typography>
        </Grid>
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default BusinessCard;
