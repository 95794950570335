import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const CostOfCollegeCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomLightBulb />}
      label={t('CostOfCollege')}
      content={t('LetsBreakDownWhatGoesIntoCollegeExpenses')}
    />
  );
};

export default CostOfCollegeCard;
