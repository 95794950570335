import { Card, Grid, Typography } from '@mui/material';
import { Colors } from '../../../themes';
import { Svgs } from '../../../assets/svg';
import AnswersDisplay from './AnswersDisplay';
import { Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';

interface QuestionDisplayProps {
  index: number;
  reviewQuestion: Domain.ReviewQuestionDto;
}
const QuestionDisplay: React.FC<QuestionDisplayProps> = ({ index, reviewQuestion }) => {
  const numberedContent = useMemo(() => {
    if (!reviewQuestion.questionDto.text) return '';

    const pattern = />([^<])/;
    const insertNumber = `>${index}. $1`;

    return reviewQuestion.questionDto.text.replace(pattern, insertNumber);
  }, [reviewQuestion.questionDto.text, index]);
  return (
    <Grid className='lms-question-display' maxWidth='500px' width='100%'>
      <Card
        className='lms-question'
        style={{
          padding: '15px 20px',
          border: 'none',
          boxShadow: 'none',
          borderRadius: '10px',
          backgroundImage: 'linear-gradient(to top, rgba(250, 253, 253, 0), #c6dde3)',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          marginTop: index !== 1 ? '20px' : '0px',
        }}>
        <Typography className='lms-quiz-text' mr={2} variant='p18Bold' color={Colors.primaryBase}>
          <div
            dangerouslySetInnerHTML={{
              __html: numberedContent,
            }}
          />
        </Typography>
        <Grid width='30px' height='30px' display='block'>
          {reviewQuestion?.answeredCorrectly ? (
            <Svgs.DisplaySmallCustomCheckmarkFilled />
          ) : (
            <Svgs.DisplaySmallCustomXFilled />
          )}
        </Grid>
      </Card>
      <AnswersDisplay
        answers={reviewQuestion.questionDto.answers}
        reviewQuestion={reviewQuestion}
      />
    </Grid>
  );
};

export default QuestionDisplay;
