import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Typography } from '@mui/material';
import { Api } from '@3nickels/data-modules';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';

interface MostPopularCarsCardProps {
  data: Api.PopularCarsData;
}

const MostPopularCarsCard: React.FC<MostPopularCarsCardProps> = ({ data }) => {
  const { t } = useTranslation();
  const fiveYearCost =
    !data.fiveYearOwnershipCost || data.fiveYearOwnershipCost === 0
      ? 'N/A*'
      : formatWholeDollars(data.fiveYearOwnershipCost);

  return (
    <Grid container mb={3} className='most-popular-car-container'>
      <Grid item xs={4}>
        <img src={data.imageUri} height='110px' width='auto' style={{ marginLeft: '50px' }} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant='p14Bold' color='primary'>
          #{data.rank}
        </Typography>
        <Typography variant='p18Bold' color='secondary'>
          {data.model}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <Grid container columnGap='80px'>
            <Grid item>
              <Typography mb={0.5} variant='p14Bold' color='primary'>
                MSRP
              </Typography>
              <Typography variant='p16' color='secondary'>
                {formatWholeDollars(data.msrp ?? 0)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography mb={0.5} variant='p14Bold' color='primary'>
                {t('5YearOwnershipCost')}
              </Typography>
              <Typography variant='p16' color='secondary'>
                {fiveYearCost}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MostPopularCarsCard;
