import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import React from 'react';
import { AutoSaveProvider } from '../../hooks/useAutoSaveStatus';

export type FormContentProps = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formProviderProps: any;
};

const FormContent: React.FC<FormContentProps> = (props) => (
  <Grid className='form-content' item>
    <FormProvider {...props.formProviderProps}>
      <AutoSaveProvider>{props.children}</AutoSaveProvider>
    </FormProvider>
  </Grid>
);

export default FormContent;
