import { Typography, Button, IconButton } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';
import { LearnMoreDrawerProps } from './LearnMoreAdviceDrawer';

const LearnMoreFreeDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
  pricebookMetadata,
}) => {
  const { isMobile } = useDeviceType();

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: isMobile ? '100%' : '90%' }}>
          {!isMobile && (
            <Typography color='secondary' variant='p14'>
              3Nickels packages
            </Typography>
          )}
          <Spacer height='xxs' />

          <Typography color='primary' variant={isMobile ? 'p24Bold' : 'p30Bold'}>
            Basic Tools
          </Typography>
          <Spacer height='xxs' />
        </Column>
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <Svgs.IconNavAlertDefault />
          </IconButton>
        ) : (
          <img src={logo3NSmall} alt='logo' style={{ height: '40px' }} />
        )}
      </Row>
      <PackageCard pricebookBundle={pricebookBundle} pricebookMetadata={pricebookMetadata} />
      {isMobile && (
        <>
          <Spacer height='xs' />
          <Column style={{ alignItems: 'center' }}>
            <Svgs.ArtPlant />
          </Column>
        </>
      )}
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Column style={{ width: '80%' }}>
          <Typography color='secondary' variant='p18Bold'>
            Description
          </Typography>
          <Spacer height='xxs' />
          <Typography color='secondary' variant='p16'>
            Get answers to your basic financial questions at no cost to you. Work through different
            aspects of your finances, one at a time.
          </Typography>
        </Column>

        {!isMobile && <Svgs.ArtPlant style={{ width: '100px', height: 'auto' }} />}
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ width: '85%' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletBook
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Accessible education</strong>&nbsp;at every level of financial literacy.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCalculator
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Calculators and checklists</strong> to help you answer one-off questions.
          </Typography>
        </Row>
      </Column>

      {!isMobile && (
        <>
          <Spacer height='xs' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => setDrawerOpen(false)}
              className='xs'
              variant='outlined'
              color='primary'>
              Close
            </Button>
          </Row>
        </>
      )}
      <Spacer height='md' />
    </RightDrawer>
  );
};

export default LearnMoreFreeDrawer;
