/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayout } from '../HolisticFinancialAdviceLayout';
import { Spacing } from '../../../themes';
import { Button } from '../../../components/buttons/Button';
import BulletedList from '../../../components/BulletedList';

const HowToOpenAnAccountView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathnameSegments = location.pathname.split('/');
  const toolsView = pathnameSegments.includes('tools');

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('HowToOpenAnAccount')}
      </Typography>
      <Typography color='secondary' variant='p18Bold' mt='40px'>
        {t('OpeningABrokerageOrIra')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('QuickGuideToOpeningAccounts')}
      </Typography>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.ArtLaptop width='114px' />
          <Grid item className='column-header'>
            <Typography color='secondary' variant='p18Bold'>
              {t('Step', { step: 1 })}
            </Typography>
            <Typography color='primary' variant='p22Bold' mt='10px'>
              {t('ResearchYourOptions')}
            </Typography>
          </Grid>
        </Grid>
        <Card className='content-card' style={{ display: 'block', marginTop: '10px' }}>
          <Typography color='secondary' variant='p16' mb='10px'>
            {t('YoullWantToSitDownAndResearch')}
          </Typography>
          <BulletedList color='secondary' style={{ marginTop: '6px' }}>
            {t('LookOnlineAtDifferentOptions')}
            {t('CheckOutWebsitesLikeNerdWallet')}
            {t('CheckWithYourBankToSeeIfTheyOfferTheTypeOfAccount')}
          </BulletedList>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column' mt='30px'>
        <Grid container className='content-card-header'>
          <Svgs.ArtBusiness width='114px' />
          <Grid item className='column-header'>
            <Typography color='secondary' variant='p18Bold'>
              {t('Step', { step: 2 })}
            </Typography>
            <Typography color='primary' variant='p22Bold' mt='10px'>
              {t('ChooseAnInstitution')}
            </Typography>
          </Grid>
        </Grid>
        <Card className='content-card' style={{ display: 'block', marginTop: '10px' }}>
          <Typography color='secondary' variant='p16' mb='10px'>
            {t('ResearchDifferentProviders')}
          </Typography>
          <BulletedList color='secondary' style={{ marginTop: '6px' }}>
            {t('CheckForStartUpCosts')}
            {t('SeeWhatTheMinimumBalaceIs')}
            {t('UnderstandHiddenFees')}
          </BulletedList>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column' mt='30px'>
        <Grid container className='content-card-header'>
          <Svgs.ArtApproved width='114px' />
          <Grid item className='column-header'>
            <Typography color='secondary' variant='p18Bold'>
              {t('Step', { step: 3 })}
            </Typography>
            <Typography color='primary' variant='p22Bold' mt='10px'>
              {t('OpenTheAccount')}
            </Typography>
          </Grid>
        </Grid>
        <Card className='content-card' style={{ display: 'block', marginTop: '10px' }}>
          <Typography color='secondary' variant='p16' mb='10px'>
            {t('TimeToOpenYourAccount')}
          </Typography>
          <BulletedList color='secondary' style={{ marginTop: '6px' }}>
            {t('GivYourself5to10Minutes')}
            {t('HaveYourEmployerInfoReady')}
            {t('YouShouldBeAbleToOpenTheAccount')}
          </BulletedList>
        </Card>
      </Grid>
      {!toolsView && (
        <Grid item sm={2} mt={Spacing.xxs}>
          <Button
            fullWidth
            label='Back'
            color='secondary'
            variant='outlined'
            onClick={() => navigate(-1)}
          />
        </Grid>
      )}
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'NextAccount',
} satisfies LayoutMeta<HolisticFinancialAdviceLayout>;

export default withLayoutMeta(HowToOpenAnAccountView, meta);
