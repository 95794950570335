import React from 'react';
import { useTranslation } from 'react-i18next';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepThree: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={3}
      headerIcon={<Svgs.ArtPiggyBank width='100px' height='auto' />}
      headerText='LookAtHealthcareCosts'
      body={
        <BulletedList
          color='secondary'
          style={{ marginTop: '6px' }}
          children={[
            t('EitherYouPayAHigherDeductible'),
            t('IfYouAreRelativelyHealthy'),
            t('IfYouNeedToSeeASpecialistOften'),
          ]}
        />
      }
    />
  );
};

export default StepThree;
