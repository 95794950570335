import { Api, Services } from '@3nickels/data-modules';
import {
  Autocomplete,
  Box,
  CardHeader,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import TextInput from '../../../../../components/form/TextInput';
import { Percentages, Spacing } from '../../../../../themes';
import { debounce, trim } from 'lodash';
import { useService } from '@aesop-fables/containr-react';
import { Button } from '../../../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

interface AutocompleteOption {
  id?: number;
  ticker?: string;
  name?: string;
}

interface SecuritiesSearcherProps {
  onSelect: (security?: Api.SecurityDetailsRest) => void;
  autoFocus?: boolean;
}

const SecuritiesSearcher: React.FC<SecuritiesSearcherProps> = ({ onSelect }) => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const securitySearch = useService<Services.ISecuritySearchService>(
    Services.Services.SecuritySearch
  );
  const generalInvestmentsApi = useService<Api.GeneralInvestmentsApi>(
    Api.ApiKeys.GeneralInvestments
  );
  const [searching, setSearching] = useState(false);
  const [criteria, setCriteria] = useState<string>('');
  const [options, setOptions] = useState<Api.SecuritiesSearchRest[]>([]);
  const [generalInvestments, setGeneralInvestments] = useState<Api.GeneralInvestmentRest[]>([]);
  const [showGeneral, setShowGeneral] = useState<boolean | undefined>(false);
  const debounceSearch = useCallback(
    debounce(() => {
      search();
    }, 500),
    [showGeneral, criteria]
  );
  const placeholder = t('SearchByNameOrTicker');

  const toggleView = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowGeneral(undefined);

    setTimeout(() => {
      setShowGeneral(!showGeneral);
    }, 10);
  };

  async function search() {
    try {
      if (showGeneral) {
        setOptions(generalInvestments);
        return;
      } else if (!(trim(criteria).length > 0)) {
        setOptions([]);
        return;
      }

      const sorted = await securitySearch.search(criteria);
      setOptions(sorted);
    } catch (error) {
      console.error(error);
      setOptions([]);
    } finally {
      setSearching(false);
    }
  }

  useEffect(() => {
    const getGeneralInvestments = async () => {
      try {
        const investments = await generalInvestmentsApi.getAll();
        const sorted = investments.data.sort(
          (a, b) => a.ticker?.localeCompare(b.ticker ?? '') ?? 0
        );
        setGeneralInvestments(sorted);
      } catch (error) {
        console.error(error);
        setGeneralInvestments([]);
      }
    };
    getGeneralInvestments();
  }, []);

  useEffect(() => {
    if (typeof showGeneral === 'undefined') {
      setOptions([]);
    } else if (showGeneral) {
      setOptions(generalInvestments);
    } else {
      setSearching(true);
    }
  }, [showGeneral]);

  useEffect(() => {
    debounceSearch();
    return debounceSearch.cancel;
  }, [criteria, debounceSearch]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <Autocomplete
          loading={searching}
          clearOnBlur={false}
          disablePortal
          autoFocus
          options={
            showGeneral
              ? generalInvestments.map((inv) => {
                  return { ticker: inv.ticker, name: inv.securityName, id: inv.id };
                })
              : options.map((option) => {
                  return { ticker: option.ticker, name: option.securityName, id: option.id };
                })
          }
          getOptionLabel={(option) => option.name ?? ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(x) => x}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={async (e: any, selectedValue: AutocompleteOption | null) => {
            const security = await securitySearch.find(selectedValue?.id ?? 0);
            onSelect(security);
          }}
          onInputChange={(e, inputValue) => {
            setSearching(true);
            setCriteria(inputValue);
          }}
          renderInput={(params) => (
            <TextInput
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{searching && <CircularProgress size={20} />}</>,
              }}
              register={false}
              error={formState.errors.selected !== undefined}
              helperText={formState.errors.selected?.message?.toString()}
              label='FindYourInvestment'
              placeholder={placeholder}
              name='selected'
            />
          )}
          renderOption={(props, option) => {
            const title = showGeneral ? option.name : option.ticker;
            const subheader = showGeneral ? option.ticker : option.name;
            return (
              <li {...props}>
                <Grid container alignItems='center'>
                  <CardHeader
                    className='search-option'
                    disableTypography
                    color='primary'
                    style={{ backgroundColor: 'primary' }}
                    title={
                      <Typography
                        color='secondary'
                        variant='p16Bold'
                        component='p'
                        width={Percentages.Full}>
                        {title}
                      </Typography>
                    }
                    subheader={
                      <Typography color='secondary' variant='p12'>
                        {subheader}
                      </Typography>
                    }
                  />
                </Grid>
              </li>
            );
          }}
          PaperComponent={(props) => (
            <Paper {...props}>
              {typeof showGeneral === 'undefined' ? (
                <Box />
              ) : (
                <SearchResults
                  children={props.children}
                  criteria={criteria}
                  options={options}
                  searching={searching}
                  showGeneral={showGeneral}
                  toggleView={toggleView}
                />
              )}
            </Paper>
          )}
        />
      </Stack>
    </Box>
  );
};

interface SearchResultsProps extends PropsWithChildren {
  criteria?: string;
  options: AutocompleteOption[];
  searching: boolean;
  showGeneral?: boolean;
  toggleView: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  children,
  criteria,
  options,
  searching,
  showGeneral,
  toggleView,
}) => {
  const { t } = useTranslation();

  return showGeneral ? (
    <Grid>
      <Grid className='no-options' mb={-3} flexDirection='column'>
        <Typography variant='p16' color='secondary'>
          {t('SorryCouldntFindInvestment')}
        </Typography>
        <Button variant='ghost' onMouseDown={toggleView}>
          {t('SearchByNameOrTicker')}
        </Button>
      </Grid>
      {options.length > 0 && children && (children as JSX.Element)}
    </Grid>
  ) : (
    <>
      <Button variant='ghost' onMouseDown={toggleView}>
        {t('CantFindYourInvestment')}
      </Button>
      {options.length > 0 && children && (children as JSX.Element)}
      {options.length === 0 && criteria && !searching && (
        <Grid className='no-options'>
          <Typography color='error' variant='p14'>
            {t('NoMatchesFound')}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default SecuritiesSearcher;
