import { Domain } from '@3nickels/data-modules';

export enum AdviceDebtIncomePercentageTypeEnum {
  DebtToIncome = 36,
  HousingCost = 28,
  ConsumerDebt = 20,
}

export const getIsAboveRecommended = (recommended: number, real: number) => {
  return recommended > real;
};

export const isDebtIncomeSupported = (data: Domain.DebtIncomePercentageData) => {
  if (
    getIsAboveRecommended(data.debtToIncomeRatio, 36) ||
    getIsAboveRecommended(data.housingCostRatio, 28) ||
    getIsAboveRecommended(data.consumerDebtRatio, 20)
  ) {
    return false;
  }

  return true;
};
