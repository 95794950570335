import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolHeader from '../../../../components/ToolHeader';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

const HealthInsuranceChecklistView: React.FC = () => {
  return (
    <Box>
      <Grid container flexDirection='column'>
        <Stack spacing={Spacing.xxxs + 3}>
          <ToolHeader title='HealthInsuranceChecklist' />
          <StepOne />
          <StepTwo />
          <StepThree />
          <StepFour />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HealthInsuranceChecklistView, meta);
