import { Grid } from '@mui/material';
import { Api, Data, formatWholeDollars, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import { useForm } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Svgs } from '../../../../assets/svg';
import RealCostOfDebtForm from './RealCostOfDebtForm';
import RealCostOfDebtResults from './RealCostOfDebtResults';
import FormContent from '../../../../components/form/FormContent';
import { useMessage } from '../../../../hooks/useMessage';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useTranslation } from 'react-i18next';

const realCostOfDebtSchema = Yup.object({
  loanAmount: Yup.string()
    .required('Required')
    .test('loanAmount', 'Loan Amount must be greater than $0', (value) => {
      const cleanNumber = cleanWholeNumberStr(value);
      return cleanNumber > 0;
    }),
  interestRate: Yup.string().required('Required'),
  loanTermMonths: Yup.string().required('Required'),
});

const RealCostOfDebtCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Api.DebtRealCostResultRest | undefined>();
  const [realCostDebtFormData, setRealCostDebtFormData] = useState<Api.DebtRealCostRest>({});
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Api.DebtRealCostRest>(SavedFormsKeyEnum.RealCostOfDebt);
  const defaultValues = formData ?? {
    loanAmount: 0,
    interestRate: 0,
    loanTermMonths: undefined,
  };

  const methods = useForm<Api.DebtRealCostRest>({
    resolver: yupResolver(realCostOfDebtSchema),
    defaultValues: defaultValues,
  });

  const formatInputs = (values: Api.DebtRealCostRest): Api.DebtRealCostRest => {
    const formData: Api.DebtRealCostRest = {
      loanAmount: cleanWholeNumberStr((values.loanAmount ?? 0).toString()),
      interestRate: values.interestRate ?? 0,
      loanTermMonths: values.loanTermMonths ?? 0,
    };

    return formData;
  };

  const onSubmit = async (values: Api.DebtRealCostRest) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      setRealCostDebtFormData(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.RealCostOfDebt,
        formData: cleanedValues,
      });
      const results = await commands.execute(
        Data.Debt.Commands.CalculateDebtRealCost,
        cleanedValues
      );
      setResults(results);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!realCostDebtFormData) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Debt.Commands.CalculateDebtRealCostPdf,
        realCostDebtFormData
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'RealCostOfDebtResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <ToolTemplate
            header={
              results ? (
                <ToolHeader
                  icon={<Svgs.ArtDebt />}
                  onDownload={onDownload}
                  title={'HeresRealCostDebt'}
                  subtitle={'DecideIfPurchaseWorthIt'}
                />
              ) : undefined
            }
            inputColumn={
              <RealCostOfDebtForm
                methods={methods}
                onSubmit={onSubmit}
                results={results}
                defaultValues={defaultValues}
              />
            }
            outputColumn={<RealCostOfDebtResults results={results} />}
            boxItems={[
              {
                boxTitle: 'TotalCostOfLoan',
                boxResult:
                  results && results.totalInterestPaid && results.totalPrincipal
                    ? formatWholeDollars(results.totalInterestPaid + results.totalPrincipal)
                    : undefined,
              },
            ]}
          />
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(RealCostOfDebtCalculatorView, meta);
