import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import { Spacing } from '../../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type HomeOtherCostsFormProps = {
  homeOtherCosts?: Data.RealEstate.HomeOtherCostsFormData;
  onSubmit: (values: Data.RealEstate.HomeOtherCostsFormData) => Promise<void>;
};

const HomeOtherCostsForm: React.FC<HomeOtherCostsFormProps> = (props) => {
  const { formState } = useFormContext();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.RealEstate.HomeOtherCostsFormData>
          error={formState.errors.annualInsurance !== undefined}
          helperText={formState.errors.annualInsurance?.message?.toString()}
          defaultValue={props.homeOtherCosts?.annualInsurance}
          label='AnnualHomeownerInsurance'
          name='annualInsurance'
          type='dollar'
          placeholder='$0'
          autoFocus
        />
        <TextInput<Data.RealEstate.HomeOtherCostsFormData>
          error={formState.errors.monthlyHoaFees !== undefined}
          helperText={formState.errors.monthlyHoaFees?.message?.toString()}
          defaultValue={props.homeOtherCosts?.monthlyHoaFees}
          label='MonthlyHOAFees'
          name='monthlyHoaFees'
          type='dollar'
          placeholder='$0'
        />
        <TextInput<Data.RealEstate.HomeOtherCostsFormData>
          error={formState.errors.annualHomeRepairCosts !== undefined}
          helperText={formState.errors.annualHomeRepairCosts?.message?.toString()}
          defaultValue={props.homeOtherCosts?.annualHomeRepairCosts}
          label='AnnualMaintenanceAndRepairs'
          name='annualHomeRepairCosts'
          type='dollar'
          placeholder='$0'
        />
        <TextInput<Data.RealEstate.HomeOtherCostsFormData>
          error={formState.errors.annualPropertyTax !== undefined}
          helperText={formState.errors.annualPropertyTax?.message?.toString()}
          defaultValue={props.homeOtherCosts?.annualPropertyTax}
          label='AnnualPropertyTax'
          name='annualPropertyTax'
          type='dollar'
          placeholder='$0'
        />
        <TextInput<Data.RealEstate.HomeOtherCostsFormData>
          error={formState.errors.annualOtherHousingCosts !== undefined}
          helperText={formState.errors.annualOtherHousingCosts?.message?.toString()}
          defaultValue={props.homeOtherCosts?.annualOtherHousingCosts}
          label='AnnualOtherHousingCosts'
          name='annualOtherHousingCosts'
          type='dollar'
          placeholder='$0'
        />
      </Stack>
    </Box>
  );
};

export default HomeOtherCostsForm;
