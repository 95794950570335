import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Backdrop } from '@mui/material';
import { Loading } from '../../../hooks/useLoading';

interface LoadingMessage {
  header: string;
  subHeader?: string;
  caption: string;
  inverted?: boolean;
}

const loadingMessages: LoadingMessage[] = [
  // This one needs to ALWAYS go first
  {
    header: 'Retrieving your account information...',
    caption: 'Hang tight, this could take a little while.',
  },

  { header: 'Money can be stressful.', caption: 'How about a deep-breathing exercise?' },
  { header: 'Did you do it yet?', caption: 'inhale...' },
  { header: 'And now...', caption: '...exhale' },

  {
    header: 'Have you ever wondered where the name “3Nickels” came from?',
    caption: 'It’s a funny story…',
  },
  {
    header: 'It began with an allowance of three nickels,',
    caption: 'one to save, one to spend, one to give…',
  },
  {
    header: 'And now we have:',
    subHeader: 'Save. Spend. Give.',
    caption: 'To hear the whole story, check out the “About Us” section in the hamburger menu.',
  },

  {
    header: 'Do you like puzzles?',
    caption:
      'Our first Nic begins saving for retirement at 25 and saves for 10 years. He saves a total of $20,000.',
  },
  {
    header: 'Who has more money by the time they retire?',
    caption:
      'Our second Nic begins saving for retirement at 35 and saves for 30 years. She saves a total of $60,000.',
    inverted: true,
  },
  {
    header: 'Our first Nic has more! We know, it’s mind-blowing!',
    caption:
      'If you want to learn more, check out “Why Save Now?…Even If You’re In Your 20’s”, a video we’ve neatly placed in the Retirement module in our tools section.',
  },
  {
    header: 'Did you know?',
    caption:
      'Sixty-seven percent (that’s right, 67%!) of the population does not even have $1,000 in emergency fund savings.',
  },
  {
    header: 'Did you know?',
    caption: 'The average non-mortgage debt balance for those under age 35 is a whopping $64,000!',
  },
  {
    header: 'Fun Fact:',
    caption: 'Average total assets held by a millennial have a value of $8,000.',
  },
  {
    header: 'Did you know?',
    caption:
      'A medical journal has coined a new disease— debt stress syndrome. We’re here to help cure it.',
  },
  {
    header: 'Thanks for your patience!',
    caption: 'With all this time, imagine what compound interest could be doing for you!',
  },
  {
    header: 'We know, we know…',
    caption: 'With all this time, retirement probably doesn’t feel so far away anymore.',
  },
];

interface PlaidWaitScreenProps {
  autoLinkJob: Domain.AutoLinkJob;
  onComplete: () => void;
}

export const PlaidWaitScreen: React.FC<PlaidWaitScreenProps> = ({ autoLinkJob, onComplete }) => {
  const [, setMessage] = useState<LoadingMessage>(loadingMessages[0]);
  const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const polling = useRef<boolean>(false);
  const wizard = Hooks.usePlaidWizard();

  const pollAutoLinkJob = useCallback(async () => {
    if (polling.current) {
      return;
    }

    try {
      polling.current = true;
      const complete = await wizard.checkAutoJobStatus(autoLinkJob.id);
      if (complete && intervalRef.current) {
        clearInterval(intervalRef.current);
        onComplete();
        return;
      }

      polling.current = false;
    } catch (e) {
      // continue polling
      polling.current = false;
    }
  }, [autoLinkJob, wizard, onComplete]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index === loadingMessages.length - 1) {
        index = 0;
      } else {
        index += 1;
      }

      setMessage(loadingMessages[index]);
      pollAutoLinkJob();
    }, 5000);

    intervalRef.current = interval;
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <Backdrop open>
      <Loading />
    </Backdrop>
  );
};
