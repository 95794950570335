import { SvgIcon, SvgIconProps } from '@mui/material';

export function GoalsSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g id='3Nickels-Web' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='action/small/help/default'>
          <rect id='Rectangle' x='0' y='0' width='25' height='25'></rect>
          <g id='Group-Copy-2' transform='translate(2, 4)' fill={props.fill} fill-rule='nonzero'>
            <path
              d='M19.1721616,8.5 L20.872649,6.23776908 C21.0115777,6.04924984 21.0338063,5.80528376 20.9282205,5.59458578 C20.8226347,5.3838878 20.611463,5.25636008 20.3780628,5.25636008 L17.0826739,5.25636008 C16.8881737,5.25636008 16.7047878,5.34507502 16.5880877,5.50032616 L14.6153,8.1285062 C14.4485856,8.34474886 14.4485856,8.64416177 14.6153,8.86594912 L16.5936448,11.4941292 C16.7103449,11.6493803 16.8937308,11.7380952 17.088231,11.7380952 L20.3836199,11.7380952 C20.6170202,11.7380952 20.8281918,11.6050228 20.9337776,11.3998695 C21.0393634,11.1891716 21.0171348,10.9452055 20.8782061,10.7566862 L19.1721616,8.5 Z'
              id='Path'></path>
            <path
              d='M11.7144686,7.88454012 L13.1370986,7.88454012 C12.8370126,5.61121983 10.8808964,3.85355512 8.52466548,3.85355512 C5.95726297,3.85355512 3.86777521,5.93835616 3.86777521,8.5 C3.86777521,11.0616438 5.95726297,13.1464449 8.52466548,13.1464449 C10.8808964,13.1464449 12.8370126,11.3887802 13.1370986,9.11545988 L11.7144686,9.11545988 C11.4254969,10.6125245 10.1028956,11.7436399 8.52466548,11.7436399 C6.72970658,11.7436399 5.27373373,10.2909328 5.27373373,8.5 C5.27373373,6.70906719 6.72970658,5.25636008 8.52466548,5.25636008 C10.1028956,5.25636008 11.4254969,6.38747554 11.7144686,7.88454012 Z'
              id='Path'></path>
            <path
              d='M15.6100296,12.2315721 L14.1318281,10.2687541 C13.3760559,12.6474234 11.1476395,14.37182 8.51910833,14.37182 C5.27373373,14.37182 2.63408829,11.7380952 2.63408829,8.5 C2.63408829,5.26190476 5.27373373,2.62818004 8.51910833,2.62818004 C11.1476395,2.62818004 13.3760559,4.35257665 14.1318281,6.73124592 L15.6100296,4.76842792 C15.7156154,4.62426614 15.8434298,4.50228311 15.9823585,4.39693412 C14.5319428,1.77429876 11.73114,0 8.51910833,0 C3.81220373,0 0,3.80919765 0,8.5 C0,13.1908023 3.81220373,17 8.51910833,17 C11.73114,17 14.5319428,15.2257012 15.9823585,12.6030659 C15.8434298,12.4977169 15.7156154,12.3757339 15.6100296,12.2315721 L15.6100296,12.2315721 Z'
              id='Path'></path>
            <path
              d='M8.51910833,7.88454012 L10.4418816,7.88454012 C10.1806957,7.07501631 9.41936636,6.48173516 8.51910833,6.48173516 C7.40767867,6.48173516 6.49630635,7.38551859 6.49630635,8.5 C6.49630635,9.61448141 7.40212153,10.5182648 8.51910833,10.5182648 C9.41936636,10.5182648 10.1806957,9.93052838 10.4418816,9.11545988 L8.51910833,9.11545988 C8.18012229,9.11545988 7.90226487,8.8382257 7.90226487,8.5 C7.90782202,8.15622962 8.18012229,7.88454012 8.51910833,7.88454012 Z'
              id='Path'></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
