import { Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { convertToTitleCase } from '../helpers/utilityFunctions';

function convertPathnameToCategory(pathname: string): string {
  switch (pathname) {
    case 'credit-card':
      return 'Credit_Card';
    default:
      return convertToTitleCase(pathname);
  }
}

export function useLifestyleProductsByCategory() {
  const lifestyleProducts = Hooks.useLifestyleProducts();
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const targetPathname = pathnameSegments[toolsIndex + 1];

  const filteredProducts = useMemo(() => {
    return lifestyleProducts?.filter((product) =>
      product?.categories?.find((x) => x.category === convertPathnameToCategory(targetPathname))
    );
  }, [lifestyleProducts]);

  return filteredProducts;
}
