import { Api, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { GoalBannerIcon } from '../../../../goals';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { GoalCardProps } from './Types';
import GoalCardHeader from './components/GoalCardHeader';
import GoalCardSummary from './components/GoalCardSummary';
import GoalCardDetails from './components/GoalCardDetails';

dayjs.extend(duration);

interface RetirementGoalCardProps extends GoalCardProps {
  ssi: number;
}

const RetirementGoalCard: React.FC<RetirementGoalCardProps> = ({ goal, ssi }) => {
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;
  const includeSpouse = Hooks.useIncludeSpouse();

  const originalRetirementGoal = goalPlan as Api.RetirementGoalRest;
  const retirementGoal = advisedGoalPlan as Api.RetirementGoalRest;

  return (
    <CollapsiblePanel
      variant='shadow'
      header={<GoalCardHeader goal={goal} />}
      expandIconPosition='end'
      icon={<GoalBannerIcon type={goalPlan.goalType} />}>
      <Grid container gap='20px'>
        <GoalCardSummary
          mainContent={
            <>
              <strong>{formatWholeDollars(retirementGoal.projectedMonthlyIncome ?? 0)}</strong>/mo
            </>
          }
          subContent='Monthly Retirement Income'
          helpContent={
            <Grid>
              <Typography variant='p16Bold' color='secondary'>
                Monthly Retirement Income
              </Typography>
              <Typography variant='p16' color='secondary' mt={1}>
                This number includes an estimated {formatWholeDollars(ssi)}
                /month of Social Security income.
              </Typography>
            </Grid>
          }
        />
        {(goalStatus === Domain.GoalStatusEnum.OnTarget ||
          goalStatus === Domain.GoalStatusEnum.PartiallyFunded) && (
          <GoalCardDetails
            details={[
              {
                key: 'Monthly Savings',
                value: formatWholeDollars(
                  (retirementGoal.monthlySavings ?? 0) +
                    (retirementGoal.outOfPlanSavingsAmount ?? 0)
                ),
              },
              {
                key: 'My Retirement Age',
                value: (retirementGoal.primary?.retirementAge ?? 0).toString(),
              },
              {
                key: `Spouse's Retirement Age`,
                value: (retirementGoal.spouse?.retirementAge ?? 0).toString(),
                hide: !includeSpouse,
              },
            ]}
          />
        )}
        {originalRetirementGoal.primary?.retirementAge !==
          retirementGoal.primary?.retirementAge && (
          <Typography variant='p12' color='accent'>
            *To help you save a bit more, we recommend retiring later at{' '}
            {retirementGoal.primary?.retirementAge}.
          </Typography>
        )}
      </Grid>
    </CollapsiblePanel>
  );
};

export default RetirementGoalCard;
