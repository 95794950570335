import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type HouseGoalFormProps = {
  model?: Data.Goals.BasicGoalFormData;
  onSubmit: (values: Data.Goals.BasicGoalFormData) => Promise<void>;
};

const HouseGoalForm: React.FC<HouseGoalFormProps> = (props) => {
  const { formState, watch } = useFormContext();
  const { model } = props;
  const [showTargetDate, setShowTargetDate] = useState(false);

  useEffect(() => {
    if (props.model?.targetDateType === Domain.GoalDateTypeEnum.ChooseDate) {
      setShowTargetDate(true);
    }
  }, [props.model]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'targetDateType') {
        const targetDateType = values[name];
        setShowTargetDate(targetDateType === Domain.GoalDateTypeEnum.ChooseDate);
      }
    });

    return () => subscription.unsubscribe();
  });

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Goals.BasicGoalFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.model?.name}
          inputProps={{ maxLength: 64 }}
          label='Name'
          name='name'
          autoFocus
        />

        <TextInput<Data.Goals.BasicGoalFormData>
          error={formState.errors.amount !== undefined}
          helperText={formState.errors.amount?.message?.toString()}
          defaultValue={props.model?.amount}
          label='Amount'
          name='amount'
          type='dollar'
          autoFocus
        />

        <ToggleRadioButtonGroup<Data.Goals.BasicGoalFormData>
          error={formState.errors.targetDateType !== undefined}
          helperText={formState.errors.targetDateType?.message?.toString()}
          name='targetDateType'
          label='Target Date Type'
          row
          defaultValue={model?.targetDateType}
          items={[
            { label: 'ASAP', value: Domain.GoalDateTypeEnum.ASAP },
            { label: 'Choose Date', value: Domain.GoalDateTypeEnum.ChooseDate },
          ]}
        />
        {showTargetDate && (
          <TextInput<Data.Goals.BasicGoalFormData>
            error={formState.errors.targetDate !== undefined}
            helperText={formState.errors.targetDate?.message?.toString()}
            defaultValue={props.model?.targetDate}
            label='Target Date'
            name='targetDate'
            type='dateInput'
          />
        )}
      </Stack>
    </Box>
  );
};

export default HouseGoalForm;
