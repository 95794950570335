import { Box } from '@mui/material';
import React from 'react';
import { desert } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import HowMuchDebtCard from './cards/HowMuchDebtCard';
import DebtConsolidationCard from './cards/DebtConsolidationCard';
import RealCostOfDebtCard from './cards/RealCostOfDebtCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const DebtToolStartView: React.FC = () => {
  const explore = useExploreGroups();

  const calculators: Group[] = [
    {
      textKey: 'how-much-debt',
      url: 'how-much-debt',
      content: <HowMuchDebtCard />,
    },
    {
      textKey: 'debt-consolidation-tool',
      url: 'debt-consolidation-tool',
      content: <DebtConsolidationCard />,
    },
    {
      textKey: 'real-cost-of-debt',
      url: 'real-cost-of-debt',
      content: <RealCostOfDebtCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplaySmallCustomDebt2 />}
        label='Debt'
        calculators={calculators}
        resources={[]}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={desert} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(DebtToolStartView, meta);
