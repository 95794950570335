import { Box, Drawer, Typography } from '@mui/material';
import { useDeviceType } from '../hooks/useDeviceType';
import Spacer from './Spacer';

interface RightDrawerProps extends React.PropsWithChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  smallTitle?: string;
  title?: string;
  minWidth?: string;
  role?: string;
}

const RightDrawer: React.FC<RightDrawerProps> = (props) => {
  const { open, setOpen, smallTitle, title, minWidth, role } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const { isMobile, isTablet } = useDeviceType();

  return (
    <>
      {isMobile || isTablet ? (
        <Drawer
          className='mobile-drawer'
          anchor='right'
          role={role}
          open={open}
          onClose={handleClose}>
          <Box>
            {smallTitle ? (
              <>
                <Typography color='secondary' variant='p14'>
                  {smallTitle}
                </Typography>
                <Spacer height='xxs' />
              </>
            ) : null}
            {title ? (
              <>
                <Typography color='primary' variant='p30Bold'>
                  {title}
                </Typography>
              </>
            ) : null}
            {props.children}
          </Box>
        </Drawer>
      ) : (
        <Drawer className='browser-drawer' anchor='right' open={open} onClose={handleClose}>
          <Box role='presentation' sx={{ padding: '20px', minWidth: minWidth ?? undefined }}>
            {smallTitle ? (
              <>
                <Typography color='secondary' variant='p14'>
                  {smallTitle}
                </Typography>
                <Spacer height='xxs' />
              </>
            ) : null}
            {title ? (
              <>
                <Typography color='primary' variant='p30Bold'>
                  {title}
                </Typography>
              </>
            ) : null}
            {props.children}
          </Box>
        </Drawer>
      )}
    </>
  );
};
export default RightDrawer;
