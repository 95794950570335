import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import { formatWholeDollars } from '../../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { Data, Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';

export interface RealEstateDetailsCardProps extends SummaryCardProps {
  propertyDetails: Data.RealEstate.HousingEntityDetailsFormData;
}

const RealEstateDetailsCard: React.FC<RealEstateDetailsCardProps> = ({
  onEdit,
  propertyDetails,
}) => {
  const { t } = useTranslation();
  const missingLoanInfo = useMemo(
    () => typeof propertyDetails.hasLoan === 'undefined' || propertyDetails.hasLoan === null,
    [propertyDetails.hasLoan]
  );

  return (
    <CardDisplay headerKey='MortgageDetails' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Mortgage?')}
          </Typography>
          <Typography color={missingLoanInfo ? 'error' : 'secondary'}>
            {missingLoanInfo ? t('AnswerNeeded') : t(propertyDetails.hasLoan ? 'Yes' : 'No')}
          </Typography>
        </Grid>
        {propertyDetails.hasLoan ? (
          <>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('CurrentBalance')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(propertyDetails.amountOwed ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('InterestRate')}
              </Typography>
              <Typography color='secondary'>{propertyDetails.interestRate}%</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MortgageTerm')}
              </Typography>
              <Typography color='secondary'>
                {Domain.MortgageTermTypeEnumMap.get(propertyDetails.mortgageTerm as string)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('MonthlyMortgagePayment')}
              </Typography>
              <Typography color='secondary'>
                {formatWholeDollars(propertyDetails.minimumPayment ?? 0)}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
            <Grid item xs />
          </>
        )}
      </Grid>
    </CardDisplay>
  );
};

export default RealEstateDetailsCard;
