/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import CashAccountBasicsForm from './CashAccountBasicsForm';
import { firstValueFrom, map } from 'rxjs';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';

const cashAccountBasicsSchema = (includeSpouse: boolean) =>
  Yup.object({
    accountType: Yup.string()
      .required('Required')
      .test('cashAccountType', 'Required', (type) =>
        Domain.CashAccountTypeOptions.some((option) => {
          return option.key === type;
        })
      ),
    name: Yup.string().required('Required'),
    owner: includeSpouse ? Yup.string().required('Required') : Yup.string().notRequired(),
    principle: Yup.string()
      .required('Required')
      .test('principle', 'Balance must be greater than $0', (balance) => {
        const cleanNumber = cleanWholeNumberStr(balance);
        return cleanNumber > 0;
      }),
  });

export const CashAccountBasicsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.useCashAccountWizard<Data.CashAccounts.CashAccountBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <CashAccountBasicsEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface CashAccountBasicsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.CashAccounts.CashAccountBasicFormData,
    Data.CashAccounts.CashAccountWizardParams
  >;
  wizard: Data.CashAccounts.CashAccountWizard;
  params: Data.CashAccounts.CashAccountWizardParams;
}

const CashAccountBasicsEditView: React.FC<CashAccountBasicsEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const includeSpouse = Hooks.useIncludeSpouse();
  const methods = useForm<Data.CashAccounts.CashAccountBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(cashAccountBasicsSchema(includeSpouse)),
  });
  const { setLoading } = useLoading();

  const onSubmit = async (values: Data.CashAccounts.CashAccountBasicFormData) => {
    setLoading(true);

    try {
      currentStep.save({
        ...values,
        principle: cleanWholeNumberStr(values.principle as unknown as string, { float: true }),
      });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/cash-account/basics/${wizardParams?.id}`, { replace: true });
      navigate(`/account-details/cash-account/summary/${wizardParams?.id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t(editing ? 'AccountBasics' : 'AddCashAccount')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CashAccountBasicsForm
                    cashAccountBasics={currentStep.model}
                    onSubmit={onSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 1,
    currentStep: 1,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(CashAccountBasicsEditViewWrapper, meta);
