import React, { useEffect, useRef, useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { t } from 'i18next';

interface OrgMissionProps {
  mission: string | undefined;
}

const OrgMission: React.FC<OrgMissionProps> = ({ mission }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [displayedText, setDisplayedText] = useState<string | undefined>('');
  const [limit, setLimit] = useState<number>(40);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const newLimit = Math.floor(container.offsetWidth / 27.5);
      setLimit(newLimit);
    });

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (!mission) return;
    if (showMore) {
      setDisplayedText(mission);
    } else {
      const words = mission.split(' ');
      if (words.length > limit) {
        setDisplayedText(words.slice(0, limit).join(' ') + '...');
      } else {
        setDisplayedText(mission);
      }
    }
  }, [showMore, mission, limit]);

  return (
    <Grid ref={containerRef}>
      <Typography mb={1} variant='p22Bold' color='primary'>
        {t('OrganizationMission')}
      </Typography>
      <Card className='content-card'>
        <Typography variant='p16' color='secondary' className='more-less'>
          {displayedText}
          {mission && mission.split(' ').length > limit && (
            <span onClick={() => setShowMore(!showMore)}>
              &nbsp;{showMore ? '(less)' : '(more)'}
            </span>
          )}
        </Typography>
      </Card>
    </Grid>
  );
};

export default OrgMission;
