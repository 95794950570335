import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { OtherAssetFormData } from './Types';

export type OtherAssetBasicsFormProps = {
  asset?: OtherAssetFormData;
  onSubmit: (values: OtherAssetFormData) => Promise<void>;
};

const OtherAssetBasicsForm: React.FC<OtherAssetBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<OtherAssetFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.asset?.name}
          inputProps={{ maxLength: 64 }}
          label='OtherAssetNickname'
          name='name'
          autoFocus
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<OtherAssetFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            defaultValue={props.asset?.owner}
            items={[
              { label: 'Me', value: 'me' },
              { label: 'Spouse', value: 'spouse' },
            ]}
          />
        )}
        <TextInput<OtherAssetFormData>
          error={formState.errors.value !== undefined}
          helperText={formState.errors.value?.message?.toString()}
          defaultValue={props.asset?.value}
          label='CurrentValueOfAsset'
          name='value'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default OtherAssetBasicsForm;
