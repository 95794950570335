import { Grid, Table, TableBody, TableContainer } from '@mui/material';
import { Domain, Hooks } from '@3nickels/data-modules';
import UnitProgressCard from './../components/UnitProgressCard';
import { Button } from '../../../components/buttons/Button';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

interface CourseProgressSideViewProps {
  selectedLesson?: Domain.LessonNavigation;
}

const CourseProgressSideView: React.FC<CourseProgressSideViewProps> = () => {
  const navigate = useNavigate();
  const { title } = useParams();
  const units = Hooks.useUniversityUnits();
  const selectedLesson = Hooks.useUniversityLessonByTitle(title ?? '');

  const startPreviousLesson = () => {
    if (selectedLesson?.previous) {
      navigate(`/learning/university/${encodeURIComponent(selectedLesson.previous.title)}`);
    }
  };

  const startNextLesson = () => {
    if (selectedLesson?.next) {
      navigate(`/learning/university/${encodeURIComponent(selectedLesson.next.title)}`);
    }
  };

  if (!selectedLesson) return null;

  return (
    <Grid width='340px'>
      <Grid display='flex' justifyContent='space-between' mb='20px'>
        <Grid>
          {selectedLesson?.previous && (
            <Button
              label='Previous'
              variant='outlined'
              startIcon={<ChevronLeft />}
              onClick={startPreviousLesson}
            />
          )}
        </Grid>
        {selectedLesson?.next && (
          <Button label='Next' endIcon={<ChevronRight />} onClick={startNextLesson} />
        )}
      </Grid>
      <Grid className='course-progress-side-view'>
        <TableContainer>
          <Table>
            <TableBody>
              {units.map((unit) => (
                <UnitProgressCard
                  unit={unit}
                  selectedLesson={selectedLesson?.lesson}
                  variant='compressed'
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CourseProgressSideView;
