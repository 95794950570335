import { Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SkeletonCourses: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Learn')}
      </Typography>
      <Grid className='3NU-section' style={{ display: 'inherit' }}>
        <Grid mt={4} className='content-section' columnGap='10px' paddingBottom='55px'>
          <Grid
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifySelf='flex-start'
            width='30%'
            pb={1}>
            <Skeleton variant='circular' width='150px' height='150px' />
          </Grid>

          <Grid className='course-details' paddingX='10px'>
            <Skeleton variant='rounded' width='140px' height='25px' />
            <Skeleton style={{ marginTop: '10px' }} variant='rounded' width='180px' height='40px' />
            <Skeleton style={{ marginTop: '10px' }} variant='rounded' width='30px' height='15px' />
          </Grid>
          <Grid justifyContent='end'>
            <Skeleton
              style={{ marginTop: '10px', borderRadius: '90px' }}
              variant='rounded'
              width='150px'
              height='40px'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkeletonCourses;
