import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../../components/form/TextInput';
import { Spacing } from '../../../../../themes';

export interface InvestmentBalanceFormData {
  balance?: number;
}

export type InvestmentBalanceFormProps = {
  onSubmit: (values: InvestmentBalanceFormData) => Promise<void>;
};

const InvestmentBalanceForm: React.FC<InvestmentBalanceFormProps> = () => {
  const { formState } = useFormContext();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<InvestmentBalanceFormData>
          error={formState.errors.balance !== undefined}
          helperText={formState.errors.balance?.message?.toString()}
          label='InvestmentBalance'
          name='balance'
          type='dollar'
          autoFocus
        />
      </Stack>
    </Box>
  );
};

export default InvestmentBalanceForm;
