import React from 'react';
import { MobileTopNavBar } from './MobileTopNavBar';
import { Grid } from '@mui/material';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface MobileScreenWrapperProps extends React.PropsWithChildren {
  childrenWithoutPadding?: React.ReactNode;
}

export const MobileScreenWrapper: React.FC<MobileScreenWrapperProps> = ({
  children,
  childrenWithoutPadding,
}) => {
  const { height } = useWindowDimensions();

  return (
    <Grid className='page-content-with-background' sx={{ overflow: 'scroll', height: height }}>
      <MobileTopNavBar />
      <Grid className='content-view' gridArea='content'>
        <Grid padding={'20px'}>{children}</Grid>
        {childrenWithoutPadding && <Grid>{childrenWithoutPadding}</Grid>}
      </Grid>
    </Grid>
  );
};
