/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonBase } from '@mui/material';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useService } from '@aesop-fables/containr-react';
import { Api } from '@3nickels/data-modules';
import { AUTH_URL } from '../configuration/Environment';

declare const AppleID: any;

const AppleLogin = () => {
  const authApi = useService<Api.AuthApi>(Api.ApiKeys.AuthApi);
  const [appleLoaded, setAppleLoaded] = useState(false);
  const [clientId, setClientId] = useState<string | undefined>(undefined);

  // load apple auth
  useEffect(() => {
    const loadClientId = async () => {
      const { data } = await authApi.getAppleClientId();
      setClientId(data.webClientId);
    };
    loadClientId();
    const loadScript = document.createElement('script');

    loadScript.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    loadScript.addEventListener('load', () => setAppleLoaded(true));
    document.body.appendChild(loadScript);

    return () => {
      document.body.removeChild(loadScript);
    };
  }, [authApi]);

  useEffect(() => {
    if (appleLoaded && clientId) {
      const initScript = document.createElement('script');

      initScript.type = 'text/javascript';
      initScript.innerHTML = AppleID.auth.init({
        clientId,
        redirectURI: AUTH_URL + 'apple/web',
        scope: 'name email',
        state: Cookies.get('APPLE-XSRF-TOKEN'),
        usePopup: false,
      });
    }
  }, [appleLoaded, clientId]);

  return (
    <ButtonBase
      style={{
        borderRadius: '50px',
        height: '40px',
        paddingTop: '5px',
        width: '100%',
      }}
      sx={{
        '& div[role=button]': {
          'width': '100%',
          'display': 'flex',
          '& > div': {
            width: '100% !important',
          },
          '& > svg': {
            'width': '100%',
            '& > rect': {
              width: '100%',
            },
          },
          '& text': {
            fontSize: '12px !important',
          },
          '& span': {
            width: '10px !important',
          },
        },
      }}>
      <div
        style={{ width: '100%', justifyContent: 'center' }}
        id='appleid-signin'
        data-color='black'
        data-border='true'
        data-border-radius='50'
        data-width='250'
        data-height='40'
        data-mode='left-align'
        data-logo-size='large'
        data-label-position='70'
        data-type='sign in'
      />
    </ButtonBase>
  );
};

export default AppleLogin;
