import { Button } from '../../../components/buttons/Button';
import React from 'react';
import DropdownMenu from '../../../components/menu/DropDownMenu';
import { useNavigate } from 'react-router-dom';
import { Hooks } from '@3nickels/data-modules';

const HeaderContent: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);
  const navigate = useNavigate();
  const plaidWizard = Hooks.usePlaidWizard();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button variant='contained' label='AddAccount' onClick={handleMenu} />
      <DropdownMenu
        onClose={handleClose}
        element={anchorEl}
        menuLinks={[
          {
            key: 'LinkAnAccount',
            onClick: () => {
              plaidWizard.reset();
              navigate('link-account');
              handleClose();
            },
          },
          {
            key: 'ManuallyAddAnAccount',
            onClick: () => navigate('manual-account'),
          },
        ]}
      />
    </>
  );
};

export default HeaderContent;
