import { Domain, Hooks } from '@3nickels/data-modules';
import AddInvestments from './AddInvestments';
import { Box, Drawer } from '@mui/material';
import { Spacing } from '../../../../themes';
import { Dispatch, SetStateAction, useCallback } from 'react';
import InvestmentBalanceEditView from './balance/InvestmentBalanceEditView';
import InvestmentDetailsEditView from './investment-details/InvestmentDetailsEditView';
import AddInvestmentSelection from './AddInvestmentsSelection';
import { useNavigate } from 'react-router-dom';

export interface InvestmentDrawerStatus {
  open: boolean;
  drawer?: 'investment' | 'balance';
  investment?: Domain.InvestmentData;
  usePlaid?: boolean;
}

export const InvestmentsView: React.FC = () => {
  const { account, investments } = Hooks.useInvestmentContext();
  const plaidWizard = Hooks.usePlaidWizard();
  const navigate = useNavigate();

  if (!account?.id) {
    return null;
  }

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.InvestmentAccount
    );
    navigate('/account-details/link-account/choose-institution');
  }, [plaidWizard, account]);

  return (
    <>
      {investments.length === 0 && (
        <AddInvestmentSelection
          account={account}
          investments={investments}
          linkAccount={linkAccountHandler}
        />
      )}
      {investments.length > 0 && (
        <AddInvestments
          account={account}
          investments={investments}
          linkAccount={linkAccountHandler}
        />
      )}
    </>
  );
};

export interface InvestmentDrawerProps {
  drawerStatus: InvestmentDrawerStatus;
  setDrawerStatus: Dispatch<SetStateAction<InvestmentDrawerStatus>>;
}

export const InvestmentDrawer: React.FC<InvestmentDrawerProps> = ({
  drawerStatus,
  setDrawerStatus,
}) => {
  const { selectedInvestment, selectInvestment } = Hooks.useInvestmentContext();
  const closeDrawer = () => {
    setDrawerStatus({ open: false });
    selectInvestment(undefined);
  };

  return (
    <Drawer
      anchor='right'
      open={drawerStatus.open}
      onClose={() => {
        setDrawerStatus({ open: false });
      }}>
      <Box p={Spacing.xxs}>
        {drawerStatus.drawer === 'investment' && (
          <InvestmentDetailsEditView
            editing={typeof selectedInvestment !== typeof undefined}
            onBack={closeDrawer}
          />
        )}
        {drawerStatus.drawer === 'balance' && (
          <InvestmentBalanceEditView editing onBack={closeDrawer} />
        )}
      </Box>
    </Drawer>
  );
};

const InvestmentViewWrapper: React.FC<Hooks.InvestmentManagerProps> = ({ investmentAccountId }) => {
  return (
    <Hooks.InvestmentManager investmentAccountId={investmentAccountId}>
      <InvestmentsView />
    </Hooks.InvestmentManager>
  );
};

export default InvestmentViewWrapper;
