import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { Colors, Spacing } from '../../../themes';
import BulletedList from '../../../components/BulletedList';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const IconAuthorsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Stack spacing={Spacing.xxxs + 1}>
          <Typography variant='p30Bold' component='h1' color='primary'>
            {t('IconAuthorAttribution')}
          </Typography>
          <Typography color='secondary' variant='p18Bold'>
            Flaticon
          </Typography>
          <Typography className='link-wrapper' color='secondary' variant='p16'>
            Select icons made by the following authors from&nbsp;
            <Link href='https://www.flaticon.com' target='_blank'>
              www.flaticon.com
            </Link>
            :
          </Typography>

          <BulletedList
            color='secondary'
            children={[
              'Bqlqn',
              'Dmitri13',
              'Fjstudio',
              'Freepik',
              'Good Ware',
              'Gregor Cresnar',
              'Icongeek26',
              'Ilham Fitrotul Hayat',
              'Itim2101',
              'Kiranshastry',
              'Kirill Kazachek',
              'Payungkead',
              'Photo3idea_studio',
              'Pixel Perfect',
              'Prettycons',
              'Roundicons',
              'Smartline',
              'Smashicons',
              'Srip',
              'Surang',
              'Those Icons',
            ]}
          />
          <Divider sx={{ borderColor: Colors.primaryLight, width: '295px' }} />
          <Typography color='secondary' variant='p18Bold'>
            Google
          </Typography>
          <Typography color='secondary' variant='p16'>
            Select icons made by Google.
          </Typography>
          <Divider sx={{ borderColor: Colors.primaryLight, width: '295px' }} />
          <Typography color='secondary' variant='p18Bold'>
            Nucleo
          </Typography>
          <Typography color='secondary' variant='p16'>
            Select icons made by Nucleo.
          </Typography>
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(IconAuthorsView, meta);
