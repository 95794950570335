import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepFour: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={<Svgs.ArtApproved />}
      headerText='GetPreApproved'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('GettingPreApprovedForAMortgageShows')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('LendersWillWantToKnowYourDebtToIncome'),
              t('CompareTheOffersOfDifferentLenders'),
              t('EnsureYouHaveTheNecessaryDocuments'),
              t('BeMindfulOfHowLong'),
            ]}
          />
        </>
      }
      related={[{ textKey: 'HowMuchDebtCanIAfford', url: '/tools/debt/how-much-debt' }]}
    />
  );
};

export default StepFour;
