/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Divider, Card, Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Spacing, Colors } from '../themes';
import { Dispatch, SetStateAction } from 'react';
import ToolHeader from './ToolHeader';

interface ToolTemplateProps {
  header?: JSX.Element;
  inputColumn: JSX.Element;
  outputColumn: JSX.Element;
  outputTabs?: string[];
  onOutputTabSelected?: Dispatch<SetStateAction<any>>;
  selectedOutputTab?: string;
  boxItems?: {
    boxTitle: string;
    boxResult?: string;
    noResult?: boolean;
    noResultContent?: JSX.Element;
  }[]; // does this replace box title and box result and noResult?
}

const ToolTemplate: React.FC<ToolTemplateProps> = ({
  header,
  inputColumn,
  outputColumn,
  outputTabs,
  onOutputTabSelected,
  selectedOutputTab,
  boxItems,
}) => {
  const { t } = useTranslation();

  const startHeader = <ToolHeader title='StartByAddingAFewDetails' />;

  return (
    <Grid container flexDirection='column'>
      <Stack spacing={Spacing.xxs}>
        {header ?? startHeader}
        <Grid container alignItems='flex-start'>
          <Grid item xs={5} container direction='column' justifyContent='flex-start'>
            {inputColumn}
          </Grid>
          <Divider
            orientation='vertical'
            sx={{ borderColor: Colors.primaryLight, margin: '0px 50px' }}
            flexItem
          />
          <Grid
            item
            xs={5}
            container
            direction='column'
            justifyContent='flex-start'
            minWidth='300px'>
            {outputTabs && onOutputTabSelected && (
              <Grid
                item
                container
                direction='row'
                justifyContent='center'
                alignSelf='center'
                mb={3}>
                {outputTabs.map((tab) => (
                  <Box
                    onClick={() => onOutputTabSelected(tab)}
                    sx={{ borderBottom: selectedOutputTab === tab ? 3 : 0, cursor: 'pointer' }}>
                    <Typography
                      mb={2}
                      mr={3}
                      ml={3}
                      variant='p18Bold'
                      color={selectedOutputTab === tab ? 'secondary' : 'primary'}>
                      {tab}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            )}
            {boxItems && (
              <Card className='tools-output-box'>
                {boxItems.map((boxItem) =>
                  boxItem.noResult ? (
                    boxItem.noResultContent ? (
                      boxItem.noResultContent
                    ) : (
                      <Grid paddingX='20px'>
                        <Typography variant='p16' color='error'>
                          {t('SorryNoDataToMatch')}
                        </Typography>
                      </Grid>
                    )
                  ) : (
                    <Grid item container direction='row' justifyContent='space-around'>
                      <Grid item container direction='column' alignItems='center'>
                        <Typography variant='p16' color='secondary'>
                          {t(`${boxItem.boxTitle}`)}
                        </Typography>
                        <Typography variant='p30Bold' color='secondary' fontFamily='Montserrat'>
                          {boxItem.boxResult ?? '– – –'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </Card>
            )}
            {outputColumn}
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default ToolTemplate;
