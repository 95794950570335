import BudgetDetailsAverages from '../pages/budget-details/BudgetDetailsAverages';
import BudgetDetailsHome from '../pages/budget-details/BudgetDetailsHome';
import SpendingSummaryOverview from '../pages/spending-summary/SpendingSummaryOverview';
import SpendingSummaryTransactionsView from '../pages/spending-summary/SpendingSummaryTransactionsView';

export const BudgetDetailsRoutes = [
  {
    index: true,
    element: <BudgetDetailsHome />,
  },
  {
    path: 'averages',
    element: <BudgetDetailsAverages />,
  },
  {
    path: 'spending',
    children: [
      { index: true, element: <SpendingSummaryOverview /> },
      { path: 'transactions', element: <SpendingSummaryTransactionsView /> },
    ],
  },
];
