import { redirect } from 'react-router-dom';
import { RedirectContext, RedirectHandler } from './RedirectHandler';

export class MissingPersonalInfoHandler implements RedirectHandler {
  async matches(context: RedirectContext) {
    return !context.birthDate || !context.firstName || !context.lastName;
  }

  handle(): Response {
    return redirect('/personal-info');
  }
}
