import { Box, Grid, Stack, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { IconContentRow } from '../../../../components/IconContentRow';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { frontPageInvestmentBrandColors } from '../../../../assets/png';

const CutSpendingView: React.FC = () => {
  return (
    <Box>
      <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
        {t('25WaysToCutSpending')}
      </Typography>
      <Grid container flexWrap='nowrap' justifyContent='space-between'>
        <Grid item display='flex' flexDirection='column' rowGap='40px'>
          <FirstSection />
          <SecondSection />
          <ThirdSection />
          <FourthSection />
          <FifthSection />
        </Grid>
        <Grid sm={5}>
          <Grid
            component='img'
            position='sticky'
            top='20vh'
            src={frontPageInvestmentBrandColors}
            onScroll={(e) => e.preventDefault()}
            width='100%'
            height='auto'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const FirstSection: React.FC = () => {
  return (
    <Stack spacing={3}>
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomReceipt />}
        title='1SaveYourReceipts'
        text='LookAtYourReceipts'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomEnvelope />}
        title='2SetUpAutoBillPay'
        text='LetYourBankHandleYourBills'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomBank />}
        title='3FindHighYieldAccount'
        text='HighYieldSavingsWillGrowYourMoney'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomBriefcase />}
        title='4LookAtWorkBenefits'
        text='SeeIfYourEmployerOffersBenefits'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomRetirement />}
        title='5SaveForRetirement'
        text='SavingIn401kPutMoneyAwayWithTaxBenefits'
      />
    </Stack>
  );
};

const SecondSection: React.FC = () => {
  return (
    <Stack spacing={3}>
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomMedical />}
        title='6SaveInHSA/FSA'
        text='SaveForMedicalExpensesWithTaxAdvantage'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomTax />}
        title='7TaxDeductionsAndCredits'
        text='InsteadOfStandardDeductionTryItemizing'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomInvestment />}
        title='8Investments'
        text='StartInvestingInFractionalShares'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomCreditCard2 />}
        title='9PayOffCreditCards'
        text='PayYourCreditCardsOnTime'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomGlass />}
        title='10OrderWater'
        text='SaveMoneyOnDrinks'
      />
    </Stack>
  );
};

const ThirdSection: React.FC = () => {
  return (
    <Stack spacing={3}>
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomBus />}
        title='11CommuteWithOthers'
        text='SaveOnGasAndRepairsByCarpooling'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomCancel />}
        title='12CancelExtraSubscriptions'
        text='DoYouStillReadThatNewspaper'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomUmbrella />}
        title='13SaveOnInsurance'
        text='FindACheaperPolicy'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomLightbulb />}
        title='14CheckYourEnergeyUsage'
        text='AShorterShowerOrTurningOffLights'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomFood />}
        title='15BringLunchToWork'
        text='PerhapsEatingOutLessIsDoable'
      />
    </Stack>
  );
};

const FourthSection: React.FC = () => {
  return (
    <Stack spacing={3}>
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomPlane />}
        title='16PostponeAVacation'
        text='MaybeTheFamilyTripCanWait'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomCoins />}
        title='17SaveExtraIncome'
        text='LetYourBonusBoostYourSavings'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumPrimaryCoinStack />}
        title='18RoundUpPurchases'
        text='SomeBanksAllowYouToSaveExtraPennies'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomPiggyBank />}
        title='19AutomaticSavings'
        text='AutomaticDeductionsSaveWithoutThinking'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomShoppingCart />}
        title='20WaitOnBigPurchases'
        text='YouMightChangeYourMindIfYouWait'
      />
    </Stack>
  );
};

const FifthSection: React.FC = () => {
  return (
    <Stack spacing={3}>
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomPhone2 />}
        title='21LowerYourPhoneBill'
        text='SwitchingCarriersCanMakeADifference'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomVideoCamera />}
        title='22WatchAMovieYouOwn'
        text='RerunATreasuredClassic'
      />
      <IconContentRow
        icon={<Svgs.DisplaySmallCustomLoans />}
        title='23RefinanceYourLoans'
        text='SeeIfYouQualifyAtALowerRate'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomTV />}
        title='24DisconnectCableTV'
        text='GetYourFreeTimeAndSave'
      />
      <IconContentRow
        icon={<Svgs.DisplayMediumCustomCoffee />}
        title='25BrewYourOwnCoffee'
        text='YouGetCoffeeForCheaper'
      />
    </Stack>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CutSpendingView, meta);
