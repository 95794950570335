import { Grid } from '@mui/material';

interface HorizontalLineIconProps {
  children: React.ReactNode;
}

const HorizontalLineIcon = ({ children }: HorizontalLineIconProps) => (
  <Grid className='horizontal-line-icon'>
    <Grid className='horizontal-line' />
    <Grid className='icon-container'>{children}</Grid>
  </Grid>
);

export default HorizontalLineIcon;
