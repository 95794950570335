import { Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography, Box } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Modal, Spacer } from '../../../components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { CarSvg } from '../../../components/images/CarSvg';
import { usePlaidCredentialRefresh } from '../../../hooks/usePlaidCredentialRefresh';

interface CarViewProps {
  cars: Domain.CarData[];
  deleteCar: MutatorState<number>;
}

export const CarView: React.FC<CarViewProps> = ({ cars, deleteCar }) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { wizard } = Hooks.useCarWizard();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.CarData>();
  const { setIdAndUpdateLogin } = usePlaidCredentialRefresh();

  const handleEdit = (x: Domain.CarData) => {
    wizard.start({ id: x.id });
    navigate(`/account-details/car/summary/${x.id}`);
  };

  const handleDeleteCar = (car: Domain.CarData) => {
    if (car.id) {
      setLoading(true);
      deleteCar.action(car.id);
    }
  };

  if (cars.length === 0) return null;

  return (
    <>
      <Grid display='none'>
        <Modal
          title='Remove Car?'
          primaryButtonText='No, Keep Car'
          secondaryButtonText='Yes, Remove Car'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            if (selectedAccount) {
              handleDeleteCar(selectedAccount);
            }
          }}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this account. Are you sure you want to do
            that?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p16Bold' color='secondary'>
            {selectedAccount?.name}
          </Typography>
          <Typography variant='p16Bold' color='secondary'>
            {t('Car')}
          </Typography>
        </Modal>
      </Grid>
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {CarSvg({ fontSize: 'large' })}
          {t('Cars', { number: cars.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.CarData>
            className='account-list'
            items={cars}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => (
              <Grid container>
                <Grid marginRight='10px'>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    fontWeight='bold'
                    onClick={() => handleEdit(x)}>
                    {x.name}
                  </Typography>
                  <Grid direction='row' display='flex' alignItems='center'>
                    <Typography marginTop='5px' color='secondary' variant='p12'>
                      {x.carLoan?.linkedInstitution?.institutionName ?? 'Manually Added'}
                    </Typography>
                    {x.carLoan?.linkedInstitution?.requiresLogin && (
                      <>
                        <Typography ml={'10px'} marginTop='5px' color='secondary' variant='p12'>
                          |
                        </Typography>
                        <Typography
                          onClick={() => {
                            setIdAndUpdateLogin(x.carLoan?.linkedInstitution?.accessId ?? 0);
                          }}
                          sx={{
                            '&:hover': {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            },
                          }}
                          ml={'10px'}
                          marginTop='5px'
                          color='error'
                          component='span'
                          variant='p12'>
                          Login Expired
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid className='account-view-status-icon' height='20px'>
                  {x.carLoan?.isLinked && (
                    <Grid display='flex' alignItems='center'>
                      <Svgs.LinkPaperClip />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            summaryFn={(x) => {
              const currentBalance = x.carLoan?.amountOwed
                ? (x.estimatedValue ?? 0) - x.carLoan.amountOwed
                : x.estimatedValue ?? 0;
              return (
                <Grid>
                  <Typography color={currentBalance >= 0 ? 'secondary' : 'error'}>
                    {formatWholeDollars(currentBalance)}
                  </Typography>
                </Grid>
              );
            }}
            needsAttention={(x) => x.carLoan?.linkedInstitution?.requiresLogin}
            stackEndContent
            onDelete={(x) => {
              setSelectedAccount(x);
              setModalOpen(true);
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};
