/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import PersonalLoanCalculatorForm from './PersonalLoanCalculatorForm';
import PersonalLoanCalculatorResults from './PersonalLoanCalculatorResults';
import FormContent from '../../../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { Data, Hooks } from '@3nickels/data-modules';
import { yupResolver } from '@hookform/resolvers/yup';
import { cleanWholeNumberStr, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';
import { useLoading } from '../../../../hooks/useLoading';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  loanAmount: Yup.string()
    .required('Required')
    .test('loanAmount', 'Required', (amount) => {
      const cleanNumber = cleanWholeNumberStr(amount);
      return cleanNumber > 0;
    }),
  loanTermMonths: Yup.string().required('Required'),
});

const PersonalLoanCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const selfData = Hooks.usePersonData() ?? {};
  const [pdfParams, setPdfParams] = useState<Data.Loans.AdviceLoansPersonalData | undefined>(
    undefined
  );
  const [results, setResults] = useState<Data.Loans.AdviceLoansPersonalResultData | undefined>(
    undefined
  );
  const creditScore = selfData.creditScore ?? 0;
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Data.Loans.AdviceLoansPersonalData>(
    SavedFormsKeyEnum.PersonalLoanCalculator
  );
  const defaultValues = formData ?? {
    loanAmount: 0,
  };
  const methods = useForm<Data.Loans.AdviceLoansPersonalData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const formatInputs = (values: any): Data.Loans.AdviceLoansPersonalData => {
    const formData: Data.Loans.AdviceLoansPersonalData = {
      loanAmount: cleanWholeNumberStr(values.loanAmount ?? 0),
      loanTermMonths: cleanWholeNumberStr(values.loanTermMonths ?? 0),
    };

    return formData;
  };

  const onSubmit = async (values: Data.Loans.AdviceLoansPersonalData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      const resultData = await commands.execute(
        Data.Loans.Commands.CalculateAdvicePersonalLoan,
        cleanedValues
      );
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.PersonalLoanCalculator,
        formData: cleanedValues,
      });
      setResults(resultData);
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    } finally {
      setLoading(false);
    }
  };

  const downloadPdf = async () => {
    if (pdfParams) {
      try {
        setLoading(true);
        const response = await commands.execute(
          Data.Loans.Commands.CalculateAdvicePersonalLoanPdf,
          pdfParams
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'PersonalLoanCalculatorResults.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  if (typeof formData === 'undefined') return null;

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <ToolTemplate
            header={
              results && (
                <ToolHeader title='HeresYourSampleMonthlyPayment' onDownload={downloadPdf} />
              )
            }
            inputColumn={
              <PersonalLoanCalculatorForm
                methods={methods}
                defaultValues={defaultValues}
                results={results}
                onSubmit={onSubmit}
              />
            }
            outputColumn={
              <PersonalLoanCalculatorResults creditScore={creditScore} results={results} />
            }
            boxItems={[
              {
                boxTitle: 'MonthlyPayment',
                boxResult: results ? formatWholeDollars(results?.monthlyPayment ?? 0) : undefined,
              },
            ]}
          />
        </Box>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(PersonalLoanCalculatorView, meta);
