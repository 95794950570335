/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import CarDetailsForm from './CarDetailsForm';
import { map } from 'rxjs';
import { cleanWholeNumberStr, getInterestPayment } from '../../../../helpers/utilityFunctions';

const carDetailsSchema = Yup.object({
  hasLoan: Yup.string()
    .required('Required')
    .test('hasLoan', 'Required', (hasLoan) => {
      return hasLoan === 'true' || hasLoan === 'false';
    }),
  amountOwed: Yup.string().when('hasLoan', {
    is: 'true',
    then: (schema) =>
      schema
        .required('Required')
        .test('amountOwed', 'Amount Owed must be greater than $0', (amount) => {
          const cleanNumber = cleanWholeNumberStr(amount);
          return cleanNumber > 0;
        }),
  }),
  interestRate: Yup.string().notRequired(), // TODO
  minimumPayment: Yup.string().when('hasLoan', {
    is: 'true',
    then: (schema) =>
      schema
        .required('Required')
        .test('minimumPayment', 'Minimum Payment must be greater than $0', (payment) => {
          const cleanNumber = cleanWholeNumberStr(payment);
          return cleanNumber > 0;
        })
        .test({
          name: 'minimumPayment greater than interest',
          // arrow function cannot access parent
          test: function (monthlyPayment, { createError, path, parent }) {
            const { interestRate, amountOwed } = parent;
            const interestPayment = getInterestPayment(
              cleanWholeNumberStr(interestRate, { float: true }),
              cleanWholeNumberStr(amountOwed)
            );
            const cleanMonthlyPayment = cleanWholeNumberStr(monthlyPayment);

            if (cleanMonthlyPayment >= interestPayment) {
              return true;
            }
            return createError({
              message: `Minimum Payment must be greater than or equal to the monthly interest of $${interestPayment}`,
              path,
            });
          },
        }),
  }),
  monthlyPayment: Yup.string().when('hasLoan', {
    is: 'true',
    then: (schema) =>
      schema
        .required('Required')
        .test('monthlyPayment', 'Monthly Payment must be greater than $0', (payment) => {
          const cleanNumber = cleanWholeNumberStr(payment);
          return cleanNumber > 0;
        })
        .test(
          'monthlyPayment greater than min',
          'Monthly Payment must be greater than or equal to minimum payment',
          // arrow function cannot access parent
          function (monthlyPayment, { parent }) {
            const { minimumPayment } = parent;
            const cleanMinimumPayment = cleanWholeNumberStr(minimumPayment);
            const cleanMonthlyPayment = cleanWholeNumberStr(monthlyPayment);
            return cleanMonthlyPayment >= cleanMinimumPayment;
          }
        ),
  }),
});

export const CarDetailsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useCarWizard<Data.Cars.CarDetailsFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('details');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'details')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'details' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <CarDetailsEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface CarDetailsEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Cars.CarDetailsFormData, Data.Cars.CarWizardParams>;
  wizard: Data.Cars.CarWizard;
  params: Data.Cars.CarWizardParams;
}

const CarDetailsEditView: React.FC<CarDetailsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Cars.CarDetailsFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(carDetailsSchema),
  });
  const { setLoading } = useLoading();

  const formatInputs = (values: any) => {
    const hasLoan = values.hasLoan === 'true';
    if (hasLoan) {
      const amountOwed = cleanWholeNumberStr(values.amountOwed);
      const interestRate = cleanWholeNumberStr(values.interestRate, { float: true });
      const monthlyPayment = cleanWholeNumberStr(values.monthlyPayment);
      const minimumPayment = cleanWholeNumberStr(values.minimumPayment);

      return { hasLoan, amountOwed, interestRate, monthlyPayment, minimumPayment };
    }

    return { hasLoan, amountOwed: 0, interestRate: 0, monthlyPayment: 0, minimumPayment: 0 };
  };

  const onSubmit = async (values: Data.Cars.CarDetailsFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('details');

      if (editing && onBack) {
        onBack();
        return;
      }

      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/car/summary/${params?.id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t(editing ? 'CarLoanDetails' : 'AddCarLoan')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CarDetailsForm carDetails={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(CarDetailsEditViewWrapper, meta);
