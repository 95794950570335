import { Domain } from '@3nickels/data-modules';

// filter duplicate ira step for navigation
export const useUniqueInvMixForNavigation = (
  investmentMixes: Domain.BreakdownRecommendations[]
): Domain.BreakdownRecommendations[] => {
  const uniqueItems = new Map<number, Domain.BreakdownRecommendations>();
  investmentMixes.forEach((invMix) => {
    if (invMix.id === 4) {
      uniqueItems.set(invMix.id, invMix);
    } else {
      if (!uniqueItems.has(invMix.id)) {
        uniqueItems.set(invMix.id, invMix);
      }
    }
  });

  const filteredInvMix = Array.from(uniqueItems.values());
  return filteredInvMix;
};
