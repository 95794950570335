import { Grid } from '@mui/material';
import React from 'react';

export interface ProgressBarProps {
  color: string;
  backgroundColor?: string;
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  color,
  backgroundColor = 'white',
  progress,
}) => {
  return (
    <Grid
      height='5px'
      width='100%'
      borderRadius='4px'
      border={`solid 1px ${color}`}
      sx={{
        background: `linear-gradient(${color} 0 0) bottom left / ${progress}% 4px no-repeat, ${backgroundColor}`,
      }}
    />
  );
};

export default ProgressBar;
