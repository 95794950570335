import { IServiceContainer } from '@aesop-fables/containr';
import { IRedirectService } from '../../redirects';
import { RedirectServices } from '../../redirects/Types';
import { json } from 'react-router-dom';

export async function redirectLoader(container: IServiceContainer) {
  const redirectService = container.get<IRedirectService>(RedirectServices.RedirectService);
  const response = await redirectService.redirect();
  if (response.status !== 200) {
    return response;
  }

  return json('no redirects required');
}
