/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { HomeSpendingSection } from './spending/home/HomeSpendingSection';
import { BudgetSectionProps } from '../Types';
import { UtilitySpendingSection } from './spending/UtilitiesSpendingSection';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { Backdrop } from '@mui/material';
import { Loading } from '../../../hooks/useLoading';
import { TransportSpendingSection } from './spending/TransportSpendingSection';
import { FoodSpendingSection } from './spending/FoodSpendingSection';
import { DebtSpendingSection } from './spending/DebtSpendingSection';
import { MiscellaneousSpendingSection } from './spending/MiscellaneousSpendingSection';

// The nested card displays should be its own component but it'll function similarly, I suppose
export const SpendingSection: React.FC<BudgetSectionProps> = ({ budget, mode }) => {
  const { ready, monthlySpending } = Hooks.useMonthlySpending();
  const spendingSummary = Hooks.useBudgetSpendingSummary();
  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, budget?.totalSpending),
    [budget?.totalSpending, mode]
  );

  if (
    typeof ready === 'undefined' ||
    ready === false ||
    typeof monthlySpending === 'undefined' ||
    typeof spendingSummary === 'undefined'
  ) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <CollapsiblePanel
      header='SPENDING'
      icon={<Svgs.DisplaySmallCustomBudget />}
      summary={formatWholeDollars(summary)}>
      <HomeSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <UtilitySpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <TransportSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <FoodSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <DebtSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <MiscellaneousSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
    </CollapsiblePanel>
  );
};

export default SpendingSection;
