import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors, Spacing } from '../../../../themes';
import { Add } from '@mui/icons-material';
import '../../../../themes/tippy-light-nickels.css';
import React, { useState } from 'react';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { FormHeader, GroupEdit } from '../../../../components/GroupEdit';
import { useLoading } from '../../../../hooks/useLoading';
import { InvestmentDrawer, InvestmentDrawerProps, InvestmentDrawerStatus } from './InvestmentsView';
import Modal from '../../../../components/Modal';
import { Spacer } from '../../../../components';
import { useMessage } from '../../../../hooks/useMessage';
import HelpTitle from '../../../../components/HelpTitle';
import { AddInvestmentHelpContent } from './investment-details/InvestmentDetailsEditView';
import { Svgs } from '../../../../assets/svg';
import { LinkAccountProps } from './InvestmentsCard';
import { t } from 'i18next';

export interface AddInvestmentRowProps {
  onAdd: () => void;
}

export const AddInvestmentRow: React.FC<AddInvestmentRowProps> = ({ onAdd }) => {
  const { t } = useTranslation();
  return (
    <Grid
      pl={4}
      width='100%'
      display='inline-flex'
      justifyContent='space-between'
      flexDirection='row'
      className='add-investment'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onAdd();
      }}>
      <Typography variant='p16Bold' color='primary'>
        {t('AddAnInvestment')}
      </Typography>
      <IconButton sx={{ paddingRight: '0' }}>
        <Add color='primary' fontSize='large' />
      </IconButton>
    </Grid>
  );
};

export interface AddInvestmentsViewProps extends LinkAccountProps {
  account: Domain.IInvestmentAccount;
  investments: Domain.InvestmentData[];
}

const AddInvestmentsView: React.FC<AddInvestmentsViewProps> = ({
  account,
  investments,
  linkAccount,
}) => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<InvestmentDrawerStatus>({
    open: false,
  });
  const planData = account.account as Domain.PlanData;
  const isIraOrBrokerage =
    Domain.isIRA(planData.planType) ||
    planData.planType === Domain.PlanTypeEnum['Brokerage Account'];
  const isInstitutional = planData.type === 'Institutional';

  return (
    <Grid container display='flex' flexDirection='column'>
      <Grid container display='flex' flexDirection='column' mb={Spacing.xxxs}>
        <HelpTitle
          text={t('AddInvestments')}
          helpContext={AddInvestmentHelpContent(t('AddInvestments'), planData.planType)}
        />
        <Typography variant='p18Bold' color='secondary' mt={Spacing.xxxs}>
          {account.name}
        </Typography>
        {!isIraOrBrokerage &&
          (isInstitutional ? (
            <Typography variant='p16' color='secondary' mt={1} mb={1}>
              {t('YourOrganizationAddedYourInvestment')}
            </Typography>
          ) : (
            <Typography variant='p12' color={Colors.accentBase} mt={Spacing.xxxs}>
              {t('AddInvestmentsNote')}
            </Typography>
          ))}
        <InvestmentsGroupEdit
          variant='add-investments'
          account={account}
          investments={investments}
          drawerStatus={drawerStatus}
          setDrawerStatus={setDrawerStatus}
          linkAccount={linkAccount}
        />
      </Grid>
    </Grid>
  );
};

interface InvestmentsGroupEditProps extends InvestmentDrawerProps, LinkAccountProps {
  variant: 'summary' | 'add-investments';
  account: Domain.IInvestmentAccount;
  investments: Domain.InvestmentData[];
}

export const InvestmentsGroupEdit: React.FC<InvestmentsGroupEditProps> = ({
  variant,
  account,
  investments,
  drawerStatus,
  setDrawerStatus,
  linkAccount,
}) => {
  const { setLoading } = useLoading();
  const { selectInvestment, institutionalAccount } = Hooks.useInvestmentContext();
  const commands = Hooks.useCommands();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState<Domain.InvestmentData>();
  const { showMessage } = useMessage();
  const planData = account.account as Domain.PlanData;
  const shouldGroupInvestments = Domain.shouldGroupInvestments(planData);

  const getTaxType = (x: Domain.InvestmentData) => {
    switch (x.taxType) {
      case Domain.TaxTypeEnum['Pre-tax']:
        return 'Pre-Tax';
      case Domain.TaxTypeEnum.Roth:
        return 'Roth';
      case Domain.TaxTypeEnum['After-tax']:
        return 'After-Tax';
      default:
        return 'Not Invested';
    }
  };

  const handleEdit = (i: Domain.InvestmentData) => {
    selectInvestment(i);
    setDrawerStatus({ drawer: 'investment', open: true });
  };

  const handleDeleteInvestment = async (values: Domain.InvestmentData) => {
    setLoading(true);
    try {
      await commands
        .execute(Data.InvestmentAccounts.Commands.DeleteInvestment, {
          investmentAccountId: account.id ?? 0,
          id: values.id ?? 0,
          institutionalAccount,
        })
        .then(() => {
          showMessage(`Investment removed!`);
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid display='none'>
        <Modal
          title='Remove Investment?'
          primaryButtonText='No, Keep Investment'
          secondaryButtonText='Yes, Remove Investment'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            if (selectedInvestment) {
              handleDeleteInvestment(selectedInvestment);
            }
          }}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this investment. Are you sure you want to do
            that?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p16Bold' color='secondary'>
            {selectedInvestment?.securityName}
          </Typography>
          <Typography variant='p16Bold' color='secondary'>
            {selectedInvestment?.ticker}
          </Typography>
          {account?.accountType !== Domain.InvestmentAccountTypeEnum.BrokerageAccount &&
            account?.accountType !== Domain.InvestmentAccountTypeEnum.HSA &&
            !Domain.isIRA(account.account.planType) && (
              <Typography variant='p16Bold' color='secondary'>
                {selectedInvestment && getTaxType(selectedInvestment)}
              </Typography>
            )}
          <Typography variant='p16Bold' color='secondary'>
            {formatWholeDollars(selectedInvestment?.balance || 0)}
          </Typography>
        </Modal>
      </Grid>
      <GroupEdit<Domain.InvestmentData>
        variant='compressed'
        title={
          <Grid
            item
            sm={6}
            mt={Spacing.xxxs}
            className='group-edit-count'
            display='inline-flex'
            justifyContent='space-between'
            flexDirection='row'>
            <FormHeader
              text={`Investments (${
                Data.InvestmentAccounts.sortInvestmentsByTaxType(investments).length
              })`}
            />
            {variant === 'summary' && linkAccount && !account.account.isLinked && (
              <Stack flexDirection='row'>
                <Typography
                  className='clickable'
                  variant='p12'
                  color='primary'
                  onClick={() => linkAccount()}>
                  Link
                </Typography>
                <Svgs.LinkPaperClip height='20px' width='20px' style={{ marginLeft: '3px' }} />
              </Stack>
            )}
            {variant === 'add-investments' && !shouldGroupInvestments && (
              <Stack mt={-3} flexDirection='row' display='flex' alignItems='flex-end'>
                <Box mb='5px' display='flex' alignItems='center'>
                  {linkAccount && !account.account.isLinked && (
                    <Typography
                      className='clickable'
                      variant='p12'
                      color='primary'
                      whiteSpace='nowrap'
                      onClick={() => linkAccount()}>
                      {t('LinkMyInvestmentBalance')}
                    </Typography>
                  )}
                  {account.account.isLinked && (
                    <Typography variant='p12' color='primary'>
                      {account.account.linkedInstitution?.institutionName}
                    </Typography>
                  )}
                  <Svgs.LinkPaperClip height='20px' width='20px' style={{ marginLeft: '3px' }} />
                </Box>

                <Typography
                  ml={2}
                  variant='p40Bold'
                  color='secondary'
                  style={{ fontFamily: 'Montserrat' }}>
                  {formatWholeDollars(account.account.balance ?? 0)}
                </Typography>
              </Stack>
            )}
          </Grid>
        }
        items={Data.InvestmentAccounts.sortInvestmentsByTaxType(investments)}
        keyFn={(x) => `${x.id ?? 0}`}
        subtitleFn={(x) => {
          return shouldGroupInvestments ? getTaxType(x) : '';
        }}
        subtitleAdornment={
          variant === 'add-investments' && shouldGroupInvestments ? (
            <Stack mt={-3} flexDirection='row' display='flex' alignItems='flex-end'>
              <Box mb='5px' display='flex' alignItems='center'>
                {linkAccount && !account.account.isLinked && (
                  <Typography
                    className='clickable'
                    variant='p12'
                    color='primary'
                    whiteSpace='nowrap'
                    onClick={() => linkAccount()}>
                    {t('LinkMyInvestmentBalance')}
                  </Typography>
                )}
                {account.account.isLinked && (
                  <Typography variant='p12' color='primary'>
                    {account.account.linkedInstitution?.institutionName}
                  </Typography>
                )}
                <Svgs.LinkPaperClip height='20px' width='20px' style={{ marginLeft: '3px' }} />
              </Box>

              <Typography
                ml={2}
                variant='p40Bold'
                color='secondary'
                style={{ fontFamily: 'Montserrat' }}>
                {formatWholeDollars(account.account.balance ?? 0)}
              </Typography>
            </Stack>
          ) : undefined
        }
        mainContentFn={(x) => (
          <Grid container display='flex'>
            <Typography
              className='hover-clickable'
              fontWeight='bold'
              color='secondary'
              width='100px'
              onClick={() => handleEdit(x)}>
              {x.ticker}
            </Typography>
            <Typography
              className='hover-clickable'
              color='secondary'
              ml={2}
              onClick={() => handleEdit(x)}>
              {x.securityName ?? ''}
            </Typography>
          </Grid>
        )}
        summaryFn={(x) => `${formatWholeDollars(x.balance ?? 0)}`}
        addItemComponent={
          <AddInvestmentRow
            onAdd={() => {
              selectInvestment(undefined);
              setDrawerStatus({ drawer: 'investment', open: true });
            }}
          />
        }
        onEdit={(i) => {
          handleEdit(i);
        }}
        onDelete={(i) => {
          // needs to be undefined if ID is undefined
          setSelectedInvestment(i);
          setModalOpen(true);
        }}
        disableDelete={(i) => !i.id}
      />
      <InvestmentDrawer drawerStatus={drawerStatus} setDrawerStatus={setDrawerStatus} />
    </>
  );
};

export default AddInvestmentsView;
