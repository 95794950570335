import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json, redirect } from 'react-router-dom';
import { waitFor } from '../utils';
import { AccountDetailsReadySubject } from '../../subjects/AppReadySubject';
import { firstValueFrom } from 'rxjs';
import { Data } from '@3nickels/data-modules';

export async function accountDetailsLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const accountDetailsReady$ = resolver.resolveSubject(AccountDetailsReadySubject);
  await waitFor(accountDetailsReady$);

  return json('account details ready');
}

export async function linkAccountLoader(request: Request, container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const selfData$ = resolver.resolveSubject(Data.People.Projections.SelfDataSubject);
  const { includeSpouse } = await firstValueFrom(selfData$);

  const redirectTo =
    includeSpouse === true
      ? '/account-details/link-account/choose-owner'
      : '/account-details/link-account/account-type';

  if (request.url.endsWith('link-account')) {
    return redirect(redirectTo);
  }

  return json('link account - no redirect');
}
