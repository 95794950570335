import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import { Spacing } from '../../../themes';
import { useTranslation } from 'react-i18next';
import BulletedList from '../../../components/BulletedList';
import Spacer from '../../../components/Spacer';

interface TargetDateFundDrawerProps {
  open: boolean;
  onClose: () => void;
}

const TargetDateFundDrawer: React.FC<TargetDateFundDrawerProps> = ({ onClose, open }) => {
  const { t } = useTranslation();

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box
        p={Spacing.xxs}
        minHeight='60%'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'>
        <Grid>
          <Typography variant='p30Bold' color='primary' mb='36px'>
            {t('TargetDateFunds')}
          </Typography>
          <Typography variant='p18Bold' color='secondary'>
            {t('WhatIsATDF?')}
          </Typography>
          <Typography className='subtitle' variant='p16' color='secondary'>
            {t('ATDFAllocatesToAMix')}
          </Typography>
          <Typography mt='30px' variant='p18Bold' color='secondary'>
            {t('WhyMightYouChooseTDF?')}
          </Typography>
          <Typography className='subtitle' variant='p16' color='secondary'>
            {t('SomePeopleMightPreferToInvestInASingleFund')}
          </Typography>
          <Typography mt='30px' variant='p18Bold' color='secondary'>
            {t('HowDoIKnowIfMyPlanHasTDFs?')}
          </Typography>
          <Spacer height='xxs' />
          <BulletedList
            style={{ marginLeft: '45px', lineHeight: '1.5' }}
            className='subtitle'
            variant='p16'
            color='secondary'>
            {t('LogIntoYourRetirementAccount')}
            {t('LookForInvestmentsSection')}
            {t('LookForWayToChangeInvestments')}
            {t('LookForInvestmentWithCalendarYear')}
            {t('ChooseTargetDateFund')}
          </BulletedList>
        </Grid>
        <Spacer height='xl' />
        <Button style={{ width: 'fit-content' }} variant='outlined' onClick={onClose}>
          Close
        </Button>
      </Box>
    </Drawer>
  );
};

export default TargetDateFundDrawer;
