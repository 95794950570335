import { LoaderFunction } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { commonRoutes } from './CommonRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { wireUpLoaders } from './utils';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import { UserEventLogger } from './UserEventLogger';
import MobileRedirectView from '../pages/mobile-redirect/MobileRedirectView';

export const mobileRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserEventLogger />,
    children: [
      {
        path: '/',
        element: <MobileRedirectView />,
      },
      ...commonRoutes,
    ],
  },
];

export function createMobileRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();

  loaders.set('/', async () => {
    await appReadyLoader(container);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });

  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => authTokenCookieLoader(container));

  return wireUpLoaders(mobileRoutes, loaders);
}
