import { Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid, Stack, Typography } from '@mui/material';
import { RangeLegend } from '../../../../components';
import {
  ChartStateHoneycomb,
  ChartStateHoneycombCellData,
} from '../../../../components/ChartStateHoneycomb';
import SortingTable from '../../../../components/SortingTable';
import { useTranslation } from 'react-i18next';

interface CostByStateViewProps {
  data: ChartStateHoneycombCellData[];
  segmentColors: string[];
  averageLoans: Domain.StudentsLoansAverageData[];
  tuitions: Domain.TuitionCostComparisionData[];
}

export const CostByStateView: React.FC<CostByStateViewProps> = ({
  data,
  segmentColors,
  averageLoans,
  tuitions,
}) => {
  const { t } = useTranslation();
  const amountRanges = ['$29–32k', '$32–35k', '$35–37k', '$37–40k', '$40–50k'];

  return (
    <Stack>
      <Typography mt={1} variant='p30Bold' component='h1' color='primary'>
        {t('Average Student Loan Debt by State')}
      </Typography>
      <Typography mt={1} color='secondary' variant='p16'>
        The average student loan debt per college graduate according to the state they live in.
      </Typography>
      <Grid
        mt={4}
        className='csc-content'
        container
        direction='row'
        justifyContent={'space-between'}>
        <Grid width='60%' item container direction='column'>
          <ChartStateHoneycomb data={data} />
        </Grid>
        <Grid mt={3} className='csc-range' item container width='25%'>
          <RangeLegend colors={segmentColors.reverse()} colorLabels={amountRanges.reverse()} />
        </Grid>
      </Grid>
      <Grid>
        <SortingTable
          headers={[
            { label: 'state', title: 'State' },
            { label: 'avgStudentDebt', title: 'Avg. Student Debt' },
            { label: 'avgOutTuition', title: 'Avg. Out-of-State Tuition' },
            { label: 'avgInTuition', title: 'Avg. In-State Tuition' },
          ]}
          defaultSortColumn='avgStudentDebt'
          data={
            averageLoans?.map((stateAvgLoan) => {
              const stateTuitionInfo = tuitions?.find(
                (x) => stateAvgLoan.abbreviation === x.abbreviation
              );
              return {
                id: stateAvgLoan.id,
                state: toTitleCase(stateAvgLoan.name ?? ''),
                avgStudentDebt: formatWholeDollars(stateAvgLoan.studentLoans ?? 0),
                avgOutTuition: formatWholeDollars(stateTuitionInfo?.publicOutStateTuition ?? 0),
                avgInTuition: formatWholeDollars(stateTuitionInfo?.publicInStateTuition ?? 0),
              };
            }) ?? [{}]
          }
        />
        <Typography mt={3} mb={1} variant='p12' color='primary'>
          Student loan info c/o educationdata.org
        </Typography>
        <Typography variant='p12' color='primary'>
          Tuition cost info c/o collegetuitioncompare.com
        </Typography>
      </Grid>
    </Stack>
  );
};

function toTitleCase(str: string) {
  return str.replace(
    /\w*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}
