import { SummaryCardProps } from '../../Types';
import { Box, Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';
import { InvestmentsGroupEdit } from './AddInvestments';
import { InvestmentDrawerStatus } from './InvestmentsView';
import { InvestmentsGroupPanels } from './AddInvestmentsSelection';
import { useLocation, useNavigate } from 'react-router-dom';

export interface LinkAccountProps {
  linkAccount?: () => void;
}

export interface InvestmentsCardProps extends SummaryCardProps, LinkAccountProps {
  includeSpouse?: boolean;
}

const InvestmentsCard: React.FC<InvestmentsCardProps> = ({ linkAccount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerStatus, setDrawerStatus] = useState<InvestmentDrawerStatus>({
    open: false,
  });

  useEffect(() => {
    if (drawerStatus.usePlaid === true && linkAccount) {
      linkAccount();
      navigate('/account-details/link-account/choose-institution', {
        state: { summaryUrl: location.pathname },
      });
      setDrawerStatus({
        ...drawerStatus,
        usePlaid: false,
      });
    }
  }, [drawerStatus.usePlaid, linkAccount]);

  const { account, investments } = Hooks.useInvestmentContext();
  if (!account?.id) {
    return null;
  }

  return (
    <CardDisplay
      headerKey='Investments'
      collapsible={investments.length > 0}
      summary={<InvestmentsSummary investments={investments} />}>
      <Box>
        {investments.length === 0 && (
          <InvestmentsGroupPanels
            variant='Collapsible'
            drawerStatus={drawerStatus}
            setDrawerStatus={setDrawerStatus}
            balanceType={
              account.accountType === Domain.InvestmentAccountTypeEnum.HSA ? 'Cash' : 'Plan'
            }
          />
        )}
        {investments.length > 0 && (
          <InvestmentsGroupEdit
            variant='summary'
            account={account}
            investments={investments}
            drawerStatus={drawerStatus}
            setDrawerStatus={setDrawerStatus}
            linkAccount={linkAccount}
          />
        )}
      </Box>
    </CardDisplay>
  );
};

type InvestmentsSummaryProps = {
  investments: Domain.InvestmentData[];
};

const InvestmentsSummary: React.FC<InvestmentsSummaryProps> = ({ investments }) => {
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    let newBalance = 0;
    investments.forEach((i) => (newBalance += i?.balance ?? 0));
    setBalance(newBalance);
  }, [investments]);
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography variant='p16Bold' color='primary'>
          Investment Balance
        </Typography>
        <Typography variant='p16' color='secondary'>
          {formatWholeDollars(balance)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ComponentWrapper: React.FC<
  LinkAccountProps & Hooks.InvestmentManagerProps & SummaryCardProps
> = ({ investmentAccountId, ...props }) => {
  return (
    <Hooks.InvestmentManager investmentAccountId={investmentAccountId}>
      <InvestmentsCard {...props} />
    </Hooks.InvestmentManager>
  );
};

export default ComponentWrapper;
