import { Box } from '@mui/material';
import React from 'react';
import { fence } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import BasicInvestingTermsCard from './cards/BasicInvestingTermsCard';
import InvestmentAccountChecklistCard from './cards/InvestmentAccountChecklistCard';
import ShopInvestmentAccountsCard from './cards/ShopInvestmentAccountsCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import { useExploreGroups } from '../../../hooks/useExploreGroups';

const InvestmentToolStartView: React.FC = () => {
  const explore = useExploreGroups();

  const resources: Group[] = [
    {
      textKey: 'basic-investing-terms',
      url: 'basic-investing-terms',
      content: <BasicInvestingTermsCard />,
    },
    {
      textKey: 'investment-account-checklist',
      url: 'investment-account-checklist',
      content: <InvestmentAccountChecklistCard />,
    },
    {
      textKey: 'shop-investment-accounts',
      url: 'shop-investment-accounts',
      content: <ShopInvestmentAccountsCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplaySmallCustomInvestment2 />}
        label='Investment'
        calculators={[]}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={fence} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(InvestmentToolStartView, meta);
