import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../../../components/buttons/Button';

interface OrgHeaderProps {
  charityName: string | undefined;
  tagline: string | undefined;
  cause: string | undefined;
  website: string | undefined;
}
const OrgHeader: React.FC<OrgHeaderProps> = ({ charityName, tagline, cause, website }) => {
  return (
    <Grid container className='org-header'>
      <Grid item rowGap='7px' className='left-column'>
        <Typography variant='p30Bold' component='h1' color='primary'>
          {charityName ?? ''}
        </Typography>
        <Typography color='secondary' variant='p18Bold'>
          {tagline ?? ''}
        </Typography>
        <Typography color='secondary' variant='p18'>
          {cause ?? ''}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => window.open(website ?? '')}
          style={{ height: 'fit-content' }}>
          View Charity’s Website
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrgHeader;
