import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CarSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h30v30H0z' />
          <path
            d='m25.154 12.53-1.684-5.6a2.976 2.976 0 0 0-2.86-2.13H9.35c-1.339 0-2.495.852-2.86 2.13l-1.684 5.6C3.75 12.996 3 14.05 3 15.268v5.985c0 .832.67 1.501 1.501 1.501v1.502c0 .831.67 1.5 1.502 1.5h1.5c.833 0 1.502-.669 1.502-1.5v-1.502h11.99v1.502c0 .831.67 1.5 1.501 1.5h1.501c.832 0 1.502-.669 1.502-1.5v-1.502c.832 0 1.501-.67 1.501-1.5v-5.986c-.04-1.237-.791-2.292-1.846-2.738zM9.35 7.782h11.26l1.338 4.504H7.991L9.35 7.782zm-.365 11.24c-.832 0-1.501-.67-1.501-1.502s.669-1.501 1.5-1.501c.833 0 1.502.67 1.502 1.501 0 .832-.67 1.501-1.501 1.501zm11.99 0c-.832 0-1.502-.67-1.502-1.502s.67-1.501 1.502-1.501c.831 0 1.5.67 1.5 1.501-.02.832-.689 1.501-1.5 1.501z'
            fill='#1B7690'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
