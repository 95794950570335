/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { AxiosError } from 'axios';
import { Api } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Button, Typography } from '@mui/material';
import { Column, Row, Spacer, ConfirmationCodeField } from '../components';
import { logo3NSmall } from '../assets/png';
import {
  AuthWizardStateDefault,
  SignUpLoginWizard,
  signUpLoginWizardKey,
} from '../services/signUpLogin';
import { useObservable } from '@aesop-fables/scrinium';
import { useDeviceType } from '../hooks/useDeviceType';
import { useLoading } from '../hooks/useLoading';
import { mobileMargin } from '../App';
import { useMessage } from '../hooks/useMessage';
import { useTranslation } from 'react-i18next';

const SignUpTwoFactorAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { isMobile } = useDeviceType();
  const { showMessage } = useMessage();
  const [codeValue, setCodeValue] = useState<string>('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState<boolean>(true);
  const authApi = useService<Api.AuthApi>(Api.ApiKeys.AuthApi);
  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);

  const state = useObservable(signUpLoginWizard.state$) ?? AuthWizardStateDefault;
  const userInfo = state.registerData;
  const CELL_COUNT = 6;

  useEffect(() => {
    if (String(codeValue).length === CELL_COUNT) setDisabledSubmitButton(false);
    else setDisabledSubmitButton(true);
  }, [codeValue]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const redirectLocation = await authApi.validateAccount({
        code: codeValue,
        twoFactorType: Api.TwoFactorTypeEnum.Email,
      });
      // don't need to set loading to false because this triggers a refresh of the app
      window.location = redirectLocation.data;
    } catch (err) {
      const error = err as AxiosError;
      const errorMessage = (error.response?.data as Record<string, any>)?.message;
      setLoading(false);
      if (errorMessage === 'This username is already in use.') {
        showMessage(t('AccountEmailAlreadyExists') as string, 'error');
      } else {
        showMessage(t('WeWerentBankingOnThat') as string, 'error');
      }
    }
  };

  const onResendCode = async () => {
    try {
      setCodeValue('');
      await signUpLoginWizard.setAccountInfoAndAttemptSignUp(
        userInfo.firstName ?? '',
        userInfo.lastName ?? '',
        userInfo.birthdate ?? '',
        userInfo.emailAddress ?? '',
        userInfo.password ?? ''
      );
      showMessage(t('AuthenticationCodeSent') as string, 'success', 4000);
    } catch (err) {
      console.error(err);
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    }
  };

  const navBack = () => {
    navigate(-1);
  };

  const handleChange = (x: string) => {
    setCodeValue(x);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <>
          <Column style={{ margin: isMobile ? mobileMargin : '15px 100px' }}>
            <Link style={{ height: '55px' }} to='/'>
              <img src={logo3NSmall} alt='logo' style={{ height: '100%', alignSelf: 'flex-end' }} />
            </Link>
            <Typography variant='p30Bold' color='primary'>
              Secure Your Account
            </Typography>
            <Spacer height='xs' />
            <Typography variant='h2' color='secondary'>
              Enter the 6-digit code we sent to your email address.
            </Typography>
            <Spacer height='xs' />
            <Typography variant='p16' color='error.main'>
              If you don’t see the email, please check your junk folder, and move it to your inbox
              so it’s no longer marked as junk.
            </Typography>
            <Spacer height='lg' />
            <Column style={{ alignItems: 'center' }}>
              <ConfirmationCodeField fieldName='2fa' value={codeValue} onChange={handleChange} />
              <Spacer height='lg' />
              <Button onClick={onResendCode} className='ghostAlt'>
                Resend Code
              </Button>
            </Column>
            <Spacer height='sm' />
            {isMobile ? (
              <Column style={{ justifyContent: 'space-between' }}>
                <Button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={disabledSubmitButton}
                  color='secondary'>
                  Submit
                </Button>
                <Spacer height='xs' />

                <Button onClick={navBack} variant='outlined' color='secondary'>
                  Back
                </Button>
              </Column>
            ) : (
              <Row style={{ justifyContent: 'space-between' }}>
                <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
                  Back
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={disabledSubmitButton}
                  className='md'
                  type='submit'
                  color='secondary'
                  style={{ alignSelf: 'flex-end' }}>
                  Submit
                </Button>
              </Row>
            )}
          </Column>
        </>
      )}
    </Form>
  );
};

export default SignUpTwoFactorAuth;
