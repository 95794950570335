import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { t } from 'i18next';

const LmsCourseCompleteCard: React.FC = () => {
  return (
    <Grid container className='lms-course-complete-lesson-card'>
      <Grid className='content'>
        <Svgs.ArtRibbon />
        <Grid display='flex' flexDirection='column' rowGap='10px'>
          <Typography variant='p30Bold' color='secondary'>
            {t('Congratulations')}
          </Typography>
          <Typography variant='p18Bold' color='secondary'>
            {t('YouCompletedThisCourse')}
          </Typography>
        </Grid>
      </Grid>
      <Svgs.Confetti className='background' />
    </Grid>
  );
};

export default LmsCourseCompleteCard;
