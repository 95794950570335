import { IServiceContainer } from '@aesop-fables/containr';
import { ICommandExecutor, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { Data } from '@3nickels/data-modules';

export async function holisticAdviceLoader(container: IServiceContainer) {
  const commands = container.get<ICommandExecutor>(ScriniumServices.CommandExecutor);
  await commands.relay(Data.HolisticAdvice.InvalidateHolisticAdvice);
  return json('holistic-advice');
}
