import { Grid, Tabs as MuiTabs, TabsProps as MuiTabsProps, Tab } from '@mui/material';
import React, { HtmlHTMLAttributes } from 'react';

export type TabsProps = {
  labels?: string[];
} & MuiTabsProps;

export const Tabs: React.FC<TabsProps> = (props) => {
  const { labels, children, ...rest } = props;
  return (
    <MuiTabs {...rest}>
      {labels?.map((label) => (
        <Tab key={label} label={label} />
      ))}
      {children}
    </MuiTabs>
  );
};

interface TabPanelProps extends HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      paddingTop={3}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </Grid>
  );
};
