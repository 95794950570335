import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { AxiosServices } from '@aesop-fables/containr-axios';

export class AuthTokenApi {
  constructor(@inject(AxiosServices.AxiosInstance) private readonly axios: AxiosInstance) {}

  // Used to access 2fa and change password
  generateCookie(): Promise<AxiosResponse> {
    return this.axios.post('/auth-token/cookie');
  }
}
