import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepThree: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={3}
      headerIcon={<Svgs.ArtBusiness style={{ marginBottom: '7px', marginTop: '7px' }} />}
      headerText='CompareLenders'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('OnceYourePreQualifiedForALoan')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('CheckOutTheRates')}
          </Typography>
        </>
      }
    />
  );
};

export default StepThree;
