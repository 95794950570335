import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function SignOutSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <g fill={props.fill} fillRule='nonzero'>
          <path d='M12.422 20.724H4.981a.827.827 0 0 1-.827-.827V5.014c0-.457.37-.827.827-.827h7.441a.826.826 0 1 0 0-1.654H4.981A2.484 2.484 0 0 0 2.5 5.013v14.884a2.484 2.484 0 0 0 2.48 2.481h7.442a.826.826 0 1 0 0-1.654z' />
          <path d='m22.165 11.867-5.028-4.961a.827.827 0 1 0-1.16 1.177l3.592 3.546H9.942a.826.826 0 1 0 0 1.653h9.627l-3.593 3.546a.827.827 0 1 0 1.161 1.177l5.028-4.96a.828.828 0 0 0 0-1.178z' />
        </g>
      </g>
    </SvgIcon>
  );
}

SignOutSvg.defaultProps = {
  fill: '#fff',
};
