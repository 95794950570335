import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Drawer, Grid, Typography } from '@mui/material';
import { Data, Hooks } from '@3nickels/data-modules';
import { Spacing } from '../../themes';
import { Svgs } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import { WizardFooter } from '../../components/form/WizardFooter';
import _ from 'lodash';
import { TransactionIcon } from './TransactionIcon';
import { Check } from '@mui/icons-material';

interface SpendingSummaryTransactionsCategoriesSelectorProps {
  disabled?: boolean;
}

const SpendingSummaryTransactionsCategoriesSelector: React.FC<
  SpendingSummaryTransactionsCategoriesSelectorProps
> = ({ disabled }) => {
  const { transactionsCategories } = Hooks.useTransactions();
  const [open, setOpen] = useState(false);

  const content = useMemo(() => {
    if (transactionsCategories.length === 0) return 'All Categories';
    if (transactionsCategories.length === 1)
      return _.startCase(_.toLower(transactionsCategories[0].toString()));
    return `${transactionsCategories.length} Categories`;
  }, [transactionsCategories]);

  return (
    <>
      <CategoriesDrawer open={open} onClose={() => setOpen(false)} />
      <Grid className='transactions-selector-panel' onClick={() => setOpen(true)}>
        <Svgs.ActionSmallFilter />
        <Typography
          variant='p12'
          color='secondary'
          style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
          {content}
        </Typography>
      </Grid>
    </>
  );
};

interface CategoriesDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CategoriesDrawer: React.FC<CategoriesDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { transactionsCategories, setTransactionsCategories } = Hooks.useTransactions();
  const [selectedCategories, setSelectedCategories] = useState<
    Set<Data.Transactions.TransactionCategoryEnum>
  >(new Set());

  const content = useMemo(() => {
    if (selectedCategories.size === 0) return 'No filters applied';
    return `${selectedCategories.size} ${
      selectedCategories.size === 1 ? 'category' : 'categories'
    } selected`;
  }, [selectedCategories.size]);

  useEffect(() => {
    setSelectedCategories(new Set(transactionsCategories));
  }, [transactionsCategories, open]);

  const toggleCategory = (category: Data.Transactions.TransactionCategoryEnum) => {
    setSelectedCategories((previous) => {
      const set = new Set(previous);
      if (set.delete(category)) {
        return set;
      }
      set.add(category);
      return set;
    });
  };

  const selectAllCategories = () => {
    setSelectedCategories((previous) => {
      if (previous.size === Data.Transactions.TransactionCategoryEnumList.length) {
        return new Set();
      }

      return new Set(
        Data.Transactions.TransactionCategoryEnumList.map(
          (x) => x.value as Data.Transactions.TransactionCategoryEnum
        )
      );
    });
  };

  const saveCategories = () => {
    setTransactionsCategories([...selectedCategories]);
    onClose();
  };

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Grid p={Spacing.xxs}>
        <Typography className='title' color='primary' component='h1' variant='h1'>
          {t('FilterByCategory')}
        </Typography>
        <Typography className='subtitle' color='secondary' variant='p16'>
          {content}
        </Typography>
        <Grid
          className='category-select-all'
          onClick={selectAllCategories}
          mt={2}
          mb='10px'
          style={{ border: 'solid 1px transparent' }}>
          <Grid display='flex' columnGap='10px'>
            <Checkbox
              checked={
                selectedCategories.size === Data.Transactions.TransactionCategoryEnumList.length
              }
              indeterminate={
                selectedCategories.size > 0 &&
                selectedCategories.size < Data.Transactions.TransactionCategoryEnumList.length
              }
            />
            <Typography variant='p14Bold' color='secondary'>
              All Categories
            </Typography>
          </Grid>
        </Grid>
        <Grid container className='category-select-container'>
          {Data.Transactions.TransactionCategoryEnumList.sort((a, b) => {
            if (a.value === Data.Transactions.TransactionCategoryEnum.OTHER) {
              return 1;
            }

            return a.key.toString().localeCompare(b.key.toString());
          }).map((category) => {
            const selected = selectedCategories.has(
              category.value as Data.Transactions.TransactionCategoryEnum
            );
            return (
              <CategoryCard
                category={category.value as Data.Transactions.TransactionCategoryEnum}
                selected={selected}
                toggleCategory={toggleCategory}
              />
            );
          })}
        </Grid>
        <WizardFooter color='primary' onBack={onClose} onDone={saveCategories} nextLabel='Done' />
      </Grid>
    </Drawer>
  );
};

interface CategoryCardProps {
  category: Data.Transactions.TransactionCategoryEnum;
  toggleCategory: (category: Data.Transactions.TransactionCategoryEnum) => void;
  selected?: boolean;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, toggleCategory, selected }) => {
  return (
    <Grid
      className={selected ? 'category-select-selected' : 'category-select'}
      onClick={() => toggleCategory(category)}>
      <Grid display='flex' columnGap='10px'>
        <Checkbox checked={selected} checkedIcon={<Check />} />
        <Typography variant='p14Bold'>{_.startCase(_.toLower(category))}</Typography>
      </Grid>
      <TransactionIcon category={category} selected={selected} />
    </Grid>
  );
};

export default SpendingSummaryTransactionsCategoriesSelector;
