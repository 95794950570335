import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LearnSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g id='open-book' transform='translate(1.6667, 4.1667)' fill={props.fill} fill-rule='evenodd'>
        <path
          d='M3.31435459,7.34525129e-05 C3.31377098,7.34525129e-05 3.31311441,7.34525129e-05 3.31253079,7.34525129e-05 C3.10760867,7.34525129e-05 2.91486952,0.080503954 2.76933031,0.226674454 C2.62218616,0.37446091 2.5411365,0.571240192 2.5411365,0.780726758 L2.5411365,12.4864865 C2.5411365,12.9157429 2.88933633,13.2658176 3.31741858,13.2669194 C5.12210479,13.2712531 8.14567276,13.6499743 10.2315159,15.8477469 L10.2315159,3.59733681 C10.2315159,3.45182739 10.1946022,3.31513226 10.1249331,3.20201539 C8.41296884,0.426098027 5.12312611,0.00433369825 3.31435459,7.34525129e-05 Z'
          id='Path'></path>
        <path
          d='M19.1256031,12.4864865 L19.1256031,0.780653306 C19.1256031,0.571166739 19.0445535,0.374387458 18.8974093,0.226601002 C18.7518701,0.0804305015 18.558985,0 18.3542818,0 C18.3536252,0 18.3529686,0 18.352385,0 C16.5436864,0.00433369825 13.2538437,0.426098027 11.5418065,3.20201539 C11.4721374,3.31513226 11.4352966,3.45182739 11.4352966,3.59733681 L11.4352966,15.8476734 C13.5211398,13.6499008 16.5447078,13.2711797 18.349394,13.266846 C18.7774033,13.2657442 19.1256031,12.9156695 19.1256031,12.4864865 Z'
          id='Path'></path>
        <path
          d='M20.8914789,2.6996002 L20.3293109,2.6996002 L20.3293109,12.4864865 C20.3293109,13.5824714 19.4424336,14.4761681 18.3522391,14.4788859 C16.8214876,14.4825585 14.2974948,14.7839342 12.5099523,16.4873714 C15.6015116,15.7252281 18.8605685,16.2206653 20.7179261,16.6468368 C20.9498404,16.7000164 21.1894877,16.6450739 21.3752964,16.4959653 C21.5604486,16.347224 21.6666667,16.1248832 21.6666667,15.886236 L21.6666667,3.4801066 C21.6666667,3.04974833 21.3189045,2.6996002 20.8914789,2.6996002 Z'
          id='Path'></path>
        <path
          d='M1.33735579,12.4864865 L1.33735579,2.6996002 L0.775187795,2.6996002 C0.347835065,2.6996002 0,3.04974833 0,3.4801066 L0,15.8860157 C0,16.1247363 0.10621809,16.3470036 0.291370229,16.495745 C0.477033031,16.6447801 0.716461447,16.6999429 0.948740568,16.6466164 C2.80609811,16.2203715 6.06522805,15.7250077 9.15664138,16.487151 C7.36917183,14.7837872 4.84517905,14.482485 3.31442754,14.4788124 C2.22430603,14.4761681 1.33735579,13.5824714 1.33735579,12.4864865 Z'
          id='Path'></path>
      </g>
    </SvgIcon>
  );
}

LearnSvg.defaultProps = {
  fill: '#fff',
};
