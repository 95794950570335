import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={2}
      headerIcon={<Svgs.ArtMagnifyingGlass />}
      headerText='SearchForTheBestRate'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('NowThatYouKnowWhyYoureRefinancing')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('WhetherYouCallDifferentLenders')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('FindOutTheDetails')}
          </Typography>
        </Stack>
      }
      related={[
        { textKey: 'MortgageRates', url: '/tools/house/mortgage-rates' },
        {
          textKey: 'MortgageRefinanceCalculator',
          url: '/tools/house/mortgage-refinance-calculator',
        },
      ]}
    />
  );
};

export default StepTwo;
