import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import Row from './Row';

interface RangeLegendProps {
  colors: string[];
  colorLabels?: string[];
  topLabel?: string;
  bottomLabel?: string;
}
export const RangeLegend: React.FC<RangeLegendProps> = ({
  colors,
  colorLabels,
  topLabel,
  bottomLabel,
}) => {
  return (
    <Stack className='range-legend-stack'>
      {topLabel && (
        <Typography mb={1} color='secondary' variant='p14'>
          {topLabel}
        </Typography>
      )}
      {colors.map((color, i) => (
        <Row
          style={colorLabels ? { justifyContent: 'space-between' } : { justifyContent: 'center' }}>
          {i === 0 ? (
            <Divider
              orientation='vertical'
              aria-hidden='true'
              sx={{
                width: 20,
                height: 60,
                transform: 'translate(-50 %, -50 %)',
                border: `10px solid ${color}`,
                borderRadius: '150px 150px 0 0',
                boxShadow: 'none',
                alignSelf: 'center',
              }}
            />
          ) : i === colors.length - 1 ? (
            <Divider
              orientation='vertical'
              aria-hidden='true'
              sx={{
                width: 20,
                height: 60,
                transform: 'translate(-50 %, -50 %)',
                border: `10px solid ${color}`,
                borderRadius: '0 0 150px 150px',
                boxShadow: 'none',
                alignSelf: 'center',
              }}
            />
          ) : (
            <Divider
              orientation='vertical'
              aria-hidden='true'
              sx={{
                width: 20,
                height: 60,
                borderRadius: '0 0 0 0',
                border: `10px solid ${color}`,
                boxShadow: 'none',
                alignSelf: 'center',
              }}
            />
          )}
          {colorLabels && (
            <Typography ml={2} color='secondary' variant='p14'>
              {colorLabels[i]}
            </Typography>
          )}
        </Row>
      ))}
      {bottomLabel && (
        <Typography mt={1} color='secondary' variant='p14'>
          {bottomLabel}
        </Typography>
      )}
    </Stack>
  );
};
