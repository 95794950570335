import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { AppReadySubject } from '../../subjects/AppReadySubject';
import { waitFor } from '../utils';
import { json } from 'react-router-dom';

export async function appReadyLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const isReady$ = resolver.resolveSubject(AppReadySubject);
  await waitFor(isReady$);
  return json('app-ready');
}
