import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import SpendingSummaryTimeframeSelector from './SpendingSummaryTimeframeSelector';
import SkeletonSpendingSummaryTotal from './skeletons/SkeletonSpendingSummaryTotal';

export interface SpendingSummaryTotalProps {
  transactionsSummary: Data.Transactions.TransactionsSummaryData[];
}

const SpendingSummaryTotal: React.FC<SpendingSummaryTotalProps> = ({ transactionsSummary }) => {
  const { loadingSummary } = Hooks.useTransactionsSummary();
  const spendingTotal = Hooks.useTransactionsSpendingTotal(transactionsSummary);

  if (loadingSummary) {
    return <SkeletonSpendingSummaryTotal />;
  }

  return (
    <Grid display='flex' justifyContent='flex-end' columnGap='30px' alignItems='center'>
      <SpendingSummaryTimeframeSelector />
      <Typography fontFamily='Montserrat' variant='p40Bold' color='secondary'>
        {formatWholeDollars(spendingTotal)}
      </Typography>
    </Grid>
  );
};

export default SpendingSummaryTotal;
