import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Spacing } from '../../../../themes';
import { Svgs } from '../../../../assets/svg';

const StepSix: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={6}
      headerIcon={<Svgs.ArtHouse style={{ marginTop: '7px', marginBottom: '7px' }} />}
      headerText='BeASmartHomeShopper'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('ByNowYouveAlreadyDecidedOnALot')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('MakeSureThatYouAreNotCompromising')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('WhenYouCanAttendInPersonOpenHouses')}
          </Typography>
        </Stack>
      }
    />
  );
};

export default StepSix;
