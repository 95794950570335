import ToolsCard from '../ToolsCard';
import { Grid } from '@mui/material';
import { Colors } from '../../../themes';
import { artQuiz } from '../../../assets/png';
import { t } from 'i18next';

const LessonToolsCard: React.FC = () => {
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];
  const content = getContent(module);

  return (
    <ToolsCard
      icon={
        <Grid
          style={{ backgroundColor: Colors.primaryLightest, padding: '15px' }}
          borderRadius='50%'>
          <img src={artQuiz} width='50px' height='50px' />
        </Grid>
      }
      label={t('ArticlesQuizzes')}
      content={content}
    />
  );
};

export default LessonToolsCard;

const getContent = (module: string) => {
  switch (module) {
    case 'budget':
      return t('BudgetLessonsCopy');
    case 'car':
      return t('CarLessonsCopy');
    case 'college':
      return t('CollegeLessonsCopy');
    case 'credit-card':
      return t('CreditCardLessonsCopy');
    case 'debt':
      return t('DebtLessonsCopy');
    case 'gifts':
      return t('GiftsLessonsCopy');
    case 'goals':
      return t('GoalsLessonsCopy');
    case 'house':
      return t('HouseLessonsCopy');
    case 'investment':
      return t('InvestmentLessonsCopy');
    case 'loans':
      return t('LoansLessonsCopy');
    case 'medical':
      return t('MedicalLessonsCopy');
    case 'retirement':
      return t('RetirementLessonsCopy');
    default:
      return '';
  }
};
