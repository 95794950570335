import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';

const StepFive: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={5}
      headerIcon={<Svgs.ArtHandshake style={{ marginTop: '7px' }} />}
      headerText='FindARealEstateAgent'
      body={
        <>
          <Typography mb={2} color='secondary' variant='p16'>
            {t('NotAllRealEstateAgents')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('AlsoTalkWithSeveralRealEstateAgents')}
          </Typography>
        </>
      }
    />
  );
};

export default StepFive;
