import React from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { TextInputProps } from './TextInput';
import { useRifm } from 'rifm';

export const formatForDisplay = (str: string): string => {
  if (!str) return '';

  // YYYY-MM-DD to MM/DD/YYYY
  const parts = str.split('-');
  if (parts.length === 3) {
    const [year, month, day] = parts;
    return `${month}/${day}/${year}`;
  }

  // MMDDYYYY to MM/DD/YYYY
  const digits = str.replace(/\D/g, '');
  let formatted = '';
  if (digits.length > 4) {
    formatted = `${digits.slice(0, 2)}/${digits.slice(2, 4)}/${digits.slice(4)}`;
  } else if (digits.length > 2) {
    formatted = `${digits.slice(0, 2)}/${digits.slice(2)}`;
  } else {
    formatted = digits;
  }
  return formatted;
};

export default function DateInput<T>(props: TextInputProps<T>) {
  const methods = useFormContext();
  const [value, setValue] = React.useState('');

  const rifm = useRifm({
    value: value,
    onChange: setValue,
    format: formatForDisplay,
  });

  React.useEffect(() => {
    const formattedForDisplay = formatForDisplay(props.defaultValue?.toString() ?? '');
    setValue(formattedForDisplay);
    methods.setValue(props.name as string, formattedForDisplay);
  }, [props.defaultValue]);

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        const newVal = values[name];
        const formattedForDisplay = formatForDisplay(newVal);
        setValue(formattedForDisplay);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name]);

  return (
    <TextField
      {...(props as TextFieldProps)}
      fullWidth
      size='small'
      variant='outlined'
      value={rifm.value}
      onChange={rifm.onChange}
      inputProps={{
        ...props.inputProps,
        color: 'secondary',
        ...(methods && methods.register ? methods.register(props.name as string) : {}),
        maxLength: 10,
      }}
      defaultValue={undefined}
      placeholder='MM/DD/YYYY'
    />
  );
}
