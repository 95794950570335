import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Grid, IconButton, Link, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { MutatorState } from '@aesop-fables/scrinium';
import { PaymentMethodsData } from '../../../api/apis/PaymentApi';
import { convertToTitleCase } from '../../../helpers/utilityFunctions';
import { Modal, Row } from '../../../components';
import { Colors } from '../../../themes';
import ActionSheet from '../../../components/ActionSheet';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { useLoading } from '../../../hooks/useLoading';

interface PaymentMethodsSectionProps {
  paymentMethodsData: PaymentMethodsData[] | undefined;
  defaultPaymentMethod: string | undefined;
  setDefaultPaymentMethod: MutatorState<string>;
  removePaymentMethod: MutatorState<string>;
  setActiveMethod: React.Dispatch<React.SetStateAction<PaymentMethodsData | undefined>>;
}

const PaymentMethodsSection: React.FC<PaymentMethodsSectionProps> = ({
  paymentMethodsData,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  removePaymentMethod,
  setActiveMethod,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  paymentMethodsData?.sort((a, b) => {
    if (a.id === defaultPaymentMethod) {
      return -1;
    } else if (b.id === defaultPaymentMethod) {
      return 1;
    } else {
      return 0;
    }
  });
  const [morePayments, setMorePayments] = useState<boolean>((paymentMethodsData?.length ?? 0) > 5);
  const [paymentListSubset, setPaymentListSubset] = useState<PaymentMethodsData[] | undefined>(
    paymentMethodsData?.slice(0, 5)
  );
  const [remainingPayments, setRemainingPayments] = useState<PaymentMethodsData[] | undefined>(
    paymentMethodsData?.slice(5)
  );

  useEffect(() => {
    if (paymentMethodsData && mobile) {
      splitUpPayments();
    }
  }, [paymentMethodsData, mobile]);

  const splitUpPayments = () => {
    setMorePayments((paymentMethodsData?.length ?? 0) > 5);
    setPaymentListSubset(paymentMethodsData?.slice(0, 5));
    setRemainingPayments(paymentMethodsData?.slice(5));
  };

  const onLoadMore = () => {
    const paymentsToAdd = remainingPayments?.slice(0, 5);
    const howManyRemaining = remainingPayments?.length ?? 0;
    if (howManyRemaining <= 5) setMorePayments(false);
    setRemainingPayments((prev) => prev?.slice(5));
    setPaymentListSubset([...(paymentListSubset ?? []), ...(paymentsToAdd ?? [])]);
  };

  if (!paymentMethodsData || paymentMethodsData.length === 0)
    return (
      <Card
        className='card-row'
        sx={{ padding: mobile ? '20px' : '10px', marginTop: mobile ? undefined : '20px' }}>
        <Grid container display='flex' alignItems='center' justifyContent='center'>
          <Typography
            sx={{ textAlign: mobile ? 'center' : undefined, width: mobile ? '80%' : undefined }}
            variant='p16'
            color='secondary'>
            {t('YouDontHaveAnySavedPaymentMethods')}
          </Typography>
        </Grid>
      </Card>
    );

  return (
    <>
      {!mobile && (
        <Grid
          container
          mt={2}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          padding='0px 5px'>
          <Grid item xs={0.5} paddingLeft={1} />
          <Grid item xs={6}>
            <Typography variant='p14' color='primary'>
              {t('Name')}
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography variant='p14' color='primary'>
              {t('Type')}
            </Typography>
          </Grid>
          <Grid item xs={1} paddingRight={1} />
        </Grid>
      )}
      {(mobile ? paymentListSubset ?? [] : paymentMethodsData).map((paymentMethod) => {
        return (
          <MethodCard
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            defaultPaymentMethod={defaultPaymentMethod}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            removePaymentMethod={removePaymentMethod}
            setActiveMethod={setActiveMethod}
          />
        );
      })}
      {mobile && morePayments && (
        <Button color='primary' variant='ghost' onClick={onLoadMore}>
          Load More
        </Button>
      )}
    </>
  );
};

interface MethodCardProps {
  paymentMethod: PaymentMethodsData;
  defaultPaymentMethod: string | undefined;
  setDefaultPaymentMethod: MutatorState<string>;
  removePaymentMethod: MutatorState<string>;
  setActiveMethod: React.Dispatch<React.SetStateAction<PaymentMethodsData | undefined>>;
}

const MethodCard: React.FC<MethodCardProps> = ({
  paymentMethod,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  removePaymentMethod,
  setActiveMethod,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const [modalOpen, setModalOpen] = useState(false);
  const isDefault = paymentMethod.id === defaultPaymentMethod;
  const [actionSheetOpen, setActionSheetOpen] = useState<boolean>(false);

  const onSetAsDefault = async () => {
    try {
      setLoading(true);
      setActiveMethod(paymentMethod);
      await setDefaultPaymentMethod.action(paymentMethod.id);
    } finally {
      setLoading(false);
    }
  };

  const onDeletePaymentMethod = async () => {
    try {
      setLoading(true);
      setActiveMethod(paymentMethod);
      await removePaymentMethod.action(paymentMethod.id);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={t('RemovePaymentMethod') as string}
        primaryButtonText='No, Keep Method'
        secondaryButtonText='Yes, Remove Method'
        secondaryButtonColor={mobile ? 'primary' : undefined}
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={onDeletePaymentMethod}>
        <Typography variant='p16' color='secondary'>
          {t('ConfirmRemovePaymentMethod')}
        </Typography>
        <Typography mt={2} variant='p16Bold' color='secondary'>
          {convertToTitleCase(paymentMethod.type)} ending in {paymentMethod.lastDigits}
        </Typography>
        <Typography variant='p16Bold' color='secondary'>
          {convertToTitleCase(paymentMethod.type)}
        </Typography>
      </Modal>

      {mobile ? (
        <>
          <Grid
            mb={1.5}
            sx={{
              borderRadius: '7px',
              padding: '20px',
              border: `solid 1px ${Colors.primaryLightest}`,
              backgroundColor: Colors.tertiaryBase,
            }}>
            <Row style={{ justifyContent: 'space-between' }}>
              <Typography mb={1} variant='p16Bold' color='secondary'>
                {convertToTitleCase(paymentMethod.type)} ending in {paymentMethod.lastDigits}
              </Typography>
              {!isDefault && <Svgs.ActionSmallEllipsis onClick={() => setActionSheetOpen(true)} />}
            </Row>
            {isDefault && (
              <Typography mb={1} variant='p16Bold' color='primary'>
                {t('Default')}
              </Typography>
            )}
            <Typography variant='p16' color='secondary'>
              {convertToTitleCase(paymentMethod.type)}
            </Typography>
          </Grid>
          <ActionSheet open={actionSheetOpen} setOpen={setActionSheetOpen}>
            <Grid sx={{ padding: '20px' }}>
              <Typography
                onClick={() => {
                  onSetAsDefault();
                  setActionSheetOpen(false);
                }}
                mb={3}
                color='primary'
                variant='p14SemiBold'>
                {t('SetAsDefault')}
              </Typography>
              <Typography
                onClick={() => {
                  setModalOpen(true);
                  setActionSheetOpen(false);
                }}
                mb={3}
                color='primary'
                variant='p14SemiBold'>
                Remove
              </Typography>
              <Typography
                onClick={() => {
                  setActionSheetOpen(false);
                }}
                color='primary'
                variant='p14SemiBold'>
                Cancel
              </Typography>
            </Grid>
          </ActionSheet>
        </>
      ) : (
        <Card
          className='card-row'
          sx={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
          <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={0.5} paddingLeft={1}>
              {!isDefault && (
                <IconButton
                  className='show-on-hover'
                  sx={{ opacity: 0, padding: 0 }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  disableRipple>
                  <Svgs.IpmIconDelete />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={6}>
              <Typography variant='p16Bold' color='secondary'>
                {convertToTitleCase(paymentMethod.type)} ending in {paymentMethod.lastDigits}
                {isDefault && (
                  <Typography ml={3} variant='p16Bold' color='primary' sx={{ display: 'inline' }}>
                    {t('Default')}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4.5}>
              <Typography variant='p16' color='secondary'>
                {convertToTitleCase(paymentMethod.type)}
              </Typography>
            </Grid>
            <Grid item xs={1} paddingRight={1} style={{ textAlign: 'end' }}>
              {!isDefault && (
                <Link variant='p12' noWrap onClick={onSetAsDefault}>
                  {t('SetAsDefault')}
                </Link>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default PaymentMethodsSection;
