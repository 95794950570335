import React from 'react';
import { useTranslation } from 'react-i18next';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={2}
      headerIcon={<Svgs.ArtHealthInsurance />}
      headerText='CompareTypesOfHealthInsurancePlans'
      body={
        <BulletedList
          color='secondary'
          style={{ marginTop: '6px' }}
          children={[
            t('ThereAreSeveralTypesOfPlans'),
            t('TheyDifferInOutOfPocketCosts'),
            t('YouCanLearnAboutHmo'),
          ]}
        />
      }
      related={[
        { textKey: 'TypesOfHealthInsurance', url: '/tools/medical/types-of-health-insurance' },
      ]}
    />
  );
};

export default StepTwo;
