import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const CollegeSavingsPlansCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomPiggyBank />}
      label={t('TypesOfCollegeSavingsPlans')}
      content={t('DeMystifyTheDifferences')}
    />
  );
};

export default CollegeSavingsPlansCard;
