import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';
import { TwoFactorMethodsWrapper as TwoFactorMethods } from './TwoFactorMethods';

const TwoFactorAuthView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography variant='p30Bold' component='h1' color='primary'>
          {t('TwoFactorAuth')}
        </Typography>
        <Typography mt={2} color='secondary' variant='p16'>
          {t('AnotherLayerOfSecurity')}
        </Typography>
        <TwoFactorMethods />
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
  onBack: (navigate) => navigate('/settings'),
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(TwoFactorAuthView, meta);
