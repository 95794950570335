/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, SetStateAction, Dispatch } from 'react';
import { LayoutMeta } from '../types/LayoutMeta';
import { useNavigate } from 'react-router-dom';
import { useServiceContainer } from '@aesop-fables/containr-react';

export function useLayoutMeta<T = object>() {
  const navigate = useNavigate();
  const [meta, setMeta] = useState<LayoutMeta<T>>({} as LayoutMeta<T>);
  const container = useServiceContainer();

  const next = async (values: any) => {
    if (!meta.operation) {
      // no-op
      return;
    }

    const operation = container.resolve(meta.operation);
    operation.onNext(values, navigate);
  };

  const prev = async () => {
    if (!meta.operation) {
      // no-op
      return;
    }

    const operation = container.resolve(meta.operation);
    operation.onPrevious(navigate);
  };

  return {
    meta: meta as LayoutMeta<T>,
    setMeta: setMeta as Dispatch<SetStateAction<Partial<LayoutMeta<T>>>>,
    next,
    prev,
  };
}
