import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Colors } from '../themes';

interface CarouselProps {
  items: React.ReactNode[];
  itemWidth: number;
}

export const Carousel: React.FC<CarouselProps> = ({ items, itemWidth }) => {
  const [index, setIndex] = useState(0);
  const [visibleItemsCount, setVisibleItemsCount] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const chevronWidth = 96;
  const buffer = 2 * chevronWidth;

  const updateVisibleItemsCount = () => {
    if (carouselRef.current) {
      const containerWidth = carouselRef.current.offsetWidth;
      const newVisibleItemsCount = Math.floor((containerWidth - buffer) / itemWidth);
      setVisibleItemsCount(newVisibleItemsCount);
    }
  };

  useEffect(() => {
    updateVisibleItemsCount();
    window.addEventListener('resize', updateVisibleItemsCount);

    return () => window.removeEventListener('resize', updateVisibleItemsCount);
  }, []);

  function onNext(): void {
    setIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex % items.length;
    });
  }

  function onPrevious(): void {
    setIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : items.length - 1;
    });
  }

  const endIndex = (index + visibleItemsCount) % items.length;
  const visibleItems =
    endIndex > index
      ? items.slice(index, endIndex)
      : [...items.slice(index, items.length), ...items.slice(0, endIndex)];

  return (
    <Grid container flexWrap='nowrap' ref={carouselRef}>
      <IconButton
        onClick={onPrevious}
        aria-label='Previous'
        style={{ alignSelf: 'center', marginRight: '64px' }}>
        <ChevronLeft fontSize='large' style={{ fill: Colors.primaryBase }} />
      </IconButton>
      <Grid
        item
        xs
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {visibleItems.map((item, i) => (
          <Grid key={i} style={{ width: itemWidth }}>
            {item}
          </Grid>
        ))}
      </Grid>
      <IconButton
        onClick={onNext}
        aria-label='Next'
        style={{ alignSelf: 'center', marginLeft: '64px' }}>
        <ChevronRight fontSize='large' style={{ fill: Colors.primaryBase }} />
      </IconButton>
    </Grid>
  );
};
