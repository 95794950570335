import { Grid, Typography } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import Spacer from '../../../../components/Spacer';

export interface CollisionInsuranceResultsProps {
  recommendation: Domain.CollisionInsuranceRecomendationEnum;
}

const CollisionInsuranceResults: React.FC<CollisionInsuranceResultsProps> = ({
  recommendation,
}) => {
  return (
    <Grid container direction='column' rowGap='30px' alignItems='center'>
      <Spacer height='xxs' />
      {typeof recommendation !== 'undefined' && <Svgs.ArtCar2 />}
      {recommendation === Domain.CollisionInsuranceRecomendationEnum['Worth It'] && (
        <Grid container direction='column' rowGap='10px' alignItems='center'>
          <Typography variant='p18Bold' color='secondary'>
            {t('WorthIt')}
          </Typography>
          <Typography variant='p16' color='secondary' textAlign='center'>
            {t('InsuranceIsAGoodChoice')}
          </Typography>
        </Grid>
      )}
      {recommendation === Domain.CollisionInsuranceRecomendationEnum['Probably Not Worth It'] && (
        <Grid container direction='column' rowGap='10px' alignItems='center'>
          <Typography variant='p18Bold' color='error'>
            {t('ProbablyNotWorth')}
          </Typography>
          <Typography variant='p16' color='secondary' display='block' textAlign='center'>
            {t('MightSaveMore1')}
            <strong>{t('without')}</strong>
            {t('MightSaveMore2')}
          </Typography>
        </Grid>
      )}
      {recommendation === Domain.CollisionInsuranceRecomendationEnum['Not Worth It'] && (
        <Grid container direction='column' rowGap='10px' alignItems='center'>
          <Typography variant='p18Bold' color='error'>
            {t('NotWorth')}
          </Typography>
          <Typography variant='p16' color='secondary' display='block' textAlign='center'>
            {t('CollisionInsuranceNotTheBestChoice1')}
            <strong>{t('not')}</strong>
            {t('CollisionInsuranceNotTheBestChoice2')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CollisionInsuranceResults;
