import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';
import { Margins } from '../../../../themes';
import { Stack } from '@mui/material';
import BulletedText from '../../../../components/BulletedText';

const HomeImprovementHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('EligibleHomeImprovements')}>
      <HelpPopoverText style={Margins.mb_md}>
        Money spent on your home falls into two categories for tax purposes– capital improvements or
        home repairs. The big difference is that capital improvements are added to the tax basis of
        the house while home repairs are not.
      </HelpPopoverText>
      <HelpPopoverText style={Margins.mb_sm}>
        Unfortunately, the IRS doesn’t provide a laundry list of what goes where. Think of a capital
        improvement as something that:
      </HelpPopoverText>
      <Stack mr={2} ml={2}>
        <BulletedText>Adds value to the home, like a swimming pool,</BulletedText>
        <BulletedText>Prolongs the life of the home, like a new roof, or</BulletedText>
        <BulletedText>Increases the use, like a room addition</BulletedText>
      </Stack>
      <HelpPopoverText style={Margins.mt_sm}>
        The improvement doesn’t have to be expensive to qualify– an intercom system could qualify.
      </HelpPopoverText>
      <HelpPopoverText style={Margins.mt_md}>
        Home repairs, such as painting, replacing a windowpane, or fixing a wall will not qualify.
      </HelpPopoverText>
    </HelpPopover>
  );
};

export default HomeImprovementHelp;
