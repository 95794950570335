import { ButtonPropsVariantOverrides, Grid } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Spacing } from '../../themes';
import { Button } from '../buttons/Button';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export interface WizardFooterProps {
  nextLabel: string;
  backLabel?: string;
  disableBack?: boolean;
  disableNext?: boolean;
  hideBack?: boolean;
  hideNext?: boolean;
  makeSubmit?: boolean;
  nextVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  backVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  onDone?: () => void;
  onBack?: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const WizardFooter: React.FC<WizardFooterProps> = ({
  disableBack = false,
  disableNext = false,
  hideBack = false,
  hideNext = false,
  nextLabel,
  backLabel = 'Back',
  nextVariant,
  backVariant,
  makeSubmit,
  onDone,
  onBack,
  color = 'secondary',
}) => {
  const navigate = useNavigate();
  const isSubmit = makeSubmit ?? typeof onDone === 'undefined';
  const clickHandler = isSubmit ? undefined : onDone;
  return (
    <Grid
      className='wizard-footer'
      item
      display='flex'
      justifyContent='space-between'
      sm={12}
      mt={Spacing.xxs}>
      <Grid container sm={1}>
        <Button
          disabled={disableBack || hideBack}
          style={{
            visibility: hideBack ? 'hidden' : undefined,
          }}
          fullWidth
          label={backLabel}
          variant={backVariant ?? 'outlined'}
          color={color}
          onClick={onBack ?? (() => navigate(-1))}
        />
      </Grid>
      {!hideNext && (
        <Grid container style={{ flexDirection: 'row-reverse' }} sm={2}>
          <Button
            disabled={disableNext}
            style={{
              minWidth: nextLabel.length > 6 ? 'fit-content' : '150px',
            }}
            fullWidth
            label={nextLabel}
            type={isSubmit ? 'submit' : 'button'}
            variant={nextVariant ?? 'contained'}
            color={color}
            onClick={clickHandler}
          />
        </Grid>
      )}
    </Grid>
  );
};
