import { Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { KeyValuePair, Spacer } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Api, formatWholeDollars } from '@3nickels/data-modules';
import { formatPercent } from '../../../../helpers/utilityFunctions';

interface RightColumnProps {
  wouldHelp: boolean;
  noDebts: boolean;
  results: Api.DebtConsolidationResultRest | undefined;
}

export const RightColumn: React.FC<RightColumnProps> = ({ wouldHelp, noDebts, results }) => {
  const { t } = useTranslation();
  const extraAsterisk = wouldHelp && !noDebts ? '*' : '';
  const monthText = results?.savingsOfTime === 1 ? 'MONTH' : 'MONTHS';

  return (
    <Grid
      item
      xs={5}
      ml={10}
      container
      direction='column'
      justifyContent='flex-start'
      minWidth='300px'>
      <Grid container direction='column' rowGap='120px'>
        <Grid item>
          <Stack spacing={Spacing.xxxs}>
            <Spacer height='xxs' />
            <Typography variant='p18Bold' color='secondary'>
              {t('LoanDetails')}
            </Typography>
            <KeyValuePair
              label={t('EstimatedAPR')}
              value={wouldHelp && !noDebts && results ? formatPercent(results.apr ?? 0) : '– – –'}
            />
            <KeyValuePair
              label={t('LoanAmount') + extraAsterisk}
              value={
                wouldHelp && !noDebts && results
                  ? formatWholeDollars(results.totalDebt ?? 0)
                  : '– – –'
              }
            />
            <KeyValuePair
              label={t('LoanTerm')}
              value={
                wouldHelp && !noDebts && results ? `${results.loanTerm} ${monthText}` : '– – –'
              }
            />
          </Stack>
          {wouldHelp && !noDebts && (
            <Typography mt={3} variant='p12' color='primary'>
              {t('OnlyConsolidateWhereBeneficial')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
