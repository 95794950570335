import { Typography, TypographyProps } from '@mui/material';
import { Margins } from '../themes';

const BulletedText: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <Typography
      className='bulleted-text'
      style={{
        ...Margins.mt_xxxs,
        display: 'list-item',
        // listStyle: 'inside',
        ...style,
      }}
      color={color ?? 'secondary'}>
      {children}
    </Typography>
  );
};

export default BulletedText;
