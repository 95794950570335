import { Grid, Typography } from '@mui/material';
import { Data, Domain, formatWholeDollars, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import CollisionInsuranceForm from './CollisionInsuranceForm';
import { useForm } from 'react-hook-form';
import CollisionInsuranceResults from './CollisionInsuranceResults';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { useLoaderData } from 'react-router-dom';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';

const collisionInsuranceSchema = Yup.object({
  carId: Yup.string().required('Required'),
  carValue: Yup.string()
    .required('Required')
    .test('carValue', 'Car Value must be greater than $0', (value) => {
      const cleanNumber = cleanWholeNumberStr(value);
      return cleanNumber > 0;
    }),
  collisionDeductible: Yup.string().notRequired(),
  sixMonthInsurancePremium: Yup.string().notRequired(),
});

const CollisionInsuranceCalculatorView: React.FC = () => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Domain.CollisionInsuranceResultData | undefined>();
  const [collisionInsuranceFormData, setCollisionInsuranceFormData] =
    useState<Domain.CollisionInsuranceFormData>({});
  const initialCars = useLoaderData() as Domain.CarData[] | undefined;
  const combinedCars = Hooks.useCombinedCars();
  const cars = useMemo(
    // use preloaded initalCars value from loader until combinedCars resloves to prevent screen flickering
    // when combined cars length is 0 initially
    () => (combinedCars.length === 0 ? initialCars : combinedCars),
    [initialCars, combinedCars]
  );
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.CollisionInsuranceFormData>(
    SavedFormsKeyEnum.CollisionInsurance
  );

  const formatInputs = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
  ): Domain.CollisionInsuranceFormData => {
    const formData: Domain.CollisionInsuranceFormData = {
      carId: cleanWholeNumberStr(values.carId ?? 0),
      collisionDeductible: cleanWholeNumberStr(values.collisionDeductible ?? 0),
      sixMonthInsurancePremium: cleanWholeNumberStr(values.sixMonthInsurancePremium ?? 0),
    };

    return Domain.mapCollisionInsuranceFormDataToCollisionInsuranceBreakevenCommandParams(formData);
  };

  const onSubmit = async (values: Domain.CollisionInsuranceFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.CollisionInsurance,
        formData: cleanedValues,
      });
      const { data } = await commands.execute(
        Data.Cars.Commands.CalculateCollisionInsuranceBreakevenOutput,
        Domain.mapCollisionInsuranceFormDataToCollisionInsuranceBreakevenCommandParams(
          cleanedValues
        )
      );
      setCollisionInsuranceFormData(cleanedValues);
      setResults(Domain.mapCarCollisionResultRestToCarCollisionResultData(data));
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Cars.Commands.CalculateCollisionInsuranceBreakevenOutputPdf,
        collisionInsuranceFormData
      );
      const url = window.URL.createObjectURL(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new Blob([pdfResults.data as any], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CollisionInsuranceBreakevenResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm<Domain.CollisionInsuranceFormData>({
    resolver: yupResolver(collisionInsuranceSchema),
    defaultValues: formData,
  });

  // wait for cars and formData to resolve to prevent flickering and
  // ensure default values aren't initialized before formData resolves
  if (typeof cars === 'undefined' || typeof formData === 'undefined') return null;

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <ToolTemplate
        header={
          results ? (
            <ToolHeader
              icon={<Svgs.ArtLightbulbLarge />}
              onDownload={onDownload}
              title={
                <RecommendationHeader
                  recommendation={
                    results.recommendation as Domain.CollisionInsuranceRecomendationEnum
                  }
                />
              }
            />
          ) : (
            <ToolHeader title={cars.length === 0 ? 'NoCarsAddedYet' : 'AddInsuranceDetails'} />
          )
        }
        inputColumn={
          <CollisionInsuranceForm
            results={results}
            cars={cars}
            collisionInsuranceFormData={formData}
            formMethods={methods}
            resetResults={() => setResults(undefined)}
            onSubmit={onSubmit}
          />
        }
        outputColumn={
          <CollisionInsuranceResults
            recommendation={results?.recommendation as Domain.CollisionInsuranceRecomendationEnum}
          />
        }
        boxItems={[
          {
            boxTitle: 'TotalInsuranceWillPay',
            boxResult:
              typeof results?.amountInsuranceWillPay !== 'undefined'
                ? formatWholeDollars(
                    results.amountInsuranceWillPay >= 0 ? results.amountInsuranceWillPay : 0
                  )
                : undefined,
          },
        ]}
      />
    </Grid>
  );
};

interface RecommendationHeaderProp {
  recommendation: Domain.CollisionInsuranceRecomendationEnum;
}

const RecommendationHeader: React.FC<RecommendationHeaderProp> = ({ recommendation }) => {
  const Recommendation = () => {
    if (recommendation === Domain.CollisionInsuranceRecomendationEnum['Worth It']) {
      return <strong>{t('WorthIt')}</strong>;
    } else if (
      recommendation === Domain.CollisionInsuranceRecomendationEnum['Probably Not Worth It']
    ) {
      return (
        <Typography component={'strong'} variant='p30' color='error'>
          {t('ProbablyNotWorth')}
        </Typography>
      );
    } else if (recommendation === Domain.CollisionInsuranceRecomendationEnum['Not Worth It']) {
      return (
        <Typography component={'strong'} variant='p30' color='error'>
          {t('NotWorth')}
        </Typography>
      );
    }
  };
  return (
    <Typography variant='p30' color='primary'>
      {t('OurRecommendation')}:&nbsp;{<Recommendation />}
    </Typography>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CollisionInsuranceCalculatorView, meta);
