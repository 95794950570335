import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { useContentView } from '../layout/ContentViewContext';
import { Hooks } from '@3nickels/data-modules';
import Breadcrumbs from '../../components/Breadcrumbs';
import CourseProgressSideView from './components/CourseProgressSideView';
import { useTranslation } from 'react-i18next';

export type UniversityLayout = Record<string, unknown>;

export declare type UniversityLayoutMeta = object;

const UniversityLayout: React.FC<UniversityLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<UniversityLayout>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { sideNav } = useContentView();
  const units = Hooks.useUniversityUnits();
  const allCourses = Hooks.useAllCourses();
  const onlyUniversity = (allCourses?.length ?? 0) === 0;

  const [pathnameSegments, breadcrumbNameMap] = useMemo(() => {
    const segments: string[] = [];
    const nameMap: { [key: string]: string } = {};
    const pathParts = location.pathname.split('/');

    const currentLesson = units
      .flatMap((unit) => unit.lessons)
      .find((lesson) => pathParts.includes(encodeURIComponent(lesson.title)));

    if (!onlyUniversity) {
      segments.push('learning');
      segments.push('university');

      nameMap['/learning'] = t('YourCourses');
      nameMap['/learning/university'] = '3NickelsU';
    } else if (currentLesson) {
      segments.push('learning/university');

      nameMap['/learning/university'] = '3NickelsU';
    }

    if (currentLesson) {
      const encodedTitle = encodeURIComponent(currentLesson.title);
      segments.push(encodedTitle);

      nameMap[`/learning/university/${encodedTitle}`] = currentLesson.title;
    }

    return [segments, nameMap];
  }, [location.pathname, units, onlyUniversity]);

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('Learn')}
      progressIndicator={<CourseProgressSideView />}
      progressIndicatorPosition='end'
      leftChildren={
        <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
      }>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default UniversityLayout;
