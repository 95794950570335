/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { Api, Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Backdrop, Divider, Grid, Typography } from '@mui/material';
import { Loading } from '../../../hooks/useLoading';
import { IncomeReadySubject } from '../../../subjects/AppReadySubject';
import { useSubject } from '@aesop-fables/scrinium';
import { BudgetSectionProps, IncomeFormData, getAdjustedNetRealEstateIncome } from '../Types';
import { useIncomeSchema } from '../BudgetHooks';
import { IncomeForm, IncomeFormProps } from './BudgetIncomeForm';
import EmbeddedList from '../../../components/EmbeddedList';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Svgs } from '../../../assets/svg';
import { Colors } from '../../../themes';
import { LeaveBudgetDetailsModal } from '../BudgetDetailsLayout';
import { useNavigate } from 'react-router-dom';

const IncomeSectionWrapper: React.FC<BudgetSectionProps> = ({ budget, mode }) => {
  const financialInfo = Hooks.useFinancialInfo();
  const incomeReady = useSubject<boolean>(IncomeReadySubject);
  const includeSpouse = Hooks.useIncludeSpouse();

  // For when Michael asks: I'm intentioanlly putting this inside of the react code
  // because I want to be able to compare both patterns (and not get too lost in data modules)
  const defaultValues: IncomeFormData = useMemo(() => {
    return {
      annualIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.income),
      otherAnnualIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.otherIncome),
      unearnedIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.unearnedIncome),
      spouseAnnualIncome: includeSpouse
        ? Data.Budget.adjustAnnualAmount(mode, financialInfo?.spouseIncome)
        : undefined,
      spouseAnnualOtherIncome: includeSpouse
        ? Data.Budget.adjustAnnualAmount(mode, financialInfo?.spouseOtherIncome)
        : undefined,
    };
  }, [includeSpouse, financialInfo, mode]);

  if (typeof incomeReady === 'undefined' || incomeReady === false) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }
  return <IncomeSection defaultValues={defaultValues} mode={mode} budget={budget} />;
};

export const IncomeSection: React.FC<
  Omit<IncomeFormProps, 'incomeType'> & BudgetSectionProps & { budget?: Api.BudgetRest }
> = ({ budget, defaultValues, mode }) => {
  const navigate = useNavigate();
  const incomeSchema = useIncomeSchema();
  const methods = useForm<IncomeFormData>({
    resolver: yupResolver(incomeSchema),
    defaultValues,
  });
  const housing = Hooks.useCombinedRealEstateData();
  const taxes = Hooks.useEstimatedTaxes(mode);
  const incomeSummary = useMemo(
    () => Data.Budget.adjustAnnualAmount(mode, budget?.afterTaxHouseholdIncome),
    [budget?.afterTaxHouseholdIncome, mode]
  );

  const [open, setOpen] = useState(false);

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  // The useAutoSaveHandler hook needs to be inside of react-hook-form's FormProvider
  // (We use that inside of our custom FormContent component)

  // The div for the Estimated Taxes is just a placeholder for a real component
  return (
    <CollapsiblePanel
      header='INCOME'
      footnote='(After Tax)'
      icon={<Svgs.DisplaySmallCustomWallet />}
      summary={formatWholeDollars(incomeSummary)}>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <Grid className='embedded-content'>
        <Typography variant='p18Bold' color='secondary' marginY='10px'>
          Earned Income
          <Typography variant='p12' color='primary' fontWeight='regular' marginLeft='5px'>
            (Before tax)
          </Typography>
        </Typography>
        <FormContent formProviderProps={methods}>
          <IncomeForm defaultValues={defaultValues} mode={mode} incomeType='earned' />
        </FormContent>
        <Typography variant='p18Bold' color='secondary' marginY='20px'>
          Unearned Income
          <Typography variant='p12' color='primary' fontWeight='regular' marginLeft='5px'>
            (Before tax)
          </Typography>
        </Typography>
        <EmbeddedList<Domain.RealEstateData>
          items={housing}
          keyFn={(x) => x.id?.toString() ?? ''}
          header='Net Real Estate Income'
          emptyHeader='No Real Estate Investments Added'
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedNetRealEstateIncome(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
        />
        <Divider sx={{ borderColor: Colors.primaryLight }} />
        <FormContent formProviderProps={methods}>
          <IncomeForm defaultValues={defaultValues} mode={mode} incomeType='unearned' />
        </FormContent>
        <Grid container display='flex' justifyContent='space-between' marginTop='20px'>
          <Typography variant='p18Bold' color='secondary'>
            Estimated Taxes:
          </Typography>
          <Grid item display='flex'>
            <Typography variant='p16Bold' color='error'>
              -{formatWholeDollars(taxes)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CollapsiblePanel>
  );
};

export default IncomeSectionWrapper;
