import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';
import ChecklistTemplate from '../../ChecklistTemplate';
import NumberedListItem from '../../../../components/NumberedListItem';

export const StepFour: React.FC = () => {
  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={
        <Svgs.DisplayLargeCustomMoneyExchange style={{ width: '80px', height: '80px' }} />
      }
      headerText={'OptionsToSell'}
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('ThreeOptions')}
          </Typography>
          <Grid sx={{ listStyle: 'decimal', pl: 4 }}>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              {t('HireAgent')}
              <Typography sx={{ display: 'inline' }} color='secondary'>
                {t('WithProfessionalUnderstanding')}
              </Typography>
            </NumberedListItem>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              {t('GetCashInstantBuyer')}
              <Typography sx={{ display: 'inline' }} color='secondary'>
                {t('LessMoneyStreamlines')}
              </Typography>
            </NumberedListItem>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              <Typography sx={{ display: 'inline' }} color='secondary'>
                {t('The ')}
              </Typography>
              <Typography sx={{ display: 'inline', fontWeight: 'bold' }} color='secondary'>
                {t('FSBO')}
              </Typography>
              <Typography sx={{ display: 'inline' }} color='secondary'>
                {t('LessMoneyMoreLegwork')}
              </Typography>
            </NumberedListItem>
          </Grid>
        </Stack>
      }
    />
  );
};
