import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../themes';
import { Row, Spacer } from '../../../components';
import { Svgs } from '../../../assets/svg';
import BudgetSubsection from './BudgetSubsection';
import LeftoverCard from './LeftoverCard';
import InfoCard from '../../../components/InfoCard';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { SaveSpendGivePieChart } from './SaveSpendGivePieChart';
import { Data, Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import { useNeedsOnboarding } from '../../../hooks';
import { useLoading } from '../../../hooks/useLoading';

const BudgetOverview: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showCurrent, setShowCurrent] = useState<boolean>(true);
  const [showSaving, setShowSaving] = useState<boolean>(false);
  const [showSpending, setShowSpending] = useState<boolean>(false);
  const [showGiving, setShowGiving] = useState<boolean>(false);
  const [enableNext, setEnableNext] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<string>('');
  const holisticAdvice = Hooks.useHolisticAdviceOverview();
  const adviceLoaded =
    typeof holisticAdvice?.budget !== 'undefined' &&
    typeof holisticAdvice?.originalBudget !== 'undefined';
  const showSavingBlurb = useMemo(
    () => (holisticAdvice?.budget.leftOverAmount ?? 0) < 0,
    [holisticAdvice?.budget.leftOverAmount]
  );
  const showGivingBlurb = false;
  const budgetTypes = ['monthlySaving', 'monthlySpending', 'monthlyGiving'];
  const colors = [Colors.primaryBase, Colors.accentBase, Colors.secondaryBase];
  const onboarding = useNeedsOnboarding();
  const commands = Hooks.useCommands();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (navigateTo !== '') {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo]);

  const onDone = useMemo(() => {
    if (onboarding) {
      return () => navigate('/holistic-advice/goals');
    }

    const goal = holisticAdvice?.goalSummaries[0];
    // skip start screen if not in onboarding
    return () => navigate(`/holistic-advice/goals/${goal?.advisedGoalPlan.id}`);
  }, [onboarding, holisticAdvice]);

  const onGotIt = () => {
    if (!showSaving) {
      setShowSaving(true);
    }
    if (showSaving && !showSpending) {
      setShowSpending(true);
    }
    if (showSaving && showSpending && !showGiving) {
      setShowGiving(true);
    }
  };

  const navigateToBudget = async () => {
    try {
      setLoading(true);
      if (onboarding) {
        if (holisticAdvice?.caseId) {
          await commands.execute(Data.HolisticAdvice.AcceptHolisticAdvice, holisticAdvice.caseId);
        }
        // set navigation and wait for the router to change.
        // navigating directly didn't work because the router used changes when the use completes onboarding
        setNavigateTo('/tools/budget/cut-spending');
      } else {
        navigate('/tools/budget/cut-spending');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSkip = () => {
    setShowSaving(true);
    setShowSpending(true);
    setShowGiving(true);
  };

  useEffect(() => {
    if (showSaving && showSpending && showGiving) {
      setEnableNext(true);
    }
  }, [showSaving, showSpending, showGiving]);

  return (
    <Box width='100%'>
      <Grid container display='flex' flexDirection='column'>
        <Typography variant='p30' color='primary' marginBottom='40px'>
          Where your&nbsp;
          <Typography variant='h1' color='primary'>
            money goes&nbsp;
          </Typography>
          each month
        </Typography>
        <Grid container>
          <Grid item sm={3} height='100%'>
            <SaveSpendGivePieChart
              data={[
                {
                  label: 'Save',
                  amount: holisticAdvice?.budget.monthlySaving,
                },
                {
                  label: 'Spend',
                  amount: holisticAdvice?.budget.monthlySpending,
                },
                {
                  label: 'Give',
                  amount: holisticAdvice?.budget.monthlyGiving,
                },
              ]}
              colors={colors}
              leftoverAmount={holisticAdvice?.budget?.leftOverAmount ?? 0}
              showSkeleton={!adviceLoaded}
            />
            {showSavingBlurb && (
              <InfoCard onClick={navigateToBudget}>
                <Typography color='secondary' variant='p16'>
                  {t('CheckOutSomeOfTheseMoneySavingIdeas')}.
                </Typography>
              </InfoCard>
            )}
            {showGivingBlurb && (
              <InfoCard>
                <Typography color='secondary' variant='p16'>
                  {t('ItsEasyToCreateACashGivingGoal')}.
                </Typography>
              </InfoCard>
            )}
          </Grid>
          <Grid item mt={3} sm={9}>
            <Header
              adviceLoaded={adviceLoaded}
              showCurrent={showCurrent}
              setShowCurrent={setShowCurrent}
            />
            <Spacer height='xxs' />
            <LeftoverCard
              originalBudget={holisticAdvice?.originalBudget}
              recommendedBudget={holisticAdvice?.budget}
              showCurrent={showCurrent}
            />
            <Spacer height='md' />
            {adviceLoaded && (
              <>
                <Grid item sm={11} display='flex' flexDirection='column' alignItems='center'>
                  <Grid container flexDirection='row'>
                    {budgetTypes.map((budgetType, index) => (
                      <React.Fragment key={budgetType}>
                        <BudgetSubsection
                          type={budgetType}
                          originalBudget={holisticAdvice.originalBudget}
                          recommendedBudget={holisticAdvice.budget}
                          color={colors[index]}
                          showCurrent={showCurrent}
                          showSubsection={
                            budgetType === 'monthlySaving'
                              ? showSaving
                              : budgetType === 'monthlySpending'
                              ? showSpending
                              : showGiving
                          }
                        />
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
                {!enableNext && (
                  <Grid container flexDirection='row'>
                    <Grid item sm={7} />
                    <Grid
                      item
                      sm={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                      onClick={onSkip}>
                      <Typography
                        color='primary'
                        variant='p14Bold'
                        className='clickable'
                        fontFamily='Montserrat'>
                        Skip
                      </Typography>
                    </Grid>
                    <Grid item sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button color='primary' onClick={onGotIt}>
                        Got it
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <WizardFooter
          nextLabel='Next: My Goals'
          hideNext={!enableNext}
          onDone={onDone}
          onBack={() => navigate('/')}
        />
      </Grid>
    </Box>
  );
};

interface HeaderProps {
  adviceLoaded: boolean;
  showCurrent: boolean;
  setShowCurrent: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({ adviceLoaded, showCurrent, setShowCurrent }) => {
  return (
    <Grid item sm={11} display='flex' flexDirection='column' alignItems='center'>
      <Grid container flexDirection='row'>
        <Grid item sm={5} />
        <Grid
          item
          sm={3}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          onClick={() => setShowCurrent((prevState) => !prevState)}>
          <Row style={{ visibility: adviceLoaded ? 'visible' : 'hidden' }}>
            {showCurrent ? (
              <>
                <Typography color='primary' variant='p12' className='clickable'>
                  Hide
                </Typography>
                <Spacer width='xxxs' />
                <Svgs.IconMinimize style={{ cursor: 'pointer' }} />
                <Spacer width='xs' />
                <Typography color={Colors.tertiaryDark} variant='p16Bold'>
                  Current
                </Typography>
              </>
            ) : (
              <>
                <Typography color='primary' variant='p12' className='clickable'>
                  Compare to Current Budget
                </Typography>
                <Spacer width='xxxs' />
                <Svgs.IconAdd style={{ cursor: 'pointer' }} />
              </>
            )}
          </Row>
        </Grid>
        <Grid item sm={1} />
        <Grid item sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography color='secondary' variant='p16Bold'>
            Recommended
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Next: My Goals',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(BudgetOverview, meta);
