import { Data, Domain } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { IAppStorage, ScriniumServices } from '@aesop-fables/scrinium';
import { json, redirect } from 'react-router';
import { firstValueFrom } from 'rxjs';
import { waitFor } from '../utils';

export async function universityLoader(container: IServiceContainer) {
  const appStorage = container.get<IAppStorage>(ScriniumServices.AppStorage);
  const userDataCache = Data.Users.retrieveUserData(appStorage);
  const universityCache = appStorage.retrieve<Data.University.UniversityCompartments>(
    Data.University.universityStorageKey
  );
  const features = await firstValueFrom(userDataCache.observe$<Domain.FeatureMap>('features'));
  if (!features?.threeNickelsU) {
    return redirect('/');
  }

  await waitFor(universityCache.initialized$);

  return json('3nU enabled');
}
