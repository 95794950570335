import { Domain } from '@3nickels/data-modules';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../themes';
import { Svgs } from '../../../assets/svg';
import { Button } from '../../../components/buttons/Button';
import QuestionDisplay from './QuestionDisplay';

interface SuccessContentProps {
  onRetake: () => void;
  onReview: () => void;
  quizAttempt: Domain.QuizAttemptDto;
  quizReview: Domain.ReviewQuizDto;
}

const SuccessContent: React.FC<SuccessContentProps> = ({
  onRetake,
  onReview,
  quizAttempt,
  quizReview,
}) => {
  const { t } = useTranslation();
  const totalQuestions = quizReview.questions.length ?? 0;

  return (
    <Grid display='flex' flexDirection='row'>
      <Grid
        className='quiz-results-left'
        width='45%'
        display='flex'
        flexDirection='column'
        alignItems='center'>
        <Typography mb={4} textAlign='center' variant='p30Bold' color={Colors.primaryBase}>
          {t('CongratsCompleteLesson')}
        </Typography>
        <Svgs.ArtHandClap />
        <Card
          className='quiz-results-card'
          sx={{
            marginTop: '30px',
            marginBottom: '46px',
            width: '70%',
            minWidth: '200px',
            maxWidth: '400px',
          }}>
          <Typography textAlign='center' mb={1} variant='p16' color='secondary'>
            {quizAttempt.answersCorrect ?? 0} out of {totalQuestions} questions
          </Typography>
          <Typography textAlign='center' variant='p30Bold' color='secondary'>
            {Math.round(quizAttempt.percentage ?? 0)}%
          </Typography>
        </Card>
        <Button
          onClick={onReview}
          variant='outlined'
          style={{ marginTop: '10px', width: '60%', maxWidth: '350px', minWidth: '175px' }}>
          {t('ReviewLesson')}
        </Button>
        <Button
          onClick={onRetake}
          variant='ghost'
          style={{
            backgroundColor: 'transparent',
            marginTop: '10px',
            width: '60%',
            maxWidth: '350px',
            minWidth: '175px',
          }}>
          {t('RetakeQuiz')}
        </Button>
      </Grid>
      <Divider
        orientation='vertical'
        color={Colors.primaryLight}
        sx={{ borderColor: Colors.primaryLight, margin: '-40px 50px', minHeight: '90vh' }}
        flexItem
      />
      <Grid
        className='quiz-results-right'
        width='45%'
        display='flex'
        flexDirection='column'
        alignItems='center'>
        {quizReview.questions.map((reviewQuestion, i) => (
          <QuestionDisplay key={i} index={i + 1} reviewQuestion={reviewQuestion} />
        ))}
      </Grid>
    </Grid>
  );
};

export default SuccessContent;
