import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { Spacing } from '../../../../themes';
import DebtBasicsCard from '../basics/DebtBasicsCard';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { DebtBasicsEditViewWrapper as DebtBasicsEditView } from '../basics/DebtBasicsEditView';
import DebtDetailsCard from '../details/DebtDetailsCard';
import { DebtDetailsEditViewWrapper as DebtDetailsEditView } from '../details/DebtDetailsEditView';
import { mapDebtTypePathToEnum, mapDebtTypeToType } from '../DebtUtils';
import { SummaryView } from '../../../../components/SummaryHeader';
import { useMessage } from '../../../../hooks/useMessage';
import { useTranslation } from 'react-i18next';

declare type DebtSummaryDrawerType = keyof Data.Debt.DebtWizardRegistry;

const DebtSummaryView: React.FC = () => {
  const { t } = useTranslation();
  const data = Hooks.useDebtWizardData();
  const { wizard } = Hooks.useDebtWizard();
  const [isStarted, params] = useObservable(combineLatest([wizard.isStarted$, wizard.params$])) ?? [
    undefined,
    undefined,
  ];
  const includeSpouse = Hooks.useIncludeSpouse();
  const { id, type } = useParams();

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const plaidWizard = Hooks.usePlaidWizard();
  const { setLoading } = useLoading();
  const debts = Hooks.useCombinedDebts();
  const deleteMutation = useMutation(new Data.Debt.Mutations.DeleteDebt());
  const account = useMemo(
    () => debts.find((x) => x.id === params?.id) as Domain.IDebt,
    [debts, params]
  );
  const accountType =
    account?.debtType === Domain.DebtTypeEnum.CREDIT_CARD
      ? Domain.FinancialAccountTypeEnum.CreditCard
      : Domain.FinancialAccountTypeEnum.Debt;
  const accountSubType = t(
    mapDebtTypeToType(data.params.type ?? Domain.DebtTypeEnum.OTHER)
  ).toString();

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: mapDebtTypePathToEnum(type ?? '') });
    }
  }, [isStarted]);

  const openDrawer = (key?: DebtSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };
  const { showMessage } = useMessage();

  const removeAccountHandler = useCallback(async () => {
    try {
      deleteMutation.action(account); // no need to actually wait since the datacache will still be updated
      navigate('/account-details');
      showMessage('Debt removed!');
    } catch {
      setLoading(false);
    }
  }, [account]);

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(account, accountType);
  }, [plaidWizard, debts]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={(data.details.amountOwed ?? 0) * -1}
      onLinkAccount={linkAccountHandler}
      linkableAccount={account}
      accountType={accountType}
      accountSubType={accountSubType}
      onRemoveAccount={removeAccountHandler}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <DebtBasicsCard
            debtBasics={data.basic}
            params={data.params}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <DebtDetailsCard debtDetails={data.details} onEdit={() => openDrawer('details')} />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(undefined as unknown as keyof Data.Debt.DebtWizardRegistry);
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useDebtWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;

  if (key === 'basic') {
    return <DebtBasicsEditView editing onBack={onBack} />;
  }

  if (key === 'details') {
    return <DebtDetailsEditView editing onBack={onBack} />;
  }

  return null;
};

const meta = {
  nextLocaleKey: 'Done',
  hexSteps: {
    steps: 5,
    currentStep: 1,
  },
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(DebtSummaryView, meta);
