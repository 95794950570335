import React, { CSSProperties } from 'react';
import { ChartHoneycomb, ChartHoneycombData } from './ChartHoneycomb';

const statePositions = createStatePositionMap();

interface ChartStateHoneycombProps {
  data: ChartStateHoneycombCellData[];
  style?: CSSProperties;
}

export interface ChartStateHoneycombCellData {
  stateAbbreviation: string;
  color: string;
  textColor: string;
  stateName: string;
  stateValue: string;
}

export const ChartStateHoneycomb: React.FC<ChartStateHoneycombProps> = (props) => {
  const mappedData: ChartHoneycombData[] = props.data.map((x) => {
    const state = x.stateAbbreviation ?? '';

    return {
      positionIndex: statePositions.get(state) ?? 0,
      text: state,
      textColor: x.textColor,
      color: x.color,
      stateName: x.stateName,
      stateValue: x.stateValue,
    };
  });
  const filteredMappedData = mappedData.filter((state) => state.text !== 'DC');

  return <ChartHoneycomb style={props.style} columns={11} data={filteredMappedData} />;
};

function createStatePositionMap(): Map<string, number> {
  const map = new Map<string, number>();
  map.set('AL', 69);
  map.set('AK', 0);
  map.set('AZ', 65);
  map.set('AR', 57);
  map.set('CA', 53);
  map.set('CO', 44);
  map.set('CT', 41);
  map.set('DE', 51);
  map.set('FL', 80);
  map.set('GA', 70);
  map.set('HI', 84);
  map.set('ID', 32);
  map.set('IL', 36);
  map.set('IN', 37);
  map.set('IA', 35);
  map.set('KS', 56);
  map.set('KY', 47);
  map.set('LA', 67);
  map.set('ME', 10);
  map.set('MD', 50);
  map.set('MA', 30);
  map.set('MI', 27);
  map.set('MN', 24);
  map.set('MS', 68);
  map.set('MO', 46);
  map.set('MT', 22);
  map.set('NE', 45);
  map.set('NV', 43);
  map.set('NH', 20);
  map.set('NJ', 40);
  map.set('NM', 55);
  map.set('NY', 29);
  map.set('NC', 59);
  map.set('ND', 23);
  map.set('OH', 38);
  map.set('OK', 66);
  map.set('OR', 42);
  map.set('PA', 39);
  map.set('RI', 31);
  map.set('SC', 60);
  map.set('SD', 34);
  map.set('TN', 58);
  map.set('TX', 77);
  map.set('UT', 54);
  map.set('VT', 19);
  map.set('VA', 49);
  map.set('WA', 21);
  map.set('WV', 48);
  map.set('WI', 25);
  map.set('WY', 33);

  return map;
}
