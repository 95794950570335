import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Api, Data, Domain, Hooks } from '@3nickels/data-modules';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../components/buttons/Button';
import { Svgs } from '../../assets/svg';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import { useService } from '@aesop-fables/containr-react';
import { useLoading } from '../../hooks/useLoading';

const UniversityLessonView: React.FC = () => {
  const navigate = useNavigate();
  const { title } = useParams();
  const universityApi = useService<Api.IUniversityApi>(Api.ApiKeys.University);
  const course = Hooks.useUniversityCourse();
  const lesson = Hooks.useUniversityLessonByTitle(title ?? '');
  const duration = useMemo(() => {
    return Math.round((lesson?.lesson.duration ?? 0) / 60);
  }, [lesson]);
  const video = useMemo(() => {
    return lesson?.lesson.resources.find((x) => x.type === Domain.ResourceTypeEnum.VIDEO);
  }, [lesson]);
  const thumbnail = useMemo(() => {
    return lesson?.lesson?.resources.find((x) => x.type === Domain.ResourceTypeEnum.THUMBNAIL);
  }, [lesson]);
  const pdf = useMemo(() => {
    return lesson?.lesson?.resources.find((x) => x.type === Domain.ResourceTypeEnum.PDF);
  }, [lesson]);
  const streamRef = useRef<StreamPlayerApi | undefined>(undefined);
  const [videoProgress, setVideoProgress] = useState<number | undefined>();
  const [locked, setLocked] = useState(false);
  const commands = Hooks.useCommands();
  const updateVideoProgress = new Data.University.Mutations.UpdateVideoResourceProgress();
  const { setLoading } = useLoading();

  useEffect(() => {
    const cleanup = () => {
      onSeek();
    };
    if (streamRef) {
      return cleanup;
    }
  }, [streamRef, course?.id, lesson?.unit.title, lesson?.lesson.title, video?.id]);

  useEffect(() => {
    if (videoProgress === undefined && typeof video?.progress !== 'undefined') {
      if (video.complete) {
        setVideoProgress(0);
      } else {
        setVideoProgress(video?.progress ?? 0);
      }
    }
  }, [videoProgress, video?.progress, video?.complete]);

  const downloadPdf = async () => {
    if (pdf?.url) {
      try {
        setLoading(true);
        const response = await universityApi.getLessonGuidePdf(pdf.id);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${lesson?.lesson.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.open(pdf.url);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const startNextLesson = () => {
    if (lesson?.next) {
      navigate(`/learning/university/${encodeURIComponent(lesson.next.title)}`);
    }
  };

  const onTimeUpdate = async () => {
    const position = streamRef.current?.currentTime ?? 0;
    const progress = video?.progress ?? 0;
    const diff = position - progress;
    if (!locked && diff > 30) {
      setLocked(true);
      await commands.executeMutation(updateVideoProgress, {
        courseId: course?.id,
        unitTitle: lesson?.unit.title,
        lessonTitle: lesson?.lesson.title,
        resourceId: video?.id,
        position: position,
      });
      setLocked(false);
    }
  };

  const onSeek = async () => {
    const position = streamRef.current?.currentTime;
    await commands.executeMutation(updateVideoProgress, {
      courseId: course?.id,
      unitTitle: lesson?.unit.title,
      lessonTitle: lesson?.lesson.title,
      resourceId: video?.id,
      position: position,
    });
  };

  return (
    <Grid display='flex' columnGap='30px'>
      <Grid width='100%'>
        <div
          style={{
            aspectRatio: '16/9',
            backgroundColor: 'black',
            backgroundImage: `url('${thumbnail?.url}')`,
            backgroundSize: 'cover',
          }}>
          {typeof video !== 'undefined' && typeof videoProgress !== 'undefined' && (
            <Stream
              controls
              streamRef={streamRef}
              src={video.uid}
              poster={thumbnail?.url}
              autoplay
              startTime={videoProgress}
              onTimeUpdate={onTimeUpdate}
              onSeeked={onSeek}
              onEnded={() => onSeek().then(startNextLesson)}
            />
          )}
        </div>
        <Typography variant='p30Bold' color='primary' mt='20px'>
          {lesson?.lesson.title ?? title}
        </Typography>
        <Typography variant='p18Bold' color='secondary' mt='10px'>
          {duration} min
        </Typography>
        <Typography variant='p16' color='secondary' mt='10px' mb='20px'>
          {lesson?.lesson.description}
        </Typography>
        {pdf?.id && (
          <Button label='DownloadPDF' onClick={downloadPdf}>
            <Svgs.IconDownload style={{ marginLeft: '10px' }} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default UniversityLessonView;
