import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { LmsLayoutMeta } from '../LmsLayout';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SuccessContent from './SuccessContent';
import FailureContent from './FailureContent';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { Loading, useLoading } from '../../../hooks/useLoading';

const LmsQuizResultsView: React.FC = () => {
  const commands = Hooks.useCommands();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const { unitId, courseId, lessonId: lessonIdString } = useParams();
  const lessonId = parseInt(lessonIdString ?? '');
  const lesson = useObservableQuery(Data.Lms.Queries.FindLessonByLessonId, lessonId);
  const course = useObservableQuery(
    Data.Lms.Queries.FindCourseByCourseId,
    parseInt(courseId ?? '')
  );
  const quiz = Hooks.useQuizByLessonId(lessonId ?? 0);
  const { resetQuiz } = Hooks.useQuizContext();
  const [quizAttempt, setQuizAttempt] = useState<Domain.QuizAttemptDto | undefined>(undefined);
  const [quizReview, setQuizReview] = useState<Domain.ReviewQuizDto | undefined>(undefined);
  const { setMeta } = useOutletContext<LayoutContext>();
  const { nextUnitId, nextLesson } = Hooks.useNextLesson(
    parseInt(courseId ?? ''),
    parseInt(unitId ?? ''),
    lessonId
  );
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];
  const yourLessonsView = pathnameSegments.includes('your-lessons');
  const path = yourLessonsView ? `/tools/${module}/your-lessons` : '/learning';
  const fromCourseView = Boolean(state?.fromCourseView);

  const footerText = useMemo(
    () =>
      nextLesson && !yourLessonsView
        ? 'NextLesson'
        : course?.completionPercentage === 100 && !yourLessonsView
        ? 'FinishCourse'
        : 'Done',
    [nextLesson, nextUnitId, course?.completionPercentage, yourLessonsView]
  );

  const backText = useMemo(() => {
    if (yourLessonsView) {
      return 'CaretYourLessons';
    }
    if (fromCourseView) {
      return 'CourseSummary';
    }
    return 'Back';
  }, [yourLessonsView, fromCourseView]);

  const navigateToNextLesson = useCallback(() => {
    if (yourLessonsView) {
      navigate(path);
    } else if (nextLesson) {
      navigate(`/learning/${courseId}/${nextUnitId}/${nextLesson?.lessonId}/content`);
    } else {
      navigate(`/learning/${courseId}`);
    }
  }, [yourLessonsView, path, nextLesson, courseId, nextUnitId, navigate]);

  const navigateBack = useCallback(() => {
    if (yourLessonsView) {
      navigate(path);
    } else if (fromCourseView) {
      navigate(`/learning/${courseId}`);
    } else {
      navigate(-1);
    }
  }, [yourLessonsView, fromCourseView, path, courseId, navigate]);

  useEffect(() => {
    setMeta(() => ({
      nextLocaleKey: footerText,
      backLocaleKey: backText,
      onNext: navigateToNextLesson,
      onBack: navigateBack,
    }));
  }, [nextLesson, nextUnitId, courseId, unitId, footerText, quizAttempt, yourLessonsView]);

  useEffect(() => {
    const getResults = async () => {
      if (!quiz?.mostRecentAttempt?.quizAttemptId) return;

      const quizAttemptResult = await commands.execute(
        Data.Lms.Commands.GetQuizAttemptResult,
        quiz.mostRecentAttempt.quizAttemptId
      );

      try {
        if (quizAttemptResult.complete) {
          const quizReviewResult = await commands.execute(
            Data.Lms.Commands.GetQuizAttemptReview,
            quizAttemptResult?.quizId
          );
          setQuizReview(quizReviewResult);
        }
      } finally {
        setQuizAttempt(quizAttemptResult);
      }
    };

    if (quiz?.mostRecentAttempt?.quizAttemptId && typeof lesson?.complete !== 'undefined') {
      getResults();
    }
  }, [lesson?.complete, quiz?.mostRecentAttempt?.quizAttemptId]);

  const onRetakeQuiz = async () => {
    try {
      setLoading(true);
      resetQuiz(quiz?.quizId ?? 0);
      await commands.execute(Data.Lms.Commands.PostQuizAttempt, quiz);
      navigate(
        `${path}/${courseId}/${unitId}/${lessonId}/${quiz?.quizId}/${quiz?.questions?.[0].questionId}`
      );
    } finally {
      setLoading(false);
    }
  };
  const onReviewLesson = () => {
    navigate(`${path}/${courseId}/${unitId}/${lessonId}/content`);
  };

  return !quiz?.mostRecentAttempt ||
    !quizAttempt ||
    quizAttempt.passed === undefined ||
    !quiz?.questions ? (
    <Loading />
  ) : quizAttempt.passed && quizReview ? (
    <SuccessContent
      onRetake={onRetakeQuiz}
      onReview={onReviewLesson}
      quizAttempt={quizAttempt}
      quizReview={quizReview}
    />
  ) : (
    <FailureContent
      onRetake={onRetakeQuiz}
      onReview={onReviewLesson}
      quizAttempt={quizAttempt}
      questions={quiz.questions}
    />
  );
};

const meta = {} satisfies LayoutMeta<LmsLayoutMeta>;

export default withLayoutMeta(LmsQuizResultsView, meta);
