export class HexagonRotated {
  readonly coordinates: { x: number; y: number }[];
  readonly pointA: { x: number; y: number };
  readonly pointB: { x: number; y: number };
  readonly pointC: { x: number; y: number };
  readonly pointD: { x: number; y: number };
  readonly pointE: { x: number; y: number };
  readonly pointF: { x: number; y: number };

  readonly height: number;
  readonly width: number;

  constructor(public orignX: number, public orignY: number, public sideLen: number) {
    //https://www.quora.com/How-can-you-find-the-coordinates-in-a-hexagon
    // inverting Y axis to match screen coordinates
    this.width = Math.sqrt(3) * sideLen;
    this.height = sideLen * 2;

    this.pointA = { x: orignX + this.width / 2, y: orignY - sideLen / 2 };
    this.pointB = { x: orignX, y: orignY - sideLen };
    this.pointC = { x: orignX - this.width / 2, y: orignY - sideLen / 2 };
    this.pointD = { x: orignX - this.width / 2, y: orignY + sideLen / 2 };
    this.pointE = { x: orignX, y: orignY + sideLen };
    this.pointF = { x: orignX + this.width / 2, y: orignY + sideLen / 2 };

    this.coordinates = [
      this.pointA,
      this.pointB,
      this.pointC,
      this.pointD,
      this.pointE,
      this.pointF,
    ];
  }

  toString(): string {
    return this.coordinates.map((i) => `${i.x},${i.y}`).join(' ');
  }
}
