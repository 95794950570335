import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function MenuHelpSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 25 25' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h25v25H0z' />
        <path
          d='M12.5 2.5c-5.522 0-10 4.48-10 10 0 5.524 4.478 10 10 10s10-4.476 10-10c0-5.52-4.478-10-10-10zm0 18.065A8.06 8.06 0 0 1 4.435 12.5 8.062 8.062 0 0 1 12.5 4.435a8.062 8.062 0 0 1 8.065 8.065 8.06 8.06 0 0 1-8.065 8.065zm4.324-10.29c0 2.703-2.92 2.745-2.92 3.744v.255a.484.484 0 0 1-.484.484h-1.84a.484.484 0 0 1-.484-.484v-.349c0-1.441 1.093-2.017 1.918-2.48.708-.397 1.142-.667 1.142-1.193 0-.696-.887-1.157-1.604-1.157-.935 0-1.366.442-1.973 1.208a.484.484 0 0 1-.672.086l-1.122-.85a.485.485 0 0 1-.107-.66c.953-1.4 2.166-2.185 4.056-2.185 1.978 0 4.09 1.544 4.09 3.58zm-2.63 6.741c0 .934-.76 1.694-1.694 1.694-.934 0-1.694-.76-1.694-1.694 0-.934.76-1.693 1.694-1.693.934 0 1.694.76 1.694 1.693z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </SvgIcon>
  );
}

MenuHelpSvg.defaultProps = {
  fill: '#fff',
};
