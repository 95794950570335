import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const StepOne: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={1}
      headerIcon={<Svgs.ArtQuestion />}
      headerText='DecideWhyYoureRefinancing'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('ItIsImportantToKnowYourReasonForRefinancing')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('HereAreAFewCommonReasonsYouMightBeRefinancing')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('YourMonthlyMortgagePaymentIsTooHigh'),
              t('YouHaveEquityInYourHome'),
              t('YouWantToPayOffYourMortgageMoreQuickly'),
              t('YoudLikeAMorePredictableInterestPayment'),
            ]}
          />
        </Stack>
      }
    />
  );
};

export default StepOne;
