import { FC } from 'react';
import { Colors } from '../../themes';
import { ButtonProps } from '../../components/buttons/Button';
import React from 'react';
import StatusCard, { LabelProps } from '../../components/StatusCard';

declare type OnboardingCategory = 'PersonalDetails' | 'BudgetDetails' | 'AccountDetails';

type OnboardingSummaryCardProps = {
  label: OnboardingCategory;
  subLabel?: LabelProps;
  complete: boolean;
  disabled: boolean;
  icon: React.ReactNode;
  onClick?: () => void;
};

type OnboardingSummaryCardButtonProps = {
  category: OnboardingCategory;
  complete: boolean;
  disabled: boolean;
  onClick?: () => void;
};

export const OnboardingSummaryCardButton = ({
  complete,
  disabled,
  onClick,
}: OnboardingSummaryCardButtonProps): ButtonProps => {
  let buttonProps: Partial<ButtonProps> = {};
  if (complete) {
    buttonProps = {
      ...buttonProps,
      label: 'Edit',
      color: 'primary',
      variant: 'outlined',
    };
  } else {
    buttonProps = {
      ...buttonProps,
      label: 'Start',
      color: 'primary',
      variant: 'contained',
    };
  }

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return {
    ...{ ...buttonProps },
    fullWidth: true,
    disabled: disabled,
    onClick: clickHandler,
  } satisfies ButtonProps;
};

export const OnboardingSummaryCard: FC<OnboardingSummaryCardProps> = ({
  complete,
  disabled = false,
  icon,
  label,
  subLabel,
  onClick,
}) => (
  <StatusCard
    label={label}
    icon={icon}
    subLabel={{
      label: subLabel ? subLabel.label : complete ? 'Complete' : 'Incomplete',
      color: subLabel ? subLabel.color : complete ? Colors.primaryBase : Colors.accentBase,
    }}
    endAdornmentVariant='Button'
    disabled={disabled}
    containerProps={{ raised: !disabled }}
    endAdornmentProps={{
      ...OnboardingSummaryCardButton({
        category: label,
        complete: complete,
        disabled: disabled,
        onClick: onClick,
      }),
    }}
  />
);
