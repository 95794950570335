import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MutationStatus, useMutation, useObservableQuery } from '@aesop-fables/scrinium';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import LmsQuizView from './LmsQuizView';
import { Loading } from '../../../hooks/useLoading';

const QUIZ_REFRESH_KEY = 'quiz-refreshed';

const LmsQuizViewWrapper = () => {
  const navigate = useNavigate();
  const commands = Hooks.useCommands();
  const { lessonId, courseId, unitId, quizId } = useParams();
  const { resetQuiz } = Hooks.useQuizContext();

  const quiz = useObservableQuery(Data.Lms.Queries.FindQuizByLessonId, parseInt(lessonId ?? '0'));

  const createAttemptMutation = useMutation(async (quiz: Domain.QuizDto) => {
    resetQuiz(quiz.quizId ?? 0);
    const newAttempt = await commands.execute(Data.Lms.Commands.PostQuizAttempt, quiz);
    const firstQuestionId = newAttempt.questionOrder?.[0]?.questionId;

    if (firstQuestionId) {
      navigate(`/learning/${courseId}/${unitId}/${lessonId}/${quizId}/${firstQuestionId}`, {
        replace: true,
      });
    }
  });

  useEffect(() => {
    if (!quiz) return;

    const handleUnload = () => {
      sessionStorage.setItem(QUIZ_REFRESH_KEY, 'true');
    };

    const handleRefresh = async () => {
      const wasRefreshed = sessionStorage.getItem(QUIZ_REFRESH_KEY) === 'true';

      if (wasRefreshed && quiz.quizId) {
        sessionStorage.removeItem(QUIZ_REFRESH_KEY);
        await createAttemptMutation.action(quiz);
      }
    };

    window.addEventListener('beforeunload', handleUnload);
    handleRefresh();

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [quiz, courseId, unitId, lessonId, quizId, navigate, createAttemptMutation.action, resetQuiz]);

  const isLoading = !quiz || createAttemptMutation.status === MutationStatus.Executing;

  if (isLoading) return <Loading />;

  return <LmsQuizView />;
};

export default LmsQuizViewWrapper;
