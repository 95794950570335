import { t } from 'i18next';
import BulletedText from '../../../../components/BulletedText';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { Margins } from '../../../../themes';
import { Stack } from '@mui/material';

export const CostofLivingModal: React.FC = () => {
  return (
    <HelpPopover title={t('CostofLiving')}>
      <HelpPopoverText style={{ display: 'inline', ...Margins.mb_sm }}>
        The cost of living for each state is compared using the
      </HelpPopoverText>
      <HelpPopoverText style={{ fontWeight: 'bold', display: 'inline' }}>
        {' '}
        cost of living index,{' '}
      </HelpPopoverText>
      <HelpPopoverText style={{ ...Margins.mb_sm }}>
        where 100 means the average cost of living in the U.S.
      </HelpPopoverText>
      <Stack mr={2} ml={2}>
        <BulletedText>
          Any state with a higher index than 100 has a higher-than-average living cost.
        </BulletedText>
        <BulletedText>
          Any state with a lower index than 100 has a lower-than-average living cost.
        </BulletedText>
      </Stack>
    </HelpPopover>
  );
};
