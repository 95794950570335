import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, InputLabel, Typography, Stack, Slider } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../themes/ThemeWrapper';
import TextInput, { TextInputProps } from './TextInput';
import { useFormContext } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';

export type SliderPercentProps<T> = TextInputProps<T> & { step?: number; initialMax?: number };

export default function SliderPercent<T>(props: SliderPercentProps<T>) {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();
  const [percent, setPercent] = useState<number | undefined>(undefined);
  const [currentMax, setCurrentMax] = useState<number | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>();
  const type = useMemo(() => props.type ?? 'percent', [props.type]);

  const {
    label,
    step,
    max = type === 'percent' ? 100 : 99999999,
    initialMax,
    ...textProps
  } = props;
  const text = label ? t(label) : '';

  const incrementMaximum = useCallback(
    (cleanedValue: number) => {
      const increment = cleanedValue / 2;
      const newMax = cleanedValue + increment;
      if (typeof max === 'number') {
        if (newMax <= max) {
          setCurrentMax(newMax);
        } else {
          setCurrentMax(max);
        }
      }
    },
    [currentMax]
  );

  useEffect(() => {
    const fieldValue = getValues(props.name.toString());
    if (fieldValue) {
      setPercent(cleanWholeNumberStr(fieldValue.toString(), { float: true }));
    } else if (props.defaultValue) {
      setPercent(props.defaultValue as number);
    } else {
      setPercent(0);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (initialMax ?? max) {
      setCurrentMax(initialMax ?? max);
    }
  }, [initialMax, max]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === props.name) {
        const newVal = values[name];
        const cleanedValue = cleanWholeNumberStr(newVal, { float: true });

        if (typeof currentMax === 'number' && typeof initialMax !== 'undefined') {
          if (cleanedValue > currentMax) {
            incrementMaximum(cleanedValue);
          }
        }

        setPercent(cleanedValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, props.name, currentMax]);

  if (typeof percent === typeof undefined) return null;

  return (
    <Grid display='flex' flexDirection='column'>
      <Grid item flexDirection='row' columnGap='5px' alignItems='center' display='flex' mb='6px'>
        <InputLabel
          shrink={false}
          disableAnimation
          required={props.required}
          htmlFor={props.name as string}
          error={props.error}>
          <Typography variant='p16Bold' color='primary'>
            {text}
          </Typography>
        </InputLabel>
        {props.helpContext && (
          <Grid item display='flex' justifyContent='flex-end'>
            <Tippy placement='right' content={props.helpContext} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          </Grid>
        )}
      </Grid>
      <Stack spacing={2} direction='row' alignItems='center'>
        <Slider
          color='primary'
          value={percent}
          min={textProps.min}
          max={currentMax}
          onChange={(event: Event, newValue: number | number[]) => {
            setValue(textProps.name.toString(), newValue.toString(), {
              shouldDirty: true,
            });
          }}
          onChangeCommitted={(e, value) => {
            if (
              typeof value === 'number' &&
              typeof currentMax === 'number' &&
              typeof initialMax !== 'undefined'
            ) {
              if (value === currentMax) {
                incrementMaximum(value);
              }
            }
          }}
          step={step}
        />
        <TextInput
          {...textProps}
          inputRef={inputRef}
          max={max}
          helpContext={undefined}
          id={textProps.name?.toString()}
          className='slider-input'
          type={type}
        />
      </Stack>
    </Grid>
  );
}
