import { type SvgIconTypeMap } from '@mui/material';
import { type OverridableComponent } from '@mui/material/OverridableComponent';
import Images from '../components/images/index';

function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key];
}

const IconSolver = (
  icon?: string
): OverridableComponent<SvgIconTypeMap<Record<string, unknown>>> => {
  type imagesKeyType = keyof typeof Images;
  if (icon === undefined) {
    return Images.HomeSvg;
  }

  return getProperty(Images, icon as imagesKeyType) as OverridableComponent<
    SvgIconTypeMap<Record<string, unknown>>
  >;
};

export default {
  getProperty,
  IconSolver,
};
