import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../components/form/TextInput';
import { Data } from '@3nickels/data-modules';
import { Spacing } from '../../../themes';
import { useFormContext } from 'react-hook-form';

type ContactInfoFormProps = {
  contactInfo?: Data.People.ContactInfoFormData;
};

const ContactInfoForm: React.FC<ContactInfoFormProps> = (props) => {
  const { formState } = useFormContext();

  return (
    <Box component='div' style={{ width: '100%' }}>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.People.ContactInfoFormData>
          error={formState.errors.mobileNumber !== undefined}
          helperText={formState.errors.mobileNumber?.message?.toString()}
          name='mobileNumber'
          defaultValue={props.contactInfo?.mobileNumber}
          label='MobilePhone'
          type='tel'
          placeholder='(###) ###-####'
          autoFocus
        />
        <TextInput<Data.People.ContactInfoFormData>
          error={formState.errors.email !== undefined}
          helperText={formState.errors.email?.message?.toString()}
          name='email'
          defaultValue={props.contactInfo?.email}
          label='EmailAddress'
          type='email'
          placeholder='name@email.com'
        />
      </Stack>
    </Box>
  );
};

export default ContactInfoForm;
