import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { StatusLabel } from '../../../../goals/GoalHeader';
import { GoalCardProps } from '../Types';

const GoalCardHeader: React.FC<GoalCardProps & { hideDate?: boolean }> = ({ goal, hideDate }) => {
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;

  return (
    <Grid item display='flex' flexDirection='column' gap='5px' width='100%'>
      <Grid container>
        <Typography
          variant='p16'
          color='secondary'
          style={{
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
          {goalPlan.goalName}
        </Typography>
      </Grid>
      <Grid container gap='10px'>
        {!hideDate && (
          <Typography variant='p12' color='secondary' fontWeight='normal'>
            {dayjs(advisedGoalPlan.goalDate).format('MMM YYYY')}
          </Typography>
        )}
        {!hideDate && (
          <Typography variant='p12' color='secondary' fontWeight='normal'>
            |
          </Typography>
        )}
        <StatusLabel goalStatus={goalStatus} variant='p12' fontWeight='normal' />
      </Grid>
    </Grid>
  );
};

export default GoalCardHeader;
