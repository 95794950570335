import { TableContainer, Table, TableBody, Grid, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonCharityResults: React.FC = () => {
  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody className='search-results-table-body'>
          <SkeletonCharity />
          <SkeletonCharity />
          <SkeletonCharity />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SkeletonCharity: React.FC = () => {
  return (
    <Grid className='search-results-row'>
      <Grid className='charity-results'>
        <Skeleton className='charity-name' variant='rectangular' height='25px' />
        <Skeleton className='category' variant='rectangular' height='25px' />
        <Skeleton className='location' variant='rectangular' height='25px' />
      </Grid>
    </Grid>
  );
};

export default SkeletonCharityResults;
