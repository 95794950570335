import React from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import MobileHomeView from './MobileHomeView';
import MobileUniversityUpsellView from './MobileUniversityUpsellView';
import MobileAdviceUpsellView from './MobileAdviceUpsellView';

interface MobileRedirectViewProps {
  stripeRedirect?: boolean;
}

const MobileRedirectView: React.FC<MobileRedirectViewProps> = ({ stripeRedirect }) => {
  const productType = Hooks.useProductType();
  const universityEnabled = Hooks.useUniversityEnabled();
  const { sortedProducts, orgOnlyOffersFreePricebook } = Hooks.useAvailableProducts();

  const adviceActive = productType === Domain.ProductTypeEnum.advice;
  const universityAvailable = sortedProducts?.find((p) => p.pricebook.productType === 'NICKELSU');

  if (adviceActive || orgOnlyOffersFreePricebook) {
    return <MobileHomeView stripeRedirect={stripeRedirect} />;
  } else if (universityAvailable && !universityEnabled) {
    return <MobileUniversityUpsellView />;
  } else {
    return <MobileAdviceUpsellView />;
  }
};

export default MobileRedirectView;
