import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import BulletedList from '../../../../components/BulletedList';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={2}
      headerIcon={<Svgs.ArtDashboardChecklist height='100px' width='auto' />}
      headerText='GetPreQualified'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('GettingPreQualifiedForALoan')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('ReasonsYouMightNotPreQualifyInclude')}
          </Typography>
          <BulletedList
            color='secondary'
            style={{ marginTop: '6px' }}
            children={[
              t('NotEnoughIncome'),
              t('MinimalWorkHistory'),
              t('HighDebtToIncomeRatio'),
              t('TooManyCreditInquiries'),
            ]}
          />
        </Stack>
      }
    />
  );
};

export default StepTwo;
