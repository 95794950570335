import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ChecklistTemplate from '../../ChecklistTemplate';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';

const StepFour: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={<Svgs.ArtDashboardChecklist height='107px' width='auto' />}
      headerText='FinalDetails'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('CongratulationsYoureAlmostThereAtThisPoint')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('AllThatsLeft')}
          </Typography>
          <Typography color='secondary' variant='p16Bold'>
            {t('AndThatsAllThereIsToIt')}
          </Typography>
        </Stack>
      }
    />
  );
};

export default StepFour;
