import { MenuItem, Grid, Card, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import FormContent from '../../../../components/form/FormContent';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Button } from '../../../../components/buttons/Button';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { Spacer } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';

export type CollisionInsuranceFormProps = {
  results: Domain.CollisionInsuranceResultData | undefined;
  cars: Domain.CarData[];
  formMethods: UseFormReturn;
  collisionInsuranceFormData?: Domain.CollisionInsuranceFormData;
  resetResults: () => void;
  onSubmit: (values: Domain.CollisionInsuranceFormData) => void;
};

const CollisionInsuranceForm: React.FC<CollisionInsuranceFormProps> = ({
  results,
  cars,
  formMethods,
  collisionInsuranceFormData,
  resetResults,
  onSubmit,
}) => {
  const { setLoading } = useLoading();
  const { formState, watch, handleSubmit, setValue } = formMethods;
  const [selectedCar, setSelectedCar] = useState<Domain.CarData | undefined>();
  const commands = Hooks.useCommands();

  useEffect(() => {
    const index = cars.findIndex((car) => car.id === collisionInsuranceFormData?.carId);
    if (index !== -1) {
      setValue('carId', cars[index].id);
    } else if (cars.length === 1) {
      setValue('carId', cars[0].id);
    }
  }, [cars, collisionInsuranceFormData?.carId]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'carId') {
        const id = values.carId;
        const car = cars.find((x) => x.id === id);
        if (typeof car !== 'undefined' && car.id !== selectedCar?.id) {
          setValue('carValue', car.estimatedValue);
          setSelectedCar(car);
          resetResults();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, cars, selectedCar]);

  const saveCarValueOnSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) => {
      try {
        setLoading(true);
        const carValue = cleanWholeNumberStr(values.carValue ?? 0);
        if (carValue !== selectedCar?.estimatedValue) {
          const updatedCar = await commands.execute(Data.Cars.Commands.AddOrUpdateCarData, {
            ...selectedCar,
            estimatedValue: carValue,
          });
          setSelectedCar(updatedCar);
        }
      } finally {
        onSubmit(values);
        setLoading(false);
      }
    },
    [selectedCar]
  );

  if (cars.length === 0) return <NoCarsAdded />;

  return (
    <FormContent formProviderProps={formMethods}>
      <form
        style={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}
        onSubmit={handleSubmit(saveCarValueOnSubmit)}>
        <Grid className='tool-form-container'>
          <SelectInput<Domain.CollisionInsuranceFormData>
            error={formState.errors.carId !== undefined}
            helperText={formState.errors.carId?.message?.toString()}
            defaultValue={collisionInsuranceFormData?.carId}
            name='carId'
            label='CarName'
            placeholder='ChooseCar'>
            {cars.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </SelectInput>
          <SliderPercent<Domain.CollisionInsuranceFormData>
            error={formState.errors.carValue !== undefined}
            helperText={formState.errors.carValue?.message?.toString()}
            defaultValue={collisionInsuranceFormData?.carValue}
            name='carValue'
            label='ValueOfCar'
            type='dollar'
            min={0}
            initialMax={100000}
            step={1000}
          />
          <SliderPercent<Domain.CollisionInsuranceFormData>
            error={formState.errors.collisionDeductible !== undefined}
            helperText={formState.errors.collisionDeductible?.message?.toString()}
            defaultValue={collisionInsuranceFormData?.collisionDeductible}
            name='collisionDeductible'
            label='CollisionDeductible'
            type='dollar'
            min={0}
            initialMax={1000}
            step={50}
          />
          <SliderPercent<Domain.CollisionInsuranceFormData>
            error={formState.errors.sixMonthInsurancePremium !== undefined}
            helperText={formState.errors.sixMonthInsurancePremium?.message?.toString()}
            defaultValue={collisionInsuranceFormData?.sixMonthInsurancePremium}
            name='sixMonthInsurancePremium'
            label='SixMonthCollisionPremium'
            type='dollar'
            min={0}
            initialMax={1000}
            step={50}
          />
        </Grid>
        <Button
          type='submit'
          style={{ width: '45%', alignSelf: 'end' }}
          label={results ? 'Recalculate' : 'Calculate'}
        />
      </form>
    </FormContent>
  );
};

const NoCarsAdded: React.FC = () => {
  const navigate = useNavigate();

  const navToAccounts = () => navigate('/account-details');

  return (
    <Card className='tools-no-items-added-box'>
      <Spacer height='xxs' />
      <Svgs.ArtCar2 />
      <Typography variant='p16' color='error'>
        {t('PleaseAddACarBeforeUsing')}
      </Typography>
      <Button label='GoToAccounts' onClick={navToAccounts} />
    </Card>
  );
};

export default CollisionInsuranceForm;
