import { Button, Card, Divider, FormGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logo3NSmall } from '../assets/png';
import { Column, PasswordRequirements, Row, Spacer } from '../components';
import { ProfileData } from '../models/ProfileData';
import { useState } from 'react';
import { SignUpLoginWizard, signUpLoginWizardKey } from '../services/signUpLogin';
import dayjs from 'dayjs';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { AxiosError } from 'axios';
import { useService } from '@aesop-fables/containr-react';
import { Api, formatDateForApi, Hooks } from '@3nickels/data-modules';
import AppleLogin from '../login/AppleLogin';
import GoogleLogin from '../login/GoogleLogin';
import { useMessage } from '../hooks/useMessage';
import * as Yup from 'yup';
import TextInput from '../components/form/TextInput';
import FormContent from '../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatMalformedDate, isValidDate } from '../helpers/utilityFunctions';

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]).{8,32}$/;

const schema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthdate: Yup.string()
    .required('Required')
    .test('validDate', 'Must be a valid date', (birthdate) => {
      return isValidDate(birthdate);
    })
    .test('birthdate', 'Must be 12 years old or older', (birthdate) => {
      const date = formatMalformedDate(birthdate);
      return dayjs().subtract(12, 'y').isSameOrAfter(dayjs(date), 'day');
    })
    .test('birthdate', 'Must be 109 years old or younger', (birthdate) => {
      const date = formatMalformedDate(birthdate);
      return dayjs().subtract(109, 'y').isSameOrBefore(dayjs(date), 'day');
    }),
  emailAddress: Yup.string()
    .required('Required')
    .test('valid-email', 'Invalid Email', (value) => {
      return /\S+@\S+\.\S+/.test(value);
    }),
  password: Yup.string()
    .required('Required')
    .min(8, 'Please follow the password requirements below')
    .max(32, 'Please follow the password requirements below')
    .matches(/[0-9]/, 'Please follow the password requirements below')
    .matches(/[A-Z]/, 'Please follow the password requirements below')
    .matches(/[a-z]/, 'Please follow the password requirements below')
    .matches(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/, 'Please follow the password requirements below')
    .test('password-requirements', 'Please follow the password requirements below', (value) => {
      return passwordRegex.test(value);
    }),
  rePassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password does not match'),
});

export interface PasswordConfirmData {
  password?: string;
  rePassword?: string;
}
const CreateAccount = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { showMessage } = useMessage();

  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasANumber, setHasANumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const updatePasswordRequirements = (passwordInput: string) => {
    setHasMinChar(passwordInput.length >= 8 && passwordInput.length <= 32);
    setHasANumber(/[0-9]/.test(passwordInput));
    setHasUpperCase(/[A-Z]/.test(passwordInput));
    setHasLowerCase(/[a-z]/.test(passwordInput));
    setHasSpecialChar(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/.test(passwordInput));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    updatePasswordRequirements(newPassword);
  };

  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);

  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);
  const organizationData = Hooks.useOrganizationData();

  const methods = useForm<ProfileData>({
    resolver: yupResolver(schema),
  });

  const navBack = () => {
    navigate(-1);
  };

  const onSubmit = async (values: ProfileData) => {
    const { firstName, lastName, emailAddress, password } = values;
    try {
      await signUpLoginWizard.setAccountInfoAndAttemptSignUp(
        firstName ?? '',
        lastName ?? '',
        formatDateForApi(values.birthdate ?? ''),
        emailAddress ?? '',
        password ?? ''
      );
      navigate('/secure-account');
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.status === 400) {
        showMessage('Invalid email: If account exists, please try logging in', 'error');
      }
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
            {organizationData ? (
              organizationData.logoUri ? (
                <img
                  src={organizationApi.getLogo()}
                  alt={organizationData?.name}
                  style={{ height: '60%' }}
                />
              ) : (
                <Typography variant='p25Bold' color='secondary'>
                  {organizationData?.name}
                </Typography>
              )
            ) : (
              <Card variant='ghost' />
            )}
            <img src={logo3NSmall} alt='logo' style={{ height: '70%' }} />
          </Row>

          <Typography variant='p30Bold' color='primary'>
            Create Your Account
          </Typography>
          <Spacer height='xs' />
          <Typography variant='h2' color='secondary'>
            Let’s set up your 3Nickels account.
          </Typography>
          <Spacer height='xs' />
          {isMobile || isTablet ? (
            <>
              <Column style={{ justifyContent: 'center' }}>
                <AppleLogin />
                <Spacer height='xs' />
                <GoogleLogin />
              </Column>
              <Spacer height='xs' />
              <Divider color='secondary' flexItem>
                <Typography variant='p18Bold' color='secondary'>
                  OR
                </Typography>
              </Divider>
              <Spacer height='xs' />
              <FormGroup
                style={{
                  alignSelf: 'center',
                  width: isMobile || isTablet ? '90%' : '70%',
                  maxWidth: isMobile ? '' : '650px',
                }}>
                <TextInput<ProfileData>
                  error={methods.formState.errors.firstName !== undefined}
                  helperText={methods.formState.errors.firstName?.message?.toString()}
                  inputProps={{ maxLength: 32 }}
                  placeholder='First Name'
                  label='FirstName'
                  name='firstName'
                  autoFocus
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.lastName !== undefined}
                  helperText={methods.formState.errors.lastName?.message?.toString()}
                  inputProps={{ maxLength: 32 }}
                  placeholder='Last Name'
                  label='LastName'
                  name='lastName'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.birthdate !== undefined}
                  helperText={methods.formState.errors.birthdate?.message?.toString()}
                  helpContext={<HelpText />}
                  label='DateOfBirth'
                  name='birthdate'
                  type='dateInput'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.emailAddress !== undefined}
                  helperText={methods.formState.errors.emailAddress?.message?.toString()}
                  placeholder='Email Address'
                  label='EmailAddress'
                  name='emailAddress'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.password !== undefined}
                  helperText={methods.formState.errors.password?.message?.toString()}
                  onChange={handlePasswordChange}
                  inputProps={{ width: '100%' }}
                  placeholder='Enter New Password'
                  label='EnterNewPassword'
                  name='password'
                  type='password'
                />
                <Spacer height='sm' />
                <PasswordRequirements
                  hasMinChar={hasMinChar}
                  hasSpecialChar={hasSpecialChar}
                  hasANumber={hasANumber}
                  hasUpperCase={hasUpperCase}
                  hasLowerCase={hasLowerCase}
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.rePassword !== undefined}
                  helperText={methods.formState.errors.rePassword?.message?.toString()}
                  inputProps={{ width: '100%' }}
                  placeholder='Re-enter Password'
                  label='ReEnterNewPassword'
                  name='rePassword'
                  type='password'
                />
              </FormGroup>
              <Spacer height='lg' />
              <Column style={{ justifyContent: 'space-between' }}>
                <Button type='submit' color='secondary'>
                  Next: Secure Your Account
                </Button>
                <Spacer height='xs' />
                <Button onClick={navBack} variant='outlined' color='secondary'>
                  Cancel
                </Button>
              </Column>
            </>
          ) : (
            <>
              <Row>
                <FormGroup
                  style={{
                    alignSelf: 'center',
                    width: isMobile || isTablet ? '90%' : '70%',
                    maxWidth: isMobile ? '' : '650px',
                  }}>
                  <Row style={{ width: '100%' }}>
                    <Column style={{ width: '100%' }}>
                      <TextInput<ProfileData>
                        error={methods.formState.errors.firstName !== undefined}
                        helperText={methods.formState.errors.firstName?.message?.toString()}
                        inputProps={{ maxLength: 32, width: '100%' }}
                        placeholder='First Name'
                        label='FirstName'
                        name='firstName'
                        autoFocus
                      />
                    </Column>
                    <Spacer height='sm' />
                    <Column style={{ marginLeft: '15px', width: '100%' }}>
                      <TextInput<ProfileData>
                        error={methods.formState.errors.lastName !== undefined}
                        helperText={methods.formState.errors.lastName?.message?.toString()}
                        inputProps={{ maxLength: 32, width: '100%' }}
                        placeholder='Last Name'
                        label='LastName'
                        name='lastName'
                      />
                    </Column>
                  </Row>
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.birthdate !== undefined}
                    helperText={methods.formState.errors.birthdate?.message?.toString()}
                    helpContext={<HelpText />}
                    label='DateOfBirth'
                    name='birthdate'
                    type='dateInput'
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.emailAddress !== undefined}
                    helperText={methods.formState.errors.emailAddress?.message?.toString()}
                    placeholder='Email Address'
                    label='EmailAddress'
                    name='emailAddress'
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.password !== undefined}
                    helperText={methods.formState.errors.password?.message?.toString()}
                    onChange={handlePasswordChange}
                    inputProps={{ width: '100%' }}
                    placeholder='Enter New Password'
                    label='EnterNewPassword'
                    name='password'
                    type='password'
                  />
                  <Spacer height='sm' />
                  <PasswordRequirements
                    hasMinChar={hasMinChar}
                    hasSpecialChar={hasSpecialChar}
                    hasANumber={hasANumber}
                    hasUpperCase={hasUpperCase}
                    hasLowerCase={hasLowerCase}
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.rePassword !== undefined}
                    helperText={methods.formState.errors.rePassword?.message?.toString()}
                    inputProps={{ width: '100%' }}
                    placeholder='Re-enter Password'
                    label='ReEnterNewPassword'
                    name='rePassword'
                    type='password'
                  />
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button onClick={navBack} variant='outlined' color='secondary'>
                      Cancel
                    </Button>
                    <Button type='submit' color='secondary'>
                      Next: Secure Your Account
                    </Button>
                  </Row>
                </FormGroup>
                <Divider
                  style={{ margin: '0px 75px' }}
                  color='secondary'
                  orientation='vertical'
                  flexItem>
                  <Typography variant='p18Bold' color='secondary'>
                    OR
                  </Typography>
                </Divider>
                <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='p18Bold' color='secondary'>
                    Sign up another way
                  </Typography>
                  <Spacer height='sm' />
                  <AppleLogin />
                  <Spacer height='xs' />
                  <GoogleLogin />
                </Column>
              </Row>
            </>
          )}
          <Spacer height='lg' />
        </Column>
      </form>
    </FormContent>
  );
};

export const HelpText = () => {
  return (
    <Column style={{ padding: '20px' }}>
      <Typography variant='p16Bold' color='secondary'>
        Date of Birth
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Used to optimize savings and withdrawals</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Certain ages, like 59 1/2 and 70 1/2, are important for tax reasons.</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        Sounds bizarre? Well, thank our complicated tax laws.
      </Typography>
    </Column>
  );
};

export default CreateAccount;
