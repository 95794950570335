/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Data, Domain, Hooks, ObservableGuard, formatWholeDollars } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta, LeaveGoalDetailsModal } from '../../GoalsLayout';
import EmbeddedList from '../../../../components/EmbeddedList';
import { Svgs } from '../../../../assets/svg';
import { Button } from '../../../../components/buttons/Button';
import { Spacing } from '../../../../themes';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { firstValueFrom, map } from 'rxjs';
import { Loading } from '../../../../hooks/useLoading';
import { FormLoader } from '../../../../components/FormLoader';

export const MortgageGoalViewWrapper: React.FC = () => {
  const { loading, currentStep, wizard } = Hooks.useMortgageGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({});
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <MortgageGoalView currentStep={currentStep} wizard={wizard} params={params} />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface MortgageGoalViewProps {
  currentStep: WizardStep<Data.Goals.MortgageGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.MortgageGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const MortgageGoalView: React.FC<MortgageGoalViewProps> = ({ wizard, currentStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const houses = Hooks.useGoalEligibleHousing();
  const housingData = Hooks.useCombinedHousingData();
  const [open, setOpen] = useState(false);

  const housingWithMortgage = housingData.filter((house) => house.hasMortgage);
  const eligibleHousingAlreadyHaveGoals = houses.length === 0 && housingWithMortgage.length > 0;

  const leaveGoalDetails = () => {
    navigate('/account-details');
  };

  const selectHouse = async (house: Domain.HousingData) => {
    currentStep.save({ mortgageId: house.debt?.id });
    await wizard.commitStep('basic');

    const wizardParams = await firstValueFrom(wizard.params$);
    await currentStep.resetState({ id: wizardParams?.id }); // force wizard to reload step so data is loaded on summary
    navigate(`${wizardParams?.id}`);
  };

  return (
    <Box>
      <LeaveGoalDetailsModal open={open} setOpen={setOpen} handleSave={leaveGoalDetails} />
      <Typography className='title' color='primary' display='inline'>
        {t('AddAGoal') + ': '}
        <strong>{t('PayOffMortgage')}</strong>
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('ChooseFromMortgages')}
      </Typography>
      <Grid container className='mortgage-select' justifyContent='center' mt={2}>
        <Grid container className={houses.length === 0 ? 'empty' : 'not-empty'} sm={10}>
          <EmbeddedList<Domain.HousingData>
            items={houses}
            keyFn={(x) => x.id?.toString() ?? ''}
            header='Saved Mortgages'
            emptyHeader={
              eligibleHousingAlreadyHaveGoals ? 'No Homes Left for Payoff Goal' : 'No Homes Added'
            }
            titleFn={(x) =>
              x.isLinked ? (
                <>
                  {x.name ?? ''}
                  <Svgs.LinkPaperClip height='20px' />
                </>
              ) : (
                x.name ?? ''
              )
            }
            summaryFn={(x) => formatWholeDollars(0 - (x.debt?.amountOwed ?? 0))}
            headingProps={{
              startAdornment: () => <Svgs.DisplaySmallCustomHouse />,
              endAdornment: () => <Svgs.ActionSmallEdit />,
              startClassName: 'start-header',
              endClassName: 'end-header',
            }}
            onClick={() => setOpen(true)}
            onClickElement={selectHouse}
          />
        </Grid>
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'House',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(MortgageGoalViewWrapper, meta);
