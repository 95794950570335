import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Drawer, Grid, Typography } from '@mui/material';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Spacing } from '../../themes';
import { Svgs } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import { WizardFooter } from '../../components/form/WizardFooter';
import { Check } from '@mui/icons-material';

interface SpendingSummaryTransactionsAccountsSelectorProps {
  disabled?: boolean;
}

const SpendingSummaryTransactionsAccountsSelector: React.FC<
  SpendingSummaryTransactionsAccountsSelectorProps
> = ({ disabled }) => {
  const { transactionsAccountIds } = Hooks.useTransactions();
  const [open, setOpen] = useState(false);

  const content = useMemo(() => {
    if (transactionsAccountIds.length === 0) return 'All Accounts';
    return `${transactionsAccountIds.length} ${
      transactionsAccountIds.length === 1 ? 'Account' : 'Accounts'
    }`;
  }, [transactionsAccountIds]);

  return (
    <>
      <AccountsDrawer open={open} onClose={() => setOpen(false)} />
      <Grid className='transactions-selector-panel' onClick={() => setOpen(true)}>
        <Svgs.ActionSmallBank />
        <Typography
          variant='p12'
          color='secondary'
          style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
          {content}
        </Typography>
      </Grid>
    </>
  );
};

interface AccountsDrawerProps {
  open: boolean;
  onClose: () => void;
}

const AccountsDrawer: React.FC<AccountsDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { transactionsAccountIds, setTransactionsAccountIds } = Hooks.useTransactions();
  const linkedAccounts = Hooks.useLinkedAccounts();
  const [selectedAccountIds, setSelectedAccountIds] = useState<Set<string>>(new Set());

  const content = useMemo(() => {
    if (selectedAccountIds.size === 0) return 'No accounts selected';
    return `${selectedAccountIds.size} ${
      selectedAccountIds.size === 1 ? 'account' : 'accounts'
    } selected`;
  }, [selectedAccountIds.size]);

  useEffect(() => {
    setSelectedAccountIds(new Set(transactionsAccountIds));
  }, [transactionsAccountIds, open]);

  const toggleAccount = (account: Domain.GenericAutoBindAccount) => {
    setSelectedAccountIds((previous) => {
      const set = new Set(previous);
      if (set.delete(account.aggAccountId.toString())) {
        return set;
      }
      set.add(account.aggAccountId.toString());
      return set;
    });
  };

  const selectAllAccounts = () => {
    setSelectedAccountIds((previous) => {
      if (previous.size === linkedAccounts.length) {
        return new Set();
      }

      return new Set(linkedAccounts.map((x) => x.aggAccountId.toString()));
    });
  };

  const saveAccounts = () => {
    setTransactionsAccountIds([...selectedAccountIds]);
    onClose();
  };

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Grid p={Spacing.xxs}>
        <Typography className='title' color='primary' component='h1' variant='h1'>
          {t('FilterByAccount')}
        </Typography>
        <Typography className='subtitle' color='secondary' variant='p16'>
          {content}
        </Typography>
        <Grid
          className='category-select-all'
          onClick={selectAllAccounts}
          mt={2}
          mb='10px'
          style={{ border: 'solid 1px transparent', width: '100%' }}>
          <Grid display='flex' columnGap='10px'>
            <Checkbox
              checked={selectedAccountIds.size === linkedAccounts.length}
              indeterminate={
                selectedAccountIds.size > 0 && selectedAccountIds.size < linkedAccounts.length
              }
            />
            <Typography variant='p14Bold' color='secondary'>
              All Accounts
            </Typography>
          </Grid>
        </Grid>
        <Grid container className='category-select-container'>
          {linkedAccounts
            .filter(
              (account) =>
                account.financialAccountTypes === Domain.FinancialAccountTypeEnum.CashAccount ||
                account.financialAccountTypes === Domain.FinancialAccountTypeEnum.Debt ||
                account.financialAccountTypes === Domain.FinancialAccountTypeEnum.CreditCard
            )
            .map((account) => {
              const selected = selectedAccountIds.has(account.aggAccountId.toString());
              return (
                <AccountCard account={account} toggleAccount={toggleAccount} selected={selected} />
              );
            })}
        </Grid>
        <WizardFooter color='primary' onBack={onClose} onDone={saveAccounts} nextLabel='Done' />
      </Grid>
    </Drawer>
  );
};

interface AccountCardProps {
  account: Domain.GenericAutoBindAccount;
  toggleAccount: (account: Domain.GenericAutoBindAccount) => void;
  selected?: boolean;
}

const AccountCard: React.FC<AccountCardProps> = ({ account, toggleAccount, selected }) => {
  return (
    <Grid
      className={selected ? 'category-select-selected' : 'category-select'}
      width='100%'
      onClick={() => toggleAccount(account)}>
      <Grid display='flex' columnGap='10px'>
        <Checkbox checked={selected} checkedIcon={<Check />} />
        <Typography variant='p14Bold'>{account.name}</Typography>
      </Grid>
    </Grid>
  );
};

export default SpendingSummaryTransactionsAccountsSelector;
