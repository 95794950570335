/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { firstValueFrom, map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import CollegeGoalBasicForm from './CollegeGoalBasicForm';
import { GoalFormTitle } from '../../../components/FormTitle';
import { useMessage } from '../../../hooks/useMessage';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  name: Yup.string().required('Required'),
  beneficiaryType: Yup.string().required('Required'),
});

export const CollegeGoalBasicEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useCollegeGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <CollegeGoalBasicEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface CollegeGoalBasicEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.CollegeGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CollegeGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const CollegeGoalBasicEditView: React.FC<CollegeGoalBasicEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Goals.CollegeGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const participantContext = Hooks.useParticipantContext();
  const [addDependentOpen, setAddDependentOpen] = React.useState<boolean>(false);

  const onSubmit = async (values: any) => {
    if (values.beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && !values.beneficiaryId) {
      showMessage(t('PleaseChooseADependent') as string, 'warning', 3000);
      return;
    }
    setLoading(true);

    let beneficiaryId = values.beneficiaryId ?? participantContext?.self.participantId;
    if (values.beneficiaryType === Domain.BeneficiaryTypeEnum.SPOUSE) {
      beneficiaryId = participantContext?.spouse?.participantId;
    }

    try {
      currentStep.save({
        ...values,
        beneficiaryId,
      });

      if (editing && onBack) {
        await wizard.commitStep('basic');
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      if (wizardParams?.id) {
        // set id in url so if user navigates back, we load the wizard with the set ID
        navigate(`/goals/college/basics/${wizardParams?.id}`, { replace: true });
        navigate(`/goals/college/details/${wizardParams?.id}`);
      } else {
        navigate(`/goals/college/details`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle type={Domain.GoalTypeEnum.College} />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={6} paddingX={5}>
                  <CollegeGoalBasicForm
                    model={currentStep.model}
                    onSubmit={onSubmit}
                    addDependentOpen={addDependentOpen}
                    setAddDependentOpen={setAddDependentOpen}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'College',
  headerVariant: 'hex',
  hexSteps: {
    steps: 2,
    currentStep: 1,
  },
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(CollegeGoalBasicEditViewWrapper, meta);
