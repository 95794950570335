import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { Spacing } from '../../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import { InvestmentAccountBasicsEditViewWrapper as HSABasicsEditView } from '../../InvestmentAccountBasicsEditView';
import HSABasicsCard from '../basics/HSABasicsCard';
import HSAContributionsCard from '../contributions/HSAContributionsCard';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { combineLatest } from 'rxjs';
import { Loading } from '../../../../../hooks/useLoading';
import { HSAContributionsEditViewWrapper as HSAContributionsEditView } from '../contributions/HSAContributionsEditView';
import InvestmentsCard from '../../investments/InvestmentsCard';
import { InvestmentAccountSummaryViewProps } from '../../brokerage-account/summary/BrokerageAccountSummaryView';
import { SummaryView } from '../../../../../components/SummaryHeader';
import { useMessage } from '../../../../../hooks/useMessage';
import { useSummaryOnDoneNavigation } from '../../../link-account/PlaidHooks';

declare type HSASummaryDrawerType =
  | keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry
  | 'contributions';

const HSASummaryViewWrapper: React.FC = () => {
  const { wizard } = Hooks.useInvestmentAccountWizard();
  const { id } = useParams();
  const isStarted = useObservable(wizard.isStarted$) ?? false;

  const investmentAccountId = parseInt(id ?? '');
  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: investmentAccountId, type: Domain.PlanTypeEnum.HSA });
    }
  }, [isStarted]);

  if (!isStarted) {
    return <Loading />;
  }

  return <HSASummaryView wizard={wizard} investmentAccountId={investmentAccountId} />;
};

const HSASummaryView: React.FC<InvestmentAccountSummaryViewProps> = ({
  investmentAccountId,
  wizard,
}) => {
  const location = useLocation();
  const data = Hooks.useHsaWizardData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const plaidWizard = Hooks.usePlaidWizard();
  const accounts = Hooks.useCombinedHsaData();
  const onDone = useSummaryOnDoneNavigation();

  const deleteMutation = useMutation(new Data.InvestmentAccounts.Mutations.DeleteHsa());
  const params = useObservable(wizard.params$);
  const navigate = useNavigate();
  const [currentKey, setCurrentKey] = useState<HSASummaryDrawerType | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const { showMessage } = useMessage();

  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.PlanData,
    [accounts, params]
  );

  useEffect(() => {
    if (data.investments?.length > 0) {
      let balance = 0;
      data.investments.forEach((i) => (balance += i.balance ?? 0));
      setCurrentBalance(balance);
    }
  }, [data]);

  const openDrawer = (key?: HSASummaryDrawerType) => {
    if (key) {
      setCurrentKey(key);
      wizard.selectStep(key as keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry);
    }
    setOpen(true);
  };

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.InvestmentAccount
    );
  }, [plaidWizard, account]);

  const linkInvestmentsHandler = () => {
    linkAccountHandler();
    navigate('/account-details/link-account/choose-institution', {
      state: { summaryUrl: location.pathname },
    });
  };

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('HSA Account removed!');
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onLinkAccount={linkAccountHandler}
      linkableAccount={account}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      onRemoveAccount={removeAccountHandler}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <HSABasicsCard
            accountBasics={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <HSAContributionsCard
            accountContributions={data.contributions}
            onEdit={() => openDrawer('contributions')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <InvestmentsCard
            investmentAccountId={investmentAccountId}
            onEdit={() => openDrawer('basic')}
            linkAccount={linkInvestmentsHandler}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        currentKey={currentKey}
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(
            undefined as unknown as keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry
          );
        }}
      />
      <WizardFooter nextLabel='Done' onDone={onDone} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  currentKey?: HSASummaryDrawerType;
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ currentKey, onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm currentKey={currentKey} onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  currentKey?: HSASummaryDrawerType;
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ currentKey, onBack }) => {
  const { wizard } = Hooks.useInvestmentAccountWizard();
  const [initialized] = useObservable(combineLatest([wizard.initialized$])) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  if (currentKey === 'basic') {
    return <HSABasicsEditView type={Domain.PlanTypeEnum.HSA} editing onBack={onBack} />;
  }

  if (currentKey === 'contributions') {
    return <HSAContributionsEditView editing onBack={onBack} />;
  }

  return null;
};

export interface HSASummaryHSAdProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(HSASummaryViewWrapper, meta);
