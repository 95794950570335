/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useLoading } from '../../../hooks/useLoading';
import { EditViewProps } from '../../EditViewUtils';
import { GoalsLayoutMeta, LeaveGoalModal } from '../GoalsLayout';
import { Colors, Spacing } from '../../../themes';
import CheckSelect from '../../../components/CheckSelect';
import { GoalFormTitle } from '../../../components/FormTitle';
import InfoCard from '../../../components/InfoCard';
import { Button } from '../../../components/buttons/Button';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { Svgs } from '../../../assets/svg';
import { Spacer } from '../../../components';
import HelpText from '../../../components/HelpText';

export const DebtSelectionEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, availableDebts, selectedDebts, goal } = Hooks.usePayOffDebtGoal();
  const highCostDebtRate = Hooks.useHighCostDebtRate();

  // useEffect(() => {
  //   if (!isAvailable) {
  //     // TODO -- Should we display a toast or something?
  //     navigate('/goals');
  //   }
  // }, [isAvailable])

  return (
    <FormLoader loading={loading}>
      <DebtSelectionEditView
        goal={goal}
        highCostDebtRate={highCostDebtRate}
        debts={availableDebts}
        selectedDebts={selectedDebts}
        editing={editing}
        onBack={onBack}
      />
    </FormLoader>
  );
};

interface DebtSelectionEditViewProps extends EditViewProps {
  goal?: Domain.HolisticGoalDisplay;
  highCostDebtRate?: string;
  debts: Domain.IDebt[];
  selectedDebts: number[];
}

const DebtSelectionEditView: React.FC<DebtSelectionEditViewProps> = ({
  debts,
  goal,
  highCostDebtRate,
  editing,
  onBack,
  selectedDebts,
}) => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [selected, setSelected] = useState<number[]>(selectedDebts);
  const [open, setOpen] = useState<boolean>(false);
  const methods = useForm<DebtSelectionEditViewProps>({
    defaultValues: { selectedDebts },
  });
  const goalId = useLoaderData();
  const editTitle = goalId !== null;

  const onSubmit = async () => {
    if (debts.length === 0) {
      if (onBack) {
        onBack();
      } else {
        navigate('/goals');
      }
      return;
    }

    setLoading(true);

    try {
      await commands.execute(Data.Goals.DebtPayOffSelection, {
        goalPlanId: goal?.id as number,
        selectedIds: selected,
      });

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelected(selectedDebts);
  }, [selectedDebts]);

  const handleLeaveGoal = () => {
    navigate('/account-details');
  };

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <LeaveGoalModal open={open} setOpen={setOpen} handleSave={handleLeaveGoal} />
          <Box>
            {/* Should be about 30px */}
            <GoalFormTitle editing={editTitle} type={Domain.GoalTypeEnum.Non_Mortgage_Debt} />
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={10}>
                <Box>
                  <Stack spacing={Spacing.xxs}>
                    {debts.length === 0 && (
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'>
                        <Card
                          className='empty-account-card'
                          style={{
                            maxWidth: '450px',
                            width: '100%',
                          }}>
                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            width='190px'
                            onClick={() => setOpen(true)}
                            style={{ cursor: 'pointer' }}>
                            <Svgs.DisplaySmallCustomDebt />
                            <Typography color='primary' fontWeight='bold'>
                              No Debts Added
                            </Typography>
                            <Svgs.IconEdit />
                          </Grid>
                        </Card>
                        <Typography variant='p14' color={Colors.accentBase} mt={3}>
                          You haven’t added any debts yet!
                        </Typography>
                      </Grid>
                    )}
                    {debts.length !== 0 && (
                      <Grid maxWidth='450px' alignSelf='center'>
                        <Grid className='dependent-select'>
                          <Grid
                            item
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems='center'
                            display='flex'
                            mb='6px'>
                            <HelpText
                              text={`Which debts would you like to focus on paying off?`}
                              textStyle={{ textDecoration: 'none' }}
                              content={<DebtHelpText highCostDebtRate={highCostDebtRate} />}
                            />
                          </Grid>
                          <CheckSelect<Domain.IDebt>
                            items={debts}
                            keyFn={(d) => d.id?.toString() ?? ''}
                            titleFn={(d) => d.name}
                            subTitleFn={(d) => `Interest Rate: ${d.interestRate?.toString()}%`}
                            valueFn={(d) => selected.indexOf(d.id ?? 0) !== -1}
                            onChange={(d, checked: boolean) => {
                              if (checked) {
                                setSelected((x) => [...x, d.id ?? 0]);
                              } else {
                                setSelected((x) => x.filter((x) => x !== d.id));
                              }
                            }}
                          />
                          {selected.length <= 0 && (
                            <Grid textAlign='center' mt={4}>
                              <Typography
                                variant='p16Bold'
                                color={Colors.accentBase}
                                justifyContent='center'>
                                No debts selected
                              </Typography>
                              <Typography
                                variant='p14'
                                mt={1.5}
                                color={Colors.accentBase}
                                justifyContent='center'>
                                We still assume that you’ll pay the minimum payment on each debt
                                every month.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Box display='flex' justifyContent='center' mt={4}>
                          <Button
                            color='primary'
                            variant='outlined'
                            onClick={() => navigate('/account-details')}>
                            View/Edit Debt
                          </Button>
                        </Box>
                        <Box display='flex' justifyContent='flex-start' mt={4}>
                          <Grid item sm={12}>
                            <InfoCard>
                              <Grid container ml={2}>
                                <Grid item sm={12}>
                                  <Typography color='secondary' display='inline-block'>
                                    <strong>Try to pay off any “high-cost” debt—</strong>&nbsp; we
                                    define that as anything with an interest rate higher than&nbsp;
                                    {cleanWholeNumberStr(highCostDebtRate ?? '')}%.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </InfoCard>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <WizardFooter
            disableBack={false}
            onBack={onBack}
            color='primary'
            nextLabel={goal?.id && debts.length > 0 ? 'Save' : 'Done'}
            // {...getWizardFooterProps(meta.nextLocaleKey, false)}
          />
        </form>
      </FormContent>
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save & Continue',
  title: 'Debt',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(DebtSelectionEditViewWrapper, meta);

const DebtHelpText = (props: any) => {
  return (
    <Grid padding={'20px'}>
      <Typography variant='p18Bold' color='secondary'>
        High-Interest Debt
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        We feel that debt with rates above {cleanWholeNumberStr(props.highCostDebtRate ?? '')}%
        grows too quickly not to pay down if possible. This threshold is not set in stone; we may
        update it from time to time as the inflation rate and interest rates change.
      </Typography>
      <Spacer height='xs' />
      <Typography variant='p16' color='secondary' display='inline-block'>
        <strong>Think about it like this:</strong> if, instead of paying off your debt, you keep the
        money in your savings account, you’ll be earning 1% interest (at the very best). And your
        high-interest debt is costing you much more than that.
      </Typography>
      <Spacer height='xxs' />
    </Grid>
  );
};
