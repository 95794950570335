import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { repertoire } from '../../../../assets/png';
import { Button } from '../../../../components/buttons/Button';

const LearnSection: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Learn')}
      </Typography>
      <Grid className='3NU-section'>
        <Typography
          className='clickable'
          variant='p12'
          color='primary'
          alignSelf='flex-end'
          onClick={() => navigate('/learning')}>
          {t('ViewCourses')}
        </Typography>
        <Grid mt={4} className='content-section' columnGap='10px' paddingBottom='55px'>
          <Grid
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifySelf='flex-start'
            width='30%'
            pb={1}>
            <img src={repertoire} width='80%' />
          </Grid>

          <Grid className='course-details' paddingX='10px'>
            <Typography variant='p18Bold' color='primary'>
              {t('BuildYourFinancialRepertoire')}
            </Typography>
            <Typography mt={1} variant='p16' color='secondary'>
              {t('ExploreASuiteOfLessons')}
            </Typography>
          </Grid>
          <Grid justifyContent='end'>
            <Button color='primary' onClick={() => navigate('/learning')}>
              {t('JumpIn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LearnSection;
