import React from 'react';
import { Carousel } from '../../../components/Carousel';
import SwipeableCarousel from '../../../components/SwipeableCarousel';
import { CarouselVariant, getCarouselItems } from './freeCarouselItems';

interface FreeCarouselProps {
  swipeable?: boolean;
  variant: CarouselVariant;
}

const DEFAULT_ITEM_WIDTH = 135;

export const FreeCarousel: React.FC<FreeCarouselProps> = ({ swipeable = false, variant }) => {
  const CarouselComponent = swipeable ? SwipeableCarousel : Carousel;
  const items = getCarouselItems(variant);

  return <CarouselComponent items={items} itemWidth={DEFAULT_ITEM_WIDTH} />;
};
