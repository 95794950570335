/* eslint-disable @typescript-eslint/no-empty-function */
import { Grid, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React, { useEffect, useMemo, useRef } from 'react';
import { Options, TypewriterClass } from 'typewriter-effect';
import TypewriterEffect from 'typewriter-effect/dist/core';
import { renderToString } from 'react-dom/server';

function reduceToString(children: React.ReactNode, initial = ''): string {
  return React.Children.toArray(children).reduce((accumulator: string, child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      return accumulator + child;
    } else if (React.isValidElement(child)) {
      // return reduceToString(child.props.children, accumulator);
      return accumulator + renderToString(child);
    }

    return accumulator;
  }, initial);
}

export type TypewriterProps = TypographyProps & {
  onInit?: (typewriter: TypewriterClass) => void;
  options?: Partial<Options>;
} & {
  onStart?: () => void;
  onComplete?: () => void;
};

const Typewriter = React.forwardRef<HTMLElement, TypewriterProps>((props, ref) => {
  const { children, onStart = () => {}, onComplete = () => {}, onInit, options, ...rest } = props;
  const content = useMemo(() => {
    return reduceToString(children);
  }, [children]);
  const typewriterRef = useRef<TypewriterClass | null>(null);

  useEffect(() => {
    typewriterRef.current = new TypewriterEffect('.typewriter', {
      delay: 50,
      ...options,
    });

    if (typewriterRef.current) {
      if (onInit) {
        onInit(typewriterRef.current);
      }
      typewriterRef.current
        .callFunction(onStart)
        .typeString(content)
        .start()
        .pauseFor(1000)
        .callFunction(onComplete);
    }

    return () => {
      if (typewriterRef.current) {
        typewriterRef.current.stop();
        typewriterRef.current = null;
      }
    };
  }, [typewriterRef.current]);

  return (
    <Typography {...rest} ref={ref}>
      <Grid className='typewriter' />
    </Typography>
  );
});

export default Typewriter;
