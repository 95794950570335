import React from 'react';
import { Button as MuiButton, type ButtonProps as MuiButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type ButtonProps = {
  label?: string;
} & MuiButtonProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const { t } = useTranslation();
  return (
    <MuiButton ref={ref} {...props}>
      {props.label ? t(props.label) : ''}
      {props.children}
    </MuiButton>
  );
});
