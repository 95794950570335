import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { Svgs } from '../../../../assets/svg';
import ChecklistTemplate from '../../ChecklistTemplate';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ChecklistTemplate
      stepNumber={2}
      headerText='DecideHowYoullPay'
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16Bold'>
            {t('HowMuchWillINeed')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('TotalCarExpensesShouldComprise')}
          </Typography>
          <Typography color='secondary' variant='p16Bold'>
            {t('NewVsUsed')}
          </Typography>
          <Typography color='secondary' variant='p16'>
            {t('NewCarsWillBeMoreExpensive')}
          </Typography>
          <Typography color='secondary' variant='p16Bold'>
            {t('WaysToPay')}
          </Typography>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <IconTextPair
              svgs={<Svgs.DisplaySmallCustomPiggyBank height='50px' width='50px' />}
              textKey='Cash'
            />
            <IconTextPair
              svgs={<Svgs.DisplaySmallCustomLoans height='50px' width='50px' />}
              textKey='Finance'
            />
            <IconTextPair
              svgs={<Svgs.DisplayMediumCustomHandshake height='50px' width='50px' />}
              textKey='Lease'
            />
          </Box>
        </Stack>
      }
      related={[
        { textKey: 'HowMuchCarCanIAfford', url: '/tools/car/how-much-car' },
        { textKey: 'ShouldIFinanceOrPayCash', url: '/tools/car/finance-or-pay-cash' },
      ]}
    />
  );
};

export default StepTwo;

interface IconTextPairProps {
  svgs: React.ReactNode;
  textKey: string;
}

const IconTextPair: React.FC<IconTextPairProps> = ({ svgs, textKey }) => {
  const { t } = useTranslation();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{ width: '15%' }}>
      {svgs}
      <Typography color='primary' variant='p16' sx={{ marginTop: 1 }}>
        {t(`${textKey}`)}
      </Typography>
    </Box>
  );
};
