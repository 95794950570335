import { Grid, Typography } from '@mui/material';
import useHolisticAdviceProgress from './useHolisticAdviceProgress';
import ProgressIndicator from '../../../components/ProgressIndicator';

const HolisticAdviceProgressIndicator: React.FC = () => {
  const progress = useHolisticAdviceProgress();

  return (
    <Grid>
      {/* Added this to handle alignment */}
      <Typography visibility='hidden' className='title' variant='h1'>
        X
      </Typography>
      <ProgressIndicator steps={progress} />
    </Grid>
  );
};

export default HolisticAdviceProgressIndicator;
