import { CircularProgress } from '@mui/material';
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Column } from '../components';

export interface ILoadingContext {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<ILoadingContext>({
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
});

export const LoadingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
};

export const Loading = () => {
  return (
    <Column style={{ margin: '15px 100px', alignItems: 'center' }}>
      <CircularProgress />
    </Column>
  );
};
