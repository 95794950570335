import { Grid } from '@mui/material';
import { Spacing } from '../../themes';
import { Data } from '@3nickels/data-modules';
import LmsUnitProgressCard from './LmsUnitProgressCard';
import { useOutletContext, useParams } from 'react-router-dom';
import { useObservableQuery } from '@aesop-fables/scrinium';
import LmsCourseCertificatePanel from './LmsCourseCertificatePanel';
import { park } from '../../assets/png';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { LmsLayoutMeta } from './LmsLayout';
import { useEffect } from 'react';
import { LmsMedia } from './LmsMedia';
import LmsNextLessonCard from './LmsNextLessonCard';
import LmsCourseCompleteCard from './LmsCourseCompleteCard';

const LmsCourseOverview: React.FC = () => {
  const { setMeta } = useOutletContext<LayoutContext>();
  const { courseId, lessonId } = useParams();
  const course = useObservableQuery(
    Data.Lms.Queries.FindCourseByCourseId,
    parseInt(courseId ?? '')
  );
  const selectedLesson = useObservableQuery(
    Data.Lms.Queries.FindLessonByLessonId,
    parseInt(lessonId ?? '')
  );

  const firstIncompleteUnit = course?.units
    ?.sort(Data.Lms.sortByPosition)
    .find((unit) => unit.completionPercentage !== 100);

  useEffect(() => {
    if (course?.background) {
      setMeta((prev) => ({
        ...prev,
        background: <LmsMedia id={course.background?.id} title={course.name ?? ''} />,
      }));
    }
  }, [course?.background]);

  return (
    <Grid display='flex' flexDirection='column' rowSpacing={Spacing.lg} rowGap='20px'>
      {course?.completionPercentage === 100 ? (
        <LmsCourseCompleteCard />
      ) : (
        <LmsNextLessonCard course={course} />
      )}
      <Grid className='lms-overview-content' display='flex' columnGap='20px'>
        <Grid className='unit-section' minWidth={0}>
          {course?.units?.sort(Data.Lms.sortByPosition).map((unit) => (
            <LmsUnitProgressCard
              unit={unit}
              selectedLesson={selectedLesson}
              firstIncomplete={firstIncompleteUnit?.unitId === unit.unitId}
            />
          ))}
        </Grid>
        <LmsCourseCertificatePanel course={course} />
      </Grid>
    </Grid>
  );
};

const meta = {
  showBack: false,
  background: <img src={park} className='tools-start-view-background' />,
} satisfies LayoutMeta<LmsLayoutMeta>;

export default withLayoutMeta(LmsCourseOverview, meta);
