import React from 'react';
import { HexagonRotated } from './Hexagon';
import { Box, Grid, Typography } from '@mui/material';
import {
  StateHexagonInnerItemContainer,
  StateHexagonOuterItemContainer,
  StateHoverModal,
} from '../themes/mui';
import { Margins } from '../themes';
import { HelpPopoverText } from './HelpPopover';

export interface StateHexagonProps {
  hexagon: HexagonRotated;
  svgStrokeOffset: number;
  hexFill: string;
  hexStroke: string;
  origin: { x: number; y: number };
  stateName: string;
  stateValue: string;
}

export const StateHexagon: React.FC<StateHexagonProps> = (props) => {
  const { hexagon, svgStrokeOffset, hexFill, origin, stateName, stateValue } = props;

  return (
    <div
      style={{
        position: 'absolute',
        left: origin.x,
        top: origin.y,
      }}>
      <div className='hexagon'>
        <Typography
          sx={StateHexagonOuterItemContainer}
          style={{
            left: -hexagon.height / 2 - svgStrokeOffset,
            top: -hexagon.height / 2 - svgStrokeOffset,
            height: hexagon.height + svgStrokeOffset * 2,
            width: hexagon.height + svgStrokeOffset * 2,
          }}>
          <Typography
            sx={StateHexagonInnerItemContainer}
            style={{
              height: (hexagon.height + svgStrokeOffset * 2) * 0.95,
              width: (hexagon.height + svgStrokeOffset * 2) * 0.95,
              backgroundColor: hexFill,
            }}
          />
        </Typography>
      </div>
      <Grid container id='hover-modal' style={{ display: 'flex' }}>
        <StateHexagonHoverModal stateName={stateName} stateValue={stateValue} />
      </Grid>
    </div>
  );
};

const StateHexagonHoverModal: React.FC<{ stateName: string; stateValue: string }> = ({
  stateName,
  stateValue,
}) => {
  return (
    <Box className='show-on-hover' paddingY={2} paddingX={1} sx={StateHoverModal}>
      <HelpPopoverText style={{ ...Margins.mb_sm, display: 'inline', fontSize: 14 }}>
        {toTitleCase(stateName)}:{' '}
      </HelpPopoverText>
      <HelpPopoverText style={{ fontWeight: 'bold', display: 'inline', fontSize: 14 }}>
        {stateValue}
      </HelpPopoverText>
    </Box>
  );
};

function toTitleCase(str: string) {
  return str.replace(
    /\w*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}
