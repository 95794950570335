import { Circle, CircleOutlined } from '@mui/icons-material';
import { Divider, Grid, SvgIcon, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Status = 'active' | 'complete';

export interface ProgressStepProps {
  title: string;
  status?: Status;
  index?: number;
  lastItem?: boolean;
  exclude?: boolean;
  onClick?: () => void;
  subSteps?: ProgressStepProps[];
}

const ProgressStep: React.FC<ProgressStepProps> = ({
  title,
  status,
  lastItem,
  onClick,
  subSteps,
}) => {
  const { t } = useTranslation();
  const color = useMemo(() => {
    return status === 'active' ? 'secondary' : 'primary';
  }, [status]);

  return (
    <Grid container className='progress-item'>
      <Grid className='progress-item-content'>
        <Typography
          onClick={onClick}
          className='progress-item-title'
          fontWeight={status === 'active' ? 'bold' : undefined}
          color={color}
          lineHeight='92%'>
          {t(title)}
        </Typography>
        {status === 'active' &&
          subSteps?.map((child) => (
            <Typography
              className='progress-item-child'
              variant='p12'
              fontWeight={child.status === 'active' ? 'bold' : undefined}
              color={color}
              onClick={child.onClick}>
              {child.title}
            </Typography>
          ))}
      </Grid>
      <Grid display='flex' flexDirection='column' alignItems='end' height='100%'>
        {!status && <CircleOutlined onClick={onClick} />}
        {status === 'complete' && <SvgIcon component={Circle} onClick={onClick} />}
        {status === 'active' && <CircleOutlined className='active' onClick={onClick} />}
        {!lastItem && (
          // <Grid flexGrow={1}>
          <Divider orientation='vertical' flexItem />
          // </Grid>
        )}
      </Grid>
    </Grid>
  );
};

interface ProgressIndicatorProps {
  steps: ProgressStepProps[];
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ steps }) => {
  const activeIndex = useMemo(() => {
    return steps.findIndex((x) => x.status === 'active');
  }, [steps]);

  return (
    <Grid className='progress-indicator'>
      {steps.map((step, index) => {
        if (step.exclude) return null;
        return <ProgressStep {...step} status={index < activeIndex ? 'complete' : step.status} />;
      })}
    </Grid>
  );
};

const WrappedProgressIndicator: React.FC<ProgressIndicatorProps> = ({ steps }) => {
  return (
    <Grid display='flex'>
      <ProgressIndicator steps={steps} />
    </Grid>
  );
};

export default WrappedProgressIndicator;
