import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data } from '@3nickels/data-modules';

export interface HomeOtherCostsCardProps extends SummaryCardProps {
  homeOtherCosts: Data.RealEstate.HomeOtherCostsFormData;
  includeSpouse?: boolean;
}

const HomeOtherCostsCard: React.FC<HomeOtherCostsCardProps> = ({ onEdit, homeOtherCosts }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='HomeOtherCosts' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualHomeownerInsurance')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(homeOtherCosts.annualInsurance ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MonthlyHOAFees')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(homeOtherCosts.monthlyHoaFees ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualMaintenanceAndRepairs')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(homeOtherCosts.annualHomeRepairCosts ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualPropertyTax')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(homeOtherCosts.annualPropertyTax ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualOtherHousingCosts')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(homeOtherCosts.annualOtherHousingCosts ?? 0)}
          </Typography>
        </Grid>
      </Grid>
    </CardDisplay>
  );
};

export default HomeOtherCostsCard;
