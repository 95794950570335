import { Button } from './buttons/Button';

interface GlowButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const GlowButton: React.FC<GlowButtonProps> = ({ children, onClick, className, ...props }) => {
  return (
    <Button className={`glow-button ${className}`} onClick={onClick} {...props}>
      {children}
      <svg
        className='border-svg'
        width='calc(100% + 4px)'
        height='calc(100% + 4px)'
        viewBox='0 0 110 45'
        preserveAspectRatio='none'>
        <rect
          className='border-rect'
          x='1'
          y='1.5'
          width='108'
          height='42'
          rx='10'
          ry='10'
          fill='none'
          strokeWidth='1.5'
        />
      </svg>
    </Button>
  );
};

export default GlowButton;
