import { Data, Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { capitalizeFirstLetter } from '../../../../helpers/utilityFunctions';
import { SummaryCardProps } from '../../Types';

export interface RetirementPlanBasicsCardProps extends SummaryCardProps {
  accountBasics: Data.InvestmentAccountBasicFormData;
  type: Domain.PlanTypeEnum;
  includeSpouse?: boolean;
}

const RetirementPlanBasicsCard: React.FC<RetirementPlanBasicsCardProps> = ({
  accountBasics,
  type,
  includeSpouse,
}) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics'>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('RetirementPlanNickname')}
          </Typography>
          <Typography color='secondary'>{accountBasics.name}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TypeOfRetirementPlan')}
          </Typography>
          <Typography color='secondary'>{Domain.planTypeToAccountLabel[type]}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {capitalizeFirstLetter(accountBasics?.owner ?? '')}
            </Typography>
          </Grid>
        )}
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default RetirementPlanBasicsCard;
