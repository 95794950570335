interface Props extends React.PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  onClick?: () => void;
}

const Row: React.FC<Props> = (props) => {
  return (
    <div
      {...props}
      className='row'
      style={{ display: 'flex', flexDirection: 'row', ...props.style }}>
      {props.children}
    </div>
  );
};
export default Row;
