import { Grid } from '@mui/material';
import { campingBanner } from '../../assets/png';

const CampingBannerPng = () => {
  return (
    <Grid item container alignItems='flex-end' flex={1}>
      <img
        src={campingBanner}
        alt='Camping Banner'
        style={{
          width: '100%',
          objectFit: 'cover',
        }}
      />
    </Grid>
  );
};

export default CampingBannerPng;
