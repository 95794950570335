/* eslint-disable @typescript-eslint/no-explicit-any */
import { Domain } from '@3nickels/data-modules';

export const DefaultPricebook: Domain.PricebookBundle = {
  pricebook: {
    id: 12,
    pricebookName: '3Nickels Free',
    defaultPricebook: true,
    groupId: 6,
    paidBy: Domain.PaidByEnum.USER,
    productType: Domain.PricebookProductTypeEnum.NICKELS,
    description: [
      'A suite of DIY tools to help you get answers to your questions',
      'Save your data while you work through your options',
      'Free means free',
    ],
    logoUrl: 'https://3nickels.azureedge.net/media/images/general/3n_logo_small.png',
    artUrl: 'https://3nickels.azureedge.net/media/images/general/3n_free_product.png',
    metadata: '',
    skipPayment: false,
    allowCoupon: true,
    skipStripePayment: true,
    paymentType: Domain.PaymentTypeEnum.PRE_PAID,
  },
  prices: [
    {
      id: 8,
      productName: '3Nickels Free',
      price: 0,
      priceId: 'DEFAULT',
      description: '3Nickels Free',
      introductionPeriodInDays: 0,
      subscriptionLengthInDays: 0,
      intervalType: Domain.PaymentIntervalEnum.NONE,
      product: {
        id: 21,
        productName: '3Nickels Free',
        productId: 7,
        threeNickelsU: false,
      },
      processor: {
        id: 2,
        name: 'STRIPE',
      },
    },
  ],
};
