import React from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { TableContainer, Table, TableBody, Grid, Typography } from '@mui/material';
import { Button } from '../../../../components/buttons/Button';
import { t } from 'i18next';
import SkeletonCharityResults, { SkeletonCharity } from './skeletons/SkeletonCharityResults';
import { charityNavigatorLogo } from '../../../../assets/png';

export interface CharityResultsProps {
  charities: Domain.CharityInfoData[];
}

const CharityResults: React.FC<CharityResultsProps> = ({ charities }) => {
  const {
    searchTerm,
    categories,
    loadingCharities,
    loadingMoreCharities,
    canLoadMore,
    totalItems,
    loadNextCharities,
  } = Hooks.useCharitySearcher();
  const navigate = useNavigate();

  if ((searchTerm?.length ?? 0) === 0 && categories.length === 0) {
    return <></>;
  }

  if (loadingCharities) {
    return (
      <Grid>
        <Typography mb={1} variant='p18Bold' color='primary'>
          Results
        </Typography>
        <CharityTableHeader />
        <SkeletonCharityResults />
        <CharityNavigatorBranding />
      </Grid>
    );
  }

  if (charities.length === 0) {
    return (
      <Grid>
        <CharityTableHeader />
        <NoCharitiesFound />
        <CharityNavigatorBranding />
      </Grid>
    );
  }

  return (
    <Grid>
      <Typography mb={1} variant='p18Bold' color='primary'>
        Results ({totalItems.toLocaleString()})
      </Typography>
      <CharityTableHeader />
      <TableContainer className='search-results-container'>
        <Table className='search-results-table'>
          <TableBody className='search-results-table-body'>
            {charities.map((charity) => {
              return (
                <Grid className='search-results-row'>
                  <Grid className='charity-results'>
                    <Typography
                      className='charity-name link'
                      variant='p16Bold'
                      color='secondary'
                      onClick={() => {
                        navigate(`${charity.orgId}`);
                      }}>
                      {charity.charityName ?? ''}
                    </Typography>
                    <Typography className='category' variant='p16' color='secondary'>
                      {charity.category ?? ''}
                    </Typography>
                    <Typography
                      className='location'
                      variant='p16'
                      color='secondary'>{`${charity.city}, ${charity.state}`}</Typography>
                  </Grid>
                </Grid>
              );
            })}
            {loadingMoreCharities && <SkeletonCharity />}
            {canLoadMore && !loadingMoreCharities && (
              <Button
                style={{ marginTop: '10px' }}
                fullWidth
                variant='ghost'
                color='primary'
                label='Load More Results'
                onClick={loadNextCharities}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CharityNavigatorBranding />
    </Grid>
  );
};

const CharityTableHeader: React.FC = () => {
  return (
    <TableContainer
      className='search-results-container'
      style={{ background: 'transparent', border: 'none', marginBottom: '10px' }}>
      <Table className='search-results-table'>
        <TableBody className='search-results-table-body' style={{ padding: '0px' }}>
          <Grid className='search-results-row' py='0px !important' mt='10px'>
            <Grid className='charity-results'>
              <Typography className='charity-name' variant='p14' color='primary'>
                {t('CharityName')}
              </Typography>
              <Typography className='category' variant='p14' color='primary'>
                {t('Category')}
              </Typography>
              <Typography className='location' variant='p14' color='primary'>
                {t('Location')}
              </Typography>
            </Grid>
          </Grid>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoCharitiesFound: React.FC = () => {
  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody
          className='search-results-table-body'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='p16' color='secondary'>
            {t('WeCouldntFindAnyCharities')}
          </Typography>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CharityNavigatorBranding: React.FC = () => {
  const onClick = () => window.open('https://www.charitynavigator.org');

  return (
    <Grid mt={3} container className='charity-navigator-branding' onClick={onClick}>
      <img src={charityNavigatorLogo} alt='charity-navigator-logo' />
      <div>
        <Typography mt={2} variant='p12' color='primary'>
          {t('CharityNavigatorAndTheCharityNavigatorLogo')}
        </Typography>
      </div>
    </Grid>
  );
};

export default CharityResults;
