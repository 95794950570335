import { SvgIcon, SvgIconProps } from '@mui/material';

export function AdviceSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 35 21' {...props}>
      <g fill='none' fill-rule='evenodd'>
        <path d='M0 0h35v21H0z' />
        <g fill={props.fill} fill-rule='nonzero'>
          <path d='M28.911 5.603h-.707v7.774c0 1.21-.705 2.305-2.118 2.305H12.244v.37c0 1.07 1.217 2.15 2.467 2.15h10.583l4.05 2.38-.588-2.38h.155c1.25 0 1.813-1.078 1.813-2.15V7.448c0-1.07-.564-1.845-1.813-1.845z' />
          <path d='M24.02.202H7.942C6.528.202 4.9 1.458 4.9 2.67v9.744c0 1.115 1.38 1.92 2.702 2.045l-.86 3.268 5.516-3.245H24.02c1.415 0 2.72-.858 2.72-2.068V2.67c0-1.212-1.306-2.468-2.72-2.468zM10.395 8.427a1.452 1.452 0 1 1 0-2.905 1.452 1.452 0 0 1 0 2.905zm5.425 0a1.452 1.452 0 1 1 0-2.905 1.452 1.452 0 0 1 0 2.905zm5.426 0a1.453 1.453 0 1 1 0-2.906 1.453 1.453 0 0 1 0 2.906z' />
        </g>
      </g>
    </SvgIcon>
  );
}
