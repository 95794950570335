import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
// import { type PersonalInfo } from '../../../types/PersonalDetails';
import { Domain } from '@3nickels/data-modules';
import CardDisplay from '../../../components/panels/CardDisplay';
import dayjs from 'dayjs';
import { SummaryCardProps } from '../../account-details/Types';

export interface PersonalInfoCardProps extends SummaryCardProps {
  personalInfo?: Domain.SelfData;
  spouseInfo?: Domain.SpouseData;
}

const PersonalInfoCard: React.FC<PersonalInfoCardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='MyPersonalInfo' actionContent='Edit' actionClick={props.onEdit}>
      {props.personalInfo?.includeSpouse && (
        <Typography variant='p16Bold' color='secondary' marginBottom={2}>
          {t('MyPersonalInfo')}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Name')}
          </Typography>
          <Typography color='secondary'>{`${props.personalInfo?.firstName} ${props.personalInfo?.lastName}`}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('PreferredName')}
          </Typography>
          <Typography color='secondary'>{`${props.personalInfo?.preferredName ?? ''}`}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('DateOfBirth')}
          </Typography>
          <Typography color='secondary'>
            {dayjs(props.personalInfo?.birthDate).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Gender')}
          </Typography>
          <Typography color='secondary'>
            {props.personalInfo?.gender && t(props.personalInfo.gender === 'M' ? 'Male' : 'Female')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MaritalStatus')}
          </Typography>
          <Typography color='secondary'>
            {t(props.personalInfo?.maritalStatus ? 'Married' : 'Single')}
          </Typography>
        </Grid>
      </Grid>
      {props.personalInfo?.includeSpouse && (
        <>
          <Typography variant='p16Bold' color='secondary' marginTop={3} marginBottom={2}>
            {t('SpouseInfo')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('Name')}
              </Typography>
              <Typography color='secondary'>{`${props.spouseInfo?.firstName} ${props.spouseInfo?.lastName}`}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('PreferredName')}
              </Typography>
              <Typography color='secondary'>{`${
                props.spouseInfo?.preferredName ?? ''
              }`}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('DateOfBirth')}
              </Typography>
              <Typography color='secondary'>
                {dayjs(props.spouseInfo?.birthDate).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('Gender')}
              </Typography>
              <Typography color='secondary'>
                {props.spouseInfo?.gender && t(props.spouseInfo.gender === 'M' ? 'Male' : 'Female')}
              </Typography>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      )}
    </CardDisplay>
  );
};

export default PersonalInfoCard;
