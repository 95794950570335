import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const CollisionInsuranceCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomCollision />}
      label={t('CollisionInsuranceBreakevenTool')}
      content={t('FindOutIfCollisionInsuranceIsWorth')}
    />
  );
};

export default CollisionInsuranceCard;
