import { Domain } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { CollapsiblePanel } from '../../components/CollapsiblePanel';
import { Colors } from '../../themes';
import LmsUnitProgressHeader from './LmsUnitProgressHeader';
import LmsLessonProgressCard from './LmsLessonProgressCard';

interface LmsUnitProgressCardProps {
  unit: Domain.UnitDto;
  selectedLesson?: Domain.LessonDto;
  unitSelected?: boolean;
  variant?: 'compressed' | 'contained';
  firstIncomplete?: boolean;
}

const LmsUnitProgressCard: React.FC<LmsUnitProgressCardProps> = ({
  unit,
  selectedLesson: lesson,
  variant = 'contained',
  firstIncomplete,
}) => {
  const selectedLesson: Domain.LessonDto = { ...lesson };
  const unitSelected = useMemo(() => {
    const found = unit.lessons?.find((x) => x.lessonId === selectedLesson?.lessonId);
    return typeof found !== 'undefined';
  }, [selectedLesson]);

  if (variant === 'compressed') {
    return (
      <CompressedLmsUnitProgressCard
        unit={unit}
        selectedLesson={selectedLesson}
        unitSelected={unitSelected}
        variant={variant}
        firstIncomplete={firstIncomplete}
      />
    );
  }

  return (
    <Grid
      sx={{
        '& > *': { boxShadow: '0 4px 7px 1px rgba(30, 41, 105, 0.2) !important' },
        '& .collapsible-panel, .collapsible-panel-header, .collapsible-panel-content': {
          backgroundColor: 'rgba(255, 255, 255, .2) !important',
          backdropFilter: 'blur(6px)',
        },
      }}>
      <CollapsiblePanel
        initiallyExpanded={unitSelected || firstIncomplete}
        collapsedHeaderStyle={{
          background: `linear-gradient(${
            unit.completionPercentage === 100 ? Colors.secondaryLight : Colors.primaryLight
          } 0 0) bottom left / ${
            unit.completionPercentage ?? 0
          }% 2px no-repeat, rgba(255, 255, 255, .2)`,
        }}
        variant='contained'
        header={<LmsUnitProgressHeader unit={unit} selected={unitSelected} variant='contained' />}
        summary={unit.completionPercentage === 100 && <Svgs.IconCheckmark />}>
        <Grid container gap={'20px'}>
          {unit.lessons?.map((lesson) => (
            <LmsUnitProgressLesson
              unit={unit}
              lesson={lesson}
              selected={lesson.lessonId === selectedLesson?.lessonId}
            />
          ))}
        </Grid>
      </CollapsiblePanel>
    </Grid>
  );
};

const CompressedLmsUnitProgressCard: React.FC<LmsUnitProgressCardProps> = ({
  unit,
  unitSelected,
  selectedLesson,
  firstIncomplete,
}) => {
  return (
    <CollapsiblePanel
      initiallyExpanded={unitSelected || firstIncomplete}
      variant='compressed'
      header={<LmsUnitProgressHeader unit={unit} selected={unitSelected} variant='compressed' />}
      expandIconPosition='end'>
      <Grid container gap={undefined}>
        {unit.lessons?.map((lesson) => (
          <LmsUnitProgressLesson
            unit={unit}
            lesson={lesson}
            selected={lesson.lessonId === selectedLesson?.lessonId}
            variant='compressed'
          />
        ))}
      </Grid>
    </CollapsiblePanel>
  );
};

interface LmsUnitProgressLessonProps {
  unit: Domain.UnitDto;
  lesson: Domain.LessonDto;
  variant?: 'compressed' | 'contained';
  selected?: boolean;
}

const LmsUnitProgressLesson: React.FC<LmsUnitProgressLessonProps> = ({
  unit,
  lesson,
  selected,
  variant = 'contained',
}) => {
  const navigate = useNavigate();
  const { courseId: courseIdParams } = useParams();
  const courseId = parseInt(courseIdParams ?? '');

  const startLesson = () => {
    // For some units, unit.sourceCourseId does not match course.courseId
    if (lesson.quiz && lesson.quiz.mostRecentAttempt) {
      navigate(
        `/learning/${courseId}/${unit.unitId}/${lesson.lessonId}/${lesson.quiz.quizId}/results`,
        { state: { fromCourseView: true } }
      );
    } else {
      navigate(`/learning/${courseId}/${unit.unitId}/${lesson.lessonId}/content`);
    }
  };

  return (
    <LmsLessonProgressCard
      lesson={lesson}
      variant={variant}
      selected={selected}
      startLesson={startLesson}
    />
  );
};

export default LmsUnitProgressCard;
