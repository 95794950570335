import { MenuItem, Grid, Card, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Domain } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import FormContent from '../../../../components/form/FormContent';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Button } from '../../../../components/buttons/Button';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { Spacer } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';

export type IraFeeCheckerFormProps = {
  iras: Domain.PlanData[];
  formMethods: UseFormReturn;
  defaultValues?: Domain.AdviceFeeCheckerPlanData;
  selectedIra?: Domain.PlanData;
  results: Domain.AdviceFeeCheckerPositionResultData | undefined;
  setSelectedIra: Dispatch<SetStateAction<Domain.PlanData | undefined>>;
  resetResults: () => void;
  onSubmit: (values: Domain.AdviceFeeCheckerPlanData) => void;
};

const IraFeeCheckerForm: React.FC<IraFeeCheckerFormProps> = ({
  iras,
  formMethods,
  defaultValues,
  selectedIra,
  results,
  setSelectedIra,
  resetResults,
  onSubmit,
}) => {
  const { formState, watch, handleSubmit, setValue } = formMethods;

  useEffect(() => {
    if (iras.length === 1) {
      setValue('iraId', iras[0].id);
    }
  }, [iras]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'iraId') {
        const id = values.iraId;
        const ira = iras.find((x) => x.id === id);
        if (typeof ira !== 'undefined' && ira.id !== selectedIra?.id) {
          setSelectedIra(ira);
          resetResults();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, iras, selectedIra]);

  if (iras.length === 0) return <NoPlansAdded />;

  return (
    <FormContent formProviderProps={formMethods}>
      <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
        <Grid className='tool-form-container'>
          <SelectInput
            error={formState.errors.iraId !== undefined}
            helperText={formState.errors.iraId?.message?.toString()}
            defaultValue={selectedIra?.id}
            name='iraId'
            label='IraName'
            placeholder='ChooseAnIra'>
            {iras.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.nickname + ` (${formatWholeDollars(x.balance ?? 0)})`}
              </MenuItem>
            ))}
          </SelectInput>
          <SliderPercent<Domain.AdviceFeeCheckerPlanData>
            error={formState.errors.maintenanceFee !== undefined}
            helperText={formState.errors.maintenanceFee?.message?.toString()}
            defaultValue={defaultValues?.maintenanceFee}
            name='maintenanceFee'
            label='AnnualAccountMaintenanceFee'
            type='dollar'
            min={0}
            max={200}
            step={10}
          />
          <SliderPercent<Domain.AdviceFeeCheckerPlanData>
            error={formState.errors.minimumBalanceFee !== undefined}
            helperText={formState.errors.minimumBalanceFee?.message?.toString()}
            defaultValue={defaultValues?.minimumBalanceFee}
            name='minimumBalanceFee'
            label='AnnualMinimumBalanceFee'
            type='dollar'
            min={0}
            max={200}
            step={10}
          />
          <SliderPercent<Domain.AdviceFeeCheckerPlanData>
            error={formState.errors.portfolioManagementFee !== undefined}
            helperText={formState.errors.portfolioManagementFee?.message?.toString()}
            defaultValue={defaultValues?.portfolioManagementFee}
            name='portfolioManagementFee'
            label='AnnualPortfolioManagementFee'
            type='percent'
            min={0}
            initialMax={2}
            max={100}
            step={0.1}
            precision={3}
          />
          <SliderPercent<Domain.AdviceFeeCheckerPlanData>
            error={formState.errors.tradingFee !== undefined}
            helperText={formState.errors.tradingFee?.message?.toString()}
            defaultValue={defaultValues?.tradingFee}
            name='tradingFee'
            label='AnnualTradingFees'
            type='dollar'
            min={0}
            max={200}
            step={10}
          />
        </Grid>
        <Button
          type='submit'
          style={{ width: '45%', alignSelf: 'end', marginTop: '30px' }}
          label={results ? 'Recalculate' : 'Calculate'}
        />
      </form>
    </FormContent>
  );
};

const NoPlansAdded: React.FC = () => {
  const navigate = useNavigate();

  const navToAccounts = () => navigate('/account-details');

  return (
    <Card className='tools-no-items-added-box'>
      <Spacer height='xxs' />
      <Svgs.ArtPieChart />
      <Typography variant='p16' color='error'>
        {t('PleaseAddAnIra')}
      </Typography>
      <Button label='GoToAccounts' onClick={navToAccounts} />
    </Card>
  );
};

export default IraFeeCheckerForm;
