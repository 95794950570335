import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type CashGivingGoalBasicFormProps = {
  model?: Data.Goals.CashGivingGoalBasicFormData;
  onSubmit: (values: Data.Goals.CashGivingGoalBasicFormData) => Promise<void>;
};

const CashGivingGoalBasicForm: React.FC<CashGivingGoalBasicFormProps> = (props) => {
  const { formState, setValue, reset } = useFormContext();
  const { model } = props;

  useEffect(() => {
    reset(model);
  }, [model, reset]);

  const [dollarOrPercent, setDollarOrPercent] = useState<Domain.GivingPlanDollarPercentEnum>();

  const setDollarOrPercentHandler = (value: Domain.GivingPlanDollarPercentEnum) => {
    setDollarOrPercent(value);
    setValue('dollarOrPercentSelf', value);
  };

  useEffect(() => {
    setDollarOrPercentHandler(
      model?.dollarOrPercentSelf ?? Domain.GivingPlanDollarPercentEnum.Dollar
    );
  }, [model?.dollarOrPercentSelf]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<Data.Goals.CashGivingGoalBasicFormData>
          error={formState.errors.dollarOrPercentSelf !== undefined}
          helperText={formState.errors.dollarOrPercentSelf?.message?.toString()}
          name='dollarOrPercentSelf'
          row
          onChange={(target) => {
            setDollarOrPercentHandler(target.value as Domain.GivingPlanDollarPercentEnum);
          }}
          defaultValue={dollarOrPercent}
          items={[
            { label: 'Fixed $ Amount', value: Domain.GivingPlanDollarPercentEnum.Dollar },
            { label: '% of Pay', value: Domain.GivingPlanDollarPercentEnum.Percent },
          ]}
        />

        {dollarOrPercent === Domain.GivingPlanDollarPercentEnum.Dollar && (
          <TextInput<Data.Goals.CashGivingGoalBasicFormData>
            error={formState.errors.personalGivingDream !== undefined}
            helperText={formState.errors.personalGivingDream?.message?.toString()}
            defaultValue={model?.personalGivingDream}
            label='Amount I Dream of Giving Annually'
            name='personalGivingDream'
            type='dollar'
            autoFocus
          />
        )}

        {dollarOrPercent === Domain.GivingPlanDollarPercentEnum.Percent && (
          <TextInput<Data.Goals.CashGivingGoalBasicFormData>
            error={formState.errors.personalGivingDreamPercent !== undefined}
            helperText={formState.errors.personalGivingDreamPercent?.message?.toString()}
            defaultValue={model?.personalGivingDreamPercent}
            label='Amount I Dream of Giving Annually'
            name='personalGivingDreamPercent'
            type='percent'
            precision={0}
            autoFocus
          />
        )}
      </Stack>
    </Box>
  );
};

export default CashGivingGoalBasicForm;
