import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

const MortgageRefinanceChecklistView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography mb={2} variant='p30Bold' component='h1' color='primary'>
          {t('MortgageRefinanceChecklist')}
        </Typography>
        <Stack spacing={Spacing.xxxs + 1}>
          <StepOne />
          <StepTwo />
          <StepThree />
          <StepFour />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(MortgageRefinanceChecklistView, meta);
