import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Box } from '@mui/material';

type TouchStartRef = {
  startX: number;
  startY: number;
  startOffset: number;
  startTime: number;
} | null;

interface SwipeableCarouselProps {
  items: ReactNode[];
  itemWidth: number;
}

const SwipeableCarousel: React.FC<SwipeableCarouselProps> = ({ items, itemWidth }) => {
  const [offset, setOffset] = useState(0);
  const [isHorizontalSwipe, setIsHorizontalSwipe] = useState(false);
  const touchRef = useRef<TouchStartRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const SPACING = 10;
  const SIDE_PADDING = 20;
  const BOUNCE_DISTANCE = 60;
  const MOMENTUM_MULTIPLIER = 500;

  const totalItemWidth = itemWidth + SPACING;
  const maxOffset = 0;
  const minOffset = -(
    totalItemWidth * (items.length - 1) -
    (window.innerWidth - itemWidth) +
    SIDE_PADDING * 2
  );

  const handleTouchStart = (e: TouchEvent) => {
    const touch = e.touches[0];
    touchRef.current = {
      startX: touch.clientX,
      startY: touch.clientY,
      startOffset: offset,
      startTime: Date.now(),
    };
    setIsHorizontalSwipe(false);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!touchRef.current) return;

    const touch = e.touches[0];
    const deltaX = touch.clientX - touchRef.current.startX;
    const deltaY = touch.clientY - touchRef.current.startY;

    if (!isHorizontalSwipe && (Math.abs(deltaX) > 8 || Math.abs(deltaY) > 8)) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        setIsHorizontalSwipe(true);
        document.body.style.overflow = 'hidden';
        e.preventDefault();
      } else {
        touchRef.current = null;
        return;
      }
    }

    if (isHorizontalSwipe) {
      e.preventDefault();
      let newOffset = touchRef.current.startOffset + deltaX;

      if (newOffset > maxOffset || newOffset < minOffset) {
        const overscroll = newOffset > maxOffset ? newOffset : newOffset - minOffset;
        newOffset = (newOffset > maxOffset ? maxOffset : minOffset) + overscroll * 0.3;
      }

      setOffset(newOffset);
    }
  };

  const handleTouchEnd = () => {
    if (!touchRef.current) return;

    const velocity =
      (offset - touchRef.current.startOffset) /
      Math.max(1, Date.now() - touchRef.current.startTime);
    const finalOffset = Math.max(
      minOffset,
      Math.min(maxOffset, offset + velocity * MOMENTUM_MULTIPLIER * Math.abs(velocity))
    );

    if (offset > maxOffset || offset < minOffset) {
      const bounceOffset =
        offset > maxOffset ? maxOffset + BOUNCE_DISTANCE : minOffset - BOUNCE_DISTANCE;
      setOffset(bounceOffset);
      requestAnimationFrame(() => requestAnimationFrame(() => setOffset(finalOffset)));
    } else {
      setOffset(finalOffset);
    }

    touchRef.current = null;
    setIsHorizontalSwipe(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    element.addEventListener('touchstart', handleTouchStart, { passive: true });
    element.addEventListener('touchmove', handleTouchMove, { passive: false });
    element.addEventListener('touchend', handleTouchEnd);
    element.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
      element.removeEventListener('touchcancel', handleTouchEnd);
      document.body.style.overflow = '';
    };
  }, [offset, isHorizontalSwipe]);

  useEffect(() => {
    setOffset(SIDE_PADDING - totalItemWidth);
    setTimeout(() => setOffset(0), 100);
  }, []);

  return (
    <Box
      ref={containerRef}
      width='100%'
      overflow='hidden'
      padding={`0 ${SIDE_PADDING}px`}
      sx={{ touchAction: isHorizontalSwipe ? 'none' : 'pan-y' }}>
      <Box
        display='flex'
        sx={{
          transform: `translateX(${offset}px)`,
          transition: touchRef.current
            ? 'none'
            : 'transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)',
        }}>
        {items.map((item, index) => (
          <Box key={index} width={itemWidth} marginRight={`${SPACING}px`}>
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SwipeableCarousel;
