import ContentView from '../../layout/Content';
import { useContentView } from '../../layout/ContentViewContext';
import React from 'react';
import { UniversityUpsellView } from './university-upsell/UniversityUpsellView';
import { AdviceUpsellView } from './advice-upsell/AdviceUpsellView';
import { Domain } from '@3nickels/data-modules';

interface FreeViewProps {
  sortedProducts: Domain.PricebookBundle[];
  universityEnabled: boolean | undefined;
}

const FreeView: React.FC<FreeViewProps> = ({ sortedProducts, universityEnabled }) => {
  const { sideNav } = useContentView();
  const universityAvailable = sortedProducts?.find((p) => p.pricebook.productType === 'NICKELSU');

  return (
    <ContentView sideNavMenuItems={sideNav('Home')}>
      {universityAvailable && !universityEnabled ? <UniversityUpsellView /> : <AdviceUpsellView />}
    </ContentView>
  );
};

export default FreeView;
