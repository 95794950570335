import { redirect } from 'react-router-dom';
import { RedirectContext, RedirectHandler } from './RedirectHandler';

export class AcceptTermsHandler implements RedirectHandler {
  async matches(context: RedirectContext) {
    return context.requireToAcceptTerms === true;
  }

  handle(): Response {
    return redirect('/accept-terms');
  }
}
