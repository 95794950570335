import { Grid, Typography } from '@mui/material';
import React from 'react';

interface GoalDetail {
  key: string;
  value: string;
  hide?: boolean;
}

interface GoalCardDetailsProps {
  details: GoalDetail[];
}

const GoalCardDetails: React.FC<GoalCardDetailsProps> = ({ details }) => {
  return (
    <Grid container gap='10px'>
      {details
        .filter((detail) => !detail.hide)
        .map((detail) => (
          <Grid key={detail.key} container display='flex' justifyContent='space-between'>
            <Typography variant='p16Bold' color='primary' width='66%'>
              {detail.key}
            </Typography>
            <Typography variant='p16' color='secondary'>
              {detail.value}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
};

export default GoalCardDetails;
