import { Typography } from '@mui/material';
import { Colors } from '../themes';
import { Domain } from '@3nickels/data-modules';

export declare type FormTitleProps = {
  title: string;
  emphasizedText?: string;
  secondaryText?: JSX.Element;
};

export const FormTitle: React.FC<FormTitleProps> = ({ secondaryText, emphasizedText, title }) => {
  return (
    <Typography component='h1' variant='h1' color={Colors.primaryBase} display='inline-block'>
      {title}&nbsp;
      <span style={{ fontWeight: '100' }}>{emphasizedText}</span>
      {secondaryText && secondaryText}
    </Typography>
  );
};

export const GoalFormTitle: React.FC<{
  secondaryText?: JSX.Element;
  editing?: boolean;
  type: Domain.GoalTypeEnum;
}> = ({ secondaryText, editing, type }) => {
  return (
    <FormTitle
      title={editing ? `Edit Goal:` : `Add a Goal:`}
      emphasizedText={Domain.GoalTypeLabels[type]}
      secondaryText={secondaryText}
    />
  );
};
