import { Typography } from '@mui/material';
import React from 'react';

interface SummaryHeaderProps {
  content?: string;
}

export const SummaryHeader: React.FC<SummaryHeaderProps> = ({ content }) => {
  return (
    <Typography variant='p18Bold' color='secondary' alignSelf='end'>
      {content}
    </Typography>
  );
};
