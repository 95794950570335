import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import { Spacer } from '../../../components';

type QuizQuestionHeaderProps = {
  questionNumber?: number;
  text?: string;
  answerType?: Domain.AnswerTypeEnum;
};

export const QuizQuestionHeader = ({
  questionNumber = 0,
  text = '',
  answerType,
}: QuizQuestionHeaderProps) => {
  const numberedContent = useMemo(() => {
    if (!text) return '';

    const pattern = />([^<])/;
    const insertNumber = `>${questionNumber + 1}. $1`;

    return text.replace(pattern, insertNumber);
  }, [text, questionNumber]);

  const multipleChoiceText = useMemo(() => {
    return text?.replace(
      /<p>.*?<\/p>/,
      '<p style="font-size: 16px; font-weight: normal">Select all that apply.</p>'
    );
  }, [text]);

  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: numberedContent }} />
      {answerType === Domain.AnswerTypeEnum.MULTIPLE && (
        <>
          <Spacer height='xxs' />
          <div dangerouslySetInnerHTML={{ __html: multipleChoiceText }} />
        </>
      )}
    </Box>
  );
};
