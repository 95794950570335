import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from './ApiKeys';

interface PlatformSwitchResponse {
  redirectUrl: string;
}

export class PlatformSwitchApi {
  constructor(@inject(ApiKeys.MobileAuthAxios) private readonly axios: AxiosInstance) {}

  // Used to deep link to mobile app with authentication
  webToMobile(): Promise<AxiosResponse<PlatformSwitchResponse>> {
    return this.axios.post('/platform-switch/web-to-mobile');
  }
}
