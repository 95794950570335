/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate } from 'react-router-dom';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import LinkAccountHeader from '../../headers/LinkAccountHeader';
import { Backdrop, Card, CardHeader, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Spacer } from '../../../../components';
import { PlaidInstitutionContext } from '../PlaidWizard';
import { useObservable } from '@aesop-fables/scrinium';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Colors } from '../../../../themes';
import { Check } from '@mui/icons-material';
import { usePlaidManualLinkRedirect } from '../../../../hooks/usePlaidManualLinkRedirect';
import NoAccountsFoundCard from './NoAccountsFoundCard';

interface ManualImportAccountViewProps {
  // currentStep: WizardStep<InvestmentAccountBasicFormData, InvestmentAccountWizardParams>;
  // wizard: InvestmentAccountWizard;
  institution: PlaidInstitutionContext;
  setLatch: (val: boolean) => void;
}

const ManualImportAccountViewWrapper: React.FC = () => {
  const wizard = Hooks.usePlaidWizard();
  const navigate = useNavigate();
  const context = useObservable(wizard.context$);
  const loading = useObservable(wizard.loading$);
  const [latch, setLatch] = useState(false);

  useEffect(() => {
    if (context && typeof context.selectedInstitution === 'undefined') {
      navigate(`/account-details/link-account/choose-institution`);
    }
  }, [context]);

  if (!latch && (loading === true || typeof context?.selectedInstitution === 'undefined')) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <ManualImportAccountView
      institution={context?.selectedInstitution as PlaidInstitutionContext}
      setLatch={setLatch}
    />
  );
};

const ManualImportAccountView: React.FC<ManualImportAccountViewProps> = ({
  institution,
  setLatch,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selected, setSelected] = useState<Domain.IAutoBindableAccount | undefined>(undefined);
  const { setLoading } = useLoading();
  const wizard = Hooks.usePlaidWizard();
  const context = Hooks.usePlaidContext();
  const navigate = useNavigate();
  const redirectToSummary = usePlaidManualLinkRedirect();
  const accountsAvailable = institution.availableAccounts?.length > 0;
  const summaryUrl = location.state?.summaryUrl;
  const hideBack = location.state?.hideBack;

  const linkAccountHandler = async () => {
    setLoading(true);
    setLatch(true);
    try {
      if (selected) {
        await wizard.finalizeManualAccountLink(selected);
        wizard.prepareToReloadInvestments(context.account?.id ?? 0);
        const url = redirectToSummary(selected);
        navigate(url);
      } else {
        wizard.reset();
        navigate(summaryUrl ?? '/account-details');
      }
    } finally {
      setLoading(false);
      // setLatch(false);
    }
  };

  return (
    <Grid container flexDirection='column'>
      <Typography className='title' variant='h1' component='h1' color='primary'>
        {t('ImportAccounts')}
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p18Bold' color='secondary'>
        {institution.institutionName}
      </Typography>
      {accountsAvailable && (
        <Typography className='subtitle' color='secondary'>
          {t('ManualImportDescription')}
        </Typography>
      )}
      <Spacer height='xxs' />

      <Typography color='primary' variant='p12'>
        {t('ImportAccountsNote')}
      </Typography>
      {accountsAvailable && (
        <>
          <Spacer height='sm' />
          <Typography color='primary' variant='h3' fontWeight='bold'>
            {t('SelectWhichAccount')}
          </Typography>
        </>
      )}
      <Spacer height='xxs' />
      <Grid container justifyContent='center'>
        <Grid item justifyContent='center' sm={6}>
          {accountsAvailable ? (
            institution.availableAccounts.map((account) => {
              const checked = selected === account;
              return (
                <Card
                  className={checked ? 'check-select-checked' : 'check-select'}
                  onClick={() => {
                    setSelected(checked ? undefined : account);
                  }}
                  key={account.name}
                  elevation={0}>
                  <CardHeader
                    disableTypography
                    title={
                      <Typography
                        color={checked ? Colors.tertiaryBase : Colors.secondaryBase}
                        variant='p18Bold'
                        marginTop={checked ? '' : '8px'}>
                        {account.name}
                      </Typography>
                    }
                    action={
                      checked && (
                        <Grid item mt='11px' mr={1}>
                          <Check htmlColor={Colors.tertiaryBase} />
                        </Grid>
                      )
                    }
                  />
                </Card>
              );
            })
          ) : (
            <NoAccountsFoundCard />
          )}
        </Grid>
      </Grid>
      <WizardFooter
        disableNext={accountsAvailable && typeof selected === 'undefined'}
        hideBack={hideBack}
        {...getWizardFooterProps(meta.nextLocaleKey)}
        onDone={() => linkAccountHandler()}
      />
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  disableNextButton: true,
  header: <LinkAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(ManualImportAccountViewWrapper, meta);
