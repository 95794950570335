import React, { SetStateAction, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { useContentView } from '../layout/ContentViewContext';
import { Data, Domain } from '@3nickels/data-modules';
import LmsSideView from './LmsSideView';
import { useObservableQuery } from '@aesop-fables/scrinium';
import Breadcrumbs from '../../components/Breadcrumbs';
import { t } from 'i18next';
import { Svgs } from '../../assets/svg';
import LmsFooter from './LmsFooter';

export type LmsLayout = Record<string, unknown>;

export declare type LmsLayoutMeta = {
  centerComponent?: React.JSX.Element;
  onNext?: () => void;
  background?: React.ReactNode;
};

export interface AllLessonsButtonProps {
  course?: Domain.CourseDto;
  yourLessonsView?: boolean;
  expanded: boolean;
  setExpanded: React.Dispatch<SetStateAction<boolean>>;
}

export const AllLessonsButton: React.FC<AllLessonsButtonProps> = ({
  course,
  yourLessonsView,
  expanded,
  setExpanded,
}) => {
  const { setId } = useContentView();

  const onClick = () =>
    setExpanded((prev) => {
      if (!prev) {
        setId('component');
      } else {
        setId('min');
      }
      return !prev;
    });

  return (
    <>
      {!expanded && (
        <>
          <Button
            variant='ghost'
            size='large'
            onClick={onClick}
            sx={{ display: { xl: 'none', lg: 'inline' }, padding: '5px !important' }}>
            <Grid display='flex' alignItems='center'>
              <Svgs.ActionSmallGrip />
            </Grid>
          </Button>
          <Button
            variant='ghost'
            size='large'
            onClick={onClick}
            sx={{ display: { xl: 'inline', lg: 'none', md: 'none', sm: 'none', xs: 'none' } }}>
            <Grid display='flex' alignItems='center' columnGap='10px'>
              <Svgs.ActionSmallGrip />
              {yourLessonsView ? course?.name : t('AllLessons')}
            </Grid>
          </Button>
        </>
      )}
      {expanded && (
        <Button variant='ghost' size='large' onClick={onClick}>
          <Grid display='flex' alignItems='center' columnGap='10px'>
            {expanded ? <Svgs.ActionSmallMenuClose /> : <Svgs.ActionSmallGrip />}
            {yourLessonsView ? course?.name : t('AllLessons')}
          </Grid>
        </Button>
      )}
    </>
  );
};

const LmsLayout: React.FC = () => {
  const { meta, setMeta } = useLayoutMeta<LmsLayoutMeta>();
  const { sideNav } = useContentView();
  const [expanded, setExpanded] = useState(false);

  const { courseId, unitId, lessonId } = useParams();
  const selectedCourse = useObservableQuery(
    Data.Lms.Queries.FindCourseByCourseId,
    parseInt(courseId ?? '')
  );
  const selectedUnit = useObservableQuery(
    Data.Lms.Queries.FindUnitByUnitId,
    parseInt(unitId ?? '')
  );
  const selectedLesson = useObservableQuery(
    Data.Lms.Queries.FindLessonByLessonId,
    parseInt(lessonId ?? '')
  );

  const [pathnameSegments, breadcrumbNameMap] = useMemo(() => {
    const segments: string[] = ['learning'];
    const nameMap: { [key: string]: string } = { '/learning': t('YourCourses') };
    if (selectedCourse?.courseId && selectedCourse.name) {
      const courseSegment = `${selectedCourse.courseId}`;
      const courseSegmentKey = '/learning/' + courseSegment;
      segments.push(courseSegment);
      nameMap[courseSegmentKey] = selectedCourse.name;
      if (selectedUnit?.unitId && selectedUnit.name) {
        const unitSegment = `${selectedUnit.unitId}`;
        const unitSegmentKey = courseSegmentKey + '/' + unitSegment;
        segments.push(unitSegment);
        nameMap[unitSegmentKey] = selectedUnit.name;

        if (selectedLesson?.lessonId && selectedLesson.name) {
          const lessonSegment = `${selectedLesson.lessonId}`;
          const lessonSegmentKey = unitSegmentKey + '/' + lessonSegment;
          segments.push(lessonSegment);
          nameMap[lessonSegmentKey] = selectedLesson.name;
        }
      }
    }

    return [segments, nameMap];
  }, [selectedCourse, selectedUnit, selectedLesson]);
  const lessonView = typeof selectedLesson?.lessonId !== 'undefined';

  return (
    <Data.Lms.QuizProvider>
      <ContentView
        background={meta.background}
        sideNavMenuItems={sideNav('Learn')}
        sideComponent={
          lessonView ? (
            <LmsSideView
              course={selectedCourse}
              lesson={selectedLesson}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ) : undefined
        }
        footer={
          lessonView ? (
            <LmsFooter expanded={expanded} setExpanded={setExpanded} meta={meta} />
          ) : undefined
        }
        containerProps={
          lessonView
            ? { gridTemplateColumns: expanded ? '350px' : { xl: '0px', lg: 'min-content' } }
            : undefined
        }
        leftChildren={
          <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
        }>
        <Outlet context={{ setMeta }} />
      </ContentView>
    </Data.Lms.QuizProvider>
  );
};

export default LmsLayout;
