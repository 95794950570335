import { Grid, Stack } from '@mui/material';
import { t } from 'i18next';
import { Spacer, KeyValuePair } from '../../../../components';
import { Spacing } from '../../../../themes';
import { Domain, formatWholeDollars } from '@3nickels/data-modules';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import ZillowCard from '../../../../components/ZillowCard';

export interface MortgageRatesResults {
  results: Domain.MortgageRateResultData;
}

const MortgageRatesResults: React.FC<MortgageRatesResults> = ({ results }) => {
  return (
    <Grid container direction='column' rowGap='120px'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 1}>
          <Spacer height='xxs' />
          <KeyValuePair
            label={t('Rate')}
            value={typeof results.rate !== 'undefined' ? formatPercent(results.rate) : '– – –'}
          />
          <KeyValuePair
            label={t('APR')}
            value={typeof results.apr !== 'undefined' ? formatPercent(results.apr) : '– – –'}
          />
          <KeyValuePair
            label={t('MonthlyPayment')}
            value={
              typeof results.monthlyPayment !== 'undefined'
                ? formatWholeDollars(results.monthlyPayment)
                : '– – –'
            }
          />
        </Stack>
      </Grid>
      <ZillowCard />
    </Grid>
  );
};

export default MortgageRatesResults;
