import { SVGProps } from 'react';
import { Svgs } from '../assets/svg';
import { Hooks } from '@3nickels/data-modules';

type HolisticPlanStepIconProps = Omit<SVGProps<SVGSVGElement>, 'type'> & {
  title?: string | undefined;
  type: Hooks.HolisticAdvicePlan.HolisticStepTypeEnum;
};

const HolisticPlanStepIcon: React.FC<HolisticPlanStepIconProps> = ({ type, ...rest }) => {
  switch (type) {
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.ASK_EMPLOYER:
      return <Svgs.DisplaySmallCustomOrganization {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.CHANGE_CONTRIBUTIONS:
      return <Svgs.DisplaySmallCustomCoins {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.CHANGE_GIVING:
      return <Svgs.DisplaySmallCustomGifts {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.CHANGE_INVESTMENTS:
      return <Svgs.DisplaySmallCustomInvestment {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.DEPOSIT:
      return <Svgs.DisplaySmallCustomDeposit {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.KEEP:
      return <Svgs.DisplaySmallCustomProgressComplete {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.OPEN_ACCOUNT:
      return <Svgs.DisplaySmallCustomBank {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.PAY_DOWN:
      return <Svgs.DisplaySmallCustomPayOff {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.PAY_MONTHLY:
      return <Svgs.DisplaySmallCustomLoans {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.SAVE_MONTHLY:
      return <Svgs.DisplaySmallCustomPiggyBank {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.TAKE_OUT:
      return <Svgs.DisplaySmallCustomWithdraw {...rest} />;
    case Hooks.HolisticAdvicePlan.HolisticStepTypeEnum.TRACK_HOURS:
      return <Svgs.DisplaySmallCustomClock {...rest} />;
    default:
      return <Svgs.DisplaySmallCustomPiggyBank {...rest} />;
  }
};

export default HolisticPlanStepIcon;
