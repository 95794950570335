import { Stack } from '@mui/material';
import { t } from 'i18next';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';

export const SplittingUpInvestmentsModal: React.FC = () => {
  return (
    <HelpPopover title={t('SplittingUpYourInvestments')}>
      <Stack spacing={2}>
        <HelpPopoverText>{t('YouMayHaveHeardThePhrase')}</HelpPopoverText>
        <HelpPopoverText>{t('TheKeyIsToMakeSure')}</HelpPopoverText>
        <HelpPopoverText>{t('ItsADisciplinedApproach')}</HelpPopoverText>
      </Stack>
    </HelpPopover>
  );
};
