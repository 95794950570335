import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const FinanceOrPayCashCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomCoins />}
      label={t('FinanceOrPayCash')}
      content={t('BorrowingIsNotTheBestOption')}
    />
  );
};

export default FinanceOrPayCashCard;
