import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FinancialInfoForm from './FinancialInfoForm';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormLoader } from '../../../components/FormLoader';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { MutationStatus, WizardStep, useMutation } from '@aesop-fables/scrinium';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../components/form/FormContent';
import { useLoading } from '../../../hooks/useLoading';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';

export const FinancialInfoEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.BasicFinancialFormData>();
  const { person } = Hooks.useCombinedSelfData();
  useEffect(() => {
    wizard.selectStep('financialInfo');
  }, []);

  return (
    <FormLoader loading={loading}>
      <FinancialInfoEditView
        currentStep={currentStep}
        wizard={wizard}
        editing={editing}
        onBack={onBack}
        includeSpouse={person?.includeSpouse}
      />
    </FormLoader>
  );
};

interface FinancialInfoEditProps extends EditViewProps {
  currentStep: WizardStep<Data.People.BasicFinancialFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
  includeSpouse?: boolean;
}
const financialInfoNoSpouse = {
  annualIncome: Yup.string()
    .required('Annual Income is required')
    .test('annualIncome', 'Annual Income must be greater than $0', (income) => {
      const cleanNumber = cleanWholeNumberStr(income);
      return cleanNumber > 0;
    }),
  otherAnnualIncome: Yup.string().notRequired(),
  taxFilingStatus: Yup.number()
    .transform((transformed, original) => (String(original).trim() === '' ? null : transformed))
    .required('Tax Filing Status is required')
    .test('taxFilingStatus', 'Tax Filing Status is required', (status) =>
      Domain.TaxFilingStatusOptions.some((option) => {
        return option.key === status;
      })
    ),
  taxFilingState: Yup.string()
    .required('Tax Filing State is required')
    .test('taxFilingState', 'Tax Filing State is required', (state) =>
      Object.values(Domain.UsStatesEnum).some((option) => option === state)
    ),
  creditScore: Yup.number()
    .required('Credit Score is required')
    .typeError('Credit Score must be a number between 250 and 900')
    .test('creditScore', 'Credit Score cannot be lower than 250', (score) => score >= 250)
    .test('creditScore', 'Credit Score cannot be higher than 900', (score) => score <= 900),
  investmentPreference: Yup.string()
    .required('Investing Preference is required')
    .test('investmentPreference', 'Investing Preference is required', (preference) =>
      Domain.InvestmentPreferenceTypeListItems.some((option) => option.key === preference)
    ),
  eligibleForHsa: Yup.string()
    .required('HSA Eligibility is required')
    .test('eligibleForHsa', 'HSA Eligibility is required', (eligible) => {
      return eligible === 'true' || eligible === 'false';
    }),
  selfEmployed: Yup.string()
    .required('Self Employment Status is required')
    .test(
      'selfEmployed',
      'Self Employment Status is required',
      (status) => status === 'true' || status === 'false'
    ),
};

const financialInfoWithSpouse = {
  ...financialInfoNoSpouse,
  spouseAnnualIncome: Yup.string().notRequired(),
  spouseAnnualOtherIncome: Yup.string().notRequired(),
  spouseCreditScore: Yup.number()
    .required("Spouse's Credit Score is required")
    .typeError("Spouse's Credit Score must be a number between 250 and 900")
    .test(
      'spouseCreditScore',
      "Spouse's Credit Score cannot be lower than 250",
      (score) => score >= 250
    )
    .test(
      'spouseCreditScore',
      "Spouse's Credit Score cannot be higher than 900",
      (score) => score <= 900
    ),
  spouseEligibleForHsa: Yup.string()
    .required("Spouse's HSA Eligibility is required")
    .test('spouseEligibleForHsa', "Spouse's HSA Eligibility is required", (eligible) => {
      return eligible === 'true' || eligible === 'false';
    }),
  spouseSelfEmployed: Yup.string()
    .required("Spouse's Self Employment Status is required")
    .test(
      'spouseSelfEmployed',
      "Spouse's Self Employment Status is required",
      (status) => status === 'true' || status === 'false'
    ),
};

const FinancialInfoEditView: React.FC<FinancialInfoEditProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
  includeSpouse,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const completePersonalDetails = useMutation(new Data.Users.Mutations.CompletePersonalDetails());
  const methods = useForm<Data.People.BasicFinancialFormData>({
    defaultValues: {
      ...currentStep.model,
      eligibleForHsa: currentStep.model.eligibleForHsa ?? false,
      selfEmployed: currentStep.model.selfEmployed ?? false,
      spouseEligibleForHsa: currentStep.model.spouseEligibleForHsa ?? false,
      spouseSelfEmployed: currentStep.model.spouseSelfEmployed ?? false,
      investmentPreference:
        currentStep.model.investmentPreference ?? Domain.InvestmentPreferencesEnum['Low Cost'],
    },
    resolver: yupResolver(
      Yup.object(includeSpouse ? financialInfoWithSpouse : financialInfoNoSpouse)
    ),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any) => {
    const annualIncome = cleanWholeNumberStr(values.annualIncome);
    const otherAnnualIncome = cleanWholeNumberStr(values.otherAnnualIncome);
    const eligibleForHsa = values.eligibleForHsa === 'true';
    const selfEmployed = values.selfEmployed === 'true';
    let spouseAnnualIncome;
    let spouseAnnualOtherIncome;
    let spouseEligibleForHsa;
    let spouseSelfEmployed;
    if (includeSpouse) {
      spouseAnnualIncome = cleanWholeNumberStr(values.spouseAnnualIncome);
      spouseAnnualOtherIncome = cleanWholeNumberStr(values.spouseAnnualOtherIncome);
      spouseEligibleForHsa = values.spouseEligibleForHsa === 'true';
      spouseSelfEmployed = values.spouseSelfEmployed === 'true';
    }
    return {
      annualIncome,
      otherAnnualIncome,
      eligibleForHsa,
      selfEmployed,
      spouseAnnualIncome,
      spouseAnnualOtherIncome,
      spouseEligibleForHsa,
      spouseSelfEmployed,
    };
  };

  useEffect(() => {
    if (completePersonalDetails.status === MutationStatus.Complete) {
      navigate('/personal-details/dependents');
      setLoading(false);
    }
  }, [completePersonalDetails.status]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setLoading(true);
    const cleanedValues = formatInputs(values);
    currentStep.save({ ...values, ...cleanedValues });
    await wizard.commitStep('financialInfo');
    if (editing && onBack) {
      onBack();
      setLoading(false);
      return;
    }
    completePersonalDetails.action();
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography className='title' color='primary' variant='h1'>
            {t('MyBasicFinancialInfo')}
          </Typography>
          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 6}>
              <FinancialInfoForm financialInfo={currentStep?.model} includeSpouse={includeSpouse} />
            </Grid>
          </Grid>
        </Box>
        <WizardFooter
          color={editing ? 'primary' : undefined}
          onBack={onBack}
          {...getWizardFooterProps(meta.nextLocaleKey, editing)}
        />
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'NextDependents',
  // nextUrl: 'dependents',
  showBack: true,
  showNext: true,
  hexSteps: {
    steps: 5,
    currentStep: 4,
  },
} satisfies LayoutMeta;

export default withLayoutMeta(FinancialInfoEditViewWrapper, meta);
