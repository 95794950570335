export const ApiKeys = {
  Account: 'api/account',
  Auth: 'api/auth',
  AuthToken: 'api/authToken',
  MobileAuthAxios: 'api/mobileAuthAxios',
  Organization: 'api/organization',
  Payment: 'api/payment',
  Person: 'api/person',
  PlatformSwitch: 'api/platformSwitch',
  Pricebook: 'api/pricebook',
  Terms: 'api/terms',
  UserConfig: 'api/userConfig',
};
