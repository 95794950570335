/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Data, Domain, Hooks, ObservableGuard } from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { firstValueFrom, map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import EmergencyGoalForm from './EmergencyGoalForm';
import useBasicGoalValidation from '../../../hooks/useBasicGoalValidation';
import { GoalFormTitle } from '../../../components/FormTitle';

export const EmergencyGoalEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useBasicGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const emergencyGoal = Hooks.useEmergencyFundGoal();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted && emergencyGoal.isResolved) {
      wizard.start({ id: emergencyGoal.value?.id, type: Domain.GoalTypeEnum['Emergency Fund'] });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [emergencyGoal.isResolved, isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from anEmergency step
        map((current) => emergencyGoal.isResolved && current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <EmergencyGoalEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface EmergencyGoalEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.BasicGoalFormData, Data.Goals.BasicGoalWizardParams>;
  wizard: Data.Goals.BasicGoalWizard;
  params: Data.Goals.BasicGoalWizardParams;
}

const EmergencyGoalEditView: React.FC<EmergencyGoalEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const navigate = useNavigate();
  const { formSchema, formatInputs } = useBasicGoalValidation(
    Domain.GoalTypeEnum['Emergency Fund']
  );
  const methods = useForm<Data.Goals.BasicGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/goals/emergency/${wizardParams?.id}`, { replace: true });
      navigate('/goals');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <GoalFormTitle editing={true} type={Domain.GoalTypeEnum['Emergency Fund']} />

          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 6}>
              <EmergencyGoalForm model={currentStep.model} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </Box>
        <WizardFooter
          color={editing ? 'primary' : undefined}
          disableBack={false}
          onBack={onBack}
          {...getWizardFooterProps(meta.nextLocaleKey, editing)}
        />
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'Save & Continue',
  title: 'Emergency',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(EmergencyGoalEditViewWrapper, meta);
