import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { TabPanel } from '../../../../components/Tabs';
import MostPopularCarsHeader from './MostPopularCarsHeader';
import { Hooks } from '@3nickels/data-modules';
import MostPopularCarsCard from './MostPopularCarsCard';

const MostPopularCarsView: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);
  const cars = Hooks.usePopularCars();
  const suvs = Hooks.usePopularSuvs();
  const trucks = Hooks.usePopularTrucks();
  const allVehicles = [...cars, ...suvs, ...trucks];
  const showUnavailableMessage = useMemo(() => {
    return allVehicles.some((x) => !x.fiveYearOwnershipCost || x.fiveYearOwnershipCost === 0);
  }, [allVehicles]);

  return (
    <Box>
      <Grid container flexDirection='column'>
        <MostPopularCarsHeader value={value} setValue={setValue} />
        <TabPanel value={value} index={0}>
          {cars.map((car) => (
            <MostPopularCarsCard data={car} />
          ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {suvs.map((suv) => (
            <MostPopularCarsCard data={suv} />
          ))}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {trucks.map((truck) => (
            <MostPopularCarsCard data={truck} />
          ))}
        </TabPanel>
        {showUnavailableMessage && (
          <Typography mb={1} variant='p12' color='primary'>
            {t('5YearOwnershipCostInfoUnavailable')}
          </Typography>
        )}
        <Typography variant='p12' color='primary'>
          {t('ImagesGoogleCostInfo')}
        </Typography>
      </Grid>
    </Box>
  );
};

const meta = {} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(MostPopularCarsView, meta);
