import { Grid, Typography } from '@mui/material';
import { repertoire } from '../../../../assets/png';
import { t } from 'i18next';

const RepertoireCard = () => {
  return (
    <Grid
      container
      px={2}
      height='100%'
      alignItems='center'
      justifyContent='space-between'
      gap='20px'>
      <Grid flex={1} display='flex' alignItems='center' textAlign='start'>
        <Typography variant='p20Bold' color='primary'>
          {t('KeepYourMoneyKnowHow')}
        </Typography>
      </Grid>
      <Grid flex={1} minWidth='50%'>
        <img src={repertoire} style={{ width: '100%', height: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default RepertoireCard;
