/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import React, { useEffect, useState } from 'react';
import { map } from 'rxjs';
import { Backdrop, Box, Grid, Stack, Typography } from '@mui/material';
import { Loading, useLoading } from '../../../../../hooks/useLoading';
import { FormLoader } from '../../../../../components/FormLoader';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormContent from '../../../../../components/form/FormContent';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { Spacing } from '../../../../../themes';
import TextInput from '../../../../../components/form/TextInput';
import { TabPanel, Tabs } from '../../../../../components/Tabs';
import { cleanWholeNumberStr } from '../../../../../helpers/utilityFunctions';

const rentSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  monthlyPayment: Yup.string().notRequired(),
  monthlyInsurance: Yup.string().notRequired(),
});

interface HomeRentEditViewWrapperProps extends EditViewProps {
  id?: number;
}

export const HomeRentEditViewWrapper: React.FC<HomeRentEditViewWrapperProps> = ({
  editing,
  onBack,
  id,
}) => {
  const { loading, currentStep, wizard } = Hooks.useRentWizard();
  const isStarted = useObservable(wizard.isStarted$);

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && ( // don't even mount until correct step is set
        <FormLoader loading={loading}>
          <HomeRentEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface HomeRentEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.RealEstate.RentBasicFormData, Data.RealEstate.RentWizardParams>;
  wizard: Data.RealEstate.RentWizard;
}

const HomeRentEditView: React.FC<HomeRentEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.RealEstate.RentBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(rentSchema),
  });
  const { formState, getValues } = methods;
  const { setLoading } = useLoading();
  const [value, setValue] = React.useState(0);

  const [monthlyInsurance, setMonthlyInsurance] = useState<number>(
    currentStep.model?.monthlyInsurance ?? 0
  );
  const [annualInsurance, setAnnualInsurance] = useState<number>(
    (currentStep.model?.monthlyInsurance ?? 0) * 12
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const formInsurance = cleanWholeNumberStr(getValues('monthlyInsurance')?.toString() ?? '');
    if (newValue === 0) {
      setMonthlyInsurance(formInsurance / 12);
    } else {
      setAnnualInsurance(formInsurance * 12);
    }
    setValue(newValue);
  };

  const formatInputs = (values: any) => {
    const monthlyPayment = cleanWholeNumberStr(values.monthlyPayment);
    let monthlyInsurance = cleanWholeNumberStr(values.monthlyInsurance);
    if (value === 1) {
      monthlyInsurance = monthlyInsurance / 12;
    }
    return { monthlyPayment, monthlyInsurance };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('basic');

      if (onBack) {
        onBack();
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' variant='p22Bold'>
                {t(currentStep.model.id ? 'EditRent' : 'AddRent')}
              </Typography>
              <Typography color='secondary' variant='p18SemiBold' mt={Spacing.xxxs}>
                {t('HousingInfo')}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={10}>
                  <Stack spacing={Spacing.xxs}>
                    <TextInput<Data.RealEstate.RentBasicFormData>
                      error={formState.errors.name !== undefined}
                      helperText={formState.errors.name?.message?.toString()}
                      defaultValue={currentStep.model?.name}
                      inputProps={{ maxLength: 64 }}
                      label={'HousingNickname'}
                      name='name'
                      autoFocus
                    />
                    <TextInput<Data.RealEstate.RentBasicFormData>
                      error={formState.errors.monthlyPayment !== undefined}
                      helperText={formState.errors.monthlyPayment?.message?.toString()}
                      defaultValue={currentStep.model?.monthlyPayment}
                      label={'MonthlyRentPayment'}
                      name='monthlyPayment'
                      type='dollar'
                      autoFocus
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Typography color='secondary' variant='p18SemiBold' mt={Spacing.sm}>
                {t('Other')}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={10}>
                  <Stack spacing={Spacing.xxs}>
                    <Tabs labels={['Monthly', 'Annual']} value={value} onChange={handleChange} />
                    <TabPanel value={value} index={0}>
                      <TextInput<Data.RealEstate.RentBasicFormData>
                        error={formState.errors.monthlyInsurance !== undefined}
                        helperText={formState.errors.monthlyInsurance?.message?.toString()}
                        defaultValue={monthlyInsurance}
                        label={'RentersInsurance'}
                        name='monthlyInsurance'
                        type='dollar'
                        autoFocus
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <TextInput<Data.RealEstate.RentBasicFormData>
                        error={formState.errors.monthlyInsurance !== undefined}
                        helperText={formState.errors.monthlyInsurance?.message?.toString()}
                        defaultValue={annualInsurance}
                        label={'RentersInsurance'}
                        name='monthlyInsurance'
                        type='dollar'
                        autoFocus
                      />
                    </TabPanel>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('save', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};
