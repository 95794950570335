import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Colors, Spacing } from '../../../themes';
import { Api, Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SliderPercent from '../../../components/form/SliderPercent';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import { t } from 'i18next';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import HelpText from '../../../components/HelpText';
import { Spacer } from '../../../components';

export type RetirementGoalFormProps = {
  ageBoundary: Api.RetirementAgeBoundRest;
  includeSpouse: boolean;
  model?: Data.Goals.RetirementGoalFormData;
  onSubmit: (values: Data.Goals.RetirementGoalFormData) => Promise<void>;
};

const RetirementGoalForm: React.FC<RetirementGoalFormProps> = (props) => {
  const { formState } = useFormContext();
  const { ageBoundary, includeSpouse, model } = props;
  const titleStyle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: Colors.secondaryBase,
    textDecoration: 'none',
  };

  return (
    <Box>
      <Typography variant='p18Bold' color='secondary' mt={1}>
        {t('RetirementAge')}
      </Typography>
      <Grid container mt={1.5} justifyContent='center'>
        <Grid item sm={10}>
          <Card className='content-card'>
            <Grid item xs>
              <Stack spacing={Spacing.xxs}>
                <SliderPercent<Data.Goals.RetirementGoalFormData>
                  error={formState.errors.retirementAge !== undefined}
                  helperText={formState.errors.retirementAge?.message?.toString()}
                  name='retirementAge'
                  label='MyRetirementAge'
                  min={ageBoundary?.primary?.minimumRetirementAge}
                  max={ageBoundary?.primary?.maximumRetirementAge}
                  defaultValue={model?.retirementAge}
                  type='number'
                  placeholder='0'
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      if (e.key === '.' || e.key === 'Decimal') {
                        e.preventDefault();
                      }
                    },
                  }}
                />
                {includeSpouse && (
                  <SliderPercent<Data.Goals.RetirementGoalFormData>
                    error={formState.errors.spouseRetirementAge !== undefined}
                    helperText={formState.errors.spouseRetirementAge?.message?.toString()}
                    defaultValue={model?.spouseRetirementAge}
                    label='SpouseRetirementAge'
                    min={ageBoundary?.spouse?.minimumRetirementAge}
                    max={ageBoundary?.spouse?.maximumRetirementAge}
                    name='spouseRetirementAge'
                    type='number'
                    placeholder='0'
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                        if (e.key === '.' || e.key === 'Decimal') {
                          e.preventDefault();
                        }
                      },
                    }}
                  />
                )}
              </Stack>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <br />
      <IncomeReplacementHelp titleStyle={titleStyle} />
      <Grid container mt={1.5} justifyContent='center'>
        <Grid item sm={10}>
          <Card className='content-card'>
            <Grid item xs>
              <Stack spacing={Spacing.xxs}>
                <SliderPercent<Data.Goals.RetirementGoalFormData>
                  error={formState.errors.incomeReplacementPercent !== undefined}
                  helperText={formState.errors.incomeReplacementPercent?.message?.toString()}
                  name='incomeReplacementPercent'
                  label='PercentageOfCurrentIncome'
                  defaultValue={model?.incomeReplacementPercent}
                  type='percent'
                  precision={0}
                  min={5}
                  max={200}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      if (e.key === '.' || e.key === 'Decimal') {
                        e.preventDefault();
                      }
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <br />
      <SocialSecurityHelp titleStyle={titleStyle} />
      <Grid container mt={1.5} justifyContent='center'>
        <Grid item sm={10}>
          <Card className='content-card' style={{ flexDirection: 'column' }}>
            <Grid width='100%'>
              <Stack spacing={2}>
                <ToggleRadioButtonGroup<Data.Goals.RetirementGoalFormData>
                  helperText={formState.errors.includeSsi?.message?.toString()}
                  name='includeSsi'
                  label='IncludeYourEstimatedSSI'
                  row
                  defaultValue={model?.includeSsi?.toString()}
                  items={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                />
                {includeSpouse && (
                  <ToggleRadioButtonGroup<Data.Goals.RetirementGoalFormData>
                    error={formState.errors.spouseIncludeSsi !== undefined}
                    helperText={formState.errors.spouseIncludeSsi?.message?.toString()}
                    name='spouseIncludeSsi'
                    label='IncludeSpousesEstimatedSSI'
                    row
                    defaultValue={model?.spouseIncludeSsi?.toString()}
                    items={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                  />
                )}
              </Stack>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RetirementGoalForm;

interface RetirementHelpPopoverProps {
  titleStyle: React.CSSProperties;
}

export const IncomeReplacementHelp: React.FC<RetirementHelpPopoverProps> = ({ titleStyle }) => {
  return (
    <HelpText
      text={t('IncomeReplacement')}
      textStyle={titleStyle}
      content={
        <HelpPopover title={t('IncomeReplacement')}>
          <Spacer height='xxxs' />
          <Stack spacing={2}>
            <HelpPopoverText>
              Most financial planners suggest that you’ll need to plan to replace from 70% to 90% of
              your pre-retirement income, depending upon a variety of factors from lifestyle to
              health.
            </HelpPopoverText>
            <HelpPopoverText style={{ fontWeight: 'bold' }}>
              To make it simple, we recommend setting a target of 80%.
            </HelpPopoverText>
            <HelpPopoverText>
              To see how you’re doing in relation to your target, we determine the amount of income
              you may expect each month in an average market (probability: 2 out of 3) and compare
              it against your current income.
            </HelpPopoverText>
          </Stack>
        </HelpPopover>
      }
    />
  );
};

export const SocialSecurityHelp: React.FC<RetirementHelpPopoverProps> = ({ titleStyle }) => {
  return (
    <HelpText
      text={t('SocialSecurityIncome')}
      textStyle={titleStyle}
      content={
        <HelpPopover title={t('SocialSecurityIncome')}>
          <Stack spacing={2}>
            <HelpPopoverText>
              <Spacer height='xxxs' />
              By default, we estimate SSI and include it in your monthly retirement income amount.
            </HelpPopoverText>
            <HelpPopoverText>
              If you’d like to see your results without SSI, feel free to toggle it “off.”
            </HelpPopoverText>
            <HelpPopoverText style={{ fontStyle: 'italic' }}>
              Note: Social Security estimates are before-tax.
            </HelpPopoverText>
          </Stack>
        </HelpPopover>
      }
    />
  );
};
