import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { useNeedsOnboarding } from '.';

function useAdviceResultsNextInvestmentAccount() {
  const navigate = useNavigate();
  const onboarding = useNeedsOnboarding();

  const nextInvestmentAccount = (nextId?: number) => {
    if (nextId) {
      // open a brokerage account
      if (nextId === 2) {
        navigate('/holistic-advice/investments/brokerage');
      }
      // open an ira
      else if (nextId === 4) {
        navigate('/holistic-advice/investments/ira');
      } else {
        navigate(`/holistic-advice/investments/${nextId}`);
      }
    } else if (onboarding) {
      navigate('/holistic-advice/plan/');
    } else {
      // skip start view if not in onboarding
      navigate('/holistic-advice/plan/outline');
    }
  };

  return nextInvestmentAccount;
}

export default useAdviceResultsNextInvestmentAccount;
