import React, { CSSProperties } from 'react';
import { HexagonRotated } from './Hexagon';
import { StateHexagon } from './StateHexagon';
import { Box, Grid, Typography } from '@mui/material';
import { Colors } from '../themes';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { StateHexagonContainer } from '../themes/mui';

interface ChartHoneycombProps {
  columns: number;
  data: ChartHoneycombData[];
  style?: CSSProperties;
}

export interface ChartHoneycombData {
  positionIndex: number;
  color: string;
  text: string;
  textColor: string;
  stateName: string;
  stateValue: string;
}

export const ChartHoneycomb: React.FC<ChartHoneycombProps> = (props) => {
  const { width } = useWindowDimensions();
  const { columns, data } = props;
  const hexWidth = width > 2000 ? 2000 / 2.5 / columns : width / 2.5 / columns;
  const hexHeight = (hexWidth * 2) / Math.sqrt(3);
  const hex = new HexagonRotated(hexHeight / 2, hexHeight / 2, hexHeight / 2);

  const chartHeight =
    Math.ceil(Math.max(...data.map((x) => x.positionIndex)) / props.columns) * hexHeight;

  const mapped = data.map((h) => {
    const { rowIndex, columnIndex } = getRowAndColumnIndex(h.positionIndex, columns);

    const longRow = rowIndex % 2 === 0;
    const xOffset = hexWidth * (longRow ? 0.5 : 1);
    const yOffset = hex.height / 2;
    const xOrigin = xOffset + hexWidth * columnIndex;
    const yOrigin = yOffset + hex.sideLen * 1.5 * rowIndex;

    return {
      xOrigin,
      yOrigin,
      positionIndex: h.positionIndex,
      text: h.text,
      color: h.color,
      textColor: h.textColor,
      stateName: h.stateName,
      stateValue: h.stateValue,
    };
  });

  return (
    <Grid style={{ height: chartHeight - 50, position: 'relative' }}>
      {mapped.map((h, i) => (
        <Box sx={StateHexagonContainer} key={i.toString()}>
          <div className='content'>
            <StateHexagon
              hexagon={hex}
              origin={{ x: h.xOrigin, y: h.yOrigin }}
              hexFill={h.color}
              hexStroke={Colors.tertiaryBase}
              svgStrokeOffset={0}
              stateName={h.stateName}
              stateValue={h.stateValue}
            />
            <Typography
              sx={{
                position: 'absolute',
                justifyContent: 'center',
                left: h.xOrigin - hexHeight / 2,
                top: h.yOrigin - hexHeight / 2,
                width: hexHeight,
                height: hexHeight,
              }}>
              <Typography
                sx={{
                  'marginRight': 0.5,
                  'color': h.textColor,
                  'fontSize': hex.height / 3,
                  'fontWeight': '700',
                  'justifyContent': 'center',
                  'zIndex': 9,
                  '&:hover': {
                    cursor: 'default',
                  },
                }}>
                {h.text}
              </Typography>
            </Typography>
          </div>
        </Box>
      ))}
    </Grid>
  );
};

function getRowAndColumnIndex(
  index: number,
  columns: number
): { rowIndex: number; columnIndex: number } {
  const longAndShortRowColumns = columns * 2 - 1;

  const longAndShortRowIndex = Math.floor(index / longAndShortRowColumns);
  const longAndShortRowColumnIndex = index % longAndShortRowColumns;

  const rowIndex =
    longAndShortRowColumnIndex < columns ? longAndShortRowIndex * 2 : longAndShortRowIndex * 2 + 1;
  const columnIndex =
    longAndShortRowColumnIndex < columns
      ? longAndShortRowColumnIndex
      : longAndShortRowColumnIndex - columns;

  return { rowIndex, columnIndex };
}
