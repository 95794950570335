import { Data, Domain } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { IAppStorage, ScriniumServices } from '@aesop-fables/scrinium';
import { json, redirect } from 'react-router';
import { firstValueFrom } from 'rxjs';
import { waitFor } from '../utils';

export async function learningLoader(container: IServiceContainer) {
  const appStorage = container.get<IAppStorage>(ScriniumServices.AppStorage);
  const lmsCache = appStorage.retrieve<Data.Lms.LmsCompartments>(Data.Lms.lmsStorageKey);
  const allCourses$ = lmsCache.observe$<Domain.CourseDto[] | undefined>('allCourses');

  await waitFor(lmsCache.initialized$);
  const allCourses = await firstValueFrom(allCourses$);

  if ((allCourses?.length ?? 0) === 0) {
    return redirect('/');
  }

  return json('lms enabled');
}
