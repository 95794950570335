import { Grid, Stack, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { accentBase } from '../../../../themes/colors';

export const UsefulTip: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.ArtLightbulbMedium />
        <Grid ml={3}>
          <Typography color='secondary' variant='p18Bold'>
            {t('UsefulTip')}
          </Typography>
        </Grid>
      </Row>
      <Row>
        <Typography display='inline' variant='p16Bold' color='secondary'>
          {t('TheSecondNumber')}&nbsp;
        </Typography>
        <Typography display='inline' variant='p16' color='secondary'>
          {t('CoverAllAssets')}
        </Typography>
      </Row>
      <Typography sx={{ color: accentBase, alignSelf: 'center' }} variant='p24Bold'>
        100/300/100
      </Typography>
    </Stack>
  );
};
