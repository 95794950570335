import { useState, useEffect } from 'react';

export type DevicePlatform = 'Windows Phone' | 'Android' | 'iOS' | 'unknown';

interface ExtendedWindow extends Window {
  opera?: string;
  MSStream?: unknown;
}

export const useDevicePlatform = (): DevicePlatform => {
  const [os, setOs] = useState<DevicePlatform>('unknown');

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as ExtendedWindow).opera || '';

    if (/windows phone/i.test(userAgent)) {
      setOs('Windows Phone');
    } else if (/android/i.test(userAgent)) {
      setOs('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as ExtendedWindow).MSStream) {
      setOs('iOS');
    }
  }, []);

  return os;
};
