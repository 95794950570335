import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SliderPercent from '../../../../components/form/SliderPercent';
import { useTranslation } from 'react-i18next';
import { WizardStep } from '@aesop-fables/scrinium';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';

export type RetirementPlanContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.AccountContributionsFormData;
  eligibility: Domain.AccountEligibility;
  onEligibilityChange?: (isEligible: boolean, spouseIncome: number) => void;
  eligibleForContributions?: boolean;
  setEligibleForContributions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  spouseEligibleZeroIncome: boolean;
  isSpouse?: boolean;
  currentStep: WizardStep<
    Data.InvestmentAccounts.AccountContributionsFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  editing?: boolean;
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const RetirementPlanContributionsForm: React.FC<RetirementPlanContributionsFormProps> = ({
  contributions,
  eligibility,
  onEligibilityChange,
  eligibleForContributions,
  setEligibleForContributions,
  spouseEligibleZeroIncome,
  isSpouse,
  currentStep,
  editing,
  params,
  setDisableNext,
}) => {
  const { formState, watch } = useFormContext();
  const { showMessage } = useMessage();
  const selfData = Hooks.usePersonData();
  const spouseData = Hooks.useSpouseData();
  const [paycheckContributions, setPaycheckContributions] = useState<boolean | undefined>(
    undefined
  );
  const [contributionType, setContributionType] = useState<
    Domain.EligibleContributionTypeEnum | undefined
  >();
  const [rothContributionsAllowed, setRothContributionsAllowed] = useState<boolean | undefined>(
    undefined
  );
  const [afterTaxContributionsAllowed, setAfterTaxContributionsAllowed] = useState<
    boolean | undefined
  >(undefined);
  const percentContributionFields = watch([
    'annualContribPreTaxPercent',
    'annualContribRothPercent',
    'annualContribAfterTaxPercent',
  ]);
  const dollarContributionFields = watch([
    'annualContribPreTaxDollar',
    'annualContribRothDollar',
    'annualContribAfterTaxDollar',
  ]);
  const anyEligibility = useMemo(
    () =>
      eligibility.paycheckContribution?.preTax ||
      eligibility.paycheckContribution?.roth ||
      eligibility.paycheckContribution?.postTax,
    [eligibility]
  );
  const undefinedPaycheckContrib = !editing && params.institutionalAccount;

  const handleSpouseEligible = (value: string) => {
    const isEligible = value === 'true';
    const spouseIncome = spouseData?.annualIncome ?? 0;
    onEligibilityChange && onEligibilityChange(isEligible, spouseIncome);
  };

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      switch (name) {
        case 'eligibleForContributions': {
          const eligible = values[name];
          setEligibleForContributions(eligible === 'true');
          break;
        }
        case 'paycheckContributions': {
          const contributing = values[name];
          setPaycheckContributions(contributing === 'true');
          break;
        }
        case 'contributionType': {
          const type = values[name];
          setContributionType(type);
          break;
        }
        case 'rothContributionsAllowed': {
          const allowed = values[name];
          setRothContributionsAllowed(allowed === 'true');
          break;
        }
        case 'afterTaxContributionsAllowed': {
          const allowed = values[name];
          setAfterTaxContributionsAllowed(allowed === 'true');
          break;
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setEligibleForContributions(contributions?.eligibleForContributions);
    setPaycheckContributions(
      undefinedPaycheckContrib ? undefined : contributions?.paycheckContributions
    );
    setContributionType(contributions?.contributionType);
    setRothContributionsAllowed(contributions?.rothContributionsAllowed);
    setAfterTaxContributionsAllowed(contributions?.afterTaxContributionsAllowed);
  }, []);

  useEffect(() => {
    const model = currentStep?.model;
    if (
      typeof eligibleForContributions !== 'undefined' &&
      model.eligibleForContributions !== eligibleForContributions
    ) {
      currentStep?.save({ ...model, eligibleForContributions });
    }
  }, [eligibleForContributions]);

  useEffect(() => {
    const annualIncome = isSpouse ? spouseData?.annualIncome : selfData?.annualIncome;
    const contributionFields =
      contributionType === Domain.EligibleContributionTypeEnum.Dollar
        ? dollarContributionFields
        : percentContributionFields;

    let total = 0;
    // only consider currently allowed contribution types
    eligibleForContributions && (total += cleanWholeNumberStr(contributionFields[0] || ''));
    rothContributionsAllowed && (total += cleanWholeNumberStr(contributionFields[1] || ''));
    afterTaxContributionsAllowed && (total += cleanWholeNumberStr(contributionFields[2] || ''));

    if (contributionType === Domain.EligibleContributionTypeEnum.Percent && total > 100) {
      showMessage(`Total contributions shouldn't be more than 100%`, 'error');
      setDisableNext(true);
    } else if (
      contributionType === Domain.EligibleContributionTypeEnum.Dollar &&
      annualIncome &&
      total > annualIncome
    ) {
      showMessage(`Total contributions shouldn't be more than annual income`, 'error');
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [
    contributionType,
    dollarContributionFields,
    percentContributionFields,
    eligibleForContributions,
    rothContributionsAllowed,
    afterTaxContributionsAllowed,
    selfData?.annualIncome,
  ]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        {!params.institutionalAccount && anyEligibility && (
          <ToggleRadioButtonGroup<Data.InvestmentAccounts.AccountContributionsFormData>
            error={formState.errors.eligibleForContributions !== undefined}
            helperText={formState.errors.eligibleForContributions?.message?.toString()}
            name='eligibleForContributions'
            label={
              isSpouse ? 'SpouseEligibleToMakeContributions' : 'AreYouEligibleToMakeContributions'
            }
            row
            defaultValue={contributions?.eligibleForContributions?.toString()}
            items={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            onChange={(event) =>
              isSpouse && onEligibilityChange && handleSpouseEligible(event.value)
            }
          />
        )}

        {eligibleForContributions && !spouseEligibleZeroIncome && (
          <ToggleRadioButtonGroup<Data.InvestmentAccounts.AccountContributionsFormData>
            error={formState.errors.paycheckContributions !== undefined}
            helperText={formState.errors.paycheckContributions?.message?.toString()}
            name='paycheckContributions'
            label={
              isSpouse ? 'SpouseMakingPaycheckContributions' : 'AreYouMakingPaycheckContributions'
            }
            row
            defaultValue={paycheckContributions?.toString()}
            items={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
        )}
        {(typeof eligibleForContributions !== 'undefined' || !anyEligibility) &&
          !spouseEligibleZeroIncome && (
            <ContributionForm
              contributions={contributions}
              contributionType={contributionType}
              taxTypes={eligibility.eligiblePlanTaxTypes}
              rothContributionsAllowed={rothContributionsAllowed}
              afterTaxContributionsAllowed={afterTaxContributionsAllowed}
              eligibleForContributions={eligibleForContributions}
              paycheckContributions={paycheckContributions}
              institutionalAccount={params.institutionalAccount}
              isSpouse={isSpouse}
            />
          )}
      </Stack>
    </Box>
  );
};

interface ContributionFormProps {
  contributions?: Data.InvestmentAccounts.AccountContributionsFormData;
  contributionType?: Domain.EligibleContributionTypeEnum;
  taxTypes?: Domain.EligiblePlanTaxTypes;
  rothContributionsAllowed?: boolean;
  afterTaxContributionsAllowed?: boolean;
  eligibleForContributions?: boolean;
  paycheckContributions?: boolean;
  institutionalAccount?: boolean;
  isSpouse?: boolean;
}

const ContributionForm: React.FC<ContributionFormProps> = ({
  contributions,
  contributionType,
  taxTypes,
  rothContributionsAllowed,
  afterTaxContributionsAllowed,
  eligibleForContributions,
  paycheckContributions,
  institutionalAccount,
  isSpouse,
}) => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const planData = Hooks.usePlan();

  // ensure boolean type
  if (typeof paycheckContributions === 'string') {
    paycheckContributions = paycheckContributions === 'true';
  }

  return (
    <>
      {eligibleForContributions &&
        typeof paycheckContributions !== 'undefined' &&
        (institutionalAccount
          ? planData.allowedContribMethod === Domain.EligibleContributionTypeEnum.Both
          : true) && (
          <ToggleRadioButtonGroup<Data.InvestmentAccounts.AccountContributionsFormData>
            error={formState.errors.contributionType !== undefined}
            helperText={formState.errors.contributionType?.message?.toString()}
            name='contributionType'
            label='AreContributionsAPercentageOfPayOrDollarAmount'
            row
            defaultValue={contributionType ?? contributions?.contributionType}
            items={[
              { label: '% of Pay', value: Domain.EligibleContributionTypeEnum.Percent },
              { label: 'Fixed $ Amount', value: Domain.EligibleContributionTypeEnum.Dollar },
            ]}
          />
        )}
      {(typeof contributionType !== 'undefined' || !eligibleForContributions) && (
        <>
          {(typeof paycheckContributions !== 'undefined' || !eligibleForContributions) && (
            <>
              <Stack
                spacing={Spacing.xxxs}
                style={{ marginBottom: paycheckContributions ? '' : -Spacing.xl }}>
                {paycheckContributions &&
                  eligibleForContributions &&
                  typeof contributionType !== 'undefined' && (
                    <>
                      <Typography variant='p18Bold' color='secondary'>
                        {t('Pre-Tax')}
                      </Typography>
                      <Typography color='secondary'>{t('ContributingPreTax?')}</Typography>
                    </>
                  )}
                {!eligibleForContributions && (
                  <Typography
                    variant='p16Bold'
                    color='secondary'
                    style={{ marginBottom: paycheckContributions ? -Spacing.lg : Spacing.xs }}>
                    {t(isSpouse ? 'WhenSpouseWasEligible' : 'WhenYouWereEligible')}:
                  </Typography>
                )}
                {eligibleForContributions && paycheckContributions && (
                  <Grid style={{ marginTop: Spacing.md }}>
                    <ContributionInput
                      contributions={contributions}
                      contributionType={contributionType}
                      label='AnnualPreTaxSavings'
                      dollarName='annualContribPreTaxDollar'
                      percentName='annualContribPreTaxPercent'
                    />
                  </Grid>
                )}
              </Stack>
              {taxTypes?.roth &&
                ((institutionalAccount && paycheckContributions && rothContributionsAllowed) ||
                  !institutionalAccount) && (
                  <>
                    <Stack spacing={Spacing.xxxs}>
                      {eligibleForContributions && (
                        <Typography variant='p18Bold' color='secondary'>
                          {t('Roth')}
                        </Typography>
                      )}
                      {!institutionalAccount && (
                        <ToggleRadioButtonGroup<Data.InvestmentAccounts.AccountContributionsFormData>
                          error={formState.errors.rothContributionsAllowed !== undefined}
                          helperText={formState.errors.rothContributionsAllowed?.message?.toString()}
                          name='rothContributionsAllowed'
                          label={
                            eligibleForContributions
                              ? 'AreRothContributionsAllowed'
                              : 'WereRothContributionsAllowed'
                          }
                          row
                          defaultValue={
                            contributions?.rothContributionsAllowed?.toString() ??
                            (rothContributionsAllowed === undefined
                              ? ''
                              : rothContributionsAllowed
                              ? 'true'
                              : 'false')
                          }
                          items={[
                            { label: 'Yes', value: 'true' },
                            { label: 'No', value: 'false' },
                          ]}
                        />
                      )}
                      {eligibleForContributions &&
                        paycheckContributions &&
                        rothContributionsAllowed && (
                          <Grid style={{ marginTop: Spacing.md }}>
                            <ContributionInput
                              contributions={contributions}
                              contributionType={contributionType}
                              label='AnnualRothSavings'
                              dollarName='annualContribRothDollar'
                              percentName='annualContribRothPercent'
                            />
                          </Grid>
                        )}
                    </Stack>
                  </>
                )}
              {taxTypes?.postTax &&
                ((institutionalAccount && paycheckContributions && afterTaxContributionsAllowed) ||
                  !institutionalAccount) && (
                  <>
                    <Stack spacing={Spacing.xxxs}>
                      {eligibleForContributions && (
                        <Typography variant='p18Bold' color='secondary'>
                          {t('After-Tax')}
                        </Typography>
                      )}
                      {!institutionalAccount && (
                        <ToggleRadioButtonGroup<Data.InvestmentAccounts.AccountContributionsFormData>
                          error={formState.errors.afterTaxContributionsAllowed !== undefined}
                          helperText={formState.errors.afterTaxContributionsAllowed?.message?.toString()}
                          name='afterTaxContributionsAllowed'
                          label={
                            eligibleForContributions
                              ? 'AreAfterTaxContributionsAllowed'
                              : 'WereAfterTaxContributionsAllowed'
                          }
                          row
                          defaultValue={
                            contributions?.afterTaxContributionsAllowed?.toString() ??
                            (afterTaxContributionsAllowed === undefined
                              ? ''
                              : afterTaxContributionsAllowed
                              ? 'true'
                              : 'false')
                          }
                          items={[
                            { label: 'Yes', value: 'true' },
                            { label: 'No', value: 'false' },
                          ]}
                        />
                      )}
                      {eligibleForContributions &&
                        paycheckContributions &&
                        afterTaxContributionsAllowed && (
                          <Grid style={{ marginTop: Spacing.md }}>
                            <ContributionInput
                              contributions={contributions}
                              contributionType={contributionType}
                              label='AnnualAfterTaxSavings'
                              dollarName='annualContribAfterTaxDollar'
                              percentName='annualContribAfterTaxPercent'
                            />
                          </Grid>
                        )}
                    </Stack>
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

interface ContributionInputProps {
  contributions?: Data.InvestmentAccounts.AccountContributionsFormData;
  contributionType?: Domain.EligibleContributionTypeEnum;
  label: string;
  percentName: keyof Data.InvestmentAccounts.AccountContributionsFormData;
  dollarName: keyof Data.InvestmentAccounts.AccountContributionsFormData;
}

const ContributionInput: React.FC<ContributionInputProps> = ({
  contributions,
  contributionType,
  label,
  percentName,
  dollarName,
}) => {
  const { formState } = useFormContext();

  if (contributionType === Domain.EligibleContributionTypeEnum.Dollar) {
    return (
      <TextInput<Data.InvestmentAccounts.AccountContributionsFormData>
        error={formState.errors[dollarName] !== undefined}
        helperText={formState.errors[dollarName]?.message?.toString()}
        defaultValue={contributions?.[dollarName]}
        label={label}
        name={dollarName}
        type='dollar'
      />
    );
  } else if (contributionType === Domain.EligibleContributionTypeEnum.Percent) {
    return (
      <SliderPercent<Data.InvestmentAccounts.AccountContributionsFormData>
        error={formState.errors[percentName] !== undefined}
        helperText={formState.errors[percentName]?.message?.toString()}
        name={percentName}
        label={label}
        defaultValue={contributions?.[percentName]}
        precision={0}
      />
    );
  }

  return null;
};

export default RetirementPlanContributionsForm;
