import { createServiceModule, Scopes } from '@aesop-fables/containr';
import {
  DefaultPaymentMethod,
  PaymentHistory,
  PaymentMethods,
  PaymentServices,
} from '../../data/payment';

export const usePaymentServices = createServiceModule(
  '@3nickels/web/payment/services',
  (services) => {
    services.autoResolve(PaymentServices.PaymentMethods, PaymentMethods, Scopes.Transient);
    services.autoResolve(PaymentServices.PaymentHistory, PaymentHistory, Scopes.Transient);
    services.autoResolve(
      PaymentServices.DefaultPaymentMethod,
      DefaultPaymentMethod,
      Scopes.Transient
    );
  }
);
