import { Card, CardContent, CardHeader, Collapse, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Alignments, Colors } from '../../themes';

interface CardDisplayProps {
  headerColor?: string;
  linkKey?: string;
  linkUrl?: string;
  headerKey: string;
  headerLabelColor?: string;
  collapsible?: boolean;
  elevation?: number;
  children: React.ReactNode;
  actionLabelColor?: string;
  // actionContent?: string;
  actionContent?: React.ReactNode;
  actionUrl?: string;
  actionClick?: () => void;
  disableAction?: boolean;
  summary?: React.ReactNode;
}

const CardAction: React.FC<CardDisplayProps> = (props) => {
  const { t } = useTranslation();
  if (props.collapsible && props.actionContent && props.actionClick) {
    return (
      <Link
        className='card-action'
        style={{
          color: props.actionLabelColor,
          display: 'block',
          marginRight: '5px',
          marginTop: '4px',
        }}
        to={props.actionUrl as string}
        onClick={props.actionClick}>
        {props.actionContent}
      </Link>
    );
  }

  if (props.actionUrl && props.actionContent) {
    return (
      <Link
        className='card-action'
        style={{
          color: props.actionLabelColor,
          display: 'block',
          marginRight: '5px',
          marginTop: '4px',
          fontSize: '12px',
        }}
        to={props.actionUrl}>
        {props.actionContent}
      </Link>
    );
  }

  return (
    <Typography
      className='clickable'
      style={{
        color: props.actionLabelColor ?? Colors.primaryBase,
        display: 'block',
        marginRight: '5px',
        marginTop: '4px',
      }}
      onClick={props.actionClick}>
      {t(props.actionContent as string)}
    </Typography>
  );
};

const CardDisplay: React.FC<CardDisplayProps> = (props) => {
  const [showDetails, setShowDetails] = React.useState(props.collapsible ? false : true);
  const { t } = useTranslation();

  const actionProps: CardDisplayProps = {
    ...props,
    actionClick: props.collapsible
      ? () =>
          setShowDetails((previous) => {
            return !previous;
          })
      : props.actionClick,
    actionContent: props.collapsible ? (
      showDetails ? (
        <Grid item sx={[Alignments.verticalAlignCenter]}>
          {t('Hide Details')} <KeyboardArrowUpIcon fontSize='large' />
        </Grid>
      ) : (
        <Grid item sx={[Alignments.verticalAlignCenter]}>
          {t('Show Details')} <KeyboardArrowDownIcon fontSize='large' />
        </Grid>
      )
    ) : (
      props.actionContent
    ),
  };

  return (
    <Card className='card-display' elevation={props.elevation}>
      <CardHeader
        color={props.headerLabelColor}
        style={{ backgroundColor: props.headerColor }}
        action={props.disableAction ? undefined : <CardAction {...actionProps} />}
        disableTypography={true}
        title={
          <Typography component='h2' variant='p18Bold' color={props.headerLabelColor}>
            {t(props.headerKey)}
          </Typography>
        }
      />
      <Collapse in={showDetails}>
        <CardContent>{props.children}</CardContent>
      </Collapse>
      <Collapse in={!showDetails}>
        <CardContent>{props.summary}</CardContent>
      </Collapse>
    </Card>
  );
};

export default CardDisplay;
