import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api, Hooks } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { Loading } from '../../../hooks/useLoading';
import AuthMethodCard from './AuthMethodCard';
import { ChangeMethodModal, RemoveMethodModal } from './AuthMethodModals';
import AddAuthMethodRow from './AddAuthMethodRow';

export const TwoFactorMethodsWrapper: React.FC = () => {
  const twoFactorMethods = Hooks.useTwoFactorMethods();

  if (!twoFactorMethods || twoFactorMethods.length === 0) {
    return <Loading />;
  }

  return <TwoFactorMethods twoFactorMethods={twoFactorMethods} />;
};

interface TwoFactorAuthViewProps {
  twoFactorMethods: Api.AuthTwoFactorData[];
}

const TwoFactorMethods: React.FC<TwoFactorAuthViewProps> = ({ twoFactorMethods }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [removeMethodModalOpen, setRemoveMethodModalOpen] = useState(false);
  const [changeMethodModalOpen, setChangeMethodModalOpen] = useState(false);
  const [selected, setSelected] = useState<Api.AuthTwoFactorData | undefined>(undefined);

  const confirmDelete = (method: Api.AuthTwoFactorData) => {
    setSelected(method);
    setRemoveMethodModalOpen(true);
  };

  const onAdd = () => {
    navigate('/settings/two-factor-authentication/add');
  };

  return (
    <>
      <RemoveMethodModal
        selected={selected}
        modalOpen={removeMethodModalOpen}
        setModalOpen={setRemoveMethodModalOpen}
      />
      <ChangeMethodModal
        methods={twoFactorMethods}
        modalOpen={changeMethodModalOpen}
        setModalOpen={setChangeMethodModalOpen}
      />
      <Typography mt={3} color='secondary' variant='p18Bold'>
        {t('DefaultMethod')}
      </Typography>
      <Grid item sm={10}>
        <AuthMethodCard
          variant='default'
          method={twoFactorMethods.find((method) => method.defaultSelection) ?? twoFactorMethods[0]}
          totalMethods={twoFactorMethods.length}
          setModalOpen={setChangeMethodModalOpen}
        />
      </Grid>
      <Typography mt={3} color='secondary' variant='p18Bold'>
        {t('AllSavedMethods')}
      </Typography>
      <Grid item sm={10}>
        {twoFactorMethods.map((method, index) => {
          return (
            <AuthMethodCard key={index} variant='saved' method={method} onDelete={confirmDelete} />
          );
        })}
        <AddAuthMethodRow onAdd={onAdd} />
      </Grid>
    </>
  );
};

export default TwoFactorMethods;
