import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { houseLandscape } from '../../../../assets/png';
import { useTranslation } from 'react-i18next';
import InformationTable, { InformationTableData } from '../../../../components/InformationTable';

const CollegeSavingsPlansView: React.FC = () => {
  const { t } = useTranslation();

  const tableData: InformationTableData = {
    headers: {
      column: [t('UGMA/UTMA'), t('529EducationSavingsPlan'), t('529PrePaidTuitionPlan')],
      row: [
        t('HowDoesItWork'),
        t('WhoDoesTheAccountBelongTo'),
        t('WhatAreTheTaxImplications'),
        t('WhatAreTheInvestmentOptions'),
        t('WhatAreTheWithdrawalRequirements'),
      ],
    },
    content: [
      [
        t('TheseAreConsideredCustodial'),
        t('EachStateHasTheirOwn'),
        t('UsuallyCalledPrePaidTuition'),
      ],
      [t('TheMinor'), t('TypicallyTheParent'), t('TypicallyTheParent')],
      [
        t('HalfTheInvestmentIncome'),
        t('AlthoughContributionsAfterTax'),
        t('AlthoughContributionsAfterTaxFederalState'),
      ],
      [t('SavingsCanBeInvested'), t('TheAccountInvestedAgeBased'), t('None')],
      [t('TheFundsSpentInAnyWay'), t('MustBeUsedForEducation'), t('UsuallyOnlyCoversTuition')],
    ],
  };

  return <InformationTable data={tableData} />;
};

const meta = {
  background: (
    <img src={houseLandscape} className='tools-start-view-background' alt='House landscape' />
  ),
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CollegeSavingsPlansView, meta);
