import { Hooks } from '@3nickels/data-modules';
import UniversitySection from '../university/UniversitySection';
import LmsSection from '../../../learning-management-system/LmsSection';
import LearnSection from './LearnSection';
import SkeletonCourses from './SkeletonCourses';

const CoursesSection: React.FC = () => {
  const universityEnabled = Hooks.useUniversityEnabled();
  const universityCourse = Hooks.useUniversityCourse();
  const lmsCourses = Hooks.useAllCourses();
  const coursesInitialized = Hooks.useCourseInitialized();

  const noLmsCourses = (lmsCourses?.length ?? 0) === 0;
  const totalCourseCount = (lmsCourses?.length ?? 0) + (universityEnabled ? 1 : 0);
  const universityComplete = universityCourse?.progress === 100;
  const allLmsCoursesComplete =
    lmsCourses?.every((course) => course.completionPercentage === 100) ?? true;
  const allCoursesComplete = universityEnabled
    ? universityComplete && allLmsCoursesComplete
    : allLmsCoursesComplete;

  if (typeof universityEnabled === 'undefined' || !coursesInitialized) {
    return <SkeletonCourses />;
  }

  return (
    <>
      {universityEnabled && noLmsCourses && !universityComplete && <UniversitySection />}
      {!universityEnabled && !noLmsCourses && totalCourseCount === 1 && !allLmsCoursesComplete && (
        <LmsSection course={lmsCourses?.[0]} />
      )}
      {totalCourseCount > 1 && !allCoursesComplete && <LearnSection />}
    </>
  );
};

export default CoursesSection;
