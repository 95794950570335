import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function AccountSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <g fill={props.fill} fillRule='evenodd'>
        <path d='M0 0h30v30H0z' />
        <g fillRule='nonzero'>
          <path
            d='M26.456 25.479H2.885a1.19 1.19 0 0 0-1.183 1.183 1.19 1.19 0 0 0 1.183 1.182h23.57a1.19 1.19 0 0 0 1.183-1.182 1.19 1.19 0 0 0-1.182-1.183z'
            fill='#9A5238'
          />
          <path
            d='M4.359 22.417A1.19 1.19 0 0 0 3.176 23.6a1.19 1.19 0 0 0 1.183 1.182h20.623a1.19 1.19 0 0 0 1.182-1.182 1.19 1.19 0 0 0-1.182-1.183h-.292v-10.61h.292a.58.58 0 0 0 .583-.584.58.58 0 0 0-.583-.583H4.359a.58.58 0 0 0-.583.583c0 .324.259.583.583.583h.292v10.611h-.292zm17.966-10.61v10.61h-3.532v-10.61h3.532zm-5.897 0v10.61h-3.532v-10.61h3.532zm-9.428 0h3.531v10.61H7v-10.61z'
            fill='#1B7690'
          />
          <path
            d='M2.885 9.57h23.587a1.19 1.19 0 0 0 1.183-1.182c0-.518-.34-.956-.794-1.118L15.164 2.005a1.135 1.135 0 0 0-.972 0L2.4 7.303a1.169 1.169 0 0 0-.664 1.328c.113.55.583.94 1.15.94z'
            fill='#1E2969'
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function AccountSvgDark(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h30v30H0z' />
        <g fill='#5F5F5F' fillRule='nonzero'>
          <path d='M25.87 24.786H4.09a1.09 1.09 0 1 0 0 2.179h21.78a1.089 1.089 0 1 0 0-2.179zM5.45 21.954a1.09 1.09 0 1 0 0 2.18h19.06a1.09 1.09 0 0 0 0-2.18h-.273v-9.802h.272a.544.544 0 1 0 0-1.088H5.451a.544.544 0 1 0 0 1.088h.272v9.802H5.45zm16.609-9.802v9.802h-3.267v-9.802h3.267zm-5.446 0v9.802h-3.267v-9.802h3.267zm-8.712 0h3.267v9.802H7.9v-9.802zM4.09 10.084H25.88a1.089 1.089 0 0 0 .354-2.12L15.428 3.101a1.091 1.091 0 0 0-.894 0L3.643 8.002a1.089 1.089 0 0 0 .446 2.082z' />
        </g>
      </g>
    </SvgIcon>
  );
}

export function AccountSvgNav(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 35 21' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h35v21H0z' />
        <g fill={props.fill} fillRule='nonzero'>
          <path d='M27.03 19.086H7.957a.962.962 0 0 0-.957.957c0 .524.433.957.957.957H27.03a.962.962 0 0 0 .957-.957.962.962 0 0 0-.957-.957zM9.15 16.608a.962.962 0 0 0-.957.957c0 .524.432.957.957.957h16.687a.962.962 0 0 0 .957-.957.962.962 0 0 0-.957-.957h-.236V8.02h.236a.47.47 0 0 0 .472-.472.47.47 0 0 0-.472-.472H9.15a.47.47 0 0 0-.472.472c0 .263.21.472.472.472h.236v8.588H9.15zM23.687 8.02v8.588H20.83V8.02h2.857zm-4.771 0v8.588h-2.858V8.02h2.858zm-7.63 0h2.858v8.588h-2.857V8.02zM7.957 6.211H27.043A.962.962 0 0 0 28 5.254a.967.967 0 0 0-.642-.905L17.893.09a.918.918 0 0 0-.786 0L7.564 4.375a.946.946 0 0 0-.538 1.075c.092.446.472.76.93.76z' />
        </g>
      </g>
    </SvgIcon>
  );
}

AccountSvgNav.defaultProps = {
  fill: '#fff',
};

AccountSvg.defaultProps = {
  fill: 'none',
};
