import ContactInfoEditView from '../pages/personal-details/contactInfo/ContactInfoEditView';
import FinancialInfoEditView from '../pages/personal-details/financialInfo/FinancialInfoEditView';
import PersonalInfoEditView from '../pages/personal-details/personalInfo/PersonalInfoEditView';
import PersonalDetailsSummaryView from '../pages/personal-details/summary/PersonalDetailsSummaryView';
import MailingAddressEditInfoView from '../pages/personal-details/mailingInfo/MailingInfoEditView';
import DependentList from '../pages/personal-details/dependent/DependentView';

export const PersonalDetailsRoutes = [
  {
    path: 'personal-info',
    element: <PersonalInfoEditView />,
  },
  {
    path: 'contact-info',
    element: <ContactInfoEditView />,
  },
  {
    path: 'mailing-info',
    element: <MailingAddressEditInfoView />,
  },
  {
    path: 'financial-info',
    element: <FinancialInfoEditView />,
  },
  {
    path: 'dependents',
    element: <DependentList />,
  },
  {
    path: 'summary',
    element: <PersonalDetailsSummaryView />,
  },
];
