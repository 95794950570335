import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../themes/ThemeWrapper';

interface KeyValuePairProps {
  label: string;
  value: string;
  helpContext?: ReactNode;
}

const KeyValuePair: React.FC<KeyValuePairProps> = ({ label, value, helpContext }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item display='flex' alignItems='center' gap={1}>
        <Typography variant='p16' color='secondary' align='left'>
          {t(`${label}`)}
        </Typography>
        {helpContext && (
          <Tippy
            placement='right'
            content={helpContext}
            theme='light-nickels-theme'
            className='help'>
            <HelpOutline style={{ color: theme.palette.primary.main }} />
          </Tippy>
        )}
      </Grid>
      <Grid item>
        <Typography variant='p16Bold' color='primary' fontFamily='Montserrat' align='right'>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default KeyValuePair;
