import { Authentication, Data } from '@3nickels/data-modules';
import { IServiceContainer, inject, injectContainer } from '@aesop-fables/containr';
import {
  ConfiguredDataSource,
  DataCache,
  DataCompartment,
  fromAppStorage,
  Predicate,
  PredicateListener,
} from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { AuthTokenApi } from '../../api/apis/AuthTokenApi';
import { Observable } from 'rxjs';

export class TwoFactorDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @inject(ApiKeys.AuthToken) private readonly api: AuthTokenApi,
    @fromAppStorage(Data.TwoFactor.twoFactorStorageKey)
    private readonly cache: DataCache<Data.TwoFactor.TwoFactorCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  async onNext(): Promise<void> {
    const keys: (keyof Data.TwoFactor.TwoFactorCompartments)[] = ['twoFactorMethods'];
    await this.api.generateCookie();
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}

export class AuthCookieLoaded implements Predicate {
  private readonly compartment: DataCompartment<boolean>;
  constructor(
    @injectContainer() private readonly container: IServiceContainer,
    @inject(ApiKeys.AuthToken) private readonly api: AuthTokenApi
  ) {
    this.compartment = new DataCompartment<boolean>('auth', {
      defaultValue: false,
      loadingOptions: {
        strategy: 'lazy',
        predicate: this.container.resolve(Authentication.IsAuthenticatedSubject),
      },
      source: new ConfiguredDataSource<boolean>(async () => {
        await this.api.generateCookie();
        return true;
      }),
    });
  }

  createObservable(): Observable<boolean> {
    return this.compartment.value$;
  }
}
