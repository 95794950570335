import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import { ScriniumServices, DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import { createServiceModule, inject } from '@aesop-fables/containr';
import { Api, Data } from '@3nickels/data-modules';

export interface LoginData {
  username?: string;
  password?: string;
}

export interface PersonalInfo {
  firstName: string;
  lastName: string;
  birthdate: string;
  emailAddress: string;
}

export interface AccountInfo {
  username: string;
  password: string;
  rePassword: string;
}

export interface AccountConfirmation {
  defaultTwoFactor: Api.TwoFactorTypeEnum;
  email: string;
  phoneNumber: string;
}

interface RegisterUserData {
  birthdate?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

interface AuthWizardState {
  loginData: LoginData;
  registerData: RegisterUserData;
  loginResultData: Api.LoginResultRest;
  authMethod?: string;
}

export const AuthWizardStateDefault: AuthWizardState = {
  loginData: {},
  registerData: {},
  loginResultData: {},
  authMethod: undefined,
};

const mapRegisterUserDataToRegisterUserRequest = (
  data: RegisterUserData
): Api.RegisterUserRequest => {
  const { emailAddress, firstName, lastName, password, birthdate } = data;
  return { emailAddress, firstName, lastName, password, birthdate: birthdate };
};

export const signUpLoginWizardKey = 'services/signUpLoginWizard';

class SignUpLoginWizard implements Disposable {
  private readonly state: BehaviorSubject<AuthWizardState> = new BehaviorSubject(
    AuthWizardStateDefault
  );

  private readonly signUpComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userInfoAccepted = new Subject<boolean | undefined>();
  private accountValidated = new Subject<boolean | undefined>();

  constructor(
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(Api.ApiKeys.AuthApi)
    private readonly authApi: Api.AuthApi
  ) {
    // init
  }

  get state$(): Observable<AuthWizardState> {
    return this.state;
  }

  get userInfoAccepted$(): Observable<boolean | undefined> {
    return this.userInfoAccepted;
  }

  get accountValidated$(): Observable<boolean | undefined> {
    return this.accountValidated;
  }

  get signUpComplete$(): Observable<boolean> {
    return this.signUpComplete;
  }

  setProduct(product: string) {
    const current = this.state.value;
    const next = {
      ...current,
      product,
    };
    this.state.next(next);
    this.cacheState(next);
  }

  async setPersonalInfo(
    firstName: string,
    lastName: string,
    birthdate: string,
    emailAddress: string
  ): Promise<void> {
    const current = this.state.value;
    const registerData: RegisterUserData = {
      ...current.registerData,
      firstName,
      lastName,
      birthdate,
      emailAddress,
    };
    // await this.asyncStorageService.setItem('registerData', registerData);
    this.state.next({
      ...current,
      registerData,
    });
  }

  // For resending 2fa code during signup
  async setAccountInfoAndAttemptSignUp(
    firstName: string,
    lastName: string,
    birthdate: string,
    emailAddress: string,
    password: string
  ): Promise<void> {
    const current = this.state.value;
    const registerDataWithPassword = {
      ...current.registerData,
      firstName,
      lastName,
      birthdate,
      emailAddress,
      password,
    };
    const registerData: Api.RegisterUserRequest =
      mapRegisterUserDataToRegisterUserRequest(registerDataWithPassword);
    const result = await this.attemptSignUp(registerData);

    if (result) {
      this.userInfoAccepted.next(result);
    }
    this.state.next({ ...current, registerData });
  }

  async attemptSignUp(registerData: Api.RegisterUserRequest): Promise<boolean> {
    const response = await this.authApi.registerUser(registerData);
    return response.status === 200;
  }

  signUp() {
    this.signUpComplete.next(true);
  }

  // For resending 2fa during login
  async saveLoginDataAndAttemptLogin(username: string, password: string): Promise<void> {
    const current = this.state.value;
    // const { username, password } = loginData;
    const loginData = { ...current.loginData, username, password };
    let loginResultData = AuthWizardStateDefault.loginResultData;
    if (username && password) {
      const result = await this.attemptLogin(username, password);
      loginResultData = result;
    }
    this.state.next({ ...current, loginData, loginResultData });
  }

  async attemptLogin(username: string, password: string): Promise<Api.LoginResultRest> {
    const result = await this.authApi.login({ username, password });
    return result.data;
  }

  reset() {
    this.state.next(AuthWizardStateDefault);
    this.signUpComplete.next(false);
    this.clearCache();
  }

  private cacheState(state: AuthWizardState) {
    const userDataCache = this.appStorage.retrieve<Data.Accounts.AccountCompartments>(
      Data.Accounts.accountStorageKey
    );
    const userData = (
      userDataCache.findCompartment('account') as DataCompartment<Api.AccountRest>
    ).getData() as Api.AccountRest;
    localStorage.setItem(userData?.id + '-sign-up-state', JSON.stringify(state));
  }

  private clearCache() {
    const userDataCache = this.appStorage.retrieve<Data.Accounts.AccountCompartments>(
      Data.Accounts.accountStorageKey
    );
    const userData = (
      userDataCache.findCompartment('account') as DataCompartment<Api.AccountRest>
    ).getData() as Api.AccountRest;
    localStorage.removeItem(userData?.id + '-sign-up-state');
  }

  dispose(): void {
    console.log('disposing SignUpLoginWizard');
  }
}

export const withSignUpLoginWizard = createServiceModule(signUpLoginWizardKey, (services) => {
  services.use<SignUpLoginWizard>(signUpLoginWizardKey, SignUpLoginWizard);
});

export { SignUpLoginWizard };
