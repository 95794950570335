import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../../themes';
import { Data, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';

export type RealEstateBasicsFormProps = {
  realEstateBasics?: Data.RealEstate.HousingEntityBasicFormData;
  onSubmit: (values: Data.RealEstate.HousingEntityBasicFormData) => Promise<void>;
};

const RealEstateBasicsForm: React.FC<RealEstateBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.RealEstate.HousingEntityBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.realEstateBasics?.name}
          inputProps={{ maxLength: 64 }}
          label={'RealEstateNickname'}
          name='name'
          autoFocus
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.RealEstate.HousingEntityBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            defaultValue={props.realEstateBasics?.owner}
            items={[
              { label: 'Me', value: 'me' },
              { label: 'Spouse', value: 'spouse' },
            ]}
          />
        )}
        <TextInput<Data.RealEstate.HousingEntityBasicFormData>
          error={formState.errors.value !== undefined}
          helperText={formState.errors.value?.message?.toString()}
          defaultValue={props.realEstateBasics?.value}
          label='CurrentRealEstateValue'
          name='value'
          type='dollar'
        />
        <TextInput<Data.RealEstate.HousingEntityBasicFormData>
          error={formState.errors.rentalIncome !== undefined}
          helperText={formState.errors.rentalIncome?.message?.toString()}
          defaultValue={props.realEstateBasics?.rentalIncome}
          label='MonthlyRentalIncomeForThisProperty'
          name='rentalIncome'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default RealEstateBasicsForm;
