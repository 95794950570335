import Grid, { type GridProps } from '@mui/material/Grid';
import React from 'react';

export interface PageContentWithBackgroundProps extends GridProps {
  useContainer?: boolean;
}

export const PageContentWithBackground: React.FC<PageContentWithBackgroundProps> = ({
  useContainer = true,
  className = 'page-content-with-background',
  ...props
}) => (
  <Grid className={className} {...(useContainer && { container: true })} {...props}>
    {props.children}
  </Grid>
);
