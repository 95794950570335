/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormContext } from 'react-hook-form';
import { CircularProgress, Grid, Switch, SwitchProps, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Data, Hooks } from '@3nickels/data-modules';
import { Spacer } from '../../../components';
import FormContent from '../../../components/form/FormContent';
import { useAutoSaveHandler } from '../../../hooks/useAutoSaveHandler';
import { useAutoSaveStatus } from '../../../hooks/useAutoSaveStatus';

interface CommunicationsFormData {
  RECEIVE_FINANCIAL_TIPS_EMAIL?: boolean;
  RECEIVE_OFFERS_AND_PROMOTIONS_EMAIL?: boolean;
}

type CommunicationsSetting = {
  name: string;
  label: string;
  value?: boolean;
};

export type Alert = {
  header: string;
  subHeader: string;
  icon: React.ReactNode;
  communicationsSettings: CommunicationsSetting[];
};

interface AlertMenuProps {
  option: Alert;
}

const AlertMenu: React.FC<AlertMenuProps> = ({ option }) => {
  const { t } = useTranslation();
  const methods = useForm<CommunicationsFormData>();

  return (
    <Grid container flexDirection='column'>
      <Spacer height='xs' />
      {option.icon}
      <Typography mt={1} variant='p18Bold' color='secondary'>
        {t(option.header)}
      </Typography>
      <Typography mt={1} variant='p12' color='secondary'>
        {t(option.subHeader)}
      </Typography>
      {option.communicationsSettings.map((setting) => {
        return (
          <FormContent formProviderProps={methods}>
            <AlertSwitchOption
              key={setting.name}
              name={setting.name}
              label={setting.label}
              value={setting.value}
            />
          </FormContent>
        );
      })}
    </Grid>
  );
};

interface AlertSwitchOptionProps extends SwitchProps {
  name: string;
  label: string;
  value?: boolean;
}

function AlertSwitchOption(props: AlertSwitchOptionProps) {
  const { label, value, ...rest } = props;
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const commands = Hooks.useCommands();
  const [scheduled, setScheduled] = useState<NodeJS.Timeout | undefined>();
  const { getStatus, clearStatus } = useAutoSaveStatus();

  const status = useMemo(() => getStatus(props.name as string), [getStatus, props.name]);

  useEffect(() => {
    if (status === 'completed') {
      const t = setTimeout(() => clearStatus([props.name as string]), 1000);
      setScheduled(t);
    } else if (status === 'saving') {
      if (scheduled) {
        clearTimeout(scheduled);
        setScheduled(undefined);
      }
    }
  }, [status]);

  const saveHandler = useCallback(
    async (data: any) => {
      if (props.name === 'RECEIVE_FINANCIAL_TIPS_EMAIL') {
        await commands.execute(
          Data.Communications.Commands.UpdateReceiveFinancialTipsEmail,
          data.RECEIVE_FINANCIAL_TIPS_EMAIL
        );
      } else {
        await commands.execute(
          Data.Communications.Commands.UpdateReceiveOffersAndPromotionsEmail,
          data.RECEIVE_OFFERS_AND_PROMOTIONS_EMAIL
        );
      }
    },
    [value, props.name, commands]
  );

  const { onChange } = useAutoSaveHandler<any>({
    mode: 'full',
    onSave: saveHandler,
  });

  const changeAlertSetting = useCallback((value: boolean) => {
    setValue(`${props.name}`, value, { shouldTouch: true });
  }, []);

  const toggleAlertHandler = (value: boolean) => {
    changeAlertSetting(value);
    onChange();
  };

  return (
    <form onChange={onChange}>
      <Grid item mt={2} display='flex'>
        <Grid
          container
          mr={2}
          className='alert-switch-option'
          justifyContent='space-between'
          flexDirection='row'>
          <Typography variant='p16' color='secondary'>
            {t(label)}
          </Typography>
          <Switch
            {...rest}
            color='primary'
            onChange={(e) => {
              toggleAlertHandler(e.target.checked);
            }}
            checked={value}
          />
        </Grid>
        {status && (
          <Grid className='auto-save-indicator' item display='flex'>
            {status === 'saving' && (
              <>
                <CircularProgress />
                <Typography variant='p12' color='primary'>
                  Saving...
                </Typography>
              </>
            )}
            {status === 'completed' && (
              <>
                <Check />
                <Typography variant='p12' color='primary'>
                  Changes saved
                </Typography>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default AlertMenu;
