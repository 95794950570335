import { Grid, Typography } from '@mui/material';
import { Spacing } from '../../themes';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ToolsCardProps {
  icon: React.ReactNode;
  label: string;
  content: string;
}

const ToolsCard: React.FC<ToolsCardProps> = ({ icon, label, content }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      p={Spacing.xxxs + 1}
      display='flex'
      flexDirection='row'
      alignItems='center'
      columnGap='10px'
      flexWrap='nowrap'
      width='300px'
      height='100%'>
      <Grid>{icon}</Grid>
      <Grid
        display='flex'
        flexDirection='column'
        justifyContent='center'
        height='100%'
        rowGap='5px'>
        <Typography variant='p16Bold' color='secondary'>
          {t(label)}
        </Typography>
        <Typography variant='p12' color='secondary'>
          {t(content)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ToolsCard;
