export enum ToolTypeEnum {
  ThreeNickels = '3Nickels',
  Debt = 'Debt',
  Car = 'Car',
  Gifts = 'Gifts',
  House = 'House',
  Investment = 'Investment',
  Retirement = 'Retirement',
  Medical = 'Medical',
  CreditCard = 'CreditCard',
  Goals = 'Goals',
  College = 'College',
  Budget = 'Budget',
  Loans = 'Loans',
}

export declare type ToolSelectionItem = {
  type: ToolTypeEnum;
  key: string;
  label: string;
};
