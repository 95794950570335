import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const RealCostOfDebtCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomLightBulb />}
      label={t('TheRealCostOfDebt')}
      content={t('CalculateTheRealCostOfDebt')}
    />
  );
};

export default RealCostOfDebtCard;
