import { Group } from '../../../components/GroupPanels';
import { PiggyBankSvg } from '../../../components/images/PiggyBankSvg';
import { PalmTreeSvg } from '../../../components/images/PalmTreeSvg';
import { InvestmentSvg } from '../../../components/images/InvestmentSvg';
import { CarSvg } from '../../../components/images/CarSvg';
import { Home2Svg } from '../../../components/images/HomeSvg';
import { DebtSvg } from '../../../components/images/DebtSvg';
import { Domain, Hooks } from '@3nickels/data-modules';

export type ManualAccountTypes =
  | 'CashAccount'
  | 'RetirementPlan'
  | 'Investment'
  | 'Car'
  | 'Home'
  | 'Debt';

const useManualCashAccount = () => {
  const { wizard } = Hooks.useCashAccountWizard();

  const cashAccount: Group = {
    textKey: 'CashAccount',
    subTextKey: 'CashAccountSubtext',
    icon: ManualAccountIcon('CashAccount'),
    url: '/account-details/cash-account/basics',
    onClick: async () => {
      wizard.resetAll(); // do we still need this?
      await wizard.start({}).then(() => wizard.selectStep('basic'));
    },
  };

  return cashAccount;
};

const useRetirementAccount = () => {
  const { wizard } = Hooks.useRetirementWizard();
  const { wizard: pensionWizard } = Hooks.usePensionWizard();
  const retirementPlan: Group = {
    textKey: 'RetirementPlan',
    subTextKey: 'RetirementPlanSubtext',
    icon: ManualAccountIcon('RetirementPlan'),
    items: [
      {
        textKey: 'RetirementSavingsPlan',
        url: '/account-details/retirement-savings-plan/basics',
        onClick: async () => {
          wizard.resetAll(); // do we still need this
          await wizard.start({ type: Domain.PlanTypeEnum['#457'] });
        },
      },
      {
        textKey: 'PensionPlan',
        url: '/account-details/pension-plan/basics',
        onClick: async () => {
          pensionWizard.resetAll(); // do we still need this
          await pensionWizard.start({});
        },
      },
    ],
  };

  return retirementPlan;
};

const useInvestmentAccount = () => {
  const { wizard: cspWizard } = Hooks.useCollegeSavingsPlanWizard();
  const { wizard: investmentAccountWizard } = Hooks.useInvestmentAccountWizard();
  const { wizard: businessWizard } = Hooks.useBusinessWizard();
  const { wizard: realEstateWizard } = Hooks.useRealEstateWizard();
  const { wizard: otherAssetWizard } = Hooks.useOtherAssetWizard();
  const investment: Group = {
    textKey: 'Investment',
    subTextKey: 'InvestmentSubtext',
    icon: ManualAccountIcon('Investment'),
    items: [
      {
        textKey: 'BrokerageAccount',
        url: '/account-details/brokerage-account/basics',
        onClick: async () =>
          await investmentAccountWizard
            .start({ type: Domain.PlanTypeEnum['Brokerage Account'] })
            .then(() => investmentAccountWizard.selectStep('basic')),
      },
      {
        textKey: 'CollegeSavingsPlan',
        url: '/account-details/college-savings-plan/basics',
        onClick: async () => await cspWizard.start({}).then(() => cspWizard.selectStep('basic')),
      },
      {
        textKey: 'HSA',
        url: '/account-details/health-savings-account/basics',
        onClick: async () =>
          await investmentAccountWizard
            .start({ type: Domain.PlanTypeEnum.HSA })
            .then(() => investmentAccountWizard.selectStep('basic')),
      },
      {
        textKey: 'Business',
        url: '/account-details/business/basics',
        onClick: () => businessWizard.start({}).then(() => businessWizard.selectStep('basic')),
      },
      {
        textKey: 'InvestmentRealEstate',
        url: '/account-details/investment-real-estate/basics',
        onClick: async () =>
          await realEstateWizard
            .start({ type: Domain.RealEstateTypeEnum.INVESTMENT })
            .then(() => realEstateWizard.selectStep('basic')),
      },
      {
        textKey: 'OtherAsset',
        url: '/account-details/other-asset/basics',
        onClick: async () =>
          await otherAssetWizard.start({}).then(() => otherAssetWizard.selectStep('basic')),
      },
    ],
  };

  return investment;
};

const useManualCar = () => {
  const { wizard } = Hooks.useCarWizard();

  const car: Group = {
    textKey: 'Car',
    subTextKey: 'CarSubtext',
    icon: ManualAccountIcon('Car'),
    url: '/account-details/car/basics',
    onClick: async () => await wizard.start({}).then(() => wizard.selectStep('basic')),
  };

  return car;
};

const useManualHome = () => {
  const { wizard } = Hooks.useHomeWizard();

  const home: Group = {
    textKey: 'Home',
    subTextKey: 'HomeSubtext',
    icon: ManualAccountIcon('Home'),
    url: '/account-details/home/basics',
    onClick: async () =>
      await wizard
        .start({ type: Domain.RealEstateTypeEnum.PERSONAL })
        .then(() => wizard.selectStep('basic')),
  };

  return home;
};

const useManualDebtTypes = () => {
  const { wizard } = Hooks.useDebtWizard();

  const debt: Group = {
    textKey: 'Debt',
    subTextKey: 'DebtSubtext',
    icon: ManualAccountIcon('Debt'),
    items: [
      {
        textKey: Domain.getDebtTypeName(Domain.DebtTypeEnum.CREDIT_CARD),
        url: '/account-details/debt/basics/credit-card',
        onClick: async () =>
          await wizard
            .start({ type: Domain.DebtTypeEnum.CREDIT_CARD })
            .then(() => wizard.selectStep('basic')),
      },
      {
        textKey: Domain.getDebtTypeName(Domain.DebtTypeEnum.MEDICAL_DEBT),
        url: '/account-details/debt/basics/medical',
        onClick: async () =>
          await wizard
            .start({ type: Domain.DebtTypeEnum.MEDICAL_DEBT })
            .then(() => wizard.selectStep('basic')),
      },
      {
        textKey: Domain.getDebtTypeName(Domain.DebtTypeEnum.STUDENT_LOAN),
        url: '/account-details/debt/basics/student-loan',
        onClick: async () =>
          await wizard
            .start({ type: Domain.DebtTypeEnum.STUDENT_LOAN })
            .then(() => wizard.selectStep('basic')),
      },
      {
        textKey: Domain.getDebtTypeName(Domain.DebtTypeEnum.PERSONAL_LOAN),
        url: '/account-details/debt/basics/personal-loan',
        onClick: async () =>
          await wizard
            .start({ type: Domain.DebtTypeEnum.PERSONAL_LOAN })
            .then(() => wizard.selectStep('basic')),
      },
      {
        textKey: Domain.getDebtTypeName(Domain.DebtTypeEnum.OTHER),
        url: '/account-details/debt/basics/other',
        onClick: async () =>
          await wizard
            .start({ type: Domain.DebtTypeEnum.OTHER })
            .then(() => wizard.selectStep('basic')),
      },
    ],
  };

  return debt;
};

export const useManualAccountTypes = (): Group[] => {
  const cashAccount = useManualCashAccount();
  const retirement = useRetirementAccount();
  const investment = useInvestmentAccount();
  const car = useManualCar();
  const home = useManualHome();
  const debt = useManualDebtTypes();

  return [cashAccount, retirement, investment, car, home, debt];
};

export const ManualAccountIcon = (accountType: ManualAccountTypes) => {
  const iconProps = { sx: { fontSize: '30px' } };

  switch (accountType) {
    case 'CashAccount':
      return PiggyBankSvg(iconProps);
    case 'RetirementPlan':
      return PalmTreeSvg(iconProps);
    case 'Investment':
      return InvestmentSvg(iconProps);
    case 'Car':
      return CarSvg(iconProps);
    case 'Home':
      return Home2Svg(iconProps);
    case 'Debt':
      return DebtSvg(iconProps);
    default:
      return null;
  }
};
