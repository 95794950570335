import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { Api } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import Modal from '../../components/Modal';
import { API_BASE_URL } from '../../configuration/Environment';

interface WarningDeleteAccountModalProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarningDeleteAccountModal: React.FC<WarningDeleteAccountModalProps> = ({
  modalOpen,
  setModalOpen,
  setConfirmModalOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('DeleteYour3NickelsAccountPermanently') as string}
      primaryButtonText='No, Keep My Account'
      secondaryButtonText='Yes, Delete My Account'
      swapButtonFunctionality
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={() => {
        setConfirmModalOpen(true);
      }}>
      <Typography variant='p16' color='error.main'>
        {t('ByRemovingYour3NickelsAccount')}
      </Typography>
    </Modal>
  );
};

interface ConfirmDeleteAccountModalProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmDeleteAccountModal: React.FC<ConfirmDeleteAccountModalProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();

  const api = useService<Api.IAccountApi>(Api.ApiKeys.Account);

  const removeAccount = async () => {
    const form = document.createElement('form');
    const input = document.createElement('input');

    form.method = 'post';
    form.action = `${API_BASE_URL}/logout`;
    form.appendChild(input);

    input.name = '_csrf';
    input.value = Cookies.get('XSRF-TOKEN') ?? '';

    document.body.appendChild(form);
    await api.deleteAccount();
    form.submit();
  };

  return (
    <Modal
      title={t('YouAreDeletingYour3NickelsAccount') as string}
      primaryButtonText='Delete My Account Permanently'
      primaryButtonColor='error'
      primaryButtonVariant='contained'
      secondaryButtonColor='error'
      secondaryButtonVariant='text'
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={removeAccount}>
      <Typography variant='p16' color='error.main'>
        {t('WereSorryToSeeYouGo')}
      </Typography>
    </Modal>
  );
};

export { ConfirmDeleteAccountModal, WarningDeleteAccountModal };
