import { Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Colors } from '../../themes';
import { Row } from '../../components';
import { t } from 'i18next';

export const MobilePaymentsHistoryDetailsView: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { payment } = state;
  const receiptUrl = payment.charge.receiptUrl;

  const onViewReceipt = () => {
    window.open(receiptUrl);
  };

  return (
    <Grid container padding={'20px'} flexDirection='column' justifyContent='space-between' flex={2}>
      <Grid display='flex' direction='row' justifyContent='space-between'>
        <Typography mb={1} variant='p22Bold' color='primary'>
          {t('ViewPastInvoice')}
        </Typography>
        <Svgs.IconNavAlertDefault onClick={() => navigate('/settings/manage-payments')} />
      </Grid>
      {!receiptUrl ? (
        <Typography mb={2.5} variant='p16' color='error'>
          {t('YourReceiptHasntBeenGenerated')}
        </Typography>
      ) : (
        <Typography mb={2.5} variant='p16' color='secondary'>
          {t('YouCanEasilyViewAndDownloadYourReceipt')}
        </Typography>
      )}
      {receiptUrl && (
        <Button color='primary' onClick={onViewReceipt}>
          {t('ViewReceipt')}
        </Button>
      )}

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        {t('InvoiceDetails')}
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('InvoiceNumber')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.invoiceId}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('InvoiceDate')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.datePaid}
          </Typography>
        </Row>
      </Grid>

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        {t('ProductDetails')}
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('ProductName')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.description}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('TotalPrice')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            ${payment.charge.amountPaid / 100.0}
          </Typography>
        </Row>
      </Grid>

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        {t('BillingDetails')}
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('Name')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.name}
          </Typography>
        </Row>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            {t('Email')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.email}
          </Typography>
        </Row>
        {payment.paymentInfo.zipCode && (
          <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
            <Typography variant='p16Bold' color='primary'>
              {t('ZipCode')}
            </Typography>
            <Typography variant='p16' color='secondary'>
              {payment.paymentInfo.zipCode}
            </Typography>
          </Row>
        )}
        {payment.paymentInfo.country && (
          <Row style={{ justifyContent: 'space-between' }}>
            <Typography variant='p16Bold' color='primary'>
              {t('Country')}
            </Typography>
            <Typography variant='p16' color='secondary'>
              {payment.paymentInfo.country}
            </Typography>
          </Row>
        )}
      </Grid>
    </Grid>
  );
};
