import BudgetOverview from '../pages/holistic-financial-advice/budget/BudgetOverview';
import BudgetStartView from '../pages/holistic-financial-advice/budget/BudgetStartView';
import GoalOverview from '../pages/holistic-financial-advice/goals/GoalOverview';
import GoalsStartView from '../pages/holistic-financial-advice/goals/GoalsStartView';
import HowToOpenAnAccountView from '../pages/holistic-financial-advice/investments/HowToOpenAnAccountView';
import InvestmentAccountOverview from '../pages/holistic-financial-advice/investments/InvestmentAccountOverview';
import InvestmentsStartView from '../pages/holistic-financial-advice/investments/InvestmentsStartView';
import OpenBrokerageAccountView from '../pages/holistic-financial-advice/investments/OpenBrokerageAccountView';
import OpenIraView from '../pages/holistic-financial-advice/investments/OpenIraView';
import ShopAccountOptionsView from '../pages/holistic-financial-advice/investments/ShopAccountOptionsView';
import PlanOutlineView from '../pages/holistic-financial-advice/plan/PlanOutlineView';
import PlanStartView from '../pages/holistic-financial-advice/plan/PlanStartView';

export const HolisticFinancialAdviceRoutes = [
  {
    path: 'budget',
    children: [
      { index: true, element: <BudgetStartView /> },
      { path: 'monthly', element: <BudgetOverview /> },
    ],
  },
  {
    path: 'goals',
    children: [
      { index: true, element: <GoalsStartView /> },
      { path: ':id', element: <GoalOverview /> },
    ],
  },
  {
    path: 'investments',
    children: [
      { index: true, element: <InvestmentsStartView /> },
      { path: ':id', element: <InvestmentAccountOverview /> },
      { path: 'brokerage', element: <OpenBrokerageAccountView /> },
      { path: 'ira', element: <OpenIraView /> },
      { path: 'open-an-account', element: <HowToOpenAnAccountView /> },
      { path: 'shop-accounts', element: <ShopAccountOptionsView /> },
    ],
  },
  {
    path: 'plan',
    children: [
      { index: true, element: <PlanStartView /> },
      { path: 'outline', element: <PlanOutlineView /> },
    ],
  },
];
