/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../../hooks/useAutoSaveHandler';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import {
  BudgetSectionProps,
  UtilityFormData,
  mapUtilitiesFormToMonthlySpendingRest,
} from '../../Types';
import * as Yup from 'yup';
import EmbeddedFormInput from '../../../../components/EmbeddedFormInput';
import { Grid } from '@mui/material';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { SpendingProps } from '../Types';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import _ from 'lodash';

const schema: Yup.Schema = Yup.object({
  electricity: Yup.string().notRequired(),
  water: Yup.string().notRequired(),
  cellPhone: Yup.string().notRequired(),
  internet: Yup.string().notRequired(),
  naturalGas: Yup.string().notRequired(),
  trash: Yup.string().notRequired(),
  otherUtilities: Yup.string().notRequired(),
});

export declare type UtilityFormProps = BudgetSectionProps & { defaultValues: UtilityFormData };

export const UtilityForm: React.FC<UtilityFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<UtilityFormData>();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): UtilityFormData => {
    const electricity = cleanWholeNumberStr(data.electricity ?? '', { max });
    const water = cleanWholeNumberStr(data.water ?? '', { max });
    const cellPhone = cleanWholeNumberStr(data.cellPhone ?? '', { max });
    const internet = cleanWholeNumberStr(data.internet ?? '', { max });
    const naturalGas = cleanWholeNumberStr(data.naturalGas ?? '', { max });
    const trash = cleanWholeNumberStr(data.trash ?? '', { max });
    const otherUtilities = cleanWholeNumberStr(data.otherUtilities ?? '', { max });

    return {
      electricity,
      water,
      cellPhone,
      internet,
      naturalGas,
      trash,
      otherUtilities,
    };
  };

  const saveHandler = useCallback(
    async (data: UtilityFormData) => {
      const params = mapUtilitiesFormToMonthlySpendingRest(mode, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Budget.Commands.UpdateMonthlySpending, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial',
    onSave: saveHandler,
    schema,
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <EmbeddedFormInput<UtilityFormData>
          label='Electricity'
          placeholder='$0'
          autoComplete='off'
          name='electricity'
          type='dollar'
          max={max}
          defaultValue={defaultValues.electricity}
          error={formState.errors.electricity !== undefined}
          helperText={formState.errors.electricity?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='Water/Sewage'
          placeholder='$0'
          autoComplete='off'
          name='water'
          type='dollar'
          max={max}
          defaultValue={defaultValues.water}
          error={formState.errors.water !== undefined}
          helperText={formState.errors.water?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='CellPhone'
          placeholder='$0'
          autoComplete='off'
          name='cellPhone'
          type='dollar'
          max={max}
          defaultValue={defaultValues.cellPhone}
          error={formState.errors.cellPhone !== undefined}
          helperText={formState.errors.cellPhone?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='Cable/Internet'
          placeholder='$0'
          autoComplete='off'
          name='internet'
          type='dollar'
          max={max}
          defaultValue={defaultValues.internet}
          error={formState.errors.internet !== undefined}
          helperText={formState.errors.internet?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='Natural Gas'
          placeholder='$0'
          autoComplete='off'
          name='naturalGas'
          type='dollar'
          max={max}
          defaultValue={defaultValues.naturalGas}
          error={formState.errors.naturalGas !== undefined}
          helperText={formState.errors.naturalGas?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='Trash/Recycling'
          placeholder='$0'
          autoComplete='off'
          name='trash'
          type='dollar'
          max={max}
          defaultValue={defaultValues.trash}
          error={formState.errors.trash !== undefined}
          helperText={formState.errors.trash?.message?.toString()}
        />
        <EmbeddedFormInput<UtilityFormData>
          label='OtherUtilities'
          placeholder='$0'
          autoComplete='off'
          name='otherUtilities'
          type='dollar'
          max={max}
          defaultValue={defaultValues.otherUtilities}
          error={formState.errors.otherUtilities !== undefined}
          helperText={formState.errors.otherUtilities?.message?.toString()}
        />
      </Grid>
    </form>
  );
};

export const UtilitySpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  monthlySpending,
  spendingSummary,
}) => {
  const defaultValues = useMemo(() => {
    return {
      electricity: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.electricity),
      water: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.water),
      cellPhone: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.cellPhone),
      internet: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.internet),
      naturalGas: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.naturalGas),
      trash: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.trash),
      otherUtilities: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.otherUtilities),
    };
  }, [monthlySpending, mode]);

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.utilities),
    [spendingSummary, mode]
  );

  const methods = useForm<UtilityFormData>({ defaultValues });
  return (
    <CollapsiblePanel
      variant='compressed'
      header='Utilities'
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomLightBulb />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <UtilityForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
