import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { Colors } from '../themes';
import { Check } from '@mui/icons-material';
import React from 'react';

export interface CheckSelectItem<T> {
  checked: boolean;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  onClick?: (i: T, checked: boolean) => void;
  item: T;
}

export interface CheckSelectProps<T> {
  items: T[];
  keyFn: (i: T) => string;
  titleFn: (i: T) => React.ReactNode;
  subTitleFn?: (i: T) => React.ReactNode;
  onChange?: (i: T, checked: boolean) => void;
  valueFn: (i: T) => boolean;
}

function CheckItem<T>(props: CheckSelectItem<T>): React.ReactNode {
  const { checked, item, title, subTitle, onClick } = props;

  return (
    <Card
      className={checked ? 'check-select-checked' : 'check-select'}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick(item, !checked);
        }
      }}
      elevation={0}>
      <CardHeader
        disableTypography
        title={
          <Typography
            color={checked ? Colors.tertiaryBase : Colors.secondaryBase}
            variant='p16Bold'
            marginTop={subTitle || checked ? '' : '9px'}>
            {title}
          </Typography>
        }
        subheader={
          subTitle && (
            <Typography variant='p13' color={checked ? Colors.tertiaryBase : Colors.secondaryBase}>
              {subTitle}
            </Typography>
          )
        }
        action={
          checked && (
            <Grid item mt='11px' mr={1}>
              <Check htmlColor={Colors.tertiaryBase} />
            </Grid>
          )
        }
      />
    </Card>
  );
}

export default function CheckSelect<T>(props: CheckSelectProps<T>) {
  const { items, keyFn, valueFn, titleFn, subTitleFn, onChange } = props;
  return items?.map((i) => (
    <CheckItem<T>
      key={keyFn(i)}
      checked={valueFn(i)}
      item={i}
      title={titleFn(i)}
      subTitle={subTitleFn && subTitleFn(i)}
      onClick={onChange}
    />
  ));
}
