import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useAnimationControls, AnimationControls, AnimationDefinition } from 'framer-motion';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

export interface IAnimationContext {
  controls: AnimationControls;
}

const AnimationContext = createContext<IAnimationContext>({
  controls: {
    start: function (): Promise<unknown> {
      throw new Error('Function not implemented.');
    },
    set: function (): void {
      throw new Error('Function not implemented.');
    },
    stop: function (): void {
      throw new Error('Function not implemented.');
    },
    mount: function (): () => void {
      throw new Error('Function not implemented.');
    },
  },
});

export const AnimationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const controls = useAnimationControls();

  return <AnimationContext.Provider value={{ controls }}>{children}</AnimationContext.Provider>;
};

export const useControlledAnimation = () => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error('useControlledAnimation must be used within AnimationProvider');
  }
  return context;
};

interface AnimatiedNavigationOptions extends NavigateOptions {
  animationOptions: AnimationDefinition;
}

export const useAnimatedNavigation = () => {
  const navigate = useNavigate();
  const { controls } = useContext(AnimationContext);

  const handleNavigation = useMemo(() => {
    return (to: number | To, options?: NavigateOptions) => {
      if (typeof to === 'number') {
        navigate(to);
      } else {
        navigate(to, options);
      }
    };
  }, [navigate]);

  const navigateFunction = (to: number | To, options?: AnimatiedNavigationOptions) => {
    const { animationOptions, ...rest } = options ?? {
      animationOptions: { opacity: 0, transition: { duration: 1 } },
    };
    controls.start(animationOptions).then(() => handleNavigation(to, rest));
  };

  return navigateFunction;
};
