import { Data } from '@3nickels/data-modules';
import { Svgs } from '../../assets/svg';

interface TransactionIconProps {
  category?: Data.Transactions.TransactionCategoryEnum;
  selected?: boolean;
}

export const TransactionIcon: React.FC<TransactionIconProps> = ({ category, selected }) => {
  switch (category) {
    case Data.Transactions.TransactionCategoryEnum.GENERAL_SERVICES:
      return selected ? <Svgs.DisplaySmallWhiteMembers /> : <Svgs.DisplaySmallCustomMembers />;
    case Data.Transactions.TransactionCategoryEnum.LOAN_PAYMENTS:
      return selected ? <Svgs.DisplaySmallWhiteLoans /> : <Svgs.DisplaySmallCustomLoans />;
    case Data.Transactions.TransactionCategoryEnum.GENERAL_MERCHANDISE:
      return selected ? (
        <Svgs.DisplaySmallWhiteShoppingCart />
      ) : (
        <Svgs.DisplaySmallCustomShoppingCart />
      );
    case Data.Transactions.TransactionCategoryEnum.OTHER:
      return selected ? <Svgs.DisplaySmallWhiteBudget /> : <Svgs.DisplaySmallCustomBudget />;
    case Data.Transactions.TransactionCategoryEnum.FOOD_AND_DRINK:
      return selected ? <Svgs.DisplaySmallWhiteFood /> : <Svgs.DisplaySmallCustomFood />;
    case Data.Transactions.TransactionCategoryEnum.PERSONAL_CARE:
      return selected ? <Svgs.DisplaySmallWhiteLegacy /> : <Svgs.DisplaySmallCustomLegacy />;
    case Data.Transactions.TransactionCategoryEnum.TRANSPORTATION:
      return selected ? <Svgs.DisplaySmallWhiteCar /> : <Svgs.DisplaySmallCustomCar />;
    case Data.Transactions.TransactionCategoryEnum.TRAVEL:
      return selected ? (
        <Svgs.DisplaySmallWhiteRetirement />
      ) : (
        <Svgs.DisplaySmallCustomRetirement />
      );
    case Data.Transactions.TransactionCategoryEnum.INCOME:
      return selected ? <Svgs.DisplaySmallWhiteWallet /> : <Svgs.DisplaySmallCustomWallet />;
    case Data.Transactions.TransactionCategoryEnum.BANK_FEES:
      return selected ? <Svgs.DisplaySmallWhiteCoins /> : <Svgs.DisplaySmallCustomCoins />;
    case Data.Transactions.TransactionCategoryEnum.ENTERTAINMENT:
      return selected ? (
        <Svgs.DisplaySmallWhiteVideoCamera />
      ) : (
        <Svgs.DisplaySmallCustomVideoCamera />
      );
    case Data.Transactions.TransactionCategoryEnum.GOVERNMENT_AND_NON_PROFIT:
      return selected ? <Svgs.DisplaySmallWhiteBank /> : <Svgs.DisplaySmallCustomBank />;
    case Data.Transactions.TransactionCategoryEnum.HOME_IMPROVEMENT:
      return selected ? <Svgs.DisplaySmallWhiteHouse /> : <Svgs.DisplaySmallCustomHouse />;
    case Data.Transactions.TransactionCategoryEnum.MEDICAL:
      return selected ? <Svgs.DisplaySmallWhiteMedical /> : <Svgs.DisplaySmallCustomMedical />;
    case Data.Transactions.TransactionCategoryEnum.RENT_AND_UTILITIES:
      return selected ? <Svgs.DisplaySmallWhiteLightBulb /> : <Svgs.DisplaySmallCustomLightBulb />;
    case Data.Transactions.TransactionCategoryEnum.TRANSFER_IN:
      return selected ? <Svgs.DisplaySmallWhiteDeposit /> : <Svgs.DisplaySmallCustomDeposit />;
    case Data.Transactions.TransactionCategoryEnum.TRANSFER_OUT:
      return selected ? <Svgs.DisplaySmallWhiteWithdraw /> : <Svgs.DisplaySmallCustomWithdraw />;
    default:
      return <Svgs.DisplaySmallCustomBudget />;
  }
};
