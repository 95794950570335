import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Spacer, KeyValuePair } from '../../../../components';
import { Spacing } from '../../../../themes';
import { Api, Domain, formatWholeDollars } from '@3nickels/data-modules';
import InfoCard from '../../../../components/InfoCard';
import { useGoalEditor } from '../../../goals/useGoalEditor';

export interface RealCostOfDebtResultsProps {
  results?: Api.DebtRealCostResultRest;
}

const RealCostOfDebtResults: React.FC<RealCostOfDebtResultsProps> = ({ results }) => {
  const editGoal = useGoalEditor();

  return (
    <Grid container direction='column' rowGap='40px'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 1}>
          <Spacer height='xxs' />
          <KeyValuePair
            label={t('LoanAmount')}
            value={
              results && results.totalPrincipal
                ? formatWholeDollars(results.totalPrincipal)
                : '– – –'
            }
          />
          <KeyValuePair
            label={t('InterestPaid')}
            value={
              results && results.totalInterestPaid
                ? formatWholeDollars(results.totalInterestPaid)
                : '– – –'
            }
          />
        </Stack>
      </Grid>
      {typeof results !== 'undefined' && (
        <InfoCard
          onClick={() => {
            editGoal(Domain.GoalTypeEnum.Other);
          }}>
          <Typography variant='p16' color='secondary'>
            Would you rather save for this purchase? Create a savings goal.
          </Typography>
        </InfoCard>
      )}
    </Grid>
  );
};

export default RealCostOfDebtResults;
