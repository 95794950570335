import { Box, Grid, Stack } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolHeader from '../../../../components/ToolHeader';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import { StepSix } from './StepSix';
import { StepSeven } from './StepSeven';

export const HouseSellingChecklistView: React.FC = () => {
  return (
    <Box>
      <Grid container flexDirection='column'>
        <Stack spacing={3}>
          <ToolHeader title='HouseSellingChecklist' />
          <StepOne />
          <StepTwo />
          <StepThree />
          <StepFour />
          <StepFive />
          <StepSix />
          <StepSeven />
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HouseSellingChecklistView, meta);
