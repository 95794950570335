import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(Backend) // Lazy loads translations from /public/locales
  .use(LanguageDetector) // Detect user language
  .init({
    fallbackLng: 'en',
    debug: false,
    useCookie: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
