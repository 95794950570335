import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { useTranslation } from 'react-i18next';
import { Margins } from '../../../../themes';
import BulletedText from '../../../../components/BulletedText';
import { Stack } from '@mui/material';

const ClosingCostsHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('Closingcosts')}>
      <HelpPopoverText style={Margins.mb_sm}>These may include:</HelpPopoverText>
      <Stack mr={2} ml={2}>
        <BulletedText>Advertising</BulletedText>
        <BulletedText>Appraisal fees</BulletedText>
        <BulletedText>Attorney fees</BulletedText>
        <BulletedText>Document preparation fees</BulletedText>
        <BulletedText>Escrow fees</BulletedText>
        <BulletedText>Mortgage satisfaction fees</BulletedText>
        <BulletedText>Notary fees</BulletedText>
        <BulletedText>Points paid by seller to obtain financing for buyer</BulletedText>
        <BulletedText>Recording fees (if paid by the seller)</BulletedText>
        <BulletedText>Costs of removing title clouds</BulletedText>
        <BulletedText>Settlement fees</BulletedText>
        <BulletedText>Title search fees</BulletedText>
        <BulletedText>
          Transfer or stamp taxes charged by city, county, or state governments
        </BulletedText>
      </Stack>
    </HelpPopover>
  );
};

export default ClosingCostsHelp;
