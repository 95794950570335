import { Grid, Stack } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolHeader from '../../../../components/ToolHeader';
import { useLoading } from '../../../../hooks/useLoading';
import { useEffect, useState } from 'react';
import { useMessage } from '../../../../hooks/useMessage';
import { Spacing } from '../../../../themes';
import { accentBase, secondaryBase } from '../../../../themes/colors';
import { RatioSection } from './RatioSection';
import { IncomeChartSection } from './IncomeChartSection';
import { isDebtIncomeSupported } from './Types';
import { useTranslation } from 'react-i18next';

const HowMuchDebtCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const debts = Hooks.useAggregateDebts();
  const { totalHomeSpending, totalDebtSpending } = Hooks.useBudgetSummary() ?? {
    totalHomeSpending: 0,
    totalDebtSpending: 0,
  };
  const [results, setResults] = useState<Domain.DebtIncomePercentageData | undefined>();
  const [debtSupported, setDebtSupported] = useState<boolean>(true);
  const noDebts =
    (!debts || debts.length === 0) && totalHomeSpending === 0 && totalDebtSpending === 0;

  useEffect(() => {
    if (noDebts) return;
    calculate();
  }, [noDebts]);

  const calculate = async () => {
    setLoading(true);
    try {
      const result = await commands.relay(Data.Debt.Commands.CalculateDebtIncomePercentage);
      setResults(result);
      const supported = isDebtIncomeSupported(result);
      setDebtSupported(supported);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    if (!results) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
      return;
    }

    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Debt.Commands.CalculateDebtIncomePercentagePdf,
        {}
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HowMuchDebtCanMyIncomeSupport.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <Grid container flexDirection='column'>
        <Stack spacing={Spacing.xxs}>
          <ToolHeader
            title={noDebts ? 'NoDebtsYet' : 'CurrentSnapshot'}
            subtitleStyle={{ color: debtSupported ? secondaryBase : accentBase }}
            onDownload={results && !noDebts ? onDownload : undefined}
          />
          <Grid container direction='column' alignItems='flex-start'>
            <Stack spacing={Spacing.xxs} width='100%'>
              <IncomeChartSection noDebts={noDebts} results={results} />
              <RatioSection noDebts={noDebts} results={results} />
            </Stack>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(HowMuchDebtCalculatorView, meta);
