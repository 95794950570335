/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Spacing } from '../../../../themes';

const GivingOnBehalf: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Grid display='flex' alignItems='end'>
        <Grid flexGrow={3}>
          <Grid item>
            <Typography className='title' color='primary' component='h1' variant='h1'>
              {t('DonatingAsAGift')}
            </Typography>
            <Typography className='subtitle' color='secondary' variant='p16'>
              {t('NotSureWhatToGive?')}
            </Typography>
          </Grid>
          <Grid container className='content-card-container' flexDirection='column'>
            <Grid container className='content-card-header'>
              <Typography color='secondary' variant='p18Bold'>
                {t('HowAboutDonatingToACause?')}
              </Typography>
            </Grid>
            <Card className='content-card'>
              <BulletedList
                color='secondary'
                children={[
                  t('PlantATreeInName'),
                  t('SupportAnUnderprivilegedChild'),
                  t('GiveToTheirChurch'),
                  t('BeCreative...'),
                ]}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid flexGrow={2} display='flex' justifyContent='center'>
          <Svgs.ArtMoneyPlant />
        </Grid>
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(GivingOnBehalf, meta);
