/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../GoalsLayout';
import { Svgs } from '../../../assets/svg';
import BulletedList from '../../../components/BulletedList';
import { ambulance } from '../../../assets/png';
import { Button } from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Spacing } from '../../../themes';

const LegacyGiving: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container marginRight='10px'>
        <Svgs.ArtHeart />
        <Grid item ml='10px'>
          <Typography className='title' color='primary' component='h1' variant='h1'>
            {t('LegacyGiving')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('LegacyNoMatterYourAge')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomInvestment />
          <Typography color='secondary' variant='p18Bold'>
            {t('Assets')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Typography color='secondary' variant='p16'>
            {t('LegacyYouWillWantToBeVeryClear')}
          </Typography>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomItemization />
          <Typography color='secondary' variant='p18Bold'>
            {t('CreateAWillOrTrust')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Box>
            <Typography color='secondary' variant='p16'>
              {t('LegacyYouCanTakeAwayConfusion')}
            </Typography>
            <BulletedList
              color='secondary'
              children={[
                t('GetProfessionalHelp'),
                t('MakeSureToSetUpBeneficiaries'),
                t('IfYouHaveKids'),
                t('DecideOnAnExecutor'),
                t('MakeSureYourWillOrTrustIsUpToDate'),
              ]}
            />
          </Box>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomMedical />
          <Typography color='secondary' variant='p18Bold'>
            {t('HealthDirective')}
          </Typography>
        </Grid>
        <Card className='content-card' color='accent'>
          <Grid container>
            <img src={ambulance} alt='ambulance' width='140px' />
            <Box ml='20px'>
              <Typography color='white' variant='p16'>
                {t('LegacyByGettingALegalStructureInPlace')}
              </Typography>
              <BulletedList
                color='white'
                children={[t('AHealthDirective'), t('APowerOfAttorney')]}
              />
            </Box>
          </Grid>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomHealthInsurance />
          <Typography color='secondary' variant='p18Bold'>
            {t('LifeInsurance')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Typography color='secondary' variant='p16'>
            {t('LegacyNoMatterYourAgeOrSituation')}
          </Typography>
        </Card>
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'Legacy',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(LegacyGiving, meta);
