import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Typography } from '@mui/material';
import { Spacer } from '../../../../components';

interface GotchaItemProps {
  icon: React.ReactNode;
  title: string;
  body: string | JSX.Element;
}

const GotchaItem: React.FC<GotchaItemProps> = ({ icon, title, body }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems='center' columnGap='30px'>
        <Grid container item ml={3} xs={1.25} alignItems='center' justifyContent='center'>
          {icon}
        </Grid>
        <Grid item>
          <Typography color='primary' variant='p22Bold'>
            {t(title)}
          </Typography>
        </Grid>
      </Grid>
      <Card className='content-card'>
        <Grid container>
          <Grid item>
            {typeof body === 'string' ? (
              <Typography variant='p16' color='secondary'>
                {t(body)}
              </Typography>
            ) : (
              body
            )}
          </Grid>
        </Grid>
      </Card>
      <Spacer height='xs' />
    </>
  );
};

export default GotchaItem;
