import { Typography } from '@mui/material';
import FormInput, { FormInputProps } from './FormInput';
import React from 'react';
import Column from './Column';
import Row from './Row';
import Spacer from './Spacer';
import { useTranslation } from 'react-i18next';

interface VerticalFormInputProps<T> extends FormInputProps<T> {
  label?: string;
  helpContent?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputStyle?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const VerticalFormInput = <T extends unknown>(props: VerticalFormInputProps<T>) => {
  const { t } = useTranslation();
  const { label, helpContent } = props;
  return (
    <Column>
      <Row style={{ justifyContent: 'space-between', marginBottom: '6px' }}>
        {label ? (
          <>
            <Typography variant='p14Bold' color='primary'>
              {t(label)}
            </Typography>
            <Spacer width='xs' />
          </>
        ) : null}
        {helpContent ? (
          <>
            <Column style={{ alignSelf: 'flex-end' }}>{helpContent}</Column>
          </>
        ) : null}
      </Row>
      <FormInput {...props} />
    </Column>
  );
};

export default VerticalFormInput;
