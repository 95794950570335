import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Data, Hooks } from '@3nickels/data-modules';
import { Tabs } from '../../components/Tabs';
import { useTranslation } from 'react-i18next';
import IncomeSection from './sections/IncomeSection';
import SavingsSection from './sections/SavingsSection';
import SpendingSection from './sections/SpendingSection';
import GivingSection from './sections/GivingSection';
import BudgetDetailsSidePanel from './BudgetDetailsSidePanel';
import { useNeedsOnboarding } from '../../hooks';

export const BudgetDetailsManager: React.FC = () => {
  const { t } = useTranslation();
  const { accountId } = Hooks.usePersonData() ?? {};
  const commands = Hooks.useCommands();
  const budget = Hooks.useBudgetSummary();
  const userConfig = Hooks.useUserConfig();
  const needsOnboarding = useNeedsOnboarding();

  const [value, setValue] = React.useState(0);
  const mode = value === 0 ? 'monthly' : 'annual';
  const sidePanelWidth = 370;

  useEffect(() => {
    if (accountId && !userConfig?.budgetDetailsReviewed) {
      commands.executeMutation(new Data.Users.Mutations.CompleteBudgetDetails(), null);
    }
  }, [userConfig?.budgetDetailsReviewed, accountId, commands]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!accountId) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          maxWidth: `calc(100% - ${sidePanelWidth}px)`,
        }}>
        <Typography variant='h1' color='primary' sx={{ mb: 2 }}>
          {t(needsOnboarding ? 'BudgetDetails' : 'Budget')}
        </Typography>
        <Typography variant='p16' color='secondary' sx={{ mb: 3 }}>
          {t('EnterMonthlyOrAnnual')}
        </Typography>
        <Tabs
          labels={['Monthly', 'Annual']}
          value={value}
          onChange={handleChange}
          aria-label='budget period selector'
          sx={{ mb: 3 }}
        />
      </Box>
      <Grid container spacing={5}>
        <Grid item xs>
          <IncomeSection budget={budget} mode={mode} />
          <SavingsSection budget={budget} mode={mode} />
          <SpendingSection budget={budget} mode={mode} />
          <GivingSection budget={budget} mode={mode} />
        </Grid>
        <Grid item sx={{ width: `${sidePanelWidth}px` }}>
          <BudgetDetailsSidePanel />
        </Grid>
      </Grid>
    </Box>
  );
};
